import React from 'react';
import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import history from '../../history';
import {globs, constant, timedOut,getRandomString,saveLocalStorage,saveLocalStorage2,
  getLocalStorage,getTime,callStack,ufs} from '../../components/utils/utils';
import {dbVals,getZoneControllers} from '../../components/utils/http'
import {loadSensorsInfo,getSensorsZone,loadZonesInfo,getZoneInfo,loadSiteData,
  intToBase64} from '../components/C18utils'
import {p, pi, pInd, dpNames, tableBases2} from '../../components/utils/paramIds'
// import {config} from '../../components/utils/config'

var cl = console.log
var config = require('../../components/utils/config');
//     cl((config))
// var globs = {};
// var constant = {// must be taken from main utils!
//   urls: {
//     usa: "http://ng.c2.link4cloud.com/usa",
//     auth: "http://userAuth.c2.link4cloud.com",
//     email: "http://email.c2.link4cloud.com"
//   },
//   reCaptchaKeys: {// these are v3 keys, from 20200915, for Link4Cloud@GMail.com
//     site_v2:   "6LergcwZAAAAAEZu1hJC5b7s8ar2JSHvgyfgbYpx",
//     secret_v2: "6LergcwZAAAAABXOadtCvKVoPCfP3AibSWRVVzhQ",
//     site_v3:   "6Lesf8wZAAAAAA-PzVShQGJX3eoHvT5voGznNKIG",
//     secret_v3: "6Lesf8wZAAAAAAUmHQvGxMg6g2nS8w9GlP6woXCy",
//   },
// };
var cl=console.log;

/*
These are the v3 Recaptcha Keys for Link4Cloud@GMail.com, from 20200915:

site:   6Lesf8wZAAAAAA-PzVShQGJX3eoHvT5voGznNKIG
secret: 6Lesf8wZAAAAAAUmHQvGxMg6g2nS8w9GlP6woXCy
 */

/********************** values and functions for Widgets **********************************/

var stages=["Heat 6", "Heat 5", "Heat 4", "Heat 3", "Heat 2", "Heat 1", "Normal", 
"Cool 1", "Cool 2", "Cool 3", "Cool 4", "Cool 5", "Cool 6" ]
var humStages=["Hum 1","Normal","DeHum 1","DeHum 2"]
var eqTypes=["None", "On Off", "Irr", "Co2", "HID", "Mzone", "Pump", "Fvalve", "Vent", "Curt", "MixV", "MZone2", 
"GenPid", "None", "None", "None", "None", "None", "VarOut"]

var getTable=(pid,z)=>{
  let zType=(globs.siteZoneTypes||{})[z]||1800
  for (let i = 0 ; i < (tableBases2[zType]||[]).length - 1 ; i++){
    if(pid < tableBases2[zType][i+1][0]){
      return tableBases2[zType][i]
    }
  }
}

var pctUnit=(z)=>{
  return {t:"%",v:0}
}

var co2Unit=(z)=>{
  return {t:"PPM",v:0}
}

var nulUnit=(z)=>{
  return {t:"",v:0}
}

var pressUnit=(z)=>{
  return {t:"kPa",v:0}
}

var tempUnit=(z)=>{
  let pid=getParamId("configuration_zone_settings","Temperature Units")
//   cl(pid)
//   cl(z)
//   cl(dbVals.z[z])
//   cl(dbVals.z[z])
  let unit=(dbVals.z[z]&&dbVals.z[z][255])?+(dbVals.z[z][255][pid]||0):0
  return {t:(unit)?"\u00B0C":"\u00B0F",v:unit}
}

var windUnit=(z)=>{
  let pid=getParamId("configuration_zone_settings","Windspeed Units")
  let unit=(dbVals.z[z]&&dbVals.z[z][255])?+(dbVals.z[z][255][pid]||0):0
//   let unit=(dbVals.z[z])?+(dbVals.z[z][255][pid]||0):0
//   let unit=+(dbVals.z[z][255][pid]||0)
  return {t:(unit)?"kph":"mph",v:unit}
}

var lightUnit=(z)=>{
  let pid=getParamId("configuration_zone_settings","Light Units")
  let unit=(dbVals.z[z]&&dbVals.z[z][255])?+(dbVals.z[z][255][pid]||0):0
//   let unit=(dbVals.z[z])?+(dbVals.z[z][255][pid]||0):0
//   let unit=+(dbVals.z[z][255][pid]||0)
  return {t:["w/m\u00B2","kLux","uMol/m\u00B2/s"][unit],v:unit}
//   return {t:(unit)?"uMol/m\u00B2/s":"w/m\u00B2",v:unit}
}

var nuteUnit=(z)=>{
  let pid=getParamId("configuration_zone_settings","Nutrient Units")

  let unit=(dbVals.z[z]&&dbVals.z[z][255])?+(dbVals.z[z][255][pid]||0):0
//   let unit=(dbVals.z[z])?+(dbVals.z[z][255][pid]||0):0
//   let unit=+(dbVals.z[z][255][pid]||0)
  return {t:["us","CF","PPM"][unit],v:unit}
}

var bVolUnit=(z)=>{// "big" volume
  let pid=getParamId("configuration_zone_settings","Volume Measurement Units")
  let unit=(dbVals.z[z]&&dbVals.z[z][255])?+(dbVals.z[z][255][pid]||0):0
//   let unit=(dbVals.z[z])?+(dbVals.z[z][255][pid]||0):0
//   let unit=+(dbVals.z[z][255][pid]||0)
  return {t:["gal","l","gal"][unit],v:unit}
}

var sVolUnit=(z)=>{// "small" volume
  let pid=getParamId("configuration_zone_settings","Volume Measurement Units")
  let unit=(dbVals.z[z]&&dbVals.z[z][255])?+(dbVals.z[z][255][pid]||0):0
//   let unit=(dbVals.z[z])?+(dbVals.z[z][255][pid]||0):0
//   let unit=+(dbVals.z[z][255][pid]||0)
  return {t:["oz","ml","ml"][unit],v:unit}
}

var getParamId2=(gwType,table, column)=>{// this is not handling indexes!
    let fieldName=dpNames[table]
//     cl(fieldName)
    let type = pInd[gwType][fieldName]
//     cl(fieldName)
    let pid = pi[gwType][fieldName][column]
//     cl(fieldName)
//     if(table=="configuration_channel_data"){
//       cl(fieldName)
//       cl(pi[1800][fieldName])
//         cl(pid)
//     }
//     cl(pid)
//     if(column=="CD_analog_min_varout"){cl([fieldName,type,column,pid])}
    
    switch(type[1]){
      case 6:// zone and unit config
      case 7:// zone and unit config
        pid=2*pid+1
        break
      default:
        break
    }
    var dp
    switch(type[1]){
      default:
        dp=pid+type[0]
        break
    }
    return dp
  }

var getParamId800=(ta,co)=>{
//   cl(ta,co)
  return pi[800][ta][co]+pInd[800][ta][0]
}
  
var getParamId=(table, column)=>{// this is not handling indexes!
  return getParamId2(1800,table,column)
//     let fieldName=dpNames[table]
// //     cl(fieldName)
//     let type = pInd[1800][fieldName]
// //     cl(fieldName)
//     let pid = pi[1800][fieldName][column]
// //     if(table=="configuration_channel_data"){
// //       cl(fieldName)
// //       cl(pi[1800][fieldName])
// //         cl(pid)
// //     }
// //     cl(pid)
// //     if(column=="CD_analog_min_varout"){cl([fieldName,type,column,pid])}
//     
//     switch(type[1]){
//       case 6:// zone and unit config
//       case 7:// zone and unit config
//         pid=2*pid+1
//         break
//       default:
//         break
//     }
//     var dp
//     switch(type[1]){
//       default:
//         dp=pid+type[0]
//         break
//     }
//     return dp
  }
  
//   var alarmToSensor={ITHi:"inT",ITHi:"inT",ITSn:"inT",}
  var alarmToSensor=(alarmId)=>{
    let len=alarmId.length
    if(alarmId.substring(len-2,len)=="Sa"){
//       cl(alarmId)
//       cl(alarmId.substring(0,3))
      return alarmId.substring(0,3)
    }
  }
  
  var sensorIds
  var siteSensorNames
  
  var fixSensorIds=(zInd)=>{// extends ids to include expansion controllers
//     /*console.trace*/()
    let zoneControllers=getZoneControllers(zInd)
//     cl(zoneControllers)
//     cl(sensorIds)
    Object.keys(sensorIds).forEach(sid=>{
//       cl(sid)
      for(let i=1;i<4;i++){
        if(+zoneControllers[i]){
          sensorIds[`e${i}${sid}`]=Object.assign({},sensorIds[sid])
          
        }
      }
//       cl(sid)
      if(!["e0","e1","e2","e3"].includes(sid.substring(0,2))){
        sensorIds[`e0${sid}`]=sensorIds[sid]
        delete sensorIds[sid]
      }
    })
//     cl(sensorIds)
  }
  
  var makeSiteSensorNames=async(siteId)=>{
// use the sensorIds to make a complete list of all sensor names
// for all zones on the site, organized by zoneId
// then *erase* sensorIds
//     cl(siteId)
// this is trashing the current sensorIds
    await loadSiteData(siteId)
    await loadZonesInfo()
//     cl(sensorIds.e0at0.name)
    let gzi=globs.zonesInfo.info
    cl(gzi)
    siteSensorNames={}
    for(let i=0;i<gzi.length;i++){
      let z=gzi[i]
//       z.inNet=true// just for testing
//       cl(z)
      if((z.siteId==siteId)&&(z.inNet)){
//         cl(z)
        initSensorIds(z.siteZoneIndex)
        await setSensorIdNames(z.zoneId)
        cl(sensorIds)
        let sensorNames={}
        Object.keys(sensorIds).forEach(k=>{sensorNames[k]={name:sensorIds[k].name}})
        siteSensorNames[z.zoneId]=sensorNames
        siteSensorNames[z.siteZoneIndex]=sensorNames
      }
    }
//     cl(sensorIds.e0at0.name)
//     sensorIds=null
    cl(siteSensorNames)
    return siteSensorNames
  }
  
  var loadAddedSensors=(zInd)=>{
//     console.trace()
    let asNames={temp:"Temperature",hum:"Humidity"}
    let valId=getParamId("config_added_sensors","value")
    let typeId=getParamId("config_added_sensors","type")
    let unitId=getParamId("config_added_sensors","unit")
    let addSensors=[]
    for(let i=0;i<40;i++){
//       cl(zInd,i,valId)
//       cl([zInd,i,valId])
       if(((dbVals.z[zInd]||{})[i]||{})[valId]){
//          cl((dbVals.z[zInd][i]||{})[valId])
         let type=dbVals.z[zInd][i][typeId]||""
         let unit=dbVals.z[zInd][i][unitId]||""
//          cl([zInd,i,unitId,unit])
         let pos=unit.lastIndexOf("x")
         let mult=1
         if(pos>0){mult=+unit.substring(pos+1)}
//          cl(mult)
         addSensors.push({
           ch:i,
           name:`AS ${asNames[type]}`,
           type:type,
           unit:dbVals.z[zInd][i][unitId],
           mult:mult,
           id:valId,
        })}
    }
    return addSensors
  }
  
  var initAddedSensors=(zInd)=>{
    let addSensors=loadAddedSensors(zInd)
//     cl(addSensors)
    addSensors.forEach(s=>{
      let id=`e0as${s.ch}`
      sensorIds[id]={
        ch:s.ch,
        sensorIndex:s.ch+60,
        name:s.name,
        unit:s.unit,
        mult:s.mult,
        name2:"",
        id:s.id,
        type:"addedSensor",
      }
    })

    
//     cl(sensorIds)
// {
//     "ch": 240,
//     "id": 33,
//     "map": 4233,
//     "cal": 4295,
//     "lcIndex": 4,
//     "sensorIndex": 6,
//     "name": "Outside Light",
//     "name2": ""
// }    
//   let pid=getParamId("config_added_sensors","value")
//   cl(pid)
  
  }
  
  var initMBRegisters=(zInd,zoneId)=>{
    if(!zoneId){return}
//     cl("add mb registers")
//     cl(zInd)
//     cl(zoneId)
//     cl(globs.mbInfo.info)
//     cl(globs.zonesInfo.info)
    let zone=globs.zonesInfo.info.filter(z=>{return z.zoneId==zoneId})[0]
    let mbDevs=globs.mbInfo.info.devices.filter(d=>{
      return (d.gatewayId==zone.gatewayId)&&(d.zone==zone.siteZoneIndex)})
    let mbRegs=[]
    mbDevs.forEach(d=>{
      let addRegs=globs.mbInfo.info.registers.filter(r=>{
        return (r.gatewayId==zone.gatewayId)&&(r.typeId==d.typeId)}).map(r=>{return{
          regAddr:r.addr,
          typeId:r.typeId,
          mbAddr:d.addr,
          dName:d.name,
          rName:r.name,
        }})
//       cl(addRegs)
      mbRegs=mbRegs.concat(addRegs)
    })
//     cl(mbDevs)
//     cl(zone)
//     cl(mbRegs)
    Object.keys(mbRegs).forEach(k=>{
      let r=mbRegs[k]
//       cl(r)
      let addr=(zInd<<24)|(r.mbAddr<<16)|(r.regAddr)
      let id=`e0MB${intToBase64(addr)}`
//       cl(id)
      let name=`${r.dName}-${r.rName}`
      sensorIds[id]={
        z:zInd+64,
        ch:r.mbAddr,//s.ch,
        pid:r.regAddr,
//         sensorIndex:s.ch+60,
        name:name,
        unit:"unit",
        mult:1,
        name2:"",
        id:r.regAddr,
        type:"mbPoint",
      }
    })
//     mbRegs.forEach(r=>{
//     })
//       let id=`e0as${s.ch}`
//       sensorIds[id]={
//         ch:s.ch,
//         sensorIndex:s.ch+60,
//         name:s.name,
//         unit:s.unit,
//         mult:s.mult,
//         name2:"",
//         id:s.id,
//         type:"addedSensor",
//       }
//   cl(sensorIds)
  }

  var initSensorIds=(zInd,zone)=>{
//     cl("init sensor")
//     cl(zInd)
//     console.trace()
//     if(sensorIds){return}
    sensorIds={
      zoS:{ch: 255, id: 0, name:"Zone Setpoint", name2:"Setpoint", unit: nulUnit},
      inT:{ch: 240, id: getParamId("snapshots","inTemperature"), 
        map: getParamId("configuration_unit_settings","Inside Temperature Mapping"), 
        cal: getParamId("configuration_unit_settings","Inside Temperature Calibration"),
        lcIndex:0,
        sensorIndex:0,
        name:"Inside Temperature", name2:"In Temp.", unit: tempUnit},
      inH:{ch: 240, id: getParamId("snapshots","inHumidity"), 
        map: getParamId("configuration_unit_settings","Relative Humidity Mapping"), 
        cal: getParamId("configuration_unit_settings","Relative Humidity Calibration"), 
        lcIndex:2,
        sensorIndex:1,
        name:"Inside Humidity", name2:"In Hum.",  unit: pctUnit},
      inL:{ch: 240, id: getParamId("snapshots","inLight"), 
        map: getParamId("configuration_unit_settings","Inside Light Mapping"), 
        cal: getParamId("configuration_unit_settings","Inside Light Calibration"), 
        lcIndex:21,
        sensorIndex:2,
        name:"Inside Light", name2:"Light",  unit: lightUnit},
      inC:{ch: 240, id: getParamId("snapshots","co2"), 
        map: getParamId("configuration_unit_settings","CO2 Mapping"), 
        cal: getParamId("configuration_unit_settings","CO2 Calibration"), 
        lcIndex:3,
        sensorIndex:3,
        name:"Inside CO2", name2:"CO2",  unit: co2Unit},
      ouT:{ch: 240, id: getParamId("snapshots","outTemperature"), 
        map: getParamId("configuration_unit_settings","Outside Temperature Mapping"), 
        cal: getParamId("configuration_unit_settings","Outside Temperature Calibration"), 
        lcIndex:1,
        sensorIndex:4,
        name:"Outside Temperature", name2:"",  unit: tempUnit},
      ouH:{ch: 240, id: getParamId("snapshots","outHumidity"), 
        map: getParamId("configuration_unit_settings","Outside Humidity Mapping"), 
        cal: getParamId("configuration_unit_settings","Outside Humidity Calibration"), 
        lcIndex:44,
        sensorIndex:5,
        name:"Outside Humidity", name2:"",  unit: pctUnit},
      ouL:{ch: 240, id: getParamId("snapshots","outLight"), 
        map: getParamId("configuration_unit_settings","Outside Light Mapping"), 
        cal: getParamId("configuration_unit_settings","Inside Temperature Calibration"), 
        lcIndex:4,
        sensorIndex:6,
        name:"Outside Light", name2:"",  unit: lightUnit},
      bpT:{ch: 240, id: getParamId("snapshots","outTemperatureSecondary"), 
        map: getParamId("configuration_unit_settings","Outside Temperature 2 Mapping"), 
        cal: getParamId("configuration_unit_settings","Outside Temperature 2 Calibration"), 
        lcIndex:48,
        sensorIndex:7,
        name:"Black Plate Temp", name2:"",  unit: tempUnit},
      oWs:{ch: 240, id: getParamId("snapshots","windSpeed"), 
        map: getParamId("configuration_unit_settings","Wind Speed Mapping"), 
        cal: getParamId("configuration_unit_settings","Wind Speed Calibration"), 
        lcIndex:5,
        sensorIndex:8,
        name:"Wind Speed", name2:"",  unit: windUnit},
      oWd:{ch: 240, id: getParamId("snapshots","windDirection"), 
        map: getParamId("configuration_unit_settings","Wind Direction Mapping"), 
        cal: getParamId("configuration_unit_settings","Inside Temperature Calibration"), 
        lcIndex:12,
        sensorIndex:9,
        name:"Wind Direction", name2:"",  unit: nulUnit},
      dPr:{ch: 240, id: getParamId("snapshots","differentialPressure"), 
        map: getParamId("configuration_unit_settings","Differential Pressure Mapping"), 
        cal: getParamId("configuration_unit_settings","Differential Pressure Calibration"), 
        lcIndex:20,
        sensorIndex:10,
        name:"Differential Pressure", name2:"", name2:"",  unit: pressUnit},
      bPr:{ch: 240, id: getParamId("snapshots","barometricPressure"), 
        map: getParamId("configuration_unit_settings","Barometric Pressure Mapping"), 
        cal: getParamId("configuration_unit_settings","Barometric Pressure Calibration"), 
        lcIndex:49,
        sensorIndex:11,
        name:"Barometric Pressure", name2:"",  unit: pressUnit},
      ran:{ch: 240, id: getParamId("snapshots","rain"), 
        map: getParamId("configuration_unit_settings","Rain Mapping"), 
        cal: getParamId("configuration_unit_settings","Rain Calibration"), 
        lcIndex:18,
        sensorIndex:12,
        name:"Rain", name2:"",  unit: nulUnit},
      sno:{ch: 240, id: getParamId("snapshots","snow"), 
        map: getParamId("configuration_unit_settings","Snow Mapping"), 
        cal: getParamId("configuration_unit_settings","Snow Calibration"), 
        lcIndex:47,
        sensorIndex:13,
        name:"Snow", name2:"",  unit: nulUnit},
      vpd:{ch: 240, id: getParamId("snapshots","vpd"), 
        sensorIndex:14,
        name:"VPD", name2:"VPD",  unit: pressUnit},
      at0:{ch: 240, id: getParamId("snapshots","analogTemperature1"), 
        map: getParamId("configuration_unit_settings","Analog Temperature 1 Mapping"), 
        cal: getParamId("configuration_unit_settings","Analog Temperature 1 Calibration"), 
        lcIndex:6,
        sensorIndex:15,
        name:"Analog Temp 1", name2:"",  unit: tempUnit},
      at1:{ch: 240, id: getParamId("snapshots","analogTemperature2"), 
        map: getParamId("configuration_unit_settings","Analog Temperature 2 Mapping"), 
        cal: getParamId("configuration_unit_settings","Analog Temperature 2 Calibration"), 
        lcIndex:7,
        sensorIndex:16,
        name:"Analog Temp 2", name2:"",  unit: tempUnit},
      at2:{ch: 240, id: getParamId("snapshots","analogTemperature3"), 
        map: getParamId("configuration_unit_settings","Analog Temperature 3 Mapping"), 
        cal: getParamId("configuration_unit_settings","Analog Temperature 3 Calibration"), 
        lcIndex:8,
        sensorIndex:17,
        name:"Analog Temp 3", name2:"",  unit: tempUnit},
      at3:{ch: 240, id: getParamId("snapshots","analogTemperature4"), 
        map: getParamId("configuration_unit_settings","Analog Temperature 4 Mapping"), 
        cal: getParamId("configuration_unit_settings","Analog Temperature 4 Calibration"), 
        lcIndex:9,
        sensorIndex:18,
        name:"Analog Temp 4", name2:"",  unit: tempUnit},
      at4:{ch: 240, id: getParamId("snapshots","analogTemperature5"), 
        map: getParamId("configuration_unit_settings","Analog Temperature 5 Mapping"), 
        cal: getParamId("configuration_unit_settings","Analog Temperature 5 Calibration"), 
        lcIndex:10,
        sensorIndex:19,
        name:"Analog Temp 5", name2:"",  unit: tempUnit},
      vp0:{ch: 240, id: getParamId("snapshots","ventPosition1"), 
        map: getParamId("configuration_unit_settings","Vent Position Sensor 1 Mapping"), 
        cal: getParamId("configuration_unit_settings","Vent Position Sensor 1 Calibration"), 
        lcIndex:32,
        sensorIndex:20,
        name:"Vent Position 1", name2:"",  unit: pctUnit},
      vp1:{ch: 240, id: getParamId("snapshots","ventPosition2"), 
        map: getParamId("configuration_unit_settings","Vent Position Sensor 2 Mapping"), 
        cal: getParamId("configuration_unit_settings","Vent Position Sensor 2 Calibration"), 
        lcIndex:33,
        sensorIndex:21,
        name:"Vent Position 2", name2:"",  unit: pctUnit},
      vp2:{ch: 240, id: getParamId("snapshots","ventPosition3"), 
        map: getParamId("configuration_unit_settings","Vent Position Sensor 3 Mapping"), 
        cal: getParamId("configuration_unit_settings","Vent Position Sensor 3 Calibration"), 
        lcIndex:34,
        sensorIndex:22,
        name:"Vent Position 3", name2:"",  unit: pctUnit},
      vp3:{ch: 240, id: getParamId("snapshots","ventPosition4"), 
        map: getParamId("configuration_unit_settings","Vent Position Sensor 4 Mapping"), 
        cal: getParamId("configuration_unit_settings","Vent Position Sensor 4 Calibration"), 
        lcIndex:35,
        sensorIndex:23,
        name:"Vent Position 4", name2:"",  unit: pctUnit},
      vp4:{ch: 240, id: getParamId("snapshots","ventPosition5"), 
        map: getParamId("configuration_unit_settings","Vent Position Sensor 5 Mapping"), 
        cal: getParamId("configuration_unit_settings","Vent Position Sensor 5 Calibration"), 
        lcIndex:36,
        sensorIndex:24,
        name:"Vent Position 5", name2:"",  unit: pctUnit},
      sm0:{ch: 240, id: getParamId("snapshots","soilMoisture1"), 
        map: getParamId("configuration_unit_settings","Soil Moisture 1 Mapping"), 
        cal: getParamId("configuration_unit_settings","Soil Moisture 1 Calibration"), 
        lcIndex:13,
        sensorIndex:25,
        name:"Soil Moisture 1", name2:"",  unit: pctUnit},
      sm1:{ch: 240, id: getParamId("snapshots","soilMoisture2"), 
        map: getParamId("configuration_unit_settings","Soil Moisture 2 Mapping"), 
        cal: getParamId("configuration_unit_settings","Soil Moisture 2 Calibration"), 
        lcIndex:14,
        sensorIndex:26,
        name:"Soil Moisture 2", name2:"",  unit: pctUnit},
      sm2:{ch: 240, id: getParamId("snapshots","soilMoisture3"), 
        map: getParamId("configuration_unit_settings","Soil Moisture 3 Mapping"), 
        cal: getParamId("configuration_unit_settings","Soil Moisture 3 Calibration"), 
        lcIndex:15,
        sensorIndex:27,
        name:"Soil Moisture 3", name2:"",  unit: pctUnit},
      sm3:{ch: 240, id: getParamId("snapshots",""), 
        map: getParamId("configuration_unit_settings","Soil Moisture 4 Mapping"), 
        cal: getParamId("configuration_unit_settings","Soil Moisture 4 Calibration"), 
        lcIndex:16,
        sensorIndex:28,
        name:"Soil Moisture 4", name2:"",  unit: pctUnit},
      sm4:{ch: 240, id: getParamId("snapshots","soilMoisture5"), 
        map: getParamId("configuration_unit_settings","Soil Moisture 5 Mapping"), 
        cal: getParamId("configuration_unit_settings","Soil Moisture 5 Calibration"), 
        lcIndex:17,
        sensorIndex:29,
        name:"Soil Moisture 5", name2:"",  unit: pctUnit},
      
      fbT:{ch: 240, id: getParamId("snapshots","backupTemperature"), 
        map: getParamId("configuration_unit_settings","Fallback Sensor Input Mapping"), 
        cal: getParamId("configuration_unit_settings","Fallback Temperature Calibration"), 
        lcIndex:11,
        sensorIndex:30,
        name:"Fallback Temperature", name2:"",  unit: tempUnit},
        
      stT:{ch: 240, id: getParamId("snapshots","temperatureStage"), name:"Temperature Stage", name2:"Temp. Stage",  unit: nulUnit},
      stH:{ch: 240, id: getParamId("snapshots","humidityStage"), name:"Humidity Stage", name2:"Hum. Stage",  unit: nulUnit},
      spH:{ch: 240, id: getParamId("snapshots","heatSetpoint"), name:"Heat Setpoint", name2:"",  unit: tempUnit},
      spC:{ch: 240, id: getParamId("snapshots","coolSetpoint"), name:"Cool Setpoint", name2:"",  unit: tempUnit},
      spU:{ch: 240, id: getParamId("snapshots","humidifySetpoint"), name:"Humidify Setpoint", name2:"",  unit: pctUnit},
      spD:{ch: 240, id: getParamId("snapshots","dehumidifySetpoint"), name:"De-Humidify Setpoint", name2:"",  unit: pctUnit},
      alL:{ch: 240, id: getParamId("snapshots","lowAlarm"), name:"Low Alarm", name2:"",  unit: nulUnit},
      alH:{ch: 240, id: getParamId("snapshots","highAlarm"), name:"High Alarm", name2:"",  unit: nulUnit},
      chP:{ch: 240, id: getParamId("snapshot_channels","position"), name:"Channel Position", name2:"",  unit: nulUnit},
      ec:{ch: 192, id: getParamId("snapshot_ecphs","ec1"), name:"EC", name2:"EC",  unit: nuteUnit},
      ph:{ch: 192, id: getParamId("snapshot_ecphs","ph1"), name:"pH", name2:"pH",  unit: nulUnit},
      tp:{ch: 192, id: getParamId("snapshot_ecphs","temperature1"), name:"ECpH Temperature", name2:"",  unit: tempUnit},
      taH:{ch: 255, id: getParamId("configuration_zone_settings","High Alarm Temperature Above Cool Setpoint Threshold"), 
          name:"High Temp Alarm", name2:"",  unit: tempUnit},
      taL:{ch: 255, id: getParamId("configuration_zone_settings","Low Alarm Temperature Below Heat Setpoint Threshold"), 
          name:"Low Temp Alarm", name2:"",  unit: tempUnit},
      vpL:{ch: 240, id: getParamId("configuration_unit_settings","VPD Min"), name:"vpMin", name2:"",  unit: tempUnit},
      vpH:{ch: 240, id: getParamId("configuration_unit_settings","VPD Max"), name:"vpMax", name2:"",  unit: tempUnit},
      eL:{ch: 192, id: getParamId("configuration_ecph","lowECThreshold"), name:"Low EC Alarm", name2:"",  unit: nuteUnit},
      eH:{ch: 192, id: getParamId("configuration_ecph","highECThreshold"), name:"High EC Alarm", name2:"",  unit: nuteUnit},
      pL:{ch: 192, id: getParamId("configuration_ecph","lowPHThreshold"), name:"Low pH Alarm", name2:"",  unit: nulUnit},
      pH:{ch: 192, id: getParamId("configuration_ecph","highPHThreshold"), name:"High pH Alarm", name2:"",  unit: nulUnit},
      taN:{ch: 192, id: getParamId("configuration_ecph","name"), name:"Tank Name", name2:"",  unit: nulUnit},
    }
    initAddedSensors(zInd)
    initMBRegisters(zInd,zone)
    fixSensorIds(zInd)
  }
  
//   initSensorIds()
  
  var setSensorIdNames=async(zoneId)=>{
//     cl("set sensor ids")
//     console.trace()
    await loadSensorsInfo()
    let sensorNames=(getSensorsZone(zoneId)||{}).sensorNames
//     cl(Object.keys(sensorNames).length)
//     cl(sensorNames)
    if(sensorNames){
      Object.keys(sensorIds).forEach(k=>{
        if(sensorNames[k]){
          sensorIds[k].name=sensorNames[k]
        } else{
          if(k.substring(0,2)!="e0"){
            sensorIds[k].name=`E${k.substring(1,2)}-${sensorIds[k].name}`
          }
        }
      })
//       cl(sensorIds.e0at0.name)
    }else{
      let zInd=getZoneInfo(zoneId)?.siteZoneIndex
      initSensorIds(zInd)
    }
  }
  
var chanPosIds=(ch)=>{
    return {ch: ch, id: getParamId("snapshot_channels","position"), name:`Channel ${ch+1} Position`, unit: nulUnit}
}  
  
var eqOverrides={
  0: "",
  1: "Timed Override",
  2: "Humidify Override",
  3: "Dehumidify 1 Override",
  4: "Dehumidify 2 Override",
  5: "Dehum Low Temp Override",
  6: "Wind Override",
  7: "Rain Override",
  8: "Low Out Temp Override",
  9: "High Out Temp Override",
  10: "Aux Control Override",
  11: "EC/pH Tank Override",
  12: "Fire Override",
  13: "Wind Break Override",
  14: "Open Field Override",
  15: "Shade House Override",
  16: "Humidity Increase Override",
  17: "Crop Protection 1 Override",
  18: "Crop Protection 2 Override",
  19: "Crop Protection 3 Override",
  20: "Structural Protection Override",
  21: "Hail Override",
}
  
var idToTabInd=(id,zType)=>{
    for (let i = 0 ; i < tableBases2[zType].length ; i++){
      if(tableBases2[zType][i][0] > id) return i - 1;
    }
  }
  
var tabIndToName=(base)=>{
    for(let i=0; i<Object.keys(pInd[1800]).length; i++){
      let k=Object.keys(pInd[1800])[i];
      if(pInd[1800][k][0]==base)return k
    }
  }
  
var tabOffsetToName=(tab,tabName, ofs)=>{
//     cl([tabName,ofs])
//   cl(tab,tabName,ofs)
    if([6,7].includes(tab[1])){ofs=(ofs-1)/2}
    if(([0,3,4].includes(tab[1]))&&(tab[2]>1)){ofs=ofs%tab[2]}
//     cl(ofs)
//     if([4].includes(tab[1])){ofs=}
    for(let i=0; i<Object.keys(pi[1800][tabName]).length; i++){
      let k=Object.keys(pi[1800][tabName])[i]
//       cl(k,ofs)
      if(pi[1800][tabName][k]==ofs) return k
        
    }
  }
  
var getDatapointName=(pid,z)=>{
    if(!pid) return ""
    let zType=globs.siteZoneTypes[z]
    if(zType!=1800){return "Unknown"}
//     cl(zType)
    let ind = idToTabInd(pid,zType)
    let tab=(tableBases2[zType][ind])
    let base=tab[0]
    let tabName=tabIndToName(base)
    let ofsName=tabOffsetToName(tab,tabName, pid-base)
    let type=tab[1]
    let abv = (tabName.indexOf("snap")>=0) ? "snap" : "conf"
//     cl([ind,pid-base,tab,base,tabName,ofsName,type,abv])
    let suff=(config.server=="dev")?`-${pid}`:""
//     cl(pid)
//     cl(suff)
//     cl(config.server)
    return tabName + "-" + ofsName+suff
  }
  


var getParmInfo=(z, c, ix, pid)=>{
  var z1, c1, i1
  let tab=getTable(pid,z)||[]
//   cl(pid)
//   cl(tab)
  //       cl(tab)
  let u = 0
  let k=tab[2]
  switch(tab[1]){
    case 0:// unit data
      z1 = +z;
      c1 = c//240 + u; // zone wide
      i1 = +pid +ix*k;
      break;
    case 1:// snap chan data
      z1 = +z;
      c1 = 40 * u + c; // zone wide
      i1 = +pid;
      break;
    case 2:// snap ecph
      z1 = +z;
      // c1 = 192 + 8 * u + idx; // zone wide
      c1 = +c + 8 * u// + idx; // zone wide
      i1 = +pid;
      break;
    case 3:// conf ecph sensors - idx is tank - I don't think so! ch should be tank, ix is sensor
      z1 = +z;
      // c1 = 192 + 8 * u + idx; // zone wide
      c1 = +c + 8 * u; // + idx; // zone wide
      i1 = +pid + ix * k;
      break;
    case 4:// zone wide, let setpoints
      z1 = +z;
      c1 = 255; // zone wide
      i1 = +pid + ix * k;
      break;
    case 5:// sitewide
      z1 = 255;
      c1 = 0;
      i1 = +pid;
      break;
    case 6:// zone type
      z1 = +z;
      c1 = 255; // zone wide
      i1 = +pid;
      break;
    case 7:// controller settings
      z1 = +z;
      c1 = c//240 + u; // zone wide
      i1 = +pid;
      break;
    default:
      break;
  }
  return [z1, c1, i1];
}

// var getParmInfo=(z, c, ix, pid)=>{
//   //   cl("get parm");
//   return getTable(z, c, ix, pid);
//   //   cl(tab)
// }

var getParmValue=(z, c, ix, pid)=>{
  var p=getParmInfo(z, c, ix, pid)
//   cl(p)
  try{
    if((typeof dbVals.z[p[0]][p[1]][p[2]]) == "object"){
      return dbVals.z[p[0]][p[1]][p[2]].val
    }else{
      return dbVals.z[p[0]][p[1]][p[2]]
    }
  }catch{
    return 0
  }
}

var readValObjType=(val)=>{// only works for numbers@
  if(typeof val == "object"){
    val=val.val
  }
  return (isNaN(val))?val:+val
}

var getChannelType=(gwType, z, c)=>{
  //   cl(pi)
  //   cl(dbVals.z.length)
  //   cl([z, c])
  if(!dbVals.z.length) return
    let chType = {};
  chType[0] = "channel_None";
  chType[10] = "channel_On_Off";
  chType[20] = "channel_Irrigation_Scheduled";
  chType[21] = "channel_Irrigation_Accumulated_Light";
  chType[22] = "channel_Irrigation_Cycle";
  chType[23] = "channel_Irrigation_Trigger";
  chType[24] = "channel_Irrigation_Soil_Trigger";
  chType[25] = "channel_Irrigation_VPD";
  chType[30] = "channel_CO2";
  chType[40] = "channel_Light_Supplemental";
  chType[41] = "channel_Light_Scheduled";
  chType[42] = "channel_Light_Cyclic";
  chType[43] = "channel_Light_DLI";
  chType[50] = "channel_Microzone";
  chType[60] = "channel_Supply_Pump";
  chType[61] = "channel_Peristaltic_Recirculating_Pump";
  chType[62] = "channel_Peristaltic_Batch_Pump";
  chType[63] = "channel_Peristaltic_Balance_Pump";
  chType[70] = "channel_Fill_Valve";
  chType[80] = "channel_Vent_Roof";
  chType[81] = "channel_Vent_Retractable_Roof";
  chType[82] = "channel_Vent_Side_Wall";
  chType[90] = "channel_Curtain";
  chType[100] = "channel_Mix_Valve";
  chType[110] = "channel_Proportional_Microzone";
  chType[120] = "channel_PID";
  chType[180] = "channel_Variable_Out";
  
  let z0 = z;
  let c0 = (c*1);// 40 * (u*1) + 
  //   if (!dbVals.z[z0][c0]){
  //     dbVals.z[z0][c0] = dbVals.z[z0][0];
  //   }
  //   cl(dbVals.z)
  let ch = dbVals.z[z0][c0];
  //   cl(ch)
  let pid = p.PID_BASE_CONF_CHANNELS + pi[1800].channels_configuration["channelType"];
  //   let ty1 = 10 * (typeof ch[pid] == "object") ? ch[pid].val : ch[pid]
  let ty1 = 10 * readValObjType(ch[pid]);
  //       cl([pid, ty1]);
  cl(ty1)
  switch(ty1){
    case 20:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["irrigation_mode"];
      ty1 += readValObjType(ch[pid])
      break;
    case 40:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["light_mode"];
      ty1 += readValObjType(ch[pid])
      break;
    case 60:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["pump_type"];
      ty1 += readValObjType(ch[pid])
      break;
    case 80:
      pid = p.PID_BASE_CONF_CHAN_DATA + pi[1800].config_channels_configuration["vent_type"];// was vent_mode
      ty1 += readValObjType(ch[pid])
      break;
    default:
      break;
  }
  let suf=""
  if(((ty1%10)==8)&&(gwType==1900)){// if a vent
      suf="_1900"
  }
  let ret=chType[ty1]
  return chType[ty1]+suf;
}

var getChannelInfo=(z,c)=>{
  let ch = dbVals.z[+z][+c];
//   cl(ch)
  let chBase=p.PID_BASE_CONF_CHANNELS
  let chUsedId = chBase + pi[1800].channels_configuration["used"];
  let chAnalogId = chBase + pi[1800].channels_configuration["isAnalog"];
  let chNameId = chBase + pi[1800].channels_configuration["channelName"];
  let chTypeId = chBase + pi[1800].channels_configuration["channelType"];
  return {
    index: c,
    used: readValObjType(ch[chUsedId]),
    analog: readValObjType(ch[chAnalogId]),
    name: readValObjType(ch[chNameId]),
    type: readValObjType(ch[chTypeId]),
  }
}

var getChannelsInfo=(z,zoneControllers)=>{
//   cl(z.zoneControllers)
  let chBase=p.PID_BASE_CONF_CHANNELS
  let chUsedId = chBase + pi[1800].channels_configuration["used"];
  let ret=[]
//   cl(dbVals.z[+z])
//   cl(chUsedId)
  for(let c=0;c<160;c++){
    let u=Math.floor(c/40)
//     cl(dbVals.z)
//     cl(c)
//     cl([z,c])
    let used=readValObjType(((dbVals.z[+z]||{})[c]||{})[chUsedId])
//     cl([z,c,used])
    if(used&&(zoneControllers[u]!=0)){
      ret.push(getChannelInfo(z,c))
    }
  }
  return ret
}

/********************** End values and functions for Widgets **********************************/

const po = function (method, body){
  var ret = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/json',
      'authorization': 'Bearer ' + globs.token,
    },
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // *manual, follow, error
    referrer: 'no-referrer', // *client, no-referrer
  }
  if (body && (body !== "")) ret.body = body ;
  return ret ;
}

function doGetPost(server, purl, cmd, func, params){
  cl([server, purl, cmd, func, params]);
  return new Promise((res, rej)=>{
    let url = constant.urls[server] + "/" + purl;
    let pv = ((cmd === "POST") || (cmd === "PUT")) ?
      JSON.stringify(params.Post) : "";
    let httpObj = po (cmd, pv) ;
    return fetch(url, httpObj).then(
      response => {
//         response.text().then(r=>{cl(r)});
        response.json().then(
          resp=>{
            if(func)func(resp, params);
            res(resp);
          }
        );
      }, e=>{rej(e)}
    );
  })
}

/****************** WebSocket Functions *************************/

var wsArr = {}; 

var wsOnOpen=(r, ws)=>{
//   cl("got open");
//   cl(wsArr[ws.wsID].timeOut);
  clearTimeout(wsArr[ws.wsID].timeOut);
  wsArr[ws.wsID].state="ok";
  r({result: "ok"});
}

var wsOnClose=(ws)=>{
  wsArr[ws.wsID]=null;
//   cl("on close");
}

var wsOnError=(e, ws)=>{
  clearTimeout(wsArr[ws.wsID].timeOut);
  globs.events.publish("wsServerOK",false)
  globs.serverOK=false
  cl(e);
  e({result: "Web Socket Error"});
}

var timeoutError=(e)=>{
  e({result: "Web Socket Timeout Error"});
}

var waitForSocketOpen=(wsID, r)=>{
//   cl("wait for socket open: " + wsID);
  if((wsArr[wsID]||{}).state==="ok") {
//     clearInterval(wsArr[wsID].waitConnect);
    r({result: "ok"});
  }else{
    setTimeout(()=>waitForSocketOpen(wsID, r), 1000);
  }
}

var openWebSocket=(wsID, uri)=>{
//   cl(`Open ${wsID}, ${uri}`)
/* connect to a new web socket, add the callbacks, and
initialize the entry in the web socket array
set a timeout in case the connection just hangs

This gets more difficult when *two* callers are both trying to open the websocket*/
// cl(wsID)
// cl(uri)
  return new Promise((r, e)=>{
    if(wsArr[wsID]){// this socket is open, or connecting
//       cl("is open")
      waitForSocketOpen(wsID, r);
//       if(wsArr[wsID].state == "connecting"){
//         wsArr[wsID].waitConnect=setInterval(()=>waitForSocketOpen(wsID, r), 1000);
//       }else{
//         r({result: "ok"});
//       }
    }else{
//       cl(`Need to Open ${wsID}, ${uri}`)
//       cl(uri);
      let ws = new WebSocket(uri);
      ws.onopen = v=>wsOnOpen(r, ws);// this is where the promise returns!
      ws.onclose = v=>wsOnClose(ws);
      ws.onmessage = v=>wsOnMessage(v, ws);
      ws.onerror = v=>wsOnError(e, ws);
      ws.wsID = wsID;
//       let to=1;
//       let to = setTimeout(err=>timeoutError(err), 5000);
      let to = setTimeout(err=>timedOut("Web Socket"), 5000);
//       let to = timedOut("Web Socket")
      wsArr[wsID]={ws: ws, key: 0, callBacks: {}, timeOut: to, state: "connecting"};
//       r({result: "ok"});
//       cl(to);      
    }
  });
}

var initWebSocket=(wsID="usa")=>{
//   cl("init web socket");
//   cl(constant.wsUsaUrl);
  return openWebSocket(wsID, constant.wsUsaUrl);// a promise//"wss://gene.c2.link4cloud.com:constant.usaWebSocket"
}

var onCmdMessage=(msg,ws)=>{
  cl(msg)
  globs.events.publish(msg.cmd,msg)
//   switch(msg.cmd){
//     case "userNotification":
//       globs.events.publish("userNotification",msg)
//       break
//     default:
//       break
//   }
}

var wsOnMessage=(msg, ws)=>{

/* get the JSON object, and the callbacks for this keys
clean up the callback array, and use the success callback to return the object*/
  clearTimeout(wsArr[ws.wsID].timeOut);
  let msgObj=JSON.parse(msg.data);
  if(msgObj.cmd!="sRest"){return onCmdMessage(msgObj,ws)}
  let wsInfo = wsArr[ws.wsID];
  globs.events.publish("wsServerOK",true)
  globs.serverOK=true
  clearTimeout(wsInfo.timeOut);
//     cl(msgObj);
//   cl(wsInfo.callBacks);
  if(typeof(msgObj.cwsKey)!="undefined"){
    let [r, e] = wsInfo.callBacks[msgObj.cwsKey];
    delete wsInfo.callBacks[msgObj.cwsKey];
    r(msgObj);
  }
}

var sendWebSocketMessage=(wsID, msgObj)=>{
/* this is the bare minimum of sending a webSocket message
wsID is the id of the socket - just 'usa' for now.
wsArr holds info on all out web sockets.
*/
  wsArr[wsID].ws.send(JSON.stringify(msgObj));
}

var webSocketTransaction=(wsID, msgObj)=>{// msgObj must have a cmd field
/* this will attach a key to the message, to connect the response with the request.
This will return a promise*/
  return new Promise((r, e)=>{
    let wsInfo = wsArr[wsID];
    wsInfo.callBacks[wsInfo.key]=[r, e];
    let msgObj2=Object.assign({cwsKey: wsInfo.key++}, msgObj);
//     wsInfo.timeOut = setTimeout(err=>timeoutError(e), 5000);
    sendWebSocketMessage(wsID, msgObj2);
//     cl("sent");
  });
}

var cache={
  makeUri:(wsID,msgObj,keys)=>{
    let userId=globs.userData.session.userId||"userId"
    let accountId=globs.userData.session.accountId||"accountId"
    let keyStr=(keys||[]).join("/")
    if(keys.length){keyStr+="/"}
    return `/${wsID}${msgObj.uri}/${msgObj.method}/${accountId}/${keyStr}${userId}`
  },
  check:async(wsID,msgObj,keys,r,e)=>{
    let uri=cache.makeUri(wsID,msgObj,keys)
//     cl(`check ${uri}`)
    let res=await ufs.get(uri)
//     cl(res)
    if(res&&res.length){
      r(JSON.parse(res))
      return true
    }else{
      return false}
  },
  save:(wsID,msgObj,keys,res)=>{
    let uri=cache.makeUri(wsID,msgObj,keys)
//     cl(`save ${uri}`)
    ufs.set(uri,JSON.stringify(res))
  },
}

var badWsTrans=(msgObj)=>{
  cl(`Timeout Error in WS Transaction to: ${msgObj.uri}`)
}

var doWsTrans=(wsID,msgObj,useCache,doPromise,keys,r,e)=>{
  let now=getTime()
  let access={uri:`b_${msgObj.uri}`,method:msgObj.method,start:now}
  initWebSocket(wsID).then(
    re=>{
//       cl(msgObj.uri)
      let tmOut=setTimeout(e=>badWsTrans(msgObj),5000)
      webSocketTransaction(wsID, msgObj).then(
        res=>{
          clearTimeout(tmOut)
          if(res.result!="ok"){
            switch(res.result){
              case "badUri":
                cl(`WS Transaction Error, Bad Uri: ${msgObj.uri}`)
                break
            }
//             cl(res)
          }
//           cl(`got ${msgObj.uri}`)
          access.elapse=getTime()-access.start
          saveBrowserAccess(access)
          if(useCache){cache.save(wsID,msgObj,keys,res)}
          if(doPromise){r(res)}
        }, 
        err=>{e(err)}
      );
    },
    er=>{e(er)}
  );
  
}

var wsTrans=(wsID, msgObj,useCache=false,keys=[])=>{// this makes sure the socket is open
//   cl(keys)
  return new Promise(async(r, e)=>{
    if(useCache){
      let res=await cache.check(wsID,msgObj,keys,r,e)
      if(res){
//         cl(`fetch ${msgObj.uri}`)
        doWsTrans(wsID,msgObj,useCache,false,keys,r,e)
        return}
    }
//     cl(msgObj.uri)
    doWsTrans(wsID,msgObj,useCache,true,keys,r,e)
  });
}

var loadWsTrans=async(parms)=>{
  return await wsTrans("usa", {cmd: "cRest", uri: parms.uri, 
    method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId, 
    body: parms.body})
}
  
var webSocketREST=(wsID, uri, method, body=null)=>{
/* uri has two parts: endpoint, and params
endpoint is a normal uri: "/sites"
params is an object. the attributes are the values that are needed by the endpoint
This also needs to handle the secure transactionsif the uri begins with /s, then we need to include the 
access token. If that fails, try the refresh token. If that fails, try to login again
RememberMe should just be a 100year refresh token
*/
  let msgObj={
    cmd: "cRest",
    uri: uri,
    method: method,
    body: body
  };
  return webSocketTransaction(wsID, msgObj);
}

/****************** End WebSocket Functions *************************/

/****************** Material UI Functions *************************/

var handleClickShowPassword=(props)=>{
  cl(props);
  props.passwordType=(props.passwordType === "password") ? "text" : "password";
}

var inputField=(props)=>{
//   cl("input");
//   cl(props);
  if(props.type === "password"){
    return(
      <div style={{padding: 10}}>
      <InputLabel htmlFor="unknown">{props.title}</InputLabel>
      <Input
      onChange={props.onChange}
      type={props.passwordType}
      value={props.value}
      id={"if-" + props.field}
      endAdornment={
        <InputAdornment position="end">
        <IconButton
        aria-label="toggle password visibility"
        onClick={e=>handleClickShowPassword(props)}
        edge="end"
        >
        {props.passwordType !== "password" ? <Visibility /> : <VisibilityOff />}
        </IconButton>
        </InputAdornment>
      }
      />
      </div>
      
    );
  }else{
    return(
      <div style={{padding: 10}}>
      <InputLabel htmlFor="unknown">{props.title}</InputLabel>
      <Input
      onChange={props.onChange}
      value={props.value}
      id={"if-" + props.field}
      />
      </div>
    );
  }
}

/****************** End Material UI Functions *************************/

/********************** Login ******************************/

var logout=()=>{
//   cl("logout");
	if(globs?.userData?.session){
	  let logoutObj={cmd: "cRest", uri: "/o/users/login", method: "delete", 
		body: {sessionId: globs.userData.session.sessionId}
	  };
	  globs.userData.session=null;
	  wsTrans("usa", logoutObj).then(r=>{
		setLoggedIn(false);
	  });
	}
  
//   initWebSocket().then(r=>{
//     let body={sessionId: globs.userData.session.sessionId};
//     let msgLogOut = {cmd: "cRest", uri: "/o/users/login", method: "delete", body: body};
//     webSocketTransaction("usa", msgLogOut).then(
//       r=>{
//         setLoggedIn(false);
//       },
//       e=>{cl(e)}
//     );
//   });
}

var loginoutUsa=()=>{
// log in or out
  if(globs.userData.loggedIn){
    cl("logged in")
//     cl(dbVals)
    dbVals.z=[]
    dbVals.gotSite=false
    dbVals.initted=false
//     cl("logout");
//     cl(globs.userData.session.sessionId);
    logout();
    history.push("/usa/login");
  }else{
    history.push("/usa/login");
  }
//   cl("do login");
}

var setLoggedIn=(loggedIn)=>{
//   cl("set logged in");
  globs.userData.loggedIn=loggedIn;
//   if(!globs.menuBar) globs.menuBar={};
  if(globs.menuBar){globs.menuBar.setLoggedIn(loggedIn);}
  
}

var saveSession=(session)=>{
  saveLocalStorage("session", JSON.stringify(session));
  saveLocalStorage2("session", JSON.stringify(session));// to enable tab sharing
}

var saveTokens=(access, refresh, session)=>{// after activate or login
//   cl("saveTokens")
//   cl([access, refresh, session])
//   cl("save")
  if(!globs.userData) globs.userData={};
  globs.userData.accessToken=access;
  globs.userData.refreshToken=refresh;
  globs.userData.session=session;
  saveLocalStorage("accessToken", access);
  saveLocalStorage("refreshToken", refresh);
  saveSession(session)
//   saveLocalStorage("session", JSON.stringify(session));
  setLoggedIn(true);
}

// need mobile specific version!!!

var getUId=(uid=null)=>{
//   cl("save")
  if(!globs.userData){globs.userData={}}
  if (uid) {
    globs.userData.uId=uid
    saveLocalStorage("uId",globs.userData.uId)
  } else {
    globs.userData.uId=getLocalStorage("uId")
  }
  if(!globs.userData.uId){// unique ID for this client machine
    globs.userData.uId=getRandomString(16)
    saveLocalStorage("uId",globs.userData.uId)
  }
}

var loadTokens=(session=null)=>{
//   cl("load tokens")
//   console.trace()
//   cl("save")
  if(!globs.userData) globs.userData={};
  globs.userData.accessToken=getLocalStorage("accessToken");
  globs.userData.refreshToken=getLocalStorage("refreshToken");
  getUId()
//   cl(globs.userData.uId)
  try{
//     cl("get session")
    if (session) {
      globs.userData.session = session
      saveLocalStorage("session", globs.userData.session)
    } else {
      globs.userData.session=JSON.parse(getLocalStorage("session"));
    }
    return true
  }catch{
    globs.userData.session={};
    return false
  }
}
var loggingInResolves=[];

var doCheckLoggedIn=(r, e)=>{// this is called from MainBar
//   cl(globs.userData.loggedIn)
  if(globs.userData.loggedIn){
    r(true)
    return
  }
//   cl("still")
  loggingInResolves.push(r);// add it to the list
  if(loggingInResolves.length==1){// first time
//     cl("get")
    let body={sessionId: globs?.userData?.session?.sessionId};
    let msgCheckLoggedIn = {cmd: "cRest", uri: "/o/users/login", method: "retrieve", body: body};
//     cl(msgCheckLoggedIn)
    wsTrans("usa", msgCheckLoggedIn).then(re=>{
//       cl(re)
      if(re.result){
        let str=JSON.stringify(re.session)
        saveLocalStorage("session", str);
        saveLocalStorage2("session", str);// forced to localStorage, not session
//         cl("save session")
        globs.userData.session=re.session
//         cl(re.session)
        saveBrowserAccess("/browser/usaUtils/doCheckedLogin/line1245")
//         cl(globs.userData)
      }else{
        localStorage.removeItem("session");// session is no good
      }
//       cl(re.session)
      setLoggedIn(re.result);
//       globs.userData=null// why is this here?
      loggingInResolves.forEach(liR=>liR(re.result));
      loggingInResolves=[];
    });
  }
}

var resizePublish=()=>{
  globs.userData.resizeTimeout=null
  globs.events.publish("windowResize",globs.userData.winSize)
//   cl("resize")
}

var resizeEvent=()=>{
  globs.userData.winSize=[window.innerWidth, window.innerHeight]
  if(!globs.userData.resizeTimeout){ 
    globs.userData.resizeTimeout=setTimeout(resizePublish,1000)
  }
}

var getWindowResize=()=>{
//   cl("getWindowResize")
  globs.userData.winSize=[window.innerWidth, window.innerHeight]
  window.addEventListener('resize', resizeEvent);  
}

var checkLoggedIn=(session = null)=>{// if logged in, get session data, otherwise, mark userData.loggedIn false
  if(!globs?.userData?.session?.sessionId && !loadTokens(session)) {return}
  getWindowResize()
  return new Promise((r, e)=>{
    setTimeout(t=>doCheckLoggedIn(r, e), 0);// so that a page can "logout" before checkLoggedIn
  })
/*this needs to be callable from multiple places, as a promise, 
it will only do the actual check once, and then resolve all the promise requests
*/
  
  
// //   cl(globs.userData.session.sessionId);
//   initWebSocket().then(r=>{
//     let body={sessionId: globs?.userData?.session?.sessionId};
//     let msgCheckLoggedIn = {cmd: "cRest", uri: "/o/users/login", method: "retrieve", body: body};
//     webSocketTransaction("usa", msgCheckLoggedIn).then(// this will return tokens, like login, then redirect
//     r=>{
//       cl(r);
//       setLoggedIn(r.result);
//     },
//     e=>{cl(e)}
//     );
//   });
}

/********************** End Login ******************************/

/********************** Form Helper Functions  ******************************/
var validEmail=(email)=>{
  let atPos = email.indexOf("@");
  let perPos = email.lastIndexOf(".");
  return ((atPos > 0) && (perPos > atPos));
}

/********************** End Form Helper Functions  ******************************/

var tryIt=()=>{
  cl("try");
}

var colorSquare=(col, size, key=1, onClick=null)=>{
    return(
      <div key={key} style={{width: size, height: size, marginLeft: 10, display: "inline-block", verticalAlign: "middle", backgroundColor: col
      }} onClick={onClick}/>
    )
  }
  
var doGetPostBasic=(url, method, body=null, type='')=>{
  return new Promise((res, rej)=>{
    let options={
      method: method,
      headers: {},
    }
    if(body) options.body = body;
    if(type) options['content-type']=type
    return fetch(url, options).then(
      r=>{res(r)},
      e=>{rej(e);}
    )
  })
}

var getHomeDashboard=async()=>{
//     cl(this.state)
//     cl(this.props)
//     cl(globs.userData.session)
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {siteId: globs.userData.session.siteId}})
//     cl(r)
    if(r.data[0]&&r.data[0].homeDashboard){
      return r.data[0].homeDashboard
    }else{
      r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
        body: {a: globs.userData.session.accountId}})
      cl(r)
      if(!r.data.length){
        r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
          body: {u: globs.userData.session.userId}})
        cl(r)
      }
    }
    if(r.data[0]){return r.data[0].i}
  }

  var getLinkUrl=(parms)=>{
    let url=parms.link
    if(!url){return""}
    let end=url.lastIndexOf("/")
    let suff=url.substr(end);
    let p=suff.split("-")
    if(p.length==4){return url}
    let zci=parms.zci
    return `${url}/${zci[0]}-0-${zci[1]}-${zci[2]}`
  }
  
  var removeAClass=(curClass,remClass)=>{
    return curClass.replace(`${remClass} `,"")
  }
  
  var addAClass=(curClass,addClass)=>{
    cl(addClass)
    return curClass+`${addClass} `
  }
  
  var hasAClass=(curClass,checkClass)=>{
    return curClass.indexOf(` ${checkClass} `)>=0
  }
        
  var toggleAClass=(curClass,togClass)=>{
    let hasClass=hasAClass(curClass,togClass)
    curClass=removeAClass(curClass,togClass)
    if(!hasClass){
      curClass=addAClass(curClass,togClass)
    }
    return curClass
  }
  
  var idToTabInd=(id,zType)=>{
    cl(id,zType)
    for (let i = 0 ; i < tableBases2[zType].length ; i++){
      if(tableBases2[zType][i][0] > id) return i - 1;
    }
  }
  
  var tabIndToName=(base)=>{
    for(let i=0; i<Object.keys(pInd[1800]).length; i++){
      let k=Object.keys(pInd[1800])[i];
      if(pInd[1800][k][0]==base)return k
    }
  }
  
  var tabOffsetToName=(tab, ofs)=>{
//     cl([tab, ofs])
    for(let i=0; i<Object.keys(pi[1800][tab]).length; i++){
      let k=Object.keys(pi[1800][tab])[i]
      if(pi[1800][tab][k]==ofs) return k
    }
  }
  
  var getDatapointName=(pid,zType)=>{
    if(!pid) return ""
    let ind = idToTabInd(pid,zType)
    let tab=(tableBases2[zType][ind])
    let base=tab[0]
    let tabName=tabIndToName(base)
    let ofsName=tabOffsetToName(tabName, pid-base)
    let type=tab[1]
    let abv = (tabName.indexOf("snap")>=0) ? "snap" : "conf"
    let ret=abv + "-" + ofsName
    return ret
  }
  
  
//   var getLine=()=>{
// //     cl(callStack())
//     cl("get")
//     let er2={}
//     Error.captureStackTrace(er2)
//     let lines=er2.stack.split("\n")
//     cl(lines)
// //     cl(er2.stack)
// //     cl(typeof(er2.stack))
// //     cl(er2)
//     return
//     
//     let er=new Error()
//     let st=Error.captureStackTrace(er)
//     cl(er)
//     cl(st)
//     var orig = Error.prepareStackTrace;
//     cl(orig)
//     Error.prepareStackTrace = function(_, stack){ return stack; };
//     cl("next")
//     return
//     var err = new Error;
// //     Error.captureStackTrace(err, arguments.callee);
// //     var stack = err.stack;
// //     Error.prepareStackTrace = orig;
// //     return stack;
//     
//   }

  var browserAccess={last:0,arr:[]}
  var localAccess=[]
  
  
  var saveBrowserAccess=(msg)=>{
// if now is > 10 secs after last, then write it, and update last
// otherwise, just push to arr
// can call with null msg to flush it
//     cl(msg)
    let now=getTime()
    if(typeof(msg)=="string"){
      msg={
        uri:msg,
        method:"bCreate",
        start:now,
      }
    }
    browserAccess.arr.push(msg)
    let laLen=localAccess.length
    if(laLen>300){localAccess=localAccess.slice(laLen-200)}
    localAccess.push(msg)
    if(now-browserAccess.last>10){
      let ba={last:now,arr:browserAccess.arr.slice(0)}
      browserAccess={last:now,arr:[]}
//       cl("write")
//       let ba=Object.assign({},browserAccess)
//       browserAccess.last=now
      wsTrans("usa", {cmd: "cRest", uri: "/s/accesses", method: "create", 
        sessionId: globs?.userData?.session?.sessionId, body: ba})
    }
  }
  
  
  
export {cl, doGetPost, constant, openWebSocket, sendWebSocketMessage, webSocketTransaction,
  initWebSocket, inputField, loginoutUsa, saveTokens, checkLoggedIn, wsTrans, validEmail,
  logout, eqTypes, getChannelType, readValObjType, getTable, getParmValue,
  getDatapointName, colorSquare, getParmInfo, doGetPostBasic, getHomeDashboard,
  getLinkUrl, removeAClass, addAClass, hasAClass, toggleAClass, getParamId,
  getParamId2,
  tempUnit, lightUnit, bVolUnit, sVolUnit, windUnit,nuteUnit,stages,humStages, sensorIds,chanPosIds,getUId,eqOverrides,makeSiteSensorNames,siteSensorNames,
  saveSession,alarmToSensor,setSensorIdNames,getChannelsInfo,
  loadAddedSensors,initSensorIds,saveBrowserAccess,getWindowResize,loadWsTrans,
  localAccess,cache,getParamId800,
}
