import React from 'react';
import {cl,globs} from '../../components/utils/utils';

class C18MuiCheckbox extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }
  
  render(){
//     cl(globs)
    if(globs.noMui){
//       cl("checkbox")
      return(
        <input type="checkbox"
          checked={this.props.checked}
          value={this.props.value}
          onChange={this.props.onChange}
          disabled={!this.props.saveOK}
        >
          {this.props.children}
        </input>
      )
    }else{
      return React.createElement("input",this.props)
    }
  }
}
      
export default C18MuiCheckbox;
 
