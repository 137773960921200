import React from 'react';
// import FormControlLabel from '@material-ui/core/FormControlLabel';// import ConfTextField from './ConfTextField';
// import Checkbox from '@material-ui/core/Checkbox';
import C18MuiFormControlLabel from '../../usa/components/C18MuiFormControlLabel'
import C18MuiCheckbox from '../../usa/components/C18MuiCheckbox'
import {cl, globs} from '../../components/utils/utils';

class FuiCheckbox extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.props.getValue(props.ind, {type: "scalar"})
  }

  onChange=(e)=>{
    this.props.onChange(this.props.ind,
      {value: (e.target.checked) ? 1 : 0})
  }

  render(){
    // cl(this.props);
    return(
      <div id={this.props?.custom?.infoId} className="checkbox-field">
        <span>
          <C18MuiCheckbox
            id="check"
            checked={this.props.value != 0}
            onChange={this.onChange}
            value="checkedB"
            color="primary"
            saveOK={this.props.saveOK}
          />
          <label>{this.props.title}</label>
        </span>
      </div>
    );
  }
}

  export default FuiCheckbox ;
