import React from 'react';
import C18Select00 from './C18Select00'
import C18DateFormat00 from './C18DateFormat00'
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {cl,globs,constant,show,dateToDisplayDate,getDaysRemaining} from '../../components/utils/utils';
import {wsTrans,saveSession,saveBrowserAccess} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadAlarmsInfo,alLevel,getAlarmInfo,privs,loadPrivsInfo,
  getThirdPartyAccounts,loadMBInfo,loadGatewaysInfo,
  loadUsersInfo,getUserIndex,loadSubscriptionInfo,loadAddedSensorsInfo
} from './C18utils'
var config = require('../../components/utils/config');

class C18SitesListTiles00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(globs)
//     cl(props)
    saveBrowserAccess("/browser/sitesList/construct/line18")
    
    let sites=this.makeSites()
//     cl(sites)
    this.state={
      tileMode: "grid-select",
      loaded: false,
      accountSel:null,
      serverSel:config.server,
//       sites:sites,
      sites:[
        {
          name:"San Juan Capistrano",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Tatooine",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 1,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Mos Elsley",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Endor",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Alderaan",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
        {
          name:"Naboo",
          greenhouses: 10,
          unfinishedTasks: 3,
          alarms: 0,
          siteId:"0sna8jVYIh0xw6oF",
        },
      ],
    }
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: [
            {t:"Sites", url:"/usa/c18/sites"},
          ]},
      },
    )
  }
  
  countGreenhouses=(siteId)=>{
//     cl(siteId)
    let gzi=globs.zonesInfo.info
    let count=0
    gzi.forEach(z=>{
      if(z.siteId==siteId){count+=1}
    })
    return count
  }
  
  countAlarms=(siteId)=>{
    let gai=globs.alarmsInfo.info
//     cl(gai)
    let alarmCount=0
    for(let i=0;i<gai.length;i++){
      let a=gai[i]
//       cl(a)
      if((a.s==siteId)&&(alLevel(a.a,a.s,a.z)<4)){alarmCount++}
//         let ai=getAlarmInfo(a.a)
//         if(ai.level<=3){alarmCount++}
//       }
//       cl(a)
    }
    return alarmCount
  }
  
  saveUser=async(info)=>{
//     cl([userId,info])
    await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update2", 
      sessionId: globs.userData.session.sessionId,
      body: info})
  }
  
  showSubInfo=async()=>{
    // check if pop up should be shown
    await loadSubscriptionInfo()
    // let user=globs.usersInfo.info[getUserIndex(globs.userData.session.userId)]
    // if(!user){return}
    // if(!user.uiInfo){user.uiInfo={
    //   showHelpInfo:true,
    // }}
    // if(user.uiInfo.showHelpInfo){
    if(false){
      let days = getDaysRemaining(new Date(globs.subscriptionInfo.info.end_date))
      let text = `In ${days} days, your free trial will end. If you do not purchase licenses before trial ends, you will not be able to access unlicensed zones.` 
      let buttons = ["OK, Remind Me Later", "Manage Subscription"]
      let res=await this.props.parms.getPopup({text:text, buttons:buttons})
      // if(res=="Got It!"){
      //   user.uiInfo.showHelpInfo=false
      //   this.saveUser({userId:user.userId,uiInfo:user.uiInfo})
      // }
    }
  }

  showHelpInfo=async()=>{
    await loadUsersInfo()
    let user=globs.usersInfo.info[getUserIndex(globs.userData.session.userId)]
    if(!user){return}
    if(!user.uiInfo){user.uiInfo={
      showHelpInfo:true,
    }}
    if(user.uiInfo.showHelpInfo){
      let res=await this.props.parms.getPopup({text:"You can get information at any time by right-clicking on the area you want to know more about.",          
        buttons:["Dismiss","Got It!"]})
      if(res=="Got It!"){
        user.uiInfo.showHelpInfo=false
        this.saveUser({userId:user.userId,uiInfo:user.uiInfo})
      }
    }
  }
  
  makeSites=async()=>{
//     cl(globs.userData.session)
//     cl("make sites")
//     cl(show("main"))
//     cl("make sites")
    await loadSitesInfo()
    await loadZonesInfo()
    await loadAlarmsInfo()
    await loadGatewaysInfo()
    await loadAddedSensorsInfo()
    await loadPrivsInfo()
    await loadMBInfo()
//     cl("loaded888888888888888888888888888888888")
//     cl(globs.privsInfo)
//     let p3=await getThirdPartyAccounts(globs.userData.session.userId)
//     cl(p3)
//     cl(globs)
    await this.loadAccounts()// needs to check for privs!
//     cl(globs.privsInfo.info)
    let sites=[]
//     cl(globs.sitesInfo)
    let gsi=globs.sitesInfo.info
//     cl(gsi)
//     cl(globs.zonesInfo)
    gsi.forEach(s=>{
//       cl(s)
      let count=this.countGreenhouses(s.siteId)
      sites.push(
        {
          name: s.name.length > 35 ? s.name.substring(0, 32) + '...' : s.name,
          greenhouses:this.countGreenhouses(s.siteId),
          unfinishedTasks: "tbd",
          alarms: this.countAlarms(s.siteId),
          siteId:s.siteId,
          siteImage:s.siteImage
        }
      )
    })
    this.setState({loaded:true, sites:sites, accountSel:globs.userData.session.accountId})
//     return sites
    this.showHelpInfo()
//     cl("make sites")
//     cl(globs.zonesInfo.info[0].inNet)
    // this.showSubInfo()
  }
  
  showSiteTileAlarm=(s)=>{
    if(s.alarms){
      return(
        <tr className="alarm-on"><th>Alarms <button type="button" className="alarm-icon" 
          onClick={e=>{this.onChange("alarm",{siteId:s.siteId,event: e}); return false}}
          aria-label="alarm">!
          </button> level 1-3</th><td>{s.alarms}</td></tr>
      )
    }else{
      return(
        <tr><th>Alarms</th><td>--</td></tr>
      )
    }
  }
  
  onClickSite=async(e,s)=>{// need to set the siteId in the session on the server!
    e.preventDefault()
    e.stopPropagation()
    await wsTrans("usa", {cmd: "cRest", uri: "/s/sessions", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:s.siteId}})
    history.push(`/usa/c18/sites/${s.siteId}`)
//     cl(s)
  }
  
  showSiteTiles=()=>{
    let sites=[]
//     cl(globs.privsInfo)

    this.state.sites.forEach((s,i)=>{
//       if(s.siteId=="d1fIue45CWvP@Nik"){
//       }
      let gws=globs.gatewaysInfo.info.filter(gw=>{return gw.siteId==s.siteId})
      let serverGood=true,gotServer=false
      gws.forEach(gw=>{

        if(gw.server=="http0Test"){gw.server="prod0Test"}
//         cl(gw.server)
        if(gw.server){gotServer=true}
        if(gw.server&&(gw.server!=config.server)){serverGood=false}
      })
//       let color="black"
//       var title
      var color,title
      if(!gotServer){color="#888888"}
      if(!serverGood){
        color="red"
        title="Site is connected to a different server!!!"
      }
      if(privs("site",s.siteId,constant.AREA_PRIVS_READ)){
        let path
        if (s.siteImage) {
          let si=s.siteImage.split('.')[0];
          let ext=s.siteImage.split('.')[1];
          path=`${constant.expressUrl}/usa/images/site/uploads/${si[0]}/${si[1]}/${si[2]}/${si.substr(3)}.${ext}`
        } else {
          path = "/gh1.png";
        }
        sites.push(
          <div key={i} id="siteTiles" className="location-box-container" onClick={e=>{this.onClickSite(e,s)}}>
            <div className={`location-box${(s.alarms)?" alarm-on":""}`} aria-label={s.name}>
              <C18Anchor00 to="" onClick={e=>{this.onClickSite(e,s)}}>
                <h2 style={{color:color}} title={title}>{s.name}</h2>
              </C18Anchor00>
                
                <table><tbody>
                  <tr><th>Greenhouses</th><td>{s.greenhouses}</td></tr>
                  <tr>{false&&<><th>Unfinished Tasks</th><td>{s.unfinishedTasks}</td></>}</tr>
                  {this.showSiteTileAlarm(s)}
                </tbody></table>
                <img id="sites/siteImage" src={path} alt={s.name}/>
            </div>
          </div>
        )
      }
    })
    return (
      <div className="location-grid-area">
        {sites}
      </div>
    )
  }
  
  showSiteListAlarms=(s)=>{
    if(s.alarms){
      return(
        <>
        <td>{s.alarms}</td><td>level 1-3
        <button type="button" className="alarm-icon"
          onClick={e=>{this.onChange("alarm",{siteId:s.siteId,event: e}); return false}}>!</button></td>        
        </>
      )
    }else{
      return(
        <>
        <td>tbd</td><td></td>
        </>
      )
    }
    
  }
  
  showSiteList=()=>{
    let sites=[]
    this.state.sites.forEach((s,i)=>{
      if(privs("site",s.siteId,constant.AREA_PRIVS_READ)){
  //       cl(s)
        let alarmClass=(s.alarms)?"alarm-on":""
        sites.push(
          <tr key={i} className={alarmClass}>
            <td><C18Anchor00 to="" className="name" onClick={e=>{this.onClickSite(e,s)}}>{s.name}</C18Anchor00></td><td>{s.greenhouses}</td>
              {false&&<td>{s.unfinishedTasks}</td>}
              {this.showSiteListAlarms(s)}
              {false&&
                <td><a href="" className="create-grow-journal-entry material-icons-outlined" aria-label="make grow journal entry">
                        note_alt
                      </a></td>
              }
          </tr>
        )
      }
    })
    return (
      <div className="location-list">
        <table className="list"><tbody>
          <tr>
            <th>Name</th><th>Greenhouses</th>{false&&<th>Unfinished Tasks</th>}<th>Alarms</th>
            {false&&
            <th></th>
            }
          </tr>
          {sites}
        </tbody></table>
      </div>
    )
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "format":
        this.setState(vals)
        break
      case "selectAccount":
        this.setState(vals)
        cl(vals)
        globs.userData.session.accountId=vals.accountSel
        saveSession(globs.userData.session)
        let endPoint=(globs.privsInfo.thirdPartyAccounts?.length)?
          "/tp/tpSession":"/su/suSession"
        let res=await wsTrans("usa", {cmd: "cRest", uri: endPoint, method: "update", 
          sessionId: globs.userData.session.sessionId,
          body: {accountId:vals.accountSel}})
        cl(res)
        cl("***********************reload")
        setTimeout(()=>{window.location.reload()},1000)
        break
      case "selectServer":
        let server=vals.serverSel
        cl(server)
        this.setState({serverSel:server})
        sessionStorage.setItem("serverOverride", server);
        setTimeout(()=>{window.location.reload()},1000)
        break
      case "alarm":
        let e=vals.event
        e.stopPropagation()
        history.push(`/usa/c18/sites/${vals.siteId}/alarmLog`)
        break
    }
  }
  
  showSites=()=>{
    return (this.state.tileMode=="grid-select")?this.showSiteTiles():this.showSiteList()
  }
  
  loadAccounts=async()=>{
    var res
    if(privs("super","",constant.AREA_PRIVS_READ)){
      if(this.accounts){return}
      res=await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts", method: "retrieve", 
        sessionId: globs.userData.session.sessionId,
        body: {}})
    }
    if(globs.privsInfo.thirdPartyAccounts?.length){
      cl("get third party")
      res=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpAccounts", method: "retrieve", 
        sessionId: globs.userData.session.sessionId,
        body: {}})
    }
    this.accounts=res?.data
//     cl(this.accounts)
    if(this.accounts){
      this.accounts.sort((a,b)=>{
//         cl(a)
        let na=(a.name||"").toLowerCase()
        let nb=(b.name||"").toLowerCase()
        if(na<nb){return -1}
        if(na>nb){return 1}
        return 0
      })
    }
//     cl(this.accounts)
  }
  
  showSelectServer=()=>{
    let servers=[
      {t:"Prod",v:{s:"prod0",h:"prod0"}},
      {t:"Prod Test",v:{s:"prod0Test",h:"prod0Test"}},
      {t:"Dev",v:{s:"dev",h:"sg"}},
      {t:"Ryan ElK",v:{s:"ryan",h:"sg_ryan"}},
      {t:"Stage",v:{s:"stage",h:"sg_stage"}},
      {t:"Alpha",v:{s:"alpha",h:"sg_alpha"}},
      {t:"Demo",v:{s:"demo",h:"demo"}},
    ]
//     cl(this.state.serverSel)
    return(
        <div className="custom-select">
          <label htmlFor="">Select Server</label>
          <C18Select00 id=""
            value={this.state.serverSel}
            onChange={e=>this.onChange("selectServer",{serverSel: e.currentTarget.value})}
          >
          {servers.map((s,i)=>{
//             cl(s.v.s)
            return(
              <option key={i} value={s.v.s}>{s.t}</option>
            )
          })}
          </C18Select00>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div>
    )
  }

  showSelectAccount=()=>{
//     this.loadAccounts()
    if(privs("super","",constant.AREA_PRIVS_READ)||(
      (globs.privsInfo.thirdPartyAccounts?.length>1)
    )){
      return(
        <section>
        <div className="custom-select">
          <label htmlFor="">Select Account</label>
          <C18Select00 id=""
            value={this.state.accountSel}
            onChange={e=>this.onChange("selectAccount",{accountSel: e.currentTarget.value})}
          >
          {this.accounts.map((a,i)=>{
            return(
              <option key={i} value={a.accountId}>{a.name}</option>
            )
          })}
          </C18Select00>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </div> 
        {this.showSelectServer()}
        </section>
      )
    }else{return null}
  }

  render(){
//     cl(this.state)
//     cl(globs.privsInfo.info)
    if(this.state.loaded){
      let idx = getUserIndex(globs.userData.session.userId)
      if (idx||(idx==0)) {
        let u = globs.usersInfo.info[idx]
        this.timeZone = (u.timezone) ? u.timezone : "";
      }
      return (
        <div>
          <h1>Sites</h1>
          <div id="content-area">
            <section className={(this.state.tileMode)?"no-section":""}>
              <C18DateFormat00 parms={{
                leftString: dateToDisplayDate(new Date(),"mm/dd/yyyy h:mm ap"),
                leftClass: "date",
                type: "listTile",
                paretn: "sites",
                value: this.state.tileMode,
                valueId: "tileMode",
                timeZone: this.timeZone,
                onChange: o=>this.onChange("format",o)
              }}/>
              {this.showSelectAccount()}
              {this.showSites()}
            </section>
          </div>
        </div>
      )
    }else{
      return <div id="content-area">loading sites list. . .</div>
    }
  }
}
      
export default C18SitesListTiles00;
