import React from 'react';
// import CravoInput from './CravoInput'
import UsaDuration00 from '../../usa/components/UsaDuration00'
import {p, pi, pInd} from '../../components/utils/paramIds';
import {cl,globs,getTime} from '../../components/utils/utils';
import {sendArray, checkSendArray, dbVals} from '../../components/utils/http';
import {showSelect, showInput} from '../../components/utils/reactUtils';

const INPUTIDX_INTEMP=0
const INPUTIDX_OUTTEMP=1
const INPUTIDX_HUM=2
const INPUTIDX_CO2=3
const INPUTIDX_LITE=4
const INPUTIDX_WINSPD=5
const INPUTIDX_WINDIR=12
const INPUTIDX_BHEAT1=6
const INPUTIDX_BHEAT2=7
const INPUTIDX_BHEAT3=8
const INPUTIDX_BHEAT4=9
const INPUTIDX_BHEAT5=10
const INPUTIDX_RAIN=18

class PIDAdvancedSettings extends React.Component{
  constructor(props) {
    let [z,u,c,i]=props.zuci.split("-")
    super(props);
    let ids=this.getDBIds()
    let c2=+c+40*u
//     cl(c2)
    this.state={
      supplyPump: this.getParam(z,c2,ids.supplyPump),
      p: this.getParam(z,c2,ids.P),
      i: this.getParam(z,c2,ids.I),
      d: this.getParam(z,c2,ids.D),
      override_dir:  this.getParam(z,c2,ids.override_dir),
      override_action_target: this.getParam(z,c2,ids.override_action_target),
      override_trigger: this.getParam(z,c2,ids.override_trigger),
      sensor: this.getParam(z,c2,ids.override_sensor),
      override_limit: this.getParam(z,c2,ids.override_limit),
//       output_type: this.getParam(z,c2,ids.supplyPump),
      hold_delay:this.getParam(z,c2,ids.hold_delay),
      c:c,
      z:z,
    }
//     cl(this.state)
    this.props.getValue(props.ind, {type: "array", count: 11})
    this.props.saves.push(this.onSave)
//     cl(this.props.value)
//     this.getDBVars()
  }
  
  getParam=(z,c,id)=>{
    let ret=(((dbVals.z||{})[+z]||{})[+c]||{})[+id]
    return (ret)?ret:0
  }
  
 putParam=(z,c,id, t,parms,val)=>{// tab refers to our local (channel, id) tables
    parms.push({
      c:c,// zone wide
      d:val,
      f:1,
      i:id,
      t:t,
      z:z,
    })
  }
  
  

//   onChange=(type, valId, ind, e)=>{
//     var vals={}
//     let arr=this.props.value.slice(0)
//     switch(type){
//       case "inp":
//         arr[ind]=e.currentTarget.value
//         break
//       case "check":
//         arr[ind]=(e.currentTarget.checked)?1:0
//         break
//     }
//     this.setState({arr: arr})
//     this.props.onChange(this.props.ind, {value: arr})
//   }

  getDBIds=()=>{
    let b=pInd[1800].config_channels_configuration[0]
    let ids={}
    ids.supplyPump=b+pi[1800].config_channels_configuration.CD_supply_pump_generic
    ids.P=b+pi[1800].config_channels_configuration.P
    ids.I=b+pi[1800].config_channels_configuration.I
    ids.D=b+pi[1800].config_channels_configuration.D
    ids.override_sensor=b+pi[1800].config_channels_configuration.override_sensor
    ids.override_dir=b+pi[1800].config_channels_configuration.override_dir
    ids.override_trigger=b+pi[1800].config_channels_configuration.override_trigger
    ids.override_action_target=b+pi[1800].config_channels_configuration.override_action_target
    ids.override_limit=b+pi[1800].config_channels_configuration.override_limit
    ids.hold_delay=b+pi[1800].config_channels_configuration.hold_delay
//     cl(ids)
    return ids
  }
  
//   getDBVars=()=>{
//     let ids=this.getDBIds()
//     cl(this.state)
//     cl(ids)
//   }
  
  onSave=()=>{
//     cl(this.state)
    let [z,u,c,i]=this.props.zuci.split("-")
    let t=Math.floor(getTime())
    let ids=this.getDBIds()
    let parms=[]
    let c2=+c+40*u
//     cl(c2)
    this.putParam(z,c2,ids.supplyPump,t,parms,this.state.supplyPump)
    this.putParam(z,c2,ids.P,t,parms,this.state.p)
    this.putParam(z,c2,ids.I,t,parms,this.state.i)
    this.putParam(z,c2,ids.D,t,parms,this.state.d)
    this.putParam(z,c2,ids.override_sensor,t,parms,this.state.sensor)
    this.putParam(z,c2,ids.override_dir,t,parms,this.state.override_dir)
    this.putParam(z,c2,ids.override_trigger,t,parms,this.state.override_trigger)
    this.putParam(z,c2,ids.override_action_target,t,parms,this.state.override_action_target)
    this.putParam(z,c2,ids.override_limit,t,parms,this.state.override_limit)
    this.putParam(z,c2,ids.hold_delay,t,parms,this.state.hold_delay)
//     cl(this.state)
    checkSendArray(parms,this.props.current.virtual).then(r=>{globs.events.publish("saveOK",true)})
    
//     cl("saving")
  }
  
  showInput=(val, valId, ind, onChange)=>{
    return(
      <input
        value={val}
        style={{width: 50,}}
        type="number"
        onChange={e=>onChange("inp", valId, ind, e)}
      />
    )
  }
  
  onChange=(type,valId,ind,e)=>{//type, valId,ind,e
//     cl(type,valId,ind,e)
    globs.events.publish("savePageEnable",true)
    var vals
    switch(type){
      case "hold_delay":
        vals=valId
        break
      default:
        cl([type,valId,ind,e])
        vals={}
        vals[valId]=e.currentTarget.value
        break
    }
//     this.props.onChange(vals)
    this.setState(vals)
//     cl([a,b,c,d])
    
  }
  
  showTab1=()=>{
    let spOpts={0:{t:"None"}}
    for(let i=0;i<12;i++){spOpts[i+1]={t:`Pump ${1+i}`}}
    return(
      <table><tbody>
      <tr><td>
      {showSelect("supply", this.state.supplyPump, "supplyPump", -1, spOpts, this.onChange)}
      </td></tr>
      <tr><td>The supply pump will activate<br/> 
      when mixvalve opens past the<br/> 
      threshold set on the selected<br/> 
      pump's programming screen.
      </td></tr>
      </tbody></table>
    )
  }
  
  showTab2=()=>{
    return(
      <table><tbody>
      <tr><td>Setpoint</td></tr>
      <tr><td>
        <table><tbody>
        <tr><td>P</td><td>{showInput("fields",this.state.p,"p",-1,this.onChange)}</td></tr>
        <tr><td>I</td><td>{showInput("fields",this.state.i,"i",-1,this.onChange)}</td></tr>
        <tr><td>D</td><td>{showInput("fields",this.state.d,"d",-1,this.onChange)}</td></tr>
        </tbody></table>
      </td></tr>
      </tbody></table>
    )
  }
  
  showTab3=()=>{
    let so={}
    so[-1]={t:"None"}
    so[INPUTIDX_INTEMP]={t:"Inside Temperature"}
    so[INPUTIDX_OUTTEMP]={t:"Outside Temperature"}
    so[INPUTIDX_HUM]={t:"Relative Humidity"}
    so[INPUTIDX_CO2]={t:"CO2"}
    so[INPUTIDX_LITE]={t:"Outside Light"}
    so[INPUTIDX_WINSPD]={t:"Wind Speed"}
    so[INPUTIDX_WINDIR]={t:"Wind Direction"}
    so[INPUTIDX_BHEAT1]={t:"Analog Temperature 1"}
    so[INPUTIDX_BHEAT2]={t:"Analog Temperature 2"}
    so[INPUTIDX_BHEAT3]={t:"Analog Temperature 3"}
    so[INPUTIDX_BHEAT4]={t:"Analog Temperature 4"}
    so[INPUTIDX_BHEAT5]={t:"Analog Temperature 5"}
    so[INPUTIDX_RAIN]={t:"Rain"}
    let od={
      0:{t:"Greater Than"},
      1:{t:"Less Than"},
    }
    let oat={
      0:{t:"at least"},
      1:{t:"at most"},
    }
//     cl(this.state)
    return(
      <table><tbody>
      <tr><td>if the sensor 
        {showSelect("fields", this.state.sensor, "sensor", -1, so, this.onChange)}
      is</td></tr>
      <tr><td>
        {showSelect("fields", this.state.override_dir, "override_dir", -1, od, this.onChange)}
        {showInput("fields",this.state.override_trigger,"override_trigger",-1,this.onChange)}
      </td></tr>
      <tr><td>then limit 
        {showSelect("fields", this.state.override_action_target, "override_action_target", -1, oat, this.onChange)}
      to 
        {showInput("fields",this.state.override_limit,"override_limit",-1,this.onChange)}
      </td></tr>
      </tbody></table>
    )
  }
  
  showTab4=()=>{
    return(
      <table><tbody>
      <tr><td>
        <UsaDuration00
          value={this.state.hold_delay}
          valueId="hold_delay"
          onChange={v=>this.onChange("hold_delay",v)}
        />
      
      </td></tr>
      </tbody></table>
    )
  }
  
  showAdvanced=()=>{
    return(
      <table><tbody>
      <tr>
      <td>SUPPLY PUMP</td>
      <td>PID</td>
      <td>OVERRIDE</td>
      <td>HOLD POSITION DELAY</td>
      </tr>
      <tr>
        <td valign="top">{this.showTab1()}</td>
        <td valign="top">{this.showTab2()}</td>
        <td valign="top">{this.showTab3()}</td>
        <td valign="top">{this.showTab4()}</td></tr>
      </tbody></table>
    )
  }
  
  
  render(){
    return(
      <div>
      {this.showAdvanced()}
      </div>
    )
  }

}

export default PIDAdvancedSettings ;
