import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Button00 from './C18Button00'
import Select from 'react-select'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadUsersInfo,getUserIndex,
  privs,notifyHandlers} from '../../usa/components/C18utils'
import {wsTrans} from '../utils/utils';
import {cl,globs,getTime,constant,ymdToDate,dateToDisplayDate,getRandomString} from '../../components/utils/utils';

class C18SidebarTasks00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let nowStr= dateToDisplayDate(new Date(),"yyyy-mm-dd")
    this.state={
      mode:"sites",
      loaded:false,
      showNew:false,
      dueDate:nowStr,
      handlers:[],
      tags:[],
    }
    this.tagOptions=[
      {value: "equipment", label: "equipment"},
      {value: "sensors", label: "sensors"},
      {value: "plants", label: "plants"},
      {value: "pests", label: "pests"},
      {value: "nutrients", label: "nutrients"},
      {value: "power", label: "power"},
      {value: "personnel", label: "personnel"},
      {value: "supplies", label: "supplies"},
      {value: "maintenance", label: "maintenance"},
      {value: "link4", label: "Link4 Cloud"},
      {value: "other", label: "other"},
    ]
    this.loadInfo()
  }

  loadInfo=async()=>{
    await loadUsersInfo()
    let users={}
    globs.usersInfo.info.forEach(u=>{
      users[u.userId]=u
    })
    this.userOptions=globs.usersInfo.info.map(ui=>{
      return{value:ui.userId,label:ui.name}
    })
    var query
    let pa=this.props.parms
//     cl(pa)
    var tag
    switch(pa.level){
      case "sites":
        query={}
        break
      case "site":
        query={site:pa.siteId}
        break
      case "zone":
        query={zone:pa.zoneId}
        break
      case "config":
        tag=`${pa.pageType}-${pa.zuci}`
        query={zone:pa.zoneId,tags:{$in:[tag]}}
        break
    }
//     cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: query,})
    let tasks=this.sortTasks(res.data)
//     cl(tasks)
    this.setState({loaded:true,users:users,tasks:tasks})
  }

  sortTasks=(tasks)=>{
    return tasks.sort((a,b)=>{
      let af = a["status"]=="done"
      let bf = b["status"]=="done"
      if (af == bf) {
        af = a["dueDate"]
        bf = b["dueDate"]
      }
      if(af>bf){return 1}
      if(af<bf){return -1}
      return 0
    })
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let nowStr= dateToDisplayDate(new Date(),"yyyy-mm-dd")
    let st=this.state
    switch(type){
      case "addTask":
        vals.e.preventDefault()
        var newState
        if(st.showNew){
          newState={showNew:false}
        }else{
          newState={
            showNew:true,
            dueDate:nowStr,
            title:"",
            assignDate:nowStr,
            handlers:[],
            tags:[],
            taskId:getRandomString(16),
            editTaskId:-1,
          }
        }
        this.setState(newState)
        break
      case "handlers":
//         globs.events.publish("savePageEnable", true)
        let handlers=vals.map(ha=>{
          return ha.value
        })
        this.setState({handlers:handlers})
        break
      case "chartElements":
//         cl(this.state.tags)
//         cl(vals)
        this.setState({tags:this.setTags(vals)})
        break
      case "updComplete":
//         vals.task.complete=vals.complete
        let tasks=this.state.tasks.slice(0)
        for(let i=0;i<tasks.length;i++){
          let ta=tasks[i]
          if(ta.taskId==vals.taskId){
            ta.status=(vals.complete)?"done":"readyToStart"
//             ta.complete=vals.complete
            cl(ta)
            this.setTaskComplete(ta)
          }
        }
        tasks=this.sortTasks(tasks)
        cl(tasks)
        this.setState({tasks:tasks})
        break
      case "editEntry":
        let ta=this.state.tasks.filter(ta=>{return ta.taskId==vals.editTaskId})[0]
        cl(ta)
        Object.assign(vals,{title:ta.title,dueDate:ta.dueDate,handlers:ta.handlers,
          taskId:ta.taskId,tags:ta.tags,showNew:false})
        // resort tasks if due date changed
      case "title":
      case "upd":
        this.setState(vals)
        break
      case "saveTask":
        this.saveTask()
        break
      case "cancelTask":
        this.setState({showNew:false,editTaskId:-1})
      break
      case "deleteEntry":
        let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Task?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          this.deleteTask(vals.id)
        }
        break
    }
  }

  deleteTask=async(taskId)=>{
//     cl(`Delete ${taskId}`)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "delete",
      sessionId: globs.userData.session.sessionId, body: {taskId:taskId}})
    let tasks=this.state.tasks.slice(0)
    for(let i=0;i<tasks.length;i++){
      if(tasks[i].taskId==taskId){
        tasks.splice(i,1)
      }
    }
    this.setState({tasks:tasks})
  }

  setTaskComplete=async(ta)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update",
      sessionId: globs.userData.session.sessionId, body: {taskId:ta.taskId,status:ta.status},})
  }

  saveTask=async()=>{
// if there are multiple tasks, then add them all, and return to TaskList
//     cl(this.state)
//     cl(this.props)
    let pa=this.props.parms
//     let multi=this.state.tasks.filter(ta=>{return ta.select})
//     if(multi.length){return this.saveMultipleTasks(multi)}
    let st=this.state
    let tags=this.state.tags.slice(0)
    if(pa.level=="config"){
      let tag=`${pa.pageType}-${pa.zuci}`
      if(!tags.includes(tag)){tags.push(tag)}
    }
    let task={
      title:st.title,
//       body:st.body,
//       images:st.images,
      handlers:st.handlers,
      scope:pa.level,
      pageType:pa.pageType,
      zuci:pa.zuci,
//       scope:st.scope,
      site:pa.siteId,//st.site,
      zone:pa.zoneId,//st.zone,
      assignDate:st.assignDate,
      assignUserId:globs.userData.session.userId,
      dueDate:st.dueDate,
//       estimatedHours:+st.estimatedHours+8*st.estimatedDays,
//       priority:st.priority,
//       category:st.category,
      status:st.status,
      tags:tags,
      taskId:st.taskId,
      title:st.title,
    }
    notifyHandlers(task,this.props.parms)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update",
      sessionId: globs.userData.session.sessionId, body: task,})
    let tasks=this.state.tasks.slice(0)
    let ta=tasks.filter(ta=>{return ta.taskId==task.taskId})[0]
    if(ta){
//       cl("updating task")
      Object.assign(ta,task)
      this.sortTasks(tasks)
    }else{
//       cl("making new task")
      tasks.push(task)
      this.sortTasks(tasks)
    }
    this.setState({tasks:tasks,showNew:false,editTaskId:-1})
  }

  showTags=(tags)=>{
    if(!tags){return null}
    let tags2=tags.slice(0)
//     cl(this.props.parms)
    let pa=this.props.parms
    let myTag=`${pa.pageType}-${pa.zuci}`
    if(!["zone","config"].includes(pa.level)/*this.props.parms.level!="zone"*/){
      let zInd=getZoneIndex(pa?.entry?.zoneId)
      if(zInd){
        tags2.push(globs.zonesInfo.info[zInd].zoneName)
      }
    }
    return tags2.map((t,i)=>{
      if(t!=myTag){
        return(
            <span id="/sidebar/tasks/tags" key={i} className={`grow-journal-tag ${t}`}>{t}</span>
        )
      }
    })
  }

  showDeleteEntry=(type,id)=>{
//     cl(globs.privsInfo.info)
//     cl(privs("account",0,constant.AREA_PRIVS_ADMIN))
    if(privs("account",0,constant.AREA_PRIVS_ADMIN)){
      return(
        <button id="/sidebar/growJournalEntry/delete" type="button" className="material-icons trash" aria-label="delete entry"
          onClick={o=>this.onChange("deleteEntry",{type:type,id:id})}>
          delete_outline
        </button>
      )
    }
  }

  showEditEntry=(ta)=>{
    return(
      <button id="/sidebar/growJournalEntry/edit" type="button" className="material-icons-outlined add" aria-label="add comment"
        onClick={o=>this.onChange("editEntry",{editTaskId:ta.taskId})} title="Edit, actually">
        edit
      </button>
    )
  }

  showTaskEdit=(ta,i)=>{
//     cl(ta)
    return this.showTaskNew(i,true)
  }

  showTask=(ta,i)=>{
//     cl(ta)
    if(ta.taskId==this.state.editTaskId){return this.showTaskEdit(ta,i)}
//     cl(ta)
    let st=this.state
    let handlerId=(ta.handlers||[])[0]||"None"
    let user=st.users[handlerId]||{}
    let av=user.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    var da2="None"
    var overdue
    if(ta.dueDate){
      let tzo=(new Date).getTimezoneOffset()
//       let da=ymdToDate(ta.dueDate)
      let da=60*tzo+Date.parse(ta.dueDate)/1000//.getTime()
//       cl(da)
      
      da2=dateToDisplayDate(new Date(1000*da),"mm/dd/yyyy")
      let now=getTime()
//       cl(now,da)
      overdue=now>(da+86400)
    }
    let gjEntry={}
    if((!st.showComplete)&&(ta.status=="done")){return}
//         {this.showTags(ta.tags)}
    return(
      <div key={i} className="info-section">
        <div className="floatleft" style={{verticalAlign:"top"}}>
          <span>
            <input type="checkbox" id=""
            checked={ta.status=="done"}
            onChange={e=>this.onChange("updComplete",{taskId:ta.taskId,
              complete:e.currentTarget.checked})}
            />
          </span>
        {ta.title}
        <br></br>
        <div style={{marginTop: "8px"}}>{this.showTags(ta.tags)}</div>
      </div>
        
        <div className="floatright time-date" style={{verticalAlign:"top",marginTop:0}}>
          <span className="date" style={{color:(overdue)?"red":null}}>{da2}</span>
          {this.showDeleteEntry("main",ta.taskId)}

          {this.showEditEntry(ta)}
          <div>
            <img src={path} alt={user.name}/><span>{user.name}</span>
          </div>
        </div>


        <div className="clearfloat"></div>
        <br /><hr /><br />
      </div>
    )
  }

  showTaskHead=()=>{
    return(
      <div className="info-section">
        <div className="right-checkbox floatleft top-margin-small">
          <label htmlFor="">Show Completed</label><input type="checkbox" id=""
          checked={this.state.showComplete||false}
          onChange={e=>this.onChange("upd",{showComplete:e.currentTarget.checked})}/>
        </div>
        <C18Anchor00 to="" className="colored-link-text floatright material-icons-outlined add" aria-label="add task"
        onClick={e=>this.onChange("addTask",{e:e})}
        >add</C18Anchor00>
      </div>
    )
  }

  setTags=(vals)=>{
    let standards=this.tagOptions.map(to=>{return to.value})
//     cl(standards)
    let adds=this.state.tags.filter(ta=>{return !standards.includes(ta)})
//     cl(adds)
    let tags=[]
    vals.forEach(v=>{
      tags.push(v.value)
    })
//     cl(this.state.tags)
//     cl(tags.concat(adds))
    return tags.concat(adds)
  }

  getTagOption=(value)=>{
    for(let i=0;i<this.tagOptions.length;i++){
      let o=this.tagOptions[i]
//       cl([o.value,value])
      if(o.value==value)return o
    }
  }

  makeChartElements=()=>{
    let elements=[]
//     cl(this.state.tags)
    this.state.tags.forEach(t=>{
      elements.push(this.getTagOption(t))
    })
//     cl(elements)
    return elements
  }

  makeHandlerList=()=>{
    return this.state.handlers.map(ha=>{
      let user=globs.usersInfo.info[getUserIndex(ha)]
      return {value:user.userId,label:`${user.name}-${user.email}`}
    })
  }

  showTaskNew=(key,editFlag)=>{
    let p={}
    let addDisable=false
    let addSave=(editFlag)?"Save":"Add"
    return(
      <div key={key} className="chart-elements-container">
          <div className="clearfloat"></div>
          <label htmlFor="">{(p.edit)?"Edit":"Add"} Task</label>
          <textarea id="/sidebar/tasks/header/title"
            className="fullwidth"
            value={this.state.title}
            onChange={e=>this.onChange("title",{title:e.currentTarget.value})}
            ></textarea>
          <br />
          <label htmlFor="dueDate">Due Date</label>
          <input id="dueDate" type="date"
            value={this.state.dueDate}
            onChange={e=>this.onChange("upd",{dueDate:e.currentTarget.value})}
          />
          <div className="clearfloat"></div><br/>
          <div className="fullwidth">
            <label htmlFor="handlers">Assignees</label>
            <Select
              id="handlers"
              value={this.makeHandlerList()}
              onChange={o=>{this.onChange("handlers",o)}}
              options={this.userOptions}
              isMulti={true}
            />
          </div>
          <div className="clearfloat"></div><br/>
          <label htmlFor="">Add Tags</label>
          <Select id="/sidebar/growJournal/header/tags"
            className="floatleft grow-journal-select-tags with-right-button"
            value={this.makeChartElements()}
            onChange={o=>{this.onChange("chartElements",o)}}
            options={this.tagOptions}
            isMulti={true}
          />
          <C18Button00 type="button" className="filled" disabled={addDisable}
          style={{marginLeft:10}}
          onClick={()=>this.onChange("saveTask",{})}>{addSave}</C18Button00>

          <C18Button00 type="button" className="outlined"
          style={{marginLeft:10}}
          onClick={()=>this.onChange("cancelTask",{})}>Cancel</C18Button00>
          <div className="clearfloat"></div>

          <br /><hr /><br />
      </div>
    )
  }
  
  render(){
    let st=this.state
//     cl(st)
    if(this.state.loaded){
//         <div className="center">
//           <a href="" className="colored-link-text large-text">See All</a>
//         </div>
    return(
      <div id="sidebar-tasks">
      <div className="clearfloat"></div>
      <br /><hr /><br />
      {this.showTaskHead()}
      {st.showNew&&
        this.showTaskNew(0,false)
      }
      <div className="clearfloat"></div>
      <br /><hr /><br />
      {this.state.tasks.map((ta,i)=>{return this.showTask(ta,i)})}


      </div>

      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18SidebarTasks00;
