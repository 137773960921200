import React from 'react';
import UsaIcon from '../../usa/components/UsaIcon';
import C18Button00 from '../../usa/components/C18Button00';
import C18Input00 from '../../usa/components/C18Input00';
import {acctFeature} from '../../usa/components/C18utils'
import {wsTrans,doGetPostBasic} from '../../usa/utils/utils';
import {cl, constant,globs,getRandomString} from '../../components/utils/utils';

class ShowImage extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      showBrowse:false,
      images:[],
    }
    this.subscribe_fuiImage=globs.events.subscribe("fuiImage",this.fuiImage)    
    this.props.getValue(props.ind, {type: "local"})
    this.loadInfo()
//       <div dangerouslySetInnerHTML={{__html: this.props.custom.text}}></div>
  }
  
  componentWillUnmount=()=>{
    this.subscribe_fuiImage.remove()
  }
  
  fuiImage=()=>{
    cl("fuiImage")
    this.setState({showBrowse:true})
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "cancel":
        this.setState(vals)
        break
    }
  }
  
  makePath=(id)=>{
    return `${constant.expressUrl}/usa/images/uploads/${id[0]}/${id[1]}/${id[2]}/\
${id.substring(3)}`
  }
  
  loadInfo=async()=>{
    let pr=this.props
    let query={
      siteId:globs.userData.session.siteId,
      type:pr.type,
      zuci:pr.zuci,
    }
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiImages", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: query})
//     cl(resp)
    var path
//     cl(resp)
    if(resp.data){
//       let id=resp.data.id
//       path=`${constant.expressUrl}/usa/images/uploads/${id[0]}/${id[1]}/${id[2]}/\
// ${id.substring(3)}`
      path=this.makePath(resp.data.id)
    }else{
      path=`${constant.expressUrl}/usa/images/defaultFuiImage.jpg`
    }
//     cl(path)
    this.setState({loaded:true,path:path})
    
  }
  
  showImageBrowse=()=>{
    let st=this.state
    let disp=(st.showBrowse)?"block":"none"
    return(
      <div className="image-popup" style={{
        backgroundColor:"#FFFFFF",
//         width:500,
        display:disp,
        borderRadius:10,
        padding:"0px 20px 20px 20px",
        boxShadow:"0px 4px 24px rgba(0, 0, 0, 0.16)",
        textAlign:"left",
      }}>
        <h3>Browse for Image</h3>
          <C18Button00 type="button" className="filled" aria-label="upload">
            Upload File</C18Button00>
          <C18Button00 type="button" className="filled" aria-label="upload"
            style={{marginLeft:20}}
            onClick={e=>this.onChange("cancel",{showBrowse:false})}>Cancel</C18Button00>
          <form className="file-upload-form">
          <C18Input00 id="/sidebar/growJournal/header/attach" type="file"  onChange={this.markImage} style={{
            position:"absolute", 
            width:108, 
            height:40, 
            marginTop:0, 
//            cursor:"pointer",
//             marginLeft:-41,
            zIndex:10,                   
            opacity:0,
//             cursor: "pointer",
          }}/>
          </form>
      </div>
    )
  }
  
  saveFuiImage=async(id)=>{
    cl(this.props)
    let pr=this.props
    let query={siteId:globs.userData.session.siteId,type:pr.type,zuci:pr.zuci,
      id:id
    }
    cl(query)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/fuiImages", method: "update", 
      sessionId: globs.userData.session.sessionId, body: query})
  }
  
/****************** Image Handling *******************************/
  sendImages=async(images)=>{
    let data = new FormData()
    let base=0//this.state.gotImageCount
    for(let i=base;i<images.length;i++){
      let img=images[i]
      data.append(`id${i-base}`, img.id)
      data.append(`file${i-base}`, img.image)
      data.append('type',"fuiImage")
    }
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    let res=await doGetPostBasic(url, method, data, type)// pro  mise
    cl(res)
    return res
  }
  
  markImage=async(e)=>{
    let images=this.state.images.slice(0)
    let len0=images.length
    let imagesStr=""
    for(let i=0;i<e.target.files.length;i++){
      let name=e.target.files[i].name
      let ext=name.substring(name.lastIndexOf("."))
      images.push({id: getRandomString(16)+ext, image: e.target.files[i]/*e.target.files[0]*/})
      imagesStr+=`[Img${len0+i+1}]`
    }
    cl(images)// images[0].id has the image id
    await this.sendImages(images)
    await this.saveFuiImage(images[0].id)
    let path=this.makePath(images[0].id)
    cl(path)
    this.setState({images: images,showBrowse:false,path:path
    })//`{Img:${expImage.id}}`
  }
/****************** End Image Handling *******************************/
  

  render(){
//     cl(this.props)
//     cl(this.state)
    if(!acctFeature("equipmentImage")){return null}
    let st=this.state
    let cust=this.props.custom
    if(cust.image){
      let path=`${constant.expressUrl}/usa/images/uploads/${cust.image}`
//       cl(path)
      let className=(cust.floatRight)?"floatright":""
      return(
        <div className={className}>
        <img id={`{"cmd":"fuiImage","type":0}`} src={st.path} width={cust.width}/>
        {this.showImageBrowse()}
        </div>
      );
    }else{
      return null
    }
  }
}

  export default ShowImage ;
