 
import React from 'react';
// import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
// import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
// import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
// import C18MuiSelect from '../../usa/components/C18MuiSelect'
import {getParamId} from '../../usa/utils/utils';
import {dbVals,putZValue,sendArray} from '../../components/utils/http'
import {az} from '../../components/utils/utils'
import {HSVtoRGB,makeColor} from '../../usa/components/C18utils'
import {pInd} from '../../components/utils/paramIds'
import * as d3 from 'd3';
import {cl, globs,getTimeI} from '../../components/utils/utils';

class Setpoints extends React.Component{
  constructor(props) {
    super(props);
    this.svgDiv=React.createRef();
//     this.w=700
    this.h=300
    this.setpoints=[
      {time:360,heat:50,cool:70,hum:30,dehum:40},
      {time:420,heat:55,cool:75,hum:35,dehum:45},
      {time:480,heat:60,cool:80,hum:40,dehum:50},
      {time:540,heat:65,cool:85,hum:45,dehum:55},
    ]
    this.state={
      tempUnit:(["F","C"][props.current.units[0]])
    }
    this.graphPath={}
    this.graphColors=["#0000FF","#FF0000","#AAAA00","#00AAAA"]
    this.textColor = '';
    if (globs.device?.deviceTheme=="originalDark") {
      this.textColor = 'black'
    }
    this.loadInfo()
//     cl(props)
//     let col2=this.makeColor("#224466","#666666",0.5)
//     cl(col2)
//     cl(props)
//     this.updateFui()
//     this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
  }
  
  savePageEvent=()=>{
//     this.changed=false
    this.saveSetpointData()
  }

  componentWillUnmount=()=>{
//     this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }
  
  saveSetpointData=()=>{
    let parms=this.getSaveSetpoints("save")
    sendArray(parms,this.props.current.virtual).then(e=>{globs.events.publish("saveOK",true)})
//     cl(parms)
//     sendArray(parms).then(r=>{globs.events.publish("saveOK",true)})
  }

  getSaveSetpoints=(getSave)=>{
    let z=this.zuci[0]
    let tab=pInd[1800].config_setpoints
    let mult=tab[2]
    let setpoints=[]// used for both get and save
    let fields=["name","enabled","startTimeOfDay","astroAdjust","rampMinutes","heatSetpoint","coolSetpoint",
      "humidifySetpoint","dehumidifySetpoint"]
    let now=getTimeI()
    for(let i=0;i<8;i++){
      if(getSave=="get"){
        let val={}
        fields.forEach(f=>{ val[f]=dbVals.z[z][255][mult*i+getParamId("configuration_setpoints",f)] })
        val.enabled=+(val.enabled||0)
        setpoints.push(val)
        cl(setpoints)
      }else{
//         return
        fields.forEach(f=>{ 
          let val=this.state.setpoints[i]
//           val[f]=dbVals.z[z][255][mult*i+getParamId("configuration_setpoints",f)]
          let pid=mult*i+getParamId("configuration_setpoints",f)
          if (putZValue(z,255,pid,val[f])){
            setpoints.push({
              c:255,// zone wide
              d:val[f],
              f:1,
              i:pid,
              t:now,
              z:z,
            })
          }
        })
      }
    }
    return setpoints
  }
  
  saveSetpoints=()=>{
    
  }
  
  loadInfo=()=>{
    cl(this.props.current)
    this.zuci=this.props.zuci.split("-")
    this.state.setpoints=this.getSaveSetpoints("get")
    
  }
  
  componentDidMount=()=>{// called after constructor
    this.mounted=true
//     cl(this.svgDiv.current)
    this.svg=this.svgDiv.current
    this.w=this.svg.offsetWidth
    this.makeGraph()
    this.redrawGraph()
  }
  
  minToHM=(mins)=>{
    let h=Math.floor(mins/60)
    let m=mins%60
    return `${az(h,2)}:${az(m,2)}`
  }
  
  makeGraph=()=>{
    
  var margin = {top: 10, right: 30, bottom: 30, left: 40},
      width = this.w - margin.left - margin.right,
      height = this.h - margin.top - margin.bottom;
    cl("make graph")
    cl(this.svg)
    let svg2=d3.select(this.svg).append("svg")
    cl(svg2)
    let svg3=svg2
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    svg3
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
    cl(svg2)
    this.graph=svg3
    this.x=d3.scaleLinear()// set x scale
      .domain([0,1440])
      .range([0,width])
    this.y = d3.scaleLinear()
      .domain([0,100]) // input 
      .range([height, 0]); // output 
//     let data=[[100,20],[200,30]]
    this.xAxis=d3.axisBottom(this.x)
      .tickValues([...Array(8).keys()].map(x=>{return(180*x)}))
//       .tickFormat(d=>d3.timeFormat("%H-%M-%S")(60000*d))
      .tickFormat(this.minToHM)
//       .tickValues([0,700,1400].map(v=>{return v}))
    
    let svgx=svg3.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate("+0+"," + height + ")")
      .attr("opacity",1)
      .call(this.xAxis)
      
// o.xAxis_woy = d3
// .axisBottom(o.x)
// .ticks(3)
// .tickFormat(d3.timeFormat("%y-%b-%d"))
// .tickValues(this.sensors[0].data.map(d=>d.t));      
      
    this.yAxis=d3.axisLeft(this.y)
    let svgy=svg3.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate("+0+"," + 0 + ")")
      .attr("opacity",1)
      .call(this.yAxis)
//         .tickValues(tv.t)
//         .tickFormat(d=>{return tv.s[d]})
//         .tickSize(tickWidth)
      
    this.graphLine=d3.line()
      .x((d, i)=> { return this.x(d.t); }) // set the x values for the line generator
      .y((d)=> { return this.y(d.v); }) // set the y values for the line generator 
//     let path=svg3.append("path")
//     let data=[
//     {t:100,v:20},
//     {t:200,v:30},
//     ]
//     path.datum(data)
//       .attr("class", "line")
//       .attr("fill", "none")
//       .attr("stroke", "#000000")
//       .attr("stroke-width", 3)
//       .attr("d", line) // 11. Calls the line generator 
    
  }
  
//   d3Stuff=()=>{
// // set the dimensions and margins of the graph
// var margin = {top: 10, right: 30, bottom: 30, left: 60},
//     width = this.w - margin.left - margin.right,
//     height = this.h - margin.top - margin.bottom;
// 
// // append the svg object to the body of the page
// var svg = d3.select("#my_dataviz")
//   .append("svg")
//     .attr("width", width + margin.left + margin.right)
//     .attr("height", height + margin.top + margin.bottom)
//   .append("g")
//     .attr("transform",
//           "translate(" + margin.left + "," + margin.top + ")");
// 
// //Read the data
// d3.csv("https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/3_TwoNumOrdered_comma.csv",
// 
//   // When reading the csv, I must format variables:
//   function(d){
//     return { date : d3.timeParse("%Y-%m-%d")(d.date), value : d.value }
//   },
// 
//   // Now I can use this dataset:
//   function(data) {
// 
//     // Add X axis --> it is a date format
//     var x = d3.scaleTime()
//       .domain(d3.extent(data, function(d) { return d.date; }))
//       .range([ 0, width ]);
//     svg.append("g")
//       .attr("transform", "translate(0," + height + ")")
//       .call(d3.axisBottom(x));
// 
//     // Add Y axis
//     var y = d3.scaleLinear()
//       .domain([0, d3.max(data, function(d) { return +d.value; })])
//       .range([ height, 0 ]);
//     svg.append("g")
//       .call(d3.axisLeft(y));
// 
//     // Add the line
//     svg.append("path")
//       .datum(data)
//       .attr("fill", "none")
//       .attr("stroke", "steelblue")
//       .attr("stroke-width", 1.5)
//       .attr("d", d3.line()
//         .x(function(d) { return x(d.date) })
//         .y(function(d) { return y(d.value) })
//         )
//   })
//     
//   }
  
  showSetpointGraph=()=>{
    return(
      <div ref={this.svgDiv} style={{width:"calc(100% - 0px)",
        height:this.h,backgroundColor:"#EEEEEE",color:this.textColor}}>
      </div>
    )
  }
  
  redrawGraph=()=>{
    let drawPath=(ind,color)=>{
//       cl(graphPoints)
      let data=graphPoints[0].map((gp,i)=>{
//         cl(gp)
        return( {t:graphPoints[0][i],v:graphPoints[ind][i]} )
      })
//       cl(data)
      if(this.graphPath[ind]){this.graphPath[ind].remove()}
      let path=this.graph.append("path")
      path.datum(data)
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 3)
        .attr("d", this.graphLine) // 11. Calls the line generator 
      this.graphPath[ind]=path
    }
    
    let sp=this.state.setpoints.slice(0)
    sp.sort((a,b)=>{
      if(a.startTimeOfDay>b.startTimeOfDay){return 1}
      if(a.startTimeOfDay<b.startTimeOfDay){return -1}
      return 0
    })
    let graphPoints=[[],[],[],[],[]]
    let sp2=sp.filter(s=>{return s.enabled})
    let spLast=sp2[sp2.length-1]
//     cl(spLast)
    sp2.unshift(Object.assign({},spLast))
    sp2.push(Object.assign({},spLast))
    sp2[0].startTimeOfDay=0
    sp2[sp2.length-1].startTimeOfDay=1440
    let sp3=[]
    sp2.forEach((sp,i)=>{
      if((i>0)&&(i<sp2.length-1)){
        sp3.push(Object.assign({},sp2[i-1],{startTimeOfDay:sp.startTimeOfDay-sp.rampMinutes}))
//         cl(sp)
      }
      sp3.push(sp)
    })
//     cl(sp3)
    sp3.map(s=>{
      graphPoints[0].push(s.startTimeOfDay)
      graphPoints[1].push(s.heatSetpoint)
      graphPoints[2].push(s.coolSetpoint)
      graphPoints[3].push(s.humidifySetpoint)
      graphPoints[4].push(s.dehumidifySetpoint)
    })
    for(let i=0;i<4;i++){drawPath(i+1,this.graphColors[i])}
//     drawPath(1,"#FF0000")
    
//     cl(graphPoints)
    
    
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    globs.events.publish("savePageEnable",true)
    if(vals.type=="time"){
      cl(vals.val)
      let parts=vals.val.split(":")
      vals.val=60*(+parts[0])+(+parts[1])
    }
    let sp=this.state.setpoints.slice(0)
    switch(type){
      case "sp":
        sp[vals.i][vals.col]=vals.val
        break
      
    }
    this.redrawGraph()
    this.setState({setpoints:sp})
  }
  
//   makeColor=(h1,h2,frac,s,v)=>{
//     var d2h=(dec)=>{
//       return `00${dec.toString(16)}`.slice(-2)
//     }
//     let h=h1+frac*(h2-h1)
//     let rgb=HSVtoRGB(h,s,v)
//     return `#${d2h(rgb.r)}${d2h(rgb.g)}${d2h(rgb.b)}`
//   }
  
  showSetpointInput=(i,key,col,type)=>{
    let st=this.state
    var keyType=`${i}_${key}`
//     var op=(st.mo==keyType)?0:1
    var unit=null
//     var unit=null
    // needs to distinguish between C and F
    if((key>2)&&(key<5))
      unit=<span style={{cursor:"default", position:'absolute', marginLeft: '-52%', top:'12px',color:this.textColor}}>&deg;{st.tempUnit}</span>
    if((key>4)&&(key<7))
      unit=<span style={{cursor:"default", position:'absolute', marginLeft: '-52%', top:'12px',color:this.textColor}}>%</span>
    if((key>6)&&(key<8))
      unit=<span style={{cursor:"default", position:'absolute', marginLeft: '-52%', top:'12px',color:this.textColor}}>mins</span>
    if(st.mo==keyType){unit=null
//       unit=<span style={{marginLeft:-30}}></span>
      
    }
//     let unit=(
//       <span style={{marginLeft:-20}}>&deg;F</span>
//     )
//     cl(this.state.setpoints[i])
    var color="#FFFFFF"
//     let colors=["#CCEEFF","#CCCCFF","#CCCCFF","#CCCCFF","#CCCCFF","#CCCCFF","#CCCCFF","#FFCCCC"]
    let ri=this.state.setpoints[i]
    let width={checkbox:50,number:100,text:100}
    let value=ri[col]
    if(type=="time"){
      value=this.minToHM(value)
    }
    if(["heatSetpoint","coolSetpoint"].includes(col)){
      let frac=(value-50)/30
      if(frac<0){frac=0}
      if(frac>1){frac=1}
      let h1=0.6
      let h2=1
      color=makeColor(h1,h2,frac,0.4,1)
    }
    if(["humidifySetpoint","dehumidifySetpoint"].includes(col)){
      let frac=(value-20)/60
      if(frac<0){frac=0}
      if(frac>1){frac=1}
      let h1=0.167
      let h2=0.600
      color=makeColor(h1,h2,frac,0.4,1)
    }
    switch(type){
      case "astro":
        return(
          <td key={key}>
            <select style={{padding:10,borderStyle:"solid",borderWidth:1,borderRadius:10}}
            value={value}
            onChange={e=>this.onChange("sp",{i:i,col:col,type:type,val:e.currentTarget.value})}
            >
            <option value="0">None</option>
            <option value="1">Sunrise</option>
            <option value="2">Sunset</option>
            </select>
          </td>
        )
      case "checkbox":
        return(
          <td key={key}>
            <input
              type={type}
              style={{width:width[type],backgroundColor:color}}
              checked={value}
              onChange={e=>this.onChange("sp",{i:i,col:col,type:type,val:e.currentTarget.checked})}
            />
          </td>
        )
      default:
        return(
          <td key={key} style={{position:'relative'}}>
            <input
              type={type}
              style={{width:width[type],backgroundColor:color,margin:0,zIndex:10,color:this.textColor}}
              value={value}
              onChange={e=>this.onChange("sp",{i:i,col:col,type:type,val:e.currentTarget.value})}
              onMouseOut={e=>{
                this.setState({mo:null})
//                 this.svg.focus()
              }}
            />
            {unit}
          </td>
        )
    }
//     if(type=="astro"){
//     }else{
//     }
  }
  
  showSetpointHeader=()=>{
    let style0={fontSize:16,fontWeight:700,textAlign:"center"}
    var makeStyle=(ind)=>{
      return Object.assign({color:this.graphColors[ind]},style0)
    }
    return(
      <tr>
      <th style={style0}>Enable</th>
      <th style={style0}>Name</th>
      <th style={style0}>Start<br/>Time</th>
      <th style={makeStyle(0)}>Heat<br/>Setpoint</th>
      <th style={makeStyle(1)}>Cool<br/>Setpoint</th>
      <th style={makeStyle(2)}>Hum<br/>Setpoint</th>
      <th style={makeStyle(3)}>DeHum<br/>Setpoint</th>
      <th style={style0}>Ramp<br/>Time</th>
      <th style={style0}>Astro<br/>Adjust</th>
      </tr>
    )
  }
  
  showSetpointRow=(i)=>{
    return [
      this.showSetpointInput(i,0,"enabled","checkbox"),
      this.showSetpointInput(i,1,"name","text"),
      this.showSetpointInput(i,2,"startTimeOfDay","time"),
      this.showSetpointInput(i,3,"heatSetpoint","number"),
      this.showSetpointInput(i,4,"coolSetpoint","number"),
      this.showSetpointInput(i,5,"humidifySetpoint","number"),
      this.showSetpointInput(i,6,"dehumidifySetpoint","number"),
      this.showSetpointInput(i,7,"rampMinutes","number"),
      this.showSetpointInput(i,8,"astroAdjust","astro"),
    ]
//     cl(this.state.setpoints[i])
//     let ri=this.state.setpoints[i]
//     return(
//       <td>
//       <input 
//         type="number"
//         style={{width:70}}
//         value={ri.heatSetpoint}
//         onChange={e=>this.onChange("sp",{i:i,col:"heatSetpoint",val:e.target.value})}
//         
//         />
//       </td>
//     )
  }
  
  showSetpointTable=()=>{
    let rows=[...Array(8).keys()].map(i=>{
//       cl(i)
      return(
        <tr key={i} style={{textAlign:"center"}}>
        {this.showSetpointRow(i)}
        </tr>
      )
    })
    return(
      <div>
      <table><tbody>
      {this.showSetpointHeader()}
      {rows}
      </tbody></table>
      </div>
    )
  }


  render(){
    return(
      <div style={{overflowX:"auto"}}>
        {this.showSetpointGraph()}
        <br/>
        {this.showSetpointTable()}
      </div>
    )
  }
}

export default Setpoints ;
