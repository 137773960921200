import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import {cl, globs} from '../../components/utils/utils';

class FUISelect extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.props.getValue(props.ind, {type: "scalar"})
  }

  makeInputMappingSelect=()=>{
    if(!this.props.custom.options){
      cl("FUI Select Control is missing Options")
      return
    }
    return this.props.custom.options.map((c, i)=>{
      return(
        <C18MuiMenuItem key={i} value={c.v}>{c.t}</C18MuiMenuItem>
      )
    });
  }

  onChange=(e)=>{
//     cl(e.target.value)
    this.props.onChange(this.props.ind, {value: e.target.value})
  }

  render(){
//     cl(this.props)
    let style={}
    let style2={minWidth: 150}
    if(this.props.cont.width){
      style.width=this.props.cont.width
      style2.minWidth=this.props.cont.width
    }
    if(this.props.cont.inline){
      style.display="inline"
    }
    let val=this.props.value
    if(Array.isArray(val)){val=0}
    return(
      <div id={this.props?.custom?.infoId}>
        <C18MuiFormControl
        style={style2}
        >
          <C18MuiInputLabel>{this.props.cont.title}</C18MuiInputLabel>
          <C18MuiSelect
            value={val}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            break={this.props?.custom?.break}
            >
            {this.makeInputMappingSelect()}
          </C18MuiSelect>
        </C18MuiFormControl>
      </div>
    );
  }

}


  export default FUISelect ;
