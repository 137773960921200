import React from 'react';
import {cl,globs} from '../../components/utils/utils';

class StageOnOff extends React.Component{
  constructor(props) {
    super(props);
//     cl(props.current)
//     cl(props)
//     this.props.getValue(props.ind, {type: "array", count: 13})
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
//     cl(this.props.value)
  }

  updateFui=()=>{
    if(!this.changed){
      this.props.getValue(this.props.ind, {type: "array", count: 13})
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
//     this.subscribe_savePageEvent.remove()
  }
    
  onChange=(ind)=>{
    let stageStatus=this.props.value
//     cl(Array.isArray(stageStatus))
//     cl(stageStatus.length)
//     cl(!(stageStatus.length||0))
    if(!Array.isArray(stageStatus)){stageStatus=[0,0,0,0,0,0,0,0,0,0,0,0,0]}
    cl(stageStatus)
    stageStatus[ind]=(+stageStatus[ind])?0:1
    this.setState({stageStatus: stageStatus})
//     cl(stageStatus)
    this.props.onChange(this.props.ind, {value: stageStatus})
  }
  
  showStageButtons=()=>{
//     cl("show")
    let hs=this.props.current.heatStages
    let cs=this.props.current.coolStages
    if(hs==undefined){hs=6}
    if(cs==undefined){cs=6}
    // stage colors
    let colors=["#eb5555","#db5555","#cb5555","#bb5555","#ab5555","#ab7855",
      "#ababab","#55ab55","#55ab78","#55ab9a","#559aab","#5588cb","#5570eb"]
    let base=6-hs
    let col=colors[base]
//     cl(hs,cs)
    let head=[]
    let state=[]
    let vals=this.props.value
// //     cl(vals)
    if(!vals.length){vals=[0,0,0,0,0,0,0,0,0,0,0,0,0]}
    for(let i=0-hs; i<=cs; i++){
      let col=colors[base+(i+hs)]
      let name=(i<0)?`Heat${0-i}`:((i>0)?`Cool${i}`:`Normal`)
      let st=(+(this.props.value[i+6]||0))?"ON":"OFF"
      head.push(<th key={i}>{name}</th>)
//       cl(st)
      state.push(
        <td key={i}>
        
        <button
            style={{backgroundColor:col,color:(st)?"white":"black",
                opacity:(st)?1.0:0.2
              }}
            className={`date toggle ${(+this.props.value[i+6])?"selected":""}`}
            onClick={e=>{this.onChange(6+i)}}
        >
            {st}
        </button>
      </td>
        
      )
    }
    return(
      <table><tbody>
      <tr>{head}</tr>
      <tr>{state}</tr>
      </tbody></table>
    )
  }

  render(){
//     cl(this.props.value)
//     cl("render stage on/off")
    return(
      <div className="stage-on-off">
      {this.showStageButtons()}
      <div className="clearfloat"></div><br/>
      
      </div>
    );
  }

}

export default StageOnOff ;
