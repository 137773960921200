// import {cl} from './utils';

/*I don't think we're going to try to duplicate
the lc1800 database here. Instead, we'll come up
with a new interface for dealing with finding
values*/

/*table ids:
snaps		pb[1800].snapshots = 0 // 1-81
snapChans	pb[1800].snapshot_channels = 0 // 101-120
snapEcph	pb[1800].snapshot_ecphs = 0 // 201-235
snapChanData	pb[1800].snapshot_chan_Snapshot = 0 // 401-448
confChan	pb[1800].channels_configuration = 0 // 501-520
confChanData	pb[1800].config_channels_configuration = 0 // 600-796
snapAuxAlarms	pb[1800].snapshot_aux_alarms = p.PID_BASE_AUX_ALARMS; // 1024
snapAuxPVars	pb[1800].snapshot_aux_persistent_variables = p.PID_BASE_AUX_PERSISTENT_VARIABLE;//1056
snapAuxVars	pb[1800].snapshot_aux_variables = p.PID_BASE_AUX_VARIABLE;//1088
snapXBoards	pb[1800].snapshot_expansion_boards = p.PID_BASE_EXPANSION_BOARDS;//1152
confAuxAlarms	pb[1800].config_aux_alarms = p.PID_BASE_CONFIG_AUX_ALARMS;//1216 4 * 32
confAuxConts	pb[1800].config_aux_controls = p.PID_BASE_CONFIG_AUX_CONTROLS;//1344 17 * 128
confAuxPVars	pb[1800].config_aux_persistent_variables = p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES;//3520 4 * 32
convAuxVars	pb[1800].config_aux_variables = p.PID_BASE_CONFIG_AUX_VARIABLES;//3640 4 * 64
convXBoards	pb[1800].config_expansion_boards = p.PID_BASE_CONFIG_EXPANSION_BOARDS;//3896 5 * 64
confContSets	pb[1800].controller_configuration_settings = p.PID_BASE_CONFIG_CONTROLLER_SETTINGS;//4216
confEcph	pb[1800].config_ecph = p.PID_BASE_CONFIG_ECPH;//4506
confEcphSens	pb[1800].config_ecph_sensors = p.PID_BASE_CONFIG_ECPH_SENSORS;//4517
confSetpoints	pb[1800].config_setpoints = p.PID_BASE_CONFIG_SETPOINTS;//4586
confZoneSets	pb[1800].zone_configuration_settings = p.PID_BASE_CONFIG_ZONE_SETTINGS;//4666
confZones	pb[1800].config_zones = p.PID_BASE_CONFIG_ZONES;//4972
confConts	pb[1800].config_controllers = p.PID_BASE_CONFIG_CONTROLLERS;//4974
commStat	pb[1800].config_communication_status = p.PID_BASE_CONFIG_COMM_STAT;//4978
*/

// var tableIds = ["snaps", "snapChans", "snapEcph", "snapChanData",
//   "confChan", "confChanData", "snapAuxAlarms", "snapAuxPVars",
//   "snapAuxVars", "snapXBoards", "confAuxAlarms", "confAuxConts", 
//   "confAuxVars", "confXBoards", "confContSets",
//   "confEcph", "confEcphSens", "confSetpoints", "confZoneSets",
//   "confZones", "confConts", "commStat",
//   "confAuxPVars"];
var tableIds = {
  "1800":
  ["snaps", "snapChans", "snapEcph", "snapChanData",
    "confChan", "confChanData", "snapAuxAlarms", "snapAuxPVars",
    "snapAuxVars", "snapXBoards", "confAuxAlarms", "confAuxConts", 
    "confAuxVars", "confXBoards", "confContSets",
    "confEcph", "confEcphSens", "confSetpoints", "confZoneSets",
    "confZones", "confConts", "commStat",
    "confAuxPVars","confAnxSensors","confAddSensors"],
    "1900":["snaps", "snapChans", "snapEcph", "snapChanData",
    "confChan", "confChanData", "snapAuxAlarms", "snapAuxPVars",
    "snapAuxVars", "snapXBoards", "confAuxAlarms", "confAuxConts", 
    "confAuxVars", "confXBoards", "confContSets",
    "confEcph", "confEcphSens", "confSetpoints", "confZoneSets",
    "confZones", "confConts", "commStat",
    "confAuxPVars","confAnxSensors"],
    "2100":["snaps", "snapChans", "snapEcph", "snapChanData",
    "confChan", "confChanData", "snapAuxAlarms", "snapAuxPVars",
    "snapAuxVars", "snapXBoards", "confAuxAlarms", "confAuxConts", 
    "confAuxVars", "confXBoards", "confContSets",
    "confEcph", "confEcphSens", "confSetpoints", "confZoneSets",
    "confZones", "confConts", "commStat",
    "confAuxPVars","confAnxSensors"],
    "3200":["inConfig","outConfig","spConfig","zoneConfig","contConfig"],
    "3300":["snaps", "snapChans", "snapEcph", "snapChanData",
    "confChan", "confChanData", "snapAuxAlarms", "snapAuxPVars",
    "snapAuxVars", "snapXBoards", "confAuxAlarms", "confAuxConts", 
    "confAuxVars", "confXBoards", "confContSets",
    "confEcph", "confEcphSens", "confSetpoints", "confZoneSets",
    "confZones", "confConts", "commStat",
    "confAuxPVars","confAnxSensors","inConfig"],
  "800":
  ["igC","igCE","igCX","igR","igRA","igRE",],
};
// var tableBases = [0, 100, 200, 400, 500, 600, 1024, 1056,
//   1088, 1152, 1216, 1344, 3520, 3640, 3896, 4216, 4556,
//   4567, 4636, 4716, 5022, 5024, 5028, 999999] ;




var p = {};
var pids =[];

p.PID_3200_BASE_IN_CONFIG = 0 // 81
p.PID_3200_BASE_OUT_CONFIG = 50 // 81
p.PID_3200_BASE_SP_CONFIG = 200 // 81
p.PID_3200_BASE_ZONE_CONFIG = 250 // 81
p.PID_3200_BASE_CONT_CONFIG = 300 // 81
p.PID_3200_BASE_IN_RTD = 350 // 81
p.PID_3200_BASE_OUT_RTD = 360 // 81
p.PID_3200_BASE_ZONE_RTD = 380 // 81// next value is 420

p.PID_BASE_SNAPSHOTS = 0 // 81
p.PID_BASE_SNAP_CHANNELS = 100;
p.PID_BASE_SNAP_ECPHS = 200;
p.PID_BASE_SNAP_CHAN_DATA = 400;
p.PID_BASE_CONF_CHANNELS = 500;
p.PID_BASE_CONF_CHAN_DATA = 600;

p.PID_BASE_AUX_ALARMS = 1024 // 32
p.PID_BASE_AUX_PERSISTENT_VARIABLE = 1056 // 32
p.PID_BASE_AUX_VARIABLE = 1088 // 64
p.PID_BASE_EXPANSION_BOARDS = 1152 // 64
// p.PID_BASE_EXPANSION_BOARDS = 1216
p.PID_BASE_CONFIG_AUX_ALARMS = 1216 // 4 * 32
p.PID_BASE_CONFIG_AUX_CONTROLS = 1344 // 17 * 64 no! 128!
//p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 3520 // 4 * 32
p.PID_BASE_CONFIG_AUX_VARIABLES = 3640 // 4 * 64
p.PID_BASE_CONFIG_EXPANSION_BOARDS = 3896 // 5 * 64
p.PID_BASE_CONFIG_CONTROLLER_SETTINGS = 4216 // 145 * 2, 170, now 157*2+4216=4530, next is PID_BASE_CONFIG_ECPH at 4556

// # the following have been +50, on 20190908
// PID_BASE_CONFIG_ECPH = 4556 # 11 - actually, 8 * 11
// PID_BASE_CONFIG_ECPH_SENSORS = 4567 # 3 * 23 = 69, actually, 8 * 69
// #4440#
// # zone wide
// PID_BASE_CONFIG_SETPOINTS = 4636 # 8 * 10
// PID_BASE_CONFIG_ZONE_SETTINGS = 4716 # 153 * 2!
// PID_BASE_CONFIG_ZONES = 5022 # 2
// PID_BASE_CONFIG_CONTROLLERS = 5024 # 4
// # site wide
// PID_BASE_CONFIG_COMM_STAT = 5028 # 3
//
// PID_ZONE_MIN = PID_BASE_CONFIG_ZONES # 5022
// PID_ZONE_MAX = PID_BASE_CONFIG_CONTROLLERS - 1 # 5023
// PID_SETPOINTS_MIN = PID_BASE_CONFIG_SETPOINTS # 4636
// PID_SETPOINTS_MAX = PID_BASE_CONFIG_ZONE_SETTINGS - 1 # 4715
// PID_CONTROLLERS_VERSION = PID_BASE_CONFIG_CONTROLLERS + 1 # 5025

p.PID_BASE_CONFIG_ECPH = 4556 // 11
p.PID_BASE_CONFIG_ECPH_SENSORS = 4567 // 3 * 23 = 69
//4440//
// zone wide
p.PID_BASE_CONFIG_SETPOINTS = 4636 // 8 * 10
p.PID_BASE_CONFIG_ZONE_SETTINGS = 4716 // 153 * 2!
p.PID_BASE_CONFIG_ZONES = 5022 // 2
p.PID_BASE_CONFIG_CONTROLLERS = 5024 // 4
// site wide
p.PID_BASE_CONFIG_COMM_STAT = 5028 // 3
p.PID_BASE_CONFIG_TIME = 5031 // 1 - current time at the server

p.PID_ZONE_NAME = 5022;
p.PID_ZONE_DESCRIPTION = 5023;
p.PID_CONTROLLER_VERSION = 5025;
p.PID_BASE_CONFIG_TIME = 5031 // 1 - current time at the server
p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 5040 // 4 * 32
p.PID_BASE_CONFIG_ANNEX_SENSORS = 5200 // 20*100
p.PID_BASE_CONFIG_SENSORS = 7200 // 50 values
p.PID_BASE_ADDED_SENSORS = 0x4E00 // 16 values
p.PID_BASE_END = 7250 // 20*100
// p.PID_BASE_END = 7200 // 20*100


var pb = {};// these are the id bases
pb[800]={}
pb[1800]={}
pb[3200]={}
pb[3200].inConfig=p.PID_3200_BASE_IN_CONFIG
pb[3200].outConfig=p.PID_3200_BASE_OUT_CONFIG
pb[3200].spConfig=p.PID_3200_BASE_SP_CONFIG
pb[3200].zoneConfig=p.PID_3200_BASE_ZONE_CONFIG
pb[3200].contConfig=p.PID_3200_BASE_CONT_CONFIG
pb[3200].inRtd=p.PID_3200_BASE_IN_RTD
pb[3200].outRtd=p.PID_3200_BASE_OUT_RTD
pb[3200].zoneRtd=p.PID_3200_BASE_ZONE_RTD


pb[1800].snapshots = 0 // 1-81
pb[1800].snapshot_channels = 100 // 101-120
pb[1800].snapshot_ecphs = 200 // 201-235
pb[1800].snapshot_chan_Snapshot = 400 // 401-448
pb[1800].channels_configuration = 500 // 501-520
pb[1800].config_channels_configuration = 600 // 600-796
pb[1800].snapshot_aux_alarms = p.PID_BASE_AUX_ALARMS; // 1024
pb[1800].snapshot_aux_persistent_variables = p.PID_BASE_AUX_PERSISTENT_VARIABLE;//1056
pb[1800].snapshot_aux_variables = p.PID_BASE_AUX_VARIABLE;//1088
pb[1800].snapshot_expansion_boards = p.PID_BASE_EXPANSION_BOARDS;//1152
pb[1800].config_aux_alarms = p.PID_BASE_CONFIG_AUX_ALARMS;//1216 4 * 32
pb[1800].config_aux_controls = p.PID_BASE_CONFIG_AUX_CONTROLS;//1344 17 * 128
pb[1800].config_aux_variables = p.PID_BASE_CONFIG_AUX_VARIABLES;//3640 4 * 64
pb[1800].config_expansion_boards = p.PID_BASE_CONFIG_EXPANSION_BOARDS;//3896 5 * 64
pb[1800].controller_configuration_settings = p.PID_BASE_CONFIG_CONTROLLER_SETTINGS;//4216
pb[1800].config_ecph = p.PID_BASE_CONFIG_ECPH;//4506
pb[1800].config_ecph_sensors = p.PID_BASE_CONFIG_ECPH_SENSORS;//4517
pb[1800].config_setpoints = p.PID_BASE_CONFIG_SETPOINTS;//4586
pb[1800].zone_configuration_settings = p.PID_BASE_CONFIG_ZONE_SETTINGS;//4666
pb[1800].config_zones = p.PID_BASE_CONFIG_ZONES;//4972
pb[1800].config_controllers = p.PID_BASE_CONFIG_CONTROLLERS;//4974
pb[1800].config_communication_status = p.PID_BASE_CONFIG_COMM_STAT;//4978
pb[1800].config_aux_persistent_variables = p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES;//3520 4 * 32
pb[1800].config_annex_sensors = p.PID_BASE_CONFIG_ANNEX_SENSORS;//3520 4 * 32
pb[1800].config_added_sensors = p.PID_BASE_ADDED_SENSORS;//3520 4 * 32

// pb[3300].snapshots = 0 // 1-81
// pb[3300].snapshot_channels = 100 // 101-120
// pb[3300].snapshot_ecphs = 200 // 201-235
// pb[3300].snapshot_chan_Snapshot = 400 // 401-448
// pb[3300].channels_configuration = 500 // 501-520
// pb[3300].config_channels_configuration = 600 // 600-796
// pb[3300].snapshot_aux_alarms = p.PID_3300_BASE_AUX_ALARMS; // 1024
// pb[3300].snapshot_aux_persistent_variables = p.PID_3300_BASE_AUX_PERSISTENT_VARIABLE;//1056
// pb[3300].snapshot_aux_variables = p.PID_3300_BASE_AUX_VARIABLE;//1088
// pb[3300].snapshot_expansion_boards = p.PID_3300_BASE_EXPANSION_BOARDS;//1152
// pb[3300].config_aux_alarms = p.PID_3300_BASE_CONFIG_AUX_ALARMS;//1216 4 * 32
// pb[3300].config_aux_controls = p.PID_3300_BASE_CONFIG_AUX_CONTROLS;//1344 17 * 128
// pb[3300].config_aux_variables = p.PID_3300_BASE_CONFIG_AUX_VARIABLES;//3640 4 * 64
// pb[3300].config_expansion_boards = p.PID_3300_BASE_CONFIG_EXPANSION_BOARDS;//3896 5 * 64
// pb[3300].controller_configuration_settings = p.PID_3300_BASE_CONFIG_CONTROLLER_SETTINGS;//4216
// pb[3300].config_ecph = p.PID_3300_BASE_CONFIG_ECPH;//4506
// pb[3300].config_ecph_sensors = p.PID_3300_BASE_CONFIG_ECPH_SENSORS;//4517
// pb[3300].config_setpoints = p.PID_3300_BASE_CONFIG_SETPOINTS;//4586
// pb[3300].zone_configuration_settings = p.PID_3300_BASE_CONFIG_ZONE_SETTINGS;//4666
// pb[3300].config_zones = p.PID_3300_BASE_CONFIG_ZONES;//4972
// pb[3300].config_controllers = p.PID_3300_BASE_CONFIG_CONTROLLERS;//4974
// pb[3300].config_communication_status = p.PID_3300_BASE_CONFIG_COMM_STAT;//4978
// pb[3300].config_aux_persistent_variables = p.PID_3300_BASE_CONFIG_AUX_PERSISTENT_VARIABLES;//3520 4 * 32
// pb[3300].config_annex_sensors = p.PID_3300_BASE_CONFIG_ANNEX_SENSORS;//3520 4 * 32
// pb[3300].inConfig=p.PID_3300_BASE_IN_CONFIG

var pInd = {};// these are the index values
pInd[1800]={}
pInd[3200]={}
pInd[3300]={}
// types of organization:
// 0: zone, unit
// 1: zone, unit, channel
// 2: zone, unit, channel, tank
// 3: zone, unit, channel, tank, ecph sensor
// 4: zone
// 5: (site wide)
// 6: Zone Settings
// 7: Controller Settings
// 8: zone, unit, ecph channel

/* trying type 2 for config_ecph*/

pInd[3200].inConfig=[p.PID_3200_BASE_IN_CONFIG, 1, 0, 0]// type 1 means use channel
pInd[3200].outConfig=[p.PID_3200_BASE_OUT_CONFIG, 0, 0, 0]
pInd[3200].spConfig=[p.PID_3200_BASE_SP_CONFIG, 0, 0, 0]
pInd[3200].zoneConfig=[p.PID_3200_BASE_ZONE_CONFIG, 0, 0, 0]
pInd[3200].contConfig=[p.PID_3200_BASE_CONT_CONFIG, 0, 0, 0]
pInd[3200].inRtd=[p.PID_3200_BASE_IN_RTD, 0, 0, 0]
pInd[3200].outRtd=[p.PID_3200_BASE_OUT_RTD, 0, 0, 0]
pInd[3200].zoneRtd=[p.PID_3200_BASE_ZONE_RTD, 0, 0, 0]

// pInd[3300].inConfig=[p.PID_3200_BASE_IN_CONFIG, 1, 0, 0]// type 1 means use channel
// pInd[3300].outConfig=[p.PID_3200_BASE_OUT_CONFIG, 0, 0, 0]
// pInd[3300].spConfig=[p.PID_3200_BASE_SP_CONFIG, 0, 0, 0]
// pInd[3300].zoneConfig=[p.PID_3200_BASE_ZONE_CONFIG, 0, 0, 0]
// pInd[3300].contConfig=[p.PID_3200_BASE_CONT_CONFIG, 0, 0, 0]
// pInd[3300].inRtd=[p.PID_3200_BASE_IN_RTD, 0, 0, 0]
// pInd[3300].outRtd=[p.PID_3200_BASE_OUT_RTD, 0, 0, 0]
// pInd[3300].zoneRtd=[p.PID_3200_BASE_ZONE_RTD, 0, 0, 0]

pInd[1800].snapshots = [p.PID_BASE_SNAPSHOTS, 0, 0, 0] // 1-81: base, channel type, index size, index count. channel type 0 = "unit"
pInd[1800].snapshot_channels = [p.PID_BASE_SNAP_CHANNELS, 1, 0, 0] // 101-120 channel type 1 = "channel" - zone, unit, chan
pInd[1800].snapshot_ecphs = [p.PID_BASE_SNAP_ECPHS, 2, 0, 0] // 201-235// ecph channel type - put in 192-199, on ecphIndex
pInd[1800].snapshot_chan_Snapshot = [p.PID_BASE_SNAP_CHAN_DATA, 1, 0, 0] // 401-448 channeldata channel type
pInd[1800].channels_configuration = [p.PID_BASE_CONF_CHANNELS, 1, 0, 0] // 501-520 channel type
pInd[1800].config_channels_configuration = [p.PID_BASE_CONF_CHAN_DATA, 1, 0, 0] // 600-796 channeldata
pInd[1800].snapshot_aux_alarms = [p.PID_BASE_AUX_ALARMS, 0, 1, 32]; // 1024 + 32
pInd[1800].snapshot_aux_persistent_variables = [p.PID_BASE_AUX_PERSISTENT_VARIABLE, 0, 1, 32];//1056 + 32
pInd[1800].snapshot_aux_variables = [p.PID_BASE_AUX_VARIABLE, 0, 1, 64];//1088 + 64
pInd[1800].snapshot_expansion_boards = [p.PID_BASE_EXPANSION_BOARDS, 0, 1, 64];//1152 + 64
pInd[1800].config_aux_alarms = [p.PID_BASE_CONFIG_AUX_ALARMS, 0, 4, 32];//1216 4 * 32
pInd[1800].config_aux_controls = [p.PID_BASE_CONFIG_AUX_CONTROLS, 0, 17, 128];//1344 17 * 128
pInd[1800].config_aux_persistent_variables = [p.PID_BASE_CONFIG_AUX_PERSISTENT_VARIABLES, 0, 4, 32];//3520 4 * 32
pInd[1800].config_aux_variables = [p.PID_BASE_CONFIG_AUX_VARIABLES, 0, 4, 64];//3640 4 * 64
pInd[1800].config_expansion_boards = [p.PID_BASE_CONFIG_EXPANSION_BOARDS, 0, 5, 64];//3896 5 * 64
pInd[1800].controller_configuration_settings = [p.PID_BASE_CONFIG_CONTROLLER_SETTINGS, 7, 2, 170];//4216 170 * 2
// I really don't know what the 11, 8 refers to below!
pInd[1800].config_ecph = [p.PID_BASE_CONFIG_ECPH, 2, 11, 8];//4506 11 * 8 - ERROR!!! this is just 11 values. the 8 times are in the channel
pInd[1800].config_ecph_sensors = [p.PID_BASE_CONFIG_ECPH_SENSORS, 3, 23, 3];//4517 - channel type 3 - ecph double indexed
pInd[1800].config_setpoints = [p.PID_BASE_CONFIG_SETPOINTS, 4, 10, 8];//4586 type 4 = zone wide
pInd[1800].zone_configuration_settings = [p.PID_BASE_CONFIG_ZONE_SETTINGS, 6, 2, 153];//4666 zone *config* type
pInd[1800].config_zones = [p.PID_BASE_CONFIG_ZONES, 4, 0, 0];//4972
pInd[1800].config_controllers = [p.PID_BASE_CONFIG_CONTROLLERS, 0, 0, 0];//4974
pInd[1800].config_communication_status = [p.PID_BASE_CONFIG_COMM_STAT, 5, 0, 0];//4978 type 5 - site wide
pInd[1800].config_annex_sensors = [p.PID_BASE_CONFIG_ANNEX_SENSORS, 0, 20, 100];//5200 // 20*100
pInd[1800].config_added_sensors = [p.PID_BASE_ADDED_SENSORS, 0, 0, 0];// 0x4E00

var tableBases2={
  "1800":[
  pInd[1800].snapshots,
  pInd[1800].snapshot_channels,
  pInd[1800].snapshot_ecphs,
  pInd[1800].snapshot_chan_Snapshot,
  pInd[1800].channels_configuration,
  pInd[1800].config_channels_configuration,
  pInd[1800].snapshot_aux_alarms,
  pInd[1800].snapshot_aux_persistent_variables,
  pInd[1800].snapshot_aux_variables,
  pInd[1800].snapshot_expansion_boards,
  pInd[1800].config_aux_alarms,
  pInd[1800].config_aux_controls,
  pInd[1800].config_aux_variables,
  pInd[1800].config_expansion_boards,
  pInd[1800].controller_configuration_settings,
  pInd[1800].config_ecph,
  pInd[1800].config_ecph_sensors,
  pInd[1800].config_setpoints,
  pInd[1800].zone_configuration_settings,
  pInd[1800].config_zones,
  pInd[1800].config_controllers,
  pInd[1800].config_communication_status,
  pInd[1800].config_aux_persistent_variables,
  pInd[1800].config_annex_sensors,
  [100000]],
  "3200":[
  pInd[3200].inConfig,
  pInd[3200].outConfig,
  pInd[3200].spConfig,
  pInd[3200].zoneConfig,
  pInd[3200].contConfig,
  pInd[3200].inRtd,
  pInd[3200].outRtd,
  pInd[3200].zoneRtd,
  [100000]],
  "3300":[
  pInd[3300].snapshots,
  pInd[3300].snapshot_channels,
  pInd[3300].snapshot_ecphs,
  pInd[3300].snapshot_chan_Snapshot,
  pInd[3300].channels_configuration,
  pInd[3300].config_channels_configuration,
  pInd[3300].snapshot_aux_alarms,
  pInd[3300].snapshot_aux_persistent_variables,
  pInd[3300].snapshot_aux_variables,
  pInd[3300].snapshot_expansion_boards,
  pInd[3300].config_aux_alarms,
  pInd[3300].config_aux_controls,
  pInd[3300].config_aux_variables,
  pInd[3300].config_expansion_boards,
  pInd[3300].controller_configuration_settings,
  pInd[3300].config_ecph,
  pInd[3300].config_ecph_sensors,
  pInd[3300].config_setpoints,
  pInd[3300].zone_configuration_settings,
  pInd[3300].config_zones,
  pInd[3300].config_controllers,
  pInd[3300].config_communication_status,
  pInd[3300].config_aux_persistent_variables,
  pInd[3300].config_annex_sensors,
  pInd[3300].inConfig,
  [100000]],
//   "3300":[
//   pInd[3300].outConfig,
//   pInd[3300].spConfig,
//   pInd[3300].zoneConfig,
//   pInd[3300].contConfig,
//   pInd[3300].inRtd,
//   pInd[3300].outRtd,
//   pInd[3300].zoneRtd,
//   [100000]],
}

// var snapshots = {} // these are in channels 240 + u
var pi = {};
pi[1800]={}
pi[3200]={}
pi[3300]={}
pi[3200].inConfig=[]
pi[3200].outConfig=[]
pi[3200].spConfig=[]
pi[3200].zoneConfig=[]
pi[3200].contConfig=[]
pi[3200].inRtd=[]
pi[3200].outRtd=[]
pi[3200].zoneRtd=[]
pi[3200].inConfig["inChannel"] =	19
pi[3200].inConfig["inType"] =	1
pi[3200].inConfig["inPort"] =	2
pi[3200].inConfig["inFormat"] =	3
pi[3200].inConfig["inMBAddress"] =	4
pi[3200].inConfig["inMBRegister"] =	5
pi[3200].inConfig["inCalIn0"] =	6
pi[3200].inConfig["inCalOut0"] = 7
pi[3200].inConfig["inCalIn1"] =	8
pi[3200].inConfig["inCalOut1"] = 9
pi[3200].inConfig["inChange"] =	10
pi[3200].inConfig["inAlarmLow"] =	11
pi[3200].inConfig["inAlarmHigh"] =	12
pi[3200].inConfig["inAlarmDeadband"] =	13
pi[3200].inConfig["inAlarmDelay"] =	14
pi[3200].inConfig["inAlarmEnable"] =	15
pi[3200].inConfig["inUnit"] =	16
pi[3200].inConfig["inZone"] =	17
pi[3200].inConfig["inDatapointName"] =	18

pi[3200].outConfig["channelName"] =	1
pi[3200].outConfig["channelType"] =	2
pi[3200].outConfig["analog"] =	3
pi[3200].outConfig["handOffAuto"] =	4

pi[3200].outConfig["enableOverride"] =	5
pi[3200].outConfig["startOverride"] =	6
pi[3200].outConfig["endOverrride"] =	7
pi[3200].outConfig["daily"] =	8
pi[3200].outConfig["force"] =	9

/*specific stage instructions: temp, hum, light, co2: thlc. hex: t8 is normal, t0 is heat 8, tf is cool 7. ta0 means override off on 
temp stage cool 2 - *and* all lower stages:
0 off on this stage and all below, 1: off on this stage only, 2: on on this stage only, 3: on on this stage and all above
*/
pi[3200].outConfig["stageOverride0"] =	11
pi[3200].outConfig["stageOverride1"] =	12
pi[3200].outConfig["stageOverride2"] =	13
pi[3200].outConfig["stageOverride3"] =	14
pi[3200].outConfig["stageOverride4"] =	15
pi[3200].outConfig["stageOverride5"] =	16
pi[3200].outConfig["stageOverride6"] =	17
pi[3200].outConfig["stageOverride7"] =	18
pi[3200].outConfig["stageOverride8"] =	19
pi[3200].outConfig["stageOverride9"] =	20
pi[3200].outConfig["stageOverride10"] =	21
pi[3200].outConfig["stageOverride11"] =	22
pi[3200].outConfig["stageOverride12"] =	23
pi[3200].outConfig["stageOverride13"] =	24
pi[3200].outConfig["stageOverride14"] =	25
pi[3200].outConfig["stageOverride15"] =	26

pi[3200].zoneConfig["inTempStageWidth"] =	1// stage settings - channel sets type
pi[3200].zoneConfig["inTempAlarmLo"] =	2
pi[3200].zoneConfig["inTempAlarmHi"] =	3
pi[3200].zoneConfig["inTempAlarmEn"] =	4
pi[3200].zoneConfig["inTempAlarmDeadband"] =	5
pi[3200].zoneConfig["inHumStageWidth"] =	6
pi[3200].zoneConfig["inHumAlarmLo"] =	7
pi[3200].zoneConfig["inHumAlarmHi"] =	8
pi[3200].zoneConfig["inHumAlarmEn"] =	9
pi[3200].zoneConfig["inHumAlarmDeadband"] =	10
pi[3200].zoneConfig["inLightStageWidth"] =	11
pi[3200].zoneConfig["inLightAlarmLo"] =	12
pi[3200].zoneConfig["inLightAlarmHi"] =	13
pi[3200].zoneConfig["inLightAlarmEn"] =	14
pi[3200].zoneConfig["inLightAlarmDeadband"] =	15
pi[3200].zoneConfig["inCo2StageWidth"] =	16
pi[3200].zoneConfig["inCo2AlarmLo"] =	17
pi[3200].zoneConfig["inCo2AlarmHi"] =	18
pi[3200].zoneConfig["inCo2AlarmEn"] =	19
pi[3200].zoneConfig["inCo2AlarmDeadband"] =	20
pi[3200].zoneConfig["inVpdStageWidth"] =	21
pi[3200].zoneConfig["inVpdAlarmLo"] =	22
pi[3200].zoneConfig["inVpdAlarmHi"] =	23
pi[3200].zoneConfig["inVpdAlarmEn"] =	24
pi[3200].zoneConfig["inVpdAlarmDeadband"] =	25

pi[3200].zoneConfig["tempUnits"] =	26
pi[3200].zoneConfig["lightUnits"] =	27
pi[3200].zoneConfig["co2Units"] =	28
pi[3200].zoneConfig["windSpeedUnits"] =	29
pi[3200].zoneConfig["ecUnits"] =	30
pi[3200].zoneConfig["volumeUnits"] =	31
pi[3200].zoneConfig["concentrationUnits"] =	32
pi[3200].zoneConfig["inTempLowStages"] =	32
pi[3200].zoneConfig["inTempHighStages"] =	32
pi[3200].zoneConfig["inHumLowStages"] =	32
pi[3200].zoneConfig["inHumHighStages"] =	32
pi[3200].zoneConfig["inLightLowStages"] =	32
pi[3200].zoneConfig["inLightHighStages"] =	32
pi[3200].zoneConfig["inCo2LowStages"] =	32
pi[3200].zoneConfig["inCo2HighStages"] =	32
pi[3200].zoneConfig["inVpdLowStages"] =	32
pi[3200].zoneConfig["inVodHighStages"] =	32


pi[3200].spConfig["spStart"] =	1// time: 1440 means disabled
pi[3200].spConfig["inTempSpLow"] =	2// setpoint id (0-7) set by index
pi[3200].spConfig["inTempSpHigh"] =	3
pi[3200].spConfig["inHumSpLow"] =	4
pi[3200].spConfig["inHumSpHigh"] =	5
pi[3200].spConfig["inLightSpLow"] =	6
pi[3200].spConfig["inLightSpHigh"] =	7
pi[3200].spConfig["inCo2SpLow"] =	8
pi[3200].spConfig["inCo2SpHigh"] =	9
pi[3200].spConfig["inVpdSpLow"] =	10
pi[3200].spConfig["inVpdSpHigh"] =	11

pi[3200].contConfig["uId"] =	1

pi[3200].inRtd["value"] =	1
pi[3200].inRtd["alarm"] =	2


pi[3200].outRtd["program"] =	1
pi[3200].outRtd["conditionOverride"] =	2
pi[3200].outRtd["stageOveride"] =	3
pi[3200].outRtd["switchOverride"] =	4
pi[3200].outRtd["cloudOverride"] =	5
pi[3200].outRtd["position"] =	6
pi[3200].outRtd["relay"] =	7


pi[3200].zoneRtd["tempStage"] =	1
pi[3200].zoneRtd["humStage"] =	2
pi[3200].zoneRtd["lightStage"] =	3
pi[3200].zoneRtd["co2Stage"] =	4
pi[3200].zoneRtd["tempSpLo"] =	5
pi[3200].zoneRtd["tempSpHi"] =	6
pi[3200].zoneRtd["humSpLo"] =	7
pi[3200].zoneRtd["humSpHi"] =	8
pi[3200].zoneRtd["lightSpLo"] =	9
pi[3200].zoneRtd["LightSpHi"] =	10
pi[3200].zoneRtd["co2SpLo"] =	11
pi[3200].zoneRtd["co2SpHi"] =	12
pi[3200].zoneRtd["tempSpId"] =	13// what setpoint is active?
pi[3200].zoneRtd["humSpId"] =	14
pi[3200].zoneRtd["lightSpId"] =	15
pi[3200].zoneRtd["co2SpId"] =	16


pi[1800].snapshots = [];//0, 1-81
pi[1800].snapshot_channels = [];//0, 101-120
pi[1800].snapshot_ecphs = [];//0, 201-235
pi[1800].snapshot_chan_Snapshot = [];//0, 401-448
pi[1800].channels_configuration = [];//0 501-520
pi[1800].config_channels_configuration = [];// 0, 600-796
pi[1800].snapshot_aux_alarms = [];// 1024
pi[1800].snapshot_aux_persistent_variables = [];// 1056
pi[1800].snapshot_aux_variables = [];// 1088
pi[1800].snapshot_expansion_boards = [];// 1152
pi[1800].config_aux_alarms = [];// 1216
pi[1800].config_aux_controls = [];// 1344
pi[1800].config_aux_persistent_variables = [];// 3520
pi[1800].config_aux_variables = [];// 3640
pi[1800].config_expansion_boards = [];// 3896
pi[1800].controller_configuration_settings = [];//4216
pi[1800].config_ecph = [];//4506
pi[1800].config_ecph_sensors = [];//4517
pi[1800].config_setpoints = [];//4586
pi[1800].zone_configuration_settings = [];//4666
pi[1800].config_zones = [];//4972
pi[1800].config_controllers = [];//4974
pi[1800].config_communication_status = [];//4978
pi[1800].config_annex_sensors = [];//4978
pi[1800].config_added_sensors = [];// 0x4E00

pi[1800].sensors = [];//4978


var offsetTables={
  "1800":
  [pi[1800].snapshots, pi[1800].snapshot_channels, pi[1800].snapshot_ecphs,
  pi[1800].snapshot_chan_Snapshot, pi[1800].channels_configuration,
  pi[1800].config_channels_configuration, pi[1800].snapshot_aux_alarms,
  pi[1800].snapshot_aux_persistent_variables, pi[1800].snapshot_aux_variables,
  pi[1800].snapshot_expansion_boards, pi[1800].config_aux_alarms,
  pi[1800].config_aux_controls,
  pi[1800].config_aux_variables, pi[1800].config_expansion_boards,
  pi[1800].controller_configuration_settings, pi[1800].config_ecph,
  pi[1800].config_ecph_sensors, pi[1800].config_setpoints,
  pi[1800].zone_configuration_settings, pi[1800].config_zones, pi[1800].config_controllers,
  pi[1800].config_communication_status, pi[1800].config_aux_persistent_variables, 
  pi[1800].config_annex_sensors,
  pi[1800].config_added_sensors,  
  ],
  
  "3200":[
  pi[3200].inConfig,pi[3200].outConfig,pi[3200].spConfig,pi[3200].zoneConfig,
  pi[3200].contConfig,pi[3200].inRtd,pi[3200].outRtd,pi[3200].zoneRtd,
  ],
//   "3300":
//   [pi[3300].snapshots, pi[3300].snapshot_channels, pi[3300].snapshot_ecphs,
//   pi[3300].snapshot_chan_Snapshot, pi[3300].channels_configuration,
//   pi[3300].config_channels_configuration, pi[3300].snapshot_aux_alarms,
//   pi[3300].snapshot_aux_persistent_variables, pi[3300].snapshot_aux_variables,
//   pi[3300].snapshot_expansion_boards, pi[3300].config_aux_alarms,
//   pi[3300].config_aux_controls,
//   pi[3300].config_aux_variables, pi[3300].config_expansion_boards,
//   pi[3300].controller_configuration_settings, pi[3300].config_ecph,
//   pi[3300].config_ecph_sensors, pi[3300].config_setpoints,
//   pi[3300].zone_configuration_settings, pi[3300].config_zones, pi[3300].config_controllers,
//   pi[3300].config_communication_status, pi[3300].config_aux_persistent_variables, 
//   pi[3300].config_annex_sensors,pi[3300].inConfig,
//   ]
}
  
// for Datapoints:
  
var dpNames={
  snapshots: "snapshots",
  snapshot_channels: "snapshot_channels",
  snapshot_ecphs: "snapshot_ecphs",
  snapshot_channel_data: "snapshot_chan_Snapshot",
  snapshot_aux_variables: "snapshot_aux_variables",
  snapshot_aux_persistent_variables: "snapshot_aux_persistent_variables",
  snapshot_aux_alarms: "snapshot_aux_alarms",
  snapshot_expansion_boards: "snapshot_expansion_boards",
  configuration_channels: "channels_configuration",
  configuration_channel_data: "config_channels_configuration",
  configuration_aux_alarms: "config_aux_alarms",
  configuration_aux_controls: "config_aux_controls",
  configuration_aux_persistent_variables: "config_aux_persistent_variables",
  configuration_aux_variables: "config_aux_variables",
  configuration_expansion_boards: "config_expansion_boards",
  configuration_unit_settings: "controller_configuration_settings",
  configuration_zone_settings: "zone_configuration_settings",
  configuration_ecph: "config_ecph",
  configuration_setpoints: "config_setpoints",
  configuration_ecph_sensors: "config_ecph_sensors",
  configuration_zones: "config_zones",
  configuration_controllers: "config_controllers",
  config_annex_sensors: "config_annex_sensors",
  config_added_sensors: "config_added_sensors",
}

pi[1800].snapshots["id"] =	1
pi[1800].snapshots["zoneIndex"] =	2
pi[1800].snapshots["unitIndex"] =	3
pi[1800].snapshots["unix_timestamp(created)"] =	4
pi[1800].snapshots["unix_timestamp(unitTime)"] =	5
pi[1800].snapshots["temperatureStage"] =	6
pi[1800].snapshots["humidityStage"] =	7
pi[1800].snapshots["heatSetpoint"] =	8
pi[1800].snapshots["coolSetpoint"] =	9
pi[1800].snapshots["humidifySetpoint"] =	10
pi[1800].snapshots["dehumidifySetpoint"] =	11
pi[1800].snapshots["accumulator0"] =	12
pi[1800].snapshots["accumulator1"] =	13
pi[1800].snapshots["d2avgTotalAverage"] =	14
pi[1800].snapshots["d2avgDayAverage"] =	15
pi[1800].snapshots["d2avgNightAverage"] =	16
pi[1800].snapshots["d2avgFailDays"] =	17
pi[1800].snapshots["lowAlarm"] =	18
pi[1800].snapshots["highAlarm"] =	19
pi[1800].snapshots["lowInTemperatureAlarm"] =	20
pi[1800].snapshots["highInTemperatureAlarm"] =	21
pi[1800].snapshots["inTemperatureSensorAlarm"] =	22
pi[1800].snapshots["inTemperature"] =	23
pi[1800].snapshots["outTemperature"] =	24
pi[1800].snapshots["localTemperature"] =	25
pi[1800].snapshots["backupTemperature"] =	26
pi[1800].snapshots["inHumidity"] =	27
pi[1800].snapshots["outHumidity"] =	28
pi[1800].snapshots["localHumidity"] =	29
pi[1800].snapshots["differentialPressure"] =	30
pi[1800].snapshots["co2"] =	31
pi[1800].snapshots["inLight"] =	32
pi[1800].snapshots["outLight"] =	33
pi[1800].snapshots["windSpeed"] =	34
pi[1800].snapshots["windDirection"] =	35
pi[1800].snapshots["rain"] =	36
pi[1800].snapshots["snow"] =	37
pi[1800].snapshots["analogTemperature1"] =	38
pi[1800].snapshots["analogTemperature2"] =	39
pi[1800].snapshots["analogTemperature3"] =	40
pi[1800].snapshots["analogTemperature4"] =	41
pi[1800].snapshots["analogTemperature5"] =	42
pi[1800].snapshots["ventPosition1"] =	43
pi[1800].snapshots["ventPosition2"] =	44
pi[1800].snapshots["ventPosition3"] =	45
pi[1800].snapshots["ventPosition4"] =	46
pi[1800].snapshots["ventPosition5"] =	47
pi[1800].snapshots["soilMoisture1"] =	48
pi[1800].snapshots["soilMoisture2"] =	49
pi[1800].snapshots["soilMoisture3"] =	50
pi[1800].snapshots["soilMoisture4"] =	51
pi[1800].snapshots["soilMoisture5"] =	52
pi[1800].snapshots["ecph1"] =	53
pi[1800].snapshots["ecph2"] =	54
pi[1800].snapshots["ecph3"] =	55
pi[1800].snapshots["ecph4"] =	56
pi[1800].snapshots["ecph5"] =	57
pi[1800].snapshots["generic1"] =	58
pi[1800].snapshots["generic2"] =	59
pi[1800].snapshots["heatDemand"] =	60
pi[1800].snapshots["coolDemand"] =	61
pi[1800].snapshots["coolDemandPassive"] =	62
pi[1800].snapshots["auxHeatSetpointStatus"] =	63
pi[1800].snapshots["auxHeatSetpointValue"] =	64
pi[1800].snapshots["auxCoolSetpointStatus"] =	65
pi[1800].snapshots["auxCoolSetpointValue"] =	66
pi[1800].snapshots["auxHumidifySetpointStatus"] =	67
pi[1800].snapshots["auxHumidifySetpointValue"] =	68
pi[1800].snapshots["auxDehumidifySetpointStatus"] =	69
pi[1800].snapshots["auxDehumidifySetpointValue"] =	70
pi[1800].snapshots["auxLowAlarmStatus"] =	71
pi[1800].snapshots["auxLowAlarmValue"] =	72
pi[1800].snapshots["auxHighAlarmStatus"] =	73
pi[1800].snapshots["auxHighAlarmValue"] =	74
pi[1800].snapshots["auxActiveCoolStatus"] =	75
pi[1800].snapshots["auxActiveCoolValue"] =	76
pi[1800].snapshots["auxPassiveCoolStatus"] =	77
pi[1800].snapshots["auxPassiveCoolValue"] =	78
pi[1800].snapshots["accvpd"] =	79
pi[1800].snapshots["outTemperatureSecondary"] =	80
pi[1800].snapshots["barometricPressure"] =   81
pi[1800].snapshots["vpd"] =	82

pi[1800].snapshots["processorId"] =	83
pi[1800].snapshots["controllerId"] =	84
pi[1800].snapshots["currentTime"] =	85


pi[1800].snapshot_channels["snapshotId"] = 1
pi[1800].snapshot_channels["channelIndex"] = 2
pi[1800].snapshot_channels["channelData"] = 3
pi[1800].snapshot_channels["position"] = 4
pi[1800].snapshot_channels["relay"] = 5
pi[1800].snapshot_channels["channelOverride"] = 6
pi[1800].snapshot_channels["analogOutput"] = 7
pi[1800].snapshot_channels["co2Setpoint"] = 8
pi[1800].snapshot_channels["microzoneSetpoint"] = 9
pi[1800].snapshot_channels["proportionalZoneLowSetpoint"] = 10
pi[1800].snapshot_channels["proportionalZoneHighSetpoint"] = 11
pi[1800].snapshot_channels["pumpPeristalticSetpoint"] = 12
pi[1800].snapshot_channels["pumpPeristalticTankLevel"] = 13
pi[1800].snapshot_channels["pumpPeristalticInjectedMilliliters"] = 14
pi[1800].snapshot_channels["pumpPeristalticInjectedSeconds"] = 15
pi[1800].snapshot_channels["lightDailyLightIntegral"] = 16
pi[1800].snapshot_channels["auxOutputStatus"] = 17
pi[1800].snapshot_channels["auxOutputValue"] = 18
pi[1800].snapshot_channels["auxEquipmentStatus"] = 19
pi[1800].snapshot_channels["auxEquipmentValue"] = 20

//var snapshot_ecphs = {} // these are stored in the ecph
// channels, 192-199
pi[1800].snapshot_ecphs["snapshotId"] = 1
pi[1800].snapshot_ecphs["ecphIndex"] = 2
pi[1800].snapshot_ecphs["ec1"] = 3
pi[1800].snapshot_ecphs["ec2"] = 4
pi[1800].snapshot_ecphs["ec3"] = 5
pi[1800].snapshot_ecphs["ec1SensorAlarm"] = 6
pi[1800].snapshot_ecphs["ec1ServiceAlarm"] = 7
pi[1800].snapshot_ecphs["ec1CalibrationAlarm"] = 8
pi[1800].snapshot_ecphs["ec2SensorAlarm"] = 9
pi[1800].snapshot_ecphs["ec2ServiceAlarm"] = 10
pi[1800].snapshot_ecphs["ec2CalibrationAlarm"] = 11
pi[1800].snapshot_ecphs["ec3SensorAlarm"] = 12
pi[1800].snapshot_ecphs["ec3ServiceAlarm"] = 13
pi[1800].snapshot_ecphs["ec3CalibrationAlarm"] = 14
pi[1800].snapshot_ecphs["ecLowAlarm"] = 15
pi[1800].snapshot_ecphs["ecHighAlarm"] = 16
pi[1800].snapshot_ecphs["ecDeviationAlarm"] = 17
pi[1800].snapshot_ecphs["ph1"] = 18
pi[1800].snapshot_ecphs["ph2"] = 19
pi[1800].snapshot_ecphs["ph3"] = 20
pi[1800].snapshot_ecphs["ph1SensorAlarm"] = 21
pi[1800].snapshot_ecphs["ph1ServiceAlarm"] = 22
pi[1800].snapshot_ecphs["ph1CalibrationAlarm"] = 23
pi[1800].snapshot_ecphs["ph2SensorAlarm"] = 24
pi[1800].snapshot_ecphs["ph2ServiceAlarm"] = 25
pi[1800].snapshot_ecphs["ph2CalibrationAlarm"] = 26
pi[1800].snapshot_ecphs["ph3SensorAlarm"] = 27
pi[1800].snapshot_ecphs["ph3ServiceAlarm"] = 28
pi[1800].snapshot_ecphs["ph3CalibrationAlarm"] = 29
pi[1800].snapshot_ecphs["phLowAlarm"] = 30
pi[1800].snapshot_ecphs["phHighAlarm"] = 31
pi[1800].snapshot_ecphs["phDeviationAlarm"] = 32
pi[1800].snapshot_ecphs["temperature1"] = 33
pi[1800].snapshot_ecphs["temperature2"] = 34
pi[1800].snapshot_ecphs["temperature3"] = 35

//var snapshot_chan_Snapshot = {} // stored in the Channel ID
// these are the values out of ChannelData
pi[1800].snapshot_chan_Snapshot["accumulatedLight"] = 1
pi[1800].snapshot_chan_Snapshot["accumulator"] = 2
pi[1800].snapshot_chan_Snapshot["accvpd"] = 3
pi[1800].snapshot_chan_Snapshot["activatedTimes"] = 4
pi[1800].snapshot_chan_Snapshot["activeCount"] = 5
pi[1800].snapshot_chan_Snapshot["auxControl"] = 6
pi[1800].snapshot_chan_Snapshot["coolDemand"] = 7
pi[1800].snapshot_chan_Snapshot["coolDemandPassive"] = 8
pi[1800].snapshot_chan_Snapshot["currentSetpoint"] = 9
pi[1800].snapshot_chan_Snapshot["cycleOffTimeRemains"] = 10
pi[1800].snapshot_chan_Snapshot["cycleOnTimeRemains"] = 11
pi[1800].snapshot_chan_Snapshot["cycleState"] = 12
pi[1800].snapshot_chan_Snapshot["dailyLightIntegral"] = 13
pi[1800].snapshot_chan_Snapshot["driveToAverage"] = 14
pi[1800].snapshot_chan_Snapshot["ecphs"] = 15
pi[1800].snapshot_chan_Snapshot["equipment"] = 16
pi[1800].snapshot_chan_Snapshot["expansionBoardStatus"] = 17
pi[1800].snapshot_chan_Snapshot["heatDemand"] = 18
pi[1800].snapshot_chan_Snapshot["highAlarm"] = 19
pi[1800].snapshot_chan_Snapshot["highInTemperatureAlarm"] = 20
pi[1800].snapshot_chan_Snapshot["humidityStage"] = 21
pi[1800].snapshot_chan_Snapshot["injectedVolumeMLiters"] = 22
pi[1800].snapshot_chan_Snapshot["injectedVolumeSeconds"] = 23
pi[1800].snapshot_chan_Snapshot["inQueue"] = 24
pi[1800].snapshot_chan_Snapshot["inTemperatureSensorAlarm"] = 25
pi[1800].snapshot_chan_Snapshot["inWindow"] = 26
pi[1800].snapshot_chan_Snapshot["irrigationWeek"] = 27
pi[1800].snapshot_chan_Snapshot["isCycling"] = 28
pi[1800].snapshot_chan_Snapshot["isPulseOn"] = 29
pi[1800].snapshot_chan_Snapshot["lowAlarm"] = 30
pi[1800].snapshot_chan_Snapshot["lowInTemperatureAlarm"] = 31
pi[1800].snapshot_chan_Snapshot["offTime"] = 32
pi[1800].snapshot_chan_Snapshot["originallyOn"] = 33
pi[1800].snapshot_chan_Snapshot["output"] = 34
pi[1800].snapshot_chan_Snapshot["pdTimer"] = 35
pi[1800].snapshot_chan_Snapshot["peristalticState"] = 36
pi[1800].snapshot_chan_Snapshot["sensors"] = 37
pi[1800].snapshot_chan_Snapshot["setpoint"] = 38
pi[1800].snapshot_chan_Snapshot["setpointHigh"] = 39
pi[1800].snapshot_chan_Snapshot["setpointLow"] = 40
pi[1800].snapshot_chan_Snapshot["siteChanged"] = 41
pi[1800].snapshot_chan_Snapshot["tankLevelLiters"] = 42
pi[1800].snapshot_chan_Snapshot["tankSizeLiters"] = 43
pi[1800].snapshot_chan_Snapshot["temperatureStage"] = 44
pi[1800].snapshot_chan_Snapshot["timestamp"] = 45
pi[1800].snapshot_chan_Snapshot["unitTime"] = 46
pi[1800].snapshot_chan_Snapshot["pdTimerState"] = 47
pi[1800].snapshot_chan_Snapshot["week"] = 248

//var channels_configuration = {}
pi[1800].channels_configuration["zoneIndex"] = 1
pi[1800].channels_configuration["unitIndex"] = 2
pi[1800].channels_configuration["channelIndex"] = 3
pi[1800].channels_configuration["unix_timestamp(modified)"] = 4
pi[1800].channels_configuration["used"] = 5
pi[1800].channels_configuration["isAnalog"] = 6
pi[1800].channels_configuration["channelName"] = 7
pi[1800].channels_configuration["channelType"] = 8
pi[1800].channels_configuration["channelData"] = 9
pi[1800].channels_configuration["timedEnabled"] = 10
pi[1800].channels_configuration["timedInterval"] = 11
pi[1800].channels_configuration["timedStartTime"] = 12
pi[1800].channels_configuration["timedEndTime"] = 13
pi[1800].channels_configuration["timedOutput"] = 14
pi[1800].channels_configuration["stageDehumidfy1"] = 15
pi[1800].channels_configuration["stageDehumidfy2"] = 16
pi[1800].channels_configuration["stageColdDehumidfy"] = 17
pi[1800].channels_configuration["stageHumidfy"] = 18
pi[1800].channels_configuration["expansionFailSafeOutput"] = 19
pi[1800].channels_configuration["userComment"] = 20

//var config_channels_configuration = {}
pi[1800].config_channels_configuration["activeCool_cool1"] = 0
pi[1800].config_channels_configuration["activeCool_cool2"] = 1
pi[1800].config_channels_configuration["activeCool_cool3"] = 2
pi[1800].config_channels_configuration["activeCool_cool4"] = 3
pi[1800].config_channels_configuration["activeCool_cool5"] = 4
pi[1800].config_channels_configuration["activeCool_cool6"] = 5
pi[1800].config_channels_configuration["activeCool_heat1"] = 6
pi[1800].config_channels_configuration["activeCool_heat2"] = 7
pi[1800].config_channels_configuration["activeCool_heat3"] = 8
pi[1800].config_channels_configuration["activeCool_heat4"] = 9
pi[1800].config_channels_configuration["activeCool_heat5"] = 10
pi[1800].config_channels_configuration["activeCool_heat6"] = 11
pi[1800].config_channels_configuration["activeCool_normal"] = 12
pi[1800].config_channels_configuration["activeTrigger"] = 13
pi[1800].config_channels_configuration["advanced_close_perc"] = 14
pi[1800].config_channels_configuration["advanced_shock_threshold"] = 15
pi[1800].config_channels_configuration["advanced_stage"] = 16
pi[1800].config_channels_configuration["advanced_time"] = 17
pi[1800].config_channels_configuration["air_cool_setpoint"] = 18
pi[1800].config_channels_configuration["air_heat_setpoint"] = 19
pi[1800].config_channels_configuration["air_temp_sensor"] = 20
pi[1800].config_channels_configuration["analog_max_mixv"] = 21
// pi[1800].config_channels_configuration["analog_max_mixv"] = 22
pi[1800].config_channels_configuration["analog_min_mixv"] = 23
pi[1800].config_channels_configuration["cool1"] = 24// used for Vent
pi[1800].config_channels_configuration["cool2"] = 25
pi[1800].config_channels_configuration["cool3"] = 26
pi[1800].config_channels_configuration["cool4"] = 27
pi[1800].config_channels_configuration["cool5"] = 28
pi[1800].config_channels_configuration["cool6"] = 29
pi[1800].config_channels_configuration["cooling_device"] = 30
pi[1800].config_channels_configuration["cycleOffPeriod"] = 31
pi[1800].config_channels_configuration["cycleOnPeriod"] = 32
pi[1800].config_channels_configuration["D"] = 33
pi[1800].config_channels_configuration["daytime_astroadjust"] = 34
pi[1800].config_channels_configuration["daytime_enabled"] = 35
pi[1800].config_channels_configuration["daytime_setpoint"] = 36
pi[1800].config_channels_configuration["daytime_stage"] = 37
pi[1800].config_channels_configuration["daytime_start"] = 38
pi[1800].config_channels_configuration["deadband"] = 39
pi[1800].config_channels_configuration["dir"] = 40
pi[1800].config_channels_configuration["disable_inside_temp_pid"] = 41
pi[1800].config_channels_configuration["dliEndTime"] = 42
pi[1800].config_channels_configuration["dliEnergyOutput"] = 43
pi[1800].config_channels_configuration["dliOnTime"] = 44
pi[1800].config_channels_configuration["dliSensor"] = 45
pi[1800].config_channels_configuration["dliStartTime"] = 46
pi[1800].config_channels_configuration["dliThreshold"] = 47
pi[1800].config_channels_configuration["enableActiveCoolStageOverride"] = 48
pi[1800].config_channels_configuration["enableSmartInject"] = 49
pi[1800].config_channels_configuration["end"] = 50
pi[1800].config_channels_configuration["end_astroadjust"] = 51
pi[1800].config_channels_configuration["end_time"] = 52
pi[1800].config_channels_configuration["energy_end"] = 53
pi[1800].config_channels_configuration["energy_end_astroadjust"] = 54
pi[1800].config_channels_configuration["energy_start"] = 55
pi[1800].config_channels_configuration["energy_start_astroadjust"] = 56
pi[1800].config_channels_configuration["feedingMode"] = 57
pi[1800].config_channels_configuration["fine_adjust_range"] = 58
pi[1800].config_channels_configuration["followChannelIndex"] = 59
pi[1800].config_channels_configuration["followRatio"] = 60
pi[1800].config_channels_configuration["function"] = 61
pi[1800].config_channels_configuration["function_float_max"] = 62
pi[1800].config_channels_configuration["function_float_min"] = 63
pi[1800].config_channels_configuration["function_int_max"] = 64
pi[1800].config_channels_configuration["function_int_min"] = 65
pi[1800].config_channels_configuration["gain"] = 66
pi[1800].config_channels_configuration["heat_on_delay"] = 67
pi[1800].config_channels_configuration["heat1"] = 68
pi[1800].config_channels_configuration["heat2"] = 69
pi[1800].config_channels_configuration["heat3"] = 70
pi[1800].config_channels_configuration["heat4"] = 71
pi[1800].config_channels_configuration["heat5"] = 72
pi[1800].config_channels_configuration["heat6"] = 73
pi[1800].config_channels_configuration["highlight_cover_perc"] = 74
pi[1800].config_channels_configuration["highlight_cover_trigger_light"] = 75
pi[1800].config_channels_configuration["highlight_cover_trigger_temp"] = 76
pi[1800].config_channels_configuration["hold_delay"] = 77
pi[1800].config_channels_configuration["I"] = 78
pi[1800].config_channels_configuration["increased_co2_level"] = 79
pi[1800].config_channels_configuration["injectingBatchTimeSeconds"] = 80
pi[1800].config_channels_configuration["injectingBatchVolumeMilliLiters"] = 81
pi[1800].config_channels_configuration["injectingLimitHours"] = 82
pi[1800].config_channels_configuration["injectingLimitMilliLiters"] = 83
pi[1800].config_channels_configuration["injectingLimitSeconds"] = 84
pi[1800].config_channels_configuration["injectingTimeSeconds"] = 85
pi[1800].config_channels_configuration["injectingVolumeMilliLiters"] = 86
pi[1800].config_channels_configuration["injectionRate"] = 87
pi[1800].config_channels_configuration["inside_temp_d"] = 88
pi[1800].config_channels_configuration["inside_temp_i"] = 89
pi[1800].config_channels_configuration["inside_temp_p"] = 90
pi[1800].config_channels_configuration["irrigation_mode"] = 91
pi[1800].config_channels_configuration["isCycling"] = 92
pi[1800].config_channels_configuration["keep_open_threshold_light"] = 93
pi[1800].config_channels_configuration["keep_open_threshold_temp"] = 94
// pi[1800].config_channels_configuration["light_drive_to"] = 95
pi[1800].config_channels_configuration["light_level"] = 96
pi[1800].config_channels_configuration["light_mode"] = 97
pi[1800].config_channels_configuration["light_sensor_enabled"] = 98
// pi[1800].config_channels_configuration["light_threshold"] = 99
pi[1800].config_channels_configuration["low_light_threshold_duration"] = 100
pi[1800].config_channels_configuration["low_light_threshold_light"] = 101
pi[1800].config_channels_configuration["mixingTimeSeconds"] = 102
pi[1800].config_channels_configuration["mode"] = 103
pi[1800].config_channels_configuration["nighttime_astroadjust"] = 104
pi[1800].config_channels_configuration["nighttime_enabled"] = 105
pi[1800].config_channels_configuration["nighttime_setpoint"] = 106
pi[1800].config_channels_configuration["nighttime_stage"] = 107
pi[1800].config_channels_configuration["nighttime_start"] = 108
// pi[1800].config_channels_configuration["normal_onoff"] = 109
pi[1800].config_channels_configuration["CD_normal_vent"] = 109

pi[1800].config_channels_configuration["normal_co2_level"] = 110
pi[1800].config_channels_configuration["off_delay"] = 111
pi[1800].config_channels_configuration["off_duration"] = 112
pi[1800].config_channels_configuration["off_threshold"] = 113
pi[1800].config_channels_configuration["offDelay"] = 114
pi[1800].config_channels_configuration["offset"] = 115
pi[1800].config_channels_configuration["on_duration"] = 116
pi[1800].config_channels_configuration["on_threshold"] = 117
pi[1800].config_channels_configuration["output_type"] = 118
pi[1800].config_channels_configuration["override_action_target"] = 119
pi[1800].config_channels_configuration["override_dir"] = 120
pi[1800].config_channels_configuration["override_limit"] = 121
pi[1800].config_channels_configuration["override_sensor"] = 122
pi[1800].config_channels_configuration["override_trigger"] = 123
pi[1800].config_channels_configuration["P"] = 124
pi[1800].config_channels_configuration["passive_cooling"] = 125
pi[1800].config_channels_configuration["probe_id"] = 126
pi[1800].config_channels_configuration["probeSensorIndex"] = 127
pi[1800].config_channels_configuration["probeType"] = 128
pi[1800].config_channels_configuration["pulse_ff_time"] = 129
pi[1800].config_channels_configuration["pulse_on_time"] = 130
pi[1800].config_channels_configuration["pump_mzone"] = 131
pi[1800].config_channels_configuration["pump_onoff"] = 348
pi[1800].config_channels_configuration["pump_id"] = 132
pi[1800].config_channels_configuration["pump_type"] = 133
pi[1800].config_channels_configuration["pumpMeasurementUnits"] = 134
pi[1800].config_channels_configuration["resetInjectionTracker"] = 135
pi[1800].config_channels_configuration["sensor_mzone"] = 136
// pi[1800].config_channels_configuration["sensorHoldTime"] = 137
pi[1800].config_channels_configuration["setpoint"] = 138
pi[1800].config_channels_configuration["setting_1_threshold_light"] = 139
pi[1800].config_channels_configuration["setting_1_threshold_temp"] = 140
pi[1800].config_channels_configuration["setting_2_threshold_light"] = 141
pi[1800].config_channels_configuration["setting_2_threshold_temp"] = 142
pi[1800].config_channels_configuration["shade_end"] = 143
pi[1800].config_channels_configuration["shade_end_astroadjust"] = 144
pi[1800].config_channels_configuration["shade_start"] = 145
pi[1800].config_channels_configuration["shade_start_astroadjust"] = 146
pi[1800].config_channels_configuration["shock_protect_enabled"] = 147
pi[1800].config_channels_configuration["shock_protect_open_valve_perc"] = 148
pi[1800].config_channels_configuration["shock_protect_threshold"] = 149
pi[1800].config_channels_configuration["shock_protect_time_closed"] = 150
pi[1800].config_channels_configuration["shock_protect_water_sensor"] = 151
pi[1800].config_channels_configuration["soil_moisture_input"] = 152
pi[1800].config_channels_configuration["soil_moisture_threshold"] = 153
pi[1800].config_channels_configuration["stage"] = 154
pi[1800].config_channels_configuration["start"] = 155
pi[1800].config_channels_configuration["start_astroadjust"] = 156
pi[1800].config_channels_configuration["start_time"] = 157
pi[1800].config_channels_configuration["start_time_1"] = 158
pi[1800].config_channels_configuration["start_time_2"] = 159
pi[1800].config_channels_configuration["start_time_3"] = 160
pi[1800].config_channels_configuration["start_time_4"] = 161
pi[1800].config_channels_configuration["start_time_5"] = 162
pi[1800].config_channels_configuration["start_time_6"] = 163
pi[1800].config_channels_configuration["supply_pump_mixv"] = 164// for mixvalve
pi[1800].config_channels_configuration["tankSensor"] = 165// obsolete! maybe used for irr tankSensor
pi[1800].config_channels_configuration["tankSize"] = 166
// pi[1800].config_channels_configuration["temp_drive_to"] = 167
// pi[1800].config_channels_configuration["temp_sensor"] = 168
// pi[1800].config_channels_configuration["temp_threshold"] = 169
pi[1800].config_channels_configuration["threshold"] = 170
pi[1800].config_channels_configuration["time"] = 171
pi[1800].config_channels_configuration["triggerDelay"] = 172
pi[1800].config_channels_configuration["valve_open_time"] = 173
pi[1800].config_channels_configuration["vpdacc_threshold"] = 174
pi[1800].config_channels_configuration["water_temp_d"] = 175
pi[1800].config_channels_configuration["water_temp_i"] = 176
pi[1800].config_channels_configuration["water_temp_max"] = 177
pi[1800].config_channels_configuration["water_temp_min"] = 178
pi[1800].config_channels_configuration["water_temp_p"] = 179
pi[1800].config_channels_configuration["water_temp_sensor"] = 180
pi[1800].config_channels_configuration["week_a_fri"] = 181
pi[1800].config_channels_configuration["week_a_mon"] = 182
pi[1800].config_channels_configuration["week_a_sat"] = 183
pi[1800].config_channels_configuration["week_a_sun"] = 184
pi[1800].config_channels_configuration["week_a_thu"] = 185
pi[1800].config_channels_configuration["week_a_tue"] = 186
pi[1800].config_channels_configuration["week_a_wed"] = 187
pi[1800].config_channels_configuration["week_b_fri"] = 188
pi[1800].config_channels_configuration["week_b_mon"] = 189
pi[1800].config_channels_configuration["week_b_sat"] = 190
pi[1800].config_channels_configuration["week_b_sun"] = 191
pi[1800].config_channels_configuration["week_b_thu"] = 192
pi[1800].config_channels_configuration["week_b_tue"] = 193
pi[1800].config_channels_configuration["week_b_wed"] = 194
//added
pi[1800].config_channels_configuration["triggers"] = 195
// pi[1800].config_channels_configuration["smartInject"] = 196
// pi[1800].config_channels_configuration["vent_mode"] = 197// should be vent_type!

// pi[1800].config_channels_configuration["P"] = 198
// pi[1800].config_channels_configuration["I"] = 199
// pi[1800].config_channels_configuration["D"] = 200
pi[1800].config_channels_configuration["acDeadband"] =	201
pi[1800].config_channels_configuration["acDoDelay"] =	202
pi[1800].config_channels_configuration["acPcnt_1"] =	203
pi[1800].config_channels_configuration["acPcnt_2"] =	204
pi[1800].config_channels_configuration["acPcnt_3"] =	205
pi[1800].config_channels_configuration["acPcnt_4"] =	206
pi[1800].config_channels_configuration["acTemp_1"] =	207
pi[1800].config_channels_configuration["acTemp_2"] =	208
pi[1800].config_channels_configuration["acTemp_3"] =	209
pi[1800].config_channels_configuration["acTemp_4"] =	210
pi[1800].config_channels_configuration["acUpDelay"] =	211
pi[1800].config_channels_configuration["autoCalibration"] =	212
pi[1800].config_channels_configuration["begin_closing_leeward_trigger_end"] =	213
pi[1800].config_channels_configuration["begin_closing_leeward_trigger_start"] =	214
pi[1800].config_channels_configuration["begin_closing_windward_trigger_end"] =	215
pi[1800].config_channels_configuration["begin_closing_windward_trigger_start"] =	216
pi[1800].config_channels_configuration["closing_delay"] =	217
pi[1800].config_channels_configuration["cool_1"] =	218// duplicates NO! these are used for the vents
pi[1800].config_channels_configuration["cool_2"] =	219
pi[1800].config_channels_configuration["cool_3"] =	220
pi[1800].config_channels_configuration["cool_4"] =	221
pi[1800].config_channels_configuration["cool_5"] =	222
pi[1800].config_channels_configuration["cool_6"] =	223
pi[1800].config_channels_configuration["cp2Delay"] =	224
pi[1800].config_channels_configuration["cp2Pos"] =	225
pi[1800].config_channels_configuration["cp2Wind"] =	226
pi[1800].config_channels_configuration["cp3Delay"] =	227
pi[1800].config_channels_configuration["cp3Pos"] =	228
pi[1800].config_channels_configuration["cp3Wind"] =	229
pi[1800].config_channels_configuration["cpDelay"] =	230
pi[1800].config_channels_configuration["cpPos"] =	231
pi[1800].config_channels_configuration["cpWind"] =	232
pi[1800].config_channels_configuration["direction"] =	233
pi[1800].config_channels_configuration["fireDelay"] =	234
pi[1800].config_channels_configuration["fireIn"] =	235
pi[1800].config_channels_configuration["firePos"] =	236
pi[1800].config_channels_configuration["hailDelay"] =	237
pi[1800].config_channels_configuration["hailPos"] =	238
pi[1800].config_channels_configuration["hailThresh"] =	239
pi[1800].config_channels_configuration["hgDeadband"] =	240
pi[1800].config_channels_configuration["hgDoDelay"] =	241
pi[1800].config_channels_configuration["hgPcnt_1"] =	242
pi[1800].config_channels_configuration["hgPcnt_2"] =	243
pi[1800].config_channels_configuration["hgPcnt_3"] =	244
pi[1800].config_channels_configuration["hgPcnt_4"] =	245
pi[1800].config_channels_configuration["hgTemp_1"] =	246
pi[1800].config_channels_configuration["hgTemp_2"] =	247
pi[1800].config_channels_configuration["hgTemp_3"] =	248
pi[1800].config_channels_configuration["hgTemp_4"] =	249
pi[1800].config_channels_configuration["hgUpDelay"] =	250
pi[1800].config_channels_configuration["max_open"] =	251
pi[1800].config_channels_configuration["max_open_rain"] =	252
pi[1800].config_channels_configuration["open_var"] =	253
pi[1800].config_channels_configuration["rhgDeadband"] =	254
pi[1800].config_channels_configuration["rhgDoDelay"] =	255
pi[1800].config_channels_configuration["rhgTemp_1"] =	256
pi[1800].config_channels_configuration["rhgTemp_2"] =	257
pi[1800].config_channels_configuration["rhgTemp_3"] =	258
pi[1800].config_channels_configuration["rhgTemp_4"] =	259
pi[1800].config_channels_configuration["rhgUpDelay"] =	260
pi[1800].config_channels_configuration["rtDeadband"] =	261
pi[1800].config_channels_configuration["rtDoDelay"] =	262
pi[1800].config_channels_configuration["rtHum_1"] =	263
pi[1800].config_channels_configuration["rtHum_2"] =	264
pi[1800].config_channels_configuration["rtHum_3"] =	265
pi[1800].config_channels_configuration["rtHum_4"] =	266
pi[1800].config_channels_configuration["rtPcnt_1"] =	267
pi[1800].config_channels_configuration["rtPcnt_2"] =	268
pi[1800].config_channels_configuration["rtPcnt_3"] =	269
pi[1800].config_channels_configuration["rtPcnt_4"] =	270
pi[1800].config_channels_configuration["rtUpDelay"] =	271
pi[1800].config_channels_configuration["spDelay"] =	272
pi[1800].config_channels_configuration["spPos"] =	273
pi[1800].config_channels_configuration["spWind"] =	274
pi[1800].config_channels_configuration["temp_below_trigger"] =	275
pi[1800].config_channels_configuration["temp_var"] =	276
pi[1800].config_channels_configuration["rhgPcnt_1"] =	277
pi[1800].config_channels_configuration["rhgPcnt_2"] =	278
pi[1800].config_channels_configuration["rhgPcnt_3"] =	279
pi[1800].config_channels_configuration["rhgPcnt_4"] =	280
pi[1800].config_channels_configuration["vent_type"] =	281
pi[1800].config_channels_configuration["vps_alarm_duration"] =	282
pi[1800].config_channels_configuration["vps_alarm_limit"] =	283
pi[1800].config_channels_configuration["vps_error_exception_limit"] =	284
pi[1800].config_channels_configuration["vps_sensor"] =	285

pi[1800].config_channels_configuration["CD_start_astroadjust_co2"] = 286
pi[1800].config_channels_configuration["CD_start_astroadjust_hid"] = 287
pi[1800].config_channels_configuration["CD_end_astroadjust_co2"] = 288
pi[1800].config_channels_configuration["CD_end_astroadjust_hid"] = 289
pi[1800].config_channels_configuration["CD_start_hid"] = 290
pi[1800].config_channels_configuration["CD_end_hid"] = 291
pi[1800].config_channels_configuration["CD_tankSensor_fval"] = 292
pi[1800].config_channels_configuration["CD_time_generic"] = 293
pi[1800].config_channels_configuration["CD_acPcnt_1_cvent"] = 294
pi[1800].config_channels_configuration["CD_acPcnt_2_cvent"] = 295
pi[1800].config_channels_configuration["CD_acPcnt_3_cvent"] = 296
pi[1800].config_channels_configuration["CD_acPcnt_4_cvent"] = 297
pi[1800].config_channels_configuration["CD_acTemp_1_cvent"] = 298
pi[1800].config_channels_configuration["CD_acTemp_2_cvent"] = 299
pi[1800].config_channels_configuration["CD_acTemp_3_cvent"] = 300
pi[1800].config_channels_configuration["CD_acTemp_4_cvent"] = 301
pi[1800].config_channels_configuration["CD_hgPcnt_1_cvent"] = 302
pi[1800].config_channels_configuration["CD_hgPcnt_2_cvent"] = 303
pi[1800].config_channels_configuration["CD_hgPcnt_3_cvent"] = 304
pi[1800].config_channels_configuration["CD_hgPcnt_4_cvent"] = 305
pi[1800].config_channels_configuration["CD_hgTemp_1_cvent"] = 306
pi[1800].config_channels_configuration["CD_hgTemp_2_cvent"] = 307
pi[1800].config_channels_configuration["CD_hgTemp_3_cvent"] = 308
pi[1800].config_channels_configuration["CD_hgTemp_4_cvent"] = 309
pi[1800].config_channels_configuration["CD_rhgTemp_1_cvent"] = 310
pi[1800].config_channels_configuration["CD_rhgTemp_2_cvent"] = 311
pi[1800].config_channels_configuration["CD_rhgTemp_3_cvent"] = 312
pi[1800].config_channels_configuration["CD_rhgTemp_4_cvent"] = 313
pi[1800].config_channels_configuration["CD_rtHum_1_cvent"] = 314
pi[1800].config_channels_configuration["CD_rtHum_2_cvent"] = 315
pi[1800].config_channels_configuration["CD_rtHum_3_cvent"] = 316
pi[1800].config_channels_configuration["CD_rtHum_4_cvent"] = 317
pi[1800].config_channels_configuration["CD_rtPcnt_1_cvent"] = 318
pi[1800].config_channels_configuration["CD_rtPcnt_2_cvent"] = 319
pi[1800].config_channels_configuration["CD_rtPcnt_3_cvent"] = 320
pi[1800].config_channels_configuration["CD_rtPcnt_4_cvent"] = 321
pi[1800].config_channels_configuration["CD_rhgPcnt_1_cvent"] = 322
pi[1800].config_channels_configuration["CD_rhgPcnt_2_cvent"] = 323
pi[1800].config_channels_configuration["CD_rhgPcnt_3_cvent"] = 324
pi[1800].config_channels_configuration["CD_rhgPcnt_4_cvent"] = 325
pi[1800].config_channels_configuration["CD_start_time_1_hid"] = 326
pi[1800].config_channels_configuration["CD_start_time_2_hid"] = 327
pi[1800].config_channels_configuration["CD_start_time_3_hid"] = 328
pi[1800].config_channels_configuration["CD_start_time_4_hid"] = 329
pi[1800].config_channels_configuration["CD_start_time_5_hid"] = 330
pi[1800].config_channels_configuration["CD_start_time_6_hid"] = 331
pi[1800].config_channels_configuration["CD_week_a_fri_hid"] = 332
pi[1800].config_channels_configuration["CD_week_a_mon_hid"] = 333
pi[1800].config_channels_configuration["CD_week_a_sat_hid"] = 334
pi[1800].config_channels_configuration["CD_week_a_sun_hid"] = 335
pi[1800].config_channels_configuration["CD_week_a_thu_hid"] = 336
pi[1800].config_channels_configuration["CD_week_a_tue_hid"] = 337
pi[1800].config_channels_configuration["CD_week_a_wed_hid"] = 338
pi[1800].config_channels_configuration["CD_week_b_fri_hid"] = 339
pi[1800].config_channels_configuration["CD_week_b_mon_hid"] = 340
pi[1800].config_channels_configuration["CD_week_b_sat_hid"] = 341
pi[1800].config_channels_configuration["CD_week_b_sun_hid"] = 342
pi[1800].config_channels_configuration["CD_week_b_thu_hid"] = 343
pi[1800].config_channels_configuration["CD_week_b_tue_hid"] = 344
pi[1800].config_channels_configuration["CD_week_b_wed_hid"] = 345
pi[1800].config_channels_configuration["CD_sensor_generic"] = 346
pi[1800].config_channels_configuration["CD_mode_varout"] = 347
pi[1800].config_channels_configuration["CD_pump_onoff"] = 348
pi[1800].config_channels_configuration["CD_supply_pump_generic"] = 349
pi[1800].config_channels_configuration["CD_analog_min_varout"] = 350
pi[1800].config_channels_configuration["CD_analog_min_generic"] = 351
pi[1800].config_channels_configuration["CD_analog_max_varout"] = 352
pi[1800].config_channels_configuration["CD_analog_max_generic"] = 353
pi[1800].config_channels_configuration["CD_setpoint_generic"] = 354
pi[1800].config_channels_configuration["CD_activeTrigger_fval"] = 355

pi[1800].config_channels_configuration["CD_activeCool_cool1_onoff"] = 356// of On/Off
pi[1800].config_channels_configuration["CD_activeCool_cool2_onoff"] = 357
pi[1800].config_channels_configuration["CD_activeCool_cool3_onoff"] = 358
pi[1800].config_channels_configuration["CD_activeCool_cool4_onoff"] = 359
pi[1800].config_channels_configuration["CD_activeCool_cool5_onoff"] = 360
pi[1800].config_channels_configuration["CD_activeCool_cool6_onoff"] = 361
pi[1800].config_channels_configuration["CD_activeCool_normal_onoff"] = 362
pi[1800].config_channels_configuration["CD_heat_6"] = 363// not used - reenabled on 20220305
pi[1800].config_channels_configuration["CD_heat_5"] = 364
pi[1800].config_channels_configuration["CD_heat_4"] = 365
pi[1800].config_channels_configuration["CD_heat_3"] = 366
pi[1800].config_channels_configuration["CD_heat_2"] = 367
pi[1800].config_channels_configuration["CD_heat_1"] = 368
// pi[1800].config_channels_configuration["CD_normal_vent"] = 369
pi[1800].config_channels_configuration["normal_onoff"] = 369

pi[1800].config_channels_configuration["CD_cool_1"] = 370
pi[1800].config_channels_configuration["CD_cool_2"] = 371
pi[1800].config_channels_configuration["CD_cool_3"] = 372
pi[1800].config_channels_configuration["CD_cool_4"] = 373
pi[1800].config_channels_configuration["CD_cool_5"] = 374
pi[1800].config_channels_configuration["CD_cool_6"] = 375

pi[1800].config_channels_configuration["CD_sensorHoldTime"] = 376
pi[1800].config_channels_configuration["CD_light_threshold"] = 377
pi[1800].config_channels_configuration["CD_light_drive_to"] = 378
pi[1800].config_channels_configuration["CD_temp_sensor"] = 379
pi[1800].config_channels_configuration["CD_temp_threshold"] = 380
pi[1800].config_channels_configuration["CD_temp_drive_to"] = 381
pi[1800].config_channels_configuration["CD_enableSmartInject"] = 382

pi[1800].config_channels_configuration["CD_trigStartType_fval"] = 387// triggers
pi[1800].config_channels_configuration["CD_trigStartIDX_fval"] = 388
pi[1800].config_channels_configuration["CD_trigStopType_fval"] = 389
pi[1800].config_channels_configuration["CD_trigStopIDX_fval"] = 390
pi[1800].config_channels_configuration["CD_time_sec_fval"] = 391
pi[1800].config_channels_configuration["CD_volume_fval"] = 392

pi[1800].config_channels_configuration["CD_trigStartType_pump"] = 405
pi[1800].config_channels_configuration["CD_trigStartIDX_pump"] = 406
pi[1800].config_channels_configuration["CD_batchVolume_pump"] = 407

pi[1800].config_channels_configuration["CD_minDifferencex10_pump"] = 414
pi[1800].config_channels_configuration["CD_ratiox100_pump"] = 415

pi[1800].snapshot_aux_alarms["alarmValue"] = 0;;// 1024
pi[1800].snapshot_aux_persistent_variables["variableValue"] = 0;;// 1056
pi[1800].snapshot_aux_variables["variableValue"] = 0;;// 1056
pi[1800].snapshot_expansion_boards["boardStatus"] = 0;;// 1056




//var config_aux_alarms = {}
pi[1800].config_aux_alarms["alarmIndex"] = 0
pi[1800].config_aux_alarms["alarmName"] = 1
pi[1800].config_aux_alarms["allowGraphing"] = 2
pi[1800].config_aux_alarms["userComment"] = 3

//var config_aux_controls = {}
pi[1800].config_aux_controls["auxIndex"] = 0
pi[1800].config_aux_controls["unix_timestamp(modified)"] = 1
pi[1800].config_aux_controls["operand1Type"] = 2
pi[1800].config_aux_controls["operand1Value"] = 3
pi[1800].config_aux_controls["operand2Type"] = 4
pi[1800].config_aux_controls["operand2Value"] = 5
pi[1800].config_aux_controls["operand3Type"] = 6
pi[1800].config_aux_controls["operand3Value"] = 7
pi[1800].config_aux_controls["operator1"] = 8
pi[1800].config_aux_controls["operator2"] = 9
pi[1800].config_aux_controls["conditionSeconds"] = 10
pi[1800].config_aux_controls["action"] = 11
pi[1800].config_aux_controls["targetType"] = 12
pi[1800].config_aux_controls["targetValue"] = 13
pi[1800].config_aux_controls["actionParameter"] = 14
pi[1800].config_aux_controls["actionHoldTime"] = 15
pi[1800].config_aux_controls["userComment"] = 16

//var config_aux_persistent_variables = {}
// config_aux_persistent_variables["zoneIndex"] = 827
// config_aux_persistent_variables["unitIndex"] = 828
pi[1800].config_aux_persistent_variables["variableIndex"] = 0
pi[1800].config_aux_persistent_variables["variableName"] = 1
pi[1800].config_aux_persistent_variables["allowGraphing"] = 2
pi[1800].config_aux_persistent_variables["userComment"] = 3

pi[1800].config_annex_sensors["alarmEnable"] = 0
pi[1800].config_annex_sensors["alarmLow"] = 1
pi[1800].config_annex_sensors["alarmHigh"] = 2
pi[1800].config_annex_sensors["alarmDeadband"] = 3
pi[1800].config_annex_sensors["alarmDelay"] = 4
pi[1800].config_annex_sensors["alarmLevel"] = 5
// pi[1800].config_annex_sensors["notifyWhom"] = 6
pi[1800].config_annex_sensors["notifyHowOften"] = 7
pi[1800].config_annex_sensors["notifyPerDay"] = 8
pi[1800].config_annex_sensors["notifySnoozeEnable"] = 9
pi[1800].config_annex_sensors["notifySnooze"] = 10
pi[1800].config_annex_sensors["alarmTime"] = 11
pi[1800].config_annex_sensors["alarmState"] = 12
pi[1800].config_annex_sensors["notifyTime"] = 13
pi[1800].config_annex_sensors["notifyCount"] = 14// 20 slots reseverved
pi[1800].config_annex_sensors["rangeLow"] = 15
pi[1800].config_annex_sensors["rangeHigh"] = 16
pi[1800].config_annex_sensors["midLow"] = 17
pi[1800].config_annex_sensors["midHigh"] = 18

pi[1800].config_added_sensors["type"] = 0// 16 values reserved
pi[1800].config_added_sensors["unit"] = 1// starting at 0x4E00=19968
pi[1800].config_added_sensors["channelCount"] = 2// 16 values reserved
pi[1800].config_added_sensors["key"] = 3
pi[1800].config_added_sensors["cert"] = 4
pi[1800].config_added_sensors["ca"] = 5
pi[1800].config_added_sensors["host"] = 6
pi[1800].config_added_sensors["sensorId"] = 7
pi[1800].config_added_sensors["dashboardId"] = 8
pi[1800].config_added_sensors["value"] = 12

//var config_aux_variables = {}
// axxx["zoneIndex"] = 834
// axxx["unitIndex"] = 835
pi[1800].config_aux_variables["variableIndex"] = 0
pi[1800].config_aux_variables["variableName"] = 1
pi[1800].config_aux_variables["allowGraphing"] = 2
pi[1800].config_aux_variables["userComment"] = 3

//var config_expansion_boards = {}
// zxx["zoneIndex"] =
// zxx["unitIndex"] =
pi[1800].config_expansion_boards["boardIndex"] = 0 // 64
pi[1800].config_expansion_boards["unix_timestamp(modified)"] = 1
pi[1800].config_expansion_boards["boardType"] = 2
pi[1800].config_expansion_boards["address"] = 3
pi[1800].config_expansion_boards["startChannelIndex"] = 4

//var config_setpoints = {}
// zxx["zoneIndex"] =
// pi[1800].config_setpoints["setpointIndex"] = 0 // 8 * 10// can be used for setpointName
pi[1800].config_setpoints["name"] = 0 // 8 * 10// can be used for setpointName
pi[1800].config_setpoints["unix_timestamp(modified)"] = 1
pi[1800].config_setpoints["enabled"] = 2
pi[1800].config_setpoints["startTimeOfDay"] = 3
pi[1800].config_setpoints["astroAdjust"] = 4
pi[1800].config_setpoints["rampMinutes"] = 5
pi[1800].config_setpoints["heatSetpoint"] = 6
pi[1800].config_setpoints["coolSetpoint"] = 7
pi[1800].config_setpoints["humidifySetpoint"] = 8
pi[1800].config_setpoints["dehumidifySetpoint"] = 9

//var config_ecph = {}
// zzz["zoneIndex"] =
// zzz["unitIndex"] =
pi[1800].config_ecph["ecphIndex"] = 0 // 8 * 11 of these
pi[1800].config_ecph["unix_timestamp(modified)"] = 1
pi[1800].config_ecph["name"] = 2
pi[1800].config_ecph["ecType"] = 3
pi[1800].config_ecph["alarmHoldTime"] = 4
pi[1800].config_ecph["lowECThreshold"] = 5
pi[1800].config_ecph["highECThreshold"] = 6
pi[1800].config_ecph["highECDeviationThreshold"] = 7
pi[1800].config_ecph["lowPHThreshold"] = 8
pi[1800].config_ecph["highPHThreshold"] = 9
pi[1800].config_ecph["highPHDeviationThreshold"] = 10

//var config_ecph_sensors = {}
// config_ecph_sensors["zoneIndex"] =
// config_ecph_sensors["unitIndex"] =
// config_ecph_sensors["ecphIndex"] =
// config_ecph_sensors["sensorIndex"] =
pi[1800].config_ecph_sensors["(modified)"] = 0
pi[1800].config_ecph_sensors["ecMapping"] = 1
pi[1800].config_ecph_sensors["ecServiceIntervalDays"] = 2
pi[1800].config_ecph_sensors["ecServiceTime"] = 3
pi[1800].config_ecph_sensors["ecCalibrationIntervalDays"] = 4
pi[1800].config_ecph_sensors["ecCalibrationTime"] = 5
pi[1800].config_ecph_sensors["ecCalibration1Value"] = 6
pi[1800].config_ecph_sensors["ecCalibration1Raw"] = 7
pi[1800].config_ecph_sensors["ecCalibration2Value"] = 8
pi[1800].config_ecph_sensors["ecCalibration2Raw"] = 9
pi[1800].config_ecph_sensors["phMapping"] = 10
pi[1800].config_ecph_sensors["phServiceIntervalDays"] = 11
pi[1800].config_ecph_sensors["phServiceTime"] = 12
pi[1800].config_ecph_sensors["phCalibrationIntervalDays"] = 13
pi[1800].config_ecph_sensors["phCalibrationTime"] = 14
pi[1800].config_ecph_sensors["phCalibration1Value"] = 15
pi[1800].config_ecph_sensors["phCalibration1Raw"] = 16
pi[1800].config_ecph_sensors["phCalibration2Value"] = 17
pi[1800].config_ecph_sensors["phCalibration2Raw"] = 18
pi[1800].config_ecph_sensors["temperatureMapping"] = 19
pi[1800].config_ecph_sensors["temperatureCalibration"] = 20
pi[1800].config_ecph_sensors["temperatureCompensationMode"] = 21
pi[1800].config_ecph_sensors["temperatureCompensationValue"] = 22

//var config_zones = {}
// config_zones["zoneIndex"] =
pi[1800].config_zones["name"] = 0
pi[1800].config_zones["description"] = 1

//var config_controllers = {}
// config_controllers["zoneIndex"] =
// config_controllers["unitIndex"] =
pi[1800].config_controllers["address"] = 0
pi[1800].config_controllers["igrowVersion"] = 1
pi[1800].config_controllers["isConnected"] = 2
pi[1800].config_controllers["isInNetwork"] = 3

//var config_communication_status = {}
pi[1800].config_communication_status["statusID"] = 0
pi[1800].config_communication_status["unix_timestamp(statusTime)"] = 1
pi[1800].config_communication_status["statusLog"] = 2

//var controller_configuration_settings = {}// 20210706, these are getting the *2+1 treatment - Probably Not!
pi[1800].controller_configuration_settings["Inside Temperature Mapping"] = 1
pi[1800].controller_configuration_settings["Relative Humidity Mapping"] = 2
pi[1800].controller_configuration_settings["Local Temperature Mapping"] = 3
pi[1800].controller_configuration_settings["Local Humidity Mapping"] = 4
pi[1800].controller_configuration_settings["Outside Temperature Mapping"] = 5
pi[1800].controller_configuration_settings["Outside Humidity Mapping"] = 6
pi[1800].controller_configuration_settings["CO2 Mapping"] = 7
pi[1800].controller_configuration_settings["Outside Light Mapping"] = 8
pi[1800].controller_configuration_settings["Wind Speed Mapping"] = 9
pi[1800].controller_configuration_settings["Wind Direction Mapping"] = 10
pi[1800].controller_configuration_settings["Rain Mapping"] = 11
pi[1800].controller_configuration_settings["Analog Temperature 1 Mapping"] = 12
pi[1800].controller_configuration_settings["Analog Temperature 2 Mapping"] = 13
pi[1800].controller_configuration_settings["Analog Temperature 3 Mapping"] = 14
pi[1800].controller_configuration_settings["Analog Temperature 4 Mapping"] = 15
pi[1800].controller_configuration_settings["Analog Temperature 5 Mapping"] = 16
pi[1800].controller_configuration_settings["Soil Moisture 1 Mapping"] = 17
pi[1800].controller_configuration_settings["Soil Moisture 2 Mapping"] = 18
pi[1800].controller_configuration_settings["Soil Moisture 3 Mapping"] = 19
pi[1800].controller_configuration_settings["Soil Moisture 4 Mapping"] = 20
pi[1800].controller_configuration_settings["Soil Moisture 5 Mapping"] = 21
pi[1800].controller_configuration_settings["Vent Position Sensor 1 Mapping"] = 22
pi[1800].controller_configuration_settings["Vent Position Sensor 2 Mapping"] = 23
pi[1800].controller_configuration_settings["Vent Position Sensor 3 Mapping"] = 24
pi[1800].controller_configuration_settings["Vent Position Sensor 4 Mapping"] = 25
pi[1800].controller_configuration_settings["Vent Position Sensor 5 Mapping"] = 26
pi[1800].controller_configuration_settings["EC/pH 1 Mapping"] = 27
pi[1800].controller_configuration_settings["EC/pH 2 Mapping"] = 28
pi[1800].controller_configuration_settings["EC/pH 3 Mapping"] = 29
pi[1800].controller_configuration_settings["EC/pH 4 Mapping"] = 30
pi[1800].controller_configuration_settings["EC/pH 5 Mapping"] = 31
pi[1800].controller_configuration_settings["EC/pH 1 Probe Type"] = 32
pi[1800].controller_configuration_settings["EC/pH 2 Probe Type"] = 33
pi[1800].controller_configuration_settings["EC/pH 3 Probe Type"] = 34
pi[1800].controller_configuration_settings["EC/pH 4 Probe Type"] = 35
pi[1800].controller_configuration_settings["EC/pH 5 Probe Type"] = 36
pi[1800].controller_configuration_settings["Generic 1 Mapping"] = 37
pi[1800].controller_configuration_settings["Generic 2 Mapping"] = 38
pi[1800].controller_configuration_settings["Inside Temperature Calibration"] = 39
pi[1800].controller_configuration_settings["Relative Humidity Calibration"] = 40
pi[1800].controller_configuration_settings["Outside Temperature Calibration"] = 41
pi[1800].controller_configuration_settings["Outside Humidity Calibration"] = 42
pi[1800].controller_configuration_settings["CO2 Calibration"] = 43
pi[1800].controller_configuration_settings["Outside Light Calibration"] = 44
pi[1800].controller_configuration_settings["Wind Speed Calibration"] = 45
pi[1800].controller_configuration_settings["Fallback Temperature Calibration"] = 46
pi[1800].controller_configuration_settings["Analog Temperature 1 Calibration"] = 47
pi[1800].controller_configuration_settings["Analog Temperature 2 Calibration"] = 48
pi[1800].controller_configuration_settings["Analog Temperature 3 Calibration"] = 49
pi[1800].controller_configuration_settings["Analog Temperature 4 Calibration"] = 50
pi[1800].controller_configuration_settings["Analog Temperature 5 Calibration"] = 51
pi[1800].controller_configuration_settings["Soil Mositure 1 Calibration"] = 52
pi[1800].controller_configuration_settings["Soil Mositure 2 Calibration"] = 53
pi[1800].controller_configuration_settings["Soil Mositure 3 Calibration"] = 54
pi[1800].controller_configuration_settings["Soil Mositure 4 Calibration"] = 55
pi[1800].controller_configuration_settings["Soil Mositure 5 Calibration"] = 56
pi[1800].controller_configuration_settings["Vent Position Sensor 1 Calibration - Adjust"] = 57
pi[1800].controller_configuration_settings["Vent Position Sensor 2 Calibration - Adjust"] = 58
pi[1800].controller_configuration_settings["Vent Position Sensor 3 Calibration - Adjust"] = 59
pi[1800].controller_configuration_settings["Vent Position Sensor 4 Calibration - Adjust"] = 60
pi[1800].controller_configuration_settings["Vent Position Sensor 5 Calibration - Adjust"] = 61
pi[1800].controller_configuration_settings["EC/pH 1 Calibration - Zero"] = 62
pi[1800].controller_configuration_settings["EC/pH 2 Calibration - Zero"] = 63
pi[1800].controller_configuration_settings["EC/pH 3 Calibration - Zero"] = 64
pi[1800].controller_configuration_settings["EC/pH 4 Calibration - Zero"] = 65
pi[1800].controller_configuration_settings["EC/pH 5 Calibration - Zero"] = 66
pi[1800].controller_configuration_settings["EC/pH 1 Calibration - Gain"] = 67
pi[1800].controller_configuration_settings["EC/pH 2 Calibration - Gain"] = 68
pi[1800].controller_configuration_settings["EC/pH 3 Calibration - Gain"] = 69
pi[1800].controller_configuration_settings["EC/pH 4 Calibration - Gain"] = 70
pi[1800].controller_configuration_settings["EC/pH 5 Calibration - Gain"] = 71
pi[1800].controller_configuration_settings["EC/pH 1 Calibration - Given Gain"] = 72
pi[1800].controller_configuration_settings["EC/pH 2 Calibration - Given Gain"] = 73
pi[1800].controller_configuration_settings["EC/pH 3 Calibration - Given Gain"] = 74
pi[1800].controller_configuration_settings["EC/pH 4 Calibration - Given Gain"] = 75
pi[1800].controller_configuration_settings["EC/pH 5 Calibration - Given Gain"] = 76
pi[1800].controller_configuration_settings["Generic 1 Calibration"] = 77
pi[1800].controller_configuration_settings["Generic 2 Calibration"] = 78
pi[1800].controller_configuration_settings["CO2 Least Significant Bit"] = 79
pi[1800].controller_configuration_settings["Light Multiplier"] = 80
pi[1800].controller_configuration_settings["Generic 1 Multiplier"] = 81
pi[1800].controller_configuration_settings["Generic 2 Multiplier"] = 82
pi[1800].controller_configuration_settings["Local/Remote Setpoints"] = 83
pi[1800].controller_configuration_settings["Enable Bump Vents"] = 84
pi[1800].controller_configuration_settings["Bump Intervals Minutes)"] = 85
pi[1800].controller_configuration_settings["Spike Temperature Delta"] = 86
pi[1800].controller_configuration_settings["Spike Temperature Hold Time Exception"] = 87
pi[1800].controller_configuration_settings["Outside Temperature 2 Mapping"] = 88
pi[1800].controller_configuration_settings["Outside Temperature 2 Calibration"] = 89
pi[1800].controller_configuration_settings["Barometric Pressure Mapping"] = 90
pi[1800].controller_configuration_settings["Barometric Pressure Calibration"] = 91
pi[1800].controller_configuration_settings["Enable expansion board"] = 92
pi[1800].controller_configuration_settings["Autodetect Mode"] = 93
pi[1800].controller_configuration_settings["Heat Demand Offset"] = 94
pi[1800].controller_configuration_settings["Cool Demand Offset"] = 95
pi[1800].controller_configuration_settings["Generic Sensor 1 Range"] = 96
pi[1800].controller_configuration_settings["Generic Sensor 1 Units"] = 97
pi[1800].controller_configuration_settings["Generic Sensor 1 Filter Max Samples"] = 98
pi[1800].controller_configuration_settings["Generic Sensor 2 Range"] = 99
pi[1800].controller_configuration_settings["Generic Sensor 2 Units"] = 100
pi[1800].controller_configuration_settings["Generic PID Multipliers P"] = 101
pi[1800].controller_configuration_settings["Generic PID Multipliers I"] = 102
pi[1800].controller_configuration_settings["Generic PID Multipliers D"] = 103
pi[1800].controller_configuration_settings["Expansion Output 1 Type"] = 109
pi[1800].controller_configuration_settings["Expansion Output 2 Type"] = 110
pi[1800].controller_configuration_settings["Expansion Input 1 Type"] = 111
pi[1800].controller_configuration_settings["Expansion Input 2 Type"] = 112
pi[1800].controller_configuration_settings["Sensor Delay"] = 113
pi[1800].controller_configuration_settings["Vent Position 1 Calibration - Open"] = 114
pi[1800].controller_configuration_settings["Vent Position 2 Calibration - Open"] = 115
pi[1800].controller_configuration_settings["Vent Position 3 Calibration - Open"] = 116
pi[1800].controller_configuration_settings["Vent Position 4 Calibration - Open"] = 117
pi[1800].controller_configuration_settings["Vent Position 5 Calibration - Open"] = 118
pi[1800].controller_configuration_settings["Vent Position 1 Calibration - Close"] = 119
pi[1800].controller_configuration_settings["Vent Position 2 Calibration - Close"] = 120
pi[1800].controller_configuration_settings["Vent Position 3 Calibration - Close"] = 121
pi[1800].controller_configuration_settings["Vent Position 4 Calibration - Close"] = 122
pi[1800].controller_configuration_settings["Vent Position 5 Calibration - Close"] = 123
pi[1800].controller_configuration_settings["Inside Light Mapping"] = 124
pi[1800].controller_configuration_settings["Differential Pressure Mapping"] = 125
pi[1800].controller_configuration_settings["Snow Mapping"] = 126
pi[1800].controller_configuration_settings["Inside Light Calibration"] = 127
pi[1800].controller_configuration_settings["Differential Pressure Calibration"] = 128
pi[1800].controller_configuration_settings["Accumulator 1 - Sensor"] = 129
pi[1800].controller_configuration_settings["Accumulator 1 - Operating Period"] = 130
pi[1800].controller_configuration_settings["Accumulator 2 - Sensor"] = 131
pi[1800].controller_configuration_settings["Accumulator 2 - Operating Period"] = 132
pi[1800].controller_configuration_settings["Generic Sensor 2 Filter Max Samples"] = 133
pi[1800].controller_configuration_settings["Hold Temp Vent Open"] = 134
pi[1800].controller_configuration_settings["Irrigation Trigger Input Mapping"] = 135
pi[1800].controller_configuration_settings["Fallback Sensor Input Mapping"] = 136
pi[1800].controller_configuration_settings["Enable Expansion Board"] = 137
pi[1800].controller_configuration_settings["Enable Loud Vent"] = 138
pi[1800].controller_configuration_settings["Wind Direction Calibration"] = 139
pi[1800].controller_configuration_settings["Rain Calibration"] = 140
pi[1800].controller_configuration_settings["Snow Calibration"] = 141
pi[1800].controller_configuration_settings["Inside Light Multiplier"] = 142
pi[1800].controller_configuration_settings["Canopy Sensor Mapping"] = 143
pi[1800].controller_configuration_settings["Canopy Sensor Calibration"] = 144
pi[1800].controller_configuration_settings["Max Temperature Adjustment"] = 145
pi[1800].controller_configuration_settings["Temperature Average Enable"] = 146
pi[1800].controller_configuration_settings["Temperature Average Include Temp1"] = 147
pi[1800].controller_configuration_settings["Temperature Average Include Temp2"] = 148
pi[1800].controller_configuration_settings["Temperature Average Include Temp3"] = 149
pi[1800].controller_configuration_settings["Temperature Average Include Temp4"] = 150
pi[1800].controller_configuration_settings["Temperature Average Include Temp5"] = 151
pi[1800].controller_configuration_settings["VPD Notify"] = 152
pi[1800].controller_configuration_settings["VPD Min"] = 153
pi[1800].controller_configuration_settings["VPD Max"] = 154
pi[1800].controller_configuration_settings["Cloud 1800 Enable"] = 155
pi[1800].controller_configuration_settings["Save Inside Temperature Calibration"] = 156
pi[1800].controller_configuration_settings["Save Temperature Average Enable"] = 157

//var zone_configuration_settings = {} each of these has *2* ids
// these are taken from the server version:
//    pi[1800].zone_configuration_settings["Use Fallback Sensor if In Temp Fails"] = 	3
//    pi[1800].zone_configuration_settings["Fallback Sensor Failed Temperature Stage"] = 	7
//    pi[1800].zone_configuration_settings["Force To No Sensor Stage setting 3) if High Alarm occurs"] = 	9
//    pi[1800].zone_configuration_settings["Rain Hold Time"] = 	11
//    pi[1800].zone_configuration_settings["Command Delay Time"] = 	13
//    pi[1800].zone_configuration_settings["Daylight Savings Time"] = 	15
//    pi[1800].zone_configuration_settings["Log History"] = 	17
//    pi[1800].zone_configuration_settings["Latitude"] = 	19
//    pi[1800].zone_configuration_settings["Longitude"] = 	21
//    pi[1800].zone_configuration_settings["Temperature Units"] = 	23
//    pi[1800].zone_configuration_settings["Windspeed Units"] = 	25
//    pi[1800].zone_configuration_settings["Light Units"] = 	27
//    pi[1800].zone_configuration_settings["Irrigation Mode"] = 	29
//    pi[1800].zone_configuration_settings["Irrigation Delay"] = 	31
//    pi[1800].zone_configuration_settings["High Alarm Temperature Above Cool Setpoint Threshold"] = 	35
//    pi[1800].zone_configuration_settings["High Alarm Temperature Hold Time"] = 	37
//    pi[1800].zone_configuration_settings["Low Alarm Temperature Below Heat Setpoint Threshold"] = 	39
//    pi[1800].zone_configuration_settings["Low Alarm Temperature Hold Time"] = 	41
//    pi[1800].zone_configuration_settings["Cool Deadband"] = 	43
//    pi[1800].zone_configuration_settings["Heat Deadband"] = 	45
//    pi[1800].zone_configuration_settings["Humidity Deadband"] = 	47
//    pi[1800].zone_configuration_settings["Analog Temperature Deadband"] = 	49
//    pi[1800].zone_configuration_settings["Enable SmartCool"] = 	51
//    pi[1800].zone_configuration_settings["SmartCool Setting"] = 	53
//    pi[1800].zone_configuration_settings["Show Heat Demand on iGrow"] = 	65
//    pi[1800].zone_configuration_settings["Heat Demand Max Light"] = 	67
//    pi[1800].zone_configuration_settings["Heat Demand Max Wind"] = 	69
//    pi[1800].zone_configuration_settings["Greenhouse heating design delta-T"] = 	71
//    pi[1800].zone_configuration_settings["Light''s influence on Heat Demand"] = 	73
//    pi[1800].zone_configuration_settings["Wind''s influence on Heat Demand"] = 	75
//    pi[1800].zone_configuration_settings["Show Cool Demand on iGrow"] = 	77
//    pi[1800].zone_configuration_settings["Cool Demand Max Light"] = 	79
//    pi[1800].zone_configuration_settings["Greenhouse cooling design delta-T"] = 	81
//    pi[1800].zone_configuration_settings["Cool Demand Light Factor"] = 	83
//    pi[1800].zone_configuration_settings["Enable Active Cooling"] = 	85
//    pi[1800].zone_configuration_settings["Passive Lock Stage"] = 	87
//    pi[1800].zone_configuration_settings["Enter Active Cooling Cool Demand Threshold"] = 	89
//    pi[1800].zone_configuration_settings["Exit Active Cooling Cool Demand Threshold"] = 	91
//    pi[1800].zone_configuration_settings["Cool stages"] = 	93
//    pi[1800].zone_configuration_settings["Heat stages"] = 	95
//    pi[1800].zone_configuration_settings["Stage Width"] = 	97
//    pi[1800].zone_configuration_settings["Stage Override 1 Enabled"] = 	99
//    pi[1800].zone_configuration_settings["Stage Override 1 Temperature Stage"] = 	101
//    pi[1800].zone_configuration_settings["Stage Override 1 Start Time"] = 	103
//    pi[1800].zone_configuration_settings["Stage Override 1 End Time"] = 	105
//    pi[1800].zone_configuration_settings["Stage Override 1 Interval"] = 	107
//    pi[1800].zone_configuration_settings["Stage Override 2 Enabled"] = 	109
//    pi[1800].zone_configuration_settings["Stage Override 2 Temperature Stage"] = 	111
//    pi[1800].zone_configuration_settings["Stage Override 2 Start Time"] = 	113
//    pi[1800].zone_configuration_settings["Stage Override 2 End Time"] = 	115
//    pi[1800].zone_configuration_settings["Stage Override 2 Interval"] = 	117
//    pi[1800].zone_configuration_settings["Stage Override 3 Enabled"] = 	119
//    pi[1800].zone_configuration_settings["Stage Override 3 Temperature Stage"] = 	121
//    pi[1800].zone_configuration_settings["Stage Override 3 Start Time"] = 	123
//    pi[1800].zone_configuration_settings["Stage Override 3 End Time"] = 	125
//    pi[1800].zone_configuration_settings["Stage Override 3 Interval"] = 	127
//    pi[1800].zone_configuration_settings["Stage Override 4 Enabled"] = 	129
//    pi[1800].zone_configuration_settings["Stage Override 4 Temperature Stage"] = 	131
//    pi[1800].zone_configuration_settings["Stage Override 4 Start Time"] = 	133
//    pi[1800].zone_configuration_settings["Stage Override 4 End Time"] = 	135
//    pi[1800].zone_configuration_settings["Stage Override 4 Interval"] = 	137
//    pi[1800].zone_configuration_settings["Vapor Pressure Deficit Media Sensor"] = 	139
//    pi[1800].zone_configuration_settings["Lighting Cyclic Mode"] = 	141
//    pi[1800].zone_configuration_settings["Lighting Start Delay"] = 	143
//    pi[1800].zone_configuration_settings["Lighting Finish Delay"] = 	145
//    pi[1800].zone_configuration_settings["Lighting Active Time"] = 	147
//    pi[1800].zone_configuration_settings["Lighting Minimum On Time"] = 	149
//    pi[1800].zone_configuration_settings["Drive to Average - Enable"] = 	151
//    pi[1800].zone_configuration_settings["Drive to Average - Target Temperature"] = 	153
//    pi[1800].zone_configuration_settings["Drive to Average - Maximum Failed Days"] = 	155
//    pi[1800].zone_configuration_settings["Drive to Average - Deviated Temperature Threshold"] = 	157
//    pi[1800].zone_configuration_settings["Drive to Average - Setpoint Correction"] = 	159
//    pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Enable"] = 	161
//    pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Sensor"] = 	163
//    pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Threshold"] = 	165
//    pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Offset"] = 	167
//    pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Threshold"] = 	169
//    pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Offset"] = 	171
//    pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Enable"] = 	173
//    pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Sensor"] = 	175
//    pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Threshold"] = 	177
//    pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Offset"] = 	179
//    pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Threshold"] = 	181
//    pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Offset"] = 	183
//    pi[1800].zone_configuration_settings["Start Up Delay"] = 	185
//    pi[1800].zone_configuration_settings["Curtain Energy Mode - Light Deadband"] = 	187
//    pi[1800].zone_configuration_settings["Curtain Energy Mode - Temperature Deadband"] = 	189
//    pi[1800].zone_configuration_settings["Humidity Override On Time"] = 	191
//    pi[1800].zone_configuration_settings["Humidity Override Off Time"] = 	193
//    pi[1800].zone_configuration_settings["Dehumidify Low Outside Temperature Threshold"] = 	195
//    pi[1800].zone_configuration_settings["Dehumidify Heat Boost"] = 	197
//    pi[1800].zone_configuration_settings["Enable Demands Based on Active Cool"] = 	199
//    pi[1800].zone_configuration_settings["Enable Active Cool Stage Override"] = 	201
//    pi[1800].zone_configuration_settings["SmartCool[0] Ku"] = 	203
//    pi[1800].zone_configuration_settings["SmartCool[0] Gmax"] = 	205
//    pi[1800].zone_configuration_settings["SmartCool[0] Kd"] = 	207
//    pi[1800].zone_configuration_settings["SmartCool[0] Gmin"] = 	209
//    pi[1800].zone_configuration_settings["SmartCool[1] Ku"] = 	211
//    pi[1800].zone_configuration_settings["SmartCool[1] Gmax"] = 	213
//    pi[1800].zone_configuration_settings["SmartCool[1] Kd"] = 	215
//    pi[1800].zone_configuration_settings["SmartCool[1] Gmin"] = 	217
//    pi[1800].zone_configuration_settings["SmartCool[2] Ku"] = 	219
//    pi[1800].zone_configuration_settings["SmartCool[2] Gmax"] = 	221
//    pi[1800].zone_configuration_settings["SmartCool[2] Kd"] = 	223
//    pi[1800].zone_configuration_settings["SmartCool[2] Gmin"] = 	225
//    pi[1800].zone_configuration_settings["SmartCool[3] Ku"] = 	227
//    pi[1800].zone_configuration_settings["SmartCool[3] Gmax"] = 	229
//    pi[1800].zone_configuration_settings["SmartCool[3] Kd"] = 	231
//    pi[1800].zone_configuration_settings["SmartCool[3] Gmin"] = 	233
//    pi[1800].zone_configuration_settings["SmartCool[4] Ku"] = 	235
//    pi[1800].zone_configuration_settings["SmartCool[4] Gmax"] = 	237
//    pi[1800].zone_configuration_settings["SmartCool[4] Kd"] = 	239
//    pi[1800].zone_configuration_settings["SmartCool[4] Gmin"] = 	241
//    pi[1800].zone_configuration_settings["SmartCool[5] Ku"] = 	243
//    pi[1800].zone_configuration_settings["SmartCool[5] Gmax"] = 	245
//    pi[1800].zone_configuration_settings["SmartCool[5] Kd"] = 	247
//    pi[1800].zone_configuration_settings["SmartCool[5] Gmin"] = 	249
//    pi[1800].zone_configuration_settings["SmartCool[6] Ku"] = 	251
//    pi[1800].zone_configuration_settings["SmartCool[6] Gmax"] = 	253
//    pi[1800].zone_configuration_settings["SmartCool[6] Kd"] = 	255
//    pi[1800].zone_configuration_settings["SmartCool[6] Gmin"] = 	257
//    pi[1800].zone_configuration_settings["SmartCool[7] Ku"] = 	259
//    pi[1800].zone_configuration_settings["SmartCool[7] Gmax"] = 	261
//    pi[1800].zone_configuration_settings["SmartCool[7] Kd"] = 	263
//    pi[1800].zone_configuration_settings["SmartCool[7] Gmin"] = 	265
//    pi[1800].zone_configuration_settings["SmartCool[8] Ku"] = 	267
//    pi[1800].zone_configuration_settings["SmartCool[8] Gmax"] = 	269
//    pi[1800].zone_configuration_settings["SmartCool[8] Kd"] = 	271
//    pi[1800].zone_configuration_settings["SmartCool[8] Gmin"] = 	273
//    pi[1800].zone_configuration_settings["SmartCool[9] Ku"] = 	275
//    pi[1800].zone_configuration_settings["SmartCool[9] Gmax"] = 	277
//    pi[1800].zone_configuration_settings["SmartCool[9] Kd"] = 	279
//    pi[1800].zone_configuration_settings["SmartCool[9] Gmin"] = 	281
//    pi[1800].zone_configuration_settings["Peristaltic - Output Mode"] = 	283
//    pi[1800].zone_configuration_settings["Peristaltic - Output Delay"] = 	285
//    pi[1800].zone_configuration_settings["Volume Measurement Units"] = 	287
//    pi[1800].zone_configuration_settings["Nutrient Units"] = 	289
//    pi[1800].zone_configuration_settings["Nutrient Units - TDS Conversion Factor"] = 	291
//    pi[1800].zone_configuration_settings["Equipment Delay"] = 	293
//    pi[1800].zone_configuration_settings["Sensor Display - 1"] = 	295
//    pi[1800].zone_configuration_settings["Sensor Display - 2"] = 	297
//    pi[1800].zone_configuration_settings["Sensor Display - 3"] = 	299
//    pi[1800].zone_configuration_settings["Sensor Display - 4"] = 	301
//    pi[1800].zone_configuration_settings["Schedule Pump Transition Time"] = 	303
//    pi[1800].zone_configuration_settings["Peristaltic Pump - Advanced Mode"] = 	305
 
pi[1800].zone_configuration_settings["Use Fallback Sensor if In Temp Fails"] = 1
pi[1800].zone_configuration_settings["Fallback Sensor Failed Temperature Stage"] = 3
pi[1800].zone_configuration_settings["Force To No Sensor Stage setting 3) if High Alarm occurs"] = 4
pi[1800].zone_configuration_settings["Rain Hold Time"] = 5
pi[1800].zone_configuration_settings["Command Delay Time"] = 6
pi[1800].zone_configuration_settings["Daylight Savings Time"] = 7
pi[1800].zone_configuration_settings["Log History"] = 8
pi[1800].zone_configuration_settings["Latitude"] = 9
pi[1800].zone_configuration_settings["Longitude"] = 10
pi[1800].zone_configuration_settings["Temperature Units"] = 11
pi[1800].zone_configuration_settings["Windspeed Units"] = 12
pi[1800].zone_configuration_settings["Light Units"] = 13
pi[1800].zone_configuration_settings["Irrigation Mode"] = 14
pi[1800].zone_configuration_settings["Irrigation Delay"] = 15
pi[1800].zone_configuration_settings["High Alarm Temperature Above Cool Setpoint Threshold"] = 17
pi[1800].zone_configuration_settings["High Alarm Temperature Hold Time"] = 18
pi[1800].zone_configuration_settings["Low Alarm Temperature Below Heat Setpoint Threshold"] = 19
pi[1800].zone_configuration_settings["Low Alarm Temperature Hold Time"] = 20
pi[1800].zone_configuration_settings["Cool Deadband"] = 21
pi[1800].zone_configuration_settings["Heat Deadband"] = 22
pi[1800].zone_configuration_settings["Humidity Deadband"] = 23
pi[1800].zone_configuration_settings["Analog Temperature Deadband"] = 24
pi[1800].zone_configuration_settings["Enable SmartCool"] = 25
pi[1800].zone_configuration_settings["SmartCool Setting"] = 26
pi[1800].zone_configuration_settings["Show Heat Demand on iGrow"] = 32
pi[1800].zone_configuration_settings["Heat Demand Max Light"] = 33
pi[1800].zone_configuration_settings["Heat Demand Max Wind"] = 34
pi[1800].zone_configuration_settings["Greenhouse heating design delta-T"] = 35
pi[1800].zone_configuration_settings["Light''s influence on Heat Demand"] = 36
pi[1800].zone_configuration_settings["Wind''s influence on Heat Demand"] = 37
pi[1800].zone_configuration_settings["Show Cool Demand on iGrow"] = 38
pi[1800].zone_configuration_settings["Cool Demand Max Light"] = 39
pi[1800].zone_configuration_settings["Greenhouse cooling design delta-T"] = 40
pi[1800].zone_configuration_settings["Cool Demand Light Factor"] = 41
pi[1800].zone_configuration_settings["Enable Active Cooling"] = 42
pi[1800].zone_configuration_settings["Passive Lock Stage"] = 43
pi[1800].zone_configuration_settings["Enter Active Cooling Cool Demand Threshold"] = 44
pi[1800].zone_configuration_settings["Exit Active Cooling Cool Demand Threshold"] = 45
pi[1800].zone_configuration_settings["Cool stages"] = 46
pi[1800].zone_configuration_settings["Heat stages"] = 47
pi[1800].zone_configuration_settings["Stage Width"] = 48
pi[1800].zone_configuration_settings["Stage Override 1 Enabled"] = 49
pi[1800].zone_configuration_settings["Stage Override 1 Temperature Stage"] = 50
pi[1800].zone_configuration_settings["Stage Override 1 Start Time"] = 51
pi[1800].zone_configuration_settings["Stage Override 1 End Time"] = 52
pi[1800].zone_configuration_settings["Stage Override 1 Interval"] = 53
pi[1800].zone_configuration_settings["Stage Override 2 Enabled"] = 54
pi[1800].zone_configuration_settings["Stage Override 2 Temperature Stage"] = 55
pi[1800].zone_configuration_settings["Stage Override 2 Start Time"] = 56
pi[1800].zone_configuration_settings["Stage Override 2 End Time"] = 57
pi[1800].zone_configuration_settings["Stage Override 2 Interval"] = 58
pi[1800].zone_configuration_settings["Stage Override 3 Enabled"] = 59
pi[1800].zone_configuration_settings["Stage Override 3 Temperature Stage"] = 60
pi[1800].zone_configuration_settings["Stage Override 3 Start Time"] = 61
pi[1800].zone_configuration_settings["Stage Override 3 End Time"] = 62
pi[1800].zone_configuration_settings["Stage Override 3 Interval"] = 63
pi[1800].zone_configuration_settings["Stage Override 4 Enabled"] = 64
pi[1800].zone_configuration_settings["Stage Override 4 Temperature Stage"] = 65
pi[1800].zone_configuration_settings["Stage Override 4 Start Time"] = 66
pi[1800].zone_configuration_settings["Stage Override 4 End Time"] = 67
pi[1800].zone_configuration_settings["Stage Override 4 Interval"] = 68
pi[1800].zone_configuration_settings["Vapor Pressure Deficit Media Sensor"] = 69
pi[1800].zone_configuration_settings["Lighting Cyclic Mode"] = 70
pi[1800].zone_configuration_settings["Lighting Start Delay"] = 71
pi[1800].zone_configuration_settings["Lighting Finish Delay"] = 72
pi[1800].zone_configuration_settings["Lighting Active Time"] = 73
pi[1800].zone_configuration_settings["Lighting Minimum On Time"] = 74
pi[1800].zone_configuration_settings["Drive to Average - Enable"] = 75
pi[1800].zone_configuration_settings["Drive to Average - Target Temperature"] = 76
pi[1800].zone_configuration_settings["Drive to Average - Maximum Failed Days"] = 77
pi[1800].zone_configuration_settings["Drive to Average - Deviated Temperature Threshold"] = 78
pi[1800].zone_configuration_settings["Drive to Average - Setpoint Correction"] = 79
pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Enable"] = 80
pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Sensor"] = 81
pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Threshold"] = 82
pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Offset"] = 83
pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Threshold"] = 84
pi[1800].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Offset"] = 85
pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Enable"] = 86
pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Sensor"] = 87
pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Threshold"] = 88
pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Offset"] = 89
pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Threshold"] = 90
pi[1800].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Offset"] = 91
pi[1800].zone_configuration_settings["Start Up Delay"] = 92
pi[1800].zone_configuration_settings["Curtain Energy Mode - Light Deadband"] = 93
pi[1800].zone_configuration_settings["Curtain Energy Mode - Temperature Deadband"] = 94
pi[1800].zone_configuration_settings["Humidity Override On Time"] = 95
pi[1800].zone_configuration_settings["Humidity Override Off Time"] = 96
pi[1800].zone_configuration_settings["Dehumidify Low Outside Temperature Threshold"] = 97
pi[1800].zone_configuration_settings["Dehumidify Heat Boost"] = 98
pi[1800].zone_configuration_settings["Enable Demands Based on Active Cool"] = 99
pi[1800].zone_configuration_settings["Enable Active Cool Stage Override"] = 100
pi[1800].zone_configuration_settings["SmartCool[0] Ku"] = 101
pi[1800].zone_configuration_settings["SmartCool[0] Gmax"] = 102
pi[1800].zone_configuration_settings["SmartCool[0] Kd"] = 103
pi[1800].zone_configuration_settings["SmartCool[0] Gmin"] = 104
pi[1800].zone_configuration_settings["SmartCool[1] Ku"] = 105
pi[1800].zone_configuration_settings["SmartCool[1] Gmax"] = 106
pi[1800].zone_configuration_settings["SmartCool[1] Kd"] = 107
pi[1800].zone_configuration_settings["SmartCool[1] Gmin"] = 108
pi[1800].zone_configuration_settings["SmartCool[2] Ku"] = 109
pi[1800].zone_configuration_settings["SmartCool[2] Gmax"] = 110
pi[1800].zone_configuration_settings["SmartCool[2] Kd"] = 111
pi[1800].zone_configuration_settings["SmartCool[2] Gmin"] = 112
pi[1800].zone_configuration_settings["SmartCool[3] Ku"] = 113
pi[1800].zone_configuration_settings["SmartCool[3] Gmax"] = 114
pi[1800].zone_configuration_settings["SmartCool[3] Kd"] = 115
pi[1800].zone_configuration_settings["SmartCool[3] Gmin"] = 116
pi[1800].zone_configuration_settings["SmartCool[4] Ku"] = 117
pi[1800].zone_configuration_settings["SmartCool[4] Gmax"] = 118
pi[1800].zone_configuration_settings["SmartCool[4] Kd"] = 119
pi[1800].zone_configuration_settings["SmartCool[4] Gmin"] = 120
pi[1800].zone_configuration_settings["SmartCool[5] Ku"] = 121
pi[1800].zone_configuration_settings["SmartCool[5] Gmax"] = 122
pi[1800].zone_configuration_settings["SmartCool[5] Kd"] = 123
pi[1800].zone_configuration_settings["SmartCool[5] Gmin"] = 124
pi[1800].zone_configuration_settings["SmartCool[6] Ku"] = 125
pi[1800].zone_configuration_settings["SmartCool[6] Gmax"] = 126
pi[1800].zone_configuration_settings["SmartCool[6] Kd"] = 127
pi[1800].zone_configuration_settings["SmartCool[6] Gmin"] = 128
pi[1800].zone_configuration_settings["SmartCool[7] Ku"] = 129
pi[1800].zone_configuration_settings["SmartCool[7] Gmax"] = 130
pi[1800].zone_configuration_settings["SmartCool[7] Kd"] = 131
pi[1800].zone_configuration_settings["SmartCool[7] Gmin"] = 132
pi[1800].zone_configuration_settings["SmartCool[8] Ku"] = 133
pi[1800].zone_configuration_settings["SmartCool[8] Gmax"] = 134
pi[1800].zone_configuration_settings["SmartCool[8] Kd"] = 135
pi[1800].zone_configuration_settings["SmartCool[8] Gmin"] = 136
pi[1800].zone_configuration_settings["SmartCool[9] Ku"] = 137
pi[1800].zone_configuration_settings["SmartCool[9] Gmax"] = 138
pi[1800].zone_configuration_settings["SmartCool[9] Kd"] = 139
pi[1800].zone_configuration_settings["SmartCool[9] Gmin"] = 140
pi[1800].zone_configuration_settings["Peristaltic - Output Mode"] = 141
pi[1800].zone_configuration_settings["Peristaltic - Output Delay"] = 142
pi[1800].zone_configuration_settings["Volume Measurement Units"] = 143
pi[1800].zone_configuration_settings["Nutrient Units"] = 144
pi[1800].zone_configuration_settings["Nutrient Units - TDS Conversion Factor"] = 145
pi[1800].zone_configuration_settings["Equipment Delay"] = 146
pi[1800].zone_configuration_settings["Sensor Display - 1"] = 147
pi[1800].zone_configuration_settings["Sensor Display - 2"] = 148
pi[1800].zone_configuration_settings["Sensor Display - 3"] = 149
pi[1800].zone_configuration_settings["Sensor Display - 4"] = 150
pi[1800].zone_configuration_settings["Schedule Pump Transition Time"] = 151
pi[1800].zone_configuration_settings["Peristaltic Pump - Advanced Mode"] = 152

pi[1800].sensors["temperatureStage"] = 1
pi[1800].sensors["humidityStage"] = 2
pi[1800].sensors["heatSetpoint"] = 3
pi[1800].sensors["coolSetpoint"] = 4
pi[1800].sensors["humidifySetpoint"] = 5
pi[1800].sensors["dehumidifySetpoint"] = 6
pi[1800].sensors["lowAlarm"] = 7
pi[1800].sensors["highAlarm"] = 8
pi[1800].sensors["inTemperature"] = 9
pi[1800].sensors["outTemperature"] = 10
pi[1800].sensors["localTemperature"] = 11
pi[1800].sensors["backupTemperature"] = 12
pi[1800].sensors["inHumidity"] = 13
pi[1800].sensors["outHumidity"] = 14
pi[1800].sensors["localHumidity"] = 15
pi[1800].sensors["differentialPressure"] = 16
pi[1800].sensors["co2"] = 17
pi[1800].sensors["inLight"] = 18
pi[1800].sensors["outLight"] = 19
pi[1800].sensors["windSpeed"] = 20
pi[1800].sensors["windDirection"] = 21
pi[1800].sensors["rain"] = 22
pi[1800].sensors["snow"] = 23
pi[1800].sensors["analogTemperature1"] = 24
pi[1800].sensors["analogTemperature2"] = 25
pi[1800].sensors["analogTemperature3"] = 26
pi[1800].sensors["analogTemperature4"] = 27
pi[1800].sensors["analogTemperature5"] = 28
pi[1800].sensors["ventPosition1"] = 29
pi[1800].sensors["ventPosition2"] = 30
pi[1800].sensors["ventPosition3"] = 31
pi[1800].sensors["ventPosition4"] = 32
pi[1800].sensors["ventPosition5"] = 33
pi[1800].sensors["soilMoisture1"] = 34
pi[1800].sensors["soilMoisture2"] = 35
pi[1800].sensors["soilMoisture3"] = 36
pi[1800].sensors["soilMoisture4"] = 37
pi[1800].sensors["soilMoisture5"] = 38
pi[1800].sensors["outTemperatureSecondary"] = 39
pi[1800].sensors["barometricPressure"] = 40
pi[1800].sensors["vpd"] = 41


/******************* Pearl Values ******************************/

p.PID_1900_BASE_SNAPSHOTS = 0 // 81
p.PID_1900_BASE_SNAP_CHANNELS = 100;
p.PID_1900_BASE_SNAP_ECPHS = 200;
p.PID_1900_BASE_SNAP_CHAN_DATA = 400;
p.PID_1900_BASE_CONF_CHANNELS = 500;
p.PID_1900_BASE_CONF_CHAN_DATA = 600;

p.PID_1900_BASE_AUX_ALARMS = 1024 // 32
p.PID_1900_BASE_AUX_PERSISTENT_VARIABLE = 1056 // 32
p.PID_1900_BASE_AUX_VARIABLE = 1088 // 64
p.PID_1900_BASE_EXPANSION_BOARDS = 1152 // 64
// p.PID_1900_BASE_EXPANSION_BOARDS = 1216
p.PID_1900_BASE_CONFIG_AUX_ALARMS = 1216 // 4 * 32
p.PID_1900_BASE_CONFIG_AUX_CONTROLS = 1344 // 17 * 64 no! 128!
//p.PID_1900_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 3520 // 4 * 32
p.PID_1900_BASE_CONFIG_AUX_VARIABLES = 3640 // 4 * 64
p.PID_1900_BASE_CONFIG_EXPANSION_BOARDS = 3896 // 5 * 64
p.PID_1900_BASE_CONFIG_CONTROLLER_SETTINGS = 4216 // 145 * 2, 170, now 157*2+4216=4530, next is PID_1900_BASE_CONFIG_ECPH at 4556


p.PID_1900_BASE_CONFIG_ECPH = 4556 // 11
p.PID_1900_BASE_CONFIG_ECPH_SENSORS = 4567 // 3 * 23 = 69
// zone wide
p.PID_1900_BASE_CONFIG_SETPOINTS = 4636 // 8 * 10
p.PID_1900_BASE_CONFIG_ZONE_SETTINGS = 4716 // 153 * 2!
p.PID_1900_BASE_CONFIG_ZONES = 5022 // 2
p.PID_1900_BASE_CONFIG_CONTROLLERS = 5024 // 4
// site wide
p.PID_1900_BASE_CONFIG_COMM_STAT = 5028 // 3
p.PID_1900_BASE_CONFIG_TIME = 5031 // 1 - current time at the server

p.PID_1900_ZONE_NAME = 5022;
p.PID_1900_ZONE_DESCRIPTION = 5023;
p.PID_1900_CONTROLLER_VERSION = 5025;
p.PID_1900_BASE_CONFIG_TIME = 5031 // 1 - current time at the server
p.PID_1900_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 5040 // 4 * 32
p.PID_1900_BASE_CONFIG_ANNEX_SENSORS = 5200 // 20*100


pb[1900]={}
pb[1900].snapshots = 0 // 1-81
pb[1900].snapshot_channels = 100 // 101-120
pb[1900].snapshot_ecphs = 200 // 201-235
pb[1900].snapshot_chan_Snapshot = 400 // 401-448
pb[1900].channels_configuration = 500 // 501-520
pb[1900].config_channels_configuration = 600 // 600-796
pb[1900].snapshot_aux_alarms = p.PID_1900_BASE_AUX_ALARMS; // 1024
pb[1900].snapshot_aux_persistent_variables = p.PID_1900_BASE_AUX_PERSISTENT_VARIABLE;//1056
pb[1900].snapshot_aux_variables = p.PID_1900_BASE_AUX_VARIABLE;//1088
pb[1900].snapshot_expansion_boards = p.PID_1900_BASE_EXPANSION_BOARDS;//1152
pb[1900].config_aux_alarms = p.PID_1900_BASE_CONFIG_AUX_ALARMS;//1216 4 * 32
pb[1900].config_aux_controls = p.PID_1900_BASE_CONFIG_AUX_CONTROLS;//1344 17 * 128
pb[1900].config_aux_variables = p.PID_1900_BASE_CONFIG_AUX_VARIABLES;//3640 4 * 64
pb[1900].config_expansion_boards = p.PID_1900_BASE_CONFIG_EXPANSION_BOARDS;//3896 5 * 64
pb[1900].controller_configuration_settings = p.PID_1900_BASE_CONFIG_CONTROLLER_SETTINGS;//4216
pb[1900].config_ecph = p.PID_1900_BASE_CONFIG_ECPH;//4506
pb[1900].config_ecph_sensors = p.PID_1900_BASE_CONFIG_ECPH_SENSORS;//4517
pb[1900].config_setpoints = p.PID_1900_BASE_CONFIG_SETPOINTS;//4586
pb[1900].zone_configuration_settings = p.PID_1900_BASE_CONFIG_ZONE_SETTINGS;//4666
pb[1900].config_zones = p.PID_1900_BASE_CONFIG_ZONES;//4972
pb[1900].config_controllers = p.PID_1900_BASE_CONFIG_CONTROLLERS;//4974
pb[1900].config_communication_status = p.PID_1900_BASE_CONFIG_COMM_STAT;//4978
pb[1900].config_aux_persistent_variables = p.PID_1900_BASE_CONFIG_AUX_PERSISTENT_VARIABLES;//3520 4 * 32
pb[1900].config_annex_sensors = p.PID_1900_BASE_CONFIG_ANNEX_SENSORS;//3520 4 * 32

pInd[1900]={}

pInd[1900].snapshots = [p.PID_1900_BASE_SNAPSHOTS, 0, 0, 0] // 1-81: base, channel type, index size, index count. channel type 0 = "unit"
pInd[1900].snapshot_channels = [p.PID_1900_BASE_SNAP_CHANNELS, 1, 0, 0] // 101-120 channel type 1 = "channel" - zone, unit, chan
pInd[1900].snapshot_ecphs = [p.PID_1900_BASE_SNAP_ECPHS, 2, 0, 0] // 201-235// ecph channel type - put in 192-199, on ecphIndex
pInd[1900].snapshot_chan_Snapshot = [p.PID_1900_BASE_SNAP_CHAN_DATA, 1, 0, 0] // 401-448 channeldata channel type
pInd[1900].channels_configuration = [p.PID_1900_BASE_CONF_CHANNELS, 1, 0, 0] // 501-520 channel type
pInd[1900].config_channels_configuration = [p.PID_1900_BASE_CONF_CHAN_DATA, 1, 0, 0] // 600-796 channeldata
pInd[1900].snapshot_aux_alarms = [p.PID_1900_BASE_AUX_ALARMS, 0, 1, 32]; // 1024 + 32
pInd[1900].snapshot_aux_persistent_variables = [p.PID_1900_BASE_AUX_PERSISTENT_VARIABLE, 0, 1, 32];//1056 + 32
pInd[1900].snapshot_aux_variables = [p.PID_1900_BASE_AUX_VARIABLE, 0, 1, 64];//1088 + 64
pInd[1900].snapshot_expansion_boards = [p.PID_1900_BASE_EXPANSION_BOARDS, 0, 1, 64];//1152 + 64
pInd[1900].config_aux_alarms = [p.PID_1900_BASE_CONFIG_AUX_ALARMS, 0, 4, 32];//1216 4 * 32
pInd[1900].config_aux_controls = [p.PID_1900_BASE_CONFIG_AUX_CONTROLS, 0, 17, 128];//1344 17 * 128
pInd[1900].config_aux_persistent_variables = [p.PID_1900_BASE_CONFIG_AUX_PERSISTENT_VARIABLES, 0, 4, 32];//3520 4 * 32
pInd[1900].config_aux_variables = [p.PID_1900_BASE_CONFIG_AUX_VARIABLES, 0, 4, 64];//3640 4 * 64
pInd[1900].config_expansion_boards = [p.PID_1900_BASE_CONFIG_EXPANSION_BOARDS, 0, 8, 40];//3896 5 * 64
pInd[1900].controller_configuration_settings = [p.PID_1900_BASE_CONFIG_CONTROLLER_SETTINGS, 7, 2, 170];//4216 170 * 2
// I really don't know what the 11, 8 refers to below!
pInd[1900].config_ecph = [p.PID_1900_BASE_CONFIG_ECPH, 2, 11, 8];//4506 11 * 8 - ERROR!!! this is just 11 values. the 8 times are in the channel
pInd[1900].config_ecph_sensors = [p.PID_1900_BASE_CONFIG_ECPH_SENSORS, 3, 23, 3];//4517 - channel type 3 - ecph double indexed
pInd[1900].config_setpoints = [p.PID_1900_BASE_CONFIG_SETPOINTS, 4, 10, 8];//4586 type 4 = zone wide
pInd[1900].zone_configuration_settings = [p.PID_1900_BASE_CONFIG_ZONE_SETTINGS, 6, 2, 153];//4666 zone *config* type
pInd[1900].config_zones = [p.PID_1900_BASE_CONFIG_ZONES, 4, 0, 0];//4972
pInd[1900].config_controllers = [p.PID_1900_BASE_CONFIG_CONTROLLERS, 0, 0, 0];//4974
pInd[1900].config_communication_status = [p.PID_1900_BASE_CONFIG_COMM_STAT, 5, 0, 0];//4978 type 5 - site wide
pInd[1900].config_annex_sensors = [p.PID_1900_BASE_CONFIG_ANNEX_SENSORS, 0, 20, 100];//5200 // 20*100

tableBases2["1900"]=[
  pInd[1900].snapshots,
  pInd[1900].snapshot_channels,
  pInd[1900].snapshot_ecphs,
  pInd[1900].snapshot_chan_Snapshot,
  pInd[1900].channels_configuration,
  pInd[1900].config_channels_configuration,
  pInd[1900].snapshot_aux_alarms,
  pInd[1900].snapshot_aux_persistent_variables,
  pInd[1900].snapshot_aux_variables,
  pInd[1900].snapshot_expansion_boards,
  pInd[1900].config_aux_alarms,
  pInd[1900].config_aux_controls,
  pInd[1900].config_aux_variables,
  pInd[1900].config_expansion_boards,
  pInd[1900].controller_configuration_settings,
  pInd[1900].config_ecph,
  pInd[1900].config_ecph_sensors,
  pInd[1900].config_setpoints,
  pInd[1900].zone_configuration_settings,
  pInd[1900].config_zones,
  pInd[1900].config_controllers,
  pInd[1900].config_communication_status,
  pInd[1900].config_aux_persistent_variables,
  pInd[1900].config_annex_sensors,
  [100000]]


// var snapshots = {} // these are in channels 240 + u
pi[1900]={}
pi[1900].snapshots = [];//0, 1-81
pi[1900].snapshot_channels = [];//0, 101-120
pi[1900].snapshot_ecphs = [];//0, 201-235
pi[1900].snapshot_chan_Snapshot = [];//0, 401-448
pi[1900].channels_configuration = [];//0 501-520
pi[1900].config_channels_configuration = [];// 0, 600-796
pi[1900].snapshot_aux_alarms = [];// 1024
pi[1900].snapshot_aux_persistent_variables = [];// 1056
pi[1900].snapshot_aux_variables = [];// 1088
pi[1900].snapshot_expansion_boards = [];// 1152
pi[1900].config_aux_alarms = [];// 1216
pi[1900].config_aux_controls = [];// 1344
pi[1900].config_aux_persistent_variables = [];// 3520
pi[1900].config_aux_variables = [];// 3640
pi[1900].config_expansion_boards = [];// 3896
pi[1900].controller_configuration_settings = [];//4216
pi[1900].config_ecph = [];//4506
pi[1900].config_ecph_sensors = [];//4517
pi[1900].config_setpoints = [];//4586
pi[1900].zone_configuration_settings = [];//4666
pi[1900].config_zones = [];//4972
pi[1900].config_controllers = [];//4974
pi[1900].config_communication_status = [];//4978
pi[1900].config_annex_sensors = [];//4978

offsetTables["1900"]=[
  pi[1900].snapshots, pi[1900].snapshot_channels, pi[1900].snapshot_ecphs,
  pi[1900].snapshot_chan_Snapshot, pi[1900].channels_configuration,
  pi[1900].config_channels_configuration, pi[1900].snapshot_aux_alarms,
  pi[1900].snapshot_aux_persistent_variables, pi[1900].snapshot_aux_variables,
  pi[1900].snapshot_expansion_boards, pi[1900].config_aux_alarms,
  pi[1900].config_aux_controls,
  pi[1900].config_aux_variables, pi[1900].config_expansion_boards,
  pi[1900].controller_configuration_settings, pi[1900].config_ecph,
  pi[1900].config_ecph_sensors, pi[1900].config_setpoints,
  pi[1900].zone_configuration_settings, pi[1900].config_zones, pi[1900].config_controllers,
  pi[1900].config_communication_status, pi[1900].config_aux_persistent_variables, 
  pi[1900].config_annex_sensors]

pi[1900].snapshots["id"] =	1
pi[1900].snapshots["zoneIndex"] =	2
pi[1900].snapshots["unitIndex"] =	3
pi[1900].snapshots["unix_timestamp(created)"] =	4
pi[1900].snapshots["unix_timestamp(unitTime)"] =	5
pi[1900].snapshots["temperatureStage"] =	6
pi[1900].snapshots["humidityStage"] =	7
pi[1900].snapshots["heatSetpoint"] =	8
pi[1900].snapshots["coolSetpoint"] =	9
pi[1900].snapshots["humidifySetpoint"] =	10
pi[1900].snapshots["dehumidifySetpoint"] =	11
pi[1900].snapshots["accumulator0"] =	12
pi[1900].snapshots["accumulator1"] =	13
pi[1900].snapshots["d2avgTotalAverage"] =	14
pi[1900].snapshots["d2avgDayAverage"] =	15
pi[1900].snapshots["d2avgNightAverage"] =	16
pi[1900].snapshots["d2avgFailDays"] =	17
pi[1900].snapshots["lowAlarm"] =	18
pi[1900].snapshots["highAlarm"] =	19
pi[1900].snapshots["lowInTemperatureAlarm"] =	20
pi[1900].snapshots["highInTemperatureAlarm"] =	21
pi[1900].snapshots["inTemperatureSensorAlarm"] =	22
pi[1900].snapshots["inTemperature"] =	23
pi[1900].snapshots["outTemperature"] =	24
pi[1900].snapshots["localTemperature"] =	25
pi[1900].snapshots["backupTemperature"] =	26
pi[1900].snapshots["inHumidity"] =	27
pi[1900].snapshots["outHumidity"] =	28
pi[1900].snapshots["localHumidity"] =	29
pi[1900].snapshots["differentialPressure"] =	30
pi[1900].snapshots["co2"] =	31
pi[1900].snapshots["inLight"] =	32
pi[1900].snapshots["outLight"] =	33
pi[1900].snapshots["windSpeed"] =	34
pi[1900].snapshots["windDirection"] =	35
pi[1900].snapshots["rain"] =	36
pi[1900].snapshots["snow"] =	37
pi[1900].snapshots["analogTemperature1"] =	38
pi[1900].snapshots["analogTemperature2"] =	39
pi[1900].snapshots["analogTemperature3"] =	40
pi[1900].snapshots["analogTemperature4"] =	41
pi[1900].snapshots["analogTemperature5"] =	42
pi[1900].snapshots["ventPosition1"] =	43
pi[1900].snapshots["ventPosition2"] =	44
pi[1900].snapshots["ventPosition3"] =	45
pi[1900].snapshots["ventPosition4"] =	46
pi[1900].snapshots["ventPosition5"] =	47
pi[1900].snapshots["soilMoisture1"] =	48
pi[1900].snapshots["soilMoisture2"] =	49
pi[1900].snapshots["soilMoisture3"] =	50
pi[1900].snapshots["soilMoisture4"] =	51
pi[1900].snapshots["soilMoisture5"] =	52
pi[1900].snapshots["ecph1"] =	53
pi[1900].snapshots["ecph2"] =	54
pi[1900].snapshots["ecph3"] =	55
pi[1900].snapshots["ecph4"] =	56
pi[1900].snapshots["ecph5"] =	57
pi[1900].snapshots["generic1"] =	58
pi[1900].snapshots["generic2"] =	59
pi[1900].snapshots["heatDemand"] =	60
pi[1900].snapshots["coolDemand"] =	61
pi[1900].snapshots["coolDemandPassive"] =	62
pi[1900].snapshots["auxHeatSetpointStatus"] =	63
pi[1900].snapshots["auxHeatSetpointValue"] =	64
pi[1900].snapshots["auxCoolSetpointStatus"] =	65
pi[1900].snapshots["auxCoolSetpointValue"] =	66
pi[1900].snapshots["auxHumidifySetpointStatus"] =	67
pi[1900].snapshots["auxHumidifySetpointValue"] =	68
pi[1900].snapshots["auxDehumidifySetpointStatus"] =	69
pi[1900].snapshots["auxDehumidifySetpointValue"] =	70
pi[1900].snapshots["auxLowAlarmStatus"] =	71
pi[1900].snapshots["auxLowAlarmValue"] =	72
pi[1900].snapshots["auxHighAlarmStatus"] =	73
pi[1900].snapshots["auxHighAlarmValue"] =	74
pi[1900].snapshots["auxActiveCoolStatus"] =	75
pi[1900].snapshots["auxActiveCoolValue"] =	76
pi[1900].snapshots["auxPassiveCoolStatus"] =	77
pi[1900].snapshots["auxPassiveCoolValue"] =	78
pi[1900].snapshots["accvpd"] =	79
pi[1900].snapshots["outTemperatureSecondary"] =	80
pi[1900].snapshots["barometricPressure"] =   81
pi[1900].snapshots["vpd"] =	82

pi[1900].snapshots["processorId"] =	83
pi[1900].snapshots["controllerId"] =	84
pi[1900].snapshots["currentTime"] =	85


pi[1900].snapshot_channels["snapshotId"] = 1
pi[1900].snapshot_channels["channelIndex"] = 2
pi[1900].snapshot_channels["channelData"] = 3
pi[1900].snapshot_channels["position"] = 4
pi[1900].snapshot_channels["relay"] = 5
pi[1900].snapshot_channels["channelOverride"] = 6
pi[1900].snapshot_channels["analogOutput"] = 7
pi[1900].snapshot_channels["co2Setpoint"] = 8
pi[1900].snapshot_channels["microzoneSetpoint"] = 9
pi[1900].snapshot_channels["proportionalZoneLowSetpoint"] = 10
pi[1900].snapshot_channels["proportionalZoneHighSetpoint"] = 11
pi[1900].snapshot_channels["pumpPeristalticSetpoint"] = 12
pi[1900].snapshot_channels["pumpPeristalticTankLevel"] = 13
pi[1900].snapshot_channels["pumpPeristalticInjectedMilliliters"] = 14
pi[1900].snapshot_channels["pumpPeristalticInjectedSeconds"] = 15
pi[1900].snapshot_channels["lightDailyLightIntegral"] = 16
pi[1900].snapshot_channels["auxOutputStatus"] = 17
pi[1900].snapshot_channels["auxOutputValue"] = 18
pi[1900].snapshot_channels["auxEquipmentStatus"] = 19
pi[1900].snapshot_channels["auxEquipmentValue"] = 20

//var snapshot_ecphs = {} // these are stored in the ecph
// channels, 192-199
pi[1900].snapshot_ecphs["snapshotId"] = 1
pi[1900].snapshot_ecphs["ecphIndex"] = 2
pi[1900].snapshot_ecphs["ec1"] = 3
pi[1900].snapshot_ecphs["ec2"] = 4
pi[1900].snapshot_ecphs["ec3"] = 5
pi[1900].snapshot_ecphs["ec1SensorAlarm"] = 6
pi[1900].snapshot_ecphs["ec1ServiceAlarm"] = 7
pi[1900].snapshot_ecphs["ec1CalibrationAlarm"] = 8
pi[1900].snapshot_ecphs["ec2SensorAlarm"] = 9
pi[1900].snapshot_ecphs["ec2ServiceAlarm"] = 10
pi[1900].snapshot_ecphs["ec2CalibrationAlarm"] = 11
pi[1900].snapshot_ecphs["ec3SensorAlarm"] = 12
pi[1900].snapshot_ecphs["ec3ServiceAlarm"] = 13
pi[1900].snapshot_ecphs["ec3CalibrationAlarm"] = 14
pi[1900].snapshot_ecphs["ecLowAlarm"] = 15
pi[1900].snapshot_ecphs["ecHighAlarm"] = 16
pi[1900].snapshot_ecphs["ecDeviationAlarm"] = 17
pi[1900].snapshot_ecphs["ph1"] = 18
pi[1900].snapshot_ecphs["ph2"] = 19
pi[1900].snapshot_ecphs["ph3"] = 20
pi[1900].snapshot_ecphs["ph1SensorAlarm"] = 21
pi[1900].snapshot_ecphs["ph1ServiceAlarm"] = 22
pi[1900].snapshot_ecphs["ph1CalibrationAlarm"] = 23
pi[1900].snapshot_ecphs["ph2SensorAlarm"] = 24
pi[1900].snapshot_ecphs["ph2ServiceAlarm"] = 25
pi[1900].snapshot_ecphs["ph2CalibrationAlarm"] = 26
pi[1900].snapshot_ecphs["ph3SensorAlarm"] = 27
pi[1900].snapshot_ecphs["ph3ServiceAlarm"] = 28
pi[1900].snapshot_ecphs["ph3CalibrationAlarm"] = 29
pi[1900].snapshot_ecphs["phLowAlarm"] = 30
pi[1900].snapshot_ecphs["phHighAlarm"] = 31
pi[1900].snapshot_ecphs["phDeviationAlarm"] = 32
pi[1900].snapshot_ecphs["temperature1"] = 33
pi[1900].snapshot_ecphs["temperature2"] = 34
pi[1900].snapshot_ecphs["temperature3"] = 35

//var snapshot_chan_Snapshot = {} // stored in the Channel ID
// these are the values out of ChannelData
pi[1900].snapshot_chan_Snapshot["accumulatedLight"] = 1
pi[1900].snapshot_chan_Snapshot["accumulator"] = 2
pi[1900].snapshot_chan_Snapshot["accvpd"] = 3
pi[1900].snapshot_chan_Snapshot["activatedTimes"] = 4
pi[1900].snapshot_chan_Snapshot["activeCount"] = 5
pi[1900].snapshot_chan_Snapshot["auxControl"] = 6
pi[1900].snapshot_chan_Snapshot["coolDemand"] = 7
pi[1900].snapshot_chan_Snapshot["coolDemandPassive"] = 8
pi[1900].snapshot_chan_Snapshot["currentSetpoint"] = 9
pi[1900].snapshot_chan_Snapshot["cycleOffTimeRemains"] = 10
pi[1900].snapshot_chan_Snapshot["cycleOnTimeRemains"] = 11
pi[1900].snapshot_chan_Snapshot["cycleState"] = 12
pi[1900].snapshot_chan_Snapshot["dailyLightIntegral"] = 13
pi[1900].snapshot_chan_Snapshot["driveToAverage"] = 14
pi[1900].snapshot_chan_Snapshot["ecphs"] = 15
pi[1900].snapshot_chan_Snapshot["equipment"] = 16
pi[1900].snapshot_chan_Snapshot["expansionBoardStatus"] = 17
pi[1900].snapshot_chan_Snapshot["heatDemand"] = 18
pi[1900].snapshot_chan_Snapshot["highAlarm"] = 19
pi[1900].snapshot_chan_Snapshot["highInTemperatureAlarm"] = 20
pi[1900].snapshot_chan_Snapshot["humidityStage"] = 21
pi[1900].snapshot_chan_Snapshot["injectedVolumeMLiters"] = 22
pi[1900].snapshot_chan_Snapshot["injectedVolumeSeconds"] = 23
pi[1900].snapshot_chan_Snapshot["inQueue"] = 24
pi[1900].snapshot_chan_Snapshot["inTemperatureSensorAlarm"] = 25
pi[1900].snapshot_chan_Snapshot["inWindow"] = 26
pi[1900].snapshot_chan_Snapshot["irrigationWeek"] = 27
pi[1900].snapshot_chan_Snapshot["isCycling"] = 28
pi[1900].snapshot_chan_Snapshot["isPulseOn"] = 29
pi[1900].snapshot_chan_Snapshot["lowAlarm"] = 30
pi[1900].snapshot_chan_Snapshot["lowInTemperatureAlarm"] = 31
pi[1900].snapshot_chan_Snapshot["offTime"] = 32
pi[1900].snapshot_chan_Snapshot["originallyOn"] = 33
pi[1900].snapshot_chan_Snapshot["output"] = 34
pi[1900].snapshot_chan_Snapshot["pdTimer"] = 35
pi[1900].snapshot_chan_Snapshot["peristalticState"] = 36
pi[1900].snapshot_chan_Snapshot["sensors"] = 37
pi[1900].snapshot_chan_Snapshot["setpoint"] = 38
pi[1900].snapshot_chan_Snapshot["setpointHigh"] = 39
pi[1900].snapshot_chan_Snapshot["setpointLow"] = 40
pi[1900].snapshot_chan_Snapshot["siteChanged"] = 41
pi[1900].snapshot_chan_Snapshot["tankLevelLiters"] = 42
pi[1900].snapshot_chan_Snapshot["tankSizeLiters"] = 43
pi[1900].snapshot_chan_Snapshot["temperatureStage"] = 44
pi[1900].snapshot_chan_Snapshot["timestamp"] = 45
pi[1900].snapshot_chan_Snapshot["unitTime"] = 46
pi[1900].snapshot_chan_Snapshot["pdTimerState"] = 47
pi[1900].snapshot_chan_Snapshot["week"] = 248

//var channels_configuration = {}
pi[1900].channels_configuration["zoneIndex"] = 1
pi[1900].channels_configuration["unitIndex"] = 2
pi[1900].channels_configuration["channelIndex"] = 3
pi[1900].channels_configuration["unix_timestamp(modified)"] = 4
pi[1900].channels_configuration["used"] = 5
pi[1900].channels_configuration["isAnalog"] = 6
pi[1900].channels_configuration["channelName"] = 7
pi[1900].channels_configuration["channelType"] = 8
pi[1900].channels_configuration["channelData"] = 9
pi[1900].channels_configuration["timedEnabled"] = 10
pi[1900].channels_configuration["timedInterval"] = 11
pi[1900].channels_configuration["timedStartTime"] = 12
pi[1900].channels_configuration["timedEndTime"] = 13
pi[1900].channels_configuration["timedOutput"] = 14
pi[1900].channels_configuration["stageDehumidfy1"] = 15
pi[1900].channels_configuration["stageDehumidfy2"] = 16
pi[1900].channels_configuration["stageColdDehumidfy"] = 17
pi[1900].channels_configuration["stageHumidfy"] = 18
pi[1900].channels_configuration["expansionFailSafeOutput"] = 19
pi[1900].channels_configuration["userComment"] = 20

//var config_channels_configuration = {}
pi[1900].config_channels_configuration["activeCool_cool1"] = 0
pi[1900].config_channels_configuration["activeCool_cool2"] = 1
pi[1900].config_channels_configuration["activeCool_cool3"] = 2
pi[1900].config_channels_configuration["activeCool_cool4"] = 3
pi[1900].config_channels_configuration["activeCool_cool5"] = 4
pi[1900].config_channels_configuration["activeCool_cool6"] = 5
pi[1900].config_channels_configuration["activeCool_heat1"] = 6
pi[1900].config_channels_configuration["activeCool_heat2"] = 7
pi[1900].config_channels_configuration["activeCool_heat3"] = 8
pi[1900].config_channels_configuration["activeCool_heat4"] = 9
pi[1900].config_channels_configuration["activeCool_heat5"] = 10
pi[1900].config_channels_configuration["activeCool_heat6"] = 11
pi[1900].config_channels_configuration["activeCool_normal"] = 12
pi[1900].config_channels_configuration["activeTrigger"] = 13
pi[1900].config_channels_configuration["advanced_close_perc"] = 14
pi[1900].config_channels_configuration["advanced_shock_threshold"] = 15
pi[1900].config_channels_configuration["advanced_stage"] = 16
pi[1900].config_channels_configuration["advanced_time"] = 17
pi[1900].config_channels_configuration["air_cool_setpoint"] = 18
pi[1900].config_channels_configuration["air_heat_setpoint"] = 19
pi[1900].config_channels_configuration["air_temp_sensor"] = 20
pi[1900].config_channels_configuration["analog_max"] = 21
pi[1900].config_channels_configuration["analog_max"] = 22
pi[1900].config_channels_configuration["analog_min"] = 23
pi[1900].config_channels_configuration["cool1"] = 24
pi[1900].config_channels_configuration["cool2"] = 25
pi[1900].config_channels_configuration["cool3"] = 26
pi[1900].config_channels_configuration["cool4"] = 27
pi[1900].config_channels_configuration["cool5"] = 28
pi[1900].config_channels_configuration["cool6"] = 29
pi[1900].config_channels_configuration["cooling_device"] = 30
pi[1900].config_channels_configuration["cycleOffPeriod"] = 31
pi[1900].config_channels_configuration["cycleOnPeriod"] = 32
pi[1900].config_channels_configuration["D"] = 33
pi[1900].config_channels_configuration["daytime_astroadjust"] = 34
pi[1900].config_channels_configuration["daytime_enabled"] = 35
pi[1900].config_channels_configuration["daytime_setpoint"] = 36
pi[1900].config_channels_configuration["daytime_stage"] = 37
pi[1900].config_channels_configuration["daytime_start"] = 38
pi[1900].config_channels_configuration["deadband"] = 39
pi[1900].config_channels_configuration["dir"] = 40
pi[1900].config_channels_configuration["disable_inside_temp_pid"] = 41
pi[1900].config_channels_configuration["dliEndTime"] = 42
pi[1900].config_channels_configuration["dliEnergyOutput"] = 43
pi[1900].config_channels_configuration["dliOnTime"] = 44
pi[1900].config_channels_configuration["dliSensor"] = 45
pi[1900].config_channels_configuration["dliStartTime"] = 46
pi[1900].config_channels_configuration["dliThreshold"] = 47
pi[1900].config_channels_configuration["enableActiveCoolStageOverride"] = 48
pi[1900].config_channels_configuration["enableSmartInject"] = 49
pi[1900].config_channels_configuration["end"] = 50
pi[1900].config_channels_configuration["end_astroadjust"] = 51
pi[1900].config_channels_configuration["end_time"] = 52
pi[1900].config_channels_configuration["energy_end"] = 53
pi[1900].config_channels_configuration["energy_end_astroadjust"] = 54
pi[1900].config_channels_configuration["energy_start"] = 55
pi[1900].config_channels_configuration["energy_start_astroadjust"] = 56
pi[1900].config_channels_configuration["feedingMode"] = 57
pi[1900].config_channels_configuration["fine_adjust_range"] = 58
pi[1900].config_channels_configuration["followChannelIndex"] = 59
pi[1900].config_channels_configuration["followRatio"] = 60
pi[1900].config_channels_configuration["function"] = 61
pi[1900].config_channels_configuration["function_float_max"] = 62
pi[1900].config_channels_configuration["function_float_min"] = 63
pi[1900].config_channels_configuration["function_int_max"] = 64
pi[1900].config_channels_configuration["function_int_min"] = 65
pi[1900].config_channels_configuration["gain"] = 66
pi[1900].config_channels_configuration["heat_on_delay"] = 67
pi[1900].config_channels_configuration["heat1"] = 68
pi[1900].config_channels_configuration["heat2"] = 69
pi[1900].config_channels_configuration["heat3"] = 70
pi[1900].config_channels_configuration["heat4"] = 71
pi[1900].config_channels_configuration["heat5"] = 72
pi[1900].config_channels_configuration["heat6"] = 73
pi[1900].config_channels_configuration["highlight_cover_perc"] = 74
pi[1900].config_channels_configuration["highlight_cover_trigger_light"] = 75
pi[1900].config_channels_configuration["highlight_cover_trigger_temp"] = 76
pi[1900].config_channels_configuration["hold_delay"] = 77
pi[1900].config_channels_configuration["I"] = 78
pi[1900].config_channels_configuration["increased_co2_level"] = 79
pi[1900].config_channels_configuration["injectingBatchTimeSeconds"] = 80
pi[1900].config_channels_configuration["injectingBatchVolumeMilliLiters"] = 81
pi[1900].config_channels_configuration["injectingLimitHours"] = 82
pi[1900].config_channels_configuration["injectingLimitMilliLiters"] = 83
pi[1900].config_channels_configuration["injectingLimitSeconds"] = 84
pi[1900].config_channels_configuration["injectingTimeSeconds"] = 85
pi[1900].config_channels_configuration["injectingVolumeMilliLiters"] = 86
pi[1900].config_channels_configuration["injectionRate"] = 87
pi[1900].config_channels_configuration["inside_temp_d"] = 88
pi[1900].config_channels_configuration["inside_temp_i"] = 89
pi[1900].config_channels_configuration["inside_temp_p"] = 90
pi[1900].config_channels_configuration["irrigation_mode"] = 91
pi[1900].config_channels_configuration["isCycling"] = 92
pi[1900].config_channels_configuration["keep_open_threshold_light"] = 93
pi[1900].config_channels_configuration["keep_open_threshold_temp"] = 94
// pi[1900].config_channels_configuration["light_drive_to"] = 95
pi[1900].config_channels_configuration["light_level"] = 96
pi[1900].config_channels_configuration["light_mode"] = 97
pi[1900].config_channels_configuration["light_sensor_enabled"] = 98
// pi[1900].config_channels_configuration["light_threshold"] = 99
pi[1900].config_channels_configuration["low_light_threshold_duration"] = 100
pi[1900].config_channels_configuration["low_light_threshold_light"] = 101
pi[1900].config_channels_configuration["mixingTimeSeconds"] = 102
pi[1900].config_channels_configuration["mode"] = 103
pi[1900].config_channels_configuration["nighttime_astroadjust"] = 104
pi[1900].config_channels_configuration["nighttime_enabled"] = 105
pi[1900].config_channels_configuration["nighttime_setpoint"] = 106
pi[1900].config_channels_configuration["nighttime_stage"] = 107
pi[1900].config_channels_configuration["nighttime_start"] = 108
pi[1900].config_channels_configuration["normal"] = 109
pi[1900].config_channels_configuration["normal_co2_level"] = 110
pi[1900].config_channels_configuration["off_delay"] = 111
pi[1900].config_channels_configuration["off_duration"] = 112
pi[1900].config_channels_configuration["off_threshold"] = 113
pi[1900].config_channels_configuration["offDelay"] = 114
pi[1900].config_channels_configuration["offset"] = 115
pi[1900].config_channels_configuration["on_duration"] = 116
pi[1900].config_channels_configuration["on_threshold"] = 117
pi[1900].config_channels_configuration["output_type"] = 118
pi[1900].config_channels_configuration["override_action_target"] = 119
pi[1900].config_channels_configuration["override_dir"] = 120
pi[1900].config_channels_configuration["override_limit"] = 121
pi[1900].config_channels_configuration["override_sensor"] = 122
pi[1900].config_channels_configuration["override_trigger"] = 123
pi[1900].config_channels_configuration["P"] = 124
pi[1900].config_channels_configuration["passive_cooling"] = 125
pi[1900].config_channels_configuration["probe_id"] = 126
pi[1900].config_channels_configuration["probeSensorIndex"] = 127
pi[1900].config_channels_configuration["probeType"] = 128
pi[1900].config_channels_configuration["pulse_ff_time"] = 129
pi[1900].config_channels_configuration["pulse_on_time"] = 130
pi[1900].config_channels_configuration["pump_mzone"] = 131
pi[1900].config_channels_configuration["pump_onoff"] = 348
pi[1900].config_channels_configuration["pump_id"] = 132
pi[1900].config_channels_configuration["pump_type"] = 133
pi[1900].config_channels_configuration["pumpMeasurementUnits"] = 134
pi[1900].config_channels_configuration["resetInjectionTracker"] = 135
pi[1900].config_channels_configuration["sensor_mzone"] = 136
// pi[1900].config_channels_configuration["sensorHoldTime"] = 137
pi[1900].config_channels_configuration["setpoint"] = 138
pi[1900].config_channels_configuration["setting_1_threshold_light"] = 139
pi[1900].config_channels_configuration["setting_1_threshold_temp"] = 140
pi[1900].config_channels_configuration["setting_2_threshold_light"] = 141
pi[1900].config_channels_configuration["setting_2_threshold_temp"] = 142
pi[1900].config_channels_configuration["shade_end"] = 143
pi[1900].config_channels_configuration["shade_end_astroadjust"] = 144
pi[1900].config_channels_configuration["shade_start"] = 145
pi[1900].config_channels_configuration["shade_start_astroadjust"] = 146
pi[1900].config_channels_configuration["shock_protect_enabled"] = 147
pi[1900].config_channels_configuration["shock_protect_open_valve_perc"] = 148
pi[1900].config_channels_configuration["shock_protect_threshold"] = 149
pi[1900].config_channels_configuration["shock_protect_time_closed"] = 150
pi[1900].config_channels_configuration["shock_protect_water_sensor"] = 151
pi[1900].config_channels_configuration["soil_moisture_input"] = 152
pi[1900].config_channels_configuration["soil_moisture_threshold"] = 153
pi[1900].config_channels_configuration["stage"] = 154
pi[1900].config_channels_configuration["start"] = 155
pi[1900].config_channels_configuration["start_astroadjust"] = 156
pi[1900].config_channels_configuration["start_time"] = 157
pi[1900].config_channels_configuration["start_time_1"] = 158
pi[1900].config_channels_configuration["start_time_2"] = 159
pi[1900].config_channels_configuration["start_time_3"] = 160
pi[1900].config_channels_configuration["start_time_4"] = 161
pi[1900].config_channels_configuration["start_time_5"] = 162
pi[1900].config_channels_configuration["start_time_6"] = 163
pi[1900].config_channels_configuration["supply_pump"] = 164
pi[1900].config_channels_configuration["tankSensor"] = 165// obsolete! maybe used for irr tankSensor
pi[1900].config_channels_configuration["tankSize"] = 166
// pi[1900].config_channels_configuration["temp_drive_to"] = 167
// pi[1900].config_channels_configuration["temp_sensor"] = 168
// pi[1900].config_channels_configuration["temp_threshold"] = 169
pi[1900].config_channels_configuration["threshold"] = 170
pi[1900].config_channels_configuration["time"] = 171
pi[1900].config_channels_configuration["triggerDelay"] = 172
pi[1900].config_channels_configuration["valve_open_time"] = 173
pi[1900].config_channels_configuration["vpdacc_threshold"] = 174
pi[1900].config_channels_configuration["water_temp_d"] = 175
pi[1900].config_channels_configuration["water_temp_i"] = 176
pi[1900].config_channels_configuration["water_temp_max"] = 177
pi[1900].config_channels_configuration["water_temp_min"] = 178
pi[1900].config_channels_configuration["water_temp_p"] = 179
pi[1900].config_channels_configuration["water_temp_sensor"] = 180
pi[1900].config_channels_configuration["week_a_fri"] = 181
pi[1900].config_channels_configuration["week_a_mon"] = 182
pi[1900].config_channels_configuration["week_a_sat"] = 183
pi[1900].config_channels_configuration["week_a_sun"] = 184
pi[1900].config_channels_configuration["week_a_thu"] = 185
pi[1900].config_channels_configuration["week_a_tue"] = 186
pi[1900].config_channels_configuration["week_a_wed"] = 187
pi[1900].config_channels_configuration["week_b_fri"] = 188
pi[1900].config_channels_configuration["week_b_mon"] = 189
pi[1900].config_channels_configuration["week_b_sat"] = 190
pi[1900].config_channels_configuration["week_b_sun"] = 191
pi[1900].config_channels_configuration["week_b_thu"] = 192
pi[1900].config_channels_configuration["week_b_tue"] = 193
pi[1900].config_channels_configuration["week_b_wed"] = 194
//added
pi[1900].config_channels_configuration["triggers"] = 195
// pi[1900].config_channels_configuration["smartInject"] = 196
// pi[1900].config_channels_configuration["vent_mode"] = 197// should be vent_type!

// pi[1900].config_channels_configuration["P"] = 198
// pi[1900].config_channels_configuration["I"] = 199
// pi[1900].config_channels_configuration["D"] = 200
pi[1900].config_channels_configuration["acDeadband"] =	201
pi[1900].config_channels_configuration["acDoDelay"] =	202
pi[1900].config_channels_configuration["acPcnt_1"] =	203
pi[1900].config_channels_configuration["acPcnt_2"] =	204
pi[1900].config_channels_configuration["acPcnt_3"] =	205
pi[1900].config_channels_configuration["acPcnt_4"] =	206
pi[1900].config_channels_configuration["acTemp_1"] =	207
pi[1900].config_channels_configuration["acTemp_2"] =	208
pi[1900].config_channels_configuration["acTemp_3"] =	209
pi[1900].config_channels_configuration["acTemp_4"] =	210
pi[1900].config_channels_configuration["acUpDelay"] =	211
pi[1900].config_channels_configuration["autoCalibration"] =	212
pi[1900].config_channels_configuration["begin_closing_leeward_trigger_end"] =	213
pi[1900].config_channels_configuration["begin_closing_leeward_trigger_start"] =	214
pi[1900].config_channels_configuration["begin_closing_windward_trigger_end"] =	215
pi[1900].config_channels_configuration["begin_closing_windward_trigger_start"] =	216
pi[1900].config_channels_configuration["closing_delay"] =	217
pi[1900].config_channels_configuration["cool_1"] =	218// duplicates NO! these are used for the vents
pi[1900].config_channels_configuration["cool_2"] =	219
pi[1900].config_channels_configuration["cool_3"] =	220
pi[1900].config_channels_configuration["cool_4"] =	221
pi[1900].config_channels_configuration["cool_5"] =	222
pi[1900].config_channels_configuration["cool_6"] =	223
pi[1900].config_channels_configuration["cp2Delay"] =	224
pi[1900].config_channels_configuration["cp2Pos"] =	225
pi[1900].config_channels_configuration["cp2Wind"] =	226
pi[1900].config_channels_configuration["cp3Delay"] =	227
pi[1900].config_channels_configuration["cp3Pos"] =	228
pi[1900].config_channels_configuration["cp3Wind"] =	229
pi[1900].config_channels_configuration["cpDelay"] =	230
pi[1900].config_channels_configuration["cpPos"] =	231
pi[1900].config_channels_configuration["cpWind"] =	232
pi[1900].config_channels_configuration["direction"] =	233
pi[1900].config_channels_configuration["fireDelay"] =	234
pi[1900].config_channels_configuration["fireIn"] =	235
pi[1900].config_channels_configuration["firePos"] =	236
pi[1900].config_channels_configuration["hailDelay"] =	237
pi[1900].config_channels_configuration["hailPos"] =	238
pi[1900].config_channels_configuration["hailThresh"] =	239
pi[1900].config_channels_configuration["hgDeadband"] =	240
pi[1900].config_channels_configuration["hgDoDelay"] =	241
pi[1900].config_channels_configuration["hgPcnt_1"] =	242
pi[1900].config_channels_configuration["hgPcnt_2"] =	243
pi[1900].config_channels_configuration["hgPcnt_3"] =	244
pi[1900].config_channels_configuration["hgPcnt_4"] =	245
pi[1900].config_channels_configuration["hgTemp_1"] =	246
pi[1900].config_channels_configuration["hgTemp_2"] =	247
pi[1900].config_channels_configuration["hgTemp_3"] =	248
pi[1900].config_channels_configuration["hgTemp_4"] =	249
pi[1900].config_channels_configuration["hgUpDelay"] =	250
pi[1900].config_channels_configuration["max_open"] =	251
pi[1900].config_channels_configuration["max_open_rain"] =	252
pi[1900].config_channels_configuration["open_var"] =	253
pi[1900].config_channels_configuration["rhgDeadband"] =	254
pi[1900].config_channels_configuration["rhgDoDelay"] =	255
pi[1900].config_channels_configuration["rhgTemp_1"] =	256
pi[1900].config_channels_configuration["rhgTemp_2"] =	257
pi[1900].config_channels_configuration["rhgTemp_3"] =	258
pi[1900].config_channels_configuration["rhgTemp_4"] =	259
pi[1900].config_channels_configuration["rhgUpDelay"] =	260
pi[1900].config_channels_configuration["rtDeadband"] =	261
pi[1900].config_channels_configuration["rtDoDelay"] =	262
pi[1900].config_channels_configuration["rtHum_1"] =	263
pi[1900].config_channels_configuration["rtHum_2"] =	264
pi[1900].config_channels_configuration["rtHum_3"] =	265
pi[1900].config_channels_configuration["rtHum_4"] =	266
pi[1900].config_channels_configuration["rtPcnt_1"] =	267
pi[1900].config_channels_configuration["rtPcnt_2"] =	268
pi[1900].config_channels_configuration["rtPcnt_3"] =	269
pi[1900].config_channels_configuration["rtPcnt_4"] =	270
pi[1900].config_channels_configuration["rtUpDelay"] =	271
pi[1900].config_channels_configuration["spDelay"] =	272
pi[1900].config_channels_configuration["spPos"] =	273
pi[1900].config_channels_configuration["spWind"] =	274
pi[1900].config_channels_configuration["temp_below_trigger"] =	275
pi[1900].config_channels_configuration["temp_var"] =	276
pi[1900].config_channels_configuration["rhgPcnt_1"] =	277
pi[1900].config_channels_configuration["rhgPcnt_2"] =	278
pi[1900].config_channels_configuration["rhgPcnt_3"] =	279
pi[1900].config_channels_configuration["rhgPcnt_4"] =	280
pi[1900].config_channels_configuration["vent_type"] =	281
pi[1900].config_channels_configuration["vps_alarm_duration"] =	282
pi[1900].config_channels_configuration["vps_alarm_limit"] =	283
pi[1900].config_channels_configuration["vps_error_exception_limit"] =	284
pi[1900].config_channels_configuration["vps_sensor"] =	285

pi[1900].config_channels_configuration["CD_start_astroadjust_co2"] = 286
pi[1900].config_channels_configuration["CD_start_astroadjust_hid"] = 287
pi[1900].config_channels_configuration["CD_end_astroadjust_co2"] = 288
pi[1900].config_channels_configuration["CD_end_astroadjust_hid"] = 289
pi[1900].config_channels_configuration["CD_start_hid"] = 290
pi[1900].config_channels_configuration["CD_end_hid"] = 291
pi[1900].config_channels_configuration["CD_tankSensor_fval"] = 292
pi[1900].config_channels_configuration["CD_time_generic"] = 293
pi[1900].config_channels_configuration["CD_acPcnt_1_cvent"] = 294
pi[1900].config_channels_configuration["CD_acPcnt_2_cvent"] = 295
pi[1900].config_channels_configuration["CD_acPcnt_3_cvent"] = 296
pi[1900].config_channels_configuration["CD_acPcnt_4_cvent"] = 297
pi[1900].config_channels_configuration["CD_acTemp_1_cvent"] = 298
pi[1900].config_channels_configuration["CD_acTemp_2_cvent"] = 299
pi[1900].config_channels_configuration["CD_acTemp_3_cvent"] = 300
pi[1900].config_channels_configuration["CD_acTemp_4_cvent"] = 301
pi[1900].config_channels_configuration["CD_hgPcnt_1_cvent"] = 302
pi[1900].config_channels_configuration["CD_hgPcnt_2_cvent"] = 303
pi[1900].config_channels_configuration["CD_hgPcnt_3_cvent"] = 304
pi[1900].config_channels_configuration["CD_hgPcnt_4_cvent"] = 305
pi[1900].config_channels_configuration["CD_hgTemp_1_cvent"] = 306
pi[1900].config_channels_configuration["CD_hgTemp_2_cvent"] = 307
pi[1900].config_channels_configuration["CD_hgTemp_3_cvent"] = 308
pi[1900].config_channels_configuration["CD_hgTemp_4_cvent"] = 309
pi[1900].config_channels_configuration["CD_rhgTemp_1_cvent"] = 310
pi[1900].config_channels_configuration["CD_rhgTemp_2_cvent"] = 311
pi[1900].config_channels_configuration["CD_rhgTemp_3_cvent"] = 312
pi[1900].config_channels_configuration["CD_rhgTemp_4_cvent"] = 313
pi[1900].config_channels_configuration["CD_rtHum_1_cvent"] = 314
pi[1900].config_channels_configuration["CD_rtHum_2_cvent"] = 315
pi[1900].config_channels_configuration["CD_rtHum_3_cvent"] = 316
pi[1900].config_channels_configuration["CD_rtHum_4_cvent"] = 317
pi[1900].config_channels_configuration["CD_rtPcnt_1_cvent"] = 318
pi[1900].config_channels_configuration["CD_rtPcnt_2_cvent"] = 319
pi[1900].config_channels_configuration["CD_rtPcnt_3_cvent"] = 320
pi[1900].config_channels_configuration["CD_rtPcnt_4_cvent"] = 321
pi[1900].config_channels_configuration["CD_rhgPcnt_1_cvent"] = 322
pi[1900].config_channels_configuration["CD_rhgPcnt_2_cvent"] = 323
pi[1900].config_channels_configuration["CD_rhgPcnt_3_cvent"] = 324
pi[1900].config_channels_configuration["CD_rhgPcnt_4_cvent"] = 325
pi[1900].config_channels_configuration["CD_start_time_1_hid"] = 326
pi[1900].config_channels_configuration["CD_start_time_2_hid"] = 327
pi[1900].config_channels_configuration["CD_start_time_3_hid"] = 328
pi[1900].config_channels_configuration["CD_start_time_4_hid"] = 329
pi[1900].config_channels_configuration["CD_start_time_5_hid"] = 330
pi[1900].config_channels_configuration["CD_start_time_6_hid"] = 331
pi[1900].config_channels_configuration["CD_week_a_fri_hid"] = 332
pi[1900].config_channels_configuration["CD_week_a_mon_hid"] = 333
pi[1900].config_channels_configuration["CD_week_a_sat_hid"] = 334
pi[1900].config_channels_configuration["CD_week_a_sun_hid"] = 335
pi[1900].config_channels_configuration["CD_week_a_thu_hid"] = 336
pi[1900].config_channels_configuration["CD_week_a_tue_hid"] = 337
pi[1900].config_channels_configuration["CD_week_a_wed_hid"] = 338
pi[1900].config_channels_configuration["CD_week_b_fri_hid"] = 339
pi[1900].config_channels_configuration["CD_week_b_mon_hid"] = 340
pi[1900].config_channels_configuration["CD_week_b_sat_hid"] = 341
pi[1900].config_channels_configuration["CD_week_b_sun_hid"] = 342
pi[1900].config_channels_configuration["CD_week_b_thu_hid"] = 343
pi[1900].config_channels_configuration["CD_week_b_tue_hid"] = 344
pi[1900].config_channels_configuration["CD_week_b_wed_hid"] = 345
pi[1900].config_channels_configuration["CD_sensor_generic"] = 346
pi[1900].config_channels_configuration["CD_mode_varout"] = 347
pi[1900].config_channels_configuration["CD_pump_onoff"] = 348
pi[1900].config_channels_configuration["CD_supply_pump_generic"] = 349
pi[1900].config_channels_configuration["CD_analog_min_varout"] = 350
pi[1900].config_channels_configuration["CD_analog_min_generic"] = 351
pi[1900].config_channels_configuration["CD_analog_max_varout"] = 352
pi[1900].config_channels_configuration["CD_analog_max_generic"] = 353
pi[1900].config_channels_configuration["CD_setpoint_generic"] = 354
pi[1900].config_channels_configuration["CD_activeTrigger_fval"] = 355

pi[1900].config_channels_configuration["CD_activeCool_cool1_onoff"] = 356// of On/Off +600
pi[1900].config_channels_configuration["CD_activeCool_cool2_onoff"] = 357
pi[1900].config_channels_configuration["CD_activeCool_cool3_onoff"] = 358
pi[1900].config_channels_configuration["CD_activeCool_cool4_onoff"] = 359
pi[1900].config_channels_configuration["CD_activeCool_cool5_onoff"] = 360
pi[1900].config_channels_configuration["CD_activeCool_cool6_onoff"] = 361
pi[1900].config_channels_configuration["CD_activeCool_normal_onoff"] = 362
pi[1900].config_channels_configuration["CD_heat6_onoff"] = 363
pi[1900].config_channels_configuration["CD_heat5_onoff"] = 364
pi[1900].config_channels_configuration["CD_heat4_onoff"] = 365
pi[1900].config_channels_configuration["CD_heat3_onoff"] = 366
pi[1900].config_channels_configuration["CD_heat2_onoff"] = 367
pi[1900].config_channels_configuration["CD_heat1_onoff"] = 368
pi[1900].config_channels_configuration["CD_normal_onoff"] = 369
pi[1900].config_channels_configuration["CD_cool1_onoff"] = 370
pi[1900].config_channels_configuration["CD_cool2_onoff"] = 371
pi[1900].config_channels_configuration["CD_cool3_onoff"] = 372
pi[1900].config_channels_configuration["CD_cool4_onoff"] = 373
pi[1900].config_channels_configuration["CD_cool5_onoff"] = 374
pi[1900].config_channels_configuration["CD_cool6_onoff"] = 375

pi[1900].config_channels_configuration["CD_sensorHoldTime"] = 376
pi[1900].config_channels_configuration["CD_light_threshold"] = 377
pi[1900].config_channels_configuration["CD_light_drive_to"] = 378
pi[1900].config_channels_configuration["CD_temp_sensor"] = 379
pi[1900].config_channels_configuration["CD_temp_threshold"] = 380
pi[1900].config_channels_configuration["CD_temp_drive_to"] = 381
pi[1900].config_channels_configuration["CD_enableSmartInject"] = 382

pi[1900].config_channels_configuration["CD_trigStartType_fval"] = 387// triggers
pi[1900].config_channels_configuration["CD_trigStartIDX_fval"] = 388
pi[1900].config_channels_configuration["CD_trigStopType_fval"] = 389
pi[1900].config_channels_configuration["CD_trigStopIDX_fval"] = 390
pi[1900].config_channels_configuration["CD_time_sec_fval"] = 391
pi[1900].config_channels_configuration["CD_volume_fval"] = 392

pi[1900].config_channels_configuration["CD_trigStartType_pump"] = 405
pi[1900].config_channels_configuration["CD_trigStartIDX_pump"] = 406
pi[1900].config_channels_configuration["CD_batchVolume_pump"] = 407

pi[1900].config_channels_configuration["CD_minDifferencex10_pump"] = 414
pi[1900].config_channels_configuration["CD_ratiox100_pump"] = 415

pi[1900].snapshot_aux_alarms["alarmValue"] = 0;;// 1024
pi[1900].snapshot_aux_persistent_variables["variableValue"] = 0;;// 1056
pi[1900].snapshot_aux_variables["variableValue"] = 0;;// 1056
pi[1900].snapshot_expansion_boards["boardStatus"] = 0;;// 1056




//var config_aux_alarms = {}
pi[1900].config_aux_alarms["alarmIndex"] = 0
pi[1900].config_aux_alarms["alarmName"] = 1
pi[1900].config_aux_alarms["allowGraphing"] = 2
pi[1900].config_aux_alarms["userComment"] = 3

//var config_aux_controls = {}
pi[1900].config_aux_controls["auxIndex"] = 0
pi[1900].config_aux_controls["unix_timestamp(modified)"] = 1
pi[1900].config_aux_controls["operand1Type"] = 2
pi[1900].config_aux_controls["operand1Value"] = 3
pi[1900].config_aux_controls["operand2Type"] = 4
pi[1900].config_aux_controls["operand2Value"] = 5
pi[1900].config_aux_controls["operand3Type"] = 6
pi[1900].config_aux_controls["operand3Value"] = 7
pi[1900].config_aux_controls["operator1"] = 8
pi[1900].config_aux_controls["operator2"] = 9
pi[1900].config_aux_controls["conditionSeconds"] = 10
pi[1900].config_aux_controls["action"] = 11
pi[1900].config_aux_controls["targetType"] = 12
pi[1900].config_aux_controls["targetValue"] = 13
pi[1900].config_aux_controls["actionParameter"] = 14
pi[1900].config_aux_controls["actionHoldTime"] = 15
pi[1900].config_aux_controls["userComment"] = 16

//var config_aux_persistent_variables = {}
// config_aux_persistent_variables["zoneIndex"] = 827
// config_aux_persistent_variables["unitIndex"] = 828
pi[1900].config_aux_persistent_variables["variableIndex"] = 0
pi[1900].config_aux_persistent_variables["variableName"] = 1
pi[1900].config_aux_persistent_variables["allowGraphing"] = 2
pi[1900].config_aux_persistent_variables["userComment"] = 3

pi[1900].config_annex_sensors["alarmEnable"] = 0
pi[1900].config_annex_sensors["alarmLow"] = 1
pi[1900].config_annex_sensors["alarmHigh"] = 2
pi[1900].config_annex_sensors["alarmDeadband"] = 3
pi[1900].config_annex_sensors["alarmDelay"] = 4
pi[1900].config_annex_sensors["alarmLevel"] = 5
pi[1900].config_annex_sensors["notifyWhom"] = 6
pi[1900].config_annex_sensors["notifyHowOften"] = 7
pi[1900].config_annex_sensors["notifyPerDay"] = 8
pi[1900].config_annex_sensors["notifySnoozeEnable"] = 9
pi[1900].config_annex_sensors["notifySnooze"] = 10// 20 slots reseverved
pi[1900].config_annex_sensors["alarmState"] = 11
pi[1900].config_annex_sensors["notifyTime"] = 12
pi[1900].config_annex_sensors["notifyCount"] = 13

//var config_aux_variables = {}
// axxx["zoneIndex"] = 834
// axxx["unitIndex"] = 835
pi[1900].config_aux_variables["variableIndex"] = 0
pi[1900].config_aux_variables["variableName"] = 1
pi[1900].config_aux_variables["allowGraphing"] = 2
pi[1900].config_aux_variables["userComment"] = 3

//var config_expansion_boards = {}
// zxx["zoneIndex"] =
// zxx["unitIndex"] =
// pi[1900].config_expansion_boards["boardIndex"] = 0 // 64
// pi[1900].config_expansion_boards["unix_timestamp(modified)"] = 1
pi[1900].config_expansion_boards["boardType"] = 0
pi[1900].config_expansion_boards["address"] = 1
pi[1900].config_expansion_boards["startChannelIndex"] = 2
pi[1900].config_expansion_boards["numOutputs"] = 3
pi[1900].config_expansion_boards["numInputs"] = 4
pi[1900].config_expansion_boards["startInput"] = 5
pi[1900].config_expansion_boards["modbusPort"] = 6
pi[1900].config_expansion_boards["uniqueId0"] = 7

//var config_setpoints = {}
// zxx["zoneIndex"] =
// pi[1900].config_setpoints["setpointIndex"] = 0 // 8 * 10// can be used for setpointName
pi[1900].config_setpoints["name"] = 0 // 8 * 10// can be used for setpointName
pi[1900].config_setpoints["unix_timestamp(modified)"] = 1
pi[1900].config_setpoints["enabled"] = 2
pi[1900].config_setpoints["startTimeOfDay"] = 3
pi[1900].config_setpoints["astroAdjust"] = 4
pi[1900].config_setpoints["rampMinutes"] = 5
pi[1900].config_setpoints["heatSetpoint"] = 6
pi[1900].config_setpoints["coolSetpoint"] = 7
pi[1900].config_setpoints["humidifySetpoint"] = 8
pi[1900].config_setpoints["dehumidifySetpoint"] = 9

//var config_ecph = {}
// zzz["zoneIndex"] =
// zzz["unitIndex"] =
pi[1900].config_ecph["ecphIndex"] = 0 // 8 * 11 of these
pi[1900].config_ecph["unix_timestamp(modified)"] = 1
pi[1900].config_ecph["name"] = 2
pi[1900].config_ecph["ecType"] = 3
pi[1900].config_ecph["alarmHoldTime"] = 4
pi[1900].config_ecph["lowECThreshold"] = 5
pi[1900].config_ecph["highECThreshold"] = 6
pi[1900].config_ecph["highECDeviationThreshold"] = 7
pi[1900].config_ecph["lowPHThreshold"] = 8
pi[1900].config_ecph["highPHThreshold"] = 9
pi[1900].config_ecph["highPHDeviationThreshold"] = 10

//var config_ecph_sensors = {}
// config_ecph_sensors["zoneIndex"] =
// config_ecph_sensors["unitIndex"] =
// config_ecph_sensors["ecphIndex"] =
// config_ecph_sensors["sensorIndex"] =
pi[1900].config_ecph_sensors["(modified)"] = 0
pi[1900].config_ecph_sensors["ecMapping"] = 1
pi[1900].config_ecph_sensors["ecServiceIntervalDays"] = 2
pi[1900].config_ecph_sensors["ecServiceTime"] = 3
pi[1900].config_ecph_sensors["ecCalibrationIntervalDays"] = 4
pi[1900].config_ecph_sensors["ecCalibrationTime"] = 5
pi[1900].config_ecph_sensors["ecCalibration1Value"] = 6
pi[1900].config_ecph_sensors["ecCalibration1Raw"] = 7
pi[1900].config_ecph_sensors["ecCalibration2Value"] = 8
pi[1900].config_ecph_sensors["ecCalibration2Raw"] = 9
pi[1900].config_ecph_sensors["phMapping"] = 10
pi[1900].config_ecph_sensors["phServiceIntervalDays"] = 11
pi[1900].config_ecph_sensors["phServiceTime"] = 12
pi[1900].config_ecph_sensors["phCalibrationIntervalDays"] = 13
pi[1900].config_ecph_sensors["phCalibrationTime"] = 14
pi[1900].config_ecph_sensors["phCalibration1Value"] = 15
pi[1900].config_ecph_sensors["phCalibration1Raw"] = 16
pi[1900].config_ecph_sensors["phCalibration2Value"] = 17
pi[1900].config_ecph_sensors["phCalibration2Raw"] = 18
pi[1900].config_ecph_sensors["temperatureMapping"] = 19
pi[1900].config_ecph_sensors["temperatureCalibration"] = 20
pi[1900].config_ecph_sensors["temperatureCompensationMode"] = 21
pi[1900].config_ecph_sensors["temperatureCompensationValue"] = 22

//var config_zones = {}
// config_zones["zoneIndex"] =
pi[1900].config_zones["name"] = 0
pi[1900].config_zones["description"] = 1

//var config_controllers = {}
// config_controllers["zoneIndex"] =
// config_controllers["unitIndex"] =
pi[1900].config_controllers["address"] = 0
pi[1900].config_controllers["igrowVersion"] = 1
pi[1900].config_controllers["isConnected"] = 2
pi[1900].config_controllers["isInNetwork"] = 3

//var config_communication_status = {}
pi[1900].config_communication_status["statusID"] = 0
pi[1900].config_communication_status["unix_timestamp(statusTime)"] = 1
pi[1900].config_communication_status["statusLog"] = 2

//var controller_configuration_settings = {}// 20210706, these are getting the *2+1 treatment - Probably Not!
pi[1900].controller_configuration_settings["Inside Temperature Mapping"] = 1
pi[1900].controller_configuration_settings["Relative Humidity Mapping"] = 2
pi[1900].controller_configuration_settings["Local Temperature Mapping"] = 3
pi[1900].controller_configuration_settings["Local Humidity Mapping"] = 4
pi[1900].controller_configuration_settings["Outside Temperature Mapping"] = 5
pi[1900].controller_configuration_settings["Outside Humidity Mapping"] = 6
pi[1900].controller_configuration_settings["CO2 Mapping"] = 7
pi[1900].controller_configuration_settings["Outside Light Mapping"] = 8
pi[1900].controller_configuration_settings["Wind Speed Mapping"] = 9
pi[1900].controller_configuration_settings["Wind Direction Mapping"] = 10
pi[1900].controller_configuration_settings["Rain Mapping"] = 11
pi[1900].controller_configuration_settings["Analog Temperature 1 Mapping"] = 12
pi[1900].controller_configuration_settings["Analog Temperature 2 Mapping"] = 13
pi[1900].controller_configuration_settings["Analog Temperature 3 Mapping"] = 14
pi[1900].controller_configuration_settings["Analog Temperature 4 Mapping"] = 15
pi[1900].controller_configuration_settings["Analog Temperature 5 Mapping"] = 16
pi[1900].controller_configuration_settings["Soil Moisture 1 Mapping"] = 17
pi[1900].controller_configuration_settings["Soil Moisture 2 Mapping"] = 18
pi[1900].controller_configuration_settings["Soil Moisture 3 Mapping"] = 19
pi[1900].controller_configuration_settings["Soil Moisture 4 Mapping"] = 20
pi[1900].controller_configuration_settings["Soil Moisture 5 Mapping"] = 21
pi[1900].controller_configuration_settings["Vent Position Sensor 1 Mapping"] = 22
pi[1900].controller_configuration_settings["Vent Position Sensor 2 Mapping"] = 23
pi[1900].controller_configuration_settings["Vent Position Sensor 3 Mapping"] = 24
pi[1900].controller_configuration_settings["Vent Position Sensor 4 Mapping"] = 25
pi[1900].controller_configuration_settings["Vent Position Sensor 5 Mapping"] = 26
pi[1900].controller_configuration_settings["EC/pH 1 Mapping"] = 27
pi[1900].controller_configuration_settings["EC/pH 2 Mapping"] = 28
pi[1900].controller_configuration_settings["EC/pH 3 Mapping"] = 29
pi[1900].controller_configuration_settings["EC/pH 4 Mapping"] = 30
pi[1900].controller_configuration_settings["EC/pH 5 Mapping"] = 31
pi[1900].controller_configuration_settings["EC/pH 1 Probe Type"] = 32
pi[1900].controller_configuration_settings["EC/pH 2 Probe Type"] = 33
pi[1900].controller_configuration_settings["EC/pH 3 Probe Type"] = 34
pi[1900].controller_configuration_settings["EC/pH 4 Probe Type"] = 35
pi[1900].controller_configuration_settings["EC/pH 5 Probe Type"] = 36
pi[1900].controller_configuration_settings["Generic 1 Mapping"] = 37
pi[1900].controller_configuration_settings["Generic 2 Mapping"] = 38
pi[1900].controller_configuration_settings["Inside Temperature Calibration"] = 39
pi[1900].controller_configuration_settings["Relative Humidity Calibration"] = 40
pi[1900].controller_configuration_settings["Outside Temperature Calibration"] = 41
pi[1900].controller_configuration_settings["Outside Humidity Calibration"] = 42
pi[1900].controller_configuration_settings["CO2 Calibration"] = 43
pi[1900].controller_configuration_settings["Outside Light Calibration"] = 44
pi[1900].controller_configuration_settings["Wind Speed Calibration"] = 45
pi[1900].controller_configuration_settings["Fallback Temperature Calibration"] = 46
pi[1900].controller_configuration_settings["Analog Temperature 1 Calibration"] = 47
pi[1900].controller_configuration_settings["Analog Temperature 2 Calibration"] = 48
pi[1900].controller_configuration_settings["Analog Temperature 3 Calibration"] = 49
pi[1900].controller_configuration_settings["Analog Temperature 4 Calibration"] = 50
pi[1900].controller_configuration_settings["Analog Temperature 5 Calibration"] = 51
pi[1900].controller_configuration_settings["Soil Mositure 1 Calibration"] = 52
pi[1900].controller_configuration_settings["Soil Mositure 2 Calibration"] = 53
pi[1900].controller_configuration_settings["Soil Mositure 3 Calibration"] = 54
pi[1900].controller_configuration_settings["Soil Mositure 4 Calibration"] = 55
pi[1900].controller_configuration_settings["Soil Mositure 5 Calibration"] = 56
pi[1900].controller_configuration_settings["Vent Position Sensor 1 Calibration - Adjust"] = 57
pi[1900].controller_configuration_settings["Vent Position Sensor 2 Calibration - Adjust"] = 58
pi[1900].controller_configuration_settings["Vent Position Sensor 3 Calibration - Adjust"] = 59
pi[1900].controller_configuration_settings["Vent Position Sensor 4 Calibration - Adjust"] = 60
pi[1900].controller_configuration_settings["Vent Position Sensor 5 Calibration - Adjust"] = 61
pi[1900].controller_configuration_settings["EC/pH 1 Calibration - Zero"] = 62
pi[1900].controller_configuration_settings["EC/pH 2 Calibration - Zero"] = 63
pi[1900].controller_configuration_settings["EC/pH 3 Calibration - Zero"] = 64
pi[1900].controller_configuration_settings["EC/pH 4 Calibration - Zero"] = 65
pi[1900].controller_configuration_settings["EC/pH 5 Calibration - Zero"] = 66
pi[1900].controller_configuration_settings["EC/pH 1 Calibration - Gain"] = 67
pi[1900].controller_configuration_settings["EC/pH 2 Calibration - Gain"] = 68
pi[1900].controller_configuration_settings["EC/pH 3 Calibration - Gain"] = 69
pi[1900].controller_configuration_settings["EC/pH 4 Calibration - Gain"] = 70
pi[1900].controller_configuration_settings["EC/pH 5 Calibration - Gain"] = 71
pi[1900].controller_configuration_settings["EC/pH 1 Calibration - Given Gain"] = 72
pi[1900].controller_configuration_settings["EC/pH 2 Calibration - Given Gain"] = 73
pi[1900].controller_configuration_settings["EC/pH 3 Calibration - Given Gain"] = 74
pi[1900].controller_configuration_settings["EC/pH 4 Calibration - Given Gain"] = 75
pi[1900].controller_configuration_settings["EC/pH 5 Calibration - Given Gain"] = 76
pi[1900].controller_configuration_settings["Generic 1 Calibration"] = 77
pi[1900].controller_configuration_settings["Generic 2 Calibration"] = 78
pi[1900].controller_configuration_settings["CO2 Least Significant Bit"] = 79
pi[1900].controller_configuration_settings["Light Multiplier"] = 80
pi[1900].controller_configuration_settings["Generic 1 Multiplier"] = 81
pi[1900].controller_configuration_settings["Generic 2 Multiplier"] = 82
pi[1900].controller_configuration_settings["Local/Remote Setpoints"] = 83
pi[1900].controller_configuration_settings["Enable Bump Vents"] = 84
pi[1900].controller_configuration_settings["Bump Intervals Minutes)"] = 85
pi[1900].controller_configuration_settings["Spike Temperature Delta"] = 86
pi[1900].controller_configuration_settings["Spike Temperature Hold Time Exception"] = 87
pi[1900].controller_configuration_settings["Outside Temperature 2 Mapping"] = 88
pi[1900].controller_configuration_settings["Outside Temperature 2 Calibration"] = 89
pi[1900].controller_configuration_settings["Barometric Pressure Mapping"] = 90
pi[1900].controller_configuration_settings["Barometric Pressure Calibration"] = 91
pi[1900].controller_configuration_settings["Enable expansion board"] = 92
pi[1900].controller_configuration_settings["Autodetect Mode"] = 93
pi[1900].controller_configuration_settings["Heat Demand Offset"] = 94
pi[1900].controller_configuration_settings["Cool Demand Offset"] = 95
pi[1900].controller_configuration_settings["Generic Sensor 1 Range"] = 96
pi[1900].controller_configuration_settings["Generic Sensor 1 Units"] = 97
pi[1900].controller_configuration_settings["Generic Sensor 1 Filter Max Samples"] = 98
pi[1900].controller_configuration_settings["Generic Sensor 2 Range"] = 99
pi[1900].controller_configuration_settings["Generic Sensor 2 Units"] = 100
pi[1900].controller_configuration_settings["Generic PID Multipliers P"] = 101
pi[1900].controller_configuration_settings["Generic PID Multipliers I"] = 102
pi[1900].controller_configuration_settings["Generic PID Multipliers D"] = 103
pi[1900].controller_configuration_settings["Expansion Output 1 Type"] = 109
pi[1900].controller_configuration_settings["Expansion Output 2 Type"] = 110
pi[1900].controller_configuration_settings["Expansion Input 1 Type"] = 111
pi[1900].controller_configuration_settings["Expansion Input 2 Type"] = 112
pi[1900].controller_configuration_settings["Sensor Delay"] = 113
pi[1900].controller_configuration_settings["Vent Position 1 Calibration - Open"] = 114
pi[1900].controller_configuration_settings["Vent Position 2 Calibration - Open"] = 115
pi[1900].controller_configuration_settings["Vent Position 3 Calibration - Open"] = 116
pi[1900].controller_configuration_settings["Vent Position 4 Calibration - Open"] = 117
pi[1900].controller_configuration_settings["Vent Position 5 Calibration - Open"] = 118
pi[1900].controller_configuration_settings["Vent Position 1 Calibration - Close"] = 119
pi[1900].controller_configuration_settings["Vent Position 2 Calibration - Close"] = 120
pi[1900].controller_configuration_settings["Vent Position 3 Calibration - Close"] = 121
pi[1900].controller_configuration_settings["Vent Position 4 Calibration - Close"] = 122
pi[1900].controller_configuration_settings["Vent Position 5 Calibration - Close"] = 123
pi[1900].controller_configuration_settings["Inside Light Mapping"] = 124
pi[1900].controller_configuration_settings["Differential Pressure Mapping"] = 125
pi[1900].controller_configuration_settings["Snow Mapping"] = 126
pi[1900].controller_configuration_settings["Inside Light Calibration"] = 127
pi[1900].controller_configuration_settings["Differential Pressure Calibration"] = 128
pi[1900].controller_configuration_settings["Accumulator 1 - Sensor"] = 129
pi[1900].controller_configuration_settings["Accumulator 1 - Operating Period"] = 130
pi[1900].controller_configuration_settings["Accumulator 2 - Sensor"] = 131
pi[1900].controller_configuration_settings["Accumulator 2 - Operating Period"] = 132
pi[1900].controller_configuration_settings["Generic Sensor 2 Filter Max Samples"] = 133
pi[1900].controller_configuration_settings["Hold Temp Vent Open"] = 134
pi[1900].controller_configuration_settings["Irrigation Trigger Input Mapping"] = 135
pi[1900].controller_configuration_settings["Fallback Sensor Input Mapping"] = 136
pi[1900].controller_configuration_settings["Enable Expansion Board"] = 137
pi[1900].controller_configuration_settings["Enable Loud Vent"] = 138
pi[1900].controller_configuration_settings["Wind Direction Calibration"] = 139
pi[1900].controller_configuration_settings["Rain Calibration"] = 140
pi[1900].controller_configuration_settings["Snow Calibration"] = 141
pi[1900].controller_configuration_settings["Inside Light Multiplier"] = 142
pi[1900].controller_configuration_settings["Canopy Sensor Mapping"] = 143
pi[1900].controller_configuration_settings["Canopy Sensor Calibration"] = 144
pi[1900].controller_configuration_settings["Max Temperature Adjustment"] = 145
pi[1900].controller_configuration_settings["Temperature Average Enable"] = 146
pi[1900].controller_configuration_settings["Temperature Average Include Temp1"] = 147
pi[1900].controller_configuration_settings["Temperature Average Include Temp2"] = 148
pi[1900].controller_configuration_settings["Temperature Average Include Temp3"] = 149
pi[1900].controller_configuration_settings["Temperature Average Include Temp4"] = 150
pi[1900].controller_configuration_settings["Temperature Average Include Temp5"] = 151
pi[1900].controller_configuration_settings["VPD Notify"] = 152
pi[1900].controller_configuration_settings["VPD Min"] = 153
pi[1900].controller_configuration_settings["VPD Max"] = 154
pi[1900].controller_configuration_settings["Cloud 1900 Enable"] = 155
pi[1900].controller_configuration_settings["Save Inside Temperature Calibration"] = 156
pi[1900].controller_configuration_settings["Save Temperature Average Enable"] = 157

pi[1900].zone_configuration_settings["Use Fallback Sensor if In Temp Fails"] = 1
pi[1900].zone_configuration_settings["Fallback Sensor Failed Temperature Stage"] = 3
pi[1900].zone_configuration_settings["Force To No Sensor Stage setting 3) if High Alarm occurs"] = 4
pi[1900].zone_configuration_settings["Rain Hold Time"] = 5
pi[1900].zone_configuration_settings["Command Delay Time"] = 6
pi[1900].zone_configuration_settings["Daylight Savings Time"] = 7
pi[1900].zone_configuration_settings["Log History"] = 8
pi[1900].zone_configuration_settings["Latitude"] = 9
pi[1900].zone_configuration_settings["Longitude"] = 10
pi[1900].zone_configuration_settings["Temperature Units"] = 11
pi[1900].zone_configuration_settings["Windspeed Units"] = 12
pi[1900].zone_configuration_settings["Light Units"] = 13
pi[1900].zone_configuration_settings["Irrigation Mode"] = 14
pi[1900].zone_configuration_settings["Irrigation Delay"] = 15
pi[1900].zone_configuration_settings["High Alarm Temperature Above Cool Setpoint Threshold"] = 17
pi[1900].zone_configuration_settings["High Alarm Temperature Hold Time"] = 18
pi[1900].zone_configuration_settings["Low Alarm Temperature Below Heat Setpoint Threshold"] = 19
pi[1900].zone_configuration_settings["Low Alarm Temperature Hold Time"] = 20
pi[1900].zone_configuration_settings["Cool Deadband"] = 21
pi[1900].zone_configuration_settings["Heat Deadband"] = 22
pi[1900].zone_configuration_settings["Humidity Deadband"] = 23
pi[1900].zone_configuration_settings["Analog Temperature Deadband"] = 24
pi[1900].zone_configuration_settings["Enable SmartCool"] = 25
pi[1900].zone_configuration_settings["SmartCool Setting"] = 26
pi[1900].zone_configuration_settings["Show Heat Demand on iGrow"] = 32
pi[1900].zone_configuration_settings["Heat Demand Max Light"] = 33
pi[1900].zone_configuration_settings["Heat Demand Max Wind"] = 34
pi[1900].zone_configuration_settings["Greenhouse heating design delta-T"] = 35
pi[1900].zone_configuration_settings["Light''s influence on Heat Demand"] = 36
pi[1900].zone_configuration_settings["Wind''s influence on Heat Demand"] = 37
pi[1900].zone_configuration_settings["Show Cool Demand on iGrow"] = 38
pi[1900].zone_configuration_settings["Cool Demand Max Light"] = 39
pi[1900].zone_configuration_settings["Greenhouse cooling design delta-T"] = 40
pi[1900].zone_configuration_settings["Cool Demand Light Factor"] = 41
pi[1900].zone_configuration_settings["Enable Active Cooling"] = 42
pi[1900].zone_configuration_settings["Passive Lock Stage"] = 43
pi[1900].zone_configuration_settings["Enter Active Cooling Cool Demand Threshold"] = 44
pi[1900].zone_configuration_settings["Exit Active Cooling Cool Demand Threshold"] = 45
pi[1900].zone_configuration_settings["Cool stages"] = 46
pi[1900].zone_configuration_settings["Heat stages"] = 47
pi[1900].zone_configuration_settings["Stage Width"] = 48
pi[1900].zone_configuration_settings["Stage Override 1 Enabled"] = 49
pi[1900].zone_configuration_settings["Stage Override 1 Temperature Stage"] = 50
pi[1900].zone_configuration_settings["Stage Override 1 Start Time"] = 51
pi[1900].zone_configuration_settings["Stage Override 1 End Time"] = 52
pi[1900].zone_configuration_settings["Stage Override 1 Interval"] = 53
pi[1900].zone_configuration_settings["Stage Override 2 Enabled"] = 54
pi[1900].zone_configuration_settings["Stage Override 2 Temperature Stage"] = 55
pi[1900].zone_configuration_settings["Stage Override 2 Start Time"] = 56
pi[1900].zone_configuration_settings["Stage Override 2 End Time"] = 57
pi[1900].zone_configuration_settings["Stage Override 2 Interval"] = 58
pi[1900].zone_configuration_settings["Stage Override 3 Enabled"] = 59
pi[1900].zone_configuration_settings["Stage Override 3 Temperature Stage"] = 60
pi[1900].zone_configuration_settings["Stage Override 3 Start Time"] = 61
pi[1900].zone_configuration_settings["Stage Override 3 End Time"] = 62
pi[1900].zone_configuration_settings["Stage Override 3 Interval"] = 63
pi[1900].zone_configuration_settings["Stage Override 4 Enabled"] = 64
pi[1900].zone_configuration_settings["Stage Override 4 Temperature Stage"] = 65
pi[1900].zone_configuration_settings["Stage Override 4 Start Time"] = 66
pi[1900].zone_configuration_settings["Stage Override 4 End Time"] = 67
pi[1900].zone_configuration_settings["Stage Override 4 Interval"] = 68
pi[1900].zone_configuration_settings["Vapor Pressure Deficit Media Sensor"] = 69
pi[1900].zone_configuration_settings["Lighting Cyclic Mode"] = 70
pi[1900].zone_configuration_settings["Lighting Start Delay"] = 71
pi[1900].zone_configuration_settings["Lighting Finish Delay"] = 72
pi[1900].zone_configuration_settings["Lighting Active Time"] = 73
pi[1900].zone_configuration_settings["Lighting Minimum On Time"] = 74
pi[1900].zone_configuration_settings["Drive to Average - Enable"] = 75
pi[1900].zone_configuration_settings["Drive to Average - Target Temperature"] = 76
pi[1900].zone_configuration_settings["Drive to Average - Maximum Failed Days"] = 77
pi[1900].zone_configuration_settings["Drive to Average - Deviated Temperature Threshold"] = 78
pi[1900].zone_configuration_settings["Drive to Average - Setpoint Correction"] = 79
pi[1900].zone_configuration_settings["Cool Setpoint Influence Factor - Enable"] = 80
pi[1900].zone_configuration_settings["Cool Setpoint Influence Factor - Sensor"] = 81
pi[1900].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Threshold"] = 82
pi[1900].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Offset"] = 83
pi[1900].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Threshold"] = 84
pi[1900].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Offset"] = 85
pi[1900].zone_configuration_settings["Heat Setpoint Influence Factor - Enable"] = 86
pi[1900].zone_configuration_settings["Heat Setpoint Influence Factor - Sensor"] = 87
pi[1900].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Threshold"] = 88
pi[1900].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Offset"] = 89
pi[1900].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Threshold"] = 90
pi[1900].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Offset"] = 91
pi[1900].zone_configuration_settings["Start Up Delay"] = 92
pi[1900].zone_configuration_settings["Curtain Energy Mode - Light Deadband"] = 93
pi[1900].zone_configuration_settings["Curtain Energy Mode - Temperature Deadband"] = 94
pi[1900].zone_configuration_settings["Humidity Override On Time"] = 95
pi[1900].zone_configuration_settings["Humidity Override Off Time"] = 96
pi[1900].zone_configuration_settings["Dehumidify Low Outside Temperature Threshold"] = 97
pi[1900].zone_configuration_settings["Dehumidify Heat Boost"] = 98
pi[1900].zone_configuration_settings["Enable Demands Based on Active Cool"] = 99
pi[1900].zone_configuration_settings["Enable Active Cool Stage Override"] = 100
pi[1900].zone_configuration_settings["SmartCool[0] Ku"] = 101
pi[1900].zone_configuration_settings["SmartCool[0] Gmax"] = 102
pi[1900].zone_configuration_settings["SmartCool[0] Kd"] = 103
pi[1900].zone_configuration_settings["SmartCool[0] Gmin"] = 104
pi[1900].zone_configuration_settings["SmartCool[1] Ku"] = 105
pi[1900].zone_configuration_settings["SmartCool[1] Gmax"] = 106
pi[1900].zone_configuration_settings["SmartCool[1] Kd"] = 107
pi[1900].zone_configuration_settings["SmartCool[1] Gmin"] = 108
pi[1900].zone_configuration_settings["SmartCool[2] Ku"] = 109
pi[1900].zone_configuration_settings["SmartCool[2] Gmax"] = 110
pi[1900].zone_configuration_settings["SmartCool[2] Kd"] = 111
pi[1900].zone_configuration_settings["SmartCool[2] Gmin"] = 112
pi[1900].zone_configuration_settings["SmartCool[3] Ku"] = 113
pi[1900].zone_configuration_settings["SmartCool[3] Gmax"] = 114
pi[1900].zone_configuration_settings["SmartCool[3] Kd"] = 115
pi[1900].zone_configuration_settings["SmartCool[3] Gmin"] = 116
pi[1900].zone_configuration_settings["SmartCool[4] Ku"] = 117
pi[1900].zone_configuration_settings["SmartCool[4] Gmax"] = 118
pi[1900].zone_configuration_settings["SmartCool[4] Kd"] = 119
pi[1900].zone_configuration_settings["SmartCool[4] Gmin"] = 120
pi[1900].zone_configuration_settings["SmartCool[5] Ku"] = 121
pi[1900].zone_configuration_settings["SmartCool[5] Gmax"] = 122
pi[1900].zone_configuration_settings["SmartCool[5] Kd"] = 123
pi[1900].zone_configuration_settings["SmartCool[5] Gmin"] = 124
pi[1900].zone_configuration_settings["SmartCool[6] Ku"] = 125
pi[1900].zone_configuration_settings["SmartCool[6] Gmax"] = 126
pi[1900].zone_configuration_settings["SmartCool[6] Kd"] = 127
pi[1900].zone_configuration_settings["SmartCool[6] Gmin"] = 128
pi[1900].zone_configuration_settings["SmartCool[7] Ku"] = 129
pi[1900].zone_configuration_settings["SmartCool[7] Gmax"] = 130
pi[1900].zone_configuration_settings["SmartCool[7] Kd"] = 131
pi[1900].zone_configuration_settings["SmartCool[7] Gmin"] = 132
pi[1900].zone_configuration_settings["SmartCool[8] Ku"] = 133
pi[1900].zone_configuration_settings["SmartCool[8] Gmax"] = 134
pi[1900].zone_configuration_settings["SmartCool[8] Kd"] = 135
pi[1900].zone_configuration_settings["SmartCool[8] Gmin"] = 136
pi[1900].zone_configuration_settings["SmartCool[9] Ku"] = 137
pi[1900].zone_configuration_settings["SmartCool[9] Gmax"] = 138
pi[1900].zone_configuration_settings["SmartCool[9] Kd"] = 139
pi[1900].zone_configuration_settings["SmartCool[9] Gmin"] = 140
pi[1900].zone_configuration_settings["Peristaltic - Output Mode"] = 141
pi[1900].zone_configuration_settings["Peristaltic - Output Delay"] = 142
pi[1900].zone_configuration_settings["Volume Measurement Units"] = 143
pi[1900].zone_configuration_settings["Nutrient Units"] = 144
pi[1900].zone_configuration_settings["Nutrient Units - TDS Conversion Factor"] = 145
pi[1900].zone_configuration_settings["Equipment Delay"] = 146
pi[1900].zone_configuration_settings["Sensor Display - 1"] = 147
pi[1900].zone_configuration_settings["Sensor Display - 2"] = 148
pi[1900].zone_configuration_settings["Sensor Display - 3"] = 149
pi[1900].zone_configuration_settings["Sensor Display - 4"] = 150
pi[1900].zone_configuration_settings["Schedule Pump Transition Time"] = 151
pi[1900].zone_configuration_settings["Peristaltic Pump - Advanced Mode"] = 152


/******************* End Pearl Values ******************************/

/********************* Controller 2100 Values ********************************/

p.PID_2100_BASE_SNAPSHOTS = 0 // 81
p.PID_2100_BASE_SNAP_CHANNELS = 100;
p.PID_2100_BASE_SNAP_ECPHS = 200;
p.PID_2100_BASE_SNAP_CHAN_DATA = 400;
p.PID_2100_BASE_CONF_CHANNELS = 500;
p.PID_2100_BASE_CONF_CHAN_DATA = 600;

p.PID_2100_BASE_AUX_ALARMS = 1024 // 32
p.PID_2100_BASE_AUX_PERSISTENT_VARIABLE = 1056 // 32
p.PID_2100_BASE_AUX_VARIABLE = 1088 // 64
p.PID_2100_BASE_EXPANSION_BOARDS = 1152 // 64
// p.PID_2100_BASE_EXPANSION_BOARDS = 1216
p.PID_2100_BASE_CONFIG_AUX_ALARMS = 1216 // 4 * 32
p.PID_2100_BASE_CONFIG_AUX_CONTROLS = 1344 // 17 * 64 no! 128!
//p.PID_2100_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 3520 // 4 * 32
p.PID_2100_BASE_CONFIG_AUX_VARIABLES = 3640 // 4 * 64
p.PID_2100_BASE_CONFIG_EXPANSION_BOARDS = 3896 // 5 * 64
p.PID_2100_BASE_CONFIG_CONTROLLER_SETTINGS = 4216 // 145 * 2, 170, now 157*2+4216=4530, next is PID_2100_BASE_CONFIG_ECPH at 4556


p.PID_2100_BASE_CONFIG_ECPH = 4556 // 11
p.PID_2100_BASE_CONFIG_ECPH_SENSORS = 4567 // 3 * 23 = 69
// zone wide
p.PID_2100_BASE_CONFIG_SETPOINTS = 4636 // 8 * 10
p.PID_2100_BASE_CONFIG_ZONE_SETTINGS = 4716 // 153 * 2!
p.PID_2100_BASE_CONFIG_ZONES = 5022 // 2
p.PID_2100_BASE_CONFIG_CONTROLLERS = 5024 // 4
// site wide
p.PID_2100_BASE_CONFIG_COMM_STAT = 5028 // 3
p.PID_2100_BASE_CONFIG_TIME = 5031 // 1 - current time at the server

p.PID_2100_ZONE_NAME = 5022;
p.PID_2100_ZONE_DESCRIPTION = 5023;
p.PID_2100_CONTROLLER_VERSION = 5025;
p.PID_2100_BASE_CONFIG_TIME = 5031 // 1 - current time at the server
p.PID_2100_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 5040 // 4 * 32
p.PID_2100_BASE_CONFIG_ANNEX_SENSORS = 5200 // 20*100

pb[2100]={}
pb[2100].snapshots = 0 // 1-81
pb[2100].snapshot_channels = 100 // 101-120
pb[2100].snapshot_ecphs = 200 // 201-235
pb[2100].snapshot_chan_Snapshot = 400 // 401-448
pb[2100].channels_configuration = 500 // 501-520
pb[2100].config_channels_configuration = 600 // 600-796
pb[2100].snapshot_aux_alarms = p.PID_2100_BASE_AUX_ALARMS; // 1024
pb[2100].snapshot_aux_persistent_variables = p.PID_2100_BASE_AUX_PERSISTENT_VARIABLE;//1056
pb[2100].snapshot_aux_variables = p.PID_2100_BASE_AUX_VARIABLE;//1088
pb[2100].snapshot_expansion_boards = p.PID_2100_BASE_EXPANSION_BOARDS;//1152
pb[2100].config_aux_alarms = p.PID_2100_BASE_CONFIG_AUX_ALARMS;//1216 4 * 32
pb[2100].config_aux_controls = p.PID_2100_BASE_CONFIG_AUX_CONTROLS;//1344 17 * 128
pb[2100].config_aux_variables = p.PID_2100_BASE_CONFIG_AUX_VARIABLES;//3640 4 * 64
pb[2100].config_expansion_boards = p.PID_2100_BASE_CONFIG_EXPANSION_BOARDS;//3896 5 * 64
pb[2100].controller_configuration_settings = p.PID_2100_BASE_CONFIG_CONTROLLER_SETTINGS;//4216
pb[2100].config_ecph = p.PID_2100_BASE_CONFIG_ECPH;//4506
pb[2100].config_ecph_sensors = p.PID_2100_BASE_CONFIG_ECPH_SENSORS;//4517
pb[2100].config_setpoints = p.PID_2100_BASE_CONFIG_SETPOINTS;//4586
pb[2100].zone_configuration_settings = p.PID_2100_BASE_CONFIG_ZONE_SETTINGS;//4666
pb[2100].config_zones = p.PID_2100_BASE_CONFIG_ZONES;//4972
pb[2100].config_controllers = p.PID_2100_BASE_CONFIG_CONTROLLERS;//4974
pb[2100].config_communication_status = p.PID_2100_BASE_CONFIG_COMM_STAT;//4978
pb[2100].config_aux_persistent_variables = p.PID_2100_BASE_CONFIG_AUX_PERSISTENT_VARIABLES;//3520 4 * 32
pb[2100].config_annex_sensors = p.PID_2100_BASE_CONFIG_ANNEX_SENSORS;//3520 4 * 32

pInd[2100]={}

pInd[2100].snapshots = [p.PID_2100_BASE_SNAPSHOTS, 0, 0, 0] // 1-81: base, channel type, index size, index count. channel type 0 = "unit"
pInd[2100].snapshot_channels = [p.PID_2100_BASE_SNAP_CHANNELS, 1, 0, 0] // 101-120 channel type 1 = "channel" - zone, unit, chan
pInd[2100].snapshot_ecphs = [p.PID_2100_BASE_SNAP_ECPHS, 2, 0, 0] // 201-235// ecph channel type - put in 192-199, on ecphIndex
pInd[2100].snapshot_chan_Snapshot = [p.PID_2100_BASE_SNAP_CHAN_DATA, 1, 0, 0] // 401-448 channeldata channel type
pInd[2100].channels_configuration = [p.PID_2100_BASE_CONF_CHANNELS, 1, 0, 0] // 501-520 channel type
pInd[2100].config_channels_configuration = [p.PID_2100_BASE_CONF_CHAN_DATA, 1, 0, 0] // 600-796 channeldata
pInd[2100].snapshot_aux_alarms = [p.PID_2100_BASE_AUX_ALARMS, 0, 1, 32]; // 1024 + 32
pInd[2100].snapshot_aux_persistent_variables = [p.PID_2100_BASE_AUX_PERSISTENT_VARIABLE, 0, 1, 32];//1056 + 32
pInd[2100].snapshot_aux_variables = [p.PID_2100_BASE_AUX_VARIABLE, 0, 1, 64];//1088 + 64
pInd[2100].snapshot_expansion_boards = [p.PID_2100_BASE_EXPANSION_BOARDS, 0, 1, 64];//1152 + 64
pInd[2100].config_aux_alarms = [p.PID_2100_BASE_CONFIG_AUX_ALARMS, 0, 4, 32];//1216 4 * 32
pInd[2100].config_aux_controls = [p.PID_2100_BASE_CONFIG_AUX_CONTROLS, 0, 17, 128];//1344 17 * 128
pInd[2100].config_aux_persistent_variables = [p.PID_2100_BASE_CONFIG_AUX_PERSISTENT_VARIABLES, 0, 4, 32];//3520 4 * 32
pInd[2100].config_aux_variables = [p.PID_2100_BASE_CONFIG_AUX_VARIABLES, 0, 4, 64];//3640 4 * 64
pInd[2100].config_expansion_boards = [p.PID_2100_BASE_CONFIG_EXPANSION_BOARDS, 0, 5, 64];//3896 5 * 64
pInd[2100].controller_configuration_settings = [p.PID_2100_BASE_CONFIG_CONTROLLER_SETTINGS, 7, 2, 170];//4216 170 * 2
// I really don't know what the 11, 8 refers to below!
pInd[2100].config_ecph = [p.PID_2100_BASE_CONFIG_ECPH, 2, 11, 8];//4506 11 * 8 - ERROR!!! this is just 11 values. the 8 times are in the channel
pInd[2100].config_ecph_sensors = [p.PID_2100_BASE_CONFIG_ECPH_SENSORS, 3, 23, 3];//4517 - channel type 3 - ecph double indexed
pInd[2100].config_setpoints = [p.PID_2100_BASE_CONFIG_SETPOINTS, 4, 10, 8];//4586 type 4 = zone wide
pInd[2100].zone_configuration_settings = [p.PID_2100_BASE_CONFIG_ZONE_SETTINGS, 6, 2, 153];//4666 zone *config* type
pInd[2100].config_zones = [p.PID_2100_BASE_CONFIG_ZONES, 4, 0, 0];//4972
pInd[2100].config_controllers = [p.PID_2100_BASE_CONFIG_CONTROLLERS, 0, 0, 0];//4974
pInd[2100].config_communication_status = [p.PID_2100_BASE_CONFIG_COMM_STAT, 5, 0, 0];//4978 type 5 - site wide
pInd[2100].config_annex_sensors = [p.PID_2100_BASE_CONFIG_ANNEX_SENSORS, 0, 20, 100];//5200 // 20*100

tableBases2["2100"]=[
  pInd[2100].snapshots,
  pInd[2100].snapshot_channels,
  pInd[2100].snapshot_ecphs,
  pInd[2100].snapshot_chan_Snapshot,
  pInd[2100].channels_configuration,
  pInd[2100].config_channels_configuration,
  pInd[2100].snapshot_aux_alarms,
  pInd[2100].snapshot_aux_persistent_variables,
  pInd[2100].snapshot_aux_variables,
  pInd[2100].snapshot_expansion_boards,
  pInd[2100].config_aux_alarms,
  pInd[2100].config_aux_controls,
  pInd[2100].config_aux_variables,
  pInd[2100].config_expansion_boards,
  pInd[2100].controller_configuration_settings,
  pInd[2100].config_ecph,
  pInd[2100].config_ecph_sensors,
  pInd[2100].config_setpoints,
  pInd[2100].zone_configuration_settings,
  pInd[2100].config_zones,
  pInd[2100].config_controllers,
  pInd[2100].config_communication_status,
  pInd[2100].config_aux_persistent_variables,
  pInd[2100].config_annex_sensors,
  [100000]]


// var snapshots = {} // these are in channels 240 + u
pi[2100]={}
pi[2100].snapshots = [];//0, 1-81
pi[2100].snapshot_channels = [];//0, 101-120
pi[2100].snapshot_ecphs = [];//0, 201-235
pi[2100].snapshot_chan_Snapshot = [];//0, 401-448
pi[2100].channels_configuration = [];//0 501-520
pi[2100].config_channels_configuration = [];// 0, 600-796
pi[2100].snapshot_aux_alarms = [];// 1024
pi[2100].snapshot_aux_persistent_variables = [];// 1056
pi[2100].snapshot_aux_variables = [];// 1088
pi[2100].snapshot_expansion_boards = [];// 1152
pi[2100].config_aux_alarms = [];// 1216
pi[2100].config_aux_controls = [];// 1344
pi[2100].config_aux_persistent_variables = [];// 3520
pi[2100].config_aux_variables = [];// 3640
pi[2100].config_expansion_boards = [];// 3896
pi[2100].controller_configuration_settings = [];//4216
pi[2100].config_ecph = [];//4506
pi[2100].config_ecph_sensors = [];//4517
pi[2100].config_setpoints = [];//4586
pi[2100].zone_configuration_settings = [];//4666
pi[2100].config_zones = [];//4972
pi[2100].config_controllers = [];//4974
pi[2100].config_communication_status = [];//4978
pi[2100].config_annex_sensors = [];//4978

offsetTables["2100"]=[
  pi[2100].snapshots, pi[2100].snapshot_channels, pi[2100].snapshot_ecphs,
  pi[2100].snapshot_chan_Snapshot, pi[2100].channels_configuration,
  pi[2100].config_channels_configuration, pi[2100].snapshot_aux_alarms,
  pi[2100].snapshot_aux_persistent_variables, pi[2100].snapshot_aux_variables,
  pi[2100].snapshot_expansion_boards, pi[2100].config_aux_alarms,
  pi[2100].config_aux_controls,
  pi[2100].config_aux_variables, pi[2100].config_expansion_boards,
  pi[2100].controller_configuration_settings, pi[2100].config_ecph,
  pi[2100].config_ecph_sensors, pi[2100].config_setpoints,
  pi[2100].zone_configuration_settings, pi[2100].config_zones, pi[2100].config_controllers,
  pi[2100].config_communication_status, pi[2100].config_aux_persistent_variables, 
  pi[2100].config_annex_sensors]

pi[2100].snapshots["id"] =	1
pi[2100].snapshots["zoneIndex"] =	2
pi[2100].snapshots["unitIndex"] =	3
pi[2100].snapshots["unix_timestamp(created)"] =	4
pi[2100].snapshots["unix_timestamp(unitTime)"] =	5
pi[2100].snapshots["temperatureStage"] =	6
pi[2100].snapshots["humidityStage"] =	7
pi[2100].snapshots["heatSetpoint"] =	8
pi[2100].snapshots["coolSetpoint"] =	9
pi[2100].snapshots["humidifySetpoint"] =	10
pi[2100].snapshots["dehumidifySetpoint"] =	11
pi[2100].snapshots["accumulator0"] =	12
pi[2100].snapshots["accumulator1"] =	13
pi[2100].snapshots["d2avgTotalAverage"] =	14
pi[2100].snapshots["d2avgDayAverage"] =	15
pi[2100].snapshots["d2avgNightAverage"] =	16
pi[2100].snapshots["d2avgFailDays"] =	17
pi[2100].snapshots["lowAlarm"] =	18
pi[2100].snapshots["highAlarm"] =	19
pi[2100].snapshots["lowInTemperatureAlarm"] =	20
pi[2100].snapshots["highInTemperatureAlarm"] =	21
pi[2100].snapshots["inTemperatureSensorAlarm"] =	22
pi[2100].snapshots["inTemperature"] =	23
pi[2100].snapshots["outTemperature"] =	24
pi[2100].snapshots["localTemperature"] =	25
pi[2100].snapshots["backupTemperature"] =	26
pi[2100].snapshots["inHumidity"] =	27
pi[2100].snapshots["outHumidity"] =	28
pi[2100].snapshots["localHumidity"] =	29
pi[2100].snapshots["differentialPressure"] =	30
pi[2100].snapshots["co2"] =	31
pi[2100].snapshots["inLight"] =	32
pi[2100].snapshots["outLight"] =	33
pi[2100].snapshots["windSpeed"] =	34
pi[2100].snapshots["windDirection"] =	35
pi[2100].snapshots["rain"] =	36
pi[2100].snapshots["snow"] =	37
pi[2100].snapshots["analogTemperature1"] =	38
pi[2100].snapshots["analogTemperature2"] =	39
pi[2100].snapshots["analogTemperature3"] =	40
pi[2100].snapshots["analogTemperature4"] =	41
pi[2100].snapshots["analogTemperature5"] =	42
pi[2100].snapshots["ventPosition1"] =	43
pi[2100].snapshots["ventPosition2"] =	44
pi[2100].snapshots["ventPosition3"] =	45
pi[2100].snapshots["ventPosition4"] =	46
pi[2100].snapshots["ventPosition5"] =	47
pi[2100].snapshots["soilMoisture1"] =	48
pi[2100].snapshots["soilMoisture2"] =	49
pi[2100].snapshots["soilMoisture3"] =	50
pi[2100].snapshots["soilMoisture4"] =	51
pi[2100].snapshots["soilMoisture5"] =	52
pi[2100].snapshots["ecph1"] =	53
pi[2100].snapshots["ecph2"] =	54
pi[2100].snapshots["ecph3"] =	55
pi[2100].snapshots["ecph4"] =	56
pi[2100].snapshots["ecph5"] =	57
pi[2100].snapshots["generic1"] =	58
pi[2100].snapshots["generic2"] =	59
pi[2100].snapshots["heatDemand"] =	60
pi[2100].snapshots["coolDemand"] =	61
pi[2100].snapshots["coolDemandPassive"] =	62
pi[2100].snapshots["auxHeatSetpointStatus"] =	63
pi[2100].snapshots["auxHeatSetpointValue"] =	64
pi[2100].snapshots["auxCoolSetpointStatus"] =	65
pi[2100].snapshots["auxCoolSetpointValue"] =	66
pi[2100].snapshots["auxHumidifySetpointStatus"] =	67
pi[2100].snapshots["auxHumidifySetpointValue"] =	68
pi[2100].snapshots["auxDehumidifySetpointStatus"] =	69
pi[2100].snapshots["auxDehumidifySetpointValue"] =	70
pi[2100].snapshots["auxLowAlarmStatus"] =	71
pi[2100].snapshots["auxLowAlarmValue"] =	72
pi[2100].snapshots["auxHighAlarmStatus"] =	73
pi[2100].snapshots["auxHighAlarmValue"] =	74
pi[2100].snapshots["auxActiveCoolStatus"] =	75
pi[2100].snapshots["auxActiveCoolValue"] =	76
pi[2100].snapshots["auxPassiveCoolStatus"] =	77
pi[2100].snapshots["auxPassiveCoolValue"] =	78
pi[2100].snapshots["accvpd"] =	79
pi[2100].snapshots["outTemperatureSecondary"] =	80
pi[2100].snapshots["barometricPressure"] =   81
pi[2100].snapshots["vpd"] =	82

pi[2100].snapshot_channels["snapshotId"] = 1
pi[2100].snapshot_channels["channelIndex"] = 2
pi[2100].snapshot_channels["channelData"] = 3
pi[2100].snapshot_channels["position"] = 4
pi[2100].snapshot_channels["relay"] = 5
pi[2100].snapshot_channels["channelOverride"] = 6
pi[2100].snapshot_channels["analogOutput"] = 7
pi[2100].snapshot_channels["co2Setpoint"] = 8
pi[2100].snapshot_channels["microzoneSetpoint"] = 9
pi[2100].snapshot_channels["proportionalZoneLowSetpoint"] = 10
pi[2100].snapshot_channels["proportionalZoneHighSetpoint"] = 11
pi[2100].snapshot_channels["pumpPeristalticSetpoint"] = 12
pi[2100].snapshot_channels["pumpPeristalticTankLevel"] = 13
pi[2100].snapshot_channels["pumpPeristalticInjectedMilliliters"] = 14
pi[2100].snapshot_channels["pumpPeristalticInjectedSeconds"] = 15
pi[2100].snapshot_channels["lightDailyLightIntegral"] = 16
pi[2100].snapshot_channels["auxOutputStatus"] = 17
pi[2100].snapshot_channels["auxOutputValue"] = 18
pi[2100].snapshot_channels["auxEquipmentStatus"] = 19
pi[2100].snapshot_channels["auxEquipmentValue"] = 20

//var snapshot_ecphs = {} // these are stored in the ecph
// channels, 192-199
pi[2100].snapshot_ecphs["snapshotId"] = 1
pi[2100].snapshot_ecphs["ecphIndex"] = 2
pi[2100].snapshot_ecphs["ec1"] = 3
pi[2100].snapshot_ecphs["ec2"] = 4
pi[2100].snapshot_ecphs["ec3"] = 5
pi[2100].snapshot_ecphs["ec1SensorAlarm"] = 6
pi[2100].snapshot_ecphs["ec1ServiceAlarm"] = 7
pi[2100].snapshot_ecphs["ec1CalibrationAlarm"] = 8
pi[2100].snapshot_ecphs["ec2SensorAlarm"] = 9
pi[2100].snapshot_ecphs["ec2ServiceAlarm"] = 10
pi[2100].snapshot_ecphs["ec2CalibrationAlarm"] = 11
pi[2100].snapshot_ecphs["ec3SensorAlarm"] = 12
pi[2100].snapshot_ecphs["ec3ServiceAlarm"] = 13
pi[2100].snapshot_ecphs["ec3CalibrationAlarm"] = 14
pi[2100].snapshot_ecphs["ecLowAlarm"] = 15
pi[2100].snapshot_ecphs["ecHighAlarm"] = 16
pi[2100].snapshot_ecphs["ecDeviationAlarm"] = 17
pi[2100].snapshot_ecphs["ph1"] = 18
pi[2100].snapshot_ecphs["ph2"] = 19
pi[2100].snapshot_ecphs["ph3"] = 20
pi[2100].snapshot_ecphs["ph1SensorAlarm"] = 21
pi[2100].snapshot_ecphs["ph1ServiceAlarm"] = 22
pi[2100].snapshot_ecphs["ph1CalibrationAlarm"] = 23
pi[2100].snapshot_ecphs["ph2SensorAlarm"] = 24
pi[2100].snapshot_ecphs["ph2ServiceAlarm"] = 25
pi[2100].snapshot_ecphs["ph2CalibrationAlarm"] = 26
pi[2100].snapshot_ecphs["ph3SensorAlarm"] = 27
pi[2100].snapshot_ecphs["ph3ServiceAlarm"] = 28
pi[2100].snapshot_ecphs["ph3CalibrationAlarm"] = 29
pi[2100].snapshot_ecphs["phLowAlarm"] = 30
pi[2100].snapshot_ecphs["phHighAlarm"] = 31
pi[2100].snapshot_ecphs["phDeviationAlarm"] = 32
pi[2100].snapshot_ecphs["temperature1"] = 33
pi[2100].snapshot_ecphs["temperature2"] = 34
pi[2100].snapshot_ecphs["temperature3"] = 35

//var snapshot_chan_Snapshot = {} // stored in the Channel ID
// these are the values out of ChannelData
pi[2100].snapshot_chan_Snapshot["accumulatedLight"] = 1
pi[2100].snapshot_chan_Snapshot["accumulator"] = 2
pi[2100].snapshot_chan_Snapshot["accvpd"] = 3
pi[2100].snapshot_chan_Snapshot["activatedTimes"] = 4
pi[2100].snapshot_chan_Snapshot["activeCount"] = 5
pi[2100].snapshot_chan_Snapshot["auxControl"] = 6
pi[2100].snapshot_chan_Snapshot["coolDemand"] = 7
pi[2100].snapshot_chan_Snapshot["coolDemandPassive"] = 8
pi[2100].snapshot_chan_Snapshot["currentSetpoint"] = 9
pi[2100].snapshot_chan_Snapshot["cycleOffTimeRemains"] = 10
pi[2100].snapshot_chan_Snapshot["cycleOnTimeRemains"] = 11
pi[2100].snapshot_chan_Snapshot["cycleState"] = 12
pi[2100].snapshot_chan_Snapshot["dailyLightIntegral"] = 13
pi[2100].snapshot_chan_Snapshot["driveToAverage"] = 14
pi[2100].snapshot_chan_Snapshot["ecphs"] = 15
pi[2100].snapshot_chan_Snapshot["equipment"] = 16
pi[2100].snapshot_chan_Snapshot["expansionBoardStatus"] = 17
pi[2100].snapshot_chan_Snapshot["heatDemand"] = 18
pi[2100].snapshot_chan_Snapshot["highAlarm"] = 19
pi[2100].snapshot_chan_Snapshot["highInTemperatureAlarm"] = 20
pi[2100].snapshot_chan_Snapshot["humidityStage"] = 21
pi[2100].snapshot_chan_Snapshot["injectedVolumeMLiters"] = 22
pi[2100].snapshot_chan_Snapshot["injectedVolumeSeconds"] = 23
pi[2100].snapshot_chan_Snapshot["inQueue"] = 24
pi[2100].snapshot_chan_Snapshot["inTemperatureSensorAlarm"] = 25
pi[2100].snapshot_chan_Snapshot["inWindow"] = 26
pi[2100].snapshot_chan_Snapshot["irrigationWeek"] = 27
pi[2100].snapshot_chan_Snapshot["isCycling"] = 28
pi[2100].snapshot_chan_Snapshot["isPulseOn"] = 29
pi[2100].snapshot_chan_Snapshot["lowAlarm"] = 30
pi[2100].snapshot_chan_Snapshot["lowInTemperatureAlarm"] = 31
pi[2100].snapshot_chan_Snapshot["offTime"] = 32
pi[2100].snapshot_chan_Snapshot["originallyOn"] = 33
pi[2100].snapshot_chan_Snapshot["output"] = 34
pi[2100].snapshot_chan_Snapshot["pdTimer"] = 35
pi[2100].snapshot_chan_Snapshot["peristalticState"] = 36
pi[2100].snapshot_chan_Snapshot["sensors"] = 37
pi[2100].snapshot_chan_Snapshot["setpoint"] = 38
pi[2100].snapshot_chan_Snapshot["setpointHigh"] = 39
pi[2100].snapshot_chan_Snapshot["setpointLow"] = 40
pi[2100].snapshot_chan_Snapshot["siteChanged"] = 41
pi[2100].snapshot_chan_Snapshot["tankLevelLiters"] = 42
pi[2100].snapshot_chan_Snapshot["tankSizeLiters"] = 43
pi[2100].snapshot_chan_Snapshot["temperatureStage"] = 44
pi[2100].snapshot_chan_Snapshot["timestamp"] = 45
pi[2100].snapshot_chan_Snapshot["unitTime"] = 46
pi[2100].snapshot_chan_Snapshot["pdTimerState"] = 47
pi[2100].snapshot_chan_Snapshot["week"] = 248

//var channels_configuration = {}
pi[2100].channels_configuration["zoneIndex"] = 1
pi[2100].channels_configuration["unitIndex"] = 2
pi[2100].channels_configuration["channelIndex"] = 3
pi[2100].channels_configuration["unix_timestamp(modified)"] = 4
pi[2100].channels_configuration["used"] = 5
pi[2100].channels_configuration["isAnalog"] = 6
pi[2100].channels_configuration["channelName"] = 7
pi[2100].channels_configuration["channelType"] = 8
pi[2100].channels_configuration["channelData"] = 9
pi[2100].channels_configuration["timedEnabled"] = 10
pi[2100].channels_configuration["timedInterval"] = 11
pi[2100].channels_configuration["timedStartTime"] = 12
pi[2100].channels_configuration["timedEndTime"] = 13
pi[2100].channels_configuration["timedOutput"] = 14
pi[2100].channels_configuration["stageDehumidfy1"] = 15
pi[2100].channels_configuration["stageDehumidfy2"] = 16
pi[2100].channels_configuration["stageColdDehumidfy"] = 17
pi[2100].channels_configuration["stageHumidfy"] = 18
pi[2100].channels_configuration["expansionFailSafeOutput"] = 19
pi[2100].channels_configuration["userComment"] = 20

//var config_channels_configuration = {}
pi[2100].config_channels_configuration["activeCool_cool1"] = 0
pi[2100].config_channels_configuration["activeCool_cool2"] = 1
pi[2100].config_channels_configuration["activeCool_cool3"] = 2
pi[2100].config_channels_configuration["activeCool_cool4"] = 3
pi[2100].config_channels_configuration["activeCool_cool5"] = 4
pi[2100].config_channels_configuration["activeCool_cool6"] = 5
pi[2100].config_channels_configuration["activeCool_heat1"] = 6
pi[2100].config_channels_configuration["activeCool_heat2"] = 7
pi[2100].config_channels_configuration["activeCool_heat3"] = 8
pi[2100].config_channels_configuration["activeCool_heat4"] = 9
pi[2100].config_channels_configuration["activeCool_heat5"] = 10
pi[2100].config_channels_configuration["activeCool_heat6"] = 11
pi[2100].config_channels_configuration["activeCool_normal"] = 12
pi[2100].config_channels_configuration["activeTrigger"] = 13
pi[2100].config_channels_configuration["advanced_close_perc"] = 14
pi[2100].config_channels_configuration["advanced_shock_threshold"] = 15
pi[2100].config_channels_configuration["advanced_stage"] = 16
pi[2100].config_channels_configuration["advanced_time"] = 17
pi[2100].config_channels_configuration["air_cool_setpoint"] = 18
pi[2100].config_channels_configuration["air_heat_setpoint"] = 19
pi[2100].config_channels_configuration["air_temp_sensor"] = 20
pi[2100].config_channels_configuration["analog_max"] = 21
pi[2100].config_channels_configuration["analog_max"] = 22
pi[2100].config_channels_configuration["analog_min"] = 23
pi[2100].config_channels_configuration["cool1"] = 24
pi[2100].config_channels_configuration["cool2"] = 25
pi[2100].config_channels_configuration["cool3"] = 26
pi[2100].config_channels_configuration["cool4"] = 27
pi[2100].config_channels_configuration["cool5"] = 28
pi[2100].config_channels_configuration["cool6"] = 29
pi[2100].config_channels_configuration["cooling_device"] = 30
pi[2100].config_channels_configuration["cycleOffPeriod"] = 31
pi[2100].config_channels_configuration["cycleOnPeriod"] = 32
pi[2100].config_channels_configuration["D"] = 33
pi[2100].config_channels_configuration["daytime_astroadjust"] = 34
pi[2100].config_channels_configuration["daytime_enabled"] = 35
pi[2100].config_channels_configuration["daytime_setpoint"] = 36
pi[2100].config_channels_configuration["daytime_stage"] = 37
pi[2100].config_channels_configuration["daytime_start"] = 38
pi[2100].config_channels_configuration["deadband"] = 39
pi[2100].config_channels_configuration["dir"] = 40
pi[2100].config_channels_configuration["disable_inside_temp_pid"] = 41
pi[2100].config_channels_configuration["dliEndTime"] = 42
pi[2100].config_channels_configuration["dliEnergyOutput"] = 43
pi[2100].config_channels_configuration["dliOnTime"] = 44
pi[2100].config_channels_configuration["dliSensor"] = 45
pi[2100].config_channels_configuration["dliStartTime"] = 46
pi[2100].config_channels_configuration["dliThreshold"] = 47
pi[2100].config_channels_configuration["enableActiveCoolStageOverride"] = 48
pi[2100].config_channels_configuration["enableSmartInject"] = 49
pi[2100].config_channels_configuration["end"] = 50
pi[2100].config_channels_configuration["end_astroadjust"] = 51
pi[2100].config_channels_configuration["end_time"] = 52
pi[2100].config_channels_configuration["energy_end"] = 53
pi[2100].config_channels_configuration["energy_end_astroadjust"] = 54
pi[2100].config_channels_configuration["energy_start"] = 55
pi[2100].config_channels_configuration["energy_start_astroadjust"] = 56
pi[2100].config_channels_configuration["feedingMode"] = 57
pi[2100].config_channels_configuration["fine_adjust_range"] = 58
pi[2100].config_channels_configuration["followChannelIndex"] = 59
pi[2100].config_channels_configuration["followRatio"] = 60
pi[2100].config_channels_configuration["function"] = 61
pi[2100].config_channels_configuration["function_float_max"] = 62
pi[2100].config_channels_configuration["function_float_min"] = 63
pi[2100].config_channels_configuration["function_int_max"] = 64
pi[2100].config_channels_configuration["function_int_min"] = 65
pi[2100].config_channels_configuration["gain"] = 66
pi[2100].config_channels_configuration["heat_on_delay"] = 67
pi[2100].config_channels_configuration["heat1"] = 68
pi[2100].config_channels_configuration["heat2"] = 69
pi[2100].config_channels_configuration["heat3"] = 70
pi[2100].config_channels_configuration["heat4"] = 71
pi[2100].config_channels_configuration["heat5"] = 72
pi[2100].config_channels_configuration["heat6"] = 73
pi[2100].config_channels_configuration["highlight_cover_perc"] = 74
pi[2100].config_channels_configuration["highlight_cover_trigger_light"] = 75
pi[2100].config_channels_configuration["highlight_cover_trigger_temp"] = 76
pi[2100].config_channels_configuration["hold_delay"] = 77
pi[2100].config_channels_configuration["I"] = 78
pi[2100].config_channels_configuration["increased_co2_level"] = 79
pi[2100].config_channels_configuration["injectingBatchTimeSeconds"] = 80
pi[2100].config_channels_configuration["injectingBatchVolumeMilliLiters"] = 81
pi[2100].config_channels_configuration["injectingLimitHours"] = 82
pi[2100].config_channels_configuration["injectingLimitMilliLiters"] = 83
pi[2100].config_channels_configuration["injectingLimitSeconds"] = 84
pi[2100].config_channels_configuration["injectingTimeSeconds"] = 85
pi[2100].config_channels_configuration["injectingVolumeMilliLiters"] = 86
pi[2100].config_channels_configuration["injectionRate"] = 87
pi[2100].config_channels_configuration["inside_temp_d"] = 88
pi[2100].config_channels_configuration["inside_temp_i"] = 89
pi[2100].config_channels_configuration["inside_temp_p"] = 90
pi[2100].config_channels_configuration["irrigation_mode"] = 91
pi[2100].config_channels_configuration["isCycling"] = 92
pi[2100].config_channels_configuration["keep_open_threshold_light"] = 93
pi[2100].config_channels_configuration["keep_open_threshold_temp"] = 94
// pi[2100].config_channels_configuration["light_drive_to"] = 95
pi[2100].config_channels_configuration["light_level"] = 96
pi[2100].config_channels_configuration["light_mode"] = 97
pi[2100].config_channels_configuration["light_sensor_enabled"] = 98
// pi[2100].config_channels_configuration["light_threshold"] = 99
pi[2100].config_channels_configuration["low_light_threshold_duration"] = 100
pi[2100].config_channels_configuration["low_light_threshold_light"] = 101
pi[2100].config_channels_configuration["mixingTimeSeconds"] = 102
pi[2100].config_channels_configuration["mode"] = 103
pi[2100].config_channels_configuration["nighttime_astroadjust"] = 104
pi[2100].config_channels_configuration["nighttime_enabled"] = 105
pi[2100].config_channels_configuration["nighttime_setpoint"] = 106
pi[2100].config_channels_configuration["nighttime_stage"] = 107
pi[2100].config_channels_configuration["nighttime_start"] = 108
pi[2100].config_channels_configuration["normal"] = 109
pi[2100].config_channels_configuration["normal_co2_level"] = 110
pi[2100].config_channels_configuration["off_delay"] = 111
pi[2100].config_channels_configuration["off_duration"] = 112
pi[2100].config_channels_configuration["off_threshold"] = 113
pi[2100].config_channels_configuration["offDelay"] = 114
pi[2100].config_channels_configuration["offset"] = 115
pi[2100].config_channels_configuration["on_duration"] = 116
pi[2100].config_channels_configuration["on_threshold"] = 117
pi[2100].config_channels_configuration["output_type"] = 118
pi[2100].config_channels_configuration["override_action_target"] = 119
pi[2100].config_channels_configuration["override_dir"] = 120
pi[2100].config_channels_configuration["override_limit"] = 121
pi[2100].config_channels_configuration["override_sensor"] = 122
pi[2100].config_channels_configuration["override_trigger"] = 123
pi[2100].config_channels_configuration["P"] = 124
pi[2100].config_channels_configuration["passive_cooling"] = 125
pi[2100].config_channels_configuration["probe_id"] = 126
pi[2100].config_channels_configuration["probeSensorIndex"] = 127
pi[2100].config_channels_configuration["probeType"] = 128
pi[2100].config_channels_configuration["pulse_ff_time"] = 129
pi[2100].config_channels_configuration["pulse_on_time"] = 130
pi[2100].config_channels_configuration["pump_mzone"] = 131
pi[2100].config_channels_configuration["pump_onoff"] = 348
pi[2100].config_channels_configuration["pump_id"] = 132
pi[2100].config_channels_configuration["pump_type"] = 133
pi[2100].config_channels_configuration["pumpMeasurementUnits"] = 134
pi[2100].config_channels_configuration["resetInjectionTracker"] = 135
pi[2100].config_channels_configuration["sensor_mzone"] = 136
// pi[2100].config_channels_configuration["sensorHoldTime"] = 137
pi[2100].config_channels_configuration["setpoint"] = 138
pi[2100].config_channels_configuration["setting_1_threshold_light"] = 139
pi[2100].config_channels_configuration["setting_1_threshold_temp"] = 140
pi[2100].config_channels_configuration["setting_2_threshold_light"] = 141
pi[2100].config_channels_configuration["setting_2_threshold_temp"] = 142
pi[2100].config_channels_configuration["shade_end"] = 143
pi[2100].config_channels_configuration["shade_end_astroadjust"] = 144
pi[2100].config_channels_configuration["shade_start"] = 145
pi[2100].config_channels_configuration["shade_start_astroadjust"] = 146
pi[2100].config_channels_configuration["shock_protect_enabled"] = 147
pi[2100].config_channels_configuration["shock_protect_open_valve_perc"] = 148
pi[2100].config_channels_configuration["shock_protect_threshold"] = 149
pi[2100].config_channels_configuration["shock_protect_time_closed"] = 150
pi[2100].config_channels_configuration["shock_protect_water_sensor"] = 151
pi[2100].config_channels_configuration["soil_moisture_input"] = 152
pi[2100].config_channels_configuration["soil_moisture_threshold"] = 153
pi[2100].config_channels_configuration["stage"] = 154
pi[2100].config_channels_configuration["start"] = 155
pi[2100].config_channels_configuration["start_astroadjust"] = 156
pi[2100].config_channels_configuration["start_time"] = 157
pi[2100].config_channels_configuration["start_time_1"] = 158
pi[2100].config_channels_configuration["start_time_2"] = 159
pi[2100].config_channels_configuration["start_time_3"] = 160
pi[2100].config_channels_configuration["start_time_4"] = 161
pi[2100].config_channels_configuration["start_time_5"] = 162
pi[2100].config_channels_configuration["start_time_6"] = 163
pi[2100].config_channels_configuration["supply_pump"] = 164
pi[2100].config_channels_configuration["tankSensor"] = 165// obsolete! maybe used for irr tankSensor
pi[2100].config_channels_configuration["tankSize"] = 166
// pi[2100].config_channels_configuration["temp_drive_to"] = 167
// pi[2100].config_channels_configuration["temp_sensor"] = 168
// pi[2100].config_channels_configuration["temp_threshold"] = 169
pi[2100].config_channels_configuration["threshold"] = 170
pi[2100].config_channels_configuration["time"] = 171
pi[2100].config_channels_configuration["triggerDelay"] = 172
pi[2100].config_channels_configuration["valve_open_time"] = 173
pi[2100].config_channels_configuration["vpdacc_threshold"] = 174
pi[2100].config_channels_configuration["water_temp_d"] = 175
pi[2100].config_channels_configuration["water_temp_i"] = 176
pi[2100].config_channels_configuration["water_temp_max"] = 177
pi[2100].config_channels_configuration["water_temp_min"] = 178
pi[2100].config_channels_configuration["water_temp_p"] = 179
pi[2100].config_channels_configuration["water_temp_sensor"] = 180
pi[2100].config_channels_configuration["week_a_fri"] = 181
pi[2100].config_channels_configuration["week_a_mon"] = 182
pi[2100].config_channels_configuration["week_a_sat"] = 183
pi[2100].config_channels_configuration["week_a_sun"] = 184
pi[2100].config_channels_configuration["week_a_thu"] = 185
pi[2100].config_channels_configuration["week_a_tue"] = 186
pi[2100].config_channels_configuration["week_a_wed"] = 187
pi[2100].config_channels_configuration["week_b_fri"] = 188
pi[2100].config_channels_configuration["week_b_mon"] = 189
pi[2100].config_channels_configuration["week_b_sat"] = 190
pi[2100].config_channels_configuration["week_b_sun"] = 191
pi[2100].config_channels_configuration["week_b_thu"] = 192
pi[2100].config_channels_configuration["week_b_tue"] = 193
pi[2100].config_channels_configuration["week_b_wed"] = 194
//added
pi[2100].config_channels_configuration["triggers"] = 195
// pi[2100].config_channels_configuration["smartInject"] = 196
// pi[2100].config_channels_configuration["vent_mode"] = 197// should be vent_type!

pi[2100].config_channels_configuration["P"] = 198
pi[2100].config_channels_configuration["I"] = 199
pi[2100].config_channels_configuration["D"] = 200
pi[2100].config_channels_configuration["acDeadband"] =	201
pi[2100].config_channels_configuration["acDoDelay"] =	202
pi[2100].config_channels_configuration["acPcnt_1"] =	203
pi[2100].config_channels_configuration["acPcnt_2"] =	204
pi[2100].config_channels_configuration["acPcnt_3"] =	205
pi[2100].config_channels_configuration["acPcnt_4"] =	206
pi[2100].config_channels_configuration["acTemp_1"] =	207
pi[2100].config_channels_configuration["acTemp_2"] =	208
pi[2100].config_channels_configuration["acTemp_3"] =	209
pi[2100].config_channels_configuration["acTemp_4"] =	210
pi[2100].config_channels_configuration["acUpDelay"] =	211
pi[2100].config_channels_configuration["autoCalibration"] =	212
pi[2100].config_channels_configuration["begin_closing_leeward_trigger_end"] =	213
pi[2100].config_channels_configuration["begin_closing_leeward_trigger_start"] =	214
pi[2100].config_channels_configuration["begin_closing_windward_trigger_end"] =	215
pi[2100].config_channels_configuration["begin_closing_windward_trigger_start"] =	216
pi[2100].config_channels_configuration["closing_delay"] =	217
pi[2100].config_channels_configuration["cool_1"] =	218// duplicates NO! these are used for the vents
pi[2100].config_channels_configuration["cool_2"] =	219
pi[2100].config_channels_configuration["cool_3"] =	220
pi[2100].config_channels_configuration["cool_4"] =	221
pi[2100].config_channels_configuration["cool_5"] =	222
pi[2100].config_channels_configuration["cool_6"] =	223
pi[2100].config_channels_configuration["cp2Delay"] =	224
pi[2100].config_channels_configuration["cp2Pos"] =	225
pi[2100].config_channels_configuration["cp2Wind"] =	226
pi[2100].config_channels_configuration["cp3Delay"] =	227
pi[2100].config_channels_configuration["cp3Pos"] =	228
pi[2100].config_channels_configuration["cp3Wind"] =	229
pi[2100].config_channels_configuration["cpDelay"] =	230
pi[2100].config_channels_configuration["cpPos"] =	231
pi[2100].config_channels_configuration["cpWind"] =	232
pi[2100].config_channels_configuration["direction"] =	233
pi[2100].config_channels_configuration["fireDelay"] =	234
pi[2100].config_channels_configuration["fireIn"] =	235
pi[2100].config_channels_configuration["firePos"] =	236
pi[2100].config_channels_configuration["hailDelay"] =	237
pi[2100].config_channels_configuration["hailPos"] =	238
pi[2100].config_channels_configuration["hailThresh"] =	239
pi[2100].config_channels_configuration["hgDeadband"] =	240
pi[2100].config_channels_configuration["hgDoDelay"] =	241
pi[2100].config_channels_configuration["hgPcnt_1"] =	242
pi[2100].config_channels_configuration["hgPcnt_2"] =	243
pi[2100].config_channels_configuration["hgPcnt_3"] =	244
pi[2100].config_channels_configuration["hgPcnt_4"] =	245
pi[2100].config_channels_configuration["hgTemp_1"] =	246
pi[2100].config_channels_configuration["hgTemp_2"] =	247
pi[2100].config_channels_configuration["hgTemp_3"] =	248
pi[2100].config_channels_configuration["hgTemp_4"] =	249
pi[2100].config_channels_configuration["hgUpDelay"] =	250
pi[2100].config_channels_configuration["max_open"] =	251
pi[2100].config_channels_configuration["max_open_rain"] =	252
pi[2100].config_channels_configuration["open_var"] =	253
pi[2100].config_channels_configuration["rhgDeadband"] =	254
pi[2100].config_channels_configuration["rhgDoDelay"] =	255
pi[2100].config_channels_configuration["rhgTemp_1"] =	256
pi[2100].config_channels_configuration["rhgTemp_2"] =	257
pi[2100].config_channels_configuration["rhgTemp_3"] =	258
pi[2100].config_channels_configuration["rhgTemp_4"] =	259
pi[2100].config_channels_configuration["rhgUpDelay"] =	260
pi[2100].config_channels_configuration["rtDeadband"] =	261
pi[2100].config_channels_configuration["rtDoDelay"] =	262
pi[2100].config_channels_configuration["rtHum_1"] =	263
pi[2100].config_channels_configuration["rtHum_2"] =	264
pi[2100].config_channels_configuration["rtHum_3"] =	265
pi[2100].config_channels_configuration["rtHum_4"] =	266
pi[2100].config_channels_configuration["rtPcnt_1"] =	267
pi[2100].config_channels_configuration["rtPcnt_2"] =	268
pi[2100].config_channels_configuration["rtPcnt_3"] =	269
pi[2100].config_channels_configuration["rtPcnt_4"] =	270
pi[2100].config_channels_configuration["rtUpDelay"] =	271
pi[2100].config_channels_configuration["spDelay"] =	272
pi[2100].config_channels_configuration["spPos"] =	273
pi[2100].config_channels_configuration["spWind"] =	274
pi[2100].config_channels_configuration["temp_below_trigger"] =	275
pi[2100].config_channels_configuration["temp_var"] =	276
pi[2100].config_channels_configuration["rhgPcnt_1"] =	277
pi[2100].config_channels_configuration["rhgPcnt_2"] =	278
pi[2100].config_channels_configuration["rhgPcnt_3"] =	279
pi[2100].config_channels_configuration["rhgPcnt_4"] =	280
pi[2100].config_channels_configuration["vent_type"] =	281
pi[2100].config_channels_configuration["vps_alarm_duration"] =	282
pi[2100].config_channels_configuration["vps_alarm_limit"] =	283
pi[2100].config_channels_configuration["vps_error_exception_limit"] =	284
pi[2100].config_channels_configuration["vps_sensor"] =	285

pi[2100].config_channels_configuration["CD_start_astroadjust_co2"] = 286
pi[2100].config_channels_configuration["CD_start_astroadjust_hid"] = 287
pi[2100].config_channels_configuration["CD_end_astroadjust_co2"] = 288
pi[2100].config_channels_configuration["CD_end_astroadjust_hid"] = 289
pi[2100].config_channels_configuration["CD_start_hid"] = 290
pi[2100].config_channels_configuration["CD_end_hid"] = 291
pi[2100].config_channels_configuration["CD_tankSensor_fval"] = 292
pi[2100].config_channels_configuration["CD_time_generic"] = 293
pi[2100].config_channels_configuration["CD_acPcnt_1_cvent"] = 294
pi[2100].config_channels_configuration["CD_acPcnt_2_cvent"] = 295
pi[2100].config_channels_configuration["CD_acPcnt_3_cvent"] = 296
pi[2100].config_channels_configuration["CD_acPcnt_4_cvent"] = 297
pi[2100].config_channels_configuration["CD_acTemp_1_cvent"] = 298
pi[2100].config_channels_configuration["CD_acTemp_2_cvent"] = 299
pi[2100].config_channels_configuration["CD_acTemp_3_cvent"] = 300
pi[2100].config_channels_configuration["CD_acTemp_4_cvent"] = 301
pi[2100].config_channels_configuration["CD_hgPcnt_1_cvent"] = 302
pi[2100].config_channels_configuration["CD_hgPcnt_2_cvent"] = 303
pi[2100].config_channels_configuration["CD_hgPcnt_3_cvent"] = 304
pi[2100].config_channels_configuration["CD_hgPcnt_4_cvent"] = 305
pi[2100].config_channels_configuration["CD_hgTemp_1_cvent"] = 306
pi[2100].config_channels_configuration["CD_hgTemp_2_cvent"] = 307
pi[2100].config_channels_configuration["CD_hgTemp_3_cvent"] = 308
pi[2100].config_channels_configuration["CD_hgTemp_4_cvent"] = 309
pi[2100].config_channels_configuration["CD_rhgTemp_1_cvent"] = 310
pi[2100].config_channels_configuration["CD_rhgTemp_2_cvent"] = 311
pi[2100].config_channels_configuration["CD_rhgTemp_3_cvent"] = 312
pi[2100].config_channels_configuration["CD_rhgTemp_4_cvent"] = 313
pi[2100].config_channels_configuration["CD_rtHum_1_cvent"] = 314
pi[2100].config_channels_configuration["CD_rtHum_2_cvent"] = 315
pi[2100].config_channels_configuration["CD_rtHum_3_cvent"] = 316
pi[2100].config_channels_configuration["CD_rtHum_4_cvent"] = 317
pi[2100].config_channels_configuration["CD_rtPcnt_1_cvent"] = 318
pi[2100].config_channels_configuration["CD_rtPcnt_2_cvent"] = 319
pi[2100].config_channels_configuration["CD_rtPcnt_3_cvent"] = 320
pi[2100].config_channels_configuration["CD_rtPcnt_4_cvent"] = 321
pi[2100].config_channels_configuration["CD_rhgPcnt_1_cvent"] = 322
pi[2100].config_channels_configuration["CD_rhgPcnt_2_cvent"] = 323
pi[2100].config_channels_configuration["CD_rhgPcnt_3_cvent"] = 324
pi[2100].config_channels_configuration["CD_rhgPcnt_4_cvent"] = 325
pi[2100].config_channels_configuration["CD_start_time_1_hid"] = 326
pi[2100].config_channels_configuration["CD_start_time_2_hid"] = 327
pi[2100].config_channels_configuration["CD_start_time_3_hid"] = 328
pi[2100].config_channels_configuration["CD_start_time_4_hid"] = 329
pi[2100].config_channels_configuration["CD_start_time_5_hid"] = 330
pi[2100].config_channels_configuration["CD_start_time_6_hid"] = 331
pi[2100].config_channels_configuration["CD_week_a_fri_hid"] = 332
pi[2100].config_channels_configuration["CD_week_a_mon_hid"] = 333
pi[2100].config_channels_configuration["CD_week_a_sat_hid"] = 334
pi[2100].config_channels_configuration["CD_week_a_sun_hid"] = 335
pi[2100].config_channels_configuration["CD_week_a_thu_hid"] = 336
pi[2100].config_channels_configuration["CD_week_a_tue_hid"] = 337
pi[2100].config_channels_configuration["CD_week_a_wed_hid"] = 338
pi[2100].config_channels_configuration["CD_week_b_fri_hid"] = 339
pi[2100].config_channels_configuration["CD_week_b_mon_hid"] = 340
pi[2100].config_channels_configuration["CD_week_b_sat_hid"] = 341
pi[2100].config_channels_configuration["CD_week_b_sun_hid"] = 342
pi[2100].config_channels_configuration["CD_week_b_thu_hid"] = 343
pi[2100].config_channels_configuration["CD_week_b_tue_hid"] = 344
pi[2100].config_channels_configuration["CD_week_b_wed_hid"] = 345
pi[2100].config_channels_configuration["CD_sensor_generic"] = 346
pi[2100].config_channels_configuration["CD_mode_varout"] = 347
pi[2100].config_channels_configuration["CD_pump_onoff"] = 348
pi[2100].config_channels_configuration["CD_supply_pump_generic"] = 349
pi[2100].config_channels_configuration["CD_analog_min_varout"] = 350
pi[2100].config_channels_configuration["CD_analog_min_generic"] = 351
pi[2100].config_channels_configuration["CD_analog_max_varout"] = 352
pi[2100].config_channels_configuration["CD_analog_max_generic"] = 353
pi[2100].config_channels_configuration["CD_setpoint_generic"] = 354
pi[2100].config_channels_configuration["CD_activeTrigger_fval"] = 355

pi[2100].config_channels_configuration["CD_activeCool_cool1_onoff"] = 356// of On/Off
pi[2100].config_channels_configuration["CD_activeCool_cool2_onoff"] = 357
pi[2100].config_channels_configuration["CD_activeCool_cool3_onoff"] = 358
pi[2100].config_channels_configuration["CD_activeCool_cool4_onoff"] = 359
pi[2100].config_channels_configuration["CD_activeCool_cool5_onoff"] = 360
pi[2100].config_channels_configuration["CD_activeCool_cool6_onoff"] = 361
pi[2100].config_channels_configuration["CD_activeCool_normal_onoff"] = 362
pi[2100].config_channels_configuration["CD_heat6_onoff"] = 363
pi[2100].config_channels_configuration["CD_heat5_onoff"] = 364
pi[2100].config_channels_configuration["CD_heat4_onoff"] = 365
pi[2100].config_channels_configuration["CD_heat3_onoff"] = 366
pi[2100].config_channels_configuration["CD_heat2_onoff"] = 367
pi[2100].config_channels_configuration["CD_heat1_onoff"] = 368
pi[2100].config_channels_configuration["CD_normal_onoff"] = 369
pi[2100].config_channels_configuration["CD_cool1_onoff"] = 370
pi[2100].config_channels_configuration["CD_cool2_onoff"] = 371
pi[2100].config_channels_configuration["CD_cool3_onoff"] = 372
pi[2100].config_channels_configuration["CD_cool4_onoff"] = 373
pi[2100].config_channels_configuration["CD_cool5_onoff"] = 374
pi[2100].config_channels_configuration["CD_cool6_onoff"] = 375

pi[2100].config_channels_configuration["CD_sensorHoldTime"] = 376
pi[2100].config_channels_configuration["CD_light_threshold"] = 377
pi[2100].config_channels_configuration["CD_light_drive_to"] = 378
pi[2100].config_channels_configuration["CD_temp_sensor"] = 379
pi[2100].config_channels_configuration["CD_temp_threshold"] = 380
pi[2100].config_channels_configuration["CD_temp_drive_to"] = 381
pi[2100].config_channels_configuration["CD_enableSmartInject"] = 382

pi[2100].config_channels_configuration["CD_trigStartType_fval"] = 387// triggers
pi[2100].config_channels_configuration["CD_trigStartIDX_fval"] = 388
pi[2100].config_channels_configuration["CD_trigStopType_fval"] = 389
pi[2100].config_channels_configuration["CD_trigStopIDX_fval"] = 390
pi[2100].config_channels_configuration["CD_time_sec_fval"] = 391
pi[2100].config_channels_configuration["CD_volume_fval"] = 392

pi[2100].config_channels_configuration["CD_trigStartType_pump"] = 405
pi[2100].config_channels_configuration["CD_trigStartIDX_pump"] = 406
pi[2100].config_channels_configuration["CD_batchVolume_pump"] = 407

pi[2100].config_channels_configuration["CD_minDifferencex10_pump"] = 414
pi[2100].config_channels_configuration["CD_ratiox100_pump"] = 415

pi[2100].snapshot_aux_alarms["alarmValue"] = 0;;// 1024
pi[2100].snapshot_aux_persistent_variables["variableValue"] = 0;;// 1056
pi[2100].snapshot_aux_variables["variableValue"] = 0;;// 1056
pi[2100].snapshot_expansion_boards["boardStatus"] = 0;;// 1056




//var config_aux_alarms = {}
pi[2100].config_aux_alarms["alarmIndex"] = 0
pi[2100].config_aux_alarms["alarmName"] = 1
pi[2100].config_aux_alarms["allowGraphing"] = 2
pi[2100].config_aux_alarms["userComment"] = 3

//var config_aux_controls = {}
pi[2100].config_aux_controls["auxIndex"] = 0
pi[2100].config_aux_controls["unix_timestamp(modified)"] = 1
pi[2100].config_aux_controls["operand1Type"] = 2
pi[2100].config_aux_controls["operand1Value"] = 3
pi[2100].config_aux_controls["operand2Type"] = 4
pi[2100].config_aux_controls["operand2Value"] = 5
pi[2100].config_aux_controls["operand3Type"] = 6
pi[2100].config_aux_controls["operand3Value"] = 7
pi[2100].config_aux_controls["operator1"] = 8
pi[2100].config_aux_controls["operator2"] = 9
pi[2100].config_aux_controls["conditionSeconds"] = 10
pi[2100].config_aux_controls["action"] = 11
pi[2100].config_aux_controls["targetType"] = 12
pi[2100].config_aux_controls["targetValue"] = 13
pi[2100].config_aux_controls["actionParameter"] = 14
pi[2100].config_aux_controls["actionHoldTime"] = 15
pi[2100].config_aux_controls["userComment"] = 16

//var config_aux_persistent_variables = {}
// config_aux_persistent_variables["zoneIndex"] = 827
// config_aux_persistent_variables["unitIndex"] = 828
pi[2100].config_aux_persistent_variables["variableIndex"] = 0
pi[2100].config_aux_persistent_variables["variableName"] = 1
pi[2100].config_aux_persistent_variables["allowGraphing"] = 2
pi[2100].config_aux_persistent_variables["userComment"] = 3

pi[2100].config_annex_sensors["alarmEnable"] = 0
pi[2100].config_annex_sensors["alarmLow"] = 1
pi[2100].config_annex_sensors["alarmHigh"] = 2
pi[2100].config_annex_sensors["alarmDeadband"] = 3
pi[2100].config_annex_sensors["alarmDelay"] = 4
pi[2100].config_annex_sensors["alarmLevel"] = 5
pi[2100].config_annex_sensors["notifyWhom"] = 6
pi[2100].config_annex_sensors["notifyHowOften"] = 7
pi[2100].config_annex_sensors["notifyPerDay"] = 8
pi[2100].config_annex_sensors["notifySnoozeEnable"] = 9
pi[2100].config_annex_sensors["notifySnooze"] = 10// 20 slots reseverved
pi[2100].config_annex_sensors["alarmState"] = 11
pi[2100].config_annex_sensors["notifyTime"] = 12
pi[2100].config_annex_sensors["notifyCount"] = 13

//var config_aux_variables = {}
// axxx["zoneIndex"] = 834
// axxx["unitIndex"] = 835
pi[2100].config_aux_variables["variableIndex"] = 0
pi[2100].config_aux_variables["variableName"] = 1
pi[2100].config_aux_variables["allowGraphing"] = 2
pi[2100].config_aux_variables["userComment"] = 3

//var config_expansion_boards = {}
// zxx["zoneIndex"] =
// zxx["unitIndex"] =
pi[2100].config_expansion_boards["boardIndex"] = 0 // 64
pi[2100].config_expansion_boards["unix_timestamp(modified)"] = 1
pi[2100].config_expansion_boards["boardType"] = 2
pi[2100].config_expansion_boards["address"] = 3
pi[2100].config_expansion_boards["startChannelIndex"] = 4

//var config_setpoints = {}
// zxx["zoneIndex"] =
// pi[2100].config_setpoints["setpointIndex"] = 0 // 8 * 10// can be used for setpointName
pi[2100].config_setpoints["name"] = 0 // 8 * 10// can be used for setpointName
pi[2100].config_setpoints["unix_timestamp(modified)"] = 1
pi[2100].config_setpoints["enabled"] = 2
pi[2100].config_setpoints["startTimeOfDay"] = 3
pi[2100].config_setpoints["astroAdjust"] = 4
pi[2100].config_setpoints["rampMinutes"] = 5
pi[2100].config_setpoints["heatSetpoint"] = 6
pi[2100].config_setpoints["coolSetpoint"] = 7
pi[2100].config_setpoints["humidifySetpoint"] = 8
pi[2100].config_setpoints["dehumidifySetpoint"] = 9

//var config_ecph = {}
// zzz["zoneIndex"] =
// zzz["unitIndex"] =
pi[2100].config_ecph["ecphIndex"] = 0 // 8 * 11 of these
pi[2100].config_ecph["unix_timestamp(modified)"] = 1
pi[2100].config_ecph["name"] = 2
pi[2100].config_ecph["ecType"] = 3
pi[2100].config_ecph["alarmHoldTime"] = 4
pi[2100].config_ecph["lowECThreshold"] = 5
pi[2100].config_ecph["highECThreshold"] = 6
pi[2100].config_ecph["highECDeviationThreshold"] = 7
pi[2100].config_ecph["lowPHThreshold"] = 8
pi[2100].config_ecph["highPHThreshold"] = 9
pi[2100].config_ecph["highPHDeviationThreshold"] = 10

//var config_ecph_sensors = {}
// config_ecph_sensors["zoneIndex"] =
// config_ecph_sensors["unitIndex"] =
// config_ecph_sensors["ecphIndex"] =
// config_ecph_sensors["sensorIndex"] =
pi[2100].config_ecph_sensors["(modified)"] = 0
pi[2100].config_ecph_sensors["ecMapping"] = 1
pi[2100].config_ecph_sensors["ecServiceIntervalDays"] = 2
pi[2100].config_ecph_sensors["ecServiceTime"] = 3
pi[2100].config_ecph_sensors["ecCalibrationIntervalDays"] = 4
pi[2100].config_ecph_sensors["ecCalibrationTime"] = 5
pi[2100].config_ecph_sensors["ecCalibration1Value"] = 6
pi[2100].config_ecph_sensors["ecCalibration1Raw"] = 7
pi[2100].config_ecph_sensors["ecCalibration2Value"] = 8
pi[2100].config_ecph_sensors["ecCalibration2Raw"] = 9
pi[2100].config_ecph_sensors["phMapping"] = 10
pi[2100].config_ecph_sensors["phServiceIntervalDays"] = 11
pi[2100].config_ecph_sensors["phServiceTime"] = 12
pi[2100].config_ecph_sensors["phCalibrationIntervalDays"] = 13
pi[2100].config_ecph_sensors["phCalibrationTime"] = 14
pi[2100].config_ecph_sensors["phCalibration1Value"] = 15
pi[2100].config_ecph_sensors["phCalibration1Raw"] = 16
pi[2100].config_ecph_sensors["phCalibration2Value"] = 17
pi[2100].config_ecph_sensors["phCalibration2Raw"] = 18
pi[2100].config_ecph_sensors["temperatureMapping"] = 19
pi[2100].config_ecph_sensors["temperatureCalibration"] = 20
pi[2100].config_ecph_sensors["temperatureCompensationMode"] = 21
pi[2100].config_ecph_sensors["temperatureCompensationValue"] = 22

//var config_zones = {}
// config_zones["zoneIndex"] =
pi[2100].config_zones["name"] = 0
pi[2100].config_zones["description"] = 1

//var config_controllers = {}
// config_controllers["zoneIndex"] =
// config_controllers["unitIndex"] =
pi[2100].config_controllers["address"] = 0
pi[2100].config_controllers["igrowVersion"] = 1
pi[2100].config_controllers["isConnected"] = 2
pi[2100].config_controllers["isInNetwork"] = 3

//var config_communication_status = {}
pi[2100].config_communication_status["statusID"] = 0
pi[2100].config_communication_status["unix_timestamp(statusTime)"] = 1
pi[2100].config_communication_status["statusLog"] = 2

//var controller_configuration_settings = {}// 20210706, these are getting the *2+1 treatment - Probably Not!
pi[2100].controller_configuration_settings["Inside Temperature Mapping"] = 1
pi[2100].controller_configuration_settings["Relative Humidity Mapping"] = 2
pi[2100].controller_configuration_settings["Local Temperature Mapping"] = 3
pi[2100].controller_configuration_settings["Local Humidity Mapping"] = 4
pi[2100].controller_configuration_settings["Outside Temperature Mapping"] = 5
pi[2100].controller_configuration_settings["Outside Humidity Mapping"] = 6
pi[2100].controller_configuration_settings["CO2 Mapping"] = 7
pi[2100].controller_configuration_settings["Outside Light Mapping"] = 8
pi[2100].controller_configuration_settings["Wind Speed Mapping"] = 9
pi[2100].controller_configuration_settings["Wind Direction Mapping"] = 10
pi[2100].controller_configuration_settings["Rain Mapping"] = 11
pi[2100].controller_configuration_settings["Analog Temperature 1 Mapping"] = 12
pi[2100].controller_configuration_settings["Analog Temperature 2 Mapping"] = 13
pi[2100].controller_configuration_settings["Analog Temperature 3 Mapping"] = 14
pi[2100].controller_configuration_settings["Analog Temperature 4 Mapping"] = 15
pi[2100].controller_configuration_settings["Analog Temperature 5 Mapping"] = 16
pi[2100].controller_configuration_settings["Soil Moisture 1 Mapping"] = 17
pi[2100].controller_configuration_settings["Soil Moisture 2 Mapping"] = 18
pi[2100].controller_configuration_settings["Soil Moisture 3 Mapping"] = 19
pi[2100].controller_configuration_settings["Soil Moisture 4 Mapping"] = 20
pi[2100].controller_configuration_settings["Soil Moisture 5 Mapping"] = 21
pi[2100].controller_configuration_settings["Vent Position Sensor 1 Mapping"] = 22
pi[2100].controller_configuration_settings["Vent Position Sensor 2 Mapping"] = 23
pi[2100].controller_configuration_settings["Vent Position Sensor 3 Mapping"] = 24
pi[2100].controller_configuration_settings["Vent Position Sensor 4 Mapping"] = 25
pi[2100].controller_configuration_settings["Vent Position Sensor 5 Mapping"] = 26
pi[2100].controller_configuration_settings["EC/pH 1 Mapping"] = 27
pi[2100].controller_configuration_settings["EC/pH 2 Mapping"] = 28
pi[2100].controller_configuration_settings["EC/pH 3 Mapping"] = 29
pi[2100].controller_configuration_settings["EC/pH 4 Mapping"] = 30
pi[2100].controller_configuration_settings["EC/pH 5 Mapping"] = 31
pi[2100].controller_configuration_settings["EC/pH 1 Probe Type"] = 32
pi[2100].controller_configuration_settings["EC/pH 2 Probe Type"] = 33
pi[2100].controller_configuration_settings["EC/pH 3 Probe Type"] = 34
pi[2100].controller_configuration_settings["EC/pH 4 Probe Type"] = 35
pi[2100].controller_configuration_settings["EC/pH 5 Probe Type"] = 36
pi[2100].controller_configuration_settings["Generic 1 Mapping"] = 37
pi[2100].controller_configuration_settings["Generic 2 Mapping"] = 38
pi[2100].controller_configuration_settings["Inside Temperature Calibration"] = 39
pi[2100].controller_configuration_settings["Relative Humidity Calibration"] = 40
pi[2100].controller_configuration_settings["Outside Temperature Calibration"] = 41
pi[2100].controller_configuration_settings["Outside Humidity Calibration"] = 42
pi[2100].controller_configuration_settings["CO2 Calibration"] = 43
pi[2100].controller_configuration_settings["Outside Light Calibration"] = 44
pi[2100].controller_configuration_settings["Wind Speed Calibration"] = 45
pi[2100].controller_configuration_settings["Fallback Temperature Calibration"] = 46
pi[2100].controller_configuration_settings["Analog Temperature 1 Calibration"] = 47
pi[2100].controller_configuration_settings["Analog Temperature 2 Calibration"] = 48
pi[2100].controller_configuration_settings["Analog Temperature 3 Calibration"] = 49
pi[2100].controller_configuration_settings["Analog Temperature 4 Calibration"] = 50
pi[2100].controller_configuration_settings["Analog Temperature 5 Calibration"] = 51
pi[2100].controller_configuration_settings["Soil Mositure 1 Calibration"] = 52
pi[2100].controller_configuration_settings["Soil Mositure 2 Calibration"] = 53
pi[2100].controller_configuration_settings["Soil Mositure 3 Calibration"] = 54
pi[2100].controller_configuration_settings["Soil Mositure 4 Calibration"] = 55
pi[2100].controller_configuration_settings["Soil Mositure 5 Calibration"] = 56
pi[2100].controller_configuration_settings["Vent Position Sensor 1 Calibration - Adjust"] = 57
pi[2100].controller_configuration_settings["Vent Position Sensor 2 Calibration - Adjust"] = 58
pi[2100].controller_configuration_settings["Vent Position Sensor 3 Calibration - Adjust"] = 59
pi[2100].controller_configuration_settings["Vent Position Sensor 4 Calibration - Adjust"] = 60
pi[2100].controller_configuration_settings["Vent Position Sensor 5 Calibration - Adjust"] = 61
pi[2100].controller_configuration_settings["EC/pH 1 Calibration - Zero"] = 62
pi[2100].controller_configuration_settings["EC/pH 2 Calibration - Zero"] = 63
pi[2100].controller_configuration_settings["EC/pH 3 Calibration - Zero"] = 64
pi[2100].controller_configuration_settings["EC/pH 4 Calibration - Zero"] = 65
pi[2100].controller_configuration_settings["EC/pH 5 Calibration - Zero"] = 66
pi[2100].controller_configuration_settings["EC/pH 1 Calibration - Gain"] = 67
pi[2100].controller_configuration_settings["EC/pH 2 Calibration - Gain"] = 68
pi[2100].controller_configuration_settings["EC/pH 3 Calibration - Gain"] = 69
pi[2100].controller_configuration_settings["EC/pH 4 Calibration - Gain"] = 70
pi[2100].controller_configuration_settings["EC/pH 5 Calibration - Gain"] = 71
pi[2100].controller_configuration_settings["EC/pH 1 Calibration - Given Gain"] = 72
pi[2100].controller_configuration_settings["EC/pH 2 Calibration - Given Gain"] = 73
pi[2100].controller_configuration_settings["EC/pH 3 Calibration - Given Gain"] = 74
pi[2100].controller_configuration_settings["EC/pH 4 Calibration - Given Gain"] = 75
pi[2100].controller_configuration_settings["EC/pH 5 Calibration - Given Gain"] = 76
pi[2100].controller_configuration_settings["Generic 1 Calibration"] = 77
pi[2100].controller_configuration_settings["Generic 2 Calibration"] = 78
pi[2100].controller_configuration_settings["CO2 Least Significant Bit"] = 79
pi[2100].controller_configuration_settings["Light Multiplier"] = 80
pi[2100].controller_configuration_settings["Generic 1 Multiplier"] = 81
pi[2100].controller_configuration_settings["Generic 2 Multiplier"] = 82
pi[2100].controller_configuration_settings["Local/Remote Setpoints"] = 83
pi[2100].controller_configuration_settings["Enable Bump Vents"] = 84
pi[2100].controller_configuration_settings["Bump Intervals Minutes)"] = 85
pi[2100].controller_configuration_settings["Spike Temperature Delta"] = 86
pi[2100].controller_configuration_settings["Spike Temperature Hold Time Exception"] = 87
pi[2100].controller_configuration_settings["Outside Temperature 2 Mapping"] = 88
pi[2100].controller_configuration_settings["Outside Temperature 2 Calibration"] = 89
pi[2100].controller_configuration_settings["Barometric Pressure Mapping"] = 90
pi[2100].controller_configuration_settings["Barometric Pressure Calibration"] = 91
pi[2100].controller_configuration_settings["Enable expansion board"] = 92
pi[2100].controller_configuration_settings["Autodetect Mode"] = 93
pi[2100].controller_configuration_settings["Heat Demand Offset"] = 94
pi[2100].controller_configuration_settings["Cool Demand Offset"] = 95
pi[2100].controller_configuration_settings["Generic Sensor 1 Range"] = 96
pi[2100].controller_configuration_settings["Generic Sensor 1 Units"] = 97
pi[2100].controller_configuration_settings["Generic Sensor 1 Filter Max Samples"] = 98
pi[2100].controller_configuration_settings["Generic Sensor 2 Range"] = 99
pi[2100].controller_configuration_settings["Generic Sensor 2 Units"] = 100
pi[2100].controller_configuration_settings["Generic PID Multipliers P"] = 101
pi[2100].controller_configuration_settings["Generic PID Multipliers I"] = 102
pi[2100].controller_configuration_settings["Generic PID Multipliers D"] = 103
pi[2100].controller_configuration_settings["Expansion Output 1 Type"] = 109
pi[2100].controller_configuration_settings["Expansion Output 2 Type"] = 110
pi[2100].controller_configuration_settings["Expansion Input 1 Type"] = 111
pi[2100].controller_configuration_settings["Expansion Input 2 Type"] = 112
pi[2100].controller_configuration_settings["Sensor Delay"] = 113
pi[2100].controller_configuration_settings["Vent Position 1 Calibration - Open"] = 114
pi[2100].controller_configuration_settings["Vent Position 2 Calibration - Open"] = 115
pi[2100].controller_configuration_settings["Vent Position 3 Calibration - Open"] = 116
pi[2100].controller_configuration_settings["Vent Position 4 Calibration - Open"] = 117
pi[2100].controller_configuration_settings["Vent Position 5 Calibration - Open"] = 118
pi[2100].controller_configuration_settings["Vent Position 1 Calibration - Close"] = 119
pi[2100].controller_configuration_settings["Vent Position 2 Calibration - Close"] = 120
pi[2100].controller_configuration_settings["Vent Position 3 Calibration - Close"] = 121
pi[2100].controller_configuration_settings["Vent Position 4 Calibration - Close"] = 122
pi[2100].controller_configuration_settings["Vent Position 5 Calibration - Close"] = 123
pi[2100].controller_configuration_settings["Inside Light Mapping"] = 124
pi[2100].controller_configuration_settings["Differential Pressure Mapping"] = 125
pi[2100].controller_configuration_settings["Snow Mapping"] = 126
pi[2100].controller_configuration_settings["Inside Light Calibration"] = 127
pi[2100].controller_configuration_settings["Differential Pressure Calibration"] = 128
pi[2100].controller_configuration_settings["Accumulator 1 - Sensor"] = 129
pi[2100].controller_configuration_settings["Accumulator 1 - Operating Period"] = 130
pi[2100].controller_configuration_settings["Accumulator 2 - Sensor"] = 131
pi[2100].controller_configuration_settings["Accumulator 2 - Operating Period"] = 132
pi[2100].controller_configuration_settings["Generic Sensor 2 Filter Max Samples"] = 133
pi[2100].controller_configuration_settings["Hold Temp Vent Open"] = 134
pi[2100].controller_configuration_settings["Irrigation Trigger Input Mapping"] = 135
pi[2100].controller_configuration_settings["Fallback Sensor Input Mapping"] = 136
pi[2100].controller_configuration_settings["Enable Expansion Board"] = 137
pi[2100].controller_configuration_settings["Enable Loud Vent"] = 138
pi[2100].controller_configuration_settings["Wind Direction Calibration"] = 139
pi[2100].controller_configuration_settings["Rain Calibration"] = 140
pi[2100].controller_configuration_settings["Snow Calibration"] = 141
pi[2100].controller_configuration_settings["Inside Light Multiplier"] = 142
pi[2100].controller_configuration_settings["Canopy Sensor Mapping"] = 143
pi[2100].controller_configuration_settings["Canopy Sensor Calibration"] = 144
pi[2100].controller_configuration_settings["Max Temperature Adjustment"] = 145
pi[2100].controller_configuration_settings["Temperature Average Enable"] = 146
pi[2100].controller_configuration_settings["Temperature Average Include Temp1"] = 147
pi[2100].controller_configuration_settings["Temperature Average Include Temp2"] = 148
pi[2100].controller_configuration_settings["Temperature Average Include Temp3"] = 149
pi[2100].controller_configuration_settings["Temperature Average Include Temp4"] = 150
pi[2100].controller_configuration_settings["Temperature Average Include Temp5"] = 151
pi[2100].controller_configuration_settings["VPD Notify"] = 152
pi[2100].controller_configuration_settings["VPD Min"] = 153
pi[2100].controller_configuration_settings["VPD Max"] = 154
pi[2100].controller_configuration_settings["Cloud 2100 Enable"] = 155
pi[2100].controller_configuration_settings["Save Inside Temperature Calibration"] = 156
pi[2100].controller_configuration_settings["Save Temperature Average Enable"] = 157

pi[2100].zone_configuration_settings["Use Fallback Sensor if In Temp Fails"] = 1
pi[2100].zone_configuration_settings["Fallback Sensor Failed Temperature Stage"] = 3
pi[2100].zone_configuration_settings["Force To No Sensor Stage setting 3) if High Alarm occurs"] = 4
pi[2100].zone_configuration_settings["Rain Hold Time"] = 5
pi[2100].zone_configuration_settings["Command Delay Time"] = 6
pi[2100].zone_configuration_settings["Daylight Savings Time"] = 7
pi[2100].zone_configuration_settings["Log History"] = 8
pi[2100].zone_configuration_settings["Latitude"] = 9
pi[2100].zone_configuration_settings["Longitude"] = 10
pi[2100].zone_configuration_settings["Temperature Units"] = 11
pi[2100].zone_configuration_settings["Windspeed Units"] = 12
pi[2100].zone_configuration_settings["Light Units"] = 13
pi[2100].zone_configuration_settings["Irrigation Mode"] = 14
pi[2100].zone_configuration_settings["Irrigation Delay"] = 15
pi[2100].zone_configuration_settings["High Alarm Temperature Above Cool Setpoint Threshold"] = 17
pi[2100].zone_configuration_settings["High Alarm Temperature Hold Time"] = 18
pi[2100].zone_configuration_settings["Low Alarm Temperature Below Heat Setpoint Threshold"] = 19
pi[2100].zone_configuration_settings["Low Alarm Temperature Hold Time"] = 20
pi[2100].zone_configuration_settings["Cool Deadband"] = 21
pi[2100].zone_configuration_settings["Heat Deadband"] = 22
pi[2100].zone_configuration_settings["Humidity Deadband"] = 23
pi[2100].zone_configuration_settings["Analog Temperature Deadband"] = 24
pi[2100].zone_configuration_settings["Enable SmartCool"] = 25
pi[2100].zone_configuration_settings["SmartCool Setting"] = 26
pi[2100].zone_configuration_settings["Show Heat Demand on iGrow"] = 32
pi[2100].zone_configuration_settings["Heat Demand Max Light"] = 33
pi[2100].zone_configuration_settings["Heat Demand Max Wind"] = 34
pi[2100].zone_configuration_settings["Greenhouse heating design delta-T"] = 35
pi[2100].zone_configuration_settings["Light''s influence on Heat Demand"] = 36
pi[2100].zone_configuration_settings["Wind''s influence on Heat Demand"] = 37
pi[2100].zone_configuration_settings["Show Cool Demand on iGrow"] = 38
pi[2100].zone_configuration_settings["Cool Demand Max Light"] = 39
pi[2100].zone_configuration_settings["Greenhouse cooling design delta-T"] = 40
pi[2100].zone_configuration_settings["Cool Demand Light Factor"] = 41
pi[2100].zone_configuration_settings["Enable Active Cooling"] = 42
pi[2100].zone_configuration_settings["Passive Lock Stage"] = 43
pi[2100].zone_configuration_settings["Enter Active Cooling Cool Demand Threshold"] = 44
pi[2100].zone_configuration_settings["Exit Active Cooling Cool Demand Threshold"] = 45
pi[2100].zone_configuration_settings["Cool stages"] = 46
pi[2100].zone_configuration_settings["Heat stages"] = 47
pi[2100].zone_configuration_settings["Stage Width"] = 48
pi[2100].zone_configuration_settings["Stage Override 1 Enabled"] = 49
pi[2100].zone_configuration_settings["Stage Override 1 Temperature Stage"] = 50
pi[2100].zone_configuration_settings["Stage Override 1 Start Time"] = 51
pi[2100].zone_configuration_settings["Stage Override 1 End Time"] = 52
pi[2100].zone_configuration_settings["Stage Override 1 Interval"] = 53
pi[2100].zone_configuration_settings["Stage Override 2 Enabled"] = 54
pi[2100].zone_configuration_settings["Stage Override 2 Temperature Stage"] = 55
pi[2100].zone_configuration_settings["Stage Override 2 Start Time"] = 56
pi[2100].zone_configuration_settings["Stage Override 2 End Time"] = 57
pi[2100].zone_configuration_settings["Stage Override 2 Interval"] = 58
pi[2100].zone_configuration_settings["Stage Override 3 Enabled"] = 59
pi[2100].zone_configuration_settings["Stage Override 3 Temperature Stage"] = 60
pi[2100].zone_configuration_settings["Stage Override 3 Start Time"] = 61
pi[2100].zone_configuration_settings["Stage Override 3 End Time"] = 62
pi[2100].zone_configuration_settings["Stage Override 3 Interval"] = 63
pi[2100].zone_configuration_settings["Stage Override 4 Enabled"] = 64
pi[2100].zone_configuration_settings["Stage Override 4 Temperature Stage"] = 65
pi[2100].zone_configuration_settings["Stage Override 4 Start Time"] = 66
pi[2100].zone_configuration_settings["Stage Override 4 End Time"] = 67
pi[2100].zone_configuration_settings["Stage Override 4 Interval"] = 68
pi[2100].zone_configuration_settings["Vapor Pressure Deficit Media Sensor"] = 69
pi[2100].zone_configuration_settings["Lighting Cyclic Mode"] = 70
pi[2100].zone_configuration_settings["Lighting Start Delay"] = 71
pi[2100].zone_configuration_settings["Lighting Finish Delay"] = 72
pi[2100].zone_configuration_settings["Lighting Active Time"] = 73
pi[2100].zone_configuration_settings["Lighting Minimum On Time"] = 74
pi[2100].zone_configuration_settings["Drive to Average - Enable"] = 75
pi[2100].zone_configuration_settings["Drive to Average - Target Temperature"] = 76
pi[2100].zone_configuration_settings["Drive to Average - Maximum Failed Days"] = 77
pi[2100].zone_configuration_settings["Drive to Average - Deviated Temperature Threshold"] = 78
pi[2100].zone_configuration_settings["Drive to Average - Setpoint Correction"] = 79
pi[2100].zone_configuration_settings["Cool Setpoint Influence Factor - Enable"] = 80
pi[2100].zone_configuration_settings["Cool Setpoint Influence Factor - Sensor"] = 81
pi[2100].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Threshold"] = 82
pi[2100].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Offset"] = 83
pi[2100].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Threshold"] = 84
pi[2100].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Offset"] = 85
pi[2100].zone_configuration_settings["Heat Setpoint Influence Factor - Enable"] = 86
pi[2100].zone_configuration_settings["Heat Setpoint Influence Factor - Sensor"] = 87
pi[2100].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Threshold"] = 88
pi[2100].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Offset"] = 89
pi[2100].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Threshold"] = 90
pi[2100].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Offset"] = 91
pi[2100].zone_configuration_settings["Start Up Delay"] = 92
pi[2100].zone_configuration_settings["Curtain Energy Mode - Light Deadband"] = 93
pi[2100].zone_configuration_settings["Curtain Energy Mode - Temperature Deadband"] = 94
pi[2100].zone_configuration_settings["Humidity Override On Time"] = 95
pi[2100].zone_configuration_settings["Humidity Override Off Time"] = 96
pi[2100].zone_configuration_settings["Dehumidify Low Outside Temperature Threshold"] = 97
pi[2100].zone_configuration_settings["Dehumidify Heat Boost"] = 98
pi[2100].zone_configuration_settings["Enable Demands Based on Active Cool"] = 99
pi[2100].zone_configuration_settings["Enable Active Cool Stage Override"] = 100
pi[2100].zone_configuration_settings["SmartCool[0] Ku"] = 101
pi[2100].zone_configuration_settings["SmartCool[0] Gmax"] = 102
pi[2100].zone_configuration_settings["SmartCool[0] Kd"] = 103
pi[2100].zone_configuration_settings["SmartCool[0] Gmin"] = 104
pi[2100].zone_configuration_settings["SmartCool[1] Ku"] = 105
pi[2100].zone_configuration_settings["SmartCool[1] Gmax"] = 106
pi[2100].zone_configuration_settings["SmartCool[1] Kd"] = 107
pi[2100].zone_configuration_settings["SmartCool[1] Gmin"] = 108
pi[2100].zone_configuration_settings["SmartCool[2] Ku"] = 109
pi[2100].zone_configuration_settings["SmartCool[2] Gmax"] = 110
pi[2100].zone_configuration_settings["SmartCool[2] Kd"] = 111
pi[2100].zone_configuration_settings["SmartCool[2] Gmin"] = 112
pi[2100].zone_configuration_settings["SmartCool[3] Ku"] = 113
pi[2100].zone_configuration_settings["SmartCool[3] Gmax"] = 114
pi[2100].zone_configuration_settings["SmartCool[3] Kd"] = 115
pi[2100].zone_configuration_settings["SmartCool[3] Gmin"] = 116
pi[2100].zone_configuration_settings["SmartCool[4] Ku"] = 117
pi[2100].zone_configuration_settings["SmartCool[4] Gmax"] = 118
pi[2100].zone_configuration_settings["SmartCool[4] Kd"] = 119
pi[2100].zone_configuration_settings["SmartCool[4] Gmin"] = 120
pi[2100].zone_configuration_settings["SmartCool[5] Ku"] = 121
pi[2100].zone_configuration_settings["SmartCool[5] Gmax"] = 122
pi[2100].zone_configuration_settings["SmartCool[5] Kd"] = 123
pi[2100].zone_configuration_settings["SmartCool[5] Gmin"] = 124
pi[2100].zone_configuration_settings["SmartCool[6] Ku"] = 125
pi[2100].zone_configuration_settings["SmartCool[6] Gmax"] = 126
pi[2100].zone_configuration_settings["SmartCool[6] Kd"] = 127
pi[2100].zone_configuration_settings["SmartCool[6] Gmin"] = 128
pi[2100].zone_configuration_settings["SmartCool[7] Ku"] = 129
pi[2100].zone_configuration_settings["SmartCool[7] Gmax"] = 130
pi[2100].zone_configuration_settings["SmartCool[7] Kd"] = 131
pi[2100].zone_configuration_settings["SmartCool[7] Gmin"] = 132
pi[2100].zone_configuration_settings["SmartCool[8] Ku"] = 133
pi[2100].zone_configuration_settings["SmartCool[8] Gmax"] = 134
pi[2100].zone_configuration_settings["SmartCool[8] Kd"] = 135
pi[2100].zone_configuration_settings["SmartCool[8] Gmin"] = 136
pi[2100].zone_configuration_settings["SmartCool[9] Ku"] = 137
pi[2100].zone_configuration_settings["SmartCool[9] Gmax"] = 138
pi[2100].zone_configuration_settings["SmartCool[9] Kd"] = 139
pi[2100].zone_configuration_settings["SmartCool[9] Gmin"] = 140
pi[2100].zone_configuration_settings["Peristaltic - Output Mode"] = 141
pi[2100].zone_configuration_settings["Peristaltic - Output Delay"] = 142
pi[2100].zone_configuration_settings["Volume Measurement Units"] = 143
pi[2100].zone_configuration_settings["Nutrient Units"] = 144
pi[2100].zone_configuration_settings["Nutrient Units - TDS Conversion Factor"] = 145
pi[2100].zone_configuration_settings["Equipment Delay"] = 146
pi[2100].zone_configuration_settings["Sensor Display - 1"] = 147
pi[2100].zone_configuration_settings["Sensor Display - 2"] = 148
pi[2100].zone_configuration_settings["Sensor Display - 3"] = 149
pi[2100].zone_configuration_settings["Sensor Display - 4"] = 150
pi[2100].zone_configuration_settings["Schedule Pump Transition Time"] = 151
pi[2100].zone_configuration_settings["Peristaltic Pump - Advanced Mode"] = 152


/********************* End Controller 2100 Values ********************************/


/******************* 3300 Values ******************************/

p.PID_3300_BASE_SNAPSHOTS = 0 // 81
p.PID_3300_BASE_SNAP_CHANNELS = 100;
p.PID_3300_BASE_SNAP_ECPHS = 200;
p.PID_3300_BASE_SNAP_CHAN_DATA = 400;
p.PID_3300_BASE_CONF_CHANNELS = 500;
p.PID_3300_BASE_CONF_CHAN_DATA = 600;

p.PID_3300_BASE_AUX_ALARMS = 1024 // 32
p.PID_3300_BASE_AUX_PERSISTENT_VARIABLE = 1056 // 32
p.PID_3300_BASE_AUX_VARIABLE = 1088 // 64
p.PID_3300_BASE_EXPANSION_BOARDS = 1152 // 64
// p.PID_3300_BASE_EXPANSION_BOARDS = 1216
p.PID_3300_BASE_CONFIG_AUX_ALARMS = 1216 // 4 * 32
p.PID_3300_BASE_CONFIG_AUX_CONTROLS = 1344 // 17 * 64 no! 128!
//p.PID_3300_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 3520 // 4 * 32
p.PID_3300_BASE_CONFIG_AUX_VARIABLES = 3640 // 4 * 64
p.PID_3300_BASE_CONFIG_EXPANSION_BOARDS = 3896 // 5 * 64
p.PID_3300_BASE_CONFIG_CONTROLLER_SETTINGS = 4216 // 145 * 2, 170, now 157*2+4216=4530, next is PID_3300_BASE_CONFIG_ECPH at 4556


p.PID_3300_BASE_CONFIG_ECPH = 4556 // 11
p.PID_3300_BASE_CONFIG_ECPH_SENSORS = 4567 // 3 * 23 = 69
// zone wide
p.PID_3300_BASE_CONFIG_SETPOINTS = 4636 // 8 * 10
p.PID_3300_BASE_CONFIG_ZONE_SETTINGS = 4716 // 153 * 2!
p.PID_3300_BASE_CONFIG_ZONES = 5022 // 2
p.PID_3300_BASE_CONFIG_CONTROLLERS = 5024 // 4
// site wide
p.PID_3300_BASE_CONFIG_COMM_STAT = 5028 // 3
p.PID_3300_BASE_CONFIG_TIME = 5031 // 1 - current time at the server

p.PID_3300_ZONE_NAME = 5022;
p.PID_3300_ZONE_DESCRIPTION = 5023;
p.PID_3300_CONTROLLER_VERSION = 5025;
p.PID_3300_BASE_CONFIG_TIME = 5031 // 1 - current time at the server
p.PID_3300_BASE_CONFIG_AUX_PERSISTENT_VARIABLES = 5040 // 4 * 32
p.PID_3300_BASE_CONFIG_ANNEX_SENSORS = 5200 // 20*100
p.PID_3300_BASE_IN_CONFIG=10500 // 50 * 128 = 6400


pb[3300]={}
pb[3300].snapshots = 0 // 1-81
pb[3300].snapshot_channels = 100 // 101-120
pb[3300].snapshot_ecphs = 200 // 201-235
pb[3300].snapshot_chan_Snapshot = 400 // 401-448
pb[3300].channels_configuration = 500 // 501-520
pb[3300].config_channels_configuration = 600 // 600-796
pb[3300].snapshot_aux_alarms = p.PID_3300_BASE_AUX_ALARMS; // 1024
pb[3300].snapshot_aux_persistent_variables = p.PID_3300_BASE_AUX_PERSISTENT_VARIABLE;//1056
pb[3300].snapshot_aux_variables = p.PID_3300_BASE_AUX_VARIABLE;//1088
pb[3300].snapshot_expansion_boards = p.PID_3300_BASE_EXPANSION_BOARDS;//1152
pb[3300].config_aux_alarms = p.PID_3300_BASE_CONFIG_AUX_ALARMS;//1216 4 * 32
pb[3300].config_aux_controls = p.PID_3300_BASE_CONFIG_AUX_CONTROLS;//1344 17 * 128
pb[3300].config_aux_variables = p.PID_3300_BASE_CONFIG_AUX_VARIABLES;//3640 4 * 64
pb[3300].config_expansion_boards = p.PID_3300_BASE_CONFIG_EXPANSION_BOARDS;//3896 5 * 64
pb[3300].controller_configuration_settings = p.PID_3300_BASE_CONFIG_CONTROLLER_SETTINGS;//4216
pb[3300].config_ecph = p.PID_3300_BASE_CONFIG_ECPH;//4506
pb[3300].config_ecph_sensors = p.PID_3300_BASE_CONFIG_ECPH_SENSORS;//4517
pb[3300].config_setpoints = p.PID_3300_BASE_CONFIG_SETPOINTS;//4586
pb[3300].zone_configuration_settings = p.PID_3300_BASE_CONFIG_ZONE_SETTINGS;//4666
pb[3300].config_zones = p.PID_3300_BASE_CONFIG_ZONES;//4972
pb[3300].config_controllers = p.PID_3300_BASE_CONFIG_CONTROLLERS;//4974
pb[3300].config_communication_status = p.PID_3300_BASE_CONFIG_COMM_STAT;//4978
pb[3300].config_aux_persistent_variables = p.PID_3300_BASE_CONFIG_AUX_PERSISTENT_VARIABLES;//3520 4 * 32
pb[3300].config_annex_sensors = p.PID_3300_BASE_CONFIG_ANNEX_SENSORS;//3520 4 * 32
pb[3300].inConfig=p.PID_3300_BASE_IN_CONFIG

pInd[3300]={}

pInd[3300].snapshots = [p.PID_3300_BASE_SNAPSHOTS, 0, 0, 0] // 1-81: base, channel type, index size, index count. channel type 0 = "unit"
pInd[3300].snapshot_channels = [p.PID_3300_BASE_SNAP_CHANNELS, 1, 0, 0] // 101-120 channel type 1 = "channel" - zone, unit, chan
pInd[3300].snapshot_ecphs = [p.PID_3300_BASE_SNAP_ECPHS, 2, 0, 0] // 201-235// ecph channel type - put in 192-199, on ecphIndex
pInd[3300].snapshot_chan_Snapshot = [p.PID_3300_BASE_SNAP_CHAN_DATA, 1, 0, 0] // 401-448 channeldata channel type
pInd[3300].channels_configuration = [p.PID_3300_BASE_CONF_CHANNELS, 1, 0, 0] // 501-520 channel type
pInd[3300].config_channels_configuration = [p.PID_3300_BASE_CONF_CHAN_DATA, 1, 0, 0] // 600-796 channeldata
pInd[3300].snapshot_aux_alarms = [p.PID_3300_BASE_AUX_ALARMS, 0, 1, 32]; // 1024 + 32
pInd[3300].snapshot_aux_persistent_variables = [p.PID_3300_BASE_AUX_PERSISTENT_VARIABLE, 0, 1, 32];//1056 + 32
pInd[3300].snapshot_aux_variables = [p.PID_3300_BASE_AUX_VARIABLE, 0, 1, 64];//1088 + 64
pInd[3300].snapshot_expansion_boards = [p.PID_3300_BASE_EXPANSION_BOARDS, 0, 1, 64];//1152 + 64
pInd[3300].config_aux_alarms = [p.PID_3300_BASE_CONFIG_AUX_ALARMS, 0, 4, 32];//1216 4 * 32
pInd[3300].config_aux_controls = [p.PID_3300_BASE_CONFIG_AUX_CONTROLS, 0, 17, 128];//1344 17 * 128
pInd[3300].config_aux_persistent_variables = [p.PID_3300_BASE_CONFIG_AUX_PERSISTENT_VARIABLES, 0, 4, 32];//3520 4 * 32
pInd[3300].config_aux_variables = [p.PID_3300_BASE_CONFIG_AUX_VARIABLES, 0, 4, 64];//3640 4 * 64
pInd[3300].config_expansion_boards = [p.PID_3300_BASE_CONFIG_EXPANSION_BOARDS, 0, 5, 64];//3896 5 * 64
pInd[3300].controller_configuration_settings = [p.PID_3300_BASE_CONFIG_CONTROLLER_SETTINGS, 7, 2, 170];//4216 170 * 2
// I really don't know what the 11, 8 refers to below!
pInd[3300].config_ecph = [p.PID_3300_BASE_CONFIG_ECPH, 2, 11, 8];//4506 11 * 8 - ERROR!!! this is just 11 values. the 8 times are in the channel
pInd[3300].config_ecph_sensors = [p.PID_3300_BASE_CONFIG_ECPH_SENSORS, 3, 23, 3];//4517 - channel type 3 - ecph double indexed
pInd[3300].config_setpoints = [p.PID_3300_BASE_CONFIG_SETPOINTS, 4, 10, 8];//4586 type 4 = zone wide
pInd[3300].zone_configuration_settings = [p.PID_3300_BASE_CONFIG_ZONE_SETTINGS, 6, 2, 153];//4666 zone *config* type
pInd[3300].config_zones = [p.PID_3300_BASE_CONFIG_ZONES, 4, 0, 0];//4972
pInd[3300].config_controllers = [p.PID_3300_BASE_CONFIG_CONTROLLERS, 0, 0, 0];//4974
pInd[3300].config_communication_status = [p.PID_3300_BASE_CONFIG_COMM_STAT, 5, 0, 0];//4978 type 5 - site wide
pInd[3300].config_annex_sensors = [p.PID_3300_BASE_CONFIG_ANNEX_SENSORS, 0, 20, 100];//5200 // 20*100
pInd[3300].inConfig = [p.PID_3300_BASE_IN_CONFIG, 0, 50, 128];//5200 // 50*128

tableBases2["3300"]=[
  pInd[3300].snapshots,
  pInd[3300].snapshot_channels,
  pInd[3300].snapshot_ecphs,
  pInd[3300].snapshot_chan_Snapshot,
  pInd[3300].channels_configuration,
  pInd[3300].config_channels_configuration,
  pInd[3300].snapshot_aux_alarms,
  pInd[3300].snapshot_aux_persistent_variables,
  pInd[3300].snapshot_aux_variables,
  pInd[3300].snapshot_expansion_boards,
  pInd[3300].config_aux_alarms,
  pInd[3300].config_aux_controls,
  pInd[3300].config_aux_variables,
  pInd[3300].config_expansion_boards,
  pInd[3300].controller_configuration_settings,
  pInd[3300].config_ecph,
  pInd[3300].config_ecph_sensors,
  pInd[3300].config_setpoints,
  pInd[3300].zone_configuration_settings,
  pInd[3300].config_zones,
  pInd[3300].config_controllers,
  pInd[3300].config_communication_status,
  pInd[3300].config_aux_persistent_variables,
  pInd[3300].config_annex_sensors,
  pInd[3300].inConfig,
  [100000]]


// var snapshots = {} // these are in channels 240 + u
pi[3300]={}
pi[3300].snapshots = [];//0, 1-81
pi[3300].snapshot_channels = [];//0, 101-120
pi[3300].snapshot_ecphs = [];//0, 201-235
pi[3300].snapshot_chan_Snapshot = [];//0, 401-448
pi[3300].channels_configuration = [];//0 501-520
pi[3300].config_channels_configuration = [];// 0, 600-796
pi[3300].snapshot_aux_alarms = [];// 1024
pi[3300].snapshot_aux_persistent_variables = [];// 1056
pi[3300].snapshot_aux_variables = [];// 1088
pi[3300].snapshot_expansion_boards = [];// 1152
pi[3300].config_aux_alarms = [];// 1216
pi[3300].config_aux_controls = [];// 1344
pi[3300].config_aux_persistent_variables = [];// 3520
pi[3300].config_aux_variables = [];// 3640
pi[3300].config_expansion_boards = [];// 3896
pi[3300].controller_configuration_settings = [];//4216
pi[3300].config_ecph = [];//4506
pi[3300].config_ecph_sensors = [];//4517
pi[3300].config_setpoints = [];//4586
pi[3300].zone_configuration_settings = [];//4666
pi[3300].config_zones = [];//4972
pi[3300].config_controllers = [];//4974
pi[3300].config_communication_status = [];//4978
pi[3300].config_annex_sensors = [];//4978
pi[3300].inConfig = [];//4978

offsetTables["3300"]=[
  pi[3300].snapshots, pi[3300].snapshot_channels, pi[3300].snapshot_ecphs,
  pi[3300].snapshot_chan_Snapshot, pi[3300].channels_configuration,
  pi[3300].config_channels_configuration, pi[3300].snapshot_aux_alarms,
  pi[3300].snapshot_aux_persistent_variables, pi[3300].snapshot_aux_variables,
  pi[3300].snapshot_expansion_boards, pi[3300].config_aux_alarms,
  pi[3300].config_aux_controls,
  pi[3300].config_aux_variables, pi[3300].config_expansion_boards,
  pi[3300].controller_configuration_settings, pi[3300].config_ecph,
  pi[3300].config_ecph_sensors, pi[3300].config_setpoints,
  pi[3300].zone_configuration_settings, pi[3300].config_zones, pi[3300].config_controllers,
  pi[3300].config_communication_status, pi[3300].config_aux_persistent_variables, 
  pi[3300].config_annex_sensors,pi[3300].inConfig]

pi[3300].snapshots["id"] =	1
pi[3300].snapshots["zoneIndex"] =	2
pi[3300].snapshots["unitIndex"] =	3
pi[3300].snapshots["unix_timestamp(created)"] =	4
pi[3300].snapshots["unix_timestamp(unitTime)"] =	5
pi[3300].snapshots["temperatureStage"] =	6
pi[3300].snapshots["humidityStage"] =	7
pi[3300].snapshots["heatSetpoint"] =	8
pi[3300].snapshots["coolSetpoint"] =	9
pi[3300].snapshots["humidifySetpoint"] =	10
pi[3300].snapshots["dehumidifySetpoint"] =	11
pi[3300].snapshots["accumulator0"] =	12
pi[3300].snapshots["accumulator1"] =	13
pi[3300].snapshots["d2avgTotalAverage"] =	14
pi[3300].snapshots["d2avgDayAverage"] =	15
pi[3300].snapshots["d2avgNightAverage"] =	16
pi[3300].snapshots["d2avgFailDays"] =	17
pi[3300].snapshots["lowAlarm"] =	18
pi[3300].snapshots["highAlarm"] =	19
pi[3300].snapshots["lowInTemperatureAlarm"] =	20
pi[3300].snapshots["highInTemperatureAlarm"] =	21
pi[3300].snapshots["inTemperatureSensorAlarm"] =	22
pi[3300].snapshots["inTemperature"] =	23
pi[3300].snapshots["outTemperature"] =	24
pi[3300].snapshots["localTemperature"] =	25
pi[3300].snapshots["backupTemperature"] =	26
pi[3300].snapshots["inHumidity"] =	27
pi[3300].snapshots["outHumidity"] =	28
pi[3300].snapshots["localHumidity"] =	29
pi[3300].snapshots["differentialPressure"] =	30
pi[3300].snapshots["co2"] =	31
pi[3300].snapshots["inLight"] =	32
pi[3300].snapshots["outLight"] =	33
pi[3300].snapshots["windSpeed"] =	34
pi[3300].snapshots["windDirection"] =	35
pi[3300].snapshots["rain"] =	36
pi[3300].snapshots["snow"] =	37
pi[3300].snapshots["analogTemperature1"] =	38
pi[3300].snapshots["analogTemperature2"] =	39
pi[3300].snapshots["analogTemperature3"] =	40
pi[3300].snapshots["analogTemperature4"] =	41
pi[3300].snapshots["analogTemperature5"] =	42
pi[3300].snapshots["ventPosition1"] =	43
pi[3300].snapshots["ventPosition2"] =	44
pi[3300].snapshots["ventPosition3"] =	45
pi[3300].snapshots["ventPosition4"] =	46
pi[3300].snapshots["ventPosition5"] =	47
pi[3300].snapshots["soilMoisture1"] =	48
pi[3300].snapshots["soilMoisture2"] =	49
pi[3300].snapshots["soilMoisture3"] =	50
pi[3300].snapshots["soilMoisture4"] =	51
pi[3300].snapshots["soilMoisture5"] =	52
pi[3300].snapshots["ecph1"] =	53
pi[3300].snapshots["ecph2"] =	54
pi[3300].snapshots["ecph3"] =	55
pi[3300].snapshots["ecph4"] =	56
pi[3300].snapshots["ecph5"] =	57
pi[3300].snapshots["generic1"] =	58
pi[3300].snapshots["generic2"] =	59
pi[3300].snapshots["heatDemand"] =	60
pi[3300].snapshots["coolDemand"] =	61
pi[3300].snapshots["coolDemandPassive"] =	62
pi[3300].snapshots["auxHeatSetpointStatus"] =	63
pi[3300].snapshots["auxHeatSetpointValue"] =	64
pi[3300].snapshots["auxCoolSetpointStatus"] =	65
pi[3300].snapshots["auxCoolSetpointValue"] =	66
pi[3300].snapshots["auxHumidifySetpointStatus"] =	67
pi[3300].snapshots["auxHumidifySetpointValue"] =	68
pi[3300].snapshots["auxDehumidifySetpointStatus"] =	69
pi[3300].snapshots["auxDehumidifySetpointValue"] =	70
pi[3300].snapshots["auxLowAlarmStatus"] =	71
pi[3300].snapshots["auxLowAlarmValue"] =	72
pi[3300].snapshots["auxHighAlarmStatus"] =	73
pi[3300].snapshots["auxHighAlarmValue"] =	74
pi[3300].snapshots["auxActiveCoolStatus"] =	75
pi[3300].snapshots["auxActiveCoolValue"] =	76
pi[3300].snapshots["auxPassiveCoolStatus"] =	77
pi[3300].snapshots["auxPassiveCoolValue"] =	78
pi[3300].snapshots["accvpd"] =	79
pi[3300].snapshots["outTemperatureSecondary"] =	80
pi[3300].snapshots["barometricPressure"] =   81
pi[3300].snapshots["vpd"] =	82

pi[3300].snapshot_channels["snapshotId"] = 1
pi[3300].snapshot_channels["channelIndex"] = 2
pi[3300].snapshot_channels["channelData"] = 3
pi[3300].snapshot_channels["position"] = 4
pi[3300].snapshot_channels["relay"] = 5
pi[3300].snapshot_channels["channelOverride"] = 6
pi[3300].snapshot_channels["analogOutput"] = 7
pi[3300].snapshot_channels["co2Setpoint"] = 8
pi[3300].snapshot_channels["microzoneSetpoint"] = 9
pi[3300].snapshot_channels["proportionalZoneLowSetpoint"] = 10
pi[3300].snapshot_channels["proportionalZoneHighSetpoint"] = 11
pi[3300].snapshot_channels["pumpPeristalticSetpoint"] = 12
pi[3300].snapshot_channels["pumpPeristalticTankLevel"] = 13
pi[3300].snapshot_channels["pumpPeristalticInjectedMilliliters"] = 14
pi[3300].snapshot_channels["pumpPeristalticInjectedSeconds"] = 15
pi[3300].snapshot_channels["lightDailyLightIntegral"] = 16
pi[3300].snapshot_channels["auxOutputStatus"] = 17
pi[3300].snapshot_channels["auxOutputValue"] = 18
pi[3300].snapshot_channels["auxEquipmentStatus"] = 19
pi[3300].snapshot_channels["auxEquipmentValue"] = 20

//var snapshot_ecphs = {} // these are stored in the ecph
// channels, 192-199
pi[3300].snapshot_ecphs["snapshotId"] = 1
pi[3300].snapshot_ecphs["ecphIndex"] = 2
pi[3300].snapshot_ecphs["ec1"] = 3
pi[3300].snapshot_ecphs["ec2"] = 4
pi[3300].snapshot_ecphs["ec3"] = 5
pi[3300].snapshot_ecphs["ec1SensorAlarm"] = 6
pi[3300].snapshot_ecphs["ec1ServiceAlarm"] = 7
pi[3300].snapshot_ecphs["ec1CalibrationAlarm"] = 8
pi[3300].snapshot_ecphs["ec2SensorAlarm"] = 9
pi[3300].snapshot_ecphs["ec2ServiceAlarm"] = 10
pi[3300].snapshot_ecphs["ec2CalibrationAlarm"] = 11
pi[3300].snapshot_ecphs["ec3SensorAlarm"] = 12
pi[3300].snapshot_ecphs["ec3ServiceAlarm"] = 13
pi[3300].snapshot_ecphs["ec3CalibrationAlarm"] = 14
pi[3300].snapshot_ecphs["ecLowAlarm"] = 15
pi[3300].snapshot_ecphs["ecHighAlarm"] = 16
pi[3300].snapshot_ecphs["ecDeviationAlarm"] = 17
pi[3300].snapshot_ecphs["ph1"] = 18
pi[3300].snapshot_ecphs["ph2"] = 19
pi[3300].snapshot_ecphs["ph3"] = 20
pi[3300].snapshot_ecphs["ph1SensorAlarm"] = 21
pi[3300].snapshot_ecphs["ph1ServiceAlarm"] = 22
pi[3300].snapshot_ecphs["ph1CalibrationAlarm"] = 23
pi[3300].snapshot_ecphs["ph2SensorAlarm"] = 24
pi[3300].snapshot_ecphs["ph2ServiceAlarm"] = 25
pi[3300].snapshot_ecphs["ph2CalibrationAlarm"] = 26
pi[3300].snapshot_ecphs["ph3SensorAlarm"] = 27
pi[3300].snapshot_ecphs["ph3ServiceAlarm"] = 28
pi[3300].snapshot_ecphs["ph3CalibrationAlarm"] = 29
pi[3300].snapshot_ecphs["phLowAlarm"] = 30
pi[3300].snapshot_ecphs["phHighAlarm"] = 31
pi[3300].snapshot_ecphs["phDeviationAlarm"] = 32
pi[3300].snapshot_ecphs["temperature1"] = 33
pi[3300].snapshot_ecphs["temperature2"] = 34
pi[3300].snapshot_ecphs["temperature3"] = 35

//var snapshot_chan_Snapshot = {} // stored in the Channel ID
// these are the values out of ChannelData
pi[3300].snapshot_chan_Snapshot["accumulatedLight"] = 1
pi[3300].snapshot_chan_Snapshot["accumulator"] = 2
pi[3300].snapshot_chan_Snapshot["accvpd"] = 3
pi[3300].snapshot_chan_Snapshot["activatedTimes"] = 4
pi[3300].snapshot_chan_Snapshot["activeCount"] = 5
pi[3300].snapshot_chan_Snapshot["auxControl"] = 6
pi[3300].snapshot_chan_Snapshot["coolDemand"] = 7
pi[3300].snapshot_chan_Snapshot["coolDemandPassive"] = 8
pi[3300].snapshot_chan_Snapshot["currentSetpoint"] = 9
pi[3300].snapshot_chan_Snapshot["cycleOffTimeRemains"] = 10
pi[3300].snapshot_chan_Snapshot["cycleOnTimeRemains"] = 11
pi[3300].snapshot_chan_Snapshot["cycleState"] = 12
pi[3300].snapshot_chan_Snapshot["dailyLightIntegral"] = 13
pi[3300].snapshot_chan_Snapshot["driveToAverage"] = 14
pi[3300].snapshot_chan_Snapshot["ecphs"] = 15
pi[3300].snapshot_chan_Snapshot["equipment"] = 16
pi[3300].snapshot_chan_Snapshot["expansionBoardStatus"] = 17
pi[3300].snapshot_chan_Snapshot["heatDemand"] = 18
pi[3300].snapshot_chan_Snapshot["highAlarm"] = 19
pi[3300].snapshot_chan_Snapshot["highInTemperatureAlarm"] = 20
pi[3300].snapshot_chan_Snapshot["humidityStage"] = 21
pi[3300].snapshot_chan_Snapshot["injectedVolumeMLiters"] = 22
pi[3300].snapshot_chan_Snapshot["injectedVolumeSeconds"] = 23
pi[3300].snapshot_chan_Snapshot["inQueue"] = 24
pi[3300].snapshot_chan_Snapshot["inTemperatureSensorAlarm"] = 25
pi[3300].snapshot_chan_Snapshot["inWindow"] = 26
pi[3300].snapshot_chan_Snapshot["irrigationWeek"] = 27
pi[3300].snapshot_chan_Snapshot["isCycling"] = 28
pi[3300].snapshot_chan_Snapshot["isPulseOn"] = 29
pi[3300].snapshot_chan_Snapshot["lowAlarm"] = 30
pi[3300].snapshot_chan_Snapshot["lowInTemperatureAlarm"] = 31
pi[3300].snapshot_chan_Snapshot["offTime"] = 32
pi[3300].snapshot_chan_Snapshot["originallyOn"] = 33
pi[3300].snapshot_chan_Snapshot["output"] = 34
pi[3300].snapshot_chan_Snapshot["pdTimer"] = 35
pi[3300].snapshot_chan_Snapshot["peristalticState"] = 36
pi[3300].snapshot_chan_Snapshot["sensors"] = 37
pi[3300].snapshot_chan_Snapshot["setpoint"] = 38
pi[3300].snapshot_chan_Snapshot["setpointHigh"] = 39
pi[3300].snapshot_chan_Snapshot["setpointLow"] = 40
pi[3300].snapshot_chan_Snapshot["siteChanged"] = 41
pi[3300].snapshot_chan_Snapshot["tankLevelLiters"] = 42
pi[3300].snapshot_chan_Snapshot["tankSizeLiters"] = 43
pi[3300].snapshot_chan_Snapshot["temperatureStage"] = 44
pi[3300].snapshot_chan_Snapshot["timestamp"] = 45
pi[3300].snapshot_chan_Snapshot["unitTime"] = 46
pi[3300].snapshot_chan_Snapshot["pdTimerState"] = 47
pi[3300].snapshot_chan_Snapshot["week"] = 248

//var channels_configuration = {}
pi[3300].channels_configuration["zoneIndex"] = 1
pi[3300].channels_configuration["unitIndex"] = 2
pi[3300].channels_configuration["channelIndex"] = 3
pi[3300].channels_configuration["unix_timestamp(modified)"] = 4
pi[3300].channels_configuration["used"] = 5
pi[3300].channels_configuration["isAnalog"] = 6
pi[3300].channels_configuration["channelName"] = 7
pi[3300].channels_configuration["channelType"] = 8
pi[3300].channels_configuration["channelData"] = 9
pi[3300].channels_configuration["timedEnabled"] = 10
pi[3300].channels_configuration["timedInterval"] = 11
pi[3300].channels_configuration["timedStartTime"] = 12
pi[3300].channels_configuration["timedEndTime"] = 13
pi[3300].channels_configuration["timedOutput"] = 14
pi[3300].channels_configuration["stageDehumidfy1"] = 15
pi[3300].channels_configuration["stageDehumidfy2"] = 16
pi[3300].channels_configuration["stageColdDehumidfy"] = 17
pi[3300].channels_configuration["stageHumidfy"] = 18
pi[3300].channels_configuration["expansionFailSafeOutput"] = 19
pi[3300].channels_configuration["userComment"] = 20

//var config_channels_configuration = {}
pi[3300].config_channels_configuration["activeCool_cool1"] = 0
pi[3300].config_channels_configuration["activeCool_cool2"] = 1
pi[3300].config_channels_configuration["activeCool_cool3"] = 2
pi[3300].config_channels_configuration["activeCool_cool4"] = 3
pi[3300].config_channels_configuration["activeCool_cool5"] = 4
pi[3300].config_channels_configuration["activeCool_cool6"] = 5
pi[3300].config_channels_configuration["activeCool_heat1"] = 6
pi[3300].config_channels_configuration["activeCool_heat2"] = 7
pi[3300].config_channels_configuration["activeCool_heat3"] = 8
pi[3300].config_channels_configuration["activeCool_heat4"] = 9
pi[3300].config_channels_configuration["activeCool_heat5"] = 10
pi[3300].config_channels_configuration["activeCool_heat6"] = 11
pi[3300].config_channels_configuration["activeCool_normal"] = 12
pi[3300].config_channels_configuration["activeTrigger"] = 13
pi[3300].config_channels_configuration["advanced_close_perc"] = 14
pi[3300].config_channels_configuration["advanced_shock_threshold"] = 15
pi[3300].config_channels_configuration["advanced_stage"] = 16
pi[3300].config_channels_configuration["advanced_time"] = 17
pi[3300].config_channels_configuration["air_cool_setpoint"] = 18
pi[3300].config_channels_configuration["air_heat_setpoint"] = 19
pi[3300].config_channels_configuration["air_temp_sensor"] = 20
pi[3300].config_channels_configuration["analog_max"] = 21
pi[3300].config_channels_configuration["analog_max"] = 22
pi[3300].config_channels_configuration["analog_min"] = 23
pi[3300].config_channels_configuration["cool1"] = 24
pi[3300].config_channels_configuration["cool2"] = 25
pi[3300].config_channels_configuration["cool3"] = 26
pi[3300].config_channels_configuration["cool4"] = 27
pi[3300].config_channels_configuration["cool5"] = 28
pi[3300].config_channels_configuration["cool6"] = 29
pi[3300].config_channels_configuration["cooling_device"] = 30
pi[3300].config_channels_configuration["cycleOffPeriod"] = 31
pi[3300].config_channels_configuration["cycleOnPeriod"] = 32
pi[3300].config_channels_configuration["D"] = 33
pi[3300].config_channels_configuration["daytime_astroadjust"] = 34
pi[3300].config_channels_configuration["daytime_enabled"] = 35
pi[3300].config_channels_configuration["daytime_setpoint"] = 36
pi[3300].config_channels_configuration["daytime_stage"] = 37
pi[3300].config_channels_configuration["daytime_start"] = 38
pi[3300].config_channels_configuration["deadband"] = 39
pi[3300].config_channels_configuration["dir"] = 40
pi[3300].config_channels_configuration["disable_inside_temp_pid"] = 41
pi[3300].config_channels_configuration["dliEndTime"] = 42
pi[3300].config_channels_configuration["dliEnergyOutput"] = 43
pi[3300].config_channels_configuration["dliOnTime"] = 44
pi[3300].config_channels_configuration["dliSensor"] = 45
pi[3300].config_channels_configuration["dliStartTime"] = 46
pi[3300].config_channels_configuration["dliThreshold"] = 47
pi[3300].config_channels_configuration["enableActiveCoolStageOverride"] = 48
pi[3300].config_channels_configuration["enableSmartInject"] = 49
pi[3300].config_channels_configuration["end"] = 50
pi[3300].config_channels_configuration["end_astroadjust"] = 51
pi[3300].config_channels_configuration["end_time"] = 52
pi[3300].config_channels_configuration["energy_end"] = 53
pi[3300].config_channels_configuration["energy_end_astroadjust"] = 54
pi[3300].config_channels_configuration["energy_start"] = 55
pi[3300].config_channels_configuration["energy_start_astroadjust"] = 56
pi[3300].config_channels_configuration["feedingMode"] = 57
pi[3300].config_channels_configuration["fine_adjust_range"] = 58
pi[3300].config_channels_configuration["followChannelIndex"] = 59
pi[3300].config_channels_configuration["followRatio"] = 60
pi[3300].config_channels_configuration["function"] = 61
pi[3300].config_channels_configuration["function_float_max"] = 62
pi[3300].config_channels_configuration["function_float_min"] = 63
pi[3300].config_channels_configuration["function_int_max"] = 64
pi[3300].config_channels_configuration["function_int_min"] = 65
pi[3300].config_channels_configuration["gain"] = 66
pi[3300].config_channels_configuration["heat_on_delay"] = 67
pi[3300].config_channels_configuration["heat1"] = 68
pi[3300].config_channels_configuration["heat2"] = 69
pi[3300].config_channels_configuration["heat3"] = 70
pi[3300].config_channels_configuration["heat4"] = 71
pi[3300].config_channels_configuration["heat5"] = 72
pi[3300].config_channels_configuration["heat6"] = 73
pi[3300].config_channels_configuration["highlight_cover_perc"] = 74
pi[3300].config_channels_configuration["highlight_cover_trigger_light"] = 75
pi[3300].config_channels_configuration["highlight_cover_trigger_temp"] = 76
pi[3300].config_channels_configuration["hold_delay"] = 77
pi[3300].config_channels_configuration["I"] = 78
pi[3300].config_channels_configuration["increased_co2_level"] = 79
pi[3300].config_channels_configuration["injectingBatchTimeSeconds"] = 80
pi[3300].config_channels_configuration["injectingBatchVolumeMilliLiters"] = 81
pi[3300].config_channels_configuration["injectingLimitHours"] = 82
pi[3300].config_channels_configuration["injectingLimitMilliLiters"] = 83
pi[3300].config_channels_configuration["injectingLimitSeconds"] = 84
pi[3300].config_channels_configuration["injectingTimeSeconds"] = 85
pi[3300].config_channels_configuration["injectingVolumeMilliLiters"] = 86
pi[3300].config_channels_configuration["injectionRate"] = 87
pi[3300].config_channels_configuration["inside_temp_d"] = 88
pi[3300].config_channels_configuration["inside_temp_i"] = 89
pi[3300].config_channels_configuration["inside_temp_p"] = 90
pi[3300].config_channels_configuration["irrigation_mode"] = 91
pi[3300].config_channels_configuration["isCycling"] = 92
pi[3300].config_channels_configuration["keep_open_threshold_light"] = 93
pi[3300].config_channels_configuration["keep_open_threshold_temp"] = 94
// pi[3300].config_channels_configuration["light_drive_to"] = 95
pi[3300].config_channels_configuration["light_level"] = 96
pi[3300].config_channels_configuration["light_mode"] = 97
pi[3300].config_channels_configuration["light_sensor_enabled"] = 98
// pi[3300].config_channels_configuration["light_threshold"] = 99
pi[3300].config_channels_configuration["low_light_threshold_duration"] = 100
pi[3300].config_channels_configuration["low_light_threshold_light"] = 101
pi[3300].config_channels_configuration["mixingTimeSeconds"] = 102
pi[3300].config_channels_configuration["mode"] = 103
pi[3300].config_channels_configuration["nighttime_astroadjust"] = 104
pi[3300].config_channels_configuration["nighttime_enabled"] = 105
pi[3300].config_channels_configuration["nighttime_setpoint"] = 106
pi[3300].config_channels_configuration["nighttime_stage"] = 107
pi[3300].config_channels_configuration["nighttime_start"] = 108
pi[3300].config_channels_configuration["normal"] = 109
pi[3300].config_channels_configuration["normal_co2_level"] = 110
pi[3300].config_channels_configuration["off_delay"] = 111
pi[3300].config_channels_configuration["off_duration"] = 112
pi[3300].config_channels_configuration["off_threshold"] = 113
pi[3300].config_channels_configuration["offDelay"] = 114
pi[3300].config_channels_configuration["offset"] = 115
pi[3300].config_channels_configuration["on_duration"] = 116
pi[3300].config_channels_configuration["on_threshold"] = 117
pi[3300].config_channels_configuration["output_type"] = 118
pi[3300].config_channels_configuration["override_action_target"] = 119
pi[3300].config_channels_configuration["override_dir"] = 120
pi[3300].config_channels_configuration["override_limit"] = 121
pi[3300].config_channels_configuration["override_sensor"] = 122
pi[3300].config_channels_configuration["override_trigger"] = 123
pi[3300].config_channels_configuration["P"] = 124
pi[3300].config_channels_configuration["passive_cooling"] = 125
pi[3300].config_channels_configuration["probe_id"] = 126
pi[3300].config_channels_configuration["probeSensorIndex"] = 127
pi[3300].config_channels_configuration["probeType"] = 128
pi[3300].config_channels_configuration["pulse_ff_time"] = 129
pi[3300].config_channels_configuration["pulse_on_time"] = 130
pi[3300].config_channels_configuration["pump_mzone"] = 131
pi[3300].config_channels_configuration["pump_onoff"] = 348
pi[3300].config_channels_configuration["pump_id"] = 132
pi[3300].config_channels_configuration["pump_type"] = 133
pi[3300].config_channels_configuration["pumpMeasurementUnits"] = 134
pi[3300].config_channels_configuration["resetInjectionTracker"] = 135
pi[3300].config_channels_configuration["sensor_mzone"] = 136
// pi[3300].config_channels_configuration["sensorHoldTime"] = 137
pi[3300].config_channels_configuration["setpoint"] = 138
pi[3300].config_channels_configuration["setting_1_threshold_light"] = 139
pi[3300].config_channels_configuration["setting_1_threshold_temp"] = 140
pi[3300].config_channels_configuration["setting_2_threshold_light"] = 141
pi[3300].config_channels_configuration["setting_2_threshold_temp"] = 142
pi[3300].config_channels_configuration["shade_end"] = 143
pi[3300].config_channels_configuration["shade_end_astroadjust"] = 144
pi[3300].config_channels_configuration["shade_start"] = 145
pi[3300].config_channels_configuration["shade_start_astroadjust"] = 146
pi[3300].config_channels_configuration["shock_protect_enabled"] = 147
pi[3300].config_channels_configuration["shock_protect_open_valve_perc"] = 148
pi[3300].config_channels_configuration["shock_protect_threshold"] = 149
pi[3300].config_channels_configuration["shock_protect_time_closed"] = 150
pi[3300].config_channels_configuration["shock_protect_water_sensor"] = 151
pi[3300].config_channels_configuration["soil_moisture_input"] = 152
pi[3300].config_channels_configuration["soil_moisture_threshold"] = 153
pi[3300].config_channels_configuration["stage"] = 154
pi[3300].config_channels_configuration["start"] = 155
pi[3300].config_channels_configuration["start_astroadjust"] = 156
pi[3300].config_channels_configuration["start_time"] = 157
pi[3300].config_channels_configuration["start_time_1"] = 158
pi[3300].config_channels_configuration["start_time_2"] = 159
pi[3300].config_channels_configuration["start_time_3"] = 160
pi[3300].config_channels_configuration["start_time_4"] = 161
pi[3300].config_channels_configuration["start_time_5"] = 162
pi[3300].config_channels_configuration["start_time_6"] = 163
pi[3300].config_channels_configuration["supply_pump"] = 164
pi[3300].config_channels_configuration["tankSensor"] = 165// obsolete! maybe used for irr tankSensor
pi[3300].config_channels_configuration["tankSize"] = 166
// pi[3300].config_channels_configuration["temp_drive_to"] = 167
// pi[3300].config_channels_configuration["temp_sensor"] = 168
// pi[3300].config_channels_configuration["temp_threshold"] = 169
pi[3300].config_channels_configuration["threshold"] = 170
pi[3300].config_channels_configuration["time"] = 171
pi[3300].config_channels_configuration["triggerDelay"] = 172
pi[3300].config_channels_configuration["valve_open_time"] = 173
pi[3300].config_channels_configuration["vpdacc_threshold"] = 174
pi[3300].config_channels_configuration["water_temp_d"] = 175
pi[3300].config_channels_configuration["water_temp_i"] = 176
pi[3300].config_channels_configuration["water_temp_max"] = 177
pi[3300].config_channels_configuration["water_temp_min"] = 178
pi[3300].config_channels_configuration["water_temp_p"] = 179
pi[3300].config_channels_configuration["water_temp_sensor"] = 180
pi[3300].config_channels_configuration["week_a_fri"] = 181
pi[3300].config_channels_configuration["week_a_mon"] = 182
pi[3300].config_channels_configuration["week_a_sat"] = 183
pi[3300].config_channels_configuration["week_a_sun"] = 184
pi[3300].config_channels_configuration["week_a_thu"] = 185
pi[3300].config_channels_configuration["week_a_tue"] = 186
pi[3300].config_channels_configuration["week_a_wed"] = 187
pi[3300].config_channels_configuration["week_b_fri"] = 188
pi[3300].config_channels_configuration["week_b_mon"] = 189
pi[3300].config_channels_configuration["week_b_sat"] = 190
pi[3300].config_channels_configuration["week_b_sun"] = 191
pi[3300].config_channels_configuration["week_b_thu"] = 192
pi[3300].config_channels_configuration["week_b_tue"] = 193
pi[3300].config_channels_configuration["week_b_wed"] = 194
//added
pi[3300].config_channels_configuration["triggers"] = 195
// pi[3300].config_channels_configuration["smartInject"] = 196
// pi[3300].config_channels_configuration["vent_mode"] = 197// should be vent_type!

// pi[3300].config_channels_configuration["P"] = 198
// pi[3300].config_channels_configuration["I"] = 199
// pi[3300].config_channels_configuration["D"] = 200
pi[3300].config_channels_configuration["acDeadband"] =	201
pi[3300].config_channels_configuration["acDoDelay"] =	202
pi[3300].config_channels_configuration["acPcnt_1"] =	203
pi[3300].config_channels_configuration["acPcnt_2"] =	204
pi[3300].config_channels_configuration["acPcnt_3"] =	205
pi[3300].config_channels_configuration["acPcnt_4"] =	206
pi[3300].config_channels_configuration["acTemp_1"] =	207
pi[3300].config_channels_configuration["acTemp_2"] =	208
pi[3300].config_channels_configuration["acTemp_3"] =	209
pi[3300].config_channels_configuration["acTemp_4"] =	210
pi[3300].config_channels_configuration["acUpDelay"] =	211
pi[3300].config_channels_configuration["autoCalibration"] =	212
pi[3300].config_channels_configuration["begin_closing_leeward_trigger_end"] =	213
pi[3300].config_channels_configuration["begin_closing_leeward_trigger_start"] =	214
pi[3300].config_channels_configuration["begin_closing_windward_trigger_end"] =	215
pi[3300].config_channels_configuration["begin_closing_windward_trigger_start"] =	216
pi[3300].config_channels_configuration["closing_delay"] =	217
pi[3300].config_channels_configuration["cool_1"] =	218// duplicates NO! these are used for the vents
pi[3300].config_channels_configuration["cool_2"] =	219
pi[3300].config_channels_configuration["cool_3"] =	220
pi[3300].config_channels_configuration["cool_4"] =	221
pi[3300].config_channels_configuration["cool_5"] =	222
pi[3300].config_channels_configuration["cool_6"] =	223
pi[3300].config_channels_configuration["cp2Delay"] =	224
pi[3300].config_channels_configuration["cp2Pos"] =	225
pi[3300].config_channels_configuration["cp2Wind"] =	226
pi[3300].config_channels_configuration["cp3Delay"] =	227
pi[3300].config_channels_configuration["cp3Pos"] =	228
pi[3300].config_channels_configuration["cp3Wind"] =	229
pi[3300].config_channels_configuration["cpDelay"] =	230
pi[3300].config_channels_configuration["cpPos"] =	231
pi[3300].config_channels_configuration["cpWind"] =	232
pi[3300].config_channels_configuration["direction"] =	233
pi[3300].config_channels_configuration["fireDelay"] =	234
pi[3300].config_channels_configuration["fireIn"] =	235
pi[3300].config_channels_configuration["firePos"] =	236
pi[3300].config_channels_configuration["hailDelay"] =	237
pi[3300].config_channels_configuration["hailPos"] =	238
pi[3300].config_channels_configuration["hailThresh"] =	239
pi[3300].config_channels_configuration["hgDeadband"] =	240
pi[3300].config_channels_configuration["hgDoDelay"] =	241
pi[3300].config_channels_configuration["hgPcnt_1"] =	242
pi[3300].config_channels_configuration["hgPcnt_2"] =	243
pi[3300].config_channels_configuration["hgPcnt_3"] =	244
pi[3300].config_channels_configuration["hgPcnt_4"] =	245
pi[3300].config_channels_configuration["hgTemp_1"] =	246
pi[3300].config_channels_configuration["hgTemp_2"] =	247
pi[3300].config_channels_configuration["hgTemp_3"] =	248
pi[3300].config_channels_configuration["hgTemp_4"] =	249
pi[3300].config_channels_configuration["hgUpDelay"] =	250
pi[3300].config_channels_configuration["max_open"] =	251
pi[3300].config_channels_configuration["max_open_rain"] =	252
pi[3300].config_channels_configuration["open_var"] =	253
pi[3300].config_channels_configuration["rhgDeadband"] =	254
pi[3300].config_channels_configuration["rhgDoDelay"] =	255
pi[3300].config_channels_configuration["rhgTemp_1"] =	256
pi[3300].config_channels_configuration["rhgTemp_2"] =	257
pi[3300].config_channels_configuration["rhgTemp_3"] =	258
pi[3300].config_channels_configuration["rhgTemp_4"] =	259
pi[3300].config_channels_configuration["rhgUpDelay"] =	260
pi[3300].config_channels_configuration["rtDeadband"] =	261
pi[3300].config_channels_configuration["rtDoDelay"] =	262
pi[3300].config_channels_configuration["rtHum_1"] =	263
pi[3300].config_channels_configuration["rtHum_2"] =	264
pi[3300].config_channels_configuration["rtHum_3"] =	265
pi[3300].config_channels_configuration["rtHum_4"] =	266
pi[3300].config_channels_configuration["rtPcnt_1"] =	267
pi[3300].config_channels_configuration["rtPcnt_2"] =	268
pi[3300].config_channels_configuration["rtPcnt_3"] =	269
pi[3300].config_channels_configuration["rtPcnt_4"] =	270
pi[3300].config_channels_configuration["rtUpDelay"] =	271
pi[3300].config_channels_configuration["spDelay"] =	272
pi[3300].config_channels_configuration["spPos"] =	273
pi[3300].config_channels_configuration["spWind"] =	274
pi[3300].config_channels_configuration["temp_below_trigger"] =	275
pi[3300].config_channels_configuration["temp_var"] =	276
pi[3300].config_channels_configuration["rhgPcnt_1"] =	277
pi[3300].config_channels_configuration["rhgPcnt_2"] =	278
pi[3300].config_channels_configuration["rhgPcnt_3"] =	279
pi[3300].config_channels_configuration["rhgPcnt_4"] =	280
pi[3300].config_channels_configuration["vent_type"] =	281
pi[3300].config_channels_configuration["vps_alarm_duration"] =	282
pi[3300].config_channels_configuration["vps_alarm_limit"] =	283
pi[3300].config_channels_configuration["vps_error_exception_limit"] =	284
pi[3300].config_channels_configuration["vps_sensor"] =	285

pi[3300].config_channels_configuration["CD_start_astroadjust_co2"] = 286
pi[3300].config_channels_configuration["CD_start_astroadjust_hid"] = 287
pi[3300].config_channels_configuration["CD_end_astroadjust_co2"] = 288
pi[3300].config_channels_configuration["CD_end_astroadjust_hid"] = 289
pi[3300].config_channels_configuration["CD_start_hid"] = 290
pi[3300].config_channels_configuration["CD_end_hid"] = 291
pi[3300].config_channels_configuration["CD_tankSensor_fval"] = 292
pi[3300].config_channels_configuration["CD_time_generic"] = 293
pi[3300].config_channels_configuration["CD_acPcnt_1_cvent"] = 294
pi[3300].config_channels_configuration["CD_acPcnt_2_cvent"] = 295
pi[3300].config_channels_configuration["CD_acPcnt_3_cvent"] = 296
pi[3300].config_channels_configuration["CD_acPcnt_4_cvent"] = 297
pi[3300].config_channels_configuration["CD_acTemp_1_cvent"] = 298
pi[3300].config_channels_configuration["CD_acTemp_2_cvent"] = 299
pi[3300].config_channels_configuration["CD_acTemp_3_cvent"] = 300
pi[3300].config_channels_configuration["CD_acTemp_4_cvent"] = 301
pi[3300].config_channels_configuration["CD_hgPcnt_1_cvent"] = 302
pi[3300].config_channels_configuration["CD_hgPcnt_2_cvent"] = 303
pi[3300].config_channels_configuration["CD_hgPcnt_3_cvent"] = 304
pi[3300].config_channels_configuration["CD_hgPcnt_4_cvent"] = 305
pi[3300].config_channels_configuration["CD_hgTemp_1_cvent"] = 306
pi[3300].config_channels_configuration["CD_hgTemp_2_cvent"] = 307
pi[3300].config_channels_configuration["CD_hgTemp_3_cvent"] = 308
pi[3300].config_channels_configuration["CD_hgTemp_4_cvent"] = 309
pi[3300].config_channels_configuration["CD_rhgTemp_1_cvent"] = 310
pi[3300].config_channels_configuration["CD_rhgTemp_2_cvent"] = 311
pi[3300].config_channels_configuration["CD_rhgTemp_3_cvent"] = 312
pi[3300].config_channels_configuration["CD_rhgTemp_4_cvent"] = 313
pi[3300].config_channels_configuration["CD_rtHum_1_cvent"] = 314
pi[3300].config_channels_configuration["CD_rtHum_2_cvent"] = 315
pi[3300].config_channels_configuration["CD_rtHum_3_cvent"] = 316
pi[3300].config_channels_configuration["CD_rtHum_4_cvent"] = 317
pi[3300].config_channels_configuration["CD_rtPcnt_1_cvent"] = 318
pi[3300].config_channels_configuration["CD_rtPcnt_2_cvent"] = 319
pi[3300].config_channels_configuration["CD_rtPcnt_3_cvent"] = 320
pi[3300].config_channels_configuration["CD_rtPcnt_4_cvent"] = 321
pi[3300].config_channels_configuration["CD_rhgPcnt_1_cvent"] = 322
pi[3300].config_channels_configuration["CD_rhgPcnt_2_cvent"] = 323
pi[3300].config_channels_configuration["CD_rhgPcnt_3_cvent"] = 324
pi[3300].config_channels_configuration["CD_rhgPcnt_4_cvent"] = 325
pi[3300].config_channels_configuration["CD_start_time_1_hid"] = 326
pi[3300].config_channels_configuration["CD_start_time_2_hid"] = 327
pi[3300].config_channels_configuration["CD_start_time_3_hid"] = 328
pi[3300].config_channels_configuration["CD_start_time_4_hid"] = 329
pi[3300].config_channels_configuration["CD_start_time_5_hid"] = 330
pi[3300].config_channels_configuration["CD_start_time_6_hid"] = 331
pi[3300].config_channels_configuration["CD_week_a_fri_hid"] = 332
pi[3300].config_channels_configuration["CD_week_a_mon_hid"] = 333
pi[3300].config_channels_configuration["CD_week_a_sat_hid"] = 334
pi[3300].config_channels_configuration["CD_week_a_sun_hid"] = 335
pi[3300].config_channels_configuration["CD_week_a_thu_hid"] = 336
pi[3300].config_channels_configuration["CD_week_a_tue_hid"] = 337
pi[3300].config_channels_configuration["CD_week_a_wed_hid"] = 338
pi[3300].config_channels_configuration["CD_week_b_fri_hid"] = 339
pi[3300].config_channels_configuration["CD_week_b_mon_hid"] = 340
pi[3300].config_channels_configuration["CD_week_b_sat_hid"] = 341
pi[3300].config_channels_configuration["CD_week_b_sun_hid"] = 342
pi[3300].config_channels_configuration["CD_week_b_thu_hid"] = 343
pi[3300].config_channels_configuration["CD_week_b_tue_hid"] = 344
pi[3300].config_channels_configuration["CD_week_b_wed_hid"] = 345
pi[3300].config_channels_configuration["CD_sensor_generic"] = 346
pi[3300].config_channels_configuration["CD_mode_varout"] = 347
pi[3300].config_channels_configuration["CD_pump_onoff"] = 348
pi[3300].config_channels_configuration["CD_supply_pump_generic"] = 349
pi[3300].config_channels_configuration["CD_analog_min_varout"] = 350
pi[3300].config_channels_configuration["CD_analog_min_generic"] = 351
pi[3300].config_channels_configuration["CD_analog_max_varout"] = 352
pi[3300].config_channels_configuration["CD_analog_max_generic"] = 353
pi[3300].config_channels_configuration["CD_setpoint_generic"] = 354
pi[3300].config_channels_configuration["CD_activeTrigger_fval"] = 355

pi[3300].config_channels_configuration["CD_activeCool_cool1_onoff"] = 356// of On/Off +600
pi[3300].config_channels_configuration["CD_activeCool_cool2_onoff"] = 357
pi[3300].config_channels_configuration["CD_activeCool_cool3_onoff"] = 358
pi[3300].config_channels_configuration["CD_activeCool_cool4_onoff"] = 359
pi[3300].config_channels_configuration["CD_activeCool_cool5_onoff"] = 360
pi[3300].config_channels_configuration["CD_activeCool_cool6_onoff"] = 361
pi[3300].config_channels_configuration["CD_activeCool_normal_onoff"] = 362
pi[3300].config_channels_configuration["CD_heat6_onoff"] = 363
pi[3300].config_channels_configuration["CD_heat5_onoff"] = 364
pi[3300].config_channels_configuration["CD_heat4_onoff"] = 365
pi[3300].config_channels_configuration["CD_heat3_onoff"] = 366
pi[3300].config_channels_configuration["CD_heat2_onoff"] = 367
pi[3300].config_channels_configuration["CD_heat1_onoff"] = 368
pi[3300].config_channels_configuration["CD_normal_onoff"] = 369
pi[3300].config_channels_configuration["CD_cool1_onoff"] = 370
pi[3300].config_channels_configuration["CD_cool2_onoff"] = 371
pi[3300].config_channels_configuration["CD_cool3_onoff"] = 372
pi[3300].config_channels_configuration["CD_cool4_onoff"] = 373
pi[3300].config_channels_configuration["CD_cool5_onoff"] = 374
pi[3300].config_channels_configuration["CD_cool6_onoff"] = 375

pi[3300].config_channels_configuration["CD_sensorHoldTime"] = 376
pi[3300].config_channels_configuration["CD_light_threshold"] = 377
pi[3300].config_channels_configuration["CD_light_drive_to"] = 378
pi[3300].config_channels_configuration["CD_temp_sensor"] = 379
pi[3300].config_channels_configuration["CD_temp_threshold"] = 380
pi[3300].config_channels_configuration["CD_temp_drive_to"] = 381
pi[3300].config_channels_configuration["CD_enableSmartInject"] = 382

pi[3300].config_channels_configuration["CD_trigStartType_fval"] = 387// triggers
pi[3300].config_channels_configuration["CD_trigStartIDX_fval"] = 388
pi[3300].config_channels_configuration["CD_trigStopType_fval"] = 389
pi[3300].config_channels_configuration["CD_trigStopIDX_fval"] = 390
pi[3300].config_channels_configuration["CD_time_sec_fval"] = 391
pi[3300].config_channels_configuration["CD_volume_fval"] = 392

pi[3300].config_channels_configuration["CD_trigStartType_pump"] = 405
pi[3300].config_channels_configuration["CD_trigStartIDX_pump"] = 406
pi[3300].config_channels_configuration["CD_batchVolume_pump"] = 407

pi[3300].config_channels_configuration["CD_minDifferencex10_pump"] = 414
pi[3300].config_channels_configuration["CD_ratiox100_pump"] = 415

pi[3300].snapshot_aux_alarms["alarmValue"] = 0;;// 1024
pi[3300].snapshot_aux_persistent_variables["variableValue"] = 0;;// 1056
pi[3300].snapshot_aux_variables["variableValue"] = 0;;// 1056
pi[3300].snapshot_expansion_boards["boardStatus"] = 0;;// 1056




//var config_aux_alarms = {}
pi[3300].config_aux_alarms["alarmIndex"] = 0
pi[3300].config_aux_alarms["alarmName"] = 1
pi[3300].config_aux_alarms["allowGraphing"] = 2
pi[3300].config_aux_alarms["userComment"] = 3

//var config_aux_controls = {}
pi[3300].config_aux_controls["auxIndex"] = 0
pi[3300].config_aux_controls["unix_timestamp(modified)"] = 1
pi[3300].config_aux_controls["operand1Type"] = 2
pi[3300].config_aux_controls["operand1Value"] = 3
pi[3300].config_aux_controls["operand2Type"] = 4
pi[3300].config_aux_controls["operand2Value"] = 5
pi[3300].config_aux_controls["operand3Type"] = 6
pi[3300].config_aux_controls["operand3Value"] = 7
pi[3300].config_aux_controls["operator1"] = 8
pi[3300].config_aux_controls["operator2"] = 9
pi[3300].config_aux_controls["conditionSeconds"] = 10
pi[3300].config_aux_controls["action"] = 11
pi[3300].config_aux_controls["targetType"] = 12
pi[3300].config_aux_controls["targetValue"] = 13
pi[3300].config_aux_controls["actionParameter"] = 14
pi[3300].config_aux_controls["actionHoldTime"] = 15
pi[3300].config_aux_controls["userComment"] = 16

//var config_aux_persistent_variables = {}
// config_aux_persistent_variables["zoneIndex"] = 827
// config_aux_persistent_variables["unitIndex"] = 828
pi[3300].config_aux_persistent_variables["variableIndex"] = 0
pi[3300].config_aux_persistent_variables["variableName"] = 1
pi[3300].config_aux_persistent_variables["allowGraphing"] = 2
pi[3300].config_aux_persistent_variables["userComment"] = 3

pi[3300].config_annex_sensors["alarmEnable"] = 0
pi[3300].config_annex_sensors["alarmLow"] = 1
pi[3300].config_annex_sensors["alarmHigh"] = 2
pi[3300].config_annex_sensors["alarmDeadband"] = 3
pi[3300].config_annex_sensors["alarmDelay"] = 4
pi[3300].config_annex_sensors["alarmLevel"] = 5
pi[3300].config_annex_sensors["notifyWhom"] = 6
pi[3300].config_annex_sensors["notifyHowOften"] = 7
pi[3300].config_annex_sensors["notifyPerDay"] = 8
pi[3300].config_annex_sensors["notifySnoozeEnable"] = 9
pi[3300].config_annex_sensors["notifySnooze"] = 10// 20 slots reseverved
pi[3300].config_annex_sensors["alarmState"] = 11
pi[3300].config_annex_sensors["notifyTime"] = 12
pi[3300].config_annex_sensors["notifyCount"] = 13

//var config_aux_variables = {}
// axxx["zoneIndex"] = 834
// axxx["unitIndex"] = 835
pi[3300].config_aux_variables["variableIndex"] = 0
pi[3300].config_aux_variables["variableName"] = 1
pi[3300].config_aux_variables["allowGraphing"] = 2
pi[3300].config_aux_variables["userComment"] = 3

//var config_expansion_boards = {}
// zxx["zoneIndex"] =
// zxx["unitIndex"] =
pi[3300].config_expansion_boards["boardIndex"] = 0 // 64
pi[3300].config_expansion_boards["unix_timestamp(modified)"] = 1
pi[3300].config_expansion_boards["boardType"] = 2
pi[3300].config_expansion_boards["address"] = 3
pi[3300].config_expansion_boards["startChannelIndex"] = 4

//var config_setpoints = {}
// zxx["zoneIndex"] =
// pi[3300].config_setpoints["setpointIndex"] = 0 // 8 * 10// can be used for setpointName
pi[3300].config_setpoints["name"] = 0 // 8 * 10// can be used for setpointName
pi[3300].config_setpoints["unix_timestamp(modified)"] = 1
pi[3300].config_setpoints["enabled"] = 2
pi[3300].config_setpoints["startTimeOfDay"] = 3
pi[3300].config_setpoints["astroAdjust"] = 4
pi[3300].config_setpoints["rampMinutes"] = 5
pi[3300].config_setpoints["heatSetpoint"] = 6
pi[3300].config_setpoints["coolSetpoint"] = 7
pi[3300].config_setpoints["humidifySetpoint"] = 8
pi[3300].config_setpoints["dehumidifySetpoint"] = 9

//var config_ecph = {}
// zzz["zoneIndex"] =
// zzz["unitIndex"] =
pi[3300].config_ecph["ecphIndex"] = 0 // 8 * 11 of these
pi[3300].config_ecph["unix_timestamp(modified)"] = 1
pi[3300].config_ecph["name"] = 2
pi[3300].config_ecph["ecType"] = 3
pi[3300].config_ecph["alarmHoldTime"] = 4
pi[3300].config_ecph["lowECThreshold"] = 5
pi[3300].config_ecph["highECThreshold"] = 6
pi[3300].config_ecph["highECDeviationThreshold"] = 7
pi[3300].config_ecph["lowPHThreshold"] = 8
pi[3300].config_ecph["highPHThreshold"] = 9
pi[3300].config_ecph["highPHDeviationThreshold"] = 10

//var config_ecph_sensors = {}
// config_ecph_sensors["zoneIndex"] =
// config_ecph_sensors["unitIndex"] =
// config_ecph_sensors["ecphIndex"] =
// config_ecph_sensors["sensorIndex"] =
pi[3300].config_ecph_sensors["(modified)"] = 0
pi[3300].config_ecph_sensors["ecMapping"] = 1
pi[3300].config_ecph_sensors["ecServiceIntervalDays"] = 2
pi[3300].config_ecph_sensors["ecServiceTime"] = 3
pi[3300].config_ecph_sensors["ecCalibrationIntervalDays"] = 4
pi[3300].config_ecph_sensors["ecCalibrationTime"] = 5
pi[3300].config_ecph_sensors["ecCalibration1Value"] = 6
pi[3300].config_ecph_sensors["ecCalibration1Raw"] = 7
pi[3300].config_ecph_sensors["ecCalibration2Value"] = 8
pi[3300].config_ecph_sensors["ecCalibration2Raw"] = 9
pi[3300].config_ecph_sensors["phMapping"] = 10
pi[3300].config_ecph_sensors["phServiceIntervalDays"] = 11
pi[3300].config_ecph_sensors["phServiceTime"] = 12
pi[3300].config_ecph_sensors["phCalibrationIntervalDays"] = 13
pi[3300].config_ecph_sensors["phCalibrationTime"] = 14
pi[3300].config_ecph_sensors["phCalibration1Value"] = 15
pi[3300].config_ecph_sensors["phCalibration1Raw"] = 16
pi[3300].config_ecph_sensors["phCalibration2Value"] = 17
pi[3300].config_ecph_sensors["phCalibration2Raw"] = 18
pi[3300].config_ecph_sensors["temperatureMapping"] = 19
pi[3300].config_ecph_sensors["temperatureCalibration"] = 20
pi[3300].config_ecph_sensors["temperatureCompensationMode"] = 21
pi[3300].config_ecph_sensors["temperatureCompensationValue"] = 22

//var config_zones = {}
// config_zones["zoneIndex"] =
pi[3300].config_zones["name"] = 0
pi[3300].config_zones["description"] = 1

//var config_controllers = {}
// config_controllers["zoneIndex"] =
// config_controllers["unitIndex"] =
pi[3300].config_controllers["address"] = 0
pi[3300].config_controllers["igrowVersion"] = 1
pi[3300].config_controllers["isConnected"] = 2
pi[3300].config_controllers["isInNetwork"] = 3

//var config_communication_status = {}
pi[3300].config_communication_status["statusID"] = 0
pi[3300].config_communication_status["unix_timestamp(statusTime)"] = 1
pi[3300].config_communication_status["statusLog"] = 2

//var controller_configuration_settings = {}// 20210706, these are getting the *2+1 treatment - Probably Not!
pi[3300].controller_configuration_settings["Inside Temperature Mapping"] = 1
pi[3300].controller_configuration_settings["Relative Humidity Mapping"] = 2
pi[3300].controller_configuration_settings["Local Temperature Mapping"] = 3
pi[3300].controller_configuration_settings["Local Humidity Mapping"] = 4
pi[3300].controller_configuration_settings["Outside Temperature Mapping"] = 5
pi[3300].controller_configuration_settings["Outside Humidity Mapping"] = 6
pi[3300].controller_configuration_settings["CO2 Mapping"] = 7
pi[3300].controller_configuration_settings["Outside Light Mapping"] = 8
pi[3300].controller_configuration_settings["Wind Speed Mapping"] = 9
pi[3300].controller_configuration_settings["Wind Direction Mapping"] = 10
pi[3300].controller_configuration_settings["Rain Mapping"] = 11
pi[3300].controller_configuration_settings["Analog Temperature 1 Mapping"] = 12
pi[3300].controller_configuration_settings["Analog Temperature 2 Mapping"] = 13
pi[3300].controller_configuration_settings["Analog Temperature 3 Mapping"] = 14
pi[3300].controller_configuration_settings["Analog Temperature 4 Mapping"] = 15
pi[3300].controller_configuration_settings["Analog Temperature 5 Mapping"] = 16
pi[3300].controller_configuration_settings["Soil Moisture 1 Mapping"] = 17
pi[3300].controller_configuration_settings["Soil Moisture 2 Mapping"] = 18
pi[3300].controller_configuration_settings["Soil Moisture 3 Mapping"] = 19
pi[3300].controller_configuration_settings["Soil Moisture 4 Mapping"] = 20
pi[3300].controller_configuration_settings["Soil Moisture 5 Mapping"] = 21
pi[3300].controller_configuration_settings["Vent Position Sensor 1 Mapping"] = 22
pi[3300].controller_configuration_settings["Vent Position Sensor 2 Mapping"] = 23
pi[3300].controller_configuration_settings["Vent Position Sensor 3 Mapping"] = 24
pi[3300].controller_configuration_settings["Vent Position Sensor 4 Mapping"] = 25
pi[3300].controller_configuration_settings["Vent Position Sensor 5 Mapping"] = 26
pi[3300].controller_configuration_settings["EC/pH 1 Mapping"] = 27
pi[3300].controller_configuration_settings["EC/pH 2 Mapping"] = 28
pi[3300].controller_configuration_settings["EC/pH 3 Mapping"] = 29
pi[3300].controller_configuration_settings["EC/pH 4 Mapping"] = 30
pi[3300].controller_configuration_settings["EC/pH 5 Mapping"] = 31
pi[3300].controller_configuration_settings["EC/pH 1 Probe Type"] = 32
pi[3300].controller_configuration_settings["EC/pH 2 Probe Type"] = 33
pi[3300].controller_configuration_settings["EC/pH 3 Probe Type"] = 34
pi[3300].controller_configuration_settings["EC/pH 4 Probe Type"] = 35
pi[3300].controller_configuration_settings["EC/pH 5 Probe Type"] = 36
pi[3300].controller_configuration_settings["Generic 1 Mapping"] = 37
pi[3300].controller_configuration_settings["Generic 2 Mapping"] = 38
pi[3300].controller_configuration_settings["Inside Temperature Calibration"] = 39
pi[3300].controller_configuration_settings["Relative Humidity Calibration"] = 40
pi[3300].controller_configuration_settings["Outside Temperature Calibration"] = 41
pi[3300].controller_configuration_settings["Outside Humidity Calibration"] = 42
pi[3300].controller_configuration_settings["CO2 Calibration"] = 43
pi[3300].controller_configuration_settings["Outside Light Calibration"] = 44
pi[3300].controller_configuration_settings["Wind Speed Calibration"] = 45
pi[3300].controller_configuration_settings["Fallback Temperature Calibration"] = 46
pi[3300].controller_configuration_settings["Analog Temperature 1 Calibration"] = 47
pi[3300].controller_configuration_settings["Analog Temperature 2 Calibration"] = 48
pi[3300].controller_configuration_settings["Analog Temperature 3 Calibration"] = 49
pi[3300].controller_configuration_settings["Analog Temperature 4 Calibration"] = 50
pi[3300].controller_configuration_settings["Analog Temperature 5 Calibration"] = 51
pi[3300].controller_configuration_settings["Soil Mositure 1 Calibration"] = 52
pi[3300].controller_configuration_settings["Soil Mositure 2 Calibration"] = 53
pi[3300].controller_configuration_settings["Soil Mositure 3 Calibration"] = 54
pi[3300].controller_configuration_settings["Soil Mositure 4 Calibration"] = 55
pi[3300].controller_configuration_settings["Soil Mositure 5 Calibration"] = 56
pi[3300].controller_configuration_settings["Vent Position Sensor 1 Calibration - Adjust"] = 57
pi[3300].controller_configuration_settings["Vent Position Sensor 2 Calibration - Adjust"] = 58
pi[3300].controller_configuration_settings["Vent Position Sensor 3 Calibration - Adjust"] = 59
pi[3300].controller_configuration_settings["Vent Position Sensor 4 Calibration - Adjust"] = 60
pi[3300].controller_configuration_settings["Vent Position Sensor 5 Calibration - Adjust"] = 61
pi[3300].controller_configuration_settings["EC/pH 1 Calibration - Zero"] = 62
pi[3300].controller_configuration_settings["EC/pH 2 Calibration - Zero"] = 63
pi[3300].controller_configuration_settings["EC/pH 3 Calibration - Zero"] = 64
pi[3300].controller_configuration_settings["EC/pH 4 Calibration - Zero"] = 65
pi[3300].controller_configuration_settings["EC/pH 5 Calibration - Zero"] = 66
pi[3300].controller_configuration_settings["EC/pH 1 Calibration - Gain"] = 67
pi[3300].controller_configuration_settings["EC/pH 2 Calibration - Gain"] = 68
pi[3300].controller_configuration_settings["EC/pH 3 Calibration - Gain"] = 69
pi[3300].controller_configuration_settings["EC/pH 4 Calibration - Gain"] = 70
pi[3300].controller_configuration_settings["EC/pH 5 Calibration - Gain"] = 71
pi[3300].controller_configuration_settings["EC/pH 1 Calibration - Given Gain"] = 72
pi[3300].controller_configuration_settings["EC/pH 2 Calibration - Given Gain"] = 73
pi[3300].controller_configuration_settings["EC/pH 3 Calibration - Given Gain"] = 74
pi[3300].controller_configuration_settings["EC/pH 4 Calibration - Given Gain"] = 75
pi[3300].controller_configuration_settings["EC/pH 5 Calibration - Given Gain"] = 76
pi[3300].controller_configuration_settings["Generic 1 Calibration"] = 77
pi[3300].controller_configuration_settings["Generic 2 Calibration"] = 78
pi[3300].controller_configuration_settings["CO2 Least Significant Bit"] = 79
pi[3300].controller_configuration_settings["Light Multiplier"] = 80
pi[3300].controller_configuration_settings["Generic 1 Multiplier"] = 81
pi[3300].controller_configuration_settings["Generic 2 Multiplier"] = 82
pi[3300].controller_configuration_settings["Local/Remote Setpoints"] = 83
pi[3300].controller_configuration_settings["Enable Bump Vents"] = 84
pi[3300].controller_configuration_settings["Bump Intervals Minutes)"] = 85
pi[3300].controller_configuration_settings["Spike Temperature Delta"] = 86
pi[3300].controller_configuration_settings["Spike Temperature Hold Time Exception"] = 87
pi[3300].controller_configuration_settings["Outside Temperature 2 Mapping"] = 88
pi[3300].controller_configuration_settings["Outside Temperature 2 Calibration"] = 89
pi[3300].controller_configuration_settings["Barometric Pressure Mapping"] = 90
pi[3300].controller_configuration_settings["Barometric Pressure Calibration"] = 91
pi[3300].controller_configuration_settings["Enable expansion board"] = 92
pi[3300].controller_configuration_settings["Autodetect Mode"] = 93
pi[3300].controller_configuration_settings["Heat Demand Offset"] = 94
pi[3300].controller_configuration_settings["Cool Demand Offset"] = 95
pi[3300].controller_configuration_settings["Generic Sensor 1 Range"] = 96
pi[3300].controller_configuration_settings["Generic Sensor 1 Units"] = 97
pi[3300].controller_configuration_settings["Generic Sensor 1 Filter Max Samples"] = 98
pi[3300].controller_configuration_settings["Generic Sensor 2 Range"] = 99
pi[3300].controller_configuration_settings["Generic Sensor 2 Units"] = 100
pi[3300].controller_configuration_settings["Generic PID Multipliers P"] = 101
pi[3300].controller_configuration_settings["Generic PID Multipliers I"] = 102
pi[3300].controller_configuration_settings["Generic PID Multipliers D"] = 103
pi[3300].controller_configuration_settings["Expansion Output 1 Type"] = 109
pi[3300].controller_configuration_settings["Expansion Output 2 Type"] = 110
pi[3300].controller_configuration_settings["Expansion Input 1 Type"] = 111
pi[3300].controller_configuration_settings["Expansion Input 2 Type"] = 112
pi[3300].controller_configuration_settings["Sensor Delay"] = 113
pi[3300].controller_configuration_settings["Vent Position 1 Calibration - Open"] = 114
pi[3300].controller_configuration_settings["Vent Position 2 Calibration - Open"] = 115
pi[3300].controller_configuration_settings["Vent Position 3 Calibration - Open"] = 116
pi[3300].controller_configuration_settings["Vent Position 4 Calibration - Open"] = 117
pi[3300].controller_configuration_settings["Vent Position 5 Calibration - Open"] = 118
pi[3300].controller_configuration_settings["Vent Position 1 Calibration - Close"] = 119
pi[3300].controller_configuration_settings["Vent Position 2 Calibration - Close"] = 120
pi[3300].controller_configuration_settings["Vent Position 3 Calibration - Close"] = 121
pi[3300].controller_configuration_settings["Vent Position 4 Calibration - Close"] = 122
pi[3300].controller_configuration_settings["Vent Position 5 Calibration - Close"] = 123
pi[3300].controller_configuration_settings["Inside Light Mapping"] = 124
pi[3300].controller_configuration_settings["Differential Pressure Mapping"] = 125
pi[3300].controller_configuration_settings["Snow Mapping"] = 126
pi[3300].controller_configuration_settings["Inside Light Calibration"] = 127
pi[3300].controller_configuration_settings["Differential Pressure Calibration"] = 128
pi[3300].controller_configuration_settings["Accumulator 1 - Sensor"] = 129
pi[3300].controller_configuration_settings["Accumulator 1 - Operating Period"] = 130
pi[3300].controller_configuration_settings["Accumulator 2 - Sensor"] = 131
pi[3300].controller_configuration_settings["Accumulator 2 - Operating Period"] = 132
pi[3300].controller_configuration_settings["Generic Sensor 2 Filter Max Samples"] = 133
pi[3300].controller_configuration_settings["Hold Temp Vent Open"] = 134
pi[3300].controller_configuration_settings["Irrigation Trigger Input Mapping"] = 135
pi[3300].controller_configuration_settings["Fallback Sensor Input Mapping"] = 136
pi[3300].controller_configuration_settings["Enable Expansion Board"] = 137
pi[3300].controller_configuration_settings["Enable Loud Vent"] = 138
pi[3300].controller_configuration_settings["Wind Direction Calibration"] = 139
pi[3300].controller_configuration_settings["Rain Calibration"] = 140
pi[3300].controller_configuration_settings["Snow Calibration"] = 141
pi[3300].controller_configuration_settings["Inside Light Multiplier"] = 142
pi[3300].controller_configuration_settings["Canopy Sensor Mapping"] = 143
pi[3300].controller_configuration_settings["Canopy Sensor Calibration"] = 144
pi[3300].controller_configuration_settings["Max Temperature Adjustment"] = 145
pi[3300].controller_configuration_settings["Temperature Average Enable"] = 146
pi[3300].controller_configuration_settings["Temperature Average Include Temp1"] = 147
pi[3300].controller_configuration_settings["Temperature Average Include Temp2"] = 148
pi[3300].controller_configuration_settings["Temperature Average Include Temp3"] = 149
pi[3300].controller_configuration_settings["Temperature Average Include Temp4"] = 150
pi[3300].controller_configuration_settings["Temperature Average Include Temp5"] = 151
pi[3300].controller_configuration_settings["VPD Notify"] = 152
pi[3300].controller_configuration_settings["VPD Min"] = 153
pi[3300].controller_configuration_settings["VPD Max"] = 154
pi[3300].controller_configuration_settings["Cloud 3300 Enable"] = 155
pi[3300].controller_configuration_settings["Save Inside Temperature Calibration"] = 156
pi[3300].controller_configuration_settings["Save Temperature Average Enable"] = 157

pi[3300].zone_configuration_settings["Use Fallback Sensor if In Temp Fails"] = 1
pi[3300].zone_configuration_settings["Fallback Sensor Failed Temperature Stage"] = 3
pi[3300].zone_configuration_settings["Force To No Sensor Stage setting 3) if High Alarm occurs"] = 4
pi[3300].zone_configuration_settings["Rain Hold Time"] = 5
pi[3300].zone_configuration_settings["Command Delay Time"] = 6
pi[3300].zone_configuration_settings["Daylight Savings Time"] = 7
pi[3300].zone_configuration_settings["Log History"] = 8
pi[3300].zone_configuration_settings["Latitude"] = 9
pi[3300].zone_configuration_settings["Longitude"] = 10
pi[3300].zone_configuration_settings["Temperature Units"] = 11
pi[3300].zone_configuration_settings["Windspeed Units"] = 12
pi[3300].zone_configuration_settings["Light Units"] = 13
pi[3300].zone_configuration_settings["Irrigation Mode"] = 14
pi[3300].zone_configuration_settings["Irrigation Delay"] = 15
pi[3300].zone_configuration_settings["High Alarm Temperature Above Cool Setpoint Threshold"] = 17
pi[3300].zone_configuration_settings["High Alarm Temperature Hold Time"] = 18
pi[3300].zone_configuration_settings["Low Alarm Temperature Below Heat Setpoint Threshold"] = 19
pi[3300].zone_configuration_settings["Low Alarm Temperature Hold Time"] = 20
pi[3300].zone_configuration_settings["Cool Deadband"] = 21
pi[3300].zone_configuration_settings["Heat Deadband"] = 22
pi[3300].zone_configuration_settings["Humidity Deadband"] = 23
pi[3300].zone_configuration_settings["Analog Temperature Deadband"] = 24
pi[3300].zone_configuration_settings["Enable SmartCool"] = 25
pi[3300].zone_configuration_settings["SmartCool Setting"] = 26
pi[3300].zone_configuration_settings["Show Heat Demand on iGrow"] = 32
pi[3300].zone_configuration_settings["Heat Demand Max Light"] = 33
pi[3300].zone_configuration_settings["Heat Demand Max Wind"] = 34
pi[3300].zone_configuration_settings["Greenhouse heating design delta-T"] = 35
pi[3300].zone_configuration_settings["Light''s influence on Heat Demand"] = 36
pi[3300].zone_configuration_settings["Wind''s influence on Heat Demand"] = 37
pi[3300].zone_configuration_settings["Show Cool Demand on iGrow"] = 38
pi[3300].zone_configuration_settings["Cool Demand Max Light"] = 39
pi[3300].zone_configuration_settings["Greenhouse cooling design delta-T"] = 40
pi[3300].zone_configuration_settings["Cool Demand Light Factor"] = 41
pi[3300].zone_configuration_settings["Enable Active Cooling"] = 42
pi[3300].zone_configuration_settings["Passive Lock Stage"] = 43
pi[3300].zone_configuration_settings["Enter Active Cooling Cool Demand Threshold"] = 44
pi[3300].zone_configuration_settings["Exit Active Cooling Cool Demand Threshold"] = 45
pi[3300].zone_configuration_settings["Cool stages"] = 46
pi[3300].zone_configuration_settings["Heat stages"] = 47
pi[3300].zone_configuration_settings["Stage Width"] = 48
pi[3300].zone_configuration_settings["Stage Override 1 Enabled"] = 49
pi[3300].zone_configuration_settings["Stage Override 1 Temperature Stage"] = 50
pi[3300].zone_configuration_settings["Stage Override 1 Start Time"] = 51
pi[3300].zone_configuration_settings["Stage Override 1 End Time"] = 52
pi[3300].zone_configuration_settings["Stage Override 1 Interval"] = 53
pi[3300].zone_configuration_settings["Stage Override 2 Enabled"] = 54
pi[3300].zone_configuration_settings["Stage Override 2 Temperature Stage"] = 55
pi[3300].zone_configuration_settings["Stage Override 2 Start Time"] = 56
pi[3300].zone_configuration_settings["Stage Override 2 End Time"] = 57
pi[3300].zone_configuration_settings["Stage Override 2 Interval"] = 58
pi[3300].zone_configuration_settings["Stage Override 3 Enabled"] = 59
pi[3300].zone_configuration_settings["Stage Override 3 Temperature Stage"] = 60
pi[3300].zone_configuration_settings["Stage Override 3 Start Time"] = 61
pi[3300].zone_configuration_settings["Stage Override 3 End Time"] = 62
pi[3300].zone_configuration_settings["Stage Override 3 Interval"] = 63
pi[3300].zone_configuration_settings["Stage Override 4 Enabled"] = 64
pi[3300].zone_configuration_settings["Stage Override 4 Temperature Stage"] = 65
pi[3300].zone_configuration_settings["Stage Override 4 Start Time"] = 66
pi[3300].zone_configuration_settings["Stage Override 4 End Time"] = 67
pi[3300].zone_configuration_settings["Stage Override 4 Interval"] = 68
pi[3300].zone_configuration_settings["Vapor Pressure Deficit Media Sensor"] = 69
pi[3300].zone_configuration_settings["Lighting Cyclic Mode"] = 70
pi[3300].zone_configuration_settings["Lighting Start Delay"] = 71
pi[3300].zone_configuration_settings["Lighting Finish Delay"] = 72
pi[3300].zone_configuration_settings["Lighting Active Time"] = 73
pi[3300].zone_configuration_settings["Lighting Minimum On Time"] = 74
pi[3300].zone_configuration_settings["Drive to Average - Enable"] = 75
pi[3300].zone_configuration_settings["Drive to Average - Target Temperature"] = 76
pi[3300].zone_configuration_settings["Drive to Average - Maximum Failed Days"] = 77
pi[3300].zone_configuration_settings["Drive to Average - Deviated Temperature Threshold"] = 78
pi[3300].zone_configuration_settings["Drive to Average - Setpoint Correction"] = 79
pi[3300].zone_configuration_settings["Cool Setpoint Influence Factor - Enable"] = 80
pi[3300].zone_configuration_settings["Cool Setpoint Influence Factor - Sensor"] = 81
pi[3300].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Threshold"] = 82
pi[3300].zone_configuration_settings["Cool Setpoint Influence Factor - Upper Offset"] = 83
pi[3300].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Threshold"] = 84
pi[3300].zone_configuration_settings["Cool Setpoint Influence Factor - Lower Offset"] = 85
pi[3300].zone_configuration_settings["Heat Setpoint Influence Factor - Enable"] = 86
pi[3300].zone_configuration_settings["Heat Setpoint Influence Factor - Sensor"] = 87
pi[3300].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Threshold"] = 88
pi[3300].zone_configuration_settings["Heat Setpoint Influence Factor - Upper Offset"] = 89
pi[3300].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Threshold"] = 90
pi[3300].zone_configuration_settings["Heat Setpoint Influence Factor - Lower Offset"] = 91
pi[3300].zone_configuration_settings["Start Up Delay"] = 92
pi[3300].zone_configuration_settings["Curtain Energy Mode - Light Deadband"] = 93
pi[3300].zone_configuration_settings["Curtain Energy Mode - Temperature Deadband"] = 94
pi[3300].zone_configuration_settings["Humidity Override On Time"] = 95
pi[3300].zone_configuration_settings["Humidity Override Off Time"] = 96
pi[3300].zone_configuration_settings["Dehumidify Low Outside Temperature Threshold"] = 97
pi[3300].zone_configuration_settings["Dehumidify Heat Boost"] = 98
pi[3300].zone_configuration_settings["Enable Demands Based on Active Cool"] = 99
pi[3300].zone_configuration_settings["Enable Active Cool Stage Override"] = 100
pi[3300].zone_configuration_settings["SmartCool[0] Ku"] = 101
pi[3300].zone_configuration_settings["SmartCool[0] Gmax"] = 102
pi[3300].zone_configuration_settings["SmartCool[0] Kd"] = 103
pi[3300].zone_configuration_settings["SmartCool[0] Gmin"] = 104
pi[3300].zone_configuration_settings["SmartCool[1] Ku"] = 105
pi[3300].zone_configuration_settings["SmartCool[1] Gmax"] = 106
pi[3300].zone_configuration_settings["SmartCool[1] Kd"] = 107
pi[3300].zone_configuration_settings["SmartCool[1] Gmin"] = 108
pi[3300].zone_configuration_settings["SmartCool[2] Ku"] = 109
pi[3300].zone_configuration_settings["SmartCool[2] Gmax"] = 110
pi[3300].zone_configuration_settings["SmartCool[2] Kd"] = 111
pi[3300].zone_configuration_settings["SmartCool[2] Gmin"] = 112
pi[3300].zone_configuration_settings["SmartCool[3] Ku"] = 113
pi[3300].zone_configuration_settings["SmartCool[3] Gmax"] = 114
pi[3300].zone_configuration_settings["SmartCool[3] Kd"] = 115
pi[3300].zone_configuration_settings["SmartCool[3] Gmin"] = 116
pi[3300].zone_configuration_settings["SmartCool[4] Ku"] = 117
pi[3300].zone_configuration_settings["SmartCool[4] Gmax"] = 118
pi[3300].zone_configuration_settings["SmartCool[4] Kd"] = 119
pi[3300].zone_configuration_settings["SmartCool[4] Gmin"] = 120
pi[3300].zone_configuration_settings["SmartCool[5] Ku"] = 121
pi[3300].zone_configuration_settings["SmartCool[5] Gmax"] = 122
pi[3300].zone_configuration_settings["SmartCool[5] Kd"] = 123
pi[3300].zone_configuration_settings["SmartCool[5] Gmin"] = 124
pi[3300].zone_configuration_settings["SmartCool[6] Ku"] = 125
pi[3300].zone_configuration_settings["SmartCool[6] Gmax"] = 126
pi[3300].zone_configuration_settings["SmartCool[6] Kd"] = 127
pi[3300].zone_configuration_settings["SmartCool[6] Gmin"] = 128
pi[3300].zone_configuration_settings["SmartCool[7] Ku"] = 129
pi[3300].zone_configuration_settings["SmartCool[7] Gmax"] = 130
pi[3300].zone_configuration_settings["SmartCool[7] Kd"] = 131
pi[3300].zone_configuration_settings["SmartCool[7] Gmin"] = 132
pi[3300].zone_configuration_settings["SmartCool[8] Ku"] = 133
pi[3300].zone_configuration_settings["SmartCool[8] Gmax"] = 134
pi[3300].zone_configuration_settings["SmartCool[8] Kd"] = 135
pi[3300].zone_configuration_settings["SmartCool[8] Gmin"] = 136
pi[3300].zone_configuration_settings["SmartCool[9] Ku"] = 137
pi[3300].zone_configuration_settings["SmartCool[9] Gmax"] = 138
pi[3300].zone_configuration_settings["SmartCool[9] Kd"] = 139
pi[3300].zone_configuration_settings["SmartCool[9] Gmin"] = 140
pi[3300].zone_configuration_settings["Peristaltic - Output Mode"] = 141
pi[3300].zone_configuration_settings["Peristaltic - Output Delay"] = 142
pi[3300].zone_configuration_settings["Volume Measurement Units"] = 143
pi[3300].zone_configuration_settings["Nutrient Units"] = 144
pi[3300].zone_configuration_settings["Nutrient Units - TDS Conversion Factor"] = 145
pi[3300].zone_configuration_settings["Equipment Delay"] = 146
pi[3300].zone_configuration_settings["Sensor Display - 1"] = 147
pi[3300].zone_configuration_settings["Sensor Display - 2"] = 148
pi[3300].zone_configuration_settings["Sensor Display - 3"] = 149
pi[3300].zone_configuration_settings["Sensor Display - 4"] = 150
pi[3300].zone_configuration_settings["Schedule Pump Transition Time"] = 151
pi[3300].zone_configuration_settings["Peristaltic Pump - Advanced Mode"] = 152

pi[3300].inConfig["inEnable"]=0
pi[3300].inConfig["inName"]=1
pi[3300].inConfig["inUnits"]=2
pi[3300].inConfig["inType"]=3
pi[3300].inConfig["inReadType"]=4
pi[3300].inConfig["inMbAddress"]=5
pi[3300].inConfig["inMbRegister"]=6
pi[3300].inConfig["inMbType"]=7
pi[3300].inConfig["inMbParm1"]=8
pi[3300].inConfig["inMbParm2"]=9
pi[3300].inConfig["inCalibrateMx"]=10
pi[3300].inConfig["inCalibrateB"]=11
pi[3300].inConfig["inSP0Lo"]=12
pi[3300].inConfig["inSP0Hi"]=13
pi[3300].inConfig["inSP1Lo"]=14
pi[3300].inConfig["inSP1Hi"]=15
pi[3300].inConfig["inSP2Lo"]=16
pi[3300].inConfig["inSP2Hi"]=17
pi[3300].inConfig["inSP3Lo"]=18
pi[3300].inConfig["inSP3Hi"]=19
pi[3300].inConfig["inSP4Lo"]=20
pi[3300].inConfig["inSP4Hi"]=21
pi[3300].inConfig["inSP5Lo"]=22
pi[3300].inConfig["inSP5Hi"]=23
pi[3300].inConfig["inSP6Lo"]=24
pi[3300].inConfig["inSP6Hi"]=25
pi[3300].inConfig["inSP7Lo"]=26
pi[3300].inConfig["inSP7Hi"]=27
pi[3300].inConfig["inStageWidth"]=28
pi[3300].inConfig["inAlarmEnable"]=29
pi[3300].inConfig["inAlarmLow"]=30
pi[3300].inConfig["inAlarmHigh"]=31
pi[3300].inConfig["inAlarmDeadband"]=32
pi[3300].inConfig["inAlarmDelay"]=33
pi[3300].inConfig["inAlarmLevel"]=34
pi[3300].inConfig["inNotifyFreq"]=35
pi[3300].inConfig["inNotifyMax"]=36
pi[3300].inConfig["inReadPeriod"]=37
pi[3300].inConfig["inMinChange"]=38
pi[3300].inConfig["inMapZone"]=39
pi[3300].inConfig["inMapId"]=40

/******************* End 3300 Values ******************************/
// function getParamIds(){
//   cl(pi[1800].config_setpoints);
//   for (var key in pi[1800].config_setpoints){
//     pids[p.PID_BASE_CONFIG_SETPOINTS +
//       pi[1800].config_setpoints[key]] = key;
//   }
//   cl(pids);
//
// }
/******************* 800 Values ******************************/

// p[800]={}
// p[800].PID_800_BASE_CONFIG: 0,
// p[800].PID_800_BASE_CONFIG_EQUIPMENT: 150,
// p[800].PID_800_BASE_CONFIG_EXPANSION_BOARDS: 250,
// p[800].PID_800_BASE_RTD: 850,
// p[800].PID_800_BASE_RTD_ANNEX: 900,
// p[800].PID_800_BASE_RTD_EQUIPMENT: 950, // size is 1*50 = 50, next id is 1000
// }

p[800]={
  PID_800_BASE_CONFIG: 0,
  PID_800_BASE_CONFIG_EQUIPMENT: 150,
  PID_800_BASE_CONFIG_EXPANSION_BOARDS: 250,
  PID_800_BASE_RTD: 850,
  PID_800_BASE_RTD_ANNEX: 900,
  PID_800_BASE_RTD_EQUIPMENT: 950, // size is 1*50 = 50, next id is 1000
}

pb[800].igC=p[800].PID_800_BASE_CONFIG
pb[800].igCE=p[800].PID_800_BASE_CONFIG_EQUIPMENT
pb[800].igCX=p[800].PID_800_BASE_CONFIG_EXPANSION_BOARDS
pb[800].igR=p[800].PID_800_BASE_RTD
pb[800].igRA=p[800].PID_800_BASE_RTD_ANNEX
pb[800].igRE=p[800].PID_800_BASE_RTD_EQUIPMENT

pInd[800]={}
pInd[800].igC = [p[800].PID_800_BASE_CONFIG, 0, 150, 1]// base, type, index size, index count
pInd[800].igCE = [p[800].PID_800_BASE_CONFIG_EQUIPMENT, 1, 100, 1]// type 1, uses channel
pInd[800].igCX = [p[800].PID_800_BASE_CONFIG_EXPANSION_BOARDS, 1, 50, 12]
pInd[800].igR = [p[800].PID_800_BASE_RTD, 0, 50, 1]
pInd[800].igRA = [p[800].PID_800_BASE_RTD_ANNEX, 0, 50, 1]
pInd[800].igRE = [p[800].PID_800_BASE_RTD_EQUIPMENT, 1, 50, 1]

pi[800]={}

pi[800].igC={
  controllerID: 0,
  controllerType: 1,
  firmwareType: 2,
  firmwareVersion: 3,
  registrationKey: 4,
  lastUpdate: 5,
  infoUpdate: 6,
  hasUpdate: 7,
  needScreenshot: 8,
  lastRead: 9,
  quickUpdates: 10,
  disableEmailNotification: 11,
  disableTimeoutNotification: 12,
  disableSensorModuleNotification: 13,
  disableTemperatureNotification: 14,
  disableHumidityNotification: 15,
  disableCo2Notification: 16,
  disableVpdNotification: 17,
  numberEmailsPerPeriod: 18,
  keyTimeOffset: 19,
  keyPassword: 20,
  configVersion: 21,
  temperatureStageWidth: 22,
  temperatureDeadband: 23,
  temperatureStageDelaySeconds: 24,
  humidifyDeadband: 25,
  humidifyOnTimeSeconds: 26,
  humidifyOffTimeSeconds: 27,
  dehumidifyLowTemperature: 28,
  co2Deadband: 29,
  temperatureUnits: 30,
  windSpeedUnits: 31,
  lightUnits: 32,
  selfTestEnable: 33,
  selfTestTimeHourMinute: 34,
  selfTestRetestMinutes: 35,
  selfTestTemperatureDifference: 36,
  usbLoggingEnable: 37,
  usbLoggingFormat: 38,
  usbLoggingIntervalSeconds: 39,
  longitude: 40,
  latitude: 41,
  sensorMappingInTemperature: 42,
  sensorMappingInHumidity: 43,
  sensorMappingInCo2: 44,
  sensorMappingInLight: 45,
  sensorMappingOutTemperature: 46,
  sensorMappingLight: 47,
  sensorMappingWindSpeed: 48,
  sensorMappingWindDirection: 49,
  sensorMappingRain: 50,
  sensorMappingBackupTemperature: 51,
  sensorCalibrationInTemperature: 52,
  sensorCalibrationInHumidity: 53,
  sensorCalibrationInCo2: 54,
  sensorCalibrationInLight: 55,
  sensorCalibrationOutTemperature: 56,
  sensorCalibrationLight: 57,
  sensorCalibrationWindSpeed: 58,
  sensorCalibrationRain: 59,
  sensorCalibrationBackupTemperature: 60,
  windMphPerRevolution: 61,
  dayHeatSetpoint: 62,
  dayCoolSetpoint: 63,
  dayHumidifySetpoint: 64,
  dayCo2Setpoint: 65,
  dayDehumidifySetpoint: 66,
  dayRampTimeMinutes: 67,
  dayStartTimeMode: 68,
  dayStartTimeHourMinute: 69,
  nightEnable: 70,
  nightHeatSetpoint: 71,
  nightCoolSetpoint: 72,
  nightHumidifySetpoint: 73,
  nightCo2Setpoint: 74,
  nightDehumidifySetpoint: 75,
  nightRampTimeMinutes: 76,
  nightStartTimeMode: 77,
  nightStartTimeHourMinute: 78,
  difEnable: 79,
  difHeatSetpoint: 80,
  difCoolSetpoint: 81,
  difHumidifySetpoint: 82,
  difCo2Setpoint: 83,
  difDehumidifySetpoint: 84,
  difRampTimeMinutes: 85,
  difStartTimeMode: 86,
  difStartTimeHourMinute: 87,
  timeFormat: 88,
  screenPassword: 89,
  GrowWeekStartDate: 90,
  bumpOffTime: 91,
  enableExpansionBoard: 92,
  expansionBoardPort: 93,
  alarmHoldTime: 94,
  alarmLowTemperature: 95,
  alarmHighTemperature: 96,
  alarmLowHumidity: 97,
  alarmHighHumidity: 98,
  alarmLowCo2: 99,
  alarmHighCo2: 100,
  alarmLowVpd: 101,
  alarmHighVpd: 102,
  vpdCanopyOffset: 103,
  commandStartupDelaySeconds: 104
}
pi[800].igCE={
  controllerID: 0,
  channelID: 1,
  channelType: 2,
  channelName: 3,
  oneChannelOverride: 4,
  twoChannelOverrideMode: 5,
  twoChannelOverridePosition: 6,
  timedOverrideMode: 7,
  timedOverrideStartTimeHourMinute: 8,
  timedOverrideDurationSeconds: 9,
  timedOverridePosition: 10,
  failSafeOutput: 11,
  twoChannelMoveTimeSeconds: 12,
  heat2: 13,
  heat1: 14,
  normal: 15,
  cool1: 16,
  cool2: 17,
  cool3: 18,
  cool4: 19,
  cool5: 20,
  cool6: 21,
  humidify: 22,
  dehumidify: 23,
  coldDehum: 24,
  ventColdOutTemperaturePosition: 25,
  ventStrongWindPosition: 26,
  ventInRainPosition: 27,
  ventColdOutTemperatureThreshold: 28,
  ventStrongWindThreshold: 29,
  curtainDayLightThreshold: 30,
  curtainDayLightPosition: 31,
  curtainDayOpenDelayMinutes: 32,
  curtainDayStartTimeMode: 33,
  curtainDayStartTimeHourMinute: 34,
  curtainDayEndTimeMode: 35,
  curtainDayEndTimeHourMinute: 36,
  curtainNightPosition: 37,
  curtainNightStartTimeMode: 38,
  curtainNightStartTimeHourMinute: 39,
  curtainNightEndTimeMode: 40,
  curtainNightEndTimeHourMinute: 41,
  curtainShockProtect: 42,
  alarmTemperatureHighLow: 43,
  alarmTemperatureThreshold: 44,
  alarmThresholdHoldTime: 45,
  co2LightCutoff: 46,
  co2OnTime: 47,
  co2OffTime: 48,
  co2LightThreshold: 49,
  co2LightDeadband: 50,
}
pi[800].igCX={
  controllerID: 0,
  boardIndex: 1,
  address: 2,
  boardType: 3,
  channelStartIndex: 4,
}
pi[800].igR={
  rtdID: 0,
  controllerID: 1,
  rtdTime: 2,
  ipAddress: 3,
  controllerTime: 4,
  setpointIndex: 5,
  temperatureStage: 6,
  humidityStage: 7,
  humidifyActive: 8,
  heatSetpoint: 9,
  coolSetpoint: 10,
  humidifySetpoint: 11,
  dehumidifySetpoint: 12,
  inTemperature: 13,
  inHumidity: 14,
  inCo2: 15,
  inLight: 16,
  outTemperature: 17,
  outLight: 18,
  windSpeed: 19,
  windDirection: 20,
  isInRain: 21,
  growWeek: 22,
  alarmTemperature: 23,
  alarmHumidity: 24,
  alarmCo2: 25,
  errorInTemperature: 26,
  errorBackupTemperature: 27,
  errorInHumidity: 28,
  errorIPonicSensorModule: 29,
  expansionBoard00Status: 30,
  expansionBoard01Status: 31,
  expansionBoard02Status: 32,
  expansionBoard03Status: 33,
  expansionBoard04Status: 34,
  expansionBoard05Status: 35,
  expansionBoard06Status: 36,
  expansionBoard07Status: 37,
  expansionBoard08Status: 38,
  expansionBoard09Status: 39,
  expansionBoard10Status: 40,
  expansionBoard11Status: 41,
}
pi[800].igRA={
  rtdID: 0,
  alarmVpd: 1,
}
pi[800].igRE={
  rtdID: 0,
  equipmentIndex: 1,
  status: 2,
  relay: 3,
  position: 4,
  oneOverride: 5,
  twoOverride: 6,
}



/******************* End 800 Values ******************************/


export {p, pb, pInd, pi, pids, tableIds, tableBases2, offsetTables, dpNames}
