import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import C18MuiSelect from '../../usa/components/C18MuiSelect'
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import {chTypes} from '../utilsFui';
// import ShowArray from './ShowArray00';
// import SiteArrayMenu from './SiteArrayMenu';
import {dbVals} from '../../components/utils/http'
import {cl, globs} from '../../components/utils/utils';
import {getParamId} from '../../usa/utils/utils'
// import {dbVals, init} from './utils/http';
// import {checkLogin, saveConfigs} from './utils/httpauth';
// import {p, pi} from './utils/paramIds';
// import MainBar from './MainBar';
// import history from "../history"
/*this will have to actually set the channeltype, *and*
the sub-type in channel data*/

class ChannelType extends React.Component{


  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      type: props.type,
    }
    this.props.getValue(props.ind, {type: "channelType"})
  }
  /*
  pi[1800].config_channels_configuration["irrigation_mode"] = 91
  pi[1800].config_channels_configuration["light_mode"] = 97
  pi[1800].config_channels_configuration["pump_type"] = 133
  pi[1800].config_channels_configuration["vent_mode"] = 197// should  be vent_type
*/
  
  getChannelAnalog=()=>{
//     cl(this.props)
    let[z,u,c,ind]=this.props.zuci.split('-')
    if((this.props.virtType=="expLink4")&&(c>19))return 1
    let pid=getParamId("configuration_channels","isAnalog")
//     cl([z,c,pid])
    let an=+((dbVals.z[z]||{})[c]||{})[pid]||0
//     cl(an)
    return an
  }

  makeChannelTypeSelect=()=>{
// "channel_None": {id:0, t:"None"},
    
/* which channel types show?
l and p channels don't appear on modbus / doser
d channels only appear on doser
p channels only appear on even: 0, 2
*/
//     cl(this.props.zuci)
//     cl(this.props)
    let anChannel=this.getChannelAnalog()
//     cl(anChannel)
//     cl(an)
    let ch=+(this.props.zuci.split("-")[2])
//     cl(ch)
    return Object.keys(chTypes).map((c, i)=>{
//       cl(c)
      
      let v = chTypes[c]
//       cl([c,v])
//       cl(this.props.contType)
      let anEquipment=(v.an)?1:0
//       cl(anEquipment)
      var show=((anChannel==anEquipment)||(c=="channel_None"))
//       cl([anChannel,anEquipment])
//       cl(v)
//       cl([anChannel,anEquipment,show])
//       var show=true
      switch(this.props.contType){
        case "L4":
           if((((ch%2)&&(!anEquipment))&&v.ct=="p") || (v.ct=="d")) show=false
          break
        case "MB":
           if((v.ct=="p") || (v.ct=="l") || (v.ct=="d")) show=false
          break
        case "ID":
           if(v.ct=="p") show=false
          break
      }
      if(show){
        return(
            <C18MuiMenuItem key={i} value=
            {c}>{v.t}</C18MuiMenuItem>
        )
      }else{
        return null
      }

    });
  }

  onChange=(e)=>{
    let type = e.target.value;
    let title = "Insert Title for " + e.target.value + " Here";
    if (globs.fuiPages[type] !== undefined){
      title = globs.fuiPages[type].title;
    }
    let vo = {value: {type: "channelType", title: title,
      chType: type}}//, noChange:true
//       cl(vo)
//       cl(this.props)
    this.props.onChange(this.props.ind, vo);
  }

  render(){
    // cl(this.props.type)
//     cl(this.props)
// className="floatleft"    
    let pr=this.props
//     cl(this.props.type.slice(-5))
    let type=this.props.type
    if(type.slice(-5)=="_1900"){
      type=type.substring(0,type.length-5)// remove the _1900
    }
//     cl(type)
    return(
      <div>
        <C18MuiFormControl className={"formControl"}>
          <C18MuiInputLabel>Channel&nbsp;Type</C18MuiInputLabel>
          <C18MuiSelect
            id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
            value={type}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            >
            {this.makeChannelTypeSelect()}
          </C18MuiSelect>
        </C18MuiFormControl>
      </div>
    );
  }
}


  export default ChannelType ;
