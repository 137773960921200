import React from 'react';
import Icon from '@material-ui/core/Icon';
import Eco from '@material-ui/icons/Eco';
import UsaIcon from '../../usa/components/UsaIcon'
import C18Input00 from '../../usa/components/C18Input00'
import C18Button00 from '../../usa/components/C18Button00'
// import GrowJournalReply00 from '../../usa/components/GrowJournalReply00'
import C18GrowJournalEdit00 from '../../usa/components/C18GrowJournalEdit00'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadUsersInfo,privs,rnCl,acctFeature,
  tagColor,makePingIds,fixPingIds,replacePingIdsWithUserIds} from '../../usa/components/C18utils'
import parse from 'html-react-parser'
import {wsTrans,doGetPostBasic} from '../../usa/utils/utils'
// import MyImage2 from '../../avatar.jpg'
import {cl, globs, constant, dateToHrMinString, dateToYrMoDa, tsToDate, 
  dateToShortDay, makeShortDayDateStr,dateToDisplayDate,getTime,getRandomString} from '../../components/utils/utils';
import config from '../../components/utils/config'
import exifr from 'exifr' // => exifr/dist/full.umd.cjs 

class GrowJournalWidgetEntry00 extends React.Component{
/* this gets the size in parms w, h. assume a 5px padding*/  
  constructor(props) {
    super(props);
    this.state={
      closed: true,
      editing: false,
      addComment: false,
      commentText:"",
      loaded: false,
      images:[],
      imgLoading:false,
    }
    this.pingIds={}
    this.loremIpsum="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel mauris laoreet, fermentum urna eget, bibendum tortor. Sed dui nulla, fermentum id consectetur nec, malesuada ullamcorper turpis. Praesent at luctus diam. Cras hendrerit lacus nisi, quis feugiat urna interdum in. Maecenas iaculis viverra eros sed faucibus. Pellentesque justo ipsum, consectetur et ex ac, luctus venenatis magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam in convallis turpis. Nulla sed feugiat arcu, a laoreet massa. Pellentesque nec leo ipsum. Phasellus facilisis, lorem sed eleifend finibus, enim ex feugiat turpis, ac pellentesque tortor ante in metus. Vivamus tristique urna vitae luctus tempor. Praesent aliquam porta nibh ut euismod. Suspendisse imperdiet dui risus, a condimentum neque condimentum sollicitudin.<br/><br/>" +
    "Suspendisse suscipit vehicula commodo. Suspendisse potenti. Nam varius vestibulum nunc, ut tincidunt lorem. Aliquam ut nunc eget justo auctor mollis non vitae ante. Nunc et eleifend lectus, nec vulputate diam. Etiam porttitor nunc luctus lorem pellentesque, non mattis quam dignissim. Quisque fermentum accumsan quam in facilisis. Vestibulum non mollis felis. Nam eu libero varius, placerat nunc nec, blandit neque. Interdum et malesuada fames ac ante ipsum primis in faucibus."
    this.URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi 
//     cl(props)
//     props.notify({id: "drag", func: this.onDrag})
//     this.loadGJ()
//     if(props.parms.reply){
//       this.getThread()
//     }else{
//       this.state.loaded=true
    this.getThread()
    this.subscribe_tagsUpdate=globs.events.subscribe("tagsUpdate",
      this.tagsUpdate)
    
//     }
//     cl("continuing")
  }
  
//   componentDidMount=()=>{
//     cl("componentDidMount")
//   }
//   
//   componentDidUpdate=()=>{
//     cl("componentDidUpdate")
//   }
//   
//   componentWillUnmount=()=>{
//     cl("componentDidUpdate")
//   }
//   
//   getThreadAvatars=()=>{
//     cl(this.props.parms.avatars)
//   }
  
//   makeAvatarList=async()=>{
//     await loadUsersInfo()
//     let avatars={}
//     globs.usersInfo.info.forEach(u=>{
//       avatars[u.userId]=u.avatar
//     })
//     return avatars
//   }

  componentWillUnmount=()=>{
    this.subscribe_tagsUpdate.remove()
  }
  
  tagsUpdate=()=>{
//     cl("tags update")
    this.mySetState("tagsUpdate",{update:(this.state.update||0)+1})
  }
  
  mySetState=(id,vals)=>{
//     cl(id)
    this.setState(vals)
  }
  
  makeUserAssocArray=async()=>{
    await loadUsersInfo()
    let users={}
    globs.usersInfo.info.forEach(u=>{
      users[u.userId]=u
    })
    return users
  }
  
//   fixPingIds=(body)=>{// replaces the userIds with pingIds
// //     cl(body)
//     let parts=body.split("@")
//     for(let i=1;i<parts.length;i++){
//       let seg=parts[i]
//       let userId=seg.substring(0,16)
//       let pingId=this.pingIds[userId]
//       parts[i]=`${pingId}${seg.substring(16)}`
//     }
//     body=parts.join("")
//     return body
//   }
  
//   makePingId=(first,revPingIds)=>{
//     if(!revPingIds[`@${first}`]){return first}
//     let dig=2
//     while(revPingIds[`@${first}${dig}`]){dig+=1}
//     return `${first}${dig}`
//   }
//   
//   makePingIds=()=>{
//     this.pingIds={}
//     this.revPingIds={}
//     Object.keys(this.users).forEach(k=>{
//       let u=this.users[k]
//       let first=u.name.split(` `)[0]
//       let pingId=`@${this.makePingId(first,this.revPingIds)}`
//       this.pingIds[u.userId]=pingId
//       this.revPingIds[pingId]=u.userId
//     })
// //     cl(this.revPingIds)
//   }

  getThread=async()=>{
//     cl(this.props)
    this.users=await this.makeUserAssocArray();
    [this.pingIds,this.revPingIds]=makePingIds()
    let entry=this.props.parms.entry
    let editing=this.props.parms.editing || false
    // cl(entry)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId,
      body: {threadId: entry.threadId}})
//     this.fixPingIds(entry)
    let thread=[]
    r.data.forEach(en=>{
      if(en.growJournalId!=this.props.parms.entry.growJournalId){
        thread.push(en)
      }
    })
    thread.sort((a,b)=>{
      if(a.modTime>b.modTime){return 1}
      if(a.modTime<b.modTime){return -1}
      return 0
    })
    this.mySetState("id0",{loaded: true, threadId:entry.threadId, thread:thread, editing: editing})
  }
  
// body: "It’s okay. He woke up."
// dispTime: 1611197804
// growJournalId: "U3_Vrk-0tgpz_192"
// subject: "Did you hear about the kidnapping at school?"
// threadId: "zwGisQhdJ1qN_192"
// userId: "3hRZJgBx2_rNEIhd"


  result=async(type)=>{
//     cl(type)
    switch(type){
      case "entry-More":
        await this.getThread()
        this.mySetState("id1",{closed: false})
        break
      case "entry-Close":
        this.mySetState("id2",{closed: !this.state.closed})
        break
      case "entry-Comment":
        cl(this.props)
        this.props.onChange({gjReply: true, entry: this.props.parms.entry})
//         this.mySetState("id3",{reply: true})
        break
      default:
        break
    }
  }
  
  makeImagePath=(id, ext)=>{
    return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`// .${ext}
  }
  
  showImage=(path,s)=>{
    cl(path)
    this.props.onChange({cmd: "image", path: path, section: s})//showImage
  }
  
  showExerptImages=(parts,images)=>{
//     cl(parts)
//     cl(images)
    if(parts.length>1){
      let len=parts.length
      return parts.map((p,i)=>{
        if((i>0)&&(i<4)){
          let p2=p.split("}")
          let path=this.makeImagePath(p2[0], "jpg")
          let imageInfo={w:100, h:50}
          let sect=({i:p2[0], t:p2[1], w: imageInfo.w, h: imageInfo.h, x: 100+50*1, y: 100+50*1})// x, y is where it will appear large
//           cl([p2, path, sect])
          return this.showImageVideo(p2[0],i,images,sect)
//           return(
//             <img key={i+1} width="100" src={`${constant.expressUrl}/usa/images/uploads/${path}`} style={{display:"inline-block"}}
//               onClick={e=>{this.showImage(path, sect)}}/>
//           )
        }
      })
    }
  }
  
  showGJExcerpt=(body,images)=>{
    let parts=body.split("{Img:")
//     cl(parts)
//     cl(images)
    let sects=[parts[0]]
    parts.forEach((p,i)=>{
        let p2=p.split("}")
        sects.push(p2[1])
    })
    let str=sects.join(" ")
//     cl(parts.length)
    var path,sect
    if(parts.length>=2){
        let p2=parts[1].split("}")
        path=this.makeImagePath(p2[0], "jpg")
        let imageInfo={w:100, h:50}
        sect=({i:p2[0], t:p2[1], w: imageInfo.w, h: imageInfo.h, x: 100+50*1, y: 100+50*1})// x, y is where it will appear large
    }
//         {(parts.length>=2)&&
//             <img key={2} width="100" src={`${constant.expressUrl}/usa/images/uploads/${path}`}
//               onClick={e=>{this.showImage(path, sect)}}/>
//         }
    return (
      <div>
        <div>
          {this.makeLink(str.substr(0,105), true)}
        </div>
        {this.showExerptImages(parts,images)}
      </div>
    )
      // {this.makeLink(str.substr(0,105))+((str.length>105)?"...":"")}
  }
  
  onLoadImage=(imm)=>{
    if(imm)return this.onLoadImage
    cl("loaded")
  }
  
  getImageInfo=(id,images)=>{
    for(let i=0;i<images?.length;i++){
      let im=images[i]
//       cl(im)
      if(im.id==id){return im}
    }
    return null
  }
  
  showImageVideo=(id,key,images,sect,excerpt)=>{
//     cl([id, images])
    let ii=this.getImageInfo(id,images)
    if(!ii){return null}
//     cl(ii)
    let path=this.makeImagePath(id, "jpg")
    // cl(path)
    if((ii?.format||"").indexOf("video")>=0){
//         poster={`${constant.expressUrl}/usa/images/videoPoster.jpg`}
//       cl(path)
        // src={`https://http0.c2.link4cloud.com/usa/images/uploads/${path}#t=0.001`}
      return(
        <video key={key} width={320} height={240} controls={true}
        src={`${constant.expressUrl}/usa/images/uploads/${path}#t=0.001`}
        type={ii.format}/>
      )
    }else{
      if(excerpt){
        // src={`${constant.expressUrl}/usa/images/uploads/${path}`}
        // src={`https://http0.c2.link4cloud.com/usa/images/uploads/${path}`} 
        return(
          <img key={key+1} width="100" src={`${constant.expressUrl}/usa/images/uploads/${path}`} style={{display:"inline-block"}}
            onClick={e=>{this.showImage(path, sect)}}/>
        )
      }else{
        // src={`${constant.expressUrl}/usa/images/uploads/${path}`} 
        return(
          <img key={key} width="100" 
            src={`${constant.expressUrl}/usa/images/uploads/${path}`}
            onLoad={this.onLoadImage}// turn off spinner
            style={{display:"inline-block"}}
            onClick={e=>{this.showImage(path, sect)}}/>
        )
      }
    }
  }
  
  showGJBody=(body,growJournalId,level,images)=>{
//     cl("show body")
//     cl(images)
//     cl(body)
    if (body == null) return
    body=fixPingIds(body,this.pingIds)
    if((acctFeature("showGjId"))&&(["dev","demoPi"].includes(config.server))){
      body+=`-level-${level}-id-${growJournalId}`}
//     cl(config.server)
//     cl(body)
    if(this.state.closed){return this.showGJExcerpt(body,images)}
    let parts=body.split("{Img:")
//     cl(parts)
    let sects=[]
    parts.forEach((p,i)=>{
      if(i>0){
//         cl(p)
        let p2=p.split("}")
//         let imageInfo=this.state.images[p2[0]]
        let imageInfo={w:100, h:50}
        sects.push({i:p2[0], t:p2[1], w: imageInfo.w, h: imageInfo.h, x: 100+50*i, y: 100+50*i})// x, y is where it will appear large
      }else{
        sects.push({i: false, t:p})
      }
    })
//     cl(sects)
//     let images=[]
    for(let i=0;i<sects.length;i++){
      let s=sects[i]
      cl(s)
      if(s.i){
//         cl(s.i)
        let path=this.makeImagePath(s.i, "jpg")
        let img=new Image()
        img.src=`${constant.expressUrl}/usa/images/uploads/${path}`
        // img.src=`https://http0.c2.link4cloud.com/usa/images/usa/images/uploads/${path}`
        img.onload=l=>{
          this.imgCount-=1;
//           cl(this.imgCount)
          if(this.imgCount==0){
            this.mySetState("id5",{imgLoading:false})
            this.imgCount=-1
          }
        }
        this.images.push(img)
        this.imgCount+=1
      }
    }
    return sects.map((s,i)=>{
      cl(s)
      var path
      if(s.i){
//         path=this.makeImagePath(s.i, this.state.images[s.i].ext)
        path=this.makeImagePath(s.i, "jpg")
      }
      return(
          <span key={i}>
          {(!this.state.closed)&&s.i &&
            <>
              {this.showImageVideo(s.i,0,images,s,false)}
            </>
          }
          {s.t&&
            <div>{this.makeLink(s.t)}</div>
          }
          </span>
      )
    })
    return
  }
  
  
  showClosedEntry=()=>{
//     cl(this.props)
    let entry=this.props.parms.entry
    let av=entry.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    let dateStr=makeShortDayDateStr(entry.dispTime)
//     let da=tsToDate(entry.dispTime)
//     let dateStr=`${dateToShortDay(da)} ${dateToYrMoDa(da)} ${dateToHrMinString(da)}`
    let subject=`<strong>${entry.subject}</strong>`
//     cl(path)
    
    return(
      <table style={{textAlign: "left"}}><tbody>
      <tr><td><img src={path} height="40"/></td>
      <td>
      <table><tbody>
      <tr><td>
        <Icon component={Eco} style={{padding: 0, fontSize: 18, 
          verticalAlign: "middle"}}/>
        <span style={{verticalAlign: "middle"}}>{dateStr}</span>          
      </td></tr>
      <tr><td>{parse(subject)}
      <UsaIcon icon={"entry-More"} result={this.result}/>
      </td></tr>
      </tbody></table>
      </td>
      </tr>
      </tbody></table>
    )
    cl(entry)
  }
  
  showThreadO=()=>{
    return this.state.thread.map((th,i)=>{
      cl(th)
      cl(this.users)
//       cl(this.props.parms.avatars)
//       var av
//       if(th.userId in this.props.parms.avatars){av=this.props.parms.avatars[th.userId]}
      let av=this.users[th.userId].avatar
      var path
      if(av){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }else{
        path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      let body
      if (th.body) {
        body=this.showGJBody(th.body)
      }
//       cl(path)
      
      return(
        <tr key={i}>
          <td style={{verticalAlign: "top"}}><img src={path} height="40"/></td>
          <td>
          <table><tbody>
          <tr><td>
            <Icon component={Eco} style={{padding: 0, fontSize: 18, 
              verticalAlign: "middle"}}/>
            <span style={{verticalAlign: "middle"}}>{this.makeDateStr(th.modTime)}</span>          
          </td></tr>
          <tr><td>{body}</td></tr>
          </tbody></table>
          </td>
        </tr>
      )
    })
      
  }
  
  makeDateStr=(ts)=>{
    let da=tsToDate(ts)
    return `${dateToShortDay(da)} ${dateToYrMoDa(da)} ${dateToHrMinString(da)}`
  }
  
  doMakeEntry=(entry)=>{
//     cl(entry)
//     cl(this.users)
//     cl(entry.userId)
    let user=this.users[entry.userId]||{name:"Unknown User"}
    let av=user.avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    let da=new Date(1000*(entry.dispTime||entry.modTime))
    let gjEntry={
      avatar:path,
      name:user.name,
      userId: user.userId,
      time:dateToDisplayDate(da,"h:mm AP"),
      date:dateToDisplayDate(da,"mm/dd/yyyy"),
      dispTime:entry.dispTime,
      images:entry.images,
      body:entry.body,
      level:entry.level,
      growJournalId:entry.growJournalId,
      threadId:entry.threadId,
      tags:entry.tags||[],//["Greenhouse 03", "pH Sensor", "Equipment"],
    }
//     cl(gjEntry)
    return gjEntry
  }
  
  makeGJEntryInfo=()=>{// called from render
//     if(this.state.threadId!=this.props.parms.entry.threadId){this.getThread()}
//     cl(`ThreadIds: ${this.state.threadId}, ${this.props.parms.entry.threadId}`)
//     cl(this.state)
//     cl(this.state.threadId)
//     cl(this.props.parms.entry.threadId)
    let gjEntry=this.doMakeEntry(this.props.parms.entry)
//     cl(gjEntry)
    gjEntry.thread=this.state.thread.map(t=>{
      return this.doMakeEntry(t)
    })
//     cl(gjEntry)
    return gjEntry
  }
  
  showTags=(tags)=>{
//     cl(tags)
//     cl(this.state)
//     cl(this.props)
    let tags2=tags.slice(0)
    // cl(tags2)
//     cl(this.props.parms)
    if(!["zone","config"].includes(this.props.parms.level)/*this.props.parms.level!="zone"*/){
      let zInd=getZoneIndex(this.props.parms.entry.zoneId)
      if(zInd){
//         cl(globs.zonesInfo.info[zInd])
        tags2.push(globs.zonesInfo.info[zInd].zoneName)
      }
    }
    return tags2.map((t,i)=>{
//       cl([t, this.props.parms.myTag])
      if(t!=this.props.parms.myTag){
        return(
            <span id="/sidebar/growJournalEntry/tags" key={i} 
            style={{backgroundColor:tagColor(t),cursor:"pointer"}}
            className={`grow-journal-tag`}
            onClick={e=>this.onChange("tagClick",{filter:{tags:[t]}})}
            >{t}</span>
        )
      }
    })
  }
  
  showThread=(entry)=>{
//     cl(entry.thread)
//     cl(entry)
    return entry.thread.map((t,i)=>{
      cl(t)
      return(
        <div key={i} className="journal-entry reply">
          <div className="floatleft">
            <img src={t.avatar} alt={t.name}/><span>{t.name}</span>
          </div>

          <div className="floatright time-date">
            <span className="time">{t.time}</span> | <span className="date">{t.date}</span>
            {this.showDeleteEntry("reply",t.growJournalId)}
          </div>

          <div className="clearfloat"></div>

          <div className="body">
            {this.showGJBody(t.body,t.growJournalId,t.level,t.images)}
          </div>
        </div>
      )
    })
  }
  
  showEditEntryForm=(entry)=>{
//     cl(entry)
    let p=this.props.parms
    cl(p)
    return(
      <>
        <C18GrowJournalEdit00 parms={{
          display:true,
          level:p.level,
          siteId:p.siteId,
          zoneId:p.zoneId,
          growJournalId:entry.growJournalId,
          threadId:entry.threadId,
          dispTime:entry.dispTime,
          images:entry.images,
          edit:true,
          note:fixPingIds(entry.body,this.pingIds),
          tags:entry.tags,
          myTag:p.myTag,
          getPopup:p.getPopup,
          onChange:vals=>this.onChange("edGJEntry",vals),//props.parms.
        }}/>
        <div className="clearfloat"></div>
        <br /><hr />
      </>
    )
  }
  
  showOpenEntry=()=>{
//     cl(this.state)
    let gjEntry=this.makeGJEntryInfo()
//     cl(gjEntry)
    if(this.state.editing){return this.showEditEntryForm(gjEntry)}
//     cl("still")
    let gotPics=gjEntry.body?.indexOf("{Img:")>=0
    let showEditEntryDiv;
    if ((globs.userData.session.userId===gjEntry.userId)) {
      showEditEntryDiv=this.showEditEntry(this.props.parms.entry);
    }
    else{
      showEditEntryDiv='';
    }
    return(
      <>
        <div className={`info-section${(false&&this.state.closed)?"":" expanded"}`}>
          <div className="journal-entry">
          
            <div className="floatleft">
              <img src={gjEntry.avatar} alt={gjEntry.name}/><span>{gjEntry.name}</span>
            </div>

            <div className="floatright time-date">
              <span className="time">{gjEntry.time}</span> | <span className="date">{gjEntry.date}</span>
              {this.showDeleteEntry("main",this.props.parms.entry.threadId)}
              <button id="/sidebar/growJournalEntry/addComment" type="button" className="material-icons-outlined add" aria-label="add comment"
                onClick={o=>this.onChange("addComment")}>
                chat
              </button>
              {showEditEntryDiv}
              {this.state.imgLoading&&
                <span className="spinner"><img style={{height:12,width:12}} src="/img/spinner.gif" /></span>
              }
              {(gotPics||(gjEntry.body?.length>105)||(gjEntry.thread?.length>0)||(config.server=="dev"))&&
                <>
                  {(this.state.closed)&&gjEntry.thread?.length>0&&
                    <button id="/sidebar/growJournalEntry/openClose" type="button" className={`material-icons-outlined`}
                      onClick={e=>this.onChange("openClose",{closed:false})}>
                      expand_more
                    </button>
                  }
                  {(!this.state.closed)&&gjEntry.thread?.length>0&&
                    <button id="/sidebar/growJournalEntry/openClose" type="button" className={`material-icons-outlined`}
                      onClick={e=>this.onChange("openClose",{closed:true})}>
                      expand_less
                    </button>
                  }
                </>
              }
            </div>

            <div className="clearfloat"></div>

            <div className="body">
              <div className="full">
                {this.showGJBody(gjEntry.body,gjEntry.growJournalId,gjEntry.level,
                  gjEntry.images)}
              </div>
            </div>
          </div>
          {(true||!this.state.closed)&&this.showTags(gjEntry.tags)}
          {(!this.state.closed)&&this.showThread(gjEntry)}
          {(true||!this.state.closed)&&
            <div className="clearfloat"></div>
          }
          {(true||!this.state.closed)&&this.showGrowJournalAddComment()}
        </div>
        <div className="clearfloat"></div>
        <br /><hr />
      </>
    )
  }
  
/****************** Image Handling *******************************/
  sendImages=()=>{
    let data = new FormData()
    this.state.images.forEach((img,i)=>{
      data.append(`id${i}`, img.id)
      data.append(`file${i}`, img.image)
    })
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
//     cl(url)
    return doGetPostBasic(url, method, data, type)// pro  mise
  }
  
  markImage=(e)=>{
    let expImage={id: getRandomString(16), image: e.target.files[0]}// expanded image, w/ our name
    let images=this.state.images.slice(0)
    images.push(expImage)
//     cl(images)
    this.mySetState("id6",{commentText: this.state.commentText+`[Img${images.length}]`/*`{Img:${expImage.id}}`*/, images: images})
  }
  
  replacePingIds=(note)=>{
    return note
  }
  
/****************** End Image Handling *******************************/

  saveComment=async(vals)=>{
/*This is getting updated to handle the return value from C18GrowJournalEdit00
*/
    cl("save comment")
    cl(vals)
    cl(this.state)
//     let images=[]
//     if(this.state.images.length){
//       this.state.images.forEach(img=>{
//         images.push({name: img.image.name, id: img.id, size: img.image.size})
//       })
//       let r=await this.sendImages()
//     }
//     cl(images)

    let entry=this.props.parms.entry
//     let da=Math.floor(getTime())//new Date().getTime()/1000
//     let [note,notifications]=replacePingIdsWithUserIds(vals.gjEntry.body,this.revPingIds)
//     let i=1
//     var pos
//     do{// replace the image placeholders
//       let search=`[Img${i}]`
//       pos=note.indexOf(search)
//       if(pos>=0){
//         note=note.substr(0,pos)+`{Img:${images[i++-1].id}}`+note.substr(pos+search.length)
//       }
//     }while(pos>=0)

//     let gjEntry={
//       threadId: entry.threadId,//getRandomString(16),
//       growJournalId:getRandomString(16),
//       userId: globs.userData.session.userId,
//       body: note,//this.state.commentText,
//       modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
//       dispTime: da,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
//       images: images,//[],//
//       zoneId:entry.zoneId,
//     }
    
    let gjEntry=vals.gjEntry
    gjEntry.threadId=entry.threadId
    gjEntry.zoneId=entry.zoneId
    gjEntry.original=false
    cl(gjEntry)
    
    
    let thread=this.state.thread.slice(0)
    thread.push(gjEntry)
    this.mySetState("id7",{images:[],addComment: false, commentText:"", thread:thread})
    
//     this.props.parms.onChange({cmd:"entryReply",gjEntry:gjEntry})
//     cl(gjEntry)
    let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: gjEntry})
  }
  
  deleteReplyEntry=(growJournalId)=>{
    let thread=this.state.thread.slice(0)
    thread.forEach((th,i)=>{
      if(th.growJournalId==growJournalId){
//         cl(th,i)
        thread.splice(i,1)
//         cl("remove")
      }
    })
//     cl(thread)
    this.setState({thread:thread})
//     cl(this.state)
//     cl(growJournalId)
  }

  makeLink=(body, excerpt=false)=>{
    if (this.URL_REGEX.test(body)) {
      let arr = []
      let idx = 0
      let key=0
      let str = body.replace(this.URL_REGEX, (match, p1, p2, offset, string) => {
        // cl([match, offset, string])
        // push string before match
        if (idx != offset) {
          arr.push(string.substring(idx, offset))
        }
        // if match but at end of gj excerpt 
        if (excerpt && (offset + match.length >= string.length)) {
          arr.push(match)
        } else {
          arr.push(<a key={key++} className="colored-link-text" target="_blank" rel="noopener noreferrer" href={match}>{match}</a>)
        }
        // set next start idx
        idx = offset + match.length
        return `<a key=${offset} className="colored-link-text" target="_blank" rel="noopener noreferrer" href=${match}>${match}`
      })
      if (idx < body.length) arr.push(body.substring(idx))
      if (excerpt && body.length == 105) arr.push("...")
      // cl(str)
      // cl(arr)
      return arr
    } else {
      if (excerpt && body.length == 105) body += "..."
      return body
    }
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "addComment":
        this.mySetState("id8",{addComment:!this.state.addComment,closed:false})
        this.images=[]
        this.imgCount=0
        break
      case "saveComment":
        return this.saveComment(vals)
      case "commentText":
        this.mySetState("id9",vals)
        break
      case "deleteEntry":
//         cl("delete entry")
//         cl(vals)
//         cl(this.props.parms)
        let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Entry?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          if(vals.type=="reply"){this.deleteReplyEntry(vals.id)}
          this.props.onChange({cmd:"deleteEntry",type:vals.type,id:vals.id})
        }
//         cl("de done")
        break
      case "openClose":
//         cl(vals)
        if(!vals.closed){
//           cl(this.state)
          Object.assign(vals.imgLoading=(this.state.images.length>0))
          this.images=[]
          this.imgCount=0
        }
        this.mySetState("id10",vals)
        break
      case "editEntry":
//         cl("edit")
        this.mySetState("id11",{editing:!this.state.editing})
        break
      case "edGJEntry":
        switch(vals.cmd){
          case "addGJEntry":
//             cl(vals)
//             cl(this.props.parms)
//             cl(vals.gjEntry)
            this.props.onChange(vals)
            this.mySetState("id12",{editing:false})
            break
        }
        break
      case "tagClick":
        vals.cmd=type
        this.props.onChange(vals)
//         cl(vals)
        break
      default:
        break
    }
    
  }
  
  showGrowJournalAddComment=()=>{
    if(this.state.addComment){
//       cl("add comment")
      return(
        <>
          <C18GrowJournalEdit00 parms={{
            display:true,
            comment:true,
            level:"",//p.level,
            zoneId:"",//p.zoneId,
            growJournalId:"",//entry.growJournalId,
            threadId:"",//entry.threadId,
            dispTime:"",//entry.dispTime,
            images:[],//entry.images,
            edit:true,
            note:"",//this.fixPingIds(entry.body),
            tags:[],//entry.tags,
            myTag:"",//p.myTag,
            onChange:vals=>this.onChange("saveComment",vals),//props.parms.
          }}/>
          <div className="clearfloat"></div>
        </>
      )
    }
  }
    
  showGrowJournalAddCommentO=()=>{
    if(this.state.addComment){
      cl("add comment")
      return(
        <div>
          <div id="/sidebar/growJournalEntry/comment" className="journal-entry reply edit">
            <textarea placeholder="Enter comment here" 
              value={this.state.commentText}
              onChange={e=>{this.onChange("commentText",{commentText:e.currentTarget.value})}}>
            </textarea>
          </div>

          <div className="floatright">
            <C18Button00 type="button" className="floatleft material-icons-outlined" aria-label="upload" style={{
              width:40, 
              height:40, 
            }}>attach_file
            </C18Button00>
            
            <form className="file-upload-form">
            <C18Input00 type="file"  onChange={this.markImage} style={{
              position:"absolute", 
              width:40, 
              height:40, 
              marginTop:0, 
              marginLeft:-40,
              zIndex:10,                   
              opacity:0,
              cursor: "pointer",
            }}/>
            </form>
            
            <button type="button" className="only-icon floatleft filled material-icons-outlined" aria-label="create"
            onClick={o=>this.onChange("saveComment")}>
              send
              </button>
          </div>
        </div>
      )
    }
//     else{
//       return(
//         <button type="button" className="material-icons-outlined add floatright" aria-label="add comment"
//           onClick={o=>this.onChange("addComment")}>
//           add
//         </button>
//       )
//     }
  }
  
  showDeleteEntry=(type,id)=>{
//     cl(globs.privsInfo.info)
//     cl(privs("account",0,constant.AREA_PRIVS_ADMIN))
    if(privs("account",0,constant.AREA_PRIVS_ADMIN)){
      return(
        <button id="/sidebar/growJournalEntry/delete" type="button" className="material-icons trash" aria-label="delete entry"
          onClick={o=>this.onChange("deleteEntry",{type:type,id:id})}>
          delete_outline
        </button>
      )
    }
  }
  
  showEditEntry=(entry)=>{
    return(
      <button id="/sidebar/growJournalEntry/edit" type="button" className="material-icons-outlined add" aria-label="add comment"
        onClick={o=>this.onChange("editEntry")} title="Edit, actually">
        edit
      </button>
    )
  }
  
  showOpenEntryO=()=>{
    let entry=this.props.parms.entry
    cl(entry)
//     cl(entry.body)
//     let av=entry.avatar
    let av=this.users[entry.userId].avatar
    var path
    if(av){
      path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
    }else{
      path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    }
    let dateStr=makeShortDayDateStr(entry.dispTime)
//     let da=tsToDate(entry.dispTime)
//     let dateStr=this.makeDateStr(entry.dispTime)//`${dateToShortDay(da)} ${dateToYrMoDa(da)} $*/{dateToHrMinString(da)}`
    let subject=entry.subject
//     cl(path)
//     let text="" entry.subject + ": " + entry.body + "<br/>" + this.loremIpsum
    let body=this.showGJBody(entry.body,entry.growJournalId)
    let text=`<strong>${entry.subject}:</strong><br/>${entry.body}<p>${this.loremIpsum}</p>`
    
    
    return(
      <table style={{textAlign: "left"}}><tbody>
      <tr><td style={{verticalAlign: "top"}}><img src={path} height="40"/></td>
      <td>
      <table><tbody>
      <tr><td>
      
          <Icon component={Eco} style={{padding: 0, fontSize: 18, 
            verticalAlign: "middle"}}/>
          <span style={{verticalAlign: "middle"}}>{dateStr}</span>          
      {parse("&nbsp;&nbsp;")}
      {!this.props.parms.reply &&
        <UsaIcon icon={"entry-Close"} result={this.result}/>
      }
      {parse("&nbsp;&nbsp;")}
      <UsaIcon icon={"entry-Like"} result={this.result}/>
      {parse("&nbsp;&nbsp;")}
      <UsaIcon icon={"entry-Comment"} result={this.result}/>
      {parse("&nbsp;&nbsp;")}
      <UsaIcon icon={"entry-Share"} result={this.result}/>
      </td></tr>
      <tr><td>{body}
      </td></tr>
      </tbody></table>
      </td>
      </tr>
      {this.showThread()}
      <tr><td>
      <UsaIcon icon={"entry-Comment"} result={this.result}/>
      </td></tr>
      </tbody></table>
    )
    cl(entry)
  }
  
//   showReply=()=>{
//     return(
//       <div>reply></div>
//     )
//   }
  
  render() {
//     cl("render")
//     cl(this.props)
//     cl(this.state)
//     if(this.state.reply){
//       return this.showReply()
//     }else{
    if(this.state.loaded){
      if(this.state.closed && !this.props.parms.reply){
        return(
          <div>
          {this.showOpenEntry()}
          </div>
        )
      }else{
        
        return(
          <div>
          {this.showOpenEntry()}
          </div>
        )
      }
    }else{
      return <div id="content-area">loading...</div>
    }
//     }
  }
}

export default GrowJournalWidgetEntry00;
