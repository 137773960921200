import React from 'react';
import C18DateFormat00 from './C18DateFormat00'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import {getParamId,getParamId2,getParamId800} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,getZoneIndex,loadSiteData,getChannelType} from './C18utils'
import {getEquipmentStatus} from './C18EquipmentStatus00'
import {dbVals,getZoneControllers} from '../../components/utils/http';
import {cl,globs,dateToDisplayDate,getTime,constant} from '../../components/utils/utils';
import {pInd} from '../../components/utils/paramIds';

import history from "../../history"

class C18ZoneEquipment00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.getEquipment()
    this.state={
      mode:"sites",
      zInd:0,
      cols: 1,
      equipment:[
      {id:1, name:"Exhaust Variable Speed",system:"ON"},
      {id:2, name:"Water Valve 1",system:"ON"},
      {id:3, name:"North Roof Vents",system:"ON"},
      {id:4, name:"Water Pump",system:"ON - 60%"},
      {id:5, name:"Tank Fill Valve",system:"ON - 60%"},
      {id:6, name:"LED Grow Lights",system:"ON - 60%"},
      {id:7, name:"Floor Hot Water Valve",system:"OFF"},
      {id:8, name:"Fl Bl",system:"OFF"},
      {id:9, name:"Fl Nt",system:"OFF"},
      {id:11, name:"K Blo",system:"ON"},
      {id:12, name:"Fl Ki",system:"ON"},
      {id:13, name:"pH Up",system:"OFF"},
      {id:15, name:"pH Dn",system:"OFF"},
      {id:16, name:"Tank 2",system:"ON"},
      {id:17, name:"Gable Exhaust Fans",system:"OFF",alarm:true},
      {id:18, name:"Tank 4",system:"ON"},
      ],
      loaded:false,
      updateInterval: setInterval(this.update,1000),
    }
//     setInterval(this.update,1000)
  }
  
  update=()=>{
//     cl("update")
    this.getEquipment()
  }
  
  componentWillUnmount=()=>{
    clearInterval(this.state.updateInterval)
  }
  
  getZoneIndex=(zoneId)=>{// returns the siteZoneIndex
    for(let i=0;i<globs.zonesInfo.info.length;i++){
      let z=globs.zonesInfo.info[i]
      if(z.zoneId==zoneId){return z.siteZoneIndex}
    }
  }
  
  getPearlUsed=(zInd,zInfo)=>{
//     cl("get pearl")
    let xBoards=[]
    let numOutId=getParamId2(zInfo.gatewayType,"configuration_expansion_boards","numOutputs")
    let chIndex=getParamId2(zInfo.gatewayType,"configuration_expansion_boards","startChannelIndex")
    let addIndex=getParamId2(zInfo.gatewayType,"configuration_expansion_boards","address")
    let mult=pInd[1900].config_expansion_boards[2]
//     cl(mult)
//     cl(numOutId)
//     cl(dbVals.z[zInd][240][addIndex])
//     cl(chIndex)
    for(let i=0;i<40;i++){
      xBoards.push({
        numOut:+((dbVals.z[zInd]||{})[240]||{})[numOutId+i*mult]||0,
        chInd:+((dbVals.z[zInd]||{})[240]||{})[chIndex+i*mult]||0,
      })
    }
//     cl(xBoards)
//     cl(xBoards[0])
    if(xBoards[0].numOut==0){
      xBoards[0].numOut=8
      xBoards[0].chInd=0
      xBoards[1].numOut=4
      xBoards[1].chInd=12
    }
    let ret=[]
    xBoards.forEach(x=>{
      if((+x.numOut)>0){
        for(let i=0;i<x.numOut;i++){
          ret.push(x.chInd+i)
        }
      }
    })
//     cl(ret)
    return ret
  }
  
  getUsedChannels=(zInd,zInfo)=>{
//     cl(zInfo)
//     let gotExpansion=false
    let zc=getZoneControllers(zInd)
//     cl(zc)
    let ret=[]
    let chUsedId=getParamId("configuration_channels","used")
    var used
    if(zInfo.virtual){
      let max=(zInfo.virtualType=="expLink4")?22:12
      for(let i=0;i<max;i++)
        if((i<12)||(i>=16))
          {ret.push(i)}
    }else{
//       cl(zInfo)
      if(zInfo.gatewayType==1900){
        ret=this.getPearlUsed(zInd,zInfo)
//         cl(ret)
//         ret=[]
      }else{
        for(let i=0;i<160;i++){
          if(+zc[Math.floor(i/40)]){
            if((zInfo.gatewayType==1900)||(zInfo.gatewayType==800)){
              used=(i<8)?1:0
            }else{
              used=((dbVals.z[zInd]||{})[i]||{})[chUsedId]||0
            }
            if(+used){ret.push(i)}
          }
        }
      }
    }
    return ret
  }

  getEquipment800=async(zInd,chans)=>{
    let chTypeId=getParamId800("igCE","channelType")
    let chNameId=getParamId800("igCE","channelName")
//     cl(chTypeId)
//     cl(chans)
    let equip=[]
    chans.forEach((c,i)=>{
      let eq={
        name:dbVals.z[zInd][i][chNameId],
        type:dbVals.z[zInd][i][chTypeId],
        id:i+1,
        type:0,
      }
      equip[i]=eq
    })
    this.setState({zInd: zInd, equipment: equip, loaded: true})

  }
  
  getEquipment=async()=>{
    await loadZonesInfo()
    await loadSiteData(this.props.parms.site)
    let zInd=this.getZoneIndex(this.props.parms.zone)// *not* the shared version!
    let zInfo=globs.zonesInfo.info[getZoneIndex(this.props.parms.zone)]
    let chans=this.getUsedChannels(zInd,zInfo)// the actual channel ids.
    if(zInfo.gatewayType==800){return this.getEquipment800(zInd,chans)}
//     cl(zInfo.gatewayType)
//     cl(this.state)
    let proport=[constant.EQ_VENT, constant.EQ_CURT, constant.EQ_MIXV, constant.EQ_MZONE2, constant.EQ_GEN_PID, constant.EQ_VAROUT, constant.EQ_MIXV_AN]
    let chTypeId=getParamId("configuration_channels","channelType")
    let chNameId=getParamId("configuration_channels","channelName")
    let chPosId=getParamId("snapshot_channels","position")
    let chAnalogId=getParamId("configuration_channels","isAnalog")
//     cl(zInfo)
//     cl(chans)
    let equip=[]
    let i2=1;
//     cl(chans)
    chans.forEach(i=>{
       let zone=dbVals.z[zInd]||{}
       if(true||zone[i][chTypeId]!=0){
         var ch,pos,system,chType
         if(true||zone){
          ch=zone[i]||{}
          pos=ch[chPosId]
          let off=(proport.includes(+(zone[i]||{})[chTypeId]))?"0%":"OFF"
          let on=(proport.includes(+(zone[i]||{})[chTypeId]))?"1%":"ON"
          if(pos==99){pos=100}
          system=(pos!=undefined)?((pos==0)?off:((pos==1)?on:`${pos}%`)):"None"
          chType=getChannelType(zInfo.gatewayType, zInd,i)
          let eq={
            id:i2++, 
            name:ch[chNameId], 
            type:ch[chTypeId],
            analog:ch[chAnalogId],
            chType:chType, 
            pos:pos, 
            system:system,
            showInfo:(this.state.equipment[i]||{}).showInfo,
            status:getEquipmentStatus(zInd,i,ch),
            unit:Math.floor(i/40),
            chan:i%40,
          }
//           cl(eq)
//           equip.push(eq)
          equip[i]=eq
        }else{
          let eq={id:i2, name:`eq${i2}`, type:0, chType:0, pos:0, 
            system:0,showInfo:false,
          }
          i2+=1
//           equip.push(eq)
          equip[i]=eq
        }
      }
    })
//     for(let i=0;i<12;i++){
//     }
//     cl(equip)
    this.setState({zInd: zInd, equipment: equip, loaded: true})
  }
  
  onClick=(type,e,eq,index)=>{
// http://stage.c2.link4cloud.com:3114/fui/live/channel_None/0-0-1-0
// http://ngsg.link4cloud.com:3104/usa/c18/fui/unit_Input_Multipliers/0-0-0-0
    switch(type){
      case "equip":
        e.preventDefault()
//         cl([eq.id,eq.chType])
//         cl(eq)
        let url=`/usa/c18/fui/${eq.chType}/${this.state.zInd}-${eq.unit}-${eq.chan}-0`
        history.push(url)
        break
      case "info":// index is the equip array, *not* the id
//         let eqArr=Object.assign({},this.state.equipment)
        cl(index)
        let eqArr=this.state.equipment.slice(0)
        cl(eqArr)
        eqArr[index].showInfo=!eqArr[index].showInfo
        this.setState({equipment:eqArr})
        break
      default:
        break
    }
  }
  
  showOneEquipment=()=>{
    let twoChan=[constant.EQ_2ND_VENT, constant.EQ_2ND_CURT, constant.EQ_2ND_MIXV, constant.EQ_2ND_MZONE2, constant.EQ_2ND_GEN_PID, 
      constant.EQ_2ND_CRAVO]
    let equip=[]
//     cl(this.state)
    this.state.equipment.forEach((e,i)=>{
//       cl(e)
      let onClass=(["OFF","None"].includes(e.system))?"":" on"
      let alarmClass=(e.alarm)?"alarm-on":""
        if((i%2!=1)||(!twoChan.includes(+this.state.equipment[i].type))||e.analog){
//           cl(this.state.equipment[i])
          equip.push(this.showEquipmentRow(alarmClass,e,onClass,i,i))
        }
    })
    return(
      <table className="list"><tbody>
        <tr><th>Name</th><th>Status</th><td></td></tr>
        {equip}
      </tbody></table>
    )
  }
  
  showEquipmentOneColumn=()=>{
    return(
      <section id="equipments1800" className="right-half">
        <C18DateFormat00 parms={{
          leftString: "Equipment",
          leftClass: "title",
          type: "wrench",
          ariaLabel: "equipment settings",
          onChange: (val)=>this.props.parms.onChange({cmd:"equipmentWrench", val:val})
        }}/>
        <div className="equipment-list">
          {this.showOneEquipment()}
        </div>
      </section>
    )
  }
  
  showEquipmentRow=(alarmClass,e,onClass,i,i2)=>{// i2 is the actual index
// new and added:
//   <tr><td><a href="" class="name">1. Exhaust Variable Speed</a></td><td><span class="on-off-indicator">OFF</span></td>
//     <td><C18Button00 type="button" href="" class="info-button" aria-label="info" disabled>i</C18Button00></td></tr> <!-- MODIFIED: ADDED DISABLED ATTRIBUTE IF NO INFO -->
//   <tr class="equip-hidden-info"><td colspan=3><span>pH Setpoint: 6.0, pH Reading: 5, alternate readings: 0,0, tank level: 0.3 gal, some other info</span></td></tr>
    let showInfo=(e.showInfo)?" show-details":""
//     cl(e)
    return(
      [
        <tr key={i+i2} className={alarmClass+showInfo}><td><C18Anchor00 to="" className="name" onClick={(ev)=>this.onClick("equip",ev,e)}>{`${e.id}. ${(e.name)?e.name:"None"}`}</C18Anchor00></td><td>
        <C18Anchor00 to="" className={`on-off-indicator${onClass}`} onClick={(ev)=>this.onClick("equip",ev,e)}>
          {e.system}</C18Anchor00></td><td>
        <C18Button00 type="button" className="info-button" aria-label="info" onClick={(ev)=>this.onClick("info",ev,e,i2)}>i</C18Button00>
        </td></tr>,
        <tr key={(i+i2)+"b"} className="equip-hidden-info"><td colSpan="3"><span>{e.status}</span></td></tr>
      ]
    )
  }
  
  showTwoEquipment=(col)=>{
    let equip=[]
    let equipLength=this.state.equipment.length
    let colLength=Math.floor((equipLength+1)/2)
    for(let i=0;i<colLength;i++){
      let i2=i+i+col;
      if(i2<equipLength){
        let e=this.state.equipment[i2]
        let onClass=(e.system=="OFF")?"":" on"
        let alarmClass=(e.alarm)?"alarm-on":""
        equip.push(this.showEquipmentRow(alarmClass,e,onClass,i,i2))
//         equip.push(
//           <tr key={i2} className={alarmClass}><td><span className="name">{`${e.id}. ${e.name}`}</span></td><td><span className={`on-off-indicator${onClass}`}>{e.system}</span></td><td><span className="info-button">i</span></td></tr>
//         )
      }else{
        equip.push(
          <tr key={i2}><td></td><td></td><td></td></tr>
        )
      }
    }
    return(
      <table className="list"><tbody>
        <tr><th>Name</th><th>System</th><td></td></tr>
        {equip}
      </tbody></table>
    )
  }
  
  showEquipmentTwoColumn=()=>{
    return(
      <section>
        <div className="section-header">
        <C18DateFormat00 parms={{
          leftString: dateToDisplayDate(new Date(),"mm/dd/yyyy h:mm ap"),
          leftClass: "date",
          type: "wrench",
          ariaLabel: "equipment settings",
          value: this.state.tileMode,
          valueId: "tileMode",
          onChange: (val)=>this.props.parms.onChange({cmd:"equipmentWrench", val:val})
        }}/>

        </div>
        <div className="two-column-equipment-list">
          <div className="left-column">
            {this.showTwoEquipment(0)}
          </div>
          <div className="right-column">
            {this.showTwoEquipment(1)}
          </div>
        </div>
      </section>
    )
  }
  
  render(){
//     cl(this.state)
//     cl(this.props)
    if(this.state.loaded){
      if(this.props.parms.cols==1){
        return this.showEquipmentOneColumn()
      }else{
        return this.showEquipmentTwoColumn()
      }
    }else{
      return <div id="content-area">loading equipment. . .</div>
    }
  }
}
      
export default C18ZoneEquipment00;
