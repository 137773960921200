import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadAccountInfo,loadGatewaysInfo,getGatewayInfo,
  getGatewayIndex, addToAdminLog} from './C18utils'
import {cl, globs, getTime,getRandomString} from '../../components/utils/utils';
import history from "../../history"

class C18ZoneGroups00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      groups:{
        "NgTWZibHrcKqvdPu":{
          "GroupOne":{name:"Group One",zones:["LCUZshedgSw1Xair"],},
          "GroupTwo":{name:"Group Two",zones:["LCUZshedgSw1Xair"],},
        }
      },
      siteSel:-1,
      groupSel:-1,
      showEdit:false,
      editName:"",
      loaded:false,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveZoneGroups)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Zone Groups", url:`/usa/c18/admin/manageZoneGroups`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  saveZoneGroups=async()=>{
    cl("save it")
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneGroups", method: "update", 
      sessionId: globs.userData.session.sessionId, body:{groups:this.state.groups} })
    globs.events.publish("saveOK",true)
  }
  
  addGroup=()=>{
    let st=this.state
    cl(st)
    let siteGroups=Object.assign({},st.groups)
    if(!siteGroups[st.siteSel]){siteGroups[st.siteSel]={}}
    
    let groups=siteGroups[st.siteSel]
    let groupId=getRandomString(16)
    let group={
      name:"New Group",
      zones:[],
    }
    groups[groupId]=group
//     cl(groups)
    this.setState({groups:siteGroups,groupSel:groupId,editName:group.name})
  }
  
  onChange=async(type,vals)=>{
    cl(type,vals)
    let st=this.state
    var group,groups,groupSel,acctGroups
    switch(type){
      case "addGroup":
        this.addGroup()
        break
      case "siteSel":
        groups=st.groups[vals.siteSel]
//         if(groups){vals.groupSel=Object.keys(groups)[0]}
        vals.groupSel=(groups)?Object.keys(groups)[0]:-1
        this.setState(vals)
        break
      case "groupSel":
        group=st.groups[st.siteSel][vals.groupSel]
        vals.editName=group.name
        this.setState(vals)
        break
      case "groupName":
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
      case "checkZone":
        globs.events.publish("savePageEnable",true)
        groups=Object.assign({},st.groups)
        group=groups[st.siteSel][st.groupSel]
        let zones=group.zones
        if(vals.checked){
          zones.push(vals.zoneId)
        }else{
          group.zones=zones.filter(z=>{
            return z!=vals.zoneId})
        }
        this.setState({groups:groups})
        break
      case "editName":
        globs.events.publish("savePageEnable",true)
        groups=Object.assign({},st.groups)
        group=groups[st.siteSel][st.groupSel]
        group.name=vals.groupName
        this.setState({groups:groups})
        break
      case "deleteGroup":
        globs.events.publish("savePageEnable",true)
        groups=Object.assign({},st.groups)
        acctGroups=groups[st.siteSel]
        delete acctGroups[st.groupSel]
        groupSel=(acctGroups)?Object.keys(acctGroups)[0]:-1
        cl(acctGroups)
//         groupSel=acctGroups[0]||-1
//         group=groups[st.siteSel][st.groupSel]
//         group.name=vals.groupName
        this.setState({groups:groups,groupSel:groupSel})
        break
    }
  }
  
  
  
  loadInfo=async()=>{
//     cl("load")
    await loadZonesInfo()
    await loadSitesInfo()
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneGroups", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body:{} })
    if (res.data) {
      let siteSel=globs.sitesInfo.info[0].siteId
      let acctGroups=res.data.groups
      let groups=acctGroups[siteSel]
      let groupSel=(groups)?Object.keys(groups)[0]:-1
      let editName=groups[groupSel].name
      this.setState({loaded:true,siteSel:siteSel,groups:acctGroups,
        groupSel:groupSel,editName:editName})
    }
  }
  
  showGroupOpts=()=>{
    let groups=this.state.groups[this.state.siteSel]
    if(!groups){return[]}
//     cl(this.state.groups)
//     cl(this.state)
//     cl(groups)
    return Object.keys(groups).map((k,i)=>{
      return(
        <option key={i} value={k}>{groups[k].name}</option>
      )
    })
//     return this.state.groups.map((g,i)=>{
//     })
  }
  
  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }
  
  showSelectSite=()=>{
    return (
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showSelectGroup=()=>{
    return (
      <div className="custom-select">
        <label htmlFor="">Select Zone Group</label>
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.groupSel}
          onChange={e=>this.onChange("groupSel",{groupSel: e.currentTarget.value})}
        >
          {this.showGroupOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showAddGroup=()=>{
    return(
      <C18Button00 type="button" className="filled" style={{display:"inline-block"}}
      onClick={()=>this.onChange("addGroup",{})}>Create Group</C18Button00>
    )
  }
  
  showDeleteGroup=()=>{
    return(
      <C18Button00 type="button" className="danger" 
      style={{display:"inline-block",marginLeft:10}} 
      onClick={e=>this.onChange("deleteGroup")}>Delete Group</C18Button00>
    )
  }
  
  showEditName=()=>{
    let st=this.state
    let acctGroups=st.groups
    let groups=acctGroups[st.siteSel]
    let group=groups[st.groupSel]
    return(
      <>
      <label htmlFor="gateway-name">Group Name</label>
      <C18Input00 type="text" id="gateway-name" 
        value={group.name}
        onChange={e=>this.onChange("editName",{groupName:e.currentTarget.value})}
      />
      </>
    )
  }
  
  showZones=()=>{
    let st=this.state
//     cl(globs.zonesInfo.info)
    let zones=(st.groups[st.siteSel]||{})[st.groupSel]?.zones||[]
//     cl(zones)
    let lines=globs.zonesInfo.info.map((z,i)=>{
      if(z.siteId==st.siteSel){
        let checked=zones.includes(z.zoneId)
        return(
            <div key={i} style={{padding:0,cursor:"pointer"}}>
              <input id={`id${i}`} type="checkbox" style={{display:"inline-block"}}
                checked={checked}
                onChange={e=>this.onChange("checkZone",{checked:e.currentTarget.checked,
                  zoneId:z.zoneId})}
              />
              <label htmlFor={`id${i}`} style={{display:"inline-block", marginLeft:20}}>{z.zoneName}</label>
            </div>
        )
      }
    })
    return(
      <div>
      <label htmlFor="gateway-name">Zones in Group</label>
      <div style={{width:500,height:200,overflowY:"auto",
        borderStyle:"solid",borderWidth:1,borderRadius:10}}>
        <div style={{padding:20}}>
          {lines}
        </div>
      
      </div>
      </div>
    )
  }
  
  showEditFields=()=>{
    if(this.state.groupSel!=-1){
      return(
        <div>
        {this.showEditName()}
        <div className="clearfloat"/><br/>
        {this.showZones()}
        </div>
      )
    }else{return null}
  }
  
  render(){
    if(this.state.loaded){
    return(
      <div>
      {this.showSelectSite()}
      <div className="clearfloat"/>
      {this.showSelectGroup()}
      <div className="clearfloat"/>
      {this.showAddGroup()}
      {this.showDeleteGroup()}
      <div className="clearfloat"/><br/>
      {this.showEditFields()}
      </div>
    )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ZoneGroups00;
