import React from 'react';
import UsaIcon from '../../usa/components/UsaIcon';
import AuxControl from './AuxControl'
// import AuxAlarms00 from './AuxAlarms00'
import AuxControls01 from './AuxControls01'
// import AuxPVariables00 from './AuxPVariables00'
import AuxVariables00 from './AuxVariables00'
import SaveCancel from './SaveCancel'
import {wsTrans, getParmValue, checkLoggedIn, getParmInfo,
  initSensorIds,setSensorIdNames,sensorIds} from '../../usa/utils/utils'
import {cl, globs, leadZeros, deepCopy, getTime} from '../../components/utils/utils';
import {getZoneControllers} from '../../components/utils/http';

import {sendArray, dbVals, putZValue} from '../../components/utils/http';
import {pi,pInd} from '../../components/utils/paramIds';
import C18SubMenuHeader00 from '../../usa/components/C18SubMenuHeader00'
import C18CustomSelect01 from '../../usa/components/C18CustomSelect01'
// import './AuxControls.css'
import {loadAuxControls,makeConversionArray,loadDBNames,loadEquipmentNames,
  loadEquipmentTypes,loadSensors,setSensorNames
} from '../utilsFui'
import {loadSitesInfo,loadSiteData,loadZonesInfo,getZoneInfo,getSiteIndex,getZoneIndex,
  loadPresetsInfo,getPresetIndex,getSiteName,getZoneName,getZoneId} 
  from '../../usa/components/C18utils'
import history from "../../history"

class AuxControls extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.props.getValue(props.ind, {type: "scalar"})
    let parts=props.zuci.split("-")
    let zoneInd=+parts[0]
    let unit=+parts[1]
//     cl(this.props)
    this.state=this.makeState(zoneInd,unit)
    this.state.loaded=false
//     this.state={
//       loaded:false,
//       pageType:props.type,
//       saveOK:props.saveOK,
//       auxControls: loadAuxControls(zoneInd,0),//this.makeAuxArray(),
//       variables: loadDBNames(zoneInd, pInd[1800].config_aux_variables, pi[1800].config_aux_variables["variableName"], "Variable"),
//       pVariables: loadDBNames(zoneInd, pInd[1800].config_aux_persistent_variables, 
//         pi[1800].config_aux_persistent_variables["variableName"], "Persistent Variable"),
//       alarms: loadDBNames(zoneInd, pInd[1800].config_aux_alarms, pi[1800].config_aux_alarms["alarmName"], "Alarm"),
//       equipments: loadEquipmentNames(zoneInd,this.props.current.channelInfo),
//       equipmentTypes: loadEquipmentTypes(zoneInd,this.props.current.channelInfo),
// //       sensors: loadSensors(zoneInd),
//       tanks: loadDBNames(zoneInd, pInd[1800].config_ecph, pi[1800].config_ecph["name"], "Mixing Tank"),
//       varSelect: -1,
//       varShow: "",
//       zone: zoneInd,
//       siteId:globs.userData.session.siteId,
//       selController:0,
//     }
    this.loadInfo(zoneInd,unit)
//     this.setBreadCrumbs()
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
  }
  
  makeState=(zoneInd,unit)=>{
//     cl(`make state ${zoneInd} ${unit}`)
    let st={
//       loaded:false,
      pageType:this.props.type,
      saveOK:this.props.saveOK,
//       auxControls: loadAuxControls(zoneInd,unit),//this.makeAuxArray(),
      variables: loadDBNames(zoneInd,unit, pInd[1800].config_aux_variables, pi[1800].config_aux_variables["variableName"], "Variable"),
      pVariables: loadDBNames(zoneInd,unit, pInd[1800].config_aux_persistent_variables, 
        pi[1800].config_aux_persistent_variables["variableName"], "Persistent Variable"),
      alarms: loadDBNames(zoneInd,unit, pInd[1800].config_aux_alarms, pi[1800].config_aux_alarms["alarmName"], "Alarm"),
      equipments: loadEquipmentNames(zoneInd,unit,this.props.current.channelInfo),
      equipmentTypes: loadEquipmentTypes(zoneInd,unit,this.props.current.channelInfo),
//       sensors: loadSensors(zoneInd),
      tanks: loadDBNames(zoneInd,unit, pInd[1800].config_ecph, pi[1800].config_ecph["name"], "Mixing Tank"),
      varSelect: -1,
      varShow: "",
      unit:unit,
      zone: zoneInd,
      siteId:globs.userData.session.siteId,
      selController:unit,
    }
//     cl(this.props.current.channelInfo[0].channelName)
//     cl(st.equipments[0])
    return st
  }
  
  setSensorNames=(unit,sensorIds)=>{
    let sensors={}
    cl(sensorIds)
    Object.keys(sensorIds).forEach(k=>{
      let s=sensorIds[k]
      let u=+k.substring(1,2)
      if(s.lcIndex!=undefined){
        if(u==unit){sensors[s.lcIndex]=s.name}
      }
    })
    cl(sensors)
    return sensors
  }
  
  loadInfo=async(zoneInd,unit)=>{
    this.zoneControllers=getZoneControllers(zoneInd)
//     cl(this.zoneControllers)
    let siteId=globs.userData.session.siteId
    let zoneId=getZoneId(siteId,zoneInd)
    let zoneInfo=getZoneInfo(zoneId)
    let gwType=zoneInfo.gatewayType||1800
//     cl(zoneInfo)
    
    let auxControls=loadAuxControls(zoneInd,unit,gwType)
    cl(auxControls)
    initSensorIds(zoneInd)
    await setSensorIdNames(zoneId)
//     let sensors={}
//     cl(sensorIds)
//     Object.keys(sensorIds).forEach(k=>{
//       let s=sensorIds[k]
//       let u=+k.substring(1,2)
//       if(s.lcIndex!=undefined){
//         if(u==unit){sensors[s.lcIndex]=s.name}
//       }
//     })
//     cl(sensors)
    this.setState({loaded:true,sensors:setSensorNames(unit,sensorIds),
      auxControls:auxControls,
      gwType:gwType})
  }
  
  componentWillUnmount=()=>{
    cl("unMount")
    this.subscribe_savePageEvent.remove()
  }
  
//   componentDidUpdate=(prevProps, prevState, snapshot)=>{
//     cl(prevProps,prevState,snapshot)
//   }
  
  saveCancel=(cmd)=>{
    if(cmd=="save"){
      this.saveAuxControls()
    }
  }
  
  splitOperandOperators=(ac,i)=>{
//     cl(ac)
//     cl("combine operators")
//     cl([ac.operand1Value, ac.operand2Value, ac.operand3Value, ac.targetValue])
//     cl([ac.operand1Type, ac.operand2Type, ac.operand3Type, ac.targetType])
// these two lines were throwing an exception, and had to be unrolled:
//     [ac.operand1Value, ac.operand2Value, ac.operand3Value, ac.targetValue]=ac.operandValues;
//     [ac.operand1Type, ac.operand2Type, ac.operand3Type, ac.targetType]=ac.operandTypes;
    ac.operand1Value=ac.operandValues[0]
    ac.operand2Value=ac.operandValues[1] 
    ac.operand3Value=ac.operandValues[2]
    ac.targetValue=ac.operandValues[3]
    ac.operand1Type=ac.operandTypes[0]
    ac.operand2Type=ac.operandTypes[1]
    ac.operand3Type=ac.operandTypes[2]
    ac.targetType=ac.operandTypes[3]
    cl("split operators")
//     return
    if(this.state.gwType==1900){
      ac.operator1=(ac.operators[0]&0x0F)<<4 | (ac.operators[1]&0x0F)
      ac.operator2=0
    }else{
//       cl(ac.operators)
      ac.operator1=ac.operators[0]
      ac.operator2=ac.operators[1]
      ac.auxIndex=i
    }
// for Pearl    
//     ac.operator1=(ac.operators[0]&0x0F)<<4 | (ac.operators[1]&0x0F)
//     ac.operator2=0
// for 1800
//     cl(ac.operators)
//     ac.operator1=ac.operators[0]
//     ac.operator2=ac.operators[1]
//     ac.auxIndex=i
  }
  
  sendArrayComplete=(r)=>{
    globs.events.publish("saveOK",true)
  }

  saveAuxControls=async()=>{// needs to erase the ones at the end
//     cl("saveAuxControls")
//     cl(this.state.auxControls)
//     cl(this.state.auxControls)
//     cl(this.state.auxControls[0])
//     cl(this.state.auxControls[0].operators)
    globs.events.publish("auxControlSelect",-1)
    let pr=this.props
    let virtual=pr.current.virtual
    let zone=this.state.zone
    let parms=[]
    let mult=pInd[1800].config_aux_controls[2]
    let convArr=makeConversionArray()
//     cl(convArr)
    let t=Math.floor(getTime())
    let z=this.state.zone
    let ind=0
    let u=this.state.unit||0
//     cl((this.state.auxControls[1]||{}).operators)
    this.state.auxControls.forEach((ac,i)=>{
      let iOfs=i*mult
      let good=false
      for(let j=0;j<2;j++){good|=ac.operandTypes[j]}
      if(good){
//         cl(ac)
        this.splitOperandOperators(ac,ind++)// write ind as auxIndex
        for(let k in ac){
//           cl(k)
          if(convArr[k]){
            let pid=iOfs+convArr[k]
            let data=ac[k]
//             cl([z,240,pid,data])
            if(putZValue(z,240+u,pid,data)){
//               cl([z,240,pid,data])
              parms.push({
                c:240+u,// zone wide - not! unit-specific
                d:data,//ac[k],
                f:1,
                i:pid,//iOfs+convArr[k],
                t:t,
                z:z,
              })
            }
          }
        }
      }
    })
    let o1=convArr["operand1Type"]// clear the rest
    let o2=convArr["operand2Type"]
    let o3=convArr["operand3Type"]
    let ids=[o1,o2,o3]
    for(let i=ind;i<128;i++){
      let iOfs=i*mult
      ids.forEach(id=>{
        let pid=iOfs+id
        if(putZValue(z,240+u,pid,0)){
          parms.push({
            c:240+u,// zone wide - not! unit-specific
            d:0,
            f:1,
            i:iOfs+id,
            t:t,
            z:z,
          })
        }
      })
    }
//     cl(parms)
    sendArray(parms,virtual).then(this.sendArrayComplete);
  }
//   setBreadCrumbs=async()=>{
// // the breadcrumbs can be /graphing, /sites/xxx/graph, /sites/xxx/zones/xxx/graph, /sites/xxx/zones/xxx/sensor/xxx
// // props can contain site: zone: sensor: equip:
//     await loadSitesInfo()
//     await loadZonesInfo()
//     let siteId=globs.userData.session.siteId
//     cl(siteId)
//     let p=this.props
//     let parts=p.zuci.split("-")
//     let z=+parts[0]
//     cl(z)
//     cl(globs.zonesInfo.sz2z)
//     let zoneId=globs.zonesInfo.sz2z[siteId][z]
//     let siteName=getSiteName(siteId)
//     let zoneName=getZoneName(zoneId)
// //     let presetName=globs.presetsInfo.info[getPresetIndex(this.props.parms.presetId)].name
//     if(this.props.parms){
// //       cl(this.props)
//       let bc=[
//         {t:"Sites", url:"/usa/c18/sites"},
//         {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
//         {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
//         {t:"aux", url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}/zone_Aux_controls`},
//       ]
//         bc.push(
//         )
// 
//       this.props.parms.onChange(
//         {
//           cmd: "breadcrumbs",
//           data: {breadcrumbs:bc},
//         },
//       )
//     }
//   }
  
//   groupOperandOperators=(ac)=>{
// //     cl(ac.operator1)
//     ac.operandValues=[ac.operand1Value, ac.operand2Value, ac.operand3Value, ac.targetValue];
//     ac.operandTypes=[ac.operand1Type, ac.operand2Type, ac.operand3Type, ac.targetType];
// //     ac.operators=[ac.operator1, ac.operator2];
//     ac.operators=[(ac.operator1>>4)&0x0F, ac.operator1&0x0F];
//   }
  
//   loadAuxControls=(zone)=>{// this will scan the whole array of 128. When they're written back, delete the unused ones at the end
//     let convArr=this.makeConversionArray()
//     let mult=pInd[1800].config_aux_controls[2]
//     let z=zone//this.state.zone
//     let ch=dbVals.z[z][240]
//     let o1=convArr["operand1Type"]
//     let o2=convArr["operand2Type"]
//     let o3=convArr["operand3Type"]
//     let aux=[]
//     for(let i=0;i<128;i++){
//       let iOfs=i*mult
//       let good=(+ch[iOfs+o1])||(+ch[iOfs+o2])||(+ch[iOfs+o3])
//       if(good){
//         let ac={}
//         for (let k in convArr){
//           if(ch[iOfs+convArr[k]]!=undefined){ac[k]=ch[iOfs+convArr[k]]}
//         }
//         this.groupOperandOperators(ac)
//         aux.push(ac)
//       }
//     }
//     return aux
//   }
  
//   makeConversionArray=()=>{
//     let base=pInd[1800].config_aux_controls[0]
//     let arr={}
//     let vars=pi[1800].config_aux_controls
//     for(let k in vars){
//       arr[k]=base+vars[k]
//     }
//     return arr
//   }
  
  loadEquipmentNames=(zone)=>{
    let names=[]
    this.props.current.channelInfo.forEach(ci=>{
//       cl(ci.channelName)
      names.push(ci.channelName)
    })
    return names
  }
// pi[1800].config_aux_alarms["alarmIndex"] = 0
// pi[1800].config_aux_alarms["alarmName"] = 1
// pi[1800].config_aux_alarms["allowGraphing"] = 2
// pi[1800].config_aux_alarms["userComment"] = 3
// pi[1800].config_aux_persistent_variables["variableIndex"] = 0
// pi[1800].config_aux_persistent_variables["variableName"] = 1
// pi[1800].config_aux_persistent_variables["allowGraphing"] = 2
// pi[1800].config_aux_persistent_variables["userComment"] = 3
// pi[1800].config_aux_variables["variableIndex"] = 0
// pi[1800].config_aux_variables["variableName"] = 1
// pi[1800].config_aux_variables["allowGraphing"] = 2
// pi[1800].config_aux_variables["userComment"] = 3

  loadDBNames=(zone, vars, ofs, fillIn)=>{
    let base=vars[0]
    let siz=vars[2]
    let cnt=vars[3]
    let ret=[]
    let ch=dbVals.z[zone][240]
    for(let i=0;i<cnt;i++){
      let id=base+i*siz+ofs
      let name=ch[id]//?ch[id]:``
      let graph=ch[id+1]//?ch[id+1]:0
      let comment=ch[id+2]//?ch[id+2]:`${""}`
//       let name=(ch[id])?ch[id]:``
//       let graph=(ch[id+1])?ch[id+1]:0
//       let comment=(ch[id+2])?ch[id+2]:`${""}`
      ret.push({n:name,g:graph,c:comment})
    }
    return ret
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }

  addVar=(vals)=>{
    cl(vals.v.vars)
    let valType=vals.v.vars
    let baseName={variables:"Variable",pVariables:"Variable",alarms:"Alarm"}[valType]
    let vars=this.state[valType].splice(0)
    cl(vars)
    for(let i=0;i<vars.length;i++){
      if(!vars[i].n){
        vars[i].n=`${baseName} ${i+1}`
        break
      }
    }
    vals={}
    vals[valType]=vars
    cl(vals)
    this.setState(vals)
  }
  
  moveAuxControlDown=(ind)=>{// used for up, too
    let ac=this.state.auxControls.slice(0)
    let oneC=ac.splice(ind,1)[0]
    ac.splice(ind+1,0,oneC)
    cl(this.newProps)
    this.newProps[ind]({parms:{auxControl:ac[ind]}})
    this.newProps[ind+1]({parms:{auxControl:ac[ind+1]}})
    this.setState({auxControls: ac})
    
  }
  
  deleteAuxControl=(ind)=>{
    let ac=this.state.auxControls.slice(0)
    ac.splice(ind,1)
    this.setState({auxControls: ac})
    for(let i=0;i<ac.length;i++){
      this.newProps[i]({parms:{auxControl:ac[i]}})
    }
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    var ac
    switch(type){
      case "auxPage":// from menu
        let url=`/usa/c18/fui/${vals.pageType}/${this.props.zuci}`
        this.setState({pageType:vals.pageType})
        history.push(url)
        cl(url)
        break
      case "subPage":
        switch(vals.t){
          case "upd":
            globs.events.publish("savePageEnable",true)
            let valType=vals.v.vars
            let vars=this.state[valType].slice(0)
            let v=vars[vals.v.i]
            delete vals.v.i
            delete vals.v.vars
            Object.assign(v,vals.v)
            vals={pageChange:valType}// a hack. this is the table that needs to be saved when we change pages
            vals[valType]=vars
            this.setState(vals)
            break
          case "addVar":
            this.addVar(vals)
            break
          case "addAux":
//             cl("do add aux")
//             cl(vals)
            ac=this.state.auxControls.slice(0)
            ac.push(vals.v.ac)
            this.setState({auxControls: ac})
            break
          case "auxControl":
/* vals is t: for type, and v: which is i: for index, and v: for the aux control*/
//             cl(vals.v.v.t)
            switch(vals.v.v.t){
              case "select":
//                 cl("new select")
                globs.events.publish("auxControlSelect",vals.v.i)                
                break
              case "auxControl":// updated aux control
//                 cl(vals)
                globs.events.publish("savePageEnable",true)
                ac=this.state.auxControls.slice(0)
//                 cl("deep copy of aux control")
                ac[vals.v.i]=deepCopy(vals.v.v.v)
//                 cl(ac)
//                 cl(ac[1].operators)
                this.setState({auxControls: ac})
                break
              case "button":
//                 cl(vals.v.i)
                globs.events.publish("savePageEnable",true)
                let ind=vals.v.i
                switch(vals.v.v.v.t){
                  case "up":
                    cl("up")
                    if(ind>=1){this.moveAuxControlDown(ind-1)}
//                     this.moveAuxControlDown(ind-1)
                    break
                  case "down":
                    cl("down")
                    if(ind<this.state.auxControls.length-1){this.moveAuxControlDown(ind)}
//                     this.moveAuxControlDown(ind)
                    break
                  case "delete":
//                     cl("delete")
                    let res=await this.props.getPopup({text:`Are you sure you 
                      want to delete Aux Control ${vals.v.i+1}?`, buttons:["Cancel","Yes"]})
                    if(res=="Yes"){
                      this.deleteAuxControl(ind)
                    }
                    break
                }
                break
            }
            break
        }
        break
      case "cont":
        let pr=this.props
        let parts=pr.zuci.split("-")
        let zone=+parts[0]
        let unit=+vals.selController
        parts[1]=unit
        let zuci=parts.join("-")
        history.push(`/usa/c18/fui/${pr.type}/${zuci}`)
//         globs.events.publish("newController",unit)
        await this.props.rest.parent({uri:"makeCurrent",method:"retrieve",body:{unit:unit}})
        let st=await this.makeState(zone,unit)
        st.sensors=this.setSensorNames(unit)
        cl(st.sensors[6])
        await this.setState(st)
        this.state.auxControls.forEach((ac,i)=>{
//           cl(`call new prop ${i}`)
          if(this.newProps[i]){this.newProps[i]({parms:{auxControl:ac}})}
        })
        break
    }
  }
  
//   newController=(zone,unit)=>{
//     cl(`new controller: ${unit}`)
//   }
  
  newProps={}
  saveNewProps=(f,i)=>{// to register a components newProps function
//     cl("new props")
    this.newProps[i]=f
  }
  
  showAuxPage=()=>{
    let parms=Object.assign({
      onChange:(type,vals)=>{this.onChange("subPage",{t:type,v:vals})},
      zuci:this.props.zuci,
      newProps:(f,i)=>this.saveNewProps(f,i),
      saveOK:this.props.saveOK,
      sensors:this.state.sensors,
      getPopup:this.props.getPopup,
    },this.state)
    return(this.state.pageType=="zone_Aux_Controls")?
      <AuxControls01 parms={parms}/>:
      <AuxVariables00 parms={parms}/>
  }

     
  showControllerSelect=()=>{
//     cl("show")
    let options=[{v:0,t:"Base Controller"}]
    this.zoneControllers.forEach((c,i)=>{
      if(i&&c){options.push({v:i,t:`Expansion ${i}`})}
      
    })
    return(
      <div style={{float:"right"}}>
        <C18CustomSelect01 parms={{
          id:"contSel",
          title:"Controller Select",
          value:this.state.selController,
          onChange:e=>this.onChange("cont",{selController:e.currentTarget.value}),
          options:options,
          hideTitle:true,
        }}/>
      </div>
    )
  }
  
  render(){
//     cl(this.state)
    let pages=[
      {v:"zone_Aux_Controls",t:"Aux Controls", p:AuxControls01},
      {v:"zone_Aux_Variables",t:"Aux Variables", p:AuxVariables00},
      {v:"zone_Aux_PVariables",t:"Aux Persistent Variables", p:AuxVariables00},
      {v:"zone_Aux_Alarms",t:"Aux Alarms", p:AuxVariables00},
    ]
    let cnt=0
    if(this.zoneControllers){this.zoneControllers.forEach(c=>{cnt+=c})}
//     var Page=AuxControls01
//     pages.forEach(p=>{if(this.state.pageType==p.v){
// //       cl(p)
//       Page=p.p}})
    if(this.state.loaded){
      return(
        <div>
          <C18SubMenuHeader00 parms={{
            items:pages,
            pageType: this.state.pageType,
            onChange: o=>this.onChange("auxPage",o),
                    }}/>
          {(cnt>1)&&this.showControllerSelect()}
          <div className="clearfloat"/>
          {this.showAuxPage()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default AuxControls ;
