import React from 'react';
import C18Anchor00 from './C18Anchor00'
import C18Button00 from './C18Button00'
import ReactHtmlParser from 'react-html-parser'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans,sensorIds,getUId,doGetPostBasic} from '../utils/utils'
import {cl,globs,constant,dateToDisplayDate,recurlySubdomain,dateToMoDaYrStr} from '../../components/utils/utils';
import {getRecurlyAccounts} from './C18utils'

class C18SalesPortal00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      selAccount: "",
      sortMode:1,// site up
      planType: "",
      subStatus: "",
      billingStatus: "",
    }
    this.loadInfo()
    this.setBreadCrumbs()
    this.pageTitles={accounts:"Accounts"}
    this.pageParms={
      accounts:["accountSearch","accounts","name","adminEmail","accountId","selAccount"],
    }
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.bgColor1=(this.dark)?"#202020":"FFFFFF"
    this.bgColor2=(this.dark)?"#203030":"FFFFFF"
    this.selColor1=(this.dark)?"#606060":"DDDDDD"
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Sales Portal", url:`/usa/c18/admin/salesPortal`},
            ]},
        },
      )
    }
  }
  
  loadWsTrans=async(parms)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: parms.uri, 
      method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId, 
      body: parms.body})
  }
  
  loadInfo=async()=>{
// users, sites, accounts, zones, gateways,   
    // request account info for all 2P0 accounts?
    let recurlyAccounts = await getRecurlyAccounts()
//     cl(recurlyAccounts)
    let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    accounts.forEach((a) => {
      let rInfo = recurlyAccounts.find((r)=> r.accountId == a.accountId)
      if (rInfo) Object.assign(a, rInfo)
    })
//     cl(accounts)
    this.setState({
      loaded:true,
      accounts:accounts,
    })
  }

  downloadCsv=async()=>{
    let st = this.state
    cl(st)
    let [searchId, type, field1, field2, itemId, selId] = this.pageParms["accounts"]
    // get remaining items after filter applied
    let items=[...this.filterList(searchId, type, field1, field2)]
    cl(items)
    // convert items to form data
    let data = new FormData()
    let date = new Date()
    items.forEach((item, idx)=>{
      // create fields for each item
      let expired = (item.end_date && (new Date(item.end_date)) < (new Date())) ? "Expired" : "Active"
      item.expired = expired
      let period = item.add_ons && item.add_ons.annual ? "Annual" : "Monthly"
      item.period = period
      let billingValid = item.billingInfo && item.billingInfo.valid ? "Valid" : "Invalid"
      item.billingValid = billingValid
      let billingExpDate = item.billingInfo?.expDate
      item.billingExpDate = billingExpDate
      let zones = item.add_ons && item.add_ons.zone_qty?.zone?.unlocked
      item.zones = zones
      delete item.billingInfo
      delete item.add_ons
      data.append(idx, JSON.stringify(item))
    })
    let da0=new Date()
    let tzo=da0.getTimezoneOffset()
    let da=dateToDisplayDate(da0,"yyyy_mm_dd_hh_mm_ss",tzo)
    let query=`session=${globs.userData.session.sessionId}&type=sales`
    cl(da)
    cl(query)
    let url=`${constant.expressUrl}/usa/csv/download_${da}.csv?${query}`
    cl(url)
    let method = "POST"
    let contentType = "multipart/form-data"
    let res = await doGetPostBasic(url, method, data, contentType)
    let ret = await res.blob()
    const durl = window.URL.createObjectURL(new Blob([ret])) 
    const link = document.createElement('a')
    link.href = durl
    link.setAttribute('download', `${da}.csv`)
    document.body.appendChild(link)
    link.click()
    // window.location.href=url
  }

  onChange=(type,vals)=>{
//     cl([type,vals])
    switch(type){
      case "searchText":
        let val2={}
        val2[vals.id]=vals.search
//         cl(val2)
        this.setState(val2)
        break
      case "itemClick":
        cl(vals)
        // set selected account
        this.setState(vals)
        break
      case "sort":
        this.sortAccounts(vals.column)
        break
      case "filter":
        cl(vals)
        this.setState(vals)
        break
      case "downloadCsv":
        cl("download csv")
        this.downloadCsv()
    }
  }

  sortAccounts=(col)=>{
    var sortMode
    if(Math.abs(col)==Math.abs(this.state.sortMode)){
      sortMode=0-this.state.sortMode
    }else{
      sortMode=col
    }
    this.setState({sortMode:sortMode,accounts:this.doSortAccounts(sortMode,this.state.accounts.slice(0))})
//     cl(col)
  }

  doSortAccounts=(sortMode,accounts)=>{
    accounts.sort((a,b)=>{
      let dir=(sortMode>0)?1:-1
      switch(Math.abs(sortMode)){
        case 1:// account name
          let aName = a.name?.toLowerCase() || ""
          let bName = b.name?.toLowerCase() || ""
          if(aName<bName){return 0-dir}
          if(aName>bName){return dir}
        case 2:// plan name
          let aPlan = a.plan_name?.toLowerCase() || ""
          let bPlan = b.plan_name?.toLowerCase() || ""
          if(aPlan<bPlan){return 0-dir}
          if(aPlan>bPlan){return dir}
        case 3:// # of unlocked zones
          let aCount = parseInt(a.add_ons?.zone_qty?.zone?.unlocked || 0)
          let bCount = parseInt(b.add_ons?.zone_qty?.zone?.unlocked || 0)
          if(aCount<bCount){return 0-dir}
          if(aCount>bCount){return dir}   
        case 4:// renewal/expiration date
          let aDate = (a.end_date && new Date(a.end_date)) || ""
          let bDate = (b.end_date && new Date(b.end_date)) || ""
          if(aDate<bDate){return 0-dir}
          if(aDate>bDate){return dir}
      }
    }).slice(0)
    return accounts
  }

  filterPlan=(acct)=>{
    let st = this.state
    if (acct.plan_name) {
      switch (st.planType) {
        case "Trial":
          return acct.plan_code == "cloud2p0trialsub"
        case "Paid":
          return acct.plan_code != "cloud2p0trialsub"
        case "By License Count":
          return parseInt(acct.add_ons?.zone_qty?.zone?.unlocked || 0) >= st.zoneCount
        default:
          return true
      }
    }
    return st.planType == ""
  }

  filterSub=(acct)=>{
    let st = this.state
    if (acct.end_date) {
      let curr = new Date()
      let end = new Date(acct.end_date)
      switch (st.subStatus) {
        case "Active":
          return end >= curr
        case "Expired":
          return end < curr
        case "Days Remaining":
          let exp = new Date(curr.getTime() + st.dayCount * 86400000)
          cl(dateToMoDaYrStr(curr))
          return end >= curr && end < exp
        default:
          return true
      }
    }
    return st.subStatus == ""
  }

  filterBilling=(acct)=>{
    let st = this.state
    if (acct.billingInfo) {
      let curr = new Date()
      let end = new Date(acct.billingInfo.expDate)
      switch (st.billingStatus) {
        case "Valid":
          return acct.billingInfo.valid && end >= curr
          // return acct.billing_info.valid && end > curr
        case "Expired":
          return end < curr
        case "Missing":
        // handled by acct.billing_info existing in first place
          return false
        case "Past Due":
          return acct.billingInfo.hasPastDueInvoice
        case "Days Remaining":
            let exp = new Date(curr.getTime() + st.bdayCount * 86400000)
            cl(dateToMoDaYrStr(curr))
            return end >= curr && end < exp
        default:
          return true
      }
    }
    return st.billingStatus == "" || st.billingStatus == "Missing"
  }
  
  filterTest=(acct)=>{
//     if(acct.accountId=="a036uzDCxohZ7ovD"){
//       cl(acct)
//       cl(acct?.features)
//       cl(acct.features.includes("testAcct"))
//     }
    return !(acct?.features?.includes("testAcct")||false)
  }

  filterList=(searchId,type,field1,field2)=>{
    let st=this.state
    let search=(st[searchId]||"").toLowerCase()
        // planType: "",
        // subStatus: "",
        // billingStatus: "",
//     cl(st.accounts)
    let accts=st.accounts.filter(us=>{
      let f1=(us[field1]||"").toLowerCase().indexOf(search)>=0
      let f2=(us[field2]||"").toLowerCase().indexOf(search)>=0
      let f3=this.filterPlan(us)
      let f4=this.filterSub(us)
      let f5=this.filterBilling(us)
      let f6=this.filterTest(us)
      return (f1||f2) && f3 && f4 && f5 && f6
    })
    // apply filters to add/remove things from the list
    return accts
  }
  
  // showItems2=()=>{
  //   let [searchId, type, field1, field2, itemId, selId] = this.pageParms["accounts"]
  //   let items=this.filterList(searchId, type, field1, field2)
  //   return(
  //     <div style={{display:"inline-block"}}>
  //       <div style={{width:"100%",height:200,backgroundColor:this.bgColor2,marginRight:30,
  //       padding:10,overflowY:"auto"}}>
  //       <table><tbody>
  //         {items.map((us,i)=>{
  //           let name=us[field1]||"" 
  //           // let name2=us[field2]
  //           // name=((name2)?`${name}-${name2}`:name)
  //           let val={}
  //           val[selId]=us[itemId]
  //           let bg = (this.state[selId] == us[itemId]) ? this.selColor1:null
  //           return(
  //             <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}}
  //               onClick={e=>this.onChange("itemClick",val)}
  //             ><td>{name}</td></tr>
  //           )
  //         })}
  //       </tbody></table>
  //       </div>
  //     </div>
  //   )
  // }

  showDownloadCsv=()=>{
    return(
      <div className="float-left-box">
        <C18Button00 id="downloadCsv" className="icon"
        onClick={e=>this.onChange("downloadCsv")}>
          Download CSV
          <span className="material-icons">
          file_download
          </span>
        </C18Button00>
      </div>
    )
  }

  showRecurlyAccountButton = (accountId) => {
    if (accountId) {
      return (
        <C18Button00 className="outlined">
            {
              <a target="_blank" rel="noopener noreferrer" href={`https://${recurlySubdomain}.recurly.com/accounts/${accountId}`} className="colored-link-text">
                View Account on Recurly
              </a> 
            }
        </C18Button00>
      )
    }
  }

  showAccountInfo = (aInfo) => {
    return (
      <>
      <h2>Account Info</h2>
      <label htmlFor="account-zone">Account Name</label>
      <span>{aInfo.name}</span>
      <label htmlFor="account-zone">Admin Email</label>
      <span>{aInfo.adminEmail}</span>
      </>
    )
  }

  showSubscriptionInfo = (rInfo) => {
    let expired = (rInfo.end_date && (new Date(rInfo?.end_date)) < (new Date())) ? "(Expired)" : ""
    return (
      <>
      <h2>Subscription Info</h2>
      <label htmlFor="account-zone">Plan Name</label>
      <span>{rInfo.plan_name}</span>
      <label htmlFor="account-zone">Unlocked Zones</label>
      <span>{rInfo.add_ons && rInfo.add_ons.zone_qty?.zone?.unlocked}</span>
      <label htmlFor="account-zone">{(rInfo?.plan_code == "cloud2p0trialsub" || !rInfo?.auto_renew) ? "End Date" : "Next Renewal Date"}</label>
      <span>{rInfo.end_date && `${dateToMoDaYrStr(new Date(rInfo.end_date))} ${expired}`}</span>
      <label htmlFor="account-zone">Period</label>
      <span>{rInfo.add_ons && rInfo.add_ons.annual ? "Annual" : "Monthly"}</span>
      <label htmlFor="account-zone">Auto Renew</label>
      <span>{rInfo?.auto_renew ? "Enabled" : "Disabled"}</span>
      </>
    )
  }

  showRecurlyAccountInfo = (rInfo) => {
    return (
      <>
      <h2>Recurly Account Info</h2>
      <label htmlFor="account-zone">Account Name</label>
      <span>{rInfo.username}</span>
      <label htmlFor="account-zone">Email</label>
      <span>{rInfo.email}</span>
      <label htmlFor="account-zone">State</label>
      <span>{rInfo.active}</span>
      </>
    )
  }

  showBillingInfo = (rInfo) => {
    let expired = (rInfo.billingInfo && (new Date(rInfo?.billingInfo.expDate)) < (new Date())) ? "(Expired)" : ""
    return (
      <>
      <h2>Billing Info</h2>
      <label htmlFor="account-zone">Valid</label>
      <span>{rInfo.billingInfo && rInfo.billingInfo.valid ? "Valid" : "Invalid"}</span>
      <label htmlFor="account-zone">Expiration Date</label>
      <span>{rInfo.billingInfo && `${dateToMoDaYrStr(new Date(rInfo?.billingInfo?.expDate))} ${expired}`}</span>
      <label htmlFor="account-zone">Last Updated</label>
      <span>{rInfo.billingInfo && `${dateToMoDaYrStr(new Date(rInfo?.billingInfo?.updatedAt))}`}</span>
      </>
    )
  }

 
  showFilters = () => {
    let st = this.state
    return (
      <>
        <h2>Filters</h2>

        <label htmlFor="account-zone">Plan Type</label>
        <span className="custom-select">
          <select id="account-zone"
            value={st.planType}
            onChange={e=>this.onChange("filter",{planType:e.currentTarget.value})}
          >
          {["", "Trial", "Paid", "By License Count"].map((tz,i)=>{
            return(
              <option key={i} value={tz}>{tz}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>

        {this.state.planType == "By License Count" &&
          <>
          <span>At least,    </span>
          <input 
            type="number" 
            id="zone-unlocked" 
            value={st.zoneCount}
            onChange={(e) => this.onChange("filter", {zoneCount:e.currentTarget.value})}
            min={0}
            max={999}
            inputMode="decimal"
            display="inline"
            className="zone-counter"
           />
           <span> licenses</span>
           </>
          }


        <label htmlFor="account-zone">Subscription Status</label>
        <span className="custom-select">
          <select id="account-zone"
            value={st.subStatus}
            onChange={e=>this.onChange("filter",{subStatus:e.currentTarget.value})}
          >
          {["", "Active", "Expired", "Days Remaining"].map((tz,i)=>{
            return(
              <option key={i} value={tz}>{tz}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>

        {this.state.subStatus == "Days Remaining" &&
          <>
          <span>At most,    </span>
          <input 
            type="number" 
            id="zone-unlocked" 
            value={st.dayCount}
            onChange={(e) => this.onChange("filter", {dayCount:e.currentTarget.value})}
            min={-999}
            max={999}
            inputMode="decimal"
            display="inline"
            className="day-counter"
           />
          <span> days remaining until renewal/expiration</span>
          </>
        }

        <label htmlFor="account-zone">Billing Status</label>
        <span className="custom-select">
          <select id="account-zone"
            value={st.billingStatus}
            onChange={e=>this.onChange("filter",{billingStatus:e.currentTarget.value})}
          >
          {["", "Valid", "Missing", "Expired", "Past Due", "Days Remaining"].map((tz,i)=>{
            return(
              <option key={i} value={tz}>{tz}</option>
            )
          })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>

        {this.state.billingStatus == "Days Remaining" &&
          <>
          <span>At most,    </span>
          <input 
            type="number" 
            id="zone-unlocked" 
            value={st.bdayCount}
            onChange={(e) => this.onChange("filter", {bdayCount:e.currentTarget.value})}
            min={-99999}
            max={99999}
            inputMode="decimal"
            display="inline"
            className="bday-counter"
           />
          <span> days remaining until billing expiration</span>
          </>
        }

      </>
    )
  }

  showSearch = (id) => {
    return(
      <div>
        <label>Search</label>
        <input type="text"
          value={this.state[id]||""}
          onChange={e=>this.onChange("searchText",{id:id,search:e.currentTarget.value})}
        />
      </div>
    )
  }

  showItems=()=>{
    let st = this.state
    let [searchId, type, field1, field2, itemId, selId] = this.pageParms["accounts"]
    // get remaining items after filter applied
    let items=this.filterList(searchId, type, field1, field2)

    // table controls
    let icons=[]
    let sortMode=st.sortMode
    for(let i=0;i<4;i++){
      var icon
      if((Math.abs(sortMode)==(i+1))){
        icon=(sortMode>0)?"keyboard_arrow_down":"keyboard_arrow_up"
      }else{
        icon=""
      }
      icons.push(icon)
    }

    return(
      <div style={{display:"inline-block"}}>
        <div className="table-container">
          <table className="list"><tbody>
            <tr>
              <th style={{textAlign: "center", width:"25%"}}>
                <button type="button" aria-label="sort-account" 
                  onClick={()=>this.onChange("sort",{column:1})}>
                  <h2>Account <span className="material-icons-outlined">{icons[0]}</span></h2>
                </button>            
              </th>
              <th style={{textAlign: "center", width:"25%"}}>
                <button type="button" aria-label="sort-plan" 
                  onClick={()=>this.onChange("sort",{column:2})}>
                  <h2>Plan <span className="material-icons-outlined">{icons[1]}</span></h2>
                </button>            
              </th>
              <th style={{textAlign: "center", width:"20%"}}>
                <button type="button" aria-label="sort-unlocked" 
                  onClick={()=>this.onChange("sort",{column:3})}>
                  <h2>Unlocked <span className="material-icons-outlined">{icons[2]}</span></h2>
                </button>            
              </th>
              <th style={{textAlign: "center", width:"30%"}}>
                <button type="button" aria-label="sort-date" 
                  onClick={()=>this.onChange("sort",{column:4})}>
                  <h2>Expires/Renews On<span className="material-icons-outlined">{icons[3]}</span></h2>
                </button>            
              </th>
            </tr>
          </tbody></table>
        </div>
        <div style={{width:800,height:300,backgroundColor:this.bgColor2,marginRight:30,
        padding:10,overflowY:"auto"}}>
        <div className="table-container">
          <table className="list"><tbody>
            {items.map((a,i)=>{
              let name=a[field1]||"" 
              // let name2=us[field2]
              // name=((name2)?`${name}-${name2}`:name)
              let val={}
              val[selId]=a[itemId]
              let bg = (st[selId] == a[itemId]) ? this.selColor1:null
              return(
                <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}} onClick={e=>this.onChange("itemClick",val)}>
                  <td style={{width:"25%"}}>{a.name}</td>
                  <td style={{width:"25%"}}>{a.plan_name}</td>
                  <td style={{textAlign: "center", width:"25%"}}>{a.add_ons?.zone_qty?.zone?.unlocked || 0 }</td>
                  <td style={{width:"25%"}}>{a.end_date && dateToMoDaYrStr(new Date(a.end_date))}</td>
                </tr>
              )
            })}
          </tbody></table>
        </div>
        </div>
        <br/>
        <div><i>{items.length} results found...</i></div>
        <br/>
      </div>
    )
  }

  showSelectedInfo = () => {
    let st = this.state
    // rSubInfo.username = acct.username
    // rSubInfo.email = acct.email
    // rSubInfo.active = acct.active
    // // attach billing info to accounts
    // rSubInfo.billingInfo = {}
    // rSubInfo.billingInfo.id = acct.billing_info?.id
    // rSubInfo.billingInfo.valid = acct.billing_info?.valid || false
    // rSubInfo.billingInfo.expDate
    // rSubInfo.billingInfo.updatedAt = acct.billing_info?.updatedAt
    // rSubInfo.billingInfo.fraud = acct.fraud?.decision
    if (st.selAccount) {
      // find account and sub info from account id
      let aInfo = st.accounts.find((a)=> a.accountId == st.selAccount)
      cl(aInfo)
      return (
        <div className="manage-account">
          {this.showAccountInfo(aInfo)}
          {this.showSubscriptionInfo(aInfo)}
          {this.showRecurlyAccountInfo(aInfo)}
          {this.showBillingInfo(aInfo)}
          {this.showRecurlyAccountButton(aInfo.recurlyAccountId)}
        </div>
      )
    }
  }
  
  render(){
    let st=this.state
    if (st.loaded) {
      return(
        <div>
            {this.showFilters()}
            {this.showSearch("accountSearch")}
            {this.showItems()}
            <br/>
            {this.showDownloadCsv()}
            <br/>
            <br/>
            <br/>
            <div className="manage-account-wrapper">
              {this.showSelectedInfo()}
            </div>
        </div>
      )
    } else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18SalesPortal00;
