import React from 'react';
import Select from '@material-ui/core/Select';
import {cl,globs} from '../../components/utils/utils';

class C18MuiSelect extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
    }
  }
  
  render(){
//     cl(this.props.value)
//     cl(this.props.children)
    let pr=this.props
    if(globs.noMui){
      return(
        <>
        <div  className="custom-select float-left-box">
        <select
          id={pr.id}
          value={pr.value}
          onChange={pr.onChange}
          disabled={!pr.saveOK}
          style={{backgroundColor:pr.bgColor}}
        >
          {pr.children}
        </select>
        <span className="material-icons down-arrow" style={{pointerEvents:"none", zIndex: 2}}>
          keyboard_arrow_down
        </span>
        </div>
        {pr.unit &&
          <span className="units">{pr.unit}</span>
        }
        {(pr.break!="none")&&
          <div className="clearfloat"></div>
        }
        </>
      )
      return null
    }else{
      return React.createElement(Select,pr)
    }
  }
}
      
export default C18MuiSelect;
 
