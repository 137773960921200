import React from "react";
// import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
import {cl,globs,lerpColor} from "../../components/utils/utils";
// import Input from "@material-ui/core/Input";
// import C18MuiTextField from '../../usa/components/C18MuiTextField'
// import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInput from '../../usa/components/C18MuiInput'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import {ArrayExpression as state} from "@babel/types";
// import InputLabel from "@material-ui/core/InputLabel";

class FUINumerical extends React.Component{
    /**
     * width - width of the temperature box
     * min - Minimum allowed temperature (-200)
     * max - Maximum allowed temperature (200)
     * step - Step per arrow press (0.1)
     * unit - The degree type, assumes given temp is in right units (F)
     * @param props -
     */
    constructor(props) {
        super(props);
        this.props.getValue(props.ind, {type: "scalar"})
        this.tempWidth = 100;
        this.unit = "";
        this.min = -5000;
        this.max = 5000;
        this.mult=1
        this.step = .1;
        this.decimal = 2;
        if ("custom" in props){
            if ("min" in props.custom && !isNaN(props.custom.min))
                this.min = Number(props.custom.min);
            if ("max" in props.custom && !isNaN(props.custom.max))
                this.max = Number(props.custom.max);
            if ("mult" in props.custom && !isNaN(props.custom.mult))
                this.mult = Number(props.custom.mult);
            if ("step" in props.custom && !isNaN(props.custom.step))
                this.step = Number(props.custom.step);
            if ("unit" in props.custom)
                this.unit = this.getUnit();
            if ("width" in props.custom && !isNaN(props.custom.width))
                this.tempWidth = Number(props.custom.width);
            if ("decimal" in props.custom && !isNaN(props.custom.decimal))
                this.decimal = Number(props.custom.decimal);
        }
        this.state={
            type: "text",
            isFocus: false,
        }
        this.handleBlur = this.handleBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.canBlur = false;
        this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
        this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
        this.changed=false
    }
    
    updateFui=()=>{
      if(!this.changed){this.props.getValue(this.props.ind, {type: "scalar"})}
    }
    
    savePageEvent=()=>{
      this.changed=false
    }

    componentWillUnmount=()=>{
      this.subscribe_updateFui.remove()
      this.subscribe_savePageEvent.remove()
    }
  
    getUnit=()=>{
//       cl(this.props.custom.unit)
//       cl(this.props.current?.units)
        let unitTab = {
            degf: [0, "\u00B0F", "\u00B0C"],
            mph: [1, "mph", "kph"],
            wpm2: [2, "w/m²", "klux", "uMol"],
            liter: [3, "L", "gal", "gal"],
            ml: [3, "ml", "oz", "ml"],
            uS: [4, "uS", "CF", "PPM"],
            kPa: [5, "kPa"],
            deg: [6, "\u00B0"],
        }
        let unitType = unitTab[this.props.custom.unit]
        if (unitType === undefined){
            return this.props.custom.unit;
        }else{
            let unitSel = 1 + ((+this.props.current.units[unitType[0]])||0);
//             cl(unitType[unitSel])
            return unitType[unitSel];
        }
    }

    getColor=(val)=>{
        let startCol = [0, 0, 0, 0]
        let endCol
        switch (this.props.custom.unit) {
            // blue to red
            case "degf":
                startCol = [85, 112, 235, 1]
                endCol = [235, 85, 85, 1]
                break
            // clear to orange
            case "mph":
                startCol = [212, 165, 121, 0]
                endCol = [212, 165, 121, 1]
                break
            // clear to yellow
            case "wpm2":
                endCol = [212, 206, 121, 0]
                endCol = [212, 206, 121, 1]
                break
            // clear to blue
            case "liter":
                startCol = [108, 147, 184, 0]
                endCol = [108, 147, 184, 1]
                break
            // clear to magenta 
            case "ml":
                startCol = [196, 108, 174, 0]
                endCol = [196, 108, 174, 1]
                break
            // clear to green 
            case "uS":
                startCol = [130, 196, 108, 0]
                endCol = [130, 196, 108, 1]
                break
            // clear to brown 
            case "kPa":
                startCol = [186, 122, 86, 0]
                endCol = [186, 122, 86, 1]
                break    
            // clear to light blue 
            case "deg":
                startCol = [167, 172, 196, 0]
                endCol = [167, 172, 196, 1]
                break
            default:
                startCol = [167, 172, 196, 0]
                endCol = [167, 172, 196, 1]
                break
        }
        return lerpColor(startCol, endCol, (val + Math.abs(this.min)) / Math.abs(this.max))
    }

    handleBlur = e => {
        if (this.canBlur) {
            this.setState({
                type: "text",
                isFocus: false,
            });
            let tValue = (Number(e.target.value).toFixed(this.decimal));
//             cl(tValue)
            tValue = tValue > this.max ? this.max : tValue; //Ensure it handles max value on blur
            tValue = tValue < this.min ? this.min : tValue; //Ensure it handles min value on blur
            let obj = {type: "scalar", value: this.mult*tValue};
            this.props.onChange(this.props.ind, obj)

        }
    };

    onFocus = e => {
//       cl("on focus")
        this.canBlur = false;
        this.setState({
            type: "number",
            isFocus: true,
        });
        this.nameInput.focus();
        setTimeout( () => {
            this.canBlur = true;
        },10);
    };

    onChange = e =>{
//       cl(e.target.value)
      let obj = {type: "scalar", value: +e.target.value/**this.mult*/};
//       cl(obj)
      this.props.onChange(this.props.ind, obj)
      this.changed=true// blocks updating
    };

    render() {
      var val,type
      val=+this.props.value
      if(this.step){
        let dec=Math.round(Math.log(this.step)/-2.3)
        val=+(this.step*Math.round(val/this.step)).toFixed(dec)
      }
//       cl(["a",val])
//       cl(val)
      type="number"
//       if(this.state.isFocus){
//         val=this.props.value
//         type="number"
//       }else{
// //         val=`${this.props.value} ${this.unit}`
//         val=this.props.value
// //         type="text"
//         type="number"
//       }
//       cl(this.state)
//         this.props.getValue(this.props.ind, {type: "scalar"})
//       cl(this.state.type)
//                     value={this.state.type === "number" //If input's type is a number
//                         ? this.props.value/this.mult //Display just number but rounded Number(this.props.value).toFixed(this.decimal)
//                         : ( Number(this.props.value/this.mult).toFixed(this.decimal) +" "+this.unit)} //Display text with units
        // assign color based on unit
      let bgColor = this.getColor(val)
      let pr=this.props
        return (
            <div id={pr?.custom?.infoId}>
                <C18MuiInputLabel htmlFor="age-simple">{pr.cont.title}</C18MuiInputLabel>
                <C18MuiInput
                    id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
                    key="temperature"
                    margin="dense"
                    value={val/*pr.value*/}
                    refx={(input) => {this.nameInput = input;}}
                    onChange={this.onChange}
                    onBlur={this.handleBlur}
                    onFocus={this.onFocus}
                    saveOK={pr.saveOK}
                    type={type}
                    unit={this.unit}
                    bgColor={bgColor}
                    inputProps={{
                        step: this.step,
                        min: this.min,
                        max: this.max,
                        inputMode:"decimal",
                        type: this.state.type,
                        style: {textAlign: 'center'}
                    }}
                />
            </div>
        );
    }
}

export default FUINumerical ;
