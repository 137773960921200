import React from 'react';
import {cl} from './utils'

var makeOptions=(opts)=>{
    return Object.keys(opts).map((k,i)=>{
      return (
        <option key={i} value={k}>{opts[k].t}</option>
      )
    })
  }
  
var showSelect=(type, val,valId,ind,opts, onChange)=>{
//   cl(opts)
    return(
      <select style={{marginLeft: "0.3em",}}
        value={val} 
        onChange={e=>onChange(type, valId,ind,e)}
      >
      {makeOptions(opts)}
      </select>
    )
  }

var showInput=(type, val, valId, ind, onChange)=>{
    return(
      <input
        style={{width:70}}
        value={val}
        type="number"
        inputMode="decimal"
        onChange={e=>onChange(type, valId, ind, e)}
      />
    )
  }

  export {showSelect, showInput
}
