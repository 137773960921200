import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import {cl,globs,lerpColor} from '../../components/utils/utils';

class BiweeklyRepeat extends React.Component{

    constructor(props) {
        super(props);
//         cl(props)
        this.state={
            //TODO Obtain the current status on the Date Buttons for irrigation and save into state
            waterStatus: [true, true, true, false, true, true, true, false, false, false,true,true,true,true]
        }
        this.updateFui()
        this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
        this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
        // this.makeWaterStatus();
    }
    
    
    updateFui=()=>{
//       cl(this.changed)
//       cl("updatefui")
      if(!this.changed){
        this.props.getValue(this.props.ind, {type: "array", count: 14})
      }
    }
    
    savePageEvent=()=>{
      this.changed=false
    }

    componentWillUnmount=()=>{
      this.subscribe_updateFui.remove()
      this.subscribe_savePageEvent.remove()
    }
  
/************changes to use real DB data ****************************/
/* the days in the NexGen database are in, um, *alphabetical* order:
Friday, Monday, Saturday, Sunday, Thursday, Tuesday, Wednesday
so, to put them in order, we first pick #3 (Sunday)
then, #1 (Monday), and so forth*/
    dayOrder = [3, 1, 5, 6, 4, 0, 2, 10, 8, 12, 13, 11, 7, 9];
    dbDayToFromWaterStatus(arr, to){
      if (arr === undefined){
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ]
      }
      let ret = [];
      this.dayOrder.forEach((d, i)=>{

        if(to){
          ret[i]=arr[d] == 1; //GENE'S: ret[d]=arr[i] != 0;
        }else{
          ret[d]=arr[i] ? 1 : 0; //GENE's ret[i]=arr[d] ? 1 : 0;
        }
      });
      return ret;
    }

    waterStatusOnChange(position){
      let status = this.dbDayToFromWaterStatus(this.props.value, true);
      status[position] = !status[position];
      let dbArr = this.dbDayToFromWaterStatus(status, false);
      // let vo = {type: "array", value: dbArr}
      this.props.onChange(this.props.ind, {value: dbArr})
    }

/************ end of changes to use real DB data ****************************/

    /**
     * Updates the water status array and pushes it to the FUI page
     * @param position - Position of the button that is being affected
     */
    toggleButton(position){
        //TODO Push it to databases onChange value
        // let status = [...this.state.waterStatus];
        // status[position] = !status[position];
        // this.setState({
        //     waterStatus: status
        // });
        // cl(status)
        this.waterStatusOnChange(position)
        this.changed=true
    }

    /**
     * Generates the specific rows that are holding our BiweeklyIrrigationDate
     * @returns {*}
     */
    generateTableRows() {
        let status = this.dbDayToFromWaterStatus(this.props.value, true)
        let half_length = Math.ceil(status.length / 2);
        let weekA = status.splice(0,half_length)
        let startCol = [66, 245, 218, 1]
        let endCol = [66, 245, 144, 1]
        let col
        return(
            <tbody>
            <tr>
                <td className="week">Week A</td>
                {(weekA).map((value, index) => {
                    col = value ? lerpColor(startCol, endCol, (index * 2) / 13) : ""
                    return <BiweeklyIrrigationDate key={index} status={value} position={index} onChange={this.toggleButton.bind(this)} 
                    saveOK={this.props.saveOK} onText={this.props.custom.onText} bgColor={col}/>
                })}
            </tr>
            <tr>
                <td className="week">Week B</td>
                {(status).map((value, index) => {
                    //Add 7  since second half of array
                    col = value ? lerpColor(startCol, endCol, (index * 2 + 1) / 13) : ""
                    return <BiweeklyIrrigationDate key={index} status={value} position={index+7} onChange={this.toggleButton.bind(this)}
                    saveOK={this.props.saveOK} onText={this.props.custom.onText} bgColor={col}/>
                })}
            </tr>
            </tbody>
        );
    }

    /**
     * Generates the table for the Biweekly mapping
     * @returns {*}
     */
    generateTable(){
        return(
            <div className="biweeklyMappingWrapper">
              <table className="biweeklyMapping">
                  <thead><tr>
                      <th />
                      <th className="dayOfWeek">Sunday</th>
                      <th className="dayOfWeek">Monday</th>
                      <th className="dayOfWeek">Tuesday</th>
                      <th className="dayOfWeek">Wednesday</th>
                      <th className="dayOfWeek">Thursday</th>
                      <th className="dayOfWeek">Friday</th>
                      <th className="dayOfWeek">Saturday</th>
                  </tr></thead>
                  {this.generateTableRows()}
              </table>
            </div>
        );
    }

    render(){
//       cl(this.props.value)
        return(
            <div>
                <h2>{this.props.cont.title}</h2>
                    {this.generateTable()}
            </div>
        );
    }

}

class BiweeklyIrrigationDate extends React.Component {
    constructor(props) {
        super(props);

    }

    /**
     * Changes it in parent array
     */
    toggle(){
        this.props.onChange(this.props.position);
    }

    render() {
        return (
            <td>
                <button
                    className={`date toggle ${(this.props.status)?"selected":""}`}
                    onClick={this.toggle.bind(this)}
                    disabled={!this.props.saveOK}
                    style={{backgroundColor: this.props.bgColor}}
                >
                    {this.props.status ? this.props.onText : "Off"}
                </button>
            </td>
        );
    }
}

export default BiweeklyRepeat ;
