import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {cl,secsToHm,secsToHms,hmsToSecs,minsToHm} from '../../components/utils/utils';

class C18Duration00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
//       time:this.props.parms.duration,
      text:"",
      focus:false,
    }
  }
  
//http://ngsg.link4cloud.com:3104/usa/c18/fui/HumDeHum/1-0-240-0
  
  onFocus=()=>{
//     cl("focus")
    let tFunc=(this.props.parms.pattern=="[0-9]{2}:[0-9]{2}")?
      minsToHm:secsToHms
    this.setState({focus:true,text:tFunc(this.props.parms.duration)})
  }
  
  onBlur=()=>{
//     cl("blur")
    let dur=hmsToSecs(this.state.text)
    this.setState({focus:false,time:dur})
    this.props.parms.onChange({duration:dur})
  }
  
  render(){
//     cl(this.props.parms.pattern)
    let pattern=(this.props.parms.pattern)||"[0-9]{2}:[0-9]{2}:[0-9]{2}"
    let tFunc=(this.props.parms.pattern=="[0-9]{2}:[0-9]{2}")?
      minsToHm:secsToHms
//     cl(pattern)
    let pr=this.props
    let pa=pr.parms
    let style=(pa.width)?{width:pa.width}:{}
    return (
      <>
      <input 
        className="narrow inline-block" 
        style={style}
        id={pr.id}
        type="text" 
        inputMode="numeric" 
        pattern={pattern}
        value={(this.state.focus)?this.state.text:tFunc(pa.duration)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={e=>this.setState({text:e.currentTarget.value})}
        disabled={!pa.saveOK}
      />
      {pa.unit &&
      <span className="units">{pa.unit}</span>
      }
      </>
    )
  }
}
      
export default C18Duration00;
