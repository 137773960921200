import React from 'react';
import C18TabsHeader00 from './C18TabsHeader00'
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import {loadSitesInfo,loadPrivsInfo,loadUsersInfo,getSiteInfo,getSiteIndex,loadZonesInfo, getZoneIndex, addToAdminLog,
  getUserIndex, loadAccountInfo,privs,acctFeature} from './C18utils'
import {wsTrans, doGetPostBasic} from '../utils/utils'
import {cl, globs, constant, getRandomString, getTime} from '../../components/utils/utils';
import config from '../../components/utils/config'
import history from "../../history"

class C18ManageSiteAccess extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      mode:"user",
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePrivs)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Access", url:`/usa/c18/admin/manageSiteAccess2`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  mySetState=(vals)=>{
    this.setState(vals)
  }
  
  
  
  makeTabs=()=>{
    this.tabs=[
      {v:"user",t:"User"},
      {v:"account",t:"Account"},
      {v:"site",t:"Site"},
      {v:"zone",t:"Zone"},
    ]
    if(!acctFeature("fullSiteAccess")){
//     if(config.server=="alpha"){
      this.tabs.splice(1,2)// remove 2
    }
  }
  
  makeZoneToSite=()=>{// actually in loadZonesInfo, now
    this.z2s={}
    globs.zonesInfo.info.forEach(z=>{
      this.z2s[z.zoneId]=z.siteId
    })
//     cl(this.z2s)
  }
  
  sortZonesInfo=()=>{
    globs.zonesInfo.info.sort((a,b)=>{
      if(a.siteId>b.siteId){return 1}
      if(a.siteId<b.siteId){return -1}
      if(a.zoneId>b.zoneId){return 1}
      if(a.zoneId<b.zoneId){return -1}
      return 0
    })
  }
  
  loadInfo=async()=>{
    this.makeTabs()
    await loadAccountInfo()
    cl(globs.accountInfo)
    await loadSitesInfo()
//     this.saveSitesInfo(false)
    await loadZonesInfo()
    this.sortZonesInfo()
    await loadUsersInfo()
//     globs.privsInfo={}// force load of all privs
    await loadPrivsInfo()// for current user
//     query={$or:[{accountId:globs.userData.session.accountId}, {level:"super"}], userId:globs.userData.session.userId}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/areaPrivs", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {accountId:globs.userData.session.accountId}})
    this.allPrivs=res.data
//     cl(this.allPrivs)
//     cl("privs loaded")
    this.makePrivs()
    this.addOrigPrivs()
    this.makeZoneToSite()
    
//     let siteSel=globs.sitesInfo.info[0].siteId
//     let zoneSel=this.getZoneSel(siteSel)
//     this.setState({loaded:true, siteSel:siteSel,zoneSel:zoneSel})
    this.setInitSelects("account")
  }
  
  setInitSelects=(type)=>{
    let ui=globs.usersInfo.info
    let si=globs.sitesInfo.info
    let zi=globs.zonesInfo.info
//     let userId=this.state.selUser
    let siteId=this.state.selSite
    let zoneId=this.state.selZone
//     cl(si,zi)
//     userId=ui[0].userId
    let userId=this.props.parms.adminInfo||-1
    if(type=="account"){ 
      siteId=si[0].siteId
    }
    if((type=="site")||(type=="account")){
      for(let i=0;i<zi.length;i++){
        let z=zi[i]
        if(z.siteId==siteId){ zoneId=z.zoneId; break; }
      }
    }
//     cl(siteId, zoneId)
    this.setState({siteSel:siteId,zoneSel:zoneId,userSel:userId,loaded:true})
  }

  saveToAdminLog=(adds, o, n, level, id)=> {
//     cl(o)
//     cl(n)
//     cl([level, id])
    let addObj={
      userId:globs.userData.session.userId,
      name: n.name,
      level: level,
      id: id,
      time:Math.floor(getTime())
    }
    if(o.flags!=n.flags){
      let levelName
      switch (level) {
        case "account":
          levelName = globs.accountInfo.info.name
          break
        case "site":
          levelName = globs.sitesInfo.info[getSiteIndex(id)].name
          break
        case "zone":
          levelName = globs.zonesInfo.info[getZoneIndex(id)].zoneName
          break
      }
      adds.push(
        Object.assign({},addObj,
        {
        action:"siteAccess",
        oldVal:o.flags,
        newVal:n.flags,
        levelName: levelName
      }))
    }
  }
  
  scanPrivs=async(adminAdds,level,users,id)=>{
    let modified=false
    let sets=[]
//     cl(level)
    Object.keys(users).forEach(userId=>{
      let u=users[userId]
      if(u.cur){
        sets.push({userId:userId,flags:u.cur})
      }
//       cl(sets)
      if(u.cur!=u.orig){ 
        modified=true
        this.saveToAdminLog(adminAdds, {flags:u.orig}, {name:u.name,flags:u.cur},
          level, id)
      }
    })
    if(modified){
    await wsTrans("usa", {cmd: "cRest", uri: `${(level=="super")?"/su/areaPrivs":"/s/areaPrivs"}`, method: "update", 
      sessionId: globs.userData.session.sessionId, body: {
        level:level,
        id:id,
//         siteId:site,
//         zoneId:zone,
        privs:sets,
      }})
//     globs.events.publish("saveOK",true)
//     cl(sets)
  }
    
//     let lev=this.privs[level]
//     let userPrivs=(level=="account")?lev:lev[id]
//     cl(userPrivs)
    
//     switch(level){
//       case "account":
//         cl(this.privs[level])
//         break
//       case "site":
//         cl(this.privs[level][id])
//         break
//       case "zone":
//         cl(this.privs[level][id])
//         break
//     }
//     cl(level,users,id)
  }
  
  savePrivs=async(cmd)=>{
// { "_id" : ObjectId("60255828972777135e7b3f70"), "level" : "site", "userId" : "A3jPuMxGGiIRvt8H", "siteId
// " : "A73M5XD1PD0CH570", "flags" : 0, "accountId" : "a036uzDCxohZ7ovD" }
    
//     cl(cmd)
//       cl("save")
    if(cmd=="save"){
//       cl("save")
      let adminAdds = []
//       cl(this.privs.super)
      await this.scanPrivs(adminAdds,"super",this.privs.super, "")
      await this.scanPrivs(adminAdds,"account",this.privs.account, "")
      Object.keys(this.privs.site).forEach(async siteId=>{
        let site=this.privs.site[siteId]
        await this.scanPrivs(adminAdds,"site",site,siteId)
      })
      Object.keys(this.privs.zone).forEach(async zoneId=>{
        let zone=this.privs.zone[zoneId]
        await this.scanPrivs(adminAdds,"zone",zone,zoneId)
      })
//       cl(this.privs)
//       cl(adminAdds)
      addToAdminLog(adminAdds)
//       cl("save")
      globs.events.publish("saveOK",true)
//       cl("done")
      
//       this.saveZoneNames(true)// save to db
    }
//     history.goBack()
  }
  
  makeUsersAssocArray=()=>{
//     cl(globs.usersInfo.info)
    let ret={}
    globs.usersInfo.info.forEach(u=>{
      ret[u.userId]={name:u.name,email:u.email,orig:0,cur:0}
    })
    return ret
  }
  
  makePrivs=()=>{// makes the blank privs object, with orig and cur flag fields
/* the table has account, site, and zone levels
with an array of sites or zones, with each having cur, email, name, orig for a user*/
    this.privs={super:{},account:{},site:{},zone:{}}
    this.privs.super=this.makeUsersAssocArray()
    this.privs.account=this.makeUsersAssocArray()
    globs.sitesInfo.info.forEach(s=>{
      this.privs.site[s.siteId]=this.makeUsersAssocArray()
    })
    globs.zonesInfo.info.forEach(z=>{
      this.privs.zone[z.zoneId]=this.makeUsersAssocArray()
    })
//     cl(this.privs)
  }
  
  addOrigPrivs=()=>{
    this.allPrivs.forEach(p=>{
      var v
      switch(p.level){
        case "super":
          if(this.privs[p.level][p.userId]){ v=this.privs[p.level][p.userId]/*.orig=p.flags*/ }
          break
        case "account":
          if(this.privs[p.level][p.userId]){ v=this.privs[p.level][p.userId]/*.orig=p.flags*/ }
          break
        case "site":
          if(this.privs[p.level][p.siteId]){
            if(this.privs[p.level][p.siteId][p.userId]){ v=this.privs[p.level][p.siteId][p.userId]/*.orig=p.flags*/ }
          }
          break
        case "zone":
          if(this.privs[p.level][p.zoneId]){
            if(this.privs[p.level][p.zoneId][p.userId]){ v=this.privs[p.level][p.zoneId][p.userId]/*.orig=p.flags*/ }
          }
          break
      }
      if(v){
        v.orig=p.flags
        v.cur=p.flags
      }
    })
//     cl(this.privs)
  }
  
  getZoneSel=(siteId)=>{
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      if(gzi[i].siteId==siteId){
        return gzi[i].zoneId
      }
    }
  }
  
  getSiteZones=(siteId)=>{
    let ret=[]
    globs.zonesInfo.info.forEach(z=>{
      if(z.siteId==siteId){ret.push(z)}
    })
    return ret
  }
  
  getAccountSites=()=>{
    let ret=[]
    globs.sitesInfo.info.forEach(s=>{
      ret.push(s.siteId)
    })
    return ret
//     let siteObj={}
//     globs.zonesInfo.info.forEach(z=>{
//       siteObj[z.siteId]=1
//     })
//     return Object.keys(siteObj)
  }
  
  setSiteAll=(siteId,vals)=>{
    let zones=this.getSiteZones(siteId)
    zones.forEach(z=>{
      let o=this.privs.zone[z.zoneId][vals.u]
      if(vals.e){
        o.cur|=vals.f
      }else{
        o.cur&=~vals.f
      }
    })
  }
        
  doSetAll=(vals)=>{
    cl(vals)
    switch(vals.l){
      case "account":
        let sites=this.getAccountSites()
        sites.forEach(s=>{
          this.setSiteAll(s,vals)
          let o=this.privs.site[s][vals.u]
          if(vals.e){
            o.cur|=vals.f
          }else{
            o.cur&=~vals.f
          }
        })
        break
      case "site":
        let zones=this.getSiteZones(vals.i)
        zones.forEach(z=>{
          let o=this.privs.zone[z.zoneId][vals.u]
          if(vals.e){
            o.cur|=vals.f
          }else{
            o.cur&=~vals.f
          }
        })
        break
      case "zone":
        break
      default:
        break
    }
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "tabs":
        this.setState({mode:vals.tab})
        break
      case "account":
      case "site":
        this.mySetState(vals)
        break
      case "siteSel":
        Object.assign(vals,{zoneSel:this.getZoneSel(vals.siteSel)})
      case "zoneSel":
        this.mySetState(vals)
        break
      case "userSel":
        this.mySetState(vals)
        break
      case "privChecks":
//         cl(vals)
        if(vals.e&&(vals.f==constant.AREA_PRIVS_WRITE)){vals.f|=constant.AREA_PRIVS_READ}
        if((!vals.e)&&(vals.f==constant.AREA_PRIVS_READ)){vals.f|=(constant.AREA_PRIVS_WRITE)}
        globs.events.publish("savePageEnable",true)
        if(vals.o.all){
          cl(this.props)
          cl(this.state)
          cl("all")
        }else{
          if(vals.e){
            vals.o.cur|=vals.f
          }else{
            vals.o.cur&=~vals.f
          }
//           cl(vals)
          if(vals.f!=constant.AREA_PRIVS_ADMIN){this.doSetAll(vals)}
          this.setState({o:vals.o,cur:vals.o.cur})
        }
        break
      default:
        break
    }
  }
  
  showZonesOpts=()=>{
    let gzi=globs.zonesInfo.info
    return gzi.map((z,i)=>{
      if(z.siteId==this.state.siteSel){
        return(
          <option key={i} value={z.zoneId}>{z.zoneName}</option>
        )
      }
    })
  }
  
  showSelectZone=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select Zone</label>
        <C18Select00 
          value={this.state.zoneSel}
          onChange={e=>this.onChange("zoneSel",{zoneSel: e.currentTarget.value})}
        id="">
        {this.showZonesOpts()}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }
  
  showSelectSite=()=>{
    return (
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        <C18Select00 id=""
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div> 
    )
  }
  
  showUserOpts=()=>{
//     cl(globs.usersInfo.info)
    let opts=[<option key={-1} value={-1}>{`Select a User`}</option>]
    globs.usersInfo.info.forEach((u,i)=>{
      opts.push(
        <option key={i} value={u.userId}>{`${u.name}, ${u.email}`}</option>
      )
    })
    return opts
    
  }
  
  showUserEmail=()=>{
//     let name="none"
    let ind=getUserIndex(this.state.userSel)
    let user=globs.usersInfo.info[ind]
//     let email=user.email
//     cl(globs.usersInfo.info[ind])
    return(
      <>
        <h3>{user.name}</h3>
        <p>{`Email: ${user.email}`}
        {(this.state.userSel==globs.accountInfo.info.owner)&&
          <span><br/>Owner</span>
        }
        </p>
      </>
    )
  }
  
  showSelectUser=()=>{
    let userId=this.props.parms.adminInfo||this.state.userSel
    return (
      <div className="custom-select">
        <label htmlFor="">Select User</label>
        <C18Select00
          value={userId}
          onChange={e=>this.onChange("userSel",{userSel: e.currentTarget.value})}
        >
          {this.showUserOpts()}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
        <div className="clearfloat"></div>
      </div> 
    )
  }
  
  showTableByUser=(level,arr)=>{
//     cl(this.props)
//     cl(this.state)
//     let owner=this.state.userSel==globs.accountInfo.info.owner
//     cl(owner)
//     if(level=="zone"){cl(arr)}
    let colNames={site:"Site",zone:"Zone"}
    
    let keys=Object.keys(arr)
//     cl(arr)
    return(
      <table className="user-access"><tbody>
        <tr>
        {["zone","site","account"].includes(level)&&
          <><th>Read</th><th>Write</th></>
        }
        {(level=="account")&&
          <th>Admin</th>
        }
        {(level=="super")&&
          <><th>Account</th><th>Edit</th><th>Support</th></>
        }
          <th>{colNames[level]}</th>
        </tr>
        {arr.map((v,i)=>{
          if(v){
            return this.showPrivLine(v.userId,v.id,v.level,v.v,v.i,v.name)
          }
        })}
      </tbody></table>
    )
    cl(arr)
    return null
  }
  
  getSiteName=(siteId)=>{
//     cl(siteId)
//     cl(getSiteIndex(siteId))
//     cl(globs.sitesInfo.info)
    return globs.sitesInfo.info[getSiteIndex(siteId)].name
  }
  
  showPrivsByUser=(level)=>{
    let userId=this.state.userSel
//     cl(this.privs)
    var arr,v
    switch(level){
      case "super":
        v=this.privs[level][this.state.userSel]
        arr=[{level: level, userId:userId,v:v, i:0, name:"SuperUser"}]
        return this.showTableByUser(level,arr)
        break
      case "account":
        v=this.privs[level][this.state.userSel]
        arr=[{level: level, userId:userId,v:v, i:0, name:"Account Wide"}]
        return this.showTableByUser(level,arr)
        break
        break
      case "site":
        arr= globs.sitesInfo.info.map((s,i)=>{
          v=this.privs[level][s.siteId][this.state.userSel]
          return {level: level, userId:userId,id:s.siteId, v:v, i:i, name:s.name}
        })
        return this.showTableByUser(level,arr)
        break
      case "zone":
        arr= globs.zonesInfo.info.map((z,i)=>{
          if(getSiteIndex(z.siteId)>=0){
            v=this.privs[level][z.zoneId][this.state.userSel]
//             cl(v)
            return {level: level, userId:userId,id:z.zoneId, v:v, i:i, name:`${this.getSiteName(z.siteId)}: ${z.zoneName}`}
          }
        })
        return this.showTableByUser(level,arr)
        break
    }
  }
  
  getForce=(level,id,userId)=>{
// at zone, check for site and account
// at site, check for account
    // return 3 flags of net override
//     cl(level,id,userId)
    if((this.state.userSel==globs.accountInfo.info.owner)&&(level!="super"))
      {return[255,255]}// if owner
    var p0,p00,p1,p2,dis,chk
//     cl(this.privs)
    p0=this.privs["account"][userId].cur
    p00=this.privs["super"][userId].cur
    switch(level){
      case "super":
        return [p00,0]
      case "account":
        return [p0,0]
      case "site":
        p1=this.privs["site"][id][userId].cur
//         dis=+p0
//         chk=+p0|+p1
        chk=+p1
//         cl(dis)
        return [chk,dis]
      case "zone":
        let siteId=this.z2s[id]
//         p1=this.privs["site"][siteId][userId].cur
        p2=this.privs[level][id][userId].cur
//         cl(id,p2)
//         dis=p0|p1
//         chk=dis|p2
        chk=+p2
        return [chk,dis]
    }
    
  }
  
  showPrivLine=(userId,id,level,v,i,name)=>{
//     cl(v)
//     cl(id)
//     cl(level,id,userId)
//     cl([userId,id,level,v,i,name])
    let [chk,dis]=this.getForce(level,id,userId)
    return(
      <tr key={i}>
        {(level=="super")&&
          <>
          <td><C18Input00 type="checkbox" aria-label={`${name} read`}
            checked={chk&constant.SUPER_PRIVS_ADMIN}
            disabled={dis&constant.SUPER_PRIVS_ADMIN}
            onChange={e=>this.onChange("privChecks",{o:v,e:e.currentTarget.checked,f:constant.SUPER_PRIVS_ADMIN,u:userId,l:level,i:id})}
          /></td>
          <td><C18Input00 type="checkbox" aria-label={`${name} read`}
            checked={chk&constant.SUPER_PRIVS_EDIT}
            disabled={dis&constant.SUPER_PRIVS_EDIT}
            onChange={e=>this.onChange("privChecks",{o:v,e:e.currentTarget.checked,f:constant.SUPER_PRIVS_EDIT,u:userId,l:level,i:id})}
          /></td>
          <td><C18Input00 type="checkbox" aria-label={`${name} read`}
            checked={chk&constant.SUPER_PRIVS_SUPPORT}
            disabled={dis&constant.SUPER_PRIVS_SUPPORT}
            onChange={e=>this.onChange("privChecks",{o:v,e:e.currentTarget.checked,f:constant.SUPER_PRIVS_SUPPORT,u:userId,l:level,i:id})}
          /></td>
          </>
        }
        {(level!="super")&&
          <td><C18Input00 type="checkbox" aria-label={`${name} read`}
            checked={chk&constant.AREA_PRIVS_READ}
            disabled={dis&constant.AREA_PRIVS_READ}
            onChange={e=>this.onChange("privChecks",{o:v,e:e.currentTarget.checked,f:constant.AREA_PRIVS_READ,u:userId,l:level,i:id})}
          /></td>
        }
        {(level!="super")&&
          <td><C18Input00 type="checkbox" aria-label={`${name} write`}
            checked={chk&constant.AREA_PRIVS_WRITE}
            disabled={dis&constant.AREA_PRIVS_WRITE}
            onChange={e=>this.onChange("privChecks",{o:v,e:e.currentTarget.checked,f:constant.AREA_PRIVS_WRITE,u:userId,l:level,i:id})}
          /></td>
        }
        {(level=="account")&&
          <td><C18Input00 type="checkbox" aria-label={`${name} admin`}
            checked={chk&constant.AREA_PRIVS_ADMIN}
            disabled={dis&constant.AREA_PRIVS_ADMIN}
            onChange={e=>this.onChange("privChecks",{o:v,e:e.currentTarget.checked,f:constant.AREA_PRIVS_ADMIN,u:userId,l:level,i:id})}
          /></td>
        }
        <td>{name}</td>
      </tr>
    )
  }
  
  showUserTable=(level,id,arr)=>{
    cl(arr)
    if(!arr){return}
    let keys=Object.keys(arr)
//     cl(keys)
//     keys.unshift("all")
    return(
      <table className="user-access"><tbody>
        <tr>
        {["zone","site","account"].includes(level)&&
          <><th>Read</th><th>Write</th></>
        }
        {(level=="account")&&
          <th>Admin</th>
        }
        <th>User</th>
        </tr>
        {keys.map((k,i)=>{
          let v=(k=="all")?{name:"All Users",cur:0,orig:0,all:true}:arr[k]
          return this.showPrivLine(k,id,level,v,i,`${v.name} (${v.email})`)
        })}
      </tbody></table>
    )
    cl(arr)
    return null
    
  }
  
  showUserPrivs=(level, id)=>{
//     cl(this.state)
//     cl(level,id)
//     cl(this.privs)
    var userArr
    switch(level){
      case "account":
//         cl(level)
//         cl(this.privs)
        userArr=this.privs[level]
        break
      case "site":
        userArr=this.privs[level][id]
        break
      case "zone":
        userArr=this.privs[level][id]
        break
      default:
        break
    }
    cl([level,id,userArr])
    return this.showUserTable(level,id,userArr)
  }
  
  showTabs=()=>{
    let mode=this.state.mode
    return(
      <div className="tab-controls" role="tablist">
        <button type="button" className={`tab${(mode=="account")?" selected":""}`} role="tab" aria-expanded={(mode=="account")?"true":"false"}
        onClick={e=>{this.onChange("account",{mode:"account"})}}
        >Account Access</button>
        <button type="button" className={`tab${(mode=="site")?" selected":""}`} role="tab" aria-expanded={(mode=="site")?"true":"false"}
        onClick={e=>{this.onChange("site",{mode:"site"})}}
        >Site/Zone Access</button>
      </div>
    )
  }
  
  showSite=()=>{
//     cl(this.state)
//         <br />
//         {this.showSelectZone()}
    return(
      <div className="custom-select">
        <h2>Site Access</h2>
        {this.showSelectSite()}
        {this.showUserPrivs("site",this.state.siteSel)}
      </div>
    )
  }
  
  showZone=()=>{
    return(
      <div className="custom-select">
        <h2>Zone Access</h2>
        {this.showSelectSite()}
        {this.showSelectZone()}
        {this.showUserPrivs("zone",this.state.zoneSel)}
        <br />
      </div>
    )
  }
  
  showAccount=()=>{
    return(
      <div className="custom-select">
        <h2>Account Access</h2>
        {this.showUserPrivs("account",this.state.siteSel)}
      </div>
    )
  }
  
  showUserInfo=()=>{
//     cl(globs.userData.session.userId)
    let showSuper=privs("super","",constant.SUPER_PRIVS_ADMIN)// can't use privs on this page. It's an array of all users
    
//     let showSuper=false
//     cl(showSuper)
//         {(config.server=="dev")&&this.showUserEmail()}
    return(
      <>
        {acctFeature("showUserEmail")&&this.showUserEmail()}
        {showSuper&&
          <>
            <h3>SuperUser</h3>
            {this.showPrivsByUser("super")}
          </>
        }
        <h3>Account</h3>
        {this.showPrivsByUser("account")}
        <h3>Sites</h3>
        {this.showPrivsByUser("site")}
        <h3>Zones</h3>
        {this.showPrivsByUser("zone")}
      </>
    )
  }
  
  showUser=()=>{
//     cl(this.state)
    let userId=this.props.parms.adminInfo||this.state.userSel
    return(
      <>
        <h2>Access by User</h2>
        {!this.props.parms.adminInfo&&this.showSelectUser()}
        {(userId!=-1)&&this.showUserInfo()}
      </>
    )
  }
  
  showSiteAccount=()=>{
//     cl(this.state.mode)
    switch(this.state.mode){
      case "user":
        return this.showUser()
      case "account":
        return this.showAccount()
      case "site":
        return this.showSite()
      case "zone":
        return this.showZone()
    }
//     if(this.state.mode=="site"){
//       return this.showSite()
//     }else{
//       return this.showAccount()
//     }
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
          <div className="tabs">
            <C18TabsHeader00
              tabId={this.state.mode}
              tabs={this.tabs}
              onChange={o=>this.onChange("tabs",o)}
            />
            <div className="tab-panels">
              <div className="tab-panel selected" role="tabpanel">
                {this.showSiteAccount()}
                <div className="clearfloat"></div>
              </div> 
            </div>
          </div>
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageSiteAccess;
