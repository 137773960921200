import React from 'react';
import {cl} from '../../components/utils/utils';
import { ResponsiveBar } from '@nivo/bar'

class C18Bar extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
    }
    this.testData = [
      {
        "country": "AD",
        "hot dog": 66,
        "hot dogColor": "hsl(239, 70%, 50%)",
        "burger": 135,
        "burgerColor": "hsl(80, 70%, 50%)",
        "sandwich": 34,
        "sandwichColor": "hsl(207, 70%, 50%)",
        "kebab": 44,
        "kebabColor": "hsl(47, 70%, 50%)",
        "fries": 14,
        "friesColor": "hsl(324, 70%, 50%)",
        "donut": 156,
        "donutColor": "hsl(17, 70%, 50%)"
      },
      {
        "country": "AE",
        "hot dog": 157,
        "hot dogColor": "hsl(309, 70%, 50%)",
        "burger": 12,
        "burgerColor": "hsl(268, 70%, 50%)",
        "sandwich": 158,
        "sandwichColor": "hsl(71, 70%, 50%)",
        "kebab": 11,
        "kebabColor": "hsl(311, 70%, 50%)",
        "fries": 163,
        "friesColor": "hsl(196, 70%, 50%)",
        "donut": 199,
        "donutColor": "hsl(133, 70%, 50%)"
      },
      {
        "country": "AF",
        "hot dog": 80,
        "hot dogColor": "hsl(137, 70%, 50%)",
        "burger": 86,
        "burgerColor": "hsl(199, 70%, 50%)",
        "sandwich": 8,
        "sandwichColor": "hsl(337, 70%, 50%)",
        "kebab": 96,
        "kebabColor": "hsl(50, 70%, 50%)",
        "fries": 199,
        "friesColor": "hsl(46, 70%, 50%)",
        "donut": 166,
        "donutColor": "hsl(211, 70%, 50%)"
      },
      {
        "country": "AG",
        "hot dog": 49,
        "hot dogColor": "hsl(116, 70%, 50%)",
        "burger": 27,
        "burgerColor": "hsl(170, 70%, 50%)",
        "sandwich": 7,
        "sandwichColor": "hsl(275, 70%, 50%)",
        "kebab": 89,
        "kebabColor": "hsl(143, 70%, 50%)",
        "fries": 0,
        "friesColor": "hsl(185, 70%, 50%)",
        "donut": 112,
        "donutColor": "hsl(28, 70%, 50%)"
      },
      {
        "country": "AI",
        "hot dog": 56,
        "hot dogColor": "hsl(9, 70%, 50%)",
        "burger": 198,
        "burgerColor": "hsl(118, 70%, 50%)",
        "sandwich": 49,
        "sandwichColor": "hsl(39, 70%, 50%)",
        "kebab": 50,
        "kebabColor": "hsl(147, 70%, 50%)",
        "fries": 85,
        "friesColor": "hsl(123, 70%, 50%)",
        "donut": 139,
        "donutColor": "hsl(103, 70%, 50%)"
      },
      {
        "country": "AL",
        "hot dog": 2,
        "hot dogColor": "hsl(40, 70%, 50%)",
        "burger": 15,
        "burgerColor": "hsl(13, 70%, 50%)",
        "sandwich": 73,
        "sandwichColor": "hsl(2, 70%, 50%)",
        "kebab": 61,
        "kebabColor": "hsl(81, 70%, 50%)",
        "fries": 139,
        "friesColor": "hsl(115, 70%, 50%)",
        "donut": 192,
        "donutColor": "hsl(273, 70%, 50%)"
      },
      {
        "country": "AM",
        "hot dog": 134,
        "hot dogColor": "hsl(233, 70%, 50%)",
        "burger": 104,
        "burgerColor": "hsl(11, 70%, 50%)",
        "sandwich": 27,
        "sandwichColor": "hsl(127, 70%, 50%)",
        "kebab": 193,
        "kebabColor": "hsl(12, 70%, 50%)",
        "fries": 15,
        "friesColor": "hsl(28, 70%, 50%)",
        "donut": 45,
        "donutColor": "hsl(289, 70%, 50%)"
      }
    ]
  }

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
 	showResponsiveBar = ( data /* see data tab */ ) => {
  		data = this.testData
  		return (
	    <ResponsiveBar
	        data={data}
	        keys={[
	            'hot dog',
	            'burger',
	            'sandwich',
	            'kebab',
	            'fries',
	            'donut'
	        ]}
	        indexBy="country"
	        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
	        padding={0.3}
	        valueScale={{ type: 'linear' }}
	        indexScale={{ type: 'band', round: true }}
	        colors={{ scheme: 'nivo' }}
	        defs={[
	            {
	                id: 'dots',
	                type: 'patternDots',
	                background: 'inherit',
	                color: '#38bcb2',
	                size: 4,
	                padding: 1,
	                stagger: true
	            },
	            {
	                id: 'lines',
	                type: 'patternLines',
	                background: 'inherit',
	                color: '#eed312',
	                rotation: -45,
	                lineWidth: 6,
	                spacing: 10
	            }
	        ]}
	        fill={[
	            {
	                match: {
	                    id: 'fries'
	                },
	                id: 'dots'
	            },
	            {
	                match: {
	                    id: 'sandwich'
	                },
	                id: 'lines'
	            }
	        ]}
	        borderColor={{
	            from: 'color',
	            modifiers: [
	                [
	                    'darker',
	                    1.6
	                ]
	            ]
	        }}
	        axisTop={null}
	        axisRight={null}
	        axisBottom={{
	            tickSize: 5,
	            tickPadding: 5,
	            tickRotation: 0,
	            legend: 'country',
	            legendPosition: 'middle',
	            legendOffset: 32
	        }}
	        axisLeft={{
	            tickSize: 5,
	            tickPadding: 5,
	            tickRotation: 0,
	            legend: 'food',
	            legendPosition: 'middle',
	            legendOffset: -40
	        }}
	        labelSkipWidth={12}
	        labelSkipHeight={12}
	        labelTextColor={{
	            from: 'color',
	            modifiers: [
	                [
	                    'darker',
	                    '1.7'
	                ]
	            ]
	        }}
	        legends={[
	            {
	                dataFrom: 'keys',
	                anchor: 'bottom-right',
	                direction: 'column',
	                justify: false,
	                translateX: 120,
	                translateY: 0,
	                itemsSpacing: 2,
	                itemWidth: 100,
	                itemHeight: 20,
	                itemDirection: 'left-to-right',
	                itemOpacity: 0.85,
	                symbolSize: 20,
	                effects: [
	                    {
	                        on: 'hover',
	                        style: {
	                            itemOpacity: 1
	                        }
	                    }
	                ]
	            }
	        ]}
	        role="application"
	        ariaLabel="Nivo bar chart demo"
	        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
	    />
		)
	}
  
  render(){
    return  (
		<div style={{height:"500px"}}>
    		{this.showResponsiveBar(this.props.data)}
    	</div>
	)
  }
}

export default C18Bar;
 
