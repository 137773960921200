import React from 'react';
import history from "../../history"
import C18Select00 from './C18Select00'
import C18Anchor00 from './C18Anchor00'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import {wsTrans} from '../utils/utils'
import { DateRangePicker } from 'react-date-range';
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,loadReportsInfo,loadPrivsInfo,privs} from './C18utils'

class C18ReportList extends React.Component{
  constructor(props) {
    super(props);
    this.loadInfo()

    this.state={
      all_sites: [],
      all_reports: [],
      all_users: [],
      site: "All Sites",
      zone: "All Zones",
      sortMode:1,
      loaded:false,
    }
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveUsers)
    this.setBreadCrumbs()
  }
  
  loadInfo=async()=>{
    await this.makeSites()
    await this.makeReports()
    await this.makeUsers()
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Reports", url:`/usa/c18/reports/reportList`},
            ]},
        },
      )
    }
  }
  
  makeUsers=async()=>{
    await loadUsersInfo()
    await loadPrivsInfo()
    let writePriv=(privs("account",0,constant.AREA_PRIVS_WRITE)!=0)
//     cl(writePriv)
    let users=[]
    let gui=globs.usersInfo.info
    this.usersSearch={}
    gui.forEach(u=>{
      let user={
        name: u.name,
        userId: u.userId,
      }
      users.push(user)
      this.usersSearch[u.userId]=user
    })
//     cl("users loaded")
    this.setState({loaded:true, all_users:users,writePriv:writePriv})
  }

  makeSites=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let sites=[]
    let gsi=globs.sitesInfo.info
    gsi.forEach(s=>{
      sites.push(
        {
          name: s.name,
          zones:this.makeZones(s.siteId),
          siteId:s.siteId,
        }
      )
    })
    this.setState({all_sites:sites})
  }

  makeZones=(siteId)=>{
    let gzi=globs.zonesInfo.info
    let zones=[]
    gzi.forEach(z=>{
      if(z.siteId==siteId) {zones.push(
        {
          name: z.zoneName,
          zoneId: z.zoneId,
        }
      )}
    })
    return zones
  }

  makeReports=async(sortMode=1,val=null)=> {
    await loadReportsInfo()
    let gri=globs.reportsInfo.info
    let reports=[]

    if(val) {
      let idx = gri.findIndex((r)=>r.reportId==val)
      if (idx != -1)  {
      cl("removing from list")
      gri.splice(idx, 1)
      }
    }

    gri.forEach(r=>{
      reports.push(
        {
          title: r.title,
          reportId: r.reportId,
          userId: r.userId,
          createdOn: r.createdOn,
          sites: r.sites,
          zones: r.zones,
        }
      )
    })
    // reports=this.sortReports(reports,1)
    // retain sorting when reports is made/remade
    reports=this.sortReports(reports,sortMode)
    this.setState({all_reports:reports})
  }
  
  sortReports=(reports,sortMode)=>{
    let field=["","title","userId","createdOn"][Math.abs(sortMode)]
    let dir=(sortMode>0)?1:-1
    reports.sort((a,b)=>{
      let af=a[field]
      let bf=b[field]
      if(field=="title"){
        af=(af).toLowerCase()||"Untitled Report"
        bf=(bf).toLowerCase()||"Untitled Report"
      }
      if(field=="userId"){
        af=(this.usersSearch[af]||{}).name||"Former User"
        bf=(this.usersSearch[bf]||{}).name||"Former User"
      }
      // sort by desc date
      if(field=="createdOn"){
        bf=af||0
        af=b[field]||0
      }
      if(af>bf){return dir}
      if(af<bf){return 0-dir}
      return 0
    })
    return reports
  }

  onChange=(type, val)=> {
    cl([type, val])
    let st=this.state
    switch (type){
      case 'site':
        this.setState({site: val})
        break
      case 'zone':
        this.setState({zone: val})
        break
      case 'sort':
        cl(st)
        if(Math.abs(val.sortMode)==st.sortMode){
          val.sortMode=0-st.sortMode
        }
        let reports=this.sortReports(st.all_reports.slice(0),val.sortMode)
        Object.assign(val,{all_reports:reports})
        this.setState(val)
        break
//         cl(val)
//         this.setState(val)
//         break
    }
  }

  onClick=async(e,type, val)=> {
    e.preventDefault()
//     cl([type, val])
//     return
    switch (type){
      case 'create':
        history.push(`/usa/c18/reports/editReport/`)
        cl("create report")
        break
      // case 'create':
      //   history.push(`/usa/c18/reports/editReport/`)
      //   cl("create report")
      //   break
      case 'view':
        // navigate to view report
        history.push(`/usa/c18/reports/viewReport/${val}`)
        cl("view report")
        break
      case 'edit':
        // navigate to edit report
        history.push(`/usa/c18/reports/editReport/${val}`)
        cl("edit report")
        break
      case 'delete':
        let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Report?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          wsTrans("usa", {cmd: "cRest", uri: "/s/reports", method: "delete", 
          sessionId: globs.userData.session.sessionId, body: {
            reportId: val,
          }})
          globs.reportsInfo.got = false
          this.makeReports(this.state.sortMode,val)
        }
        break
    }
  }

  filterZone=(val)=>{
//     cl(val)
    return this.state.site == "All Sites" || this.state.site == val.siteId
  }

  showZoneSelector=()=>{
    let zones = []
    let arr = this.state.all_sites.filter(this.filterZone)
//     cl(arr)
    arr.forEach((s, i) => {
        s.zones.forEach((z, j) => {
          zones.push( 
            <option key={[i, j]} value={z.zoneId}>{z.name}</option>
          )
        })
    })

    return(
      <span className="custom-select">
        <label htmlFor="report-zone">Zone</label>
        <C18Select00 
          id="report-zone"
          onChange={(e)=>this.onChange("zone", e.currentTarget.value)}
          value={this.state.zone}
        >
          {zones}
          <option>All Zones</option>
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showSiteSelector=()=> {
    let sites = []
    this.state.all_sites.forEach((s, i) => {
        sites.push( 
          <option key={i} value={s.siteId}>{s.name}</option>
        )
    })

    return(
      <span className="custom-select">
        <label htmlFor="report-site">Site</label>
        <C18Select00 
          id="report-site"
          onChange={(e)=>this.onChange("site", e.currentTarget.value)}
          value={this.state.site}
        >
          {sites}
          <option>All Sites</option>
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  filterReport=(r)=> {
//     cl(r.sites)
    let site_bool = (this.state.site == "All Sites") || r.sites.includes(this.state.site)
    let zone_bool = (this.state.zone == "All Zones") || r.zones.includes(this.state.zone)
    return site_bool && zone_bool 
  }

  showReports=()=>  {
    let reports = []
//     cl(this.state.all_reports)
    let arr = this.state.all_reports.filter(this.filterReport)
//     cl(globs.usersInfo)
//     cl(arr)
    let banding=(globs.usersInfo.uiInfo||{}).tableBanding||true
    arr.forEach((r, i) => {
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
//       cl(this.state.all_users)
//       cl(r.userId)
      let user = this.state.all_users.find((u) => u.userId == r.userId)
      if (!r.createdOn) { r.createdOn = new Date()}
      let dt = dateToDisplayDate(new Date(r.createdOn), "mm/dd/yyyy")
//           <a href="" onClick={()=>this.onClick("view", r.reportId)} className="name">
//           </a>
      reports.push(
        <tr key={i} className={tableBand}>
          <td>
          <C18Anchor00 to="" className="name" onClick={(e)=>this.onClick(e,"view", r.reportId)}>
            {(r.title == "") ? "Untitled Report" : r.title}
          </C18Anchor00>
          </td>
          <td>
          {user == null ? "Former User" : user.name}
          </td>
          <td>
          {dt}
          </td>
          {this.state.writePriv&&
            <>
              <td>
              <C18Anchor00 to="" onClick={(e)=>this.onClick(e,"edit", r.reportId)} className="settings-button material-icons-outlined flip" aria-label="settings">build</C18Anchor00>
              </td>
              <td>
                <button onClick={(e)=>this.onClick(e,"delete", r.reportId)} type="button" className="material-icons trash" aria-label="delete report">
                  delete_outline
                </button>
              </td>
            </>
          }
          
        </tr>
      )
    })



    return (reports.length > 0 ?
      <div className="table-container">
        <table className="list reports"><tbody>
          {this.showReportHead()}
          {reports}
        </tbody></table>
      </div> :
      <div className="none-found">No Reports found</div>
      )
  }
  
  showReportHead=()=>{
    let sort=this.state.sortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<6;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
    return(
      <tr>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:1})}
        >Name <span className="material-icons-outlined">{icons[0]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:2})}
        >Created By <span className="material-icons-outlined">{icons[1]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:3})}
        >Created On <span className="material-icons-outlined">{icons[2]}</span></button></th>
        
        {this.state.writePriv&&
          <>
            <th>Edit</th><th>Delete</th>
          </>
        }
      </tr>
    )
  }

  showReportList=()=> {
    if(this.state.loaded){
      return (
        <div>
          {this.state.writePriv&&
            <div className="section-controls floatright">
              <C18Anchor00 to="" onClick={(e)=>this.onClick(e,"create", "")} className="material-icons-outlined add" aria-label="add report">
                add
              </C18Anchor00>
            </div>
          }
          <div className="clearfloat"></div>

          {this.showSiteSelector()}
          {this.showZoneSelector()}
          {this.showReports()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
  
  
  render(){
    return this.showReportList()
  }
}

export default C18ReportList;
