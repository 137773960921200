import React from 'react';
// import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import C18MuiPickersUtilsProvider from '../../usa/components/C18MuiPickersUtilsProvider'
import C18MuiTimePicker from '../../usa/components/C18MuiTimePicker'
import C18MuiInput from '../../usa/components/C18MuiInput'
// import { TimePicker } from "@material-ui/pickers";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {cl, globs,az,lerpColor} from '../../components/utils/utils';

class TimeOfDay extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.state={
      enabled: (props.value<1440),
    }
  }

  updateFui=()=>{
//     cl(this.changed)
    if(!this.changed){
      this.props.getValue(this.props.ind, {type: "scalar"})
    }
  }
  
  savePageEvent=()=>{
    this.changed=false
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  onChange=(e)=>{
    // cl(e.getHours())
    this.changed=true
    cl(e.currentTarget.value)
    let parts=e.currentTarget.value.split(":")
    cl(parts)
    let t=parts[0]*60+(+parts[1])
//     let t = e.getHours() * 60 + e.getMinutes();
    // cl(e.target.value)
    this.props.onChange(this.props.ind,
      {value: t})
  }
  
  enableChange=(e)=>{
//     let en=e.currentTarget.checked
//     this.setState({enabled: en})
    this.changed=true
    if(e.currentTarget.checked){
      this.props.onChange(this.props.ind,{value: 0})
    }else{
      this.props.onChange(this.props.ind,{value: 1440})
    }
  }

  minsToTime=(mins)=>{
    if(mins>=1440){mins=0}
    let hr=Math.floor(mins/60)
    let mn=mins%60
    return `${az(hr,2)}:${az(mn,2)}`
  }
  
//   minsToTime=(mins)=>{
//     let hr=Math.floor(mins/60)
//     let mn=mins%60
//     return `${az(hr,2)}:${az(mn,2)}`
//     return "09:00"
//   }

  render(){
//     cl(this.state)
    let val=this.props.value
    val=(val)?val:0
    // rbg values for day/night
    let col = ""
    if (this.props.value<1440) {
      let dayCol = [245, 192, 0, 1]
      let nightCol = [192, 130, 209, 1]
      col = this.props.value<720 ? lerpColor(nightCol, dayCol, val/720) : lerpColor(dayCol, nightCol, (val-720)/720)
    }
//     cl(this.minsToDate(val))
//     cl(this.props?.custom)
//     cl(this.minsToTime(val))
    let pr=this.props
    return(
      <div id={this.props?.custom?.infoId}
        className={`time-enable${(this.props?.custom?.float=="left")?" floatleft":""}`}>
        <C18MuiPickersUtilsProvider utils={DateFnsUtils}>
          <C18MuiTimePicker style={{display: "inline"}}
            id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
            ampm={true}
            openTo="hours"
            views={["hours", "minutes"]}
            format="HH:mm"
            label={this.props.title}
            disabled={this.props.value>=1440}
            value={this.minsToTime(val)}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            bgColor={col}
            inputProps={{
              style: {textAlign: 'center', width: 100,}
            }}
          />
        </C18MuiPickersUtilsProvider>
        {this.props?.custom?.showEnable&&
          <>
          <C18MuiInput 
            type="checkbox" 
            style={{display: "inline", paddingTop: 20, marginTop: 26}}
            onChange={this.enableChange}
            checked={this.props.value<1440}
            noBreak={true}
            saveOK={this.props.saveOK}
          />
          <label>Enable</label>
          </>
        }
      </div>
    );
  }
}

  export default TimeOfDay ;
