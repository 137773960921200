import React from 'react';
import C18Button00 from './C18Button00'
import C18Select00 from './C18Select00'
import {cl,globs,constant} from '../../components/utils/utils';
import {loadSitesInfo,loadZonesInfo,loadPresetsInfo,getZoneInfo,loadPrivsInfo,
  privs} from './C18utils'

class C18SiteZoneView00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded: false,
      site:props?.parms?.site||"allSites",
      zone:props?.parms?.zone||"allZones",
    }
    this.loadSitesZones()
    this.subscribe_viewsUpdated=globs.events.subscribe("viewsUpdated",this.viewsUpdated)// used when a view is added / deleted
  }
  
  componentWillUnmount=()=>{
//     this.historyUnlisten()
//     this.mounted=false
    this.subscribe_viewsUpdated.remove()
  }
  
  viewsUpdated=(presetId)=>{
//     cl("views updated")
//     cl(presetId)
    this.setState({presetOpts:this.makePresetOpts(this.state),preset:presetId}) 
  }
  
  loadSitesZones=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    await loadPresetsInfo()
    await loadPrivsInfo()
    let writePriv=(privs("account",0,constant.AREA_PRIVS_WRITE)!=0)
//     cl(this.props.parms)
//     cl(globs.presetsInfo.info)
//     cl(globs.sitesInfo)
//     cl(globs.zonesInfo)
    let siteOpts={"allSites":{v:"allSites",t:"All Sites"}}
    globs.sitesInfo.info.forEach(s=>{ 
      s.t=s.name
      s.v=s.siteId
      siteOpts[s.siteId]=s 
    })
    // allZones should not be an option for edit graph, since attempts to load selectable sensors
    let zoneOpts=(this.props.parms.pageType=="editGraph"||this.props.parms.pageType=="editSummary") ? {} : {"allZones":{v:"allZones",t:"All Zones"}}
//     cl(zoneOpts)
    globs.zonesInfo.info.forEach(z=>{
//       cl(z)
        z.t=z.zoneName
        z.v=z.zoneId
        zoneOpts[z.zoneId]=z
      })
    let siteId=this.props.parms.site
    let zoneId=this.props.parms.zone
//     if(this.props.parms.pageType=="editGraph"){
//       siteId=globs.userData.session.siteId
//       let zones=this.filterZones(siteId,zoneOpts)
//       zoneId=zones[0].zoneId
//     }
//     cl(zoneId)
//     cl(zoneOpts)
    this.setState({siteOpts:siteOpts, zoneOpts:zoneOpts, presetOpts: this.makePresetOpts(this.state), 
      site:siteId,zone:zoneId,loaded: true,writePriv:writePriv})
//     cl(this.state)
    
  }
  
  makePresetOpts=(st)=>{// this needs to account for level, site, zone
    
    var addP=(p)=>{
      p.t=p.name
      p.v=p.presetId
      presetOpts[p.presetId]=p
    }
    
//     cl(st)
    let presetOpts={none:{t:"Select a View",v:"none"}}
    globs.presetsInfo.info.forEach(p=>{
      if(p.name!="Current"){
        if(st.site=="allSites"){
          addP(p)
        }else{
          if(st.zone=="allZones"){
            if(p.site==st.site){
              addP(p)
            }
//             cl("show for site")
          }else{
//             cl("show for zone")
            if((p.level=="zone")&&(p.zone==st.zone)){
              addP(p)
            }
          }
        }
      }
//       if(p.name!="Current"){
//         p.t=p.name
//         p.v=p.presetId
//         presetOpts[p.presetId]=p
//       }
      })
    return presetOpts
  }
  
  makeSelectOpts=(opts)=>{
    let ret=[]
    Object.values(opts).forEach((o,i)=>{
      ret.push(
        <option key={i} value={o.v}>{o.t}</option>
      )
    })
    return ret
  }
  
  onChangeParent=(type,vals)=>{
    if(this.props.parms.mode="viewList"){
      vals.type=type
      this.props.parms.onChange(vals)
    }
  }
  
  onChange=(type, vals)=>{
    switch(type){
      case "sites":
        let siteId=vals.site
        cl(siteId)
        let zones=this.filterZones(siteId,this.state.zoneOpts)
        // if all sites selected, select first valid zone
        let zone={zone:(this.props.parms.pageType=="editGraph"||this.props.parms.pageType=="editSummary")? Object.values(zones).find(z=> z.zoneId != null)?.zoneId:{zone:"allZones"}}
        cl(zone)
        Object.assign(vals,zone)
        this.setState(vals)
        Object.assign(vals,{cmd:"newSiteZone"})
        break
      case "zones":
        vals.site=getZoneInfo(vals.zone)?.siteId || vals.site || this.state.site
        this.setState({site: vals.site}) 
        let st=Object.assign({},this.state,vals)
        vals.presetOpts=this.makePresetOpts(st)
        this.setState(vals)
        Object.assign(vals,{cmd:"newSiteZone"})
        break
      case "presets":
        this.props.parms.onChange({cmd:"newPreset", vals:vals})
        this.setState(vals)
        break
//       case "deleteView":
//         break
      default:
        break
    }
    this.onChangeParent(type,vals)
//     cl([type, vals])
  }
  
  showSites=(key)=>{
//     cl(this.state.siteOpts)
//     cl(this.state.site,this.state.zone)
    return(
      <div key={key} className="custom-select">
        <label htmlFor="graph-site">Site</label>
        <C18Select00 
          id="graph-site"
          onChange={e=>this.onChange("sites",{site: e.currentTarget.value})}
          value={this.state.site}
        >
        {this.makeSelectOpts(this.state.siteOpts)}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  filterZones=(site,zoneOpts)=>{
//     let zones=Object.assign({},this.state.zoneOpts)
//     let site=st.site
//     cl(site)
    if(site=="allSites"){ return zoneOpts }
    let zones=(this.props.parms.pageType=="editGraph"||this.props.parms.pageType=="editSummary")?[]:[{v:"allZones",t:"All Zones"}]
    Object.values(zoneOpts).forEach(z=>{
      if(z.siteId==site){ zones.push(z) }
    })
//     cl(zones)
    return zones
    
  }
  
  showZones=(key)=>{
//     cl(this.filterZones(this.state.site))
    return(
      <div  key={key} className="custom-select">
        <label htmlFor="graph-zone">Zone</label>
        <C18Select00 
          id="graph-zone"
          onChange={e=>this.onChange("zones",{zone: e.currentTarget.value})}
          value={this.state.zone}
        >
          {this.makeSelectOpts(this.filterZones(this.state.site,this.state.zoneOpts))}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  showViews=(key)=>{
    return(
      <div  key={key} className="custom-select">
        <label htmlFor="graph-view">Select Saved View</label>
        <C18Select00 id="graph_view"
          onChange={e=>this.onChange("presets",{preset: e.currentTarget.value})}
          value={this.state.preset}
        >
        {this.makeSelectOpts(this.state.presetOpts)}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  showSiteZoneView=()=>{
    let ret=[]
//     cl(this.state)
//     if(this.props.parms.level!="zone"){// this needs to be rethought
//       cl("show")
      ret.push(      
        this.showSites(1),
        this.showZones(2),
      )
//     }
//     cl(this.props.parms)
    // let showViews=this.props.parms.pageType!="editGraph"&&this.props.parms.pageType!="editSummary"
    let showViews=this.props.parms.pageType=="viewGraph"||this.props.parms.pageType=="graphList"
    if(showViews){
      ret.push(
        this.showViews(3)
      )
      if((this.props.parms.mode!="viewList")&&(this.state.writePriv)){ret.push(
          <C18Button00 key={4} type="button" className="danger" onClick={()=>this.props.parms.onChange({cmd:"deleteView"})}>Delete</C18Button00>
      )}
    }
    return ret
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
          {this.showSiteZoneView()}
        </div>
      )
    }else{
      return <div>loading site zone. . .</div>
    }
  }
}
      
export default C18SiteZoneView00;
