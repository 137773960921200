import React from 'react';
import FUISelect from './FUISelect';
import FUINumerical from './FUINumerical';
import Duration from './Duration';
import {cl, globs} from '../../components/utils/utils';

class Triggers18b extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
//     cl(props.custom)
    this.startProps={options: [
      {v:0, t:"Disabled"},
      {v:1, t:"Digital Input (Normally Open)"},
      {v:2, t:"Digital Input (Normally Closed)"},
      {v:3, t:"Aux Control Variables"},
    ]}
    this.startProps1b={options: this.startProps.options.slice(0)}
    this.startProps1b.options.splice(0,1)
//     cl(this.startProps1b.options)
    this.startProps2={options: [
      {v:0, t:"Digital In 1"},
      {v:1, t:"Digital In 2"},
      {v:2, t:"Digital In 3"},
      {v:3, t:"Digital In 4"},
      {v:4, t:"Digital In 5"},
      {v:5, t:"Digital In 6"},
      {v:6, t:"Digital In 7"},
      {v:7, t:"Digital In 8"},
    ]}
    this.fValves=[]
    this.props.current.fillValves.forEach(f=>{
      let name=(props.current.channelNames[f])?props.current.channelNames[f]:`Channel ${f+1}`
      this.fValves.push({v:f,t:name})
    })
    this.auxVars={options: this.props.current.auxNames.map((n, i)=>{
      if (n === undefined) n="Variable " + (i + 1);
      return {v: i, t: n}
    })}
//     cl(this.auxVars)
    if(props.custom.type=="fvalve"){
      this.startProps1b.options[3]={v:4, t:"Timer"}
      this.props.getValue(props.ind, {type: "array", count: 18})
    }else{
      this.startProps.options[4]={v:4, t:"Fill Valve"}
      this.props.getValue(props.ind, {type: "array", count: 9})
//       let tmp=[
//         {v:0, t:"Channel 1"},
//         {v:1, t:"Channel 2"},
//         {v:2, t:"Channel 3"},
//         {v:3, t:"Channel 4"},
//       ]
// 
//       cl(this.fValves)
  //     cl(this.auxVars)
    }
  }

  getValue=(index,valueDescription)=>{
  }
  
  onChange=(index,value)=>{
    cl(this.props.type)
    let vals=this.props.value.slice(0)
    if(vals[0]==undefined)vals=(this.props.custom.type=="fvalve")?[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] : [0,0,0,0,0,0,0,0,0]
    vals[index]=value.value
    this.props.onChange(this.props.ind, {value: vals})
  }
  
  showTest=()=>{
    return <div>test</div>
  }
  
  showOnePumpTrigger=(ind, vals)=>{
    let pr=this.props
    let pid0=this.props.cont.props
    let startProps={options: [
      {v:0, t:"Disabled"},
      {v:1, t:"Digital Input (Normally Open)"},
      {v:2, t:"Digital Input (Normally Closed)"},
      {v:3, t:"Aux Control Variables"},
      {v:5, t:"Fill Valve"},
    ]}
    
    let startProps2={options: [
      {v:0, t:"Digital In 1"},
      {v:1, t:"Digital In 2"},
      {v:2, t:"Digital In 3"},
      {v:3, t:"Digital In 4"},
      {v:4, t:"Digital In 5"},
      {v:5, t:"Digital In 6"},
      {v:6, t:"Digital In 7"},
      {v:7, t:"Digital In 8"},
    ]}
    let k0=3*ind
    let key=k0
    let isDigital=(vals[k0]==1)||(vals[k0]==2)
    let isAux=(vals[k0]==3)
    let isDisabled=(vals[k0]==0)
//     cl(this.fValves)
//     cl(vals)
    return(
      <div key={ind}>
      <h4>Trigger {ind+1}</h4>
        <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={startProps} 
        ind={key}
        saveOK={pr.saveOK}
        value={vals[key]}
        cont={{title: "Start Trigger", pid: pid0+key++}} />
        {isDigital &&
          <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={startProps2} 
          ind={key}
          value={vals[key]}
          cont={{title: "", pid: pid0+key++}} />
        }
        {isAux &&
          <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={this.auxVars}
          saveOK={pr.saveOK}
          ind={key}
          value={vals[key]}
          cont={{title: "", pid: pid0+key++}} />
        }
        {(vals[k0]==5)&&
          <FUISelect key={key} getValue={this.getValue} onChange={this.onChange} custom={{options: this.fValves}}
          saveOK={pr.saveOK}
          ind={key}
          value={vals[key]}
          cont={{title: "", pid: pid0+key++}} />
        }
        {!isDisabled&&(vals[k0]!=5)&&
          <FUINumerical key={key} getValue={this.getValue} onChange={this.onChange} 
          saveOK={pr.saveOK}
          current={this.props.current}
          custom={{unit: "liter"}}
          ind={key}
          value={vals[key]}
          cont={{title: "RO Water Volume", pid: pid0+key++}} />
        }
      </div>
    );
  }
  
  showOneFValveTrigger=(ind, vals)=>{
//     let vals=this.props.value
    let pid0=this.props.cont.props
    let k0=6*ind
    let key=k0
//     cl(vals)
//     cl(k0)
//     cl(vals[k0+1])
//     cl(this.startProps1b)
//     if(typeof vals != "object") vals=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    return(
      <div key={ind}>
      <h4>Trigger {ind+1}</h4>
        <FUISelect key={k0+0} getValue={this.getValue} onChange={this.onChange} custom={this.startProps} 
        ind={k0+0}
        value={vals[k0+0]}
        cont={{title: "Start Trigger", pid: pid0+k0+0}} />
        {(vals[k0]!=0)&&
          <div>
          {((vals[k0]==1)||(vals[k0]==2))&&
            <FUISelect key={k0+1} getValue={this.getValue} onChange={this.onChange} custom={this.startProps2} 
            ind={k0+1}
            value={vals[k0+1]}
            cont={{title: "Digital Input", pid: pid0+k0+1}} />
          }
          {((vals[k0]==3))&&
            <FUISelect key={k0+1} getValue={this.getValue} onChange={this.onChange} custom={this.auxVars} 
            ind={k0+1}
            value={vals[k0+1]}
            cont={{title: "Aux Variable", pid: pid0+k0+1}} />
          }

          <FUISelect key={k0+2} getValue={this.getValue} onChange={this.onChange} custom={this.startProps1b} 
          ind={k0+2}
          value={vals[k0+2]}
          cont={{title: "Stop Trigger", pid: pid0+k0+2}} />
          {((vals[k0+2]==1)||(vals[k0+2]==2))&&
            <FUISelect key={k0+3} getValue={this.getValue} onChange={this.onChange} custom={this.startProps2} 
            ind={k0+3}
            value={vals[k0+3]}
            cont={{title: "", pid: pid0+k0+3}} />
          }
          {((vals[k0+2]==3))&&
            <FUISelect key={k0+3} getValue={this.getValue} onChange={this.onChange} custom={this.auxVars} 
            ind={k0+3}
            value={vals[k0+3]}
            cont={{title: "Aux Variable", pid: pid0+k0+3}} />
          }
          <Duration key={k0+4} getValue={this.getValue} onChange={this.onChange} custom={{mode: "sec"}}
          ind={k0+4}
          value={vals[k0+4]}
          cont={{title: "Max Water Time", pid: pid0+key++}} />
          <FUINumerical key={k0+5} getValue={this.getValue} onChange={this.onChange} custom={{unit: "liter"}}
          ind={k0+5}
          value={vals[k0+5]}
          cont={{title: "RO Water Volume", pid: pid0+k0+5}} />
          </div>
        }
      </div>
    )
    
  }

  showOneTrigger=(ind, vals)=>{
    switch(this.props.custom.type){
      case "fvalve":
        return this.showOneFValveTrigger(ind,vals)
        break
      default:
        return this.showOnePumpTrigger(ind,vals)
        return
    }
  }
  
//   showFValve=()=>{
//     let vals=this.props.value
//     if(typeof vals != "object") vals=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
//     return(
//       <div>fvalve
//         <FUINumerical key={key} getValue={this.getValue} onChange={this.onChange} custom={{unit: "gal"}}
//         ind={key}
//         value={vals[key]}
//         cont={{title: "RO Water Volume", pid: pid0+key++}} />
//       </div>
//     )
//   }
//   
//   showPump=()=>{
//   }
    

  render(){
    let vals=this.props.value
//     cl(vals)
    if(typeof vals != "object") vals=(this.props.custom.type=="fvalve")?[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] : [0,0,0,0,0,0,0,0,0]
    return(
      <div>
      <h2 key={100}>Triggers</h2>
      {[0,1,2].map(k=>{return this.showOneTrigger(k, vals)})}
      </div>
      
    );
  }
}

  export default Triggers18b ;
