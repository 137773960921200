import React from 'react';
import C18Button00 from './C18Button00'
import {cl,globs} from '../../components/utils/utils';

class C18SaveHeader00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      saveEnable: false,
      saving: false,
      failing: false,// on save error
    }
    this.subscribe_savePageEnable=globs.events.subscribe("savePageEnable",this.enDisSave)
    this.subscribe_saveOK=globs.events.subscribe("saveOK",this.saveOK)
    this.subscribe_keyUp=globs.events.subscribe("keyUp",this.keyUp)
  }
  
  componentWillUnmount=()=>{
//     cl("unsubscribe")

    this.subscribe_savePageEnable.remove()
    this.subscribe_saveOK.remove()
    this.subscribe_keyUp.remove()
  }
  
  keyUp=(key)=>{
//     cl(key)
    
    if((this.state.saveEnable)&&(key=="Enter")){this.onClick("save")}
  }
  
  enDisSave=(val)=>{
//     cl("msg")
//     cl(val)
    this.setState({saveEnable:val})
    this.props.onChange({saveEnable:val})
  }
  
  saveOK=()=>{
//     cl("save ok")
    clearTimeout(this.slideTimer)
    this.props.onChange({saveEnable:false})
    this.setState({saving: false, failing: false, saveEnable: false})
  }
  
  clearSlideIn=()=>{
    cl("clearSlideIn")
    this.setState({saving: false, failing: true})
  }
  
  onClick=(type)=>{
    if(type=="save"){
//       cl("save timer")
      this.setState({saving:true,saveEnable:false})
      this.props.onChange({saveEnable:false})
      this.slideTimer=setTimeout(this.clearSlideIn,5000)
    }else{
      cl("***********************reload")
      window.location.reload()
    }
    globs.events.publish("savePageEvent",type)
  }
  
  render(){
//     cl(this.state)
    let disable=!this.state.saveEnable
    return(
      <div id="save-header">
        <div className="save-cancel-box float-right-box">
          <C18Button00 type="button" className="small" disabled={disable} onClick={o=>this.onClick("cancel")}>
            Revert
          </C18Button00>
          <C18Button00 type="button" className="small filled" disabled={disable} onClick={o=>this.onClick("save")}>
            Save
          </C18Button00>
          <div className="clearfloat"></div>
          <div id="save-notification-slideout" className={`${(this.state.saving)?"open":""}`}>
            &nbsp;&nbsp;&nbsp;Saving. . .
          </div>
          <div id="error-notification-slideout" className={`${(this.state.failing)?"open":""}`}>
            &nbsp;&nbsp;&nbsp;Error - Not Saved!
          </div>
        </div>
        <div className="clearfloat"></div>
      </div>
    )
  }
}
      
export default C18SaveHeader00;
