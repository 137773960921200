import React from 'react';
import AuxControl from './AuxControl'
import {showSelect, showInput} from '../../components/utils/reactUtils';
import {loadAuxControls,loadDBNames,loadEquipmentNames,loadEquipmentTypes,
  setSensorNames} from '../utilsFui'
import {pi,pInd} from '../../components/utils/paramIds';
import {cl,globs} from '../../components/utils/utils';
import {sensorIds,initSensorIds,setSensorIdNames} from '../../usa/utils/utils'


const OPERAND_EQUIPMENTS=6
  
class ShowAuxControls extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      arr: this.props.value,
    }
//     this.props.getValue(props.ind, {type: "array", count: 80})
//     cl(this.props.value)
    this.loadInfo()
  }
  
  componentDidMount=()=>{
    
  }
  
  
  loadInfo=async()=>{
//     cl("load aux")
//     cl(this.props)
    this.zuci=this.props.zuci.split('-')
    let ch=+this.zuci[2]
    let zoneInd=+this.zuci[0]
    let unit=+this.zuci[1]
    let siteId=globs.userData.session.siteId
    let zoneId=globs.zonesInfo.info.filter(z=>{
      return (z.siteId==siteId) && (z.siteZoneIndex==zoneInd)
    })[0]?.zoneId
//     cl(zoneId)
    let ac=loadAuxControls(zoneInd)
    initSensorIds(zoneInd)
    await setSensorIdNames(zoneId)
    let sensors=setSensorNames(unit,sensorIds)
//     cl(sensors)
//     cl("got sensors")
//     cl(sensorIds)
//     cl(globs.zonesInfo.info)
    this.auxControls=[]
    ac.forEach((a,i)=>{
      if((a.targetType==OPERAND_EQUIPMENTS)&&(a.targetValue==ch)){
        a.index=i
        this.auxControls.push(a)
      }
    })
//     cl("load aux done")
    this.setState({loaded:true,sensors:sensors})
//     cl(ac)
//     cl(auxControls)
  }

  onChange=(type, valId, ind, e)=>{
//     cl([type, valId, ind, e])
//     var vals={}
    let arr=this.props.value.slice(0)
    arr[ind]=e.currentTarget.value
    this.props.onChange(this.props.ind, {value: arr})
    this.setState({arr:arr})
  }
  
//       pageType:props.type,
//       saveOK:props.saveOK,
//       auxControls: loadAuxControls(zone),//this.makeAuxArray(),
//       variables: loadDBNames(zone, pInd[1800].config_aux_variables, pi[1800].config_aux_variables["variableName"], "Variable"),
//       pVariables: loadDBNames(zone, pInd[1800].config_aux_persistent_variables, 
//         pi[1800].config_aux_persistent_variables["variableName"], "Persistent Variable"),
//       alarms: loadDBNames(zone, pInd[1800].config_aux_alarms, pi[1800].config_aux_alarms["alarmName"], "Alarm"),
//       equipments: loadEquipmentNames(zone,this.props.current.channelInfo),
//       
//       tanks: loadDBNames(zone, pInd[1800].config_ecph, pi[1800].config_ecph["name"], "Mixing Tank"),
//       varSelect: -1,
// 
//       varShow: "",
//       zone: zone,
//       siteId:globs.userData.session.siteId,
  
  newProps={}
  saveNewProps=(f,i)=>{// to register a components newProps function
    this.newProps[i]=f
  }
  
  render(){
    let zone=+this.zuci[0]
    let unit=+this.zuci[1]
//     cl(this.props.current.channelInfo)
    let ci=this.props.current.channelInfo.slice(0)
//     cl(ci)
    let equip=loadEquipmentNames(zone,unit,ci)//this.props.current.channelInfo
//     cl(equip)
    if(this.state.loaded){
      if(this.auxControls.length>0){
      return(
        <div><h2>Aux Controls for this Equipment</h2>
          {this.auxControls.map((a,i)=>{
//             cl(zone)
//             cl(loadDBNames(zone, unit, pInd[1800].config_aux_variables,
//                 pi[1800].config_aux_variables["variableName"], "Variable"))
            return(
            <AuxControl key={i} parms={{
              auxControl: a,
              index:a.index,
              hideBox:true,
              hideButtons:true,
              hideComment:true,
              hideUnderline:true,
              inline: true,
              onChange: vals=>this.onChange("auxControl",i,vals),// vals is the whole aux control
              newProps: this.saveNewProps,
              variables: loadDBNames(zone, unit, pInd[1800].config_aux_variables,
                pi[1800].config_aux_variables["variableName"], "Variable"),
              pVariables: loadDBNames(zone, unit, pInd[1800].config_aux_persistent_variables, 
                pi[1800].config_aux_persistent_variables["variableName"], 
                  "Persistent Variable"),
              auxAlarms: loadDBNames(zone, unit, pInd[1800].config_aux_alarms,
                pi[1800].config_aux_alarms["alarmName"], "Alarm"),
              equipmentTypes: loadEquipmentTypes(zone,unit,
                this.props.current.channelInfo),
              equipments: equip,//loadEquipmentNames(zone,this.props.current.channelInfo),
              sensors:this.state.sensors,
              tanks: loadDBNames(zone, unit, pInd[1800].config_ecph, pi[1800].config_ecph["name"],
                "Mixing Tank"),
    //           saveOK:this.props.parms.saveOK,
    //           index:i,
    //           getPopup:this.props.parms.getPopup,
            }}/>
            )
          })}
          </div>
        );
      }else{return null}
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }

}

export default ShowAuxControls ;
