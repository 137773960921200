import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18DateFormat00 from './C18DateFormat00'
import history from "../../history"
import {cl,globs,constant,dateToDisplayDate,calcTimezoneOffset} from '../../components/utils/utils';
import {wsTrans,makeSiteSensorNames} from '../utils/utils'
import { DateRangePicker } from 'react-date-range';
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,loadReportsInfo,addToAdminLog} from './C18utils'

class C18EditReport extends React.Component{
  constructor(props) {
    super(props);
    let endDate= new Date()
    let startDate = new Date()
    startDate.setDate((endDate.getDate()-7))
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.onSubmit)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})

    this.state={
      userId: globs.userData.session.userId,
      accountId: globs.userData.session.accountId,
      title: "",
      description: "",
      users: [],
      sites: [],
      zones: [],
      content: [],
      customStart:0,
      customEnd:0,
      period: "today",
      absrel: "absolute",
      timePeriod: {
        startDate: startDate, //new Date(),
        endDate: endDate, //new Date(),
        key: "selection"
      },
      startTime: "00:00",
      endTime: "00:00",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone||"America/Los_Angeles",
      all_sites: [],
      all_users: [],
      all_content: [
        ["Grow Journal", "grow"],
        ["Activity Log", "activity"],
        ["Alarm Log", "alarm"],
        ["Notification Log", "notification"],
        ["User Sessions", "sessions"],
        ["Admin Log", "admin"],
        ["Tasks", "tasks"],
        ["RTD Events", "rtd"],
      ],
      showTimePeriod: "none",
      showSiteDetail: [],
      allSites: false,
      allUsers: false,
      allContent: false,
      loaded: false,
    }
//     cl(this.state.timezone)

    // this.makeSites()// moved to make report
//     this.makeUsers()// moved to makeReport
    this.makeReport(props.parms.reportId)
    this.setBreadCrumbs()
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Reports", url:`/usa/c18/reports/reportList`},
              {t:"Edit Report", url:`/usa/c18/reports/editReport/${this.props.parms.reportId}`},
            ]},
        },
      )
    }
  }
  
  onChange=(type, val)=> {
    globs.events.publish("savePageEnable",true)
//     cl([type, val])
    switch (type){
      case 'title':
        this.setState({title: val})
        break
      case 'description':
        this.setState({description: val})
        break
      case 'timezone':
        cl(val)
        this.setState({timezone: val})
        break
      case 'timePeriod':
        cl(val);
        this.setState({timePeriod: val})
        break
      case 'startTime':
        cl(val);
        this.setState({startTime: val})
        break
      case 'endTime':
        cl(val);
        this.setState({endTime: val})
        break
      case 'showTimePeriod':
        // let show=(val=="none")?"block":"none"
        this.setState({showTimePeriod: val})
        break
      case 'period':
        this.setState({period: val})
        break
      case 'absrel':
        this.setState({absrel: val})
        break
    }
  }

  onToggle=(type, val) => {
//     cl([type, val])
    if(!["showAll"].includes(type)){globs.events.publish("savePageEnable",true)}
    
    let idx
    let zones
    let content
    let users
    let deselect
    let allSites
    let allContent
    let allUsers
    switch (type){
      // case 'site':
      //   let sites = this.state.sites
      //   idx = sites.findIndex((sVal)=>val==sVal)
      //   if (idx == -1)  {
      //     sites.push(val)
      //   } else {
      //     sites.splice(idx, 1)
      //   }
      //   this.setState({sites: sites})
      //   break
      case 'content':
        content = this.state.content
        idx = content.findIndex((cVal)=>val==cVal)
        if (idx == -1)  {
          content.push(val)
          allContent = (content.length == this.state.all_content.length)
        } else {
          content.splice(idx, 1)
          allContent = false
        }
        this.setState({content: content, allContent: allContent})
        break
      case 'allContent':
        content = this.state.content
        deselect = (val.length == 0)
        val.forEach((c, i) => {
          content.push(c)
        })
        if (deselect) content = []
        allContent = !deselect
        this.setState({content: content, allContent: allContent})
        break
      case 'zone':
        zones = this.state.zones
        idx = zones.findIndex((zVal)=>val.zoneId==zVal)
        if (idx == -1)  {
          zones.push(val.zoneId)
          // check if all zones are checked off
          allSites = (zones.length == globs.zonesInfo.info.length)
        } else {
          zones.splice(idx, 1)
          allSites = false
        }
        this.checkSite(val.siteId, zones)
        this.setState({zones: zones, allSites: allSites})
        break
      case 'allZones':
        zones = this.state.zones
        deselect = true
        val.zones.forEach((z, i) => {
          idx = zones.findIndex((zVal)=>z.zoneId==zVal)
          if (idx == -1)  {
            deselect = false
          }
        })
        val.zones.forEach((z, i) => {
          idx = zones.findIndex((zVal)=>z.zoneId==zVal)
          if (idx == -1)  {
            zones.push(z.zoneId)
          } else {
            if (deselect) zones.splice(idx, 1)
          }
        })
        allSites = (zones.length == globs.zonesInfo.info.length)
        // check zones for sites
        this.checkSite(val.siteId, zones)
        this.setState({zones: zones, allSites: allSites})
        break
      case 'user':
        users = this.state.users
        idx = users.findIndex((uVal)=>val==uVal)
        if (idx == -1)  {
          users.push(val)
          allUsers = (users.length == this.state.all_users.length)
        } else {
          users.splice(idx, 1)
          allUsers = false
        }
        this.setState({users: users, allUsers: allUsers})
        break
      case 'allUsers':
        users = this.state.users
        deselect = (val.length == 0)
        val.forEach((u, i) => {
          users.push(u)
        })
        if (deselect) users = []
        allUsers = !deselect
        this.setState({users: users, allUsers: allUsers})
        break
      case "showAll":
//         cl(this.state.all_sites)
        let as=this.state.all_sites.slice(0)
        let ind=this.siteToIndex[val]
        as[ind].showAll=!as[ind].showAll
        this.setState({all_sites:as})
//         cl(this.siteToIndex)
        break
      case "allSites":
        let st=this.state
        zones = this.state.zones
        deselect = true
        globs.zonesInfo.info.forEach((z, i)=>{
          idx = zones.findIndex((zVal)=>z.zoneId==zVal)
          if (idx == -1)  {
            deselect = false
          }
        })
        globs.zonesInfo.info.forEach((z, i) => {
          idx = zones.findIndex((zVal)=>z.zoneId==zVal)
          if (idx == -1)  {
            zones.push(z.zoneId)
          } else {
            if (deselect) zones.splice(idx, 1)
          }
        })
        allSites = !deselect
        // check zones for sites
        this.state.all_sites.forEach((s, i)=>{
          this.checkSite(s.siteId, zones)
        })
        this.setState({zones: zones, allSites: !st.allSites})
        break
    }
    cl([allSites, allUsers, allContent])
  }

  makeReport=async(reportId, body=null)=> {
    await this.makeSites()
    let accountUsers=await this.makeUsers()// all users in account
    await loadReportsInfo()
    let gri=globs.reportsInfo.info
//     cl(`make ${reportId}`)
//     cl(gri)
    let r = gri.find((re)=>re.reportId == reportId)
    if (r) {
      if (body) {
        r = body;
      }
    // gri.forEach(r=>{
      // if (r.reportId==reportId) {// was this.props.parms.reportId
//         cl(r)
      let users=[]// the database users needs to be limited to users from this account
      accountUsers.forEach(au=>{
        if(r.allUsers || r.users.includes(au.userId)){users.push(au.userId)}
      })
      r.users=users
      let zones=[]
      globs.zonesInfo.info.forEach(z=>{
        if(r.allSites || r.zones.includes(z.zoneId)){
          zones.push(z.zoneId)}
      })
      r.zones=zones
      let sites=[]
      globs.sitesInfo.info.forEach(s=>{
        if(r.allSites || r.sites.includes(s.siteId)){sites.push(s.siteId)}
      })
      r.sites=sites
      let content = []
      this.state.all_content.forEach(c=>{
        if(r.allContent || r.content.includes(c[1])){content.push(c[1])}
      })
      r.content = content

      let d = new Date()
      // let ts = r.customStart + this.calcTimezoneOffset(r.timezone)
      // let start_ts = r.customStart + d.getTimezoneOffset()
      // let end_ts = r.customStart + d.getTimezoneOffset()
      // let start_ts = start_ts - this.calcTimezoneOffset(r.timezone)
      // let end_ts = end_ts - this.calcTimezoneOffset(r.timezone)

      let start_ts = r.customStart - calcTimezoneOffset(r.timezone)
      let end_ts = r.customEnd - calcTimezoneOffset(r.timezone)

      // let sDate = new Date(r.customStart)
      // let eDate = new Date(r.customEnd)
      let startDate = new Date(start_ts)
      let endDate = new Date(end_ts)
      let tp = {startDate: startDate, endDate: endDate, key: "selection"}
      let st = this.formatTime(startDate)
      let et = this.formatTime(endDate)
//         cl(r.timezone)
      r.timezone=r.timezone||"America/Los_Angeles"
      // r.startDate = startDate
      // r.endDate = endDate
      // cl([sDate, startDate])
      // cl([eDate, endDate])
      // cl([st, et])
//         cl(tp)
//         cl([r.allSites, r.allUsers, r.allContent])
//         cl([r.period])
      this.setState(r)
//         cl(r)
      this.setState({timePeriod: tp, startTime: st, endTime: et})

      // set open state
      this.setState({showSiteDetail: [...r.sites]})
//         cl(this.state)
    }
    this.setState({loaded: true})
    // })
  }

  onSubmit=async(cmd)=> {
    if (cmd == "save" || cmd == "clone") {
//       cl("saving report")
      // format date and time to utc

      let fromDate = new Date(this.state.timePeriod.startDate)
      let toDate = new Date(this.state.timePeriod.endDate)
      fromDate.setHours(0,0,0,0)
      toDate.setHours(0,0,0,0) 

      if (fromDate.getTime() === toDate.getTime()) {
        if (this.state.startTime > this.state.endTime) {
          let temp = this.state.startTime
          this.state.startTime = this.state.endTime
          this.state.endTime = temp
        }
      }

      let sDate = this.formatDateUtcTimestamp(this.state.timePeriod.startDate, this.state.startTime)
      let eDate = this.formatDateUtcTimestamp(this.state.timePeriod.endDate, this.state.endTime)
      

      let body = {
        userId: this.state.userId,
        accountId: this.state.accountId,
        title: this.state.title,
        description: this.state.description,
        users: this.state.users,
        sites: this.state.sites,
        zones: this.state.zones,
        content: this.state.content,
        customStart: sDate,
        customEnd: eDate,
        timezone: this.state.timezone,
        period: this.state.period,
        absrel: this.state.absrel,
        allSites: this.state.allSites,
        allUsers: this.state.allUsers,
        allContent: this.state.allContent,
      }

      if (cmd == "save" && this.state.reportId != "") {
        body.reportId = this.state.reportId
      }

      if (cmd == "clone") body.title =  body.title + " Copy"

      cl(body)

      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/reports", method: "update", 
      sessionId: globs.userData.session.sessionId, 
      body: body 
      })
      cl(res.data)
      let reportId=res.data.reportId
      cl([this.state.reportId, reportId])
      // new report
      if (!this.state.reportId) {
        // push newly created report into report info
        cl("new report")
        body.reportId=reportId
        globs.reportsInfo.info.push(body)
        // this.makeReport(reportId)
        globs.events.publish("saveOK",true)
        this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
        history.replace(`/usa/c18/reports/editReport/${reportId}`)
        history.push(`/usa/c18/reports/viewReport/${reportId}`)
        this.setState({reportId: reportId})
      } else if (this.state.reportId && this.state.reportId!=reportId){// cloned the report
        cl("cloned report")
        body.reportId=reportId
        globs.reportsInfo.info.push(body)
        history.replace(`/usa/c18/reports/editReport/${reportId}`)
        let res=await this.props.parms.getPopup({text:"You are now editing a copy of the original report", buttons:["OK"]})
        globs.events.publish("saveOK",true)
        this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
      } else { // editing report
        cl("edited report")
        // update page with new info
        globs.reportsInfo.info[globs.reportsInfo.info.findIndex((re)=> re.reportId == reportId)] = body
        cl(globs.reportsInfo.info.find((r)=>r.reportId == reportId))
        globs.events.publish("saveOK",true)
        this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
        history.push(`/usa/c18/reports/viewReport/${reportId}`)
      }
      this.makeReport(reportId, body)
//       globs.reportsInfo.got=false
//       await loadReportsInfo()
//       cl(globs.reportsInfo.info)
    } else if (cmd == "delete") {
      let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this report?", buttons:["Cancel","Yes"]})
      if(res=="Yes"){
        cl("deleting report" + this.state.reportId)
        if (this.state.reportId != "") {
          wsTrans("usa", {cmd: "cRest", uri: "/s/reports", method: "delete", 
          sessionId: globs.userData.session.sessionId, body: {
            reportId:this.state.reportId,
          }})
          // remove deleted report from gri
          let idx = globs.reportsInfo.info.findIndex((r)=>r.reportId==this.state.reportId)
          if (idx != -1)  {
            cl("removing from list")
            globs.reportsInfo.info.splice(idx, 1)
          }
          history.push(`/usa/c18/reports/reportList/`)
        }
      }
    }
    if(cmd=="view"){
      cl(this.props)
      cl(this.state)
      history.push(`/usa/c18/reports/viewReport/${this.state.reportId}`)
    }
    // globs.events.publish("saveOK",true)
    // cl("cancel?")
    // history.goBack()
  }


  makeSites=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let sites=[]
    let gsi=globs.sitesInfo.info
    this.siteToIndex={}
    let i = 0
    for (let s of gsi) {
      this.siteToIndex[s.siteId]=i
      sites.push(
        {
          name: s.name,
          showAll: true,
          zones:this.makeZones(s.siteId),
          siteId:s.siteId,
        }
      )
      i++ 
    }
    cl(sites)
    this.setState({loaded:true, all_sites:sites})
  }

  makeZones=(siteId)=>{
    let gzi=globs.zonesInfo.info
    let zones=[]
    gzi.forEach(z=>{
      if(z.siteId==siteId) {zones.push(
        {
          name: z.zoneName,
          zoneId: z.zoneId,
        }
      )}
    })
    return zones
  }

  makeUsers=async()=>{
    await loadUsersInfo()
    let users=[]
    let gui=globs.usersInfo.info
    gui.forEach(u=>{
      users.push(
        {
          name: u.name,
          avatar: u.avatar,
          userId: u.userId,
        }
      )
    })
    this.setState({loaded:true, all_users:users})
    cl(users)
    return users
  }

  // check if 
  checkSite(siteId, zones) {
    let site = this.state.all_sites.find((s) => siteId == s.siteId)
    let sites = this.state.sites
    let deselect = true
    for (let i = 0; i < site.zones.length; i++) {
      let z = site.zones[i]
      deselect = !zones.includes(z.zoneId)
      // found a zone for this site inside new zones list
      if (!deselect) break
    }

    let idx = sites.findIndex((sVal)=> siteId == sVal)
    if (idx == -1)  {
      // site does not exist in sites
      sites.push(siteId)
    } else {
      // if we did not find a zone but site is in arr, then remove
      if (deselect) sites.splice(idx, 1)
    }
//     cl(sites)
    this.setState({sites: sites})
  }

  // showSites=()=> {
  //   // load sites
  //   let sites = []
  //   this.state.all_sites.forEach((s, i) => {
  //     let checked = this.state.sites.includes(s.siteId)
  //     sites.push(
  //       <span key={i}>
  //         <input onChange={()=>this.onToggle("site", s.siteId)} checked={checked} type="checkbox" id={s.siteId} /><label for={s.siteId}>{s.name}</label>
  //       </span>
  //     )
  //   })
  //   return (
  //     <>
  //       <div className="checkbox-field">
  //       {sites}
  //       </div>
  //     </>
  //   )
  // }

  showSites2=()=> {
    // load sites and zones
    let sites = []
    let unselect = false
//     cl(this.state.zones)
//     cl(site)
    this.state.all_sites.forEach((s, i)=> {
      s.zones.forEach((z, i) => {
        let selected = (this.state.zones.includes(z.zoneId)) ? "toggle selected" : "toggle"
        unselect = (selected == "toggle") ? false : unselect
      })
    })
    unselect=this.state.allSites
    
    let selectStr = (unselect) ? "Unselect All" : "Select All"
    this.state.all_sites.forEach((s, i) => {
//       cl(this.state.sites)
//       cl(this.state.showSiteDetail)
//       let open = (this.state.showSiteDetail.includes(s.siteId)) ? "open" : ""

//       cl(open)
      sites.push(
        <div key={i}>
          <h2>{s.name}</h2>
          <div className="details checkbox-field">
            <div className="form-title">Zones</div>
            {this.showZones2(s.siteId)}
          </div>
        
        </div>
      )
    })
    return (
      <>
        <button type="button" className="select-all" onClick={()=>{this.onToggle("allSites", "")}}>{selectStr}</button> 
        {sites}
      </>
    )
  }

  showContent=()=> {
    let content = []
    let unselectedContent = []
    let unselect = true
    this.state.all_content.forEach((c, i) => {
      let selected = (this.state.content.includes(c[1])) ? "toggle selected" : "toggle"
      unselect = (selected == "toggle") ? false : unselect
      if (selected == "toggle") unselectedContent.push(c[1])
      let label = "content" + (i + 1)
      content.push(
        <button key={i} onClick={()=>this.onToggle("content", c[1])} type="button" className={selected} id={label}>{c[0]}</button>
      )
    })
    let selectStr = (unselect) ? "Unselect All" : "Select All"
    return (
      <>
        <div className="user-toggle-field">
        {content}
        </div>
        <button onClick={()=>{this.onToggle("allContent", unselectedContent)}} type="button" className="select-all">{selectStr}</button>
        <hr/>
      </>
    )
  }

  // showZones=()=> {
  //   let zones = []
  //   this.state.all_sites.forEach((s, i) => {
  //     s.zones.forEach((z, j) => {
  //       let selected = (this.state.zones.includes(z.zoneId)) ? "toggle selected" : "toggle"
  //       zones.push( 
  //         <button key={[i, j]} onClick={()=>this.onToggle("zone", z.zoneId)} type="button" className={selected} id={z.zoneId}>{z.name}</button>
  //       )
  //     })
  //   })
  //   return (
  //     <>
  //       <div className="user-toggle-field">
  //       {zones}
  //       </div>
  //     </>
  //   )
  // }

  showZones2=(siteId)=> {
    let zones = []
    let site = this.state.all_sites.find((s) => s.siteId == siteId)
    let unselect = true
//     cl(this.state.zones)
//     cl(site)
    site.zones.forEach((z, i) => {
      let selected = (this.state.zones.includes(z.zoneId)) ? "toggle selected" : "toggle"
      unselect = (selected == "toggle") ? false : unselect
      if(site.showAll||this.state.zones.includes(z.zoneId)){
        zones.push( 
          <button key={i} onClick={()=>this.onToggle("zone", {siteId: siteId, zoneId: z.zoneId})} type="button" className={selected} id={z.zoneId}>{z.name}</button>
        )
      }
    })
    let selectStr = (unselect) ? "Unselect All" : "Select All"
    return (
      <>
        <div className="toggle-field">
        {zones}
        </div>
        <br />
        <span>
          <input type="checkbox" checked={site.showAll} id="" onChange={()=>this.onToggle("showAll",site.siteId)}/><label htmlFor="" className="small-text">Show Unselected Zones</label>
        </span>
        <button type="button" className="select-all" onClick={()=>{this.onToggle("allZones", site)}}>{selectStr}</button> 
        <hr/>
      </>
    )
  }

  showUsers=()=>{
    let users = []
    let unselectedUsers = []
    let unselect = true
    this.state.all_users.forEach((u, i) => {
      let selected = (this.state.users.includes(u.userId)) ? "toggle selected" : "toggle"
      unselect = (selected == "toggle") ? false : unselect
      if (selected == "toggle") unselectedUsers.push(u.userId)
      let av = u.avatar
      let path
      if(u.avatar){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      } else {
        path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
      }
      users.push(
        <button key={i} onClick={()=>this.onToggle("user", u.userId)} type="button" className={selected} id={u.userId}>
          <img src={path}/>
          {u.name}
        </button>
      )
    })
    let selectStr = (unselect) ? "Unselect All" : "Select All"
    return (
      <>
        <div className="user-toggle-field">
        {users}
        </div>
        <button onClick={()=>{this.onToggle("allUsers", unselectedUsers)}} type="button" className="select-all">{selectStr}</button>
        <hr/>
      </>
    )
  }

  showTimezone=()=>{
//     cl(this.state)
    let tzs=["Pacific/Auckland",
      "Pacific/Midway",
      "Pacific/Honolulu",
      "America/Anchorage",
      "America/Los_Angeles",
      "America/Denver",
      "America/Chicago",
      "America/New_York",
      "America/Antigua",
      "America/Buenos_Aires",
      "Atlantic/Stanley",
      "Atlantic/Azores",
      "Europe/London",
      "Europe/Berlin",
      "Europe/Athens",
      "Europe/Moscow",
      "Europe/Volgograd",
      "Asia/Karachi",
      "Asia/Yekaterinburg",
      "Asia/Bangkok",
      "Asia/Hong_Kong",
      "Asia/Tokyo",
      "Australia/Brisbane",
      "Asia/Vladivostok",
      "Pacific/Auckland"];

      return(
        <div style={{position: "absolute"}}>

        <div className="time-period-container">
          <div className="custom-select">
            <C18Select00 
              id="report-timezone"
              onChange={(e)=>this.onChange("timezone", e.currentTarget.value)}
              value={this.state.timezone}
            >
              {tzs.map((t,i)=>{
                return(
                  <option key={i} value={t}>{t}</option>
                )
              })}
            </C18Select00>
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          </div>
        </div>
        </div>
      )
  }

  showTimePeriodSelect=()=>{
    let periods=[
      ["Today", "today"],
      ["Yesterday", "yesterday"],
      ["Last 7 Days", "last7"],
      ["Last 30 Days", "last30"],
      ["Last 60 Days", "last60"],
      ["Last Week", "lastWeek"],
      ["This Week", "thisWeek"],
      ["Last Month", "lastMonth"],
      ["This Month", "thisMonth"],
      ["Custom", "custom"],
      ];

      return(
        <div style={{position: "absolute"}}>

        <div className="time-period-container">
          <div className="custom-select">
            <C18Select00 
              id="report-timeperiod"
              onChange={(e)=>this.onChange("period", e.currentTarget.value)}
              value={this.state.period}
            >
              {periods.map((t,i)=>{
                return(
                  <option key={i} value={t[1]}>{t[0]}</option>
                )
              })}
            </C18Select00>
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          </div>
        </div>
        </div>
      )
  }

  showTimePeriodCustom=()=>{
    return(
      <div className="time-period-button" tabIndex="0">
        <table><tbody>
          <tr>
            <td><strong>From:</strong></td>
            <td>{dateToDisplayDate(this.state.timePeriod.startDate, "mm/dd/yyyy")}</td>
            <td>
              <input style={{
                width: "130px"
              }}
                id="picker-start-time" 
                type="time" 
                value={this.state.startTime}
                onChange={(e)=>this.onChange("startTime", e.currentTarget.value)}
                />
            </td>
            <td rowSpan="2">
              <span onClick={()=>{this.onChange("showTimePeriod", (this.state.showTimePeriod=="none")?"block":"none")}}className="material-icons">
                keyboard_arrow_down
              </span>
            </td>
          </tr>
          <tr>
            <td><strong>To:</strong></td>
            <td>
              {dateToDisplayDate(this.state.timePeriod.endDate, "mm/dd/yyyy")}
            </td>
            <td>
              <input style={{
                width: "130px"
              }}
                id="picker-end-time" 
                type="time" 
                value={this.state.endTime}
                onChange={(e)=>this.onChange("endTime", e.currentTarget.value)}
              />
            </td>
          </tr>
        </tbody></table>
        <div style={{position: "relative"}}>
          <div style={{position: "absolute", display: this.state.showTimePeriod}}>
            <DateRangePicker
              ranges={[this.state.timePeriod]}
              months={1}
              showSelectionPreview={true}
              onChange={(item)=>{
                cl(item.selection)
                this.onChange("timePeriod", item.selection)
              }}
              direction="horizontal"
            />
        </div>
      </div>
      </div>
    )
  }

  showAbsoluteRelative=()=> {
    return(
      <div className="float-right-box" style={{
        paddingTop: "10px",
        paddingLeft: "10px"
       }}>
        <C18Input00 type="radio" id="time-absolute" name="time-select" value="absolute"
          checked={this.state.absrel=="absolute"} onChange={(e)=> {
            this.onChange("absrel", e.currentTarget.value)}}></C18Input00>
        <label htmlFor="time-absolute">Absolute Time</label>
        <C18Input00 type="radio" id="time-relative" name="time-select" value="relative"
        checked={this.state.absrel=="relative"} onChange={(e)=>{
          this.onChange("absrel", e.currentTarget.value)}}></C18Input00>
        <label htmlFor="time-relative">Relative Time</label>
        <div className="spacer"></div>
      </div>
    )
  }

  showEditReport=()=> {
    return (
      <div>
        <form id="edit-report">
          <label htmlFor="report-title">Title</label>
          <input type="text" id="report-title" value={this.state.title}
          onChange={(e)=>this.onChange("title", e.target.value)}/>
          <br/>
          <label htmlFor="report-description">Description</label>
          <input type="text" id="report-description" value={this.state.description}
          onChange={(e)=>this.onChange("description", e.target.value)}/>
          <br />
          <br />

          <div className="form-title">Sites</div>
          {this.showSites2()}
          <br />

          <div className="form-title">Content</div>
          {this.showContent()}
          <br />

          <div className="form-title">Users</div>
          {this.showUsers()}
          <br />

          <div className="form-title">Timezone</div>
          {this.showTimezone()}

          <br />
          <br />
          <br />
          
          <div className="form-title">Time Period</div>
          {this.showTimePeriodSelect()}
          <br />
          <br />
          <br />

          <div className="time-period-container">
          {this.state.period == "custom" ? this.showTimePeriodCustom() : ""}
          </div>

          <div className="clearfloat"></div>

          <br />

          {this.state.period == "custom" ? this.showAbsoluteRelative() : ""}

          <div className="float-left-box">
            <button type="button" className="danger right-margin" onClick={()=>this.onSubmit("delete")}>
              Delete
            </button>
            <button type="button" className="outlined" disabled={!this.state.reportId} onClick={()=>this.onSubmit("view")}>
              View Report
            </button>
          </div>
          <div className="float-right-box">
            <button type="button" className="outlined" disabled={!this.state.reportId} onClick={()=>this.onSubmit("clone")}>
              Clone
            </button>
          </div>
          <div className="clearfloat"></div>

        </form>
      </div>
      )
  }

  // formatDate=(d)=> {
  //   let month = String(d.getMonth() + 1);
  //   let day = String(d.getDate());
  //   const year = String(d.getFullYear());

  //   if (month.length < 2) month = '0' + month;
  //   if (day.length < 2) day = '0' + day;

  //   return `${month}/${day}/${year}`;
  // }

    formatTime=(d)=> {
      let hr = String(d.getHours());
      let min = String(d.getMinutes());

      if (hr.length < 2) hr = '0' + hr;
      if (min.length < 2) min = '0' + min;

      return `${hr}:${min}`;
    }


  formatDateUtcTimestamp=(d, t)=> {
    d.setHours(t.split(":")[0])
    d.setMinutes(t.split(":")[1])
    let tzAdj = calcTimezoneOffset(this.state.timezone);
    let utcDate = new Date(d.getTime() + tzAdj)
//     cl([d, utcDate])
    // get utc date adjusted to selected timezone
    return utcDate.getTime()
    // let utcDateStr = dateToDisplayDate(utcDate, "mm/dd/yyyy h:mm ap");
    // return utcDateStr;
  }
  
  render(){
    if (this.state.loaded) {
      return this.showEditReport();
    }
    return <><br/><div>loading. . .</div></>
  }
    
}

export default C18EditReport;
