import React from 'react';
import Select from 'react-select'
import C18Input00 from './C18Input00'
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import C18Filter00 from './C18Filter00'
import {acctFeature} from './C18utils'
import C18GrowJournalEdit00 from './C18GrowJournalEdit00'

import {wsTrans,doGetPostBasic} from '../utils/utils'
import {cl,constant,globs,getRandomString,getTime} from '../../components/utils/utils';

class C18SidebarGrowJournalHeader00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      mode:"sites",
      note:"",
      tags:[],
      images:[],
      showAddNote:false,
      showFilter:false,
    }
    this.tagOptions=[
      {value: "equipment", label: "equipment"},
      {value: "sensors", label: "sensors"},
      {value: "plants", label: "plants"},
      {value: "pests", label: "pests"},
      {value: "nutrients", label: "nutrients"},
      {value: "power", label: "power"},
      {value: "personnel", label: "personnel"},
      {value: "supplies", label: "supplies"},
      {value: "maintenance", label: "maintenance"},
      {value: "other", label: "other"},
    ]
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveCancel)
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }
  
  saveCancel=async(cmd)=>{
    cl(cmd)
    switch(cmd){
      case "save":
        await this.saveJournalEntry()
        break
    }
  }
  
  onChange=(type,vals)=>{
    cl(type,vals)
    let st=this.state
    let pa=this.props.parms
    switch(type){
      case "addGJ":
        vals.e.preventDefault()
        this.setState({showAddNote:!st.showAddNote})
        break
      case "filter":
        vals.e.preventDefault()
        this.setState({showFilter:!(st.showFilter||false)})
//         cl("filter")
        break
      case "filterDlg":
//         cl(vals)
        if(vals.cmd=="filter"){pa.onChange(vals)}
        this.setState({showFilter:false})
        break
      case "addGJEntry":
        this.setState({showAddNote:false})
        this.props.parms.onChange(vals)
        cl(vals)
        break
    }
  }
  
// /****************** Image Handling *******************************/
//   sendImages=async()=>{
//     let data = new FormData()
//     this.state.images.forEach((img,i)=>{
//       data.append(`id${i}`, img.id)
//       data.append(`file${i}`, img.image)
//     })
//     let url=`${constant.expressUrl}/usa/images`
//     let method="POST"
//     let type="multipart/form-data"
//     cl(url)
//     return await doGetPostBasic(url, method, data, type)// pro  mise
//   }
//   
//   markImage=(e)=>{
//     let images=this.state.images.slice(0)
//     let len0=images.length
// //     cl(e.target)
// //     cl(e.target.files)
// //     cl(e.target.files.length)
//     let imagesStr=""
//     for(let i=0;i<e.target.files.length;i++){
// //       let f=e.target.files[i]
// //       let expImage={id: getRandomString(16), image: e.target.files[i]/*e.target.files[0]*/}// expanded image, w/ our name
//       images.push({id: getRandomString(16), image: e.target.files[i]/*e.target.files[0]*/})
//       imagesStr+=`[Img${len0+i+1}]`
//     }
// //     cl(e.target.files.FileList)
// //     e.target.files.FileList.forEach(f=>{
// //       let expImage={id: getRandomString(16), image: f/*e.target.files[0]*/}// expanded image, w/ our name
// //       images.push(expImage)
// //     })
// //     for(let i=len0;i<images.length;i++){imagesStr+=`[Img${i+1}]`}
//     this.setState({note: this.state.note+imagesStr, images: images})//`{Img:${expImage.id}}`
//   }
//   
// /****************** End Image Handling *******************************/
//   
//   saveJournalEntry=async()=>{
//     cl(this.props)
//     let da=Math.floor(getTime())//new Date().getTime()/1000
// 
//     let images=[]
//     if(this.state.images.length){
//       this.state.images.forEach(img=>{
//         images.push({name: img.image.name, id: img.id, size: img.image.size})
//       })
//       let r=await this.sendImages()
//     }
//     let tags=this.state.tags.slice(0)
//     let p=this.props.parms
//     if(p.pageType){// fui only
//       tags.push(`${p.pageType}-${p.zuci}`)
//     }
//     
//     let note=this.state.note
//     let i=1
//     var pos
//     
//     do{
//       let search=`[Img${i}]`
//       pos=note.indexOf(search)
//       if(pos>=0){
//         note=note.substr(0,pos)+`{Img:${images[i++-1].id}}`+note.substr(pos+search.length)
//       }
//     }while(pos>=0)
// 
//     let gjEntry={
//       threadId: getRandomString(16),
//       growJournalId:getRandomString(16),
//       level:p.level,
//       siteId:(p.level=="account")?null:p.siteId,
//       zoneId: ((p.level=="site")||(p.level=="account"))?null:p.zoneId,//this.props.parms.zone.zoneId,
//       userId: globs.userData.session.userId,
//       body: note,//this.state.note,
//       subject: "",//this.state.gjSubject,
//       modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
//       dispTime: da,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
//       tags:tags,
//       images: images,
//       original: true,
//     }
//     this.props.parms.onChange({cmd:"addGJEntry",gjEntry:gjEntry})
//     this.setState({images:[],tags:[],note:""})
//     let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: "create", 
//       sessionId: globs.userData.session.sessionId,
//       body: gjEntry})
//   }
//   
//   setTags=(vals)=>{
//     let tags=[]
//     vals.forEach(v=>{
//       tags.push(v.value)
//     })
//     return tags
// //     cl(vals)
// //     cl(tags)
//   }
//   
//   onChange=(type,vals)=>{
// //     cl(type,vals)
// //     globs.events.publish("savePageEnable",true)
//     switch(type){
//       case "note":
// //         cl(vals)
//         this.setState(vals)
//         break
//       case "chartElements":
// //         this.setTags(vals)
//         this.setState({tags:this.setTags(vals)})
// //         cl(vals)
//         break
//     }
// //     cl(type)
//   }
//   
//   getTagOption=(value)=>{
//     for(let i=0;i<this.tagOptions.length;i++){
//       let o=this.tagOptions[i]
//       if(o.value==value)return o
//     }
//   }
//   
//   makeChartElements=()=>{
//     let elements=[]
//     this.state.tags.forEach(t=>{
//       elements.push(this.getTagOption(t))
//     })
//     return elements
// //     cl(elements)
// //     return [
// //       {value: "supplies", label: "supplies"},
// //       {value: "maintenance", label: "maintenance"},
// //     ]
//   }
// Code that was moved to C18GrowJournalEdit00  
//         <label htmlFor="">Add Note</label>
//         <textarea 
//           className="fullwidth"
//           value={this.state.note}
//           onChange={e=>this.onChange("note",{note:e.currentTarget.value})}
//           ></textarea>
//         <br />
//         <label htmlFor="">Add Tags</label>
//         <Select className="floatleft grow-journal-select-tags" 
//           value={this.makeChartElements()}
//           onChange={o=>{this.onChange("chartElements",o)}}
//           options={this.tagOptions}
//           isMulti={true}
//         />        
// 
//         <div className="floatright">
//           <button type="button" className="top-margin-smaller floatleft material-icons-outlined" aria-label="upload">
//           </button >
//           <C18Button00 type="button" className="floatleft material-icons-outlined" aria-label="upload" style={{
//             width:40, 
//             height:40, 
//           }}>attach_file
//           </C18Button00>
//           <form className="file-upload-form">
//           <C18Input00 type="file" multiple onChange={this.markImage} style={{
//             position:"absolute", 
//             width:40, 
//             height:40, 
//             marginTop:0, 
//             marginLeft:-41,
//             zIndex:10,                   
//             opacity:0,
//             cursor: "pointer",
//           }}/>
//           </form>
//         <C18Button00 type="button" className="only-icon floatleft filled material-icons-outlined" aria-label="create"
//           onClick={e=>this.saveJournalEntry()}>
//           send
//           </C18Button00>
//           
//         </div>
// 
//         <div className="clearfloat"></div>
  
  
  render(){
    let st=this.state
    let p=this.props.parms
//     cl(p)
    var myTag
    if(p.pageType){myTag=`${p.pageType}-${p.zuci}`}
//     cl(st)
//         <C18Anchor00 to="" className="colored-link-text floatright material-icons-outlined add" aria-label="add GJ"
//         onClick={e=>this.onChange("filter",{e:e})}
//         >filter_alt</C18Anchor00>
    return(
      <div id="/sidebar/growJournal/header">
        <C18Filter00 parms={{
          display:st.showFilter,
          fields:["tags","users","date"],
          onChange:vals=>this.onChange("filterDlg",vals)
        }}/>
        <C18Anchor00 to="" className="colored-link-text floatright material-icons-outlined add" aria-label="add GJ"
        onClick={e=>this.onChange("addGJ",{e:e})}
        >add</C18Anchor00>
        {acctFeature("filterList")&&
          <C18Anchor00 to="" className="colored-link-text floatright material-icons-outlined add" aria-label="add GJ"
          onClick={e=>this.onChange("filter",{e:e})}
          >filter_alt</C18Anchor00>
        }
        <div className="clearfloat"></div>
        <C18GrowJournalEdit00 parms={{
          level:p.level,
          siteId:p.siteId,
          zoneId:p.zoneId,
          pageType:p.pageType,
          zuci:p.zuci,
          note:st.note,
          tags:st.tags,
          images:st.images,
          myTag:myTag,
//           onChange:this.props.parms.onChange,
          getPopup:p.getPopup,
          onChange:e=>this.onChange("addGJEntry",e),
          display:st.showAddNote,
        }}/>
        <div className="clearfloat"></div>
        <br /><hr />
      </div>
      
    )
  }
}
      
export default C18SidebarGrowJournalHeader00;
