import React from 'react';
// import UsaIcon from '../UsaIcon';
import C18Select01 from '../C18Select01'
import UsaSelect00 from '../UsaSelect00'
// import UsaSelect00 from '../UsaSelect00'
import {wsTrans} from '../../../usa/utils/utils'
import {loadZonesInfo,getZoneInfo,saveTable,getSiteName,getZoneName} from '../C18utils'
import {cl,globs,az,getTime,getRandomString} from '../../../components/utils/utils';

const verticalSpace=<div style={{height: 10}}/>

class Stations extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      loaded:false,
      zone:0,
      kFactor:0,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
    this.setBreadCrumbs()
    this.loadInfo()
  }
  
  componentWillUnmount=()=>{
    if(this.subscribe_savePageEvent){this.subscribe_savePageEvent.remove()}
  }
  
  setBreadCrumbs=()=>{
//     cl(this.props.parms)
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(p){
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
//               {t:"iDoser", url:`/usa/c18/idoser/nutrients`},
              {t:"Nutrients", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/nutrients`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    await loadZonesInfo()
//     cl(this.props.parms.zone)
//     cl(getZoneInfo(this.props.parms.zone))
    let zi=getZoneInfo(this.props.parms.zone)
    cl(zi)
    this.gatewayId=zi.gatewayId//||zi.zoneId// ??? why zoneId?
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/stations", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})// now for gatewayId
    let stations=res.data
    let station=stations[0]?.stationId
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/pumperControllers", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})// now for gatewayId
    let pumperControllers=res.data
    cl(pumperControllers)
    res=await wsTrans("usa", {cmd: "cRest", uri: "/s/nutrients", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})// now for gatewayId
    let nutrients={}
    res.data.forEach(re=>{
      nutrients[re.nutrientId]=re
    })
//     cl(nutrients)
//     let nutrients=res.data
//     cl(nutrients)
//     cl(stations)
//     cl(pumperControllers)

    this.setState({
      loaded:true,
      stations:stations,
      stationId:station,
      kFactor:stations[0]?.dvAddr/100,
      pumperControllers:pumperControllers,
      nutrients:nutrients,
    })
  }
  
  selectStation=(stationId)=>{// need to set 
    cl("select")
    
  }
  
  savePage=async(cmd)=>{
//     cl(this.gatewayId)
//     return
    if(cmd=="save"){
      cl(this.state)
      let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/stations", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: {gatewayId:this.gatewayId,stations:this.state.stations}})
      
      r=await wsTrans("usa", {cmd: "cRest", uri: "/s/pumperControllers", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: {gatewayId:this.gatewayId,pumperControllers:this.state.pumperControllers}})
//       globs.events.publish("savePageEnable",false)
      await saveTable("stations",this.gatewayId)
      await saveTable("pumperControllers",this.gatewayId)
      globs.events.publish("saveOK",true)
      }
  }
  
  deleteStation=async(stationId)=>{
    let st=this.state
    let station=st.stations.filter(s=>{return s.stationId==stationId})[0]||{}
    let res=await this.props.parms.getPopup({text:`Are you sure you want to delete ${station.name}?`, buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      globs.events.publish("savePageEnable",true)
      let stations=st.stations.slice(0)
      for(let i=0;i<stations.length;i++){
        if(stations[i].stationId==st.stationId){
          stations.splice(i,1)
          break
        }
      }
      let stationId=stations[0]?.stationId
      this.setState({stations:stations,stationId:stationId})
    }
  }
  
  deletePC=async(pcId)=>{
    let st=this.state
    let pumperControllers=st.pumperControllers.slice(0)
    let pc=st.pumperControllers.filter(pc=>{return pc.pumperControllerId==pcId})[0]||{}
    cl(pc)
    let res=await this.props.parms.getPopup({text:`Are you sure you want to delete ${pc.name}?`, 
      buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      globs.events.publish("savePageEnable",true)
      for(let i=0;i<pumperControllers.length;i++){
        if(pumperControllers[i].pumperControllerId==pcId){
          pumperControllers.splice(i,1)
          break
        }
      }
      this.setState({pumperControllers:pumperControllers})
    }
  }
  
  nextPCId=()=>{
    let gots={}
    this.state.pumperControllers.forEach(pc=>{gots[pc.pumperControllerId]=1})
    for(let i=1;i<100;i++){
      if(!gots[i]){return i}
    }
  }
  
  nextStationId=()=>{
    let gots={}
    this.state.stations.forEach(s=>{gots[s.stationId]=1})
    for(let i=1;i<100;i++){
      if(!gots[i]){return i}
    }
  }
  
  
  
  onChange=(type,vals)=>{
    cl(type,vals)
    let st=this.state
    var pcs,pc,stations,station
    switch(type){
      case "upd":
//         cl(vals)
//         if("zone" in vals){
//           globs.events.publish("savePageEnable",true)
//           stations=st.stations.slice(0)
//           station=stations.filter(s=>{return s.stationId==st.stationId})[0]||{}
//           station.fvAddr=+vals.zone
//           this.setState({stations:stations})
//         }
        if("stationId" in vals){this.selectStation(vals.stationId)}
        this.setState(vals)
        break
      case "fields":
        globs.events.publish("savePageEnable",true)
        stations=st.stations.slice(0)
        let stUpd={stations:stations}
        station=stations.filter(s=>{return s.stationId==st.stationId})[0]||{}
        if ("dvAddr" in vals){
          stUpd.kFactor=vals.dvAddr
          vals.dvAddr=Math.round(100*vals.dvAddr)
        }
        Object.assign(station,vals)
//         cl(station)
        this.setState(stUpd)
        break
      case "pcFields":
        globs.events.publish("savePageEnable",true)
        pcs=st.pumperControllers.slice(0)
        pc=pcs.filter(pc=>{return pc.pumperControllerId==vals.pcId})[0]||{}
        delete vals.pcId
        Object.assign(pc,vals)
//         cl(pc)
        this.setState({pumperControllers:pcs})
        break
      case "pcNute":
        globs.events.publish("savePageEnable",true)
        pcs=st.pumperControllers.slice(0)
        pc=pcs.filter(pc=>{return pc.pumperControllerId==vals.pcId})[0]||{}
//         cl(pc)
//         cl(pc.nutes)
//         cl(vals.nuteId)
        pc.nutes[vals.nuteId]=vals.val
        cl(pc.nutes)
//         let station=stations.filter(s=>{return s.stationId==st.stationId})[0]||{}
        
        this.setState({pumperControllers:pcs})
        break
      case "addPC":
        globs.events.publish("savePageEnable",true)
        pcs=st.pumperControllers.slice(0)
        cl(this.state)
        let nuteId=Object.keys(st.nutrients)[0]||0
        pc={
          modbusAddress:0,
          name:"New Pumper Controller",
          nutes:[nuteId,nuteId,nuteId,nuteId,nuteId,nuteId,nuteId,nuteId],
          pumperControllerId:this.nextPCId(),
          stationId:st.stationId,
        }
        pcs.push(pc)
        this.setState({pumperControllers:pcs})
        break
      case "deleteStation":
        this.deleteStation(st.stationId)
        break
      case "addStation":
        globs.events.publish("savePageEnable",true)
        stations=st.stations.slice(0)
        station={
          dvAddr:0,
          fvAddr:0,
          name:"New Injector Station",
          stationId:this.nextStationId(),
        }
        stations.push(station)
        {this.selectStation(station.stationId)}
        this.setState({stationId:station.stationId,stations:stations})
        break
      case "deletePC":
        this.deletePC(vals.pcId)
        break
    }
  }
  
  showStationSelect=()=>{
    let stations=[]
    this.state.stations.forEach(st=>{
      stations.push({
        v:st.stationId,
        t:st.name,
      })
//       cl(st)
    })
    return(
      <C18Select01 parms={{
        label:"Station",
//         priority:this.state.station,
        valueName:"stationId",
        opts:stations,
        onChange:this.onChange,
      }}/>
    )
  }
  
  showName=()=>{
    let st=this.state
    let station=(st.stations.filter(s=>{return s.stationId==st.stationId})[0])||{}
    return(
      <>
        <label htmlFor="station_name">Name</label>
        <input type="text" id="station_name"
          value={station.name||""}
          onChange={e=>this.onChange("fields",{name:e.currentTarget.value})}
        />
      </>
    )
  }
  
  showZoneSelect=()=>{
    let st=this.state
    let siteId=globs.userData.siteLoaded
    let zones=globs.zonesInfo.info
      .filter(z=>{return (z.siteId==siteId)&&(z.connected) })
      .map((z,i)=>{return(
        <option key={i} value={z.siteZoneIndex}>{z.zoneName}</option>
      )})
    let station=st.stations.filter(s=>{return s.stationId==st.stationId})[0]||{}
//     cl(station)
    return(
      <>
        <label htmlFor="zoneSel">Zone</label>
        <span className="custom-select">
          <select id="zoneSel"
            value={station.fvAddr}
            onChange={e=>{
              this.onChange("fields",{fvAddr:+e.currentTarget.value})}}
          >
            {zones}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
      </>
    )
  }
  
  showKFactor=()=>{
    let st=this.state
    let station=(st.stations.filter(s=>{return s.stationId==st.stationId})[0])||{}
//     let kFactor=station.dvAddr / 100
    return(
      <>
        <label htmlFor="station_kfactor">Waterflow K Factor</label>
        <input type="number" id="station_kfactor"
          value={st.kFactor}
          step="0.01"
          onChange={e=>this.onChange("fields",{dvAddr:e.currentTarget.value})}
        />
      </>
    )
//     return(
//       <div>k Factor</div>
//     )
  }
  
  showPCName=(pc)=>{
    let st=this.state
//      style={{verticalAlign:"text-top"}}
    let nameShort=(pc.name.length>10)?`${pc.name.substring(0,10)}...`:pc.name
    return(
      <>
        <label htmlFor="station_pc_name">
        <span>
          Pumper Controller Name
        </span>
        </label>
        <input type="text" id="station_pc_name"
          value={pc.name}
          onChange={e=>this.onChange("pcFields",{pcId:pc.pumperControllerId,name:e.currentTarget.value})}
        />
        <br/>
        <button type="button" 
        style={{borderStyle:"solid",borderWidth:1,padding:5,borderRadius:2}}
        onClick={e=>this.onChange("deletePC",{pcId:pc.pumperControllerId})}>
          {`Delete ${nameShort}`}
        </button>
      </>
    )
  }
  
  showPCModbusAddress=(pc)=>{
    let st=this.state
    return(
      <>
        <label htmlFor="station_pc_mbaddr">Modbus Address</label>
        <input type="number" id="station_pc_mbaddr"
          value={pc.modbusAddress}
          step="1"
          onChange={e=>this.onChange("pcFields",{pcId:pc.pumperControllerId,modbusAddress:e.currentTarget.value})}
        />
      </>
    )
  }
  
  showNuteSelect=(pc,outputId)=>{
//     cl(pc)
    let st=this.state
//     cl(st)
    let nuteSel=pc.nutes[outputId]
    let opts=Object.values(st.nutrients).map((n,i)=>{
      return(
      <option key={i} value={n.nutrientId}>{n.name}</option>
    )})
    
    return(
      <select key={`sel${outputId}`}
        value={nuteSel}
        onChange={e=>this.onChange("pcNute",{pcId:pc.pumperControllerId, nuteId:outputId, 
          val:e.currentTarget.value})}
      >
      {opts}
      </select>
    )
  }
  
  showOnePCNute=(pc,nuteId,i)=>{
    let st=this.state
    let str=`Output ${i+1}: `

    return(
      <div key={i}>
      {str}
      {this.showNuteSelect(pc,i)}
      </div>
    )
  }
  
  showAddPC=()=>{
    return(
      <button type="button" 
      style={{borderStyle:"solid",borderWidth:1,padding:5,borderRadius:2}}
      onClick={e=>this.onChange("addPC")}>
        Add Pumper Controller
      </button>
    )
  }
  
  showPCNutes=(pc)=>{
    let st=this.state
//           <button type="button" 
//           style={{borderStyle:"solid",borderWidth:1,padding:5,borderRadius:2}}
//           onClick={e=>this.onChange("addPC")}>
//             Add Pumper Controller
//           </button>
//           {this.showAddPC()}
    return(
      <>
        <label htmlFor="station_pc_nutes">Nutes</label>
        <div>
        {pc.nutes.map((nu,i)=>{return this.showOnePCNute(pc,nu,i)})}
          <br/>
        </div>
      </>
    )
  }
  
  showOnePController=(pc,i)=>{
    cl(pc)
    return(
      <div key={i}>
        {this.showPCName(pc)}
          <div className="clearfloat"></div><br/>
        {this.showPCModbusAddress(pc)}
          <div className="clearfloat"></div><br/>
        {this.showPCNutes(pc)}
        <br/>
      </div>
    )
    return null
  }
  
  showPControllers=()=>{
    let st=this.state
    return(
      <>
        <label htmlFor="pumper_controllers">Pumper Controllers</label>
        <div id="pumper_controllers" style={{width:500,
          height:500,borderStyle:"solid",borderWidth:1,
           overflowY:"auto",
          borderRadius:10,padding:10}}>
          {st.pumperControllers
            .filter(pc=>{return pc.stationId==st.stationId})
            .map((pc,i)=>this.showOnePController(pc,i))}
          {this.showAddPC()}
        </div>
      </>
    )
  }
  
  render(){
    let st=this.state
//             {this.showKFactor()}
    if(this.state.loaded){
      return(
        <div id="iDoser_stations">
          <div className="clearfloat"></div>
            {this.showStationSelect()}
              <button type="button" className="material-icons trash after-selector" aria-label="delete nutrient"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("deleteStation")}>
                delete_outline
              </button>

              <button type="button" className="material-icons-outlined add after-selector" aria-label="add nutrient"
              disabled={!this.props.parms.saveOK}
              onClick={e=>this.onChange("addStation")}>
                add
              </button>
            {this.showName()}
            <div className="clearfloat"></div><br/>
            {this.showZoneSelect()}
            <div className="clearfloat"></div><br/>
            {this.showPControllers()}
        </div>
      )
    }else{return<div>loading. . .</div>}
  }
}

export default Stations ;

