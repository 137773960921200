import React from 'react';
import C18Input00 from './C18Input00'
import C18Anchor00 from './C18Anchor00'
import C18InputError00 from './C18InputError00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils'
import {addToAdminLog, saveToAdminLog,loadUsersInfo, getUserIndex, loadDevicesInfo,loadAccountInfo,privs,acctFeature} from './C18utils';
import {cl, globs, constant, allCountries, allStates, allTimezones, allThemes, getTime} from '../../components/utils/utils';
import history from '../../history'

class C18EditUser00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      showSuper:privs("super","",constant.AREA_PRIVS_READ),
      name:"",
      email:"some",
      thirdParty:"",
      thirdPartyTxt:"",
    }
    this.roles=[
      {v:"superman",t:"Superman"},
      {v:"superadmin",t:"Super Admin"},
      {v:"mortal",t:"Mortal"},
    ]
    this.loadInfo()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveUser)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      let name=(this.name)?`Edit ${this.name}`:"Add User"
      
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Users", url:`/usa/c18/admin/manageUsers3`},
              {t:`${name}`, url:`/usa/c18/admin/editUser/${this.userId}`},
            ]},
          pageTitle:name
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
//     cl("unmount")
    this.subscribe_savePageEvent.remove()
//     let popInfo={text:"Changes were made to an Area. Do you want to Save them?.", buttons:["No","Save"]}
//     checkSave(this,this.saveArea,popInfo)
  }
  
  addUser=async (name,email)=>{
//     cl(this.state)
//     cl(this.inviteUser)
    cl(`add user: ${email}`)
    let adminAdds = []
    saveToAdminLog(adminAdds, "addUser", this.state.addEmail)
    addToAdminLog(adminAdds)
    cl(globs.usersInfo.info)
    let user={name: name, email: email,active:true}
    let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
      method: "create", body: user})
    user.userId=res.data.userId
    user.token=res.data.token
    globs.usersInfo.info.push(user)
    cl(user)
//     this.inviteUser.push({email:email})
//     cl(this.inviteUser)
//     this.mySetState({addUserLink: res.data.token,addEmail:"", userSel:`pending-${this.state.addEmail}`})
    this.setState({addUserLink: res.data.token})
  }
  
  saveUser=async(cmd)=>{
    if(this.userId){
      let st=this.state
      let ind=getUserIndex(this.userId)
      Object.assign(globs.usersInfo.info[ind],{name:st.name,email:st.email,role:st.role,active:st.active})
//       cl(globs.usersInfo.info)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "update", 
        sessionId: globs.userData.session.sessionId,
        body: {userId: this.userId,name:st.name,email:st.email,role:st.role,active:st.active}})
//       cl(this.state)
      if(this.state.owner){
        globs.accountInfo.info.owner=this.userId
        await wsTrans("usa", {cmd: "cRest", uri: "/s/accounts", method: "update", 
          sessionId: globs.userData.session.sessionId, body: {
            owner: this.userId,
          }})
      }
    }else{
      await this.addUser(this.state.name,this.state.email)
    }
    globs.events.publish("saveOK",true)
    if(!this.state.showSuper){history.goBack()}
  }

  loadInfo=async()=>{
//     cl(this.props)
    await loadAccountInfo()
//     cl(globs.accountInfo.info)
    await loadUsersInfo()
//     let parts=this.props.parms.url.split("/")
//     this.userId=parts[5]
    this.userId=this.props.parms.adminInfo
//     cl(globs.accountInfo.info)
//     cl(globs.accountInfo.info.owner==this.props.parms.adminInfo)
    var user={email:""}
//     cl(user)
    if(this.userId){
      globs.usersInfo.info.forEach(u=>{
        if(u.userId==this.userId){user=u}
      })
      this.name=user.name||""
//       cl(user)
    }
    this.setBreadCrumbs()
    user.owner=globs.accountInfo.info.owner==this.props.parms.adminInfo
//     let active=user.active
//     cl(active)
    this.setState({loaded:true,name:user.name||"",email:user.email,role:user.role||"mortal",active:user.active||false,owner:user.owner||false})
//     cl(this.state.owner)
  }

  addThirdParty=async()=>{
    cl(this.state)
    if (!this.state.thirdParty) {
      this.setState({thirdPartyTxt: "Please enter a valid user ID."})
    } else {
//     let p3Account=await this.getUserAccount(this.state.thirdParty)
      let ret = await wsTrans("usa", {cmd: "cRest", uri: "/s/thirdParty", method: "update", 
        sessionId: globs.userData.session.sessionId, 
        body: {userId:this.state.thirdParty}})
      cl(ret)
      if (ret.result == "notFound") {
        this.setState({thirdPartyTxt: "User not found. Please enter a valid user ID."})
        return
      }
      if (ret.result == "sameAccount"){
        this.setState({thirdPartyTxt: "Existing user on this account. Please enter a valid user ID."})
        return
      }
      // add to admin log
      let adminAdds = []
      saveToAdminLog(adminAdds, "addThirdParty", this.state.thirdParty)
      addToAdminLog(adminAdds)
      this.setState({thirdPartyTxt: "Third party user successfully added!"})
      // refresh page
      setTimeout(()=>{window.location.reload()},1000)
    }
  }
  
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    globs.events.publish("savePageEnable",true)
    switch(type){
      case "upd":
      case "input":
        let st=Object.assign({},this.state,vals)
        this.
        checkFields(st)
        this.setState(vals)
        break
      default:
        break
    }
  }

  showThirdParty=()=>{
    let addDisable=false
    return(
      <>
      <div id="third-party-id" style={{height:150,borderStyle:"solid",borderWidth:1,borderRadius:10, width:500, padding:"0px 0px 0px 20px"}}>
        <h2>Third Party ID</h2>

        <input type="text" maxLength="20"
          style={{display:"inline-block"}}

          value={this.state.thirdParty}
          onChange={e=>this.setState({thirdParty:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled" disabled={addDisable}
        style={{display:"inline-block",marginLeft:20}}
        onClick={()=>this.addThirdParty()}>Add</C18Button00>
        <p>{this.state.thirdPartyTxt}</p>
      </div>
      </>
    )
  }
  
  showUserName=(st)=>{
//     cl(st.name)
    return(
      <>
        <label htmlFor="new-user-name">Name</label>
        <input id="new-user-name" type="text" 
          value={st.name} 
          onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}
          />
        <br />
      </>
    )
  }
  
  showUserEmail=(st)=>{
    return(
      <>
      
        <C18InputError00 parms={{
          type:'email',
//           visible:this.state.email,
          title:"Email",
          id:"if-email",
          required: true,
          value: this.state.email,
          valueId: "email",
          onChange: this.onChange,
          message:this.state.emailError,
          messageType: "error"
        }}/>
        <br />
      </>
    )
  }
  
  showUserRole=(st)=>{
    return(
      <>
        <span className="custom-select">
          <label htmlFor="user-role">Role [for future]</label>
          <select id="user-role"
            value={this.state.role}
            onChange={e=>this.onChange("upd",{role:e.currentTarget.value})}
          >
            {this.roles.map((r,i)=>{
              return(
                <option key={i} value={r.v}>{r.t}</option>
              )
            })}
          </select>
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        </span>
        <div className="clearfloat"></div>
      </>
    )
  }
  
  showManageAccess=()=>{
    return(
      <>
        <div className="float-right-box">
          <C18Anchor00 to={`/usa/c18/admin/manageSiteAccess2/${this.userId}`} className="button outlined">Manage Access</C18Anchor00>
        </div>
        <div className="clearfloat"></div>
      </>
    )
  }
  
  showCheckActive=(st)=>{
    return(
      <div className="checkbox-list">
        <span>
          <C18Input00 type="checkbox" aria-label={`Active read`}
            checked={st.active}
            onChange={e=>this.onChange("upd",{active:e.currentTarget.checked})}
          />
          <label>Active</label>
        </span>
      </div>
    )
  }

  showCheckOwner=(st)=>{
//     cl(st)
    return(
      <div className="checkbox-list">
        <span>
          <C18Input00 type="checkbox" aria-label={`Active read`}
            checked={st.owner}
            onChange={e=>this.onChange("upd",{owner:e.currentTarget.checked})}
          />
          <label>Owner</label>
        </span>
      </div>
    )
  }

  
  showAddUserLink=()=>{
    if(this.state.addUserLink){
      let url = constant.feUrl + `/usa/c18/inviteUser/${this.state.addUserLink}`;
      return(
        <p>
        This is the link in the email: 
        <a href={url}>{url}</a>
        </p>
      );
    }
  }
  
  checkFields=async(state)=>{
    var saveOK=true
    if(!this.props.parms.adminInfo){// if a new user
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/o/users/unique", method: "retrieve", 
        body: {companyName:"", email: state.email, password: ""}})
      var msg
//       cl(this.props)
      if(!res.ue){
        msg="That email address is already in the system."
        saveOK=false
      }else{
        msg=""
      }
//       cl(saveOK)
    }
    globs.events.publish("savePageEnable",saveOK)
    
//     globs.events.publish("savePageEnable",true)
    
    this.setState({emailError:msg})
//     if(!res.ue && (this.state.alertId > 5)) return this.setAlert(5);// email used
//     if(this.state.alertId === 12) this.setAlert(14);
  }
   
  render(){
//     cl("render")
//               <button type="button" className="outlined left-margin">Cancel and Return</button>
    let showSuper=privs("super","",constant.AREA_PRIVS_READ)
    if(this.state.loaded){
      let st=this.state
//       cl(st)
//       cl(st)
//         {this.userId&&this.showUserRole(st)}
      return(
        <div>
        {!this.userId&&
          <h3>Enter Name and Email for new User:</h3>
        }
        {this.showUserName(st)}
        {this.showUserEmail(st)}
        {this.userId&&this.showManageAccess(st)}
        {this.userId&&this.showCheckActive(st)}
        {showSuper&&this.userId&&this.showCheckOwner(st)}
        {showSuper&&this.showAddUserLink()}
        <br/><br/>
        {!this.userId&&acctFeature("3rdParty")&&this.showThirdParty()}
        </div>
      );
    } else{
      return <div>loading...</div>
    }
  } 
}
  
export default C18EditUser00 ;
 
