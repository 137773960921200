import React from 'react';
import C18Button00 from './C18Button00'
import C18Input00 from './C18Input00'
import C18Tags01 from './C18Tags01'
import CreatableSelect from 'react-select/creatable'
import config from '../../components/utils/config'
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {cl,constant,globs,getRandomString,getTime} from '../../components/utils/utils';
import {addToAdminLog, loadUsersInfo, getUserIndex, loadDevicesInfo, updateDeviceInfo, rnRest, rnCl,replacePingIdsWithUserIds,
  makePingIds,acctFeature} from './C18utils';
// import exifr from 'exifr' // => exifr/dist/full.umd.cjs


class C18GrowJournalEdit00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let p=props.parms
    this.addDisable = false;
//     cl(p)
    this.images=[]
    let note=(p.edit)?this.procInpImages(p.note):p.note// sets this.images
    this.tagOptions=[
      {value: "equipment", label: "equipment"},
      {value: "sensors", label: "sensors"},
      {value: "plants", label: "plants"},
      {value: "pests", label: "pests"},
      {value: "nutrients", label: "nutrients"},
      {value: "power", label: "power"},
      {value: "personnel", label: "personnel"},
      {value: "supplies", label: "supplies"},
      {value: "maintenance", label: "maintenance"},
      {value: "link4", label: "Link4 Cloud"},
      {value: "other", label: "other"},
    ]
    let [normTags,configTags]=this.getConfigTags(p.tags)
    this.state={
      note:note,
      tags:normTags,//p.tags,
      configTags:configTags,
      images:this.images,
      gotImageCount:this.images.length,
      pingUsers:[],
      pingUserSel:0,
      pingIds:{},
      progressLoaded:0,
      progressTotal:0,
    }
    this.loadInfo()
    this.notifies=[]
    this.pingRef=React.createRef(null)
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
//     cl(this.state)
  }
  
//   makePingId=(first,revPingIds)=>{
//     if(!revPingIds[`@${first}`]){return first}
//     let dig=2
//     while(revPingIds[`@${first}${dig}`]){dig+=1}
//     return `${first}${dig}`
//   }
//   
//   makePingIds=()=>{
//     this.pingIds={}
//     this.revPingIds={}
//     globs.usersInfo.info.forEach(u=>{
// //     Object.keys(this.users).forEach(k=>{
// //       let u=this.users[k]
//       let first=(u.name||"").split(` `)[0]
//       let pingId=`@${this.makePingId(first,this.revPingIds)}`
// //       let pingId=`@${first}#${u.userId.substring(0,4)}`
//       this.pingIds[u.userId]=pingId
//       this.revPingIds[pingId]=u.userId
//     })
// //     cl(this.pingIds)
//   }

  loadInfo=async()=>{
    await loadUsersInfo();
    [this.pingIds,this.revPingIds]=makePingIds()
//     cl(globs.usersInfo.info)
  }
  
  getConfigTags=(tags)=>{// save any tags that *don't* appear in tagOptions
    let standardTags=Object.keys(globs.tagsInfo.info)
//     cl(standardTags)
//     this.tagOptions.forEach(ta=>{standardTags.push(ta.value)})
    let configTags=[]
    let normTags=[]
    tags.forEach(ta=>{
      if(standardTags.includes(ta)){normTags.push(ta)}
      else{configTags.push(ta)}
    })
    return [normTags,configTags]
  }
  
  procInpImages=(note)=>{
//     cl(this.props.parms.images)
// trying{Img:sSr5@GP1eiPSBvBe}{Img:EBVQhzCSJnrdxaeP}
    this.props.parms.images.forEach(im=>{this.images.push({id:im.id,image:im})})
    let parts=note.split("{Img:")
    this.imageMap={}
    if(parts.length>1){
      for(let i=1;i<parts.length;i++){
        let p2=parts[i].split("}")
        let id=`[Img${i}]`
        parts[i]=`${id}${p2[1]}`//[Img1]
        this.imageMap[id]=p2[0]
//         this.images.push(p2[0])
      }
      note=parts.join("")
    }
    return note
    
  }
  
  checkPing=(vals)=>{
    let no=vals.note
    let curs=this.textArea.selectionStart
    let pingStart=no.lastIndexOf("@",curs)
    if(pingStart<0){return}
//     let pingEnd=this.textArea.selectionStart
    if(curs<pingStart){return}
//     cl(vals)
//     cl(this.textArea.selectionStart)
//     cl(no.substring(pingStart+1,curs))
    let findStr=no.substring(pingStart+1,curs).toLowerCase()
    let uList=globs.usersInfo.info.slice(0)
    uList.push({name:"All",userId:"all"})
    uList.push({name:"Link4 Tech Support",userId:"link4"})
//     cl(uList)
    let users=uList.filter(u=>{
      cl(u)
      return (u.name)&&(u.name.replace(/ /g,"_").toLowerCase().indexOf(findStr)>=0)
    })
    this.setState({pingUsers:users})
//     cl(users)
  }
  
//   makePingId=(user)=>{
//     let name=user.name
//     let parts=name.split(" ")
//     let first=parts[0]
//     return `@${first}`
//   }
  
  addPing=(userId)=>{
//     let user=globs.usersInfo.info.filter(u=>{return u.userId==userId})[0]
//     cl(user)
    cl(this.pingIds)
    cl(this.revPingIds)
    let pingId=this.pingIds[userId]//this.makePingId(user)
    
    
    let no=this.state.note
//     cl(this.state.note)
    let curs=this.textArea.selectionStart
//     cl(curs)
    let pingStart=no.lastIndexOf("@",curs)
    let note=no.substring(0,pingStart)+pingId+' '+no.substring(curs)
//     let pingIds=Object.assign({},this.state.pingIds)
//     pingIds[pingId]=user.userId
    this.setState({note:note,pingUsers:[]})//,pingIds:pingIds
    this.textArea.focus()
//     cl(pingIds)
//     cl(pingId)
  }
  
  onKeyPress=(e)=>{
    let st=this.state
    let pu=this.state.pingUsers
    if(pu.length<=0){return}
    if(["ArrowDown","ArrowUp","Enter"].includes(e.key)){e.preventDefault()}
    if(e.key=="Enter"){
      return this.addPing(st.pingUsers[st.pingUserSel].userId)
    }
    let pul=st.pingUserSel+((e.key=="ArrowDown")?1:-1)
    if(pul<0){pul=0}
    let max=pu.length-1
    if(pul>max){pul=max}
    this.setState({pingUserSel:pul})
    if(this.pingRef.current){
      let topLine=Math.floor((this.pingRef.current?.scrollTop||0)/17)
      if(topLine>pul){topLine=pul}
      if(topLine+4<pul){topLine=pul-4}
      this.pingRef.current.scrollTop=17*topLine
    }
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "note":
        this.checkPing(vals)
        this.setState(vals)
        break
      case "chartElements":
        this.setState({tags:this.setTags(vals)})
        break
      case "tags":
//         cl(vals)
        this.setState({tags:vals.tags})
        break
      case "ping":
        this.addPing(vals.userId)
        break
    }
  }
  
  notify=(note)=>{
//     cl(note)
    if(note.unMount){// called when widget is unmounted
      this.notifies[note.id].forEach((n,i)=>{
        if(n==note.func){
          this.notifies[note.id].splice(i,1)
        }
      })
    }else{
      if(note.id in this.notifies){}else{
        this.notifies[note.id]=[]
      }
      this.notifies[note.id].push(note.func)
    }
  }
  
  doNotify=(event,cmd,data)=>{
//     cl("doNOtify")
//     cl(this.notifies)
    if(event in this.notifies){
      this.notifies[event].forEach(f=>{
        f({cmd:cmd, data:data})
      })
    }
  }
  
  testFunc=()=>{
    cl("test")
  }
  
  
/****************** upload file with progress *******************************/

  ufProgress=(progressEvent)=>{
    // cl(progressEvent.loaded,progressEvent.total)
    this.setState({progressLoaded:progressEvent.loaded,progressTotal:progressEvent.total})
  }
  
  uploadFile=(url, method, data, type)=>{
    return new Promise((r,e)=>{
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.upload.addEventListener("progress", this.ufProgress)
      xhr.responseType="json"
      xhr.onload=()=>{
        this.setState({progressLoaded:0,progressTotal:0})
        r(xhr.response)
      }
      xhr.send(data);
    })
  }
  
//   function uploadFile(name){
//   let xhr = new XMLHttpRequest();
//   xhr.open("POST", "php/upload.php");
//   xhr.upload.addEventListener("progress", ({loaded, total}) =>{
//     let fileLoaded = Math.floor((loaded / total) * 100);
//     let fileTotal = Math.floor(total / 1000);
//     let fileSize;
//     (fileTotal < 1024) ? fileSize = fileTotal + " KB" : fileSize = (loaded / (1024*1024)).toFixed(2) + " MB";
//     let progressHTML = `<li class="row">
//                           <i class="fas fa-file-alt"></i>
//                           <div class="content">
//                             <div class="details">
//                               <span class="name">${name} • Uploading</span>
//                               <span class="percent">${fileLoaded}%</span>
//                             </div>
//                             <div class="progress-bar">
//                               <div class="progress" style="width: ${fileLoaded}%"></div>
//                             </div>
//                           </div>
//                         </li>`;
//     uploadedArea.classList.add("onprogress");
//     progressArea.innerHTML = progressHTML;
//     if(loaded == total){
//       progressArea.innerHTML = "";
//       let uploadedHTML = `<li class="row">
//                             <div class="content upload">
//                               <i class="fas fa-file-alt"></i>
//                               <div class="details">
//                                 <span class="name">${name} • Uploaded</span>
//                                 <span class="size">${fileSize}</span>
//                               </div>
//                             </div>
//                             <i class="fas fa-check"></i>
//                           </li>`;
//       uploadedArea.classList.remove("onprogress");
//       uploadedArea.insertAdjacentHTML("afterbegin", uploadedHTML);
//     }
//   });
//   let data = new FormData(form);
//   xhr.send(data);
// }

  
/****************** end upload file with progress *******************************/
  
/****************** Image Handling *******************************/
  sendImages=async()=>{
    cl("send images")
    let st=this.state
    let data = new FormData()
    let base=st.gotImageCount
//     let imgRet=[]
//     for(let i=0;i<base;i++){
//       imgRet.push(
//         st.images[i].image
//       )
//     }
//     cl(imgRet)
//     cl(st.images)
    if(base>=st.images.length){return []}
    cl("sending images")
    for(let i=base;i<st.images.length;i++){
      let img=st.images[i]
      cl(["send",img])
      data.append(`id${i-base}`, img.id)
      data.append(`file${i-base}`, img.image)
      data.append('type',"growJournal")
      cl(JSON.stringify(img))
    }
//     st.images.forEach((img,i)=>{
//       data.append(`id${i}`, img.id)
//       data.append(`file${i}`, img.image)
//     })
    let url=`${constant.expressUrl}/usa/images`
    // let url=`https://http0.c2.link4cloud.com/usa/images`
    let method="POST"
    let type="multipart/form-data"
//     cl(url)
//     cl(data)
//     let res=await doGetPostBasic(url, method, data, type)// pro  mise
    cl(data)
    let res=await this.uploadFile(url, method, data, type)// pro  mise
    cl(res)
//     let ret=imgRet.concat(res)
//     cl(ret)
//     cl("send images do get")
//     cl([url,method,data,type])
//     let res=await doGetPostBasic(url, method, data, type)// pro  mise
//     cl("returned")
    return res//ret
  }

  sendRnImages=async()=>{
    // tell rn to upload files it has saved to state
    let res = await rnRest("/image", "upload", {base: this.state.gotImageCount, url: `${constant.expressUrl}/usa/images`})
    // let res = await rnRest("/image", "upload", {base: this.state.gotImageCount, url: `https://http0.c2.link4cloud.com/usa/images`})
    return res
    
  }
  
  markImage=(e)=>{
    cl("mark image")
    let images=this.state.images.slice(0)
    let len0=images.length
//     cl(e.target)
//     cl(e.target.files)
//     cl(e.target.files.length)
    let imagesStr=""
    for(let i=0;i<e.target.files.length;i++){
//       cl(e.target.files[i])
      let name=e.target.files[i].name
      let ext=name.substring(name.lastIndexOf("."))
//       cl(ext)
//       let f=e.target.files[i]
//       let expImage={id: getRandomString(16), image: e.target.files[i]/*e.target.files[0]*/}// expanded image, w/ our name
      images.push({id: getRandomString(16)+ext, image: e.target.files[i]/*e.target.files[0]*/})
      imagesStr+=`[Img${len0+i+1}]`
    }
//     cl(e.target.files.FileList)
//     e.target.files.FileList.forEach(f=>{
//       let expImage={id: getRandomString(16), image: f/*e.target.files[0]*/}// expanded image, w/ our name
//       images.push(expImage)
//     })
//     for(let i=len0;i<images.length;i++){imagesStr+=`[Img${i+1}]`}
//     cl("mark image set state")
    this.setState({note: this.state.note+imagesStr, images: images})//`{Img:${expImage.id}}`
//     cl("mark image done")
  }
  
/*
Editing images:

{ "_id" : ObjectId("619a853b15c146c498d722c7"), 
"growJournalId" : "PKrXHnsYVsrs7nqf", 
"accountId" : "a036uzDCxohZ7ovD", 
"body" : "trying{Img:sSr5@GP1eiPSBvBe}{Img:EBVQhzCSJnrdxaeP}", 
"dispTime" : 1637516601, 
"images" : [ 
{ "name" : "Screenshot-20210117051200-1010x714.png", "id" : "sSr5@GP1eiPSBvBe", "size" : 75419, "w" : 1536, "h" : 1086 }, 
{ "name" : "Screenshot-20210117051229-992x704.png", "id" : "EBVQhzCSJnrdxaeP", "size" : 76005, "w" : 1536, "h" : 1090 } ], 
"level" : "zone", 
"modTime" : 1637516601, 
"original" : true, 
"siteId" : "d1fIue45CWvP@Nik", 
"subject" : "", 
"tags" : [ ], 
"threadId" : "dV1eDzJ974yDeg60", 
"userId" : "1S1tSCDsWatsjHeD", 
"zoneId" : "Cq5jDVdC03vdecmJ" }
*/
  
/****************** End Image Handling *******************************/

//   saveComment=()=>{
//     let p=this.props.parms
//     cl("save comment")
//     let gjEntry={
// //       threadId: p.threadId||getRandomString(16),
// //       growJournalId:p.growJournalId||getRandomString(16),
// //       level:p.level,
// /*      siteId:(p.level=="account")?null:p.siteId,
//       zoneId: ((p.level=="site")||(p.level=="account"))?null:p.zoneId,//*///this.props.parms.zone.zoneId,
// //       userId: globs.userData.session.userId,
//       body: note,//this.state.note,
// //       subject: "",//this.state.gjSubject,
//       modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
//       dispTime: p.dispTime||da,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
// //       tags:tags,
//       images: images,
// //       original: true,
//     }
//     p.onChange({cmd:"saveComment",gjEntry:gjEntry})
//   }

//   replacePingIdsWithUserIds=(note)=>{
//     let notifications=[]
// //     var note
// //     note=this.state.note
//     let parts=note.split('@')
//     for(let i=1;i<parts.length;i++){
//       let pa=parts[i]
//       let posEnd=pa.indexOf(' ')
//       if(posEnd>0){
//         let pingId=`@`+pa.substring(0,posEnd)
//         let userId=this.revPingIds[pingId]
//         if(userId){notifications.push(userId)}
//         parts[i]=`${userId}${pa.substring(posEnd)}`
//       }
//     }
//     note=parts.join("@")
//     return[note,notifications]
//   }
  
  saveJournalEntry=async()=>{
    this.addDisable = true;
//     cl(this.props)
//     cl(this.state)
    let notes=[]
    var addNote=(userId)=>{
      notes.push({
        date:da,
        subject:"GJ Entry",
        body:this.state.note,
        level:p.level,
        zone:p.zoneId,
        userId:userId,
        from:globs.userData.session.userId,
        site:globs.userData.session.siteId,
        pageType:p.pageType,
        type:"gj",
        zuci:p.zuci,
        flags:constant.CHAT_FLAG_UNREAD,
        notificationId:getRandomString(16),
      })
    }
    let p=this.props.parms
    let da=Math.floor(getTime())//new Date().getTime()/1000
    let [note,notifications]=replacePingIdsWithUserIds(this.state.note,
      this.revPingIds)
    
    var pingLink4Ok="Yes"
    var gotLink4=false
    notifications.forEach(userId=>{
      if(userId=="all"){
        globs.usersInfo.info.forEach(u=>{
          addNote(u.userId)
        })
      }else{
        addNote(userId)
        if(userId=="link4"){gotLink4=true}
      }
    })
    if(gotLink4){
      pingLink4Ok=await this.props.parms.getPopup({text:"When you ping Link4 Tech Support in a Grow Journal Entry, a couple of things happen: <br/>1) An email will be sent to Support@Link4Controls.com, which generates a Support Ticket. <br/>2) The Link4 Tech Support department will be given temporary access to your Account to investigate and resolve your issues. <br/><br/>In Settings / Manage Users, you can see the Link4 Tech Support user, and can delete it, to terminate this access.<br/><br/>Do you want to ping Link4 Tech Support?", buttons:["No","Yes"]})
    }
    if(pingLink4Ok!="Yes"){return}
    
    this.doNotify("useTags")// there may be new tags that need to be saved

    let images=[]
    cl(this.state.images)
    if(this.state.images.length){
      this.state.images.forEach(img=>{
        images.push({name: img.name, id: img.id, size: img.size,
          format: img.image && img.image.format,height: img.image && img.image.height,width:img.image && img.image.width,
        })
      })
//       cl(images)
      let imageInfo
      if (window.ReactNativeWebView) {
        imageInfo = await this.sendRnImages()
      } else {
        imageInfo=await this.sendImages()
      }
//       cl(r)
//       let imageInfo=await r.json()// this is just the added image
      cl(imageInfo)
      let base=this.state.gotImageCount
//       cl(this.state.images)
      for(let i=base;i<this.state.images.length;i++){
        images[i].format=imageInfo[i-base].format
        images[i].width=imageInfo[i-base].width
        images[i].height=imageInfo[i-base].height
      }
// [{"format":"png","width":966,"height":654,"channels":4,"premultiplied":false,"size":38802}]
    }
    cl(images)
    let tags=this.state.tags.slice(0)
//     cl(tags)
//     cl(p)
//     cl(p.myTag)
//     cl(!tags.includes(p.myTag))
    if((!p.edit)&&p.myTag&&(!tags.includes(p.myTag))){// fui only, create only
      tags.push(p.myTag)
    }
    if(p.edit){tags=tags.concat(this.state.configTags)}
    
    
    let i=1
    var pos
    do{// replace '[Img with actual Id'
      let search=`[Img${i}]`
      pos=note.indexOf(search)
      if(pos>=0){
        note=note.substr(0,pos)+`{Img:${images[i++-1].id}}`+note.substr(pos+search.length)
      }
    }while(pos>=0)
    
    let gjEntry={
      threadId: p.threadId||getRandomString(16),
      growJournalId:p.growJournalId||getRandomString(16),
      level:p.level,
      siteId:(p.level=="account")?null:p.siteId,
      zoneId: ((p.level=="site")||(p.level=="account"))?null:p.zoneId,//this.props.parms.zone.zoneId,
      userId: globs.userData.session.userId,
      body: note,//this.state.note,
      subject: "",//this.state.gjSubject,
      modTime: da,//this.state.modTime,//Math.floor(this.state.dispTime.getTime()/1000),
      dispTime: p.dispTime||da,//this.state.dispTime,//Math.floor(this.state.modTime.getTime()/1000),
      tags:tags,
      images: images,
      original: true,
    }
//     cl(gjEntry)
    if(p.comment){
      return p.onChange({cmd:"saveComment",gjEntry:gjEntry})
    }
    p.onChange({cmd:"addGJEntry",gjEntry:gjEntry})
//     cl(gjEntry)
//     this.setState({images:[],tags:[],note:""})// setState should be used to force a page update. Why is this here?
//     cl(gjEntry)
    let r2=await wsTrans("usa", {cmd: "cRest", uri: "/s/growJournal", method: (p.edit)?"update":"create", 
      sessionId: globs.userData.session.sessionId,
      body: gjEntry})
//     cl(gjEntry)
//     cl(gjEntry.siteId)
//     cl(p)
//     cl(this.state)
//     cl(notes)
    await wsTrans("usa", {cmd: "cRest", uri: "/s/notifications", method:"create", 
      sessionId: globs.userData.session.sessionId,body:{notes:notes}
    })
    this.setState({note:"",tags:[], images:[]})
    this.doNotify("setTags",[])// there may be new tags that need to be saved
    this.addDisable = false;
  }
  
  setTags=(vals)=>{
    let tags=[]
    vals.forEach(v=>{
      tags.push(v.value)
    })
    return tags
  }
  
  getTagOption=(value)=>{
    for(let i=0;i<this.tagOptions.length;i++){
      let o=this.tagOptions[i]
//       cl([o.value,value])
      if(o.value==value)return o
    }
  }
  
  makeChartElements=()=>{
    let elements=[]
//     cl(this.state.tags)
    this.state.tags.forEach(t=>{
      elements.push(this.getTagOption(t))
    })
//     cl(elements)
//     cl(this.tagOptions)
    return elements
  }

  getRnImage = async() => {
    let ret = await rnRest("/image", "retrieve", {})
    let rnImages = ret.images
    if (rnImages && rnImages.length) {
      let images=this.state.images.slice(0)
      let len0=images.length
      let imagesStr=""
      for(let i=0;i<rnImages.length;i++){
        let image = rnImages[i]
        // images.push({name: img.image.name, id: img.id, size: img.image.size})
        images.push({id: image.id, name: image.name, size: image.size})
        imagesStr+=`[Img${len0+i+1}]`
      }
      rnCl({note: this.state.note+imagesStr, images: images})
      this.setState({note: this.state.note+imagesStr, images: images})//`{Img:${expImage.id}}`
    }
  }

  getRnImage2=async()=>{
    // get list of images to be uploaded later by react app
    let ret = await rnRest("/image", "retrieve", {})
    let rnImages = ret.images
    if (rnImages && rnImages.length) {
      let images=this.state.images.slice(0)
      let len0=images.length
      let imagesStr=""
      for(let i=0;i<rnImages.length;i++){
        cl(rnImages[i])
        // let name= rnImages[i].path
        // let ext= name.substring(name.lastIndexOf("."))
        // let id = getRandomString(16)+ext
        // let or = await exifr.orientation(rnImages[i].data)
        // let rot = await exifr.rotation(rnImages[i].data)
        // rnCl({text: "one", orientation: or, rotation: rot})
        // get base64 data from phone now
        // let pos = 0
        // let upl = await rnRest("/image", "upload", {id: rnImages[i].id, pos: pos})
        // let data = upl.data
        // while (upl.next) {
        //   pos++
        //   upl = await rnRest("/image", "upload", {id: rnImages[i].id, pos: pos})
        //   data += upl.data
        // }
        // rnCl({id: upl.id, data: data.substring(0, 100)})
        let ret2 = await fetch(rnImages[i].data)
        let blob = await ret2.blob()
        // or = await exifr.orientation(blob)
        // rot = await exifr.rotation(blob)
        // rnCl({text: "two", orientation: or, rotation: rot})
        let file = new File([blob], rnImages[i].id, {type:rnImages[i].mime, lastModified:new Date().getTime()})
        // or = await exifr.orientation(file)
        // rot = await exifr.rotation(file)
        // rnCl({text: "three", orientation: or, rotation: rot, file: file})
        rnCl({id: rnImages[i].id, image: file})
        images.push({id: rnImages[i].id, image: file})
        imagesStr+=`[Img${len0+i+1}]`
      }
      this.setState({note: this.state.note+imagesStr, images: images})//`{Img:${expImage.id}}`
    }
  }
  
  showPingUsers=()=>{
//     cl("show ping")
    let st=this.state
    let pu=this.state.pingUsers
    let cnt=pu.length
    if(cnt>0){
      let lines=[]
//       if(cnt>5){cnt=5}
      for(let i=0;i<cnt;i++){
        var bgColor=(this.dark)?"#202020":"#FFFFFF"//,pingRef
//         cl(bgColor)
        if(i==st.pingUserSel){
          bgColor=(this.dark)?"#505050":"#CCCCCC"
//           pingRef=this.pingRef
//           ref={pingRef}
        }
//         let bgColor=(i==st.pingUserSel)?"#CCCCCC":"#FFFFFF"
        lines.push(
          <span key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("ping",{userId:pu[i].userId})}
          >{pu[i].name}<br/></span>
        )
      }
      return(
        <div style={{width:200,height:100,backgroundColor:"#FFFFFF",overflowY:"auto",
          }}
        ref={this.pingRef}
        >
          {lines}
        </div>
      )
    }else{return null}
  }
  
  showUploadProgress=()=>{
    // cl("show upload progress")
    let st=this.state
    // cl([st.progressLoaded,st.progressTotal])
    if(st.progressLoaded<st.progressTotal){
      let wid=260*(st.progressLoaded/st.progressTotal)
      // cl(wid)
      return(
        <div>
          <div className="clearfloat"></div><br/>
          <div style={{borderStyle:"solid",borderWidth:1,borderRadius:10,
            padding:"10px 20px 20px 20px", width:300
          }}>
            Uploading:<br/>
            <div style={{height:10}}/>
            <div style={{width:260,height:12,borderStyle:"solid",borderWidth:1}}>
              <div className="progress-bar" style={{width:wid,height:10}}></div>
            </div>
          </div>
        </div>
      )
    }else{
      return null
    }
  }
  
  render(){
//     cl(this.state)
//     cl("render edit")
    let p=this.props.parms
    let ph=(p.comment)?"Enter comment here":""
//     cl(acctFeature("gjPing"))
//           {(["dev","stage","ryan"].includes(config.server))&&this.showPingUsers()}
//             className="floatleft grow-journal-select-tags" 
//               <CreatableSelect
//                 id="/sidebar/growJournal/header/tags" 
//                 value={this.makeChartElements()}
//                 onChange={o=>{this.onChange("chartElements",o)}}
//                 options={this.tagOptions}
//                 isMulti={true}
//               />        
    return(
      <div style={{display:(p.display)?"block":"none"}} className="chart-elements-container">
      {p.comment?
          <><div className="clearfloat"></div><br/></>
        :
          <label htmlFor="">{(p.edit)?"Edit":"Add"} Note</label>
      }
        <textarea ref={e=>this.textArea=e} placeholder={ph}
          id="/sidebar/growJournal/header/note"
          className="fullwidth"
          value={this.state.note}
          onChange={e=>this.onChange("note",{note:e.currentTarget.value})}
          onKeyDown={e=>this.onKeyPress(e)}
          ></textarea>
          {acctFeature("gjPing")&&this.showPingUsers()}
        <br />
        {!p.comment&&
          <>
            <label htmlFor="">Add Tags</label>
            <div className="floatleft"  style={{width:250}}>
              <C18Tags01 
                notify={this.notify}
                value={this.state.tags}
                parms={{
                  onChange:e=>this.onChange("tags",{tags:e}),
                }}
              />
            </div>
          </>
        }

        {(window.ReactNativeWebView) 
          ?
        <div className="floatright">
          <C18Button00 onClick={e=>this.getRnImage()} type="button" className="floatleft material-icons-outlined" aria-label="upload" style={{
            width:40, 
            height:40, 
          }}>attach_file
          </C18Button00>
         <C18Button00 id="/sidebar/growJournal/header/send" type="button" className="only-icon floatleft filled material-icons-outlined" aria-label="create"
        onClick={e=>this.saveJournalEntry()}>
        send
        </C18Button00>
        </div>
          :
        <div className="floatright">
          <button type="button" className="top-margin-smaller floatleft material-icons-outlined" aria-label="upload">
          </button >
          <C18Button00 type="button" className="floatleft material-icons-outlined" aria-label="upload" style={{
            width:40, 
            height:40, 
          }}>attach_file
          </C18Button00>
          <form className="file-upload-form">
          <C18Input00 id="/sidebar/growJournal/header/attach" type="file" multiple onChange={this.markImage} 
          style={{
            position:"absolute", 
            width:40, 
            height:40, 
            marginTop:0, 
            marginLeft:-41,
            zIndex:10,                   
            opacity:0,
            cursor: "pointer",
          }}/>
          </form>
        <C18Button00 id="/sidebar/growJournal/header/send" type="button" className="only-icon floatleft filled material-icons-outlined" aria-label="create"
          disabled={this.addDisable}
          onClick={e=>this.saveJournalEntry()}>
          send
          </C18Button00>
        </div>
        }
        {this.showUploadProgress()}
      </div>
    )
//     return React.createElement("input",this.props)
  }
}
      
export default C18GrowJournalEdit00;
