import React from 'react';
import * as d3 from 'd3';
import {cl, globs,dateToDisplayDate,constant} from '../../components/utils/utils';
import {sd} from './sampleData'

class Graph01 extends React.Component{
/* The ZCI information can come from the url, or be overridden by the dashboard or the widget*/
  constructor(props) {
    super(props);
//     cl(props)
    this.svgDiv=React.createRef();
    
    this.sensors=this.props.graph.sensors.slice(0)
//     cl(this.sensors)
    this.sensors.forEach(s=>{
      s.mode="default"
      if(s.options){
        s.color=s.options.color
        s.min=s.options.min 
        s.max=s.options.max
        s.mode=s.options.mode
        s.unit=s.options.unit
      }
    })
    this.events=this.props.graph.events.slice(0)
    // cl(this.sensors)
    this.events.forEach(s=>{
      s.mode="default"
      if(s.options){
        s.color=s.options.color
        s.min=s.options.min 
        s.max=s.options.max
        s.mode=s.options.mode
        s.unit=s.options.unit
      }
    })
    // add axis field
    this.events.push({
        id: "default",
        min:0, 
        max:15, 
        yShow:true,
    })
    // cl(this.events)
    this.state={
//       sensors: props.graph.
    }
//     setInterval(()=>{
//       cl(this.props.graph.ref)
//     }, 1000)
    let gr=this.props.graph
//     cl(gr)
    this.graphName=gr.divId
//     cl(this.props.graph.divId)
//     cl("init get history")
    this.getHistory(gr.from,gr.to)
    this.initVars()
  }
  
  initVars=()=>{
    this.tempStages=["H6","H5","H4","H3","H2","H1","N",
    "C1","C2","C3","C4","C5","C6"]
    this.humStages=["H1","N","D1","D2"]
  }
  
//   onSelectChange=(a,b)=>{
//     cl([a,b])
//   }
  
  getHistory=async(min,max)=>{
//       cl("getting history")
//     console.trace()
//     cl(min,max)
//     cl(Math.floor(max-min))
    let graphName=(this.props.graph.showSelect)?"guide":"main"
//     cl(`get history: ${graphName}, ${min}, ${max}`)
//     let gr=this.props.graph
//     cl(this.props.graph.showSelect)
// this was getting an extra day before and after: min-24*3600, to: max+24*3600
    let cmd={from: min, to: max, type: "arr", guideGraph: this.props.graph.showSelect, mainGraph:!this.props.graph.showSelect,
    eventTimeline: this.props.graph.divId == "event-timeline"}// get an array of sensors
    let sensors=[]
//     this.props.graph.sensors
    if (this.props.graph.divId != "event-timeline") {
        this.sensors.forEach(s=>{
    //       cl(s)
          sensors.push({s:s.s, z:s.z, c:s.c, i:s.i,type:s.type,
            unit:s.unit,mult:s.mult})
        })
    }
    cmd.sensors=sensors
    let events=[]
    this.events.forEach(s=>{
//       cl(s)
      if (s.id != "default") events.push({s:s.s, z:s.z, id:s.id})
    })
    cmd.events=events
//     cl(cmd.events)
//     cl(this.props.graph.divId)
//     cl(cmd)
//     cl("call")
    // cl(this.props.graph.divId)
    // cl(cmd)
    this.history=await this.props.graph.getHistory(cmd)
//     cl("got history")
//     cl(this.history)
//     cl(`done get history: ${graphName}, ${min}, ${max}`)
//     cl(this.history.sensors)
//     cl(this.sensors)
//     cl(this.history)
//     cl("done")
//     cl(this.props.graph.showSelect)
//     cl(this.history)
//     cl(this.history)
    let th=this.history
    cl(this.history)
//     cl(`got domain ${th.from}, ${th.to}`)
    this.graph.allData=this.history||{}
//     cl(this.props.graph.divId)
    // cl(this.graph.allData)
/*allData:
from and to times, and sensors object, with sensorId(site-z-c-i) keys: {t:time, d:data}*/
//     cl("checking new data")
    this.graph.checkNewData(min,max)
  }
  
  shouldComponentUpdate=()=>{
//     cl("update start")
    this.updating=true
    return true
  }
  
  componentDidUpdate=()=>{
//     cl("update done")
    this.updating=false
  }
  
  componentDidMount=()=>{
//     cl(`Did Mount ${this.props.graph.divId}`)
//     cl(this.props)
    let ref=this.svgDiv.current
//     cl(ref.clientHeight)
    let gr=this.props.graph
//     cl(gr)
//     cl(sd)// an array ov {t: v:} objects: time, value
//     cl(gr)
    let info={
      svg:ref,//this.props.graph.ref,//.current,//
//       data: sd.sensors["0sna8jVYIh0xw6oF-0-240-23"],
      allData: sd,//.sensors["0sna8jVYIh0xw6oF-0-240-23"],
      width: ref.clientWidth,
      height: ref.clientHeight,
      options:gr.options,
      from: this.props.graph.from,// graph.from and .to are the actual limits for each graph: guide, and main
      to: this.props.graph.to,
      showSelect: gr.showSelect,// true if guide graph
      showGrid: gr.showGrid,
      showDM: gr.showDM,
      sFrom: gr.sFrom,
      sTo: gr.sTo,
    }
    this.graph = this.graph01(info)
    this.props.notify({id: "newSensors", func: this.graph.onNewSensors})
    this.props.notify({id: "newEvents", func: this.graph.onNewEvents})
    this.props.notify({id: "newTimePeriod", func: this.graph.onNewTimePeriod})
    if(gr.showSelect){
      this.props.notify({id: "selectZoom", func: this.graph.onSelectZoom})
    }else{
      this.props.notify({id: "selectChange", func: this.graph.onSelectChange})
    }

  }
  
graph01=(info)=>{
  
  var o={
    config:{},
    maxZoom: 3600,
//     data: info.data,
    allData: info.allData,
    
    setYScale:(s)=>{
      s.y = d3.scaleLinear()
        .domain([s.min, s.max]) // input 
        .range([o.config.height, 0]); // output 
    },
    
    adjustSensorMinMax:(id,min,max,mode)=>{
//       cl(min,max)
      if(min==max){
        min-=0.1
        max+=0.1
      }
//       cl(mode)
      if(mode=="default"){
        let avg=(min+max)/2
        let range=max-min
  //       cl(avg,range)
        min=avg-0.55*range
        max=min+1.1*range
        let minRange={inT:20,inH:10}
        let mr=minRange[id]
        if(mr){
  //         cl(mr)
          if(max-min<mr){
            min=(min+max)/2-mr/2
            max=min+mr
          }
        }
      }
      return [min,max]
    },
    
    makeFirstSensorPoint:(p1,p2,min)=>{
      let frac=(min-p1.t)/(p2.t-p1.t)
      if(frac<0){return p1}
      let d=p1.d+frac*(p2.d-p1.d)
      return {t:min,d:d}
//       return p1
      
    },

    makeFirstEventPoint:(p1,p2,min)=>{
      let frac=(min-p1.dispTime)/(p2.dispTime-p1.dispTime)
      if(frac<0){return p1}
      let d=p1.d+frac*(p2.d-p1.d)
      return {t:min,d:d}
//       return p1
      
    },
    
    setSensorsData:(min,max)=>{
      cl("set sensor")
      cl(this.sensors)
//       cl(this.props.graph.showSelect)
      if(!o.allData.sensors || this.props.graph.divId == "event-timeline"){/*cl("no data");*/ return}
//       cl(o.allData)
//       cl(`set sensor data: ${min}, ${max}`)
//       cl(o.allData)
//       cl(`${this.props.graph.divId}`)
//       cl(this.sensors)// comes with s, z, c, i, name
      this.sensors.forEach(s=>{// when new data is put in, need to rescale Y axis
        let z=(s.type=="mbPoint")?s.z+64:s.z
        let key=`${s.s}-${z}-${s.c}-${s.i}`
        cl(s)
        s.data=[]
//         cl(s)
//         let yMin=1e6, yMax=-1e6
//         cl(key)
        cl(o.allData.sensors)
        if(key in o.allData.sensors){
          cl(key)
          let ads=o.allData.sensors[key]
          var inData=false
//           cl(ads.length)
//           var min0,max0
          var ld=ads[0]
//           cl([min,max])
          for(let i=0;i<ads.length;i++){
            let d=ads[i]
//             if(d.t<min){min0=d.d}
//             if((d.t>max)&&(!max0)){max0=d.d}
            if((ld.t<min)&&(d.t>min)){
//               cl(`before: ${i}`)
              let d2=o.makeFirstSensorPoint(ads[i-1],ads[i],min)
              s.data.push( {t:new Date(1000*d2.t), v:d2.d} )  // add the point *before* min        
//               cl(d2.t)
            }
            
            if((d.t>=min)&&(d.t<max)){
//               if(yMin>d.d){yMin=d.d}
//               if(yMax<d.d){yMax=d.d}
//               if(!inData&&(i>0)){
//                 let d2=o.makeFirstSensorPoint(ads[i-1],ads[i],min)
//                 s.data.push( {t:new Date(1000*d2.t), v:d2.d} )  // add the point *before* min        
//                 inData=true
//               }
              s.data.push( {t:new Date(1000*d.t), v:d.d, gj:d.gj} ) 
            }else{
//               if(inData&&((i+1)<ads.length)){
//                 s.data.push( {t:new Date(1000*ads[i+1].t), v:ads[i+1].d} )// add the point *after* max
//                 inData=false
//               }
            }
            if((ld.t<max)&&(d.t>max)){
//               cl(`after: ${i}`)
              let d2=o.makeFirstSensorPoint(ads[i-1],ads[i],max)
              s.data.push( {t:new Date(1000*d2.t), v:d2.d} )  // add the point *before* min        
//               cl(d2.t)
            }
//             cl(ld)
            ld=d
          }
          let yMin=1e6, yMax=-1e6
          s.data.forEach(d=>{
//             cl(d)
            if(yMin>d.v){yMin=d.v}
            if(yMax<d.v){yMax=d.v}
          })
        
//           cl(s.data)
//           cl([yMin,yMax])
          if(s.mode=="manual"){
            yMin=+s.options.min 
            yMax=+s.options.max
          }
//           cl([yMin,yMax])
//           o.allData.sensors[key].forEach(d=>{// this needs to be extended for the main graph to get the points *before* and *after*
//             if((d.t>=min)&&(d.t<max)){
//               if(yMin>d.d){yMin=d.d}
//               if(yMax<d.d){yMax=d.d}
//               s.data.push( {t:new Date(1000*d.t), v:d.d} )          
//             }
//           })
//           cl(yMin,yMax)

          let res=o.adjustSensorMinMax(s.id,yMin,yMax,s.mode)
//           cl(res)
//           cl(s)
//           cl(yMin,yMax)
          s.min=res[0]
          s.max=res[1]
          o.setYScale(s)
        }
//         cl([yMin,yMax])
//         cl(s.data)
      })
      o.minTime=min 
      o.maxTime=max
//       cl(this.sensors[0].min)
      
    },

    setEventsData:(min,max)=>{
//       cl("set events data")
//       cl([min, max])
//       cl(this.props.graph.showSelect)
//       if(!o.allData.events){cl("no data"); return}
//       cl(o.allData)
//       cl(`set sensor data: ${min}, ${max}`)
//       cl(o.allData)
      // cl(`${this.props.graph.divId}`)
//       cl(this.sensors)// comes with s, z, c, i, name
      this.events.forEach(s=>{// when new data is put in, need to rescale Y axis
        if (s.id != "default") {
        // cl(s)
        s.data=[]
        if(o.allData.events && o.allData.events[s.id]){
//           cl("setting event data for " + s.id)
          // cl(s.id)
          let ads=o.allData.events[s.id]
          var inData=false
          var ld=ads[0]
          for(let i=0;i<ads.length;i++){
            let d=ads[i]
            // if earliest data point is less than min and curr data point is more than min
            // if((ld.dispTime<min)&&(d.dispTime>min)){
            //   let d2=o.makeFirstEventPoint(ads[i-1],ads[i],min)
            //   s.data.push( {t:new Date(1000*d2.dispTime), data: d} )  // add the point *before* min        
            // }
            // if curr display time is within min and max
            if((d.dispTime>=min)&&(d.dispTime<max)){
              s.data.push( {t:new Date(1000*d.dispTime), data: d} ) 
            }
            // if earliest data point is less than max and curr data point is more than max
            // if((ld.dispTime<max)&&(d.dispTime>max)){
            //   let d2=o.makeFirstEventPoint(ads[i-1],ads[i],max)
            //   s.data.push( {t:new Date(1000*d2.dispTime), data: d} )  // add the point *before* min        
            // }
            ld=d
          }
          s.min=0
          s.max=15
          o.setYScale(s)
        }
        }
      })
      // pass data to c18graphing
      if (this.props.graph.divId == "guide-graph") this.props.graph.setEvents(this.events)
      // if (this.props.graph.divId != "main-graph") this.props.graph.setEvents(this.events)
//       cl(this.events)
      o.minTime=min 
      o.maxTime=max
    },
    
    resetZoom:()=>{
        o.zr=true
        o.zoom.transform(o.svg2,d3.zoomIdentity)
        o.zr=false
    },
    
    calcFromTo:(tr)=>{
//       cl(this.props.graph.showSelect)
      let d0=o.maxTime-o.minTime
      let d1=d0/tr.k
      let k0=(d0)/o.w
      let k1=k0/tr.k
      let ofs=k1*tr.x
      let minTime=o.minTime-ofs
      let maxTime=minTime+Math.floor(d1)
      return [minTime,maxTime]
    },
    
    calcTr:(fromVal, toVal)=>{
//       cl(o.minTime,fromVal)
      let tr={}
      let d0=o.maxTime-o.minTime
      let d1=toVal-fromVal
      let k0=d0/o.w
      let k1=d1/o.w
      let ofs=o.minTime-fromVal
//       cl(ofs)
      tr.x=ofs/k1
      tr.k=k0/k1
      tr.y=1
      return tr
    },

    checkNewData:(minTime,maxTime)=>{
//         cl(this.props.graph.divId)
//       console.trace()
//       cl("check new data")
//       cl(this.props.graph.isMain)
//       if(this.props.graph.isMain){
//         cl("check new data")
//         cl(this.sensors)
//       }
//       cl(`check new: ${minTime}, ${maxTime}, ${this.props.graph.showSelect}`)
/*how to convert between pixels and seconds:
tr.k is the new scale, <1 
The total time is oldTime/x
the old minTime is at tr.x
the x value is *after* the new scale
the old time scale is (max-min)/w in seconds / pixel
// */
//       cl(tr)

//       let [minTime,maxTime]=o.calcFromTo(tr)
//       cl(`domain: ${minTime}, ${maxTime}`)
//       cl(o.allData)
      if(o.svgx){o.svgx.remove()}
      if(o.svgxg){o.svgxg.remove()}
      o.addXAxis(o.svg2,minTime,maxTime)
//       cl(minTime,maxTime)
//       let yCnt=this.sensors.length
      let yCnt=0
//       cl(this.props.graph.isMain)
      // let isMain=this.props.graph.isMain
      let isGuide=this.props.graph.divId == "guide-graph"
      let isEvent=this.props.graph.divId == "event-timeline"
      this.sensors.forEach(s=>{if(s.yShow&&!isGuide){// remove y axes
        if(s.axis){s.axis.remove()}
        if(s.label){s.label.remove()}
        if(s.circ){s.circ.remove()}
//         if(s.dmPath){s.dmPath.remove()}
        yCnt+=1
      }})
      // cl(this.events)
      this.events.forEach(s=>{if(s.yShow&&isEvent){// remove y axes
        if(s.axis){s.axis.remove()}
        if(s.dmPath){s.dmPath.remove()}
      }})
      // cl(o.config.yAxisWidth*yCnt)
      o.x=d3.scaleLinear()// set x scale
        .domain([1000*minTime,1000*maxTime])
        .range([0+o.config.yAxisWidth*(yCnt),o.config.width])
//       o.setData(minTime,maxTime)
      o.setSensorsData(minTime,maxTime)
      // cl(this.events)
      o.setEventsData(minTime,maxTime)
      // cl(this.events)
//       cl("add y axes")
//       cl(this.sensors)

      if(!isGuide){o.addYAxes()}
//       cl("set dispfrom")
      o.dispFrom=minTime
      o.dispTo=maxTime
//       this.sensors.forEach(s=>{if(s.path){s.path.remove()}})
//       cl(this.sensors)
      if (!isEvent) {
          o.addPaths()
//       cl("add shades")
          o.addShades()
    //       o.dotsParent.remove()
    //       cl("add dms")
    //       cl(`Add DMs ${this.graphName}`)
      }
      // cl(isEvent)
      o.addDMs()
      o.setBox()
      o.resetZoom()
      o.tndTime=null
//       cl(this.sensors)
//       cl(`check new Data done ${this.props.graph.divId}`)
    },
    
    showDate:(ts)=>{
      return new Date(1000*ts)
    },
    
    countDataPoints:(ad,min,max)=>{
      let count=0
      Object.values(ad.sensors)[0].forEach(se=>{if((se.t>=min)&&(se.t<max)){count+=1}})
      return count
    },
    
    
    getRealData:async(minTime,maxTime)=>{// after a zoom, use getHistory to get the currently needed data
      // cl("getRealData")
//       let [minTime,maxTime]=o.calcFromTo(tr)
//       cl(o.allData)
//       cl(minTime,maxTime)
//       cl(this.props.graph.divId)
      
      let ad=o.allData// this is actually all the data that we have, that is used for the guide graph
//       cl(ad)
//       cl([o.showDate(minTime),o.showDate(maxTime)])
//       cl([o.showDate(ad.from),o.showDate(ad.to)])
      let count=200
      if(this.props.graph.divId=="guide-graph"){
        count=o.countDataPoints(ad,minTime,maxTime)
//         cl(count)
        
      }
      if((ad.from<minTime)&&(ad.to>maxTime)&&(count>=100)){//&&(count>=100)
//         cl("got it")
        o.checkNewData(minTime,maxTime)
//         cl(minTime,maxTime)
      }else{
//         cl("get history")
        await this.getHistory(minTime,maxTime)
//         o.checkNewData(minTime,maxTime)
//         cl("need new")
      }
    },
    
    throttleNewData:(tr)=>{
      if(!o.zr){
//         cl("throttleNewData2")
//         o.getRealData(tr)
        let [minTime,maxTime]=o.calcFromTo(tr)
        if(o.tndTime){ clearTimeout(o.tndTime) }
        o.tndTime=setTimeout(()=>{o.getRealData(minTime,maxTime,this.sensors)},100)// checkNewData
      }
    },
    
    setConfig:()=>{
//       cl("set config")
//       cl(info)
      o.config.that=this
      o.config.tzo=60*(new Date()).getTimezoneOffset()
      o.config.yAxisWidth=60
      o.config.opacity=0.7
      o.config.margin = {top: 0, right: 0, bottom: 20, left: 0}
      o.config.width = info.width-o.config.margin.left-o.config.margin.right//window.innerWidth - margin.left - margin.right // Use the window's width 
      o.config.height = info.height-o.config.margin.top-o.config.margin.bottom//window.innerHeight - margin.top - margin.bottom; // Use the window's  height
      o.config.dark=globs.device?.deviceTheme=="originalDark"
//       cl(globs.device)
      o.minTime=info.from//1624622760
      o.maxTime=info.to//o.minTime+10000
      o.dispFrom=info.from
      o.dispTo=info.to
      o.w=o.config.width
//       o.setData(o.minTime, o.maxTime)
      o.setSensorsData(o.minTime, o.maxTime)
      o.setEventsData(o.minTime, o.maxTime)
      o.x = d3.scaleLinear()
        .domain([1000*o.minTime,1000*o.maxTime])
//         .domain(d3.extent(o.config.dataset2, function(d) { return d.t; })) 
        .range([0, o.config.width]); // output
      o.y = d3.scaleLinear()
        .domain([info.yMin, info.yMax]) // input 
        .range([o.config.height, 0]); // output 
//       o.xAxis_woy = d3.axisBottom(o.x).ticks(3).tickFormat(d3.timeFormat("%y-%b-%d")).tickValues(o.config.dataset2.map(d=>d.t));
      if(this.sensors.length && this.props.graph.divId != "event-timeline"){
//           cl(this.sensors[0])
        o.xAxis_woy = d3.axisBottom(o.x).ticks(3).tickFormat(d3.timeFormat("%y-%b-%d")).tickValues(this.sensors[0].data.map(d=>d.t));
      }
      if(this.events.length > 1 && this.events[0].data && this.props.graph.divId == "event-timeline"){
          // cl(this.events[0])
          // map event data to screen
        o.xAxis_woy = d3.axisBottom(o.x).ticks(3).tickFormat(d3.timeFormat("%y-%b-%d")).tickValues(this.events[0].data.map(d=>d.t));
      }
//       cl(this.sensors[0].data)
//       cl(o)
    },

//     makeLine:()=>{
//   //     cl(this.config)
//       return d3.line()
//         .x((d, i)=> { return o.x(d.t); }) // set the x values for the line generator
//         .y((d)=> { return o.y(d.v); }) // set the y values for the line generator 
//         .curve(d3.curveMonotoneX) // apply smoothing to the line
//     },

    addDots:(svg)=>{
      o.dots=svg.selectAll(".dot")
        .data(this.sensors[0].data)
        .enter().append("circle") // Uses the enter().append() method
        .attr("class", "dot") // Assign a class for styling
        .attr("cx", (d, i)=>{ return o.x(d.t) })
        .attr("cy", (d)=>{ return o.y(d.v) })
        .attr("r", 5)
    },
    
    makeXTickVals:(min,max)=>{
      let range=max-min 
      let steps=[30,60,120,300,600,1200,1800,3600,7200,14400,28800,43200,86400,172800,345600,691200,1382400]
      var i
      for(i=0;i<steps.length;i++){
        if(range/10<steps[i]){
          break
        }
      }
      let step=steps[i]
//       cl(o.config.tzo)
      min-=o.config.tzo
      let t0=min-min%step
      t0+=o.config.tzo
      let ticks=[]
      for(let i=0;(t0+step*i)<max;i++){
        if((t0-o.config.tzo+step*i)>=min){ticks.push(new Date(1000*(t0+step*i)))}
      }
//       cl(ticks)
      return ticks
    },
    
    stageTicks:(min,max,id)=>{
      let ticks=[]
      let showTicks={}
      if(id=="stT"){
        for(let i=Math.floor(min);i<Math.ceil(max);i++){
          ticks.push(i)
          showTicks[i]=this.tempStages[i]
        }
      }
      if(id=="stH"){
        for(let i=Math.floor(min);i<Math.ceil(max);i++){
          ticks.push(i)
          showTicks[i]=this.humStages[i]
        }
      }
      return {t:ticks,s:showTicks}
    },
    
    makeTickVals:(min,max,id)=>{
      if(["stT","stH"].includes(id)){return o.stageTicks(min,max,id)}
      let logDif=Math.log(max-min)/Math.log(10)
      let iPart=Math.floor(logDif)
      let fPart=logDif-iPart
      let intv=(fPart<.505) ? ((fPart<.146) ? 1:2) : ((fPart<.851) ? 5:10)
      intv*=10**(iPart-1)
      let dec=(iPart<2)?2-iPart:0
      let ticks=[]
      let showTicks={}
      for(let i=min-(min%intv);i<max;i+=intv){
        if((i>=min)&&(i<=max)){
          ticks.push(i)
          showTicks[i]=(i<1000)?i.toFixed(dec):`${(i/1000).toFixed(1)}k`
        }
      }
      return {t:ticks,s:showTicks}
    },
    
    makeYAxesPos:()=>{
      let len=this.sensors.length
      let j=0
      let pos=[]
      let gotGrid=false
      for(let i=0;i<len;i++){
        if(this.sensors[i]?.options?.showGrid&&!gotGrid){
          pos.push(60*(len))
          gotGrid=true
        }else{
          pos.push(60*(j+1))
          j=j+1
        }
      }
      return pos
//       let gotGrid=this.sensors.filter(s=>{return s.options.showGrid})
//       if(gotGrid.length){
//         cl("got grid")
//         
//       }

//       let pos=[]
//       for(let i=0;i<this.sensors.length;i++){
//         pos.push(60*(i+1))
//       }
//       return pos
    },
    
//     onChange:(type,vals)=>{
//       cl(type,vals)
//       this.props.graph.onChange(Object.assign(vals,{type:type}))
//     },
    
    addYAxes:()=>{// this needs to handle the positioning of the axes
/* the height of the test is 500 px total
 */   
//      cl("adding y axes")
      let fontSize=15
//       cl(info)
      let yPos=o.makeYAxesPos()
//       cl(yPos)
      let gotGrid=0
      if (this.props.graph.divId == "main-graph") {
          this.sensors.forEach((s,sIndex)=>{
            let tickWidth=((s.options||{}).showGrid&&(!gotGrid))?0-info.width:0
            gotGrid+=tickWidth
    //         cl(s)
            s.y = d3.scaleLinear()
              .domain([s.min, s.max]) // input 
              .range([o.config.height, 0]); // output 
            if(s.yShow){
    //           cl(s)
              let labLen=13+Math.floor(0.41*s.name.length*fontSize)
              let labPos=(o.config.height-labLen)/2
              let tv=o.makeTickVals(s.min, s.max,s.id)
    //           cl(tv)
              if(s.axis){s.axis.remove()}
              s.axis=o.svg2.append("g")
              s.axis
                .attr('transform', `translate(${yPos[sIndex]},0)`)
                .attr("class", "y axis")
                .attr("opacity",o.config.opacity)
    //             .on("click",e=>o.onClick("c1"))
                .call(d3.axisLeft(s.y)
                  .tickValues(tv.t)
                  .tickFormat(d=>{return tv.s[d]})
                  .tickSize(tickWidth)
                )
              s.label=s.axis.append("g")
    //           cl(this.props.graph.pageType=="viewGraph")
              let curs=(this.props.graph.pageType=="viewGraph")?null:"pointer"
              s.label
                .append('text')
                .attr('transform', `translate(-40,${labPos}) rotate(-90) `)
                .attr('style', `font-size: ${fontSize}px; fill:${(o.config.dark)?"white":"black"};cursor:${curs}`)// Inside Temperature - 18 chars 111 px at 15 px=.41*15*18 + 13 for color dot
                .on("click",e=>o.onChange("yAxis",{axisOptions:sIndex, divId: this.props.graph.divId}))
                .text(s.name)
              s.circ=s.axis.append("g").append("circle")
                .attr("cx", -45)
                .attr("cy", labPos-10)
                .attr("r", 5)
                .attr("fill",s.color)
            }
          })
      } else if (this.props.graph.divId == "event-timeline") {
          // cl("drawing y axis of event timeline")
          // only label is events, it opens the events checkbox
          // need default event to act as axis
          // cl(yPos[this.sensors.length - 1])
//           cl(this.events)
          this.events.forEach((s,sIndex)=>{
          // this.sensors.forEach((s,sIndex)=>{
            if (s.id == "default") {
                let tickWidth=((s.options||{}).showGrid&&(!gotGrid))?0-info.width:0
                gotGrid+=tickWidth
        //         cl(s)
        //         cl(s.min,s.max)
//                 cl(s.min,s.max)
//                 cl(o.config.height)
                s.y = d3.scaleLinear()
                  .domain([s.min, s.max]) // input 
                  .range([o.config.height, 0]); // output 
        //           cl(s)
                // label is now the cog icon
              let labLen=13+Math.floor(0.41*6*fontSize) // 6 is len of "events"
              let labPos=(o.config.height-labLen)/2
              let tv=o.makeTickVals(0,0,s.id)
    //           cl(tv)
              if(s.axis){s.axis.remove()}
              s.axis=o.svg2.append("g")
              s.axis
                .attr('transform', `translate(${yPos[this.sensors.length - 1] || 60 },0)`) // only one y-axis
                .attr("class", "y axis")
                .attr("opacity",o.config.opacity)
    //             .on("click",e=>o.onClick("c1"))
                .call(d3.axisLeft(s.y)
                  .tickValues(tv.t)
                  .tickFormat(d=>{return tv.s[d]})
                  .tickSize(tickWidth)
                )
              s.label=s.axis.append("g")
    //           cl(this.props.graph.pageType=="viewGraph")
              // s.label
              //   .append('text')
              //   .attr('transform', `translate(-40,${labPos}) rotate(-90) `)
              //   .attr('style', `font-size: ${fontSize}px; fill:${(o.config.dark)?"white":"black"};cursor:${curs}`)// Inside Temperature - 18 chars 111 px at 15 px=.41*15*18 + 13 for color dot
              //   .on("click",e=>o.onChange("yAxis",{showEventOptions:1, divId: this.props.graph.divId}))
              //   .text("Events")
              fontSize = 25
              s.label
                .append('text')
                .attr('transform', `translate(-20,28)`)
                .attr("class", "material-icons")
                .attr('style', `font-size: ${fontSize}px; fill:${(o.config.dark)?"white":"black"};cursor:pointer`)// Inside Temperature - 18 chars 111 px at 15 px=.41*15*18 + 13 for color dot
                .on("click",e=>o.onChange("yAxis",{showEventOptions:1, divId: this.props.graph.divId}))
                .text("settings")
              // s.label
              //   .append('g')
              //   .append('path')
              //   .attr("d", cog_d)
              //   .append("rect")
              //   .attr('transform', `translate(-40,${labPos})`)
              //   .attr("y", 72.72)
              //   .attr("width", 100)
              //   .attr("height", 2)
                // s.label
                //     .append('text')
                //     .attr('transform', `translate(-40,${labPos})`)
                //     .attr("class", "material-icons")
                //     .attr('style', `font-size: ${fontSize}px; fill:${(o.config.dark)?"white":"black"};cursor:${curs}`)// Inside Temperature - 18 chars 111 px at 15 px=.41*15*18 + 13 for color dot
                //     .on("click",e=>o.onChange("yAxis",{showEventOptions:1, divId: this.props.graph.divId}))
                //     .text("settings")
                }
          })
      }
    },
    
    makeHandleGradient:()=>{
      o.hGrad=o.defs.append("linearGradient")
        .attr("id", `handleGradient`)
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%");
        o.hGrad.append("stop")
        .attr("class", "start")
        .attr("offset", "0%")
        .attr("stop-color", "black")
        .attr("stop-opacity", 0);
        o.hGrad.append("stop")
        .attr("class", "end")
        .attr("offset", "100%")
        .attr("stop-color", "black")
        .attr("stop-opacity", 0.2);
    },
    
    addGradients:(s,i)=>{
//       cl("addgrad")
//       cl(s.color)
      s.grad=o.defs.append("linearGradient")
        .attr("id", `svgGradient${i}`)
        .attr("x1", "0%")
        .attr("x2", "0%")
        .attr("y1", "0%")
        .attr("y2", "100%");
        s.grad.append("stop")
        .attr("class", "start")
        .attr("offset", "0%")
        .attr("stop-color", s.color)
        .attr("stop-opacity", .25);
        s.grad.append("stop")
        .attr("class", "end")
        .attr("offset", "50%")
        .attr("stop-color", s.color)
        .attr("stop-opacity", 0);

    },
    
    addShades:()=>{
//       cl("addShades")
      this.sensors.forEach((s,i)=>{
        var line=d3.line()
          .x((d, i)=> { return o.x(d.t); }) // set the x values for the line generator
          .y((d)=> { return s.y(d.v); }) // set the y values for the line generator 
          .curve(o.getCurveType(s)) // apply smoothing to the line
        if(s.shade){
//           cl("remove")
          s.shade.remove()
          
        }
        s.shade=o.shadeParent.append("path")
        var shade=s.data.slice(0)
        if(shade.length){
          let last=shade.length-1
//           cl(shade)
          shade.push({t:shade[last].t,v:s.min},{t:shade[0].t,v:s.min})
          s.shade
            .datum(shade) // 10. Binds data to the line 
            .attr("class", "line") // Assign a class for styling 
            .attr("fill", `url(#svgGradient${i})`) // Assign a class for styling 
  //           .attr("stroke", s.color) // Assign a class for styling 
            .attr("d", line) // 11. Calls the line generator 
        }
      })
    },

    getEventColor:(id)=>{
        switch (id) {
            case "grj":
                return "#88CC88"
            case "adl":
                return "#88CC88"
            case "cte":
                return "#1976d2"
            default:
                return "#88CC88"
        }
    },

    makeImagePath:(id)=>{
        cl(id.substring(id.length - 3))
        // if video, set timestamp 
        let url = `${constant.expressUrl}/usa/images/uploads/${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`
        if (id.substring(id.length - 3) == "mp4") url += "#t=0.001"
        // cl(`${constant.expressUrl}/usa/images/uploads/${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}`)
        return url
    },

    makeEventDMs:()=>{
        let timeDict = {};
        this.events.forEach((s,i)=>{
//             cl(s)
              // do different events for gje, adl, ctes
            if (s.id != "default") {
            if (s.dmPath) {
              s.dmPath.remove()
            }
            s.dmPath=o.dotsParent.append("g")
            // get events with and without images
            let events = s.data
            let mEvents = []
            if (s.id == "grj") {
                events = s.data.filter((s)=> {return s.data.images?.length == 0})
                mEvents = s.data.filter((s)=> {return s.data.images?.length})
            }
            let color = o.getEventColor(s.id)
            // regular events
            // cl(color)
            // cl(events)
            if (events.length) {
                s.dmPath.selectAll("event-dot")
                .data(events)
                .enter().append("circle") // Uses the enter().append() method
                .on("click",(e,d)=>o.onChange("eventClick",{e:e, type: s.id, data:d.data})) // bring up popup
                .attr("class", "dot") // Assign a class for styling
                // .attr("id","dataMarker")
                .attr("id", (d)=> {
                    // cl(d)
                    if (s.id != "grj") {
                        return `{"cmd":"graph", "type": "sensor", "s": "${s.s}", "id": "${s.id}", "t": ${Math.floor(d.t.getTime())}, "v": ${JSON.stringify(d.data)}}`
                    } else {
                        return `data marker`
                    }  
                })
                .attr("cx", (d, i)=>{ 
                  // cl(d)
                  return o.x(d.t) })
                .attr("cy", (d)=>{
                    //  check dictionary to see if an event is already at this time 
                    if (!timeDict[d.t]) timeDict[d.t] = 0
                    if (timeDict[d.t] > 0) {
                        // cl(d)
                        // cl(timeDict[d.t])
                    }
                    return s.y(timeDict[d.t]+=3)
                })
                .attr("fill", d=>{return color}) // Assign a class for styling 
                .attr("style", "cursor:pointer") // Assign a class for styling 
                .attr("stroke", s.color) // Assign a class for styling 
                .attr("r", d=>{return 5})
                .append("svg:title")
                .text((d,i)=>{return dateToDisplayDate(d.t,"hh:mm:ss")+"\n"+s.id})
            }
            // events with images
            let videoExts=["mp4","mov"]
            if (mEvents.length) {
                s.dmPath.selectAll("event-img")
                .data(mEvents.filter((d)=>{return !videoExts.includes(d.data.images[0].id.substring(
                  d.data.images[0].id.length - 3))}))
                .enter().append("svg:image") // Uses the enter().append() method
                // .enter().append((d)=> {
                //     cl(d.data.images[0].id.substring(d.data.images[0].id.length - 3))
                //     return d3.creator(d.data.images[0].id.substring(d.data.images[0].id.length - 3) == "mp4" ? "video" : "svg:image")
                // }) // Uses the enter().append() method
                .on("click",(e,d)=>o.onChange("eventClick",{e:e, type: s.id, data:d.data})) // bring up gj popup
                .attr("id","dataMarker")
                .attr("xlink:href", (d)=>{return o.makeImagePath(d.data.images[0].id)})
                .attr("x", (d, i)=>{ 
                  // cl(d)
                  return o.x(d.t) - 14})
                .attr("y", (d)=>{ return 0 })
                .attr("class", "image") // Assign a class for styling
                .attr("style", "cursor:pointer") // Assign a class for styling
                .attr('width', 30)
                .attr('height', 30)
                .attr('marginTop', -5)
                .append("svg:title")
                .text((d,i)=>{return dateToDisplayDate(d.t,"hh:mm:ss")+"\n"+ s.id})
            }
            // events with video
            if (mEvents.length) {
                s.dmPath.selectAll("event-img")
                .data(mEvents.filter((d)=>{return videoExts.includes(d.data.images[0].id.substring(d.data.images[0].id.length - 3))}))
                .enter().append('text')
                .attr("x", (d, i)=>{ 
                  // cl(d)
                  return o.x(d.t) - 14 })
                .attr("y", (d)=>{ return 27 })
                .on("click",(e,d)=>o.onChange("eventClick",{e:e, type: s.id, data:d.data})) // bring up gj popup
                .attr("id","dataMarker")
                .attr("class", "material-icons")
                .attr('style', `font-size: 25px; fill:${(o.config.dark)?"white":"black"};cursor:pointer`)// Inside Temperature - 18 chars 111 px at 15 px=.41*15*18 + 13 for color dot
                .text("play_circle_outline")
                .append("svg:title")
                .text((d,i)=>{return dateToDisplayDate(d.t,"hh:mm:ss")+"\n "+ s.id})
            }
            // cl(s.dmPath)
            //         cl(Math.floor(s.data[0].t.getTime()/1000))
            // use media instead of button if present
            
            // cl(events)
            // cl(mEvents)
            // get color based on event
            

            // if (mEvents.length) {
            // s.dmPath.selectAll("event-dot")
            //   .data(mEvents)
            //   .enter().append("circle") // Uses the enter().append() method
            //   .on("click",(e,d)=>o.onChange("eventClick",{e:e, type: s.id, data:d.data})) // bring up gj popup
            //   .attr("class", "dot") // Assign a class for styling
            //   .attr("id","dataMarker")
            //   .attr("cx", (d, i)=>{ 
            //     cl(d)
            //     return o.x(d.t) })
            //   .attr("cy", (d)=>{ return s.y(0) })
            //   .attr("fill", d=>{return color}) // Assign a class for styling 
            //   .attr("style", "cursor:pointer") // Assign a class for styling 
            //   .attr("stroke", s.color) // Assign a class for styling 
            //   .attr("r", d=>{return 7})
            //   .append("svg:title")
            //   .text((d,i)=>{return dateToDisplayDate(d.t,"hh:mm:ss")+"\n"+"GROW JOURNAL"})
            // }

            
            }
      })

    },
    
    addDMs:(s)=>{
//       cl(this.props.graph.divId)
      if(this.props.graph.divId=="guide-graph"){return}
      if(this.props.graph.divId=="event-timeline"){
//           cl(this.events)
          o.makeEventDMs()
      } else {

//       cl(info.showDM)
//       cl(`add: ${this.props.graph.divId}`)
//       cl(s.dmPath)
//       if(s.dmPath){
//         s.dmPath.remove()
//         cl("remove")
//       }
//       s.dmPath=o.pathsParent.append("DM")
      
      this.sensors.forEach((s,i)=>{
        // cl(s)

        if(s.dmPath){
//           cl("remove")
          s.dmPath.remove()
          
        }
        if(info.showDM){
          s.dmPath=o.dotsParent.append("g")
  //         cl(Math.floor(s.data[0].t.getTime()/1000))
          s.dmPath.selectAll("dot")
            .data(s.data)
            .enter().append("circle") // Uses the enter().append() method
            // .on("click",(e,d)=>o.onChange("gjClick",{e:e,gj:d.gj}))
            .attr("class", "dot") // Assign a class for styling
            // .attr("id","dataMarker")
            .attr("id", (d)=> {
                // cl(d)
                // return `{"cmd":"graph", "type": "sensor"}`
                return `{"cmd":"graph", "type": "sensor", "s": "${s.s}", "zInd": ${s.z}, "id": "${s.id}", "c": ${s.c}, "t": ${Math.floor(d.t.getTime())}, "v": ${d.v}}`
            })
            .attr("cx", (d, i)=>{ 
//               cl(d)
              return o.x(d.t) })
            .attr("cy", (d)=>{ return s.y(d.v) })
            // .attr("fill", d=>{return (d.gj)?"#88CC88":s.color}) // Assign a class for styling 
            .attr("fill", d=>{return s.color}) // Assign a class for styling 
//             .style("pointer", "cursor"
            .attr("style", "cursor:pointer") // Assign a class for styling 
//             .attr("onClick", d=>{cl(d)}) // Assign a class for styling 
            .attr("stroke", s.color) // Assign a class for styling 
            // .attr("r", d=>{return (d.gj)?10:5})
            .attr("r", d=>{return 5})
            .append("svg:title")
            .text((d,i)=>{return dateToDisplayDate(d.t,"hh:mm:ss")+"\n"+d.v})
  //         s.dmPath.remove()
//           s.dmPath.selectAll("dot")
//             .on("click",e=>{cl(e);/* o.onChange("gjClick",{gj:d.gj})*/})
        }
      })

      }
    },
    
    getCurveType:(s)=>{
//       cl(s)
      return (["stT","stH","spH","spC","spU","spD","alL","alH","c00","c01","c02","c03",
        "c04","c05","c06","c07","c08","c09","c10","c11"].includes(s.id.substring(2)))?d3.curveStepAfter:d3.curveMonotoneX
    },

    addPaths:()=>{// adds the lines
//       cl(`add paths ${this.props.graph.divId}`)
//       cl(this.sensors)
//       cl(this.props.graph.divId)
//       cl(`add ${this.sensors.length} paths to ${this.props.graph.divId}`)
      this.sensors.forEach(s=>{
//         cl(s)
//         cl(Math.floor(s.data[0].t.getTime()/1000))
//         cl(s.path)
//         cl(s.data)
        if(s.path){
//           cl(`remove ${s.color} path from ${this.props.graph.divId}`)
          s.path.remove()
//             .datum(s.data) // 10. Binds data to the line 
//             .attr("class", "line") // Assign a class for styling 
//             .attr("fill", "none") // Assign a class for styling 
//             .attr("stroke", s.color) // Assign a class for styling 
//             .attr("stroke-width", 3)
//             .attr("d", line) // 11. Calls the line generator 
        }
//         if(s.dmPath){s.dmPath.remove()}
//         s.dmPath=null
//         let curve=(["stT","stH","spH","spC","spU","spD","alL","alH",
//           "c00","c01","c02","c03","c04","c05","c06","c07","c08","c09","c10","c11"].includes(s.id))?d3.curveStepAfter:d3.curveMonotoneX
        var line=d3.line()
          .x((d, i)=> { return o.x(d.t); }) // set the x values for the line generator
          .y((d)=> { return s.y(d.v); }) // set the y values for the line generator 
//           .curve(d3.curveMonotoneX) // apply smoothing to the line
          .curve(o.getCurveType(s)) // apply smoothing to the line
//         cl(s.data)
        s.path=o.pathsParent.append("path")
//         cl(`add ${s.color} path from ${this.props.graph.divId}`)
//         cl(s.color)
//         cl(s.data)
        s.path
          .datum(s.data) // 10. Binds data to the line 
          .attr("class", "line") // Assign a class for styling 
          .attr("fill", "none") // Assign a class for styling 
          .attr("stroke", s.color) // Assign a class for styling 
          .attr("stroke-width", 3)
          .attr("d", line) // 11. Calls the line generator 
//         cl(s.path)
      })
    },

//     addPath:(svg)=>{
//       var line=o.makeLine()
//       if(o.path){o.path.remove()}
//       o.path=o.pathsParent.append("path")
//       o.path
//         .datum(o.config.dataset2) // 10. Binds data to the line 
//         .attr("class", "line") // Assign a class for styling 
//         .attr("fill", "none") // Assign a class for styling 
//         .attr("stroke", "#FF00FF") // Assign a class for styling 
//         .attr("d", line) // 11. Calls the line generator 
//     },

//     addShade:(svg)=>{
//       var line=o.makeLine()
//       var shade=o.config.dataset2.slice(0)
//       let last=shade.length-1
// //       cl(shade[0])
// //       cl(shade[last])
//       shade.push({t:shade[last].t,v:18},{t:shade[0].t,v:18})
//       o.path=svg.append("path")
//       o.path
//         .datum(shade) // 10. Binds data to the line 
//         .attr("class", "line")
//         .attr("fill", "#FFCCFF")
//         .attr("stroke", "#FF00FF")
//         .attr("d", line) // 11. Calls the line generator 
//     },
    
//     dragType: null,
//     
// //     mouseOver:()=>{
// //       cl("m")
// //     },
//     
//     mouseMove:()=>{
//       cl(o.dragType)
//     },
//     
//     mouseDown:(type,e)=>{
//       o.dragType=type
//       cl(type)
// //       cl(a)
// //       cl(b)
//     },
//     
//     mouseUp:()=>{
//       o.dragType=null
//     },
    
    onChange:(type,vals)=>{
//       cl(vals)
//       cl(type,vals)
      this.props.graph.onChange({type: type, vals:vals})
    },
    
    dragHandler: d3.drag()
    .on("start", function (e) {
//       cl("showTimePeriod")
      if(!o.config.that.props.graph.showTimePeriod){
        o.handleId=this.id
          var current = d3.select(this);
          o.deltaX = current.attr("x") - e.x;//current.attr("x")
      }
    })
    .on("drag", function (e) {
      if(!o.config.that.props.graph.showTimePeriod){// if this is the guide graph
//         cl("drag")
//         cl(o.handleId)
        let newX=e.x + o.deltaX
        let oLeft=o.left
        let oRight=o.right
//         cl(oLeft,oRight)
        if(o.handleId=="center"){
          let dif=newX-o.left
          if(((dif>0)&&(o.right+dif<info.width))||
            ((dif<0)&&(o.left+dif>0))){
              o.left+=dif
              o.right+=dif
              o.boxL.attr("x", o.left)
              o.boxR.attr("x", o.right-5)
            }
        }else{
          if(o.handleId=="left"){
//             cl("left")
            if((newX>=o.right-20)||(newX<0)){return}
            o.left=newX
            o.boxL.attr("x", o.left)
          }else{// right
            if((newX<o.left+20)||(newX>o.config.width)){return}
            o.right=newX+5
            o.boxR.attr("x", o.right-5)
          }
        }
        let sFrom=o.xValToFromPos(o.left,false)
        let sTo=o.xValToFromPos(o.right,false)
//         cl(sTo,sFrom)
        if(sTo-sFrom>o.maxZoom || // not maxed yet, or just sliding in the middle
        (((o.right-o.left)>=(oRight-oLeft))&&(o.left>0)&&(o.right<o.config.width))  ){
//           cl("not max zoom")
          info.sFrom=sFrom
          info.sTo=sTo
        }else{
//           cl("restore")
          o.left=oLeft
          o.right=oRight
//           cl(`box l: ${o.left}`)
          o.boxL.attr("x", o.left)
          o.boxR.attr("x", o.right-5)
//           cl(o.left)
        }
//         cl(o.left,o.right)
        if(info.sFrom<info.from){
          info.sFrom=info.from
          let oldLeft=o.left
          o.left=o.xValToFromPos(info.sFrom,true)
          o.deltaX+=(o.left-oldLeft)
//           cl(`box l: ${o.left}`)
          o.boxL.attr("x", o.left)
        }
        if(info.sTo>info.to){// need to make sure the box doesn't shrink too much, too
          cl(info.to,info.sTo)
  //         cl(o.right)
  //         cl(o.xValToFromPos(info.sTo,true))
          info.sTo=info.to
  //         cl(info.sTo)
          o.right=o.xValToFromPos(info.sTo,true)
          if((o.right-o.left)<20){
            o.left=o.right-20
//             cl(`box l: ${o.left}`)
            o.boxL.attr("x", o.left)
          }
          o.boxR.attr("x", o.right-5)
        }
  //       cl(info)
  //       cl(this.props)
  //       cl(o)
//         cl(info)
        cl("select change")
        o.onChange("selectChange",{fromVal:info.sFrom, toVal:info.sTo})
//         d3.select(this).attr("x", newX)
  //       cl(o.left,o.right)
        o.box
          .attr('x',o.left+5)
          .attr("width", o.right-o.left-5)
//         o.boxC
//           .attr('x',o.left+5)
//           .attr("width", o.right-o.left-5)
//         cl(o.left)
      }
        
    }),
    
    left:50,
    right:100,
    handleId: null,
    
    xValToFromPos:(v,toFlag)=>{
      v=Math.round(v)
      let gr=this.props.graph
      let x0=o.minTime//gr.from
      let d0=o.maxTime-o.minTime//gr.to-gr.from
      let x1=0
      let d1=o.config.width
      if(toFlag){// to pixel position
        return Math.round(x1+d1*(v-x0)/d0)
      }else{
        let v1=Math.round(x0+d0*(v-x1)/d1)
//         let v0=Math.round(x1+d1*(v1-x0)/d0)
//         cl(v,v0,v1)
        return v1
      }
    },
    
    deleteSensors:(oldSensors, newSensors)=>{
      for(let i=oldSensors.length-1;i>=0;i--){
        let s=oldSensors[i]
        if(!newSensors.includes(s)){
          let se=this.sensors[i]
          if(se?.path){
            se.path.remove()}
          if(se?.shade){
            se.shade.remove()}
          if(se?.dmPath){
            se.dmPath.remove()}
          this.sensors.splice(i,1)
        }
      }
//       cl(this.sensors)
    },
    
    addSensors:(oldSensors, newSensors,vals)=>{
      let needNewData=false
//       cl(oldSensors)
      newSensors.forEach((s,i)=>{
        if(!oldSensors.includes(s)){
          let v=vals[i]
//           cl(v)
          let s2={
            c:v.c,
            s:v.s,
            z:v.z,
            i:v.i,
            yShow:v.yShow,
            name:v.name,
            color:v.color,
            id:v.id,
          }
          if(v.options){
            s2.color=v.options.color
            s2.min=v.options.min 
            s2.max=v.options.max
            s2.mode=v.options.mode
            s2.unit=v.options.unit
            s2.options=v.options
          }
//           cl(s2)
          this.sensors.push(s2)
          needNewData=true
        }
      })
//       cl(this.sensors)
      return needNewData
    },
    
    deleteEvents:(oldEvents, newEvents)=>{
      for(let i=oldEvents.length-1;i>=0;i--){
        let s=oldEvents[i]
        // cl(s)
        // if(!newEvents.includes(s) && s != "default"){
        if(s != "default"){
          let se = this.events[i]
          // cl(se)
          // should remove dots???
          if(se?.dmPath){se.dmPath.remove()}
          this.events.splice(i,1)
        }
      }
//       cl(this.sensors)
    },
    
    addEvents:(oldEvents, newEvents,vals)=>{
      let needNewData=false
      newEvents.forEach((s,i)=>{
        // cl(s)
        // if(!oldEvents.includes(s)){
          let v=vals[i]
//           cl(v)
          let s2={
            s:v.s,
            z:v.z,
            yShow:v.yShow,
            name:v.name,
            color:v.color,
            id:v.id,
          }
          if(v.options){
            s2.color=v.options.color
            s2.min=v.options.min 
            s2.max=v.options.max
            s2.mode=v.options.mode
            s2.unit=v.options.unit
            s2.options=v.options
          }
//           cl(s2)
          this.events.push(s2)
          needNewData=true
        // }
      })
      // cl(needNewData)
      // cl(this.events)
      return needNewData
    },
    
    makeGradients:()=>{
      o.svg.selectAll("defs").remove()
      o.defs=o.svg.append("defs")
      this.sensors.forEach((s,i)=>{
        // cl(s.color)
        o.addGradients(s,i)
        
      })
      if(info.showSelect){
        o.makeHandleGradient()
      }
      
    },
    
    onNewTimePeriod:async(vals)=>{// vals.startDate and endDate are Date objects
// this needs to update the info.from and to, also
//       cl(vals)
//       cl(info)
      // cl(`New Time Period for ${this.graphName}`)
      info.from=vals.fromTime
      info.sFrom=(vals.fromSelect)?vals.fromSelect:vals.fromTime
      info.to=vals.toTime
      info.sTo=(vals.toSelect)?vals.toSelect:vals.toTime
      info.showDM=false// why?
//       cl(vals)
      
      if(typeof(vals.dataMarkers)!="undefined"){
        info.showDM=vals.dataMarkers
      }
//       cl(info)

      if(vals.sensors){o.setNewSensors(vals.sensors)}
//       cl(this.props.graph.showSelect)
      o.makeGradients()
//       this.sensors.forEach((s,i)=>{
//         cl(s.color)
//         o.addGradients(s,i)})
      if(!this.props.graph.showSelect){
        info.from=info.sFrom
        info.to=info.sTo
      }
      await this.getHistory(info.from,info.to)
      let tr=o.calcTr(info.from,info.to)
      o.doZoom2(tr)
      return
//       if(this.props.graph.showSelect){// double check!
//       }else{
//         cl(vals)
//         cl(o)
//         cl(info)
//         let tr=o.calcTr(vals.fromTime, vals.toTime)
//         o.doZoom2(tr)
//       }
    },
    
    setNewSensors:(vals)=>{// returns true if we need to get data
      cl(vals)
      let newSensors=[]
      vals.forEach(v=>{ newSensors.push(`${v.s}-${v.z}-${v.c}-${v.i}`) })
      let oldSensors=[]
//       cl(this.sensors)
      this.sensors.forEach(v=>{ 
//         cl(v)
        oldSensors.push(`${v.s}-${v.z}-${v.c}-${v.i}`) 
        if(v.axis){v.axis.remove()}
      })
//       cl(oldSensors)
      o.deleteSensors(oldSensors, newSensors)
      return o.addSensors(oldSensors, newSensors,vals)// true if we need new data
//       if(vals.length<this.sensors.length){
//         o.deleteSensors(oldSensors, newSensors)
//         return false
// //         o.checkNewData(o.dispFrom,o.dispTo)
//       }else{
// //         cl("add sensors")
//         o.addSensors(oldSensors, newSensors,vals)
//         return true// need new data
// //         await this.getHistory(o.dispFrom,o.dispTo)
// //         o.checkNewData(o.dispFrom,o.dispTo)
//       }
    },
    
    setNewEvents:(vals)=>{// returns true if we need to get data
      // cl(vals)
      let newEvents=[]
      vals.forEach(v=>{ newEvents.push(v.id) })
      let oldEvents=[]
      // cl(this.events)
      this.events.forEach(v=>{ 
        // cl(v)
        oldEvents.push(v.id) 
        if(v.axis){v.axis.remove()}
      })
//       cl(oldSensors)
      o.deleteEvents(oldEvents, newEvents)
      return o.addEvents(oldEvents, newEvents,vals)// true if we need new data
//       if(vals.length<this.sensors.length){
//         o.deleteSensors(oldSensors, newSensors)
//         return false
// //         o.checkNewData(o.dispFrom,o.dispTo)
//       }else{
// //         cl("add sensors")
//         o.addSensors(oldSensors, newSensors,vals)
//         return true// need new data
// //         await this.getHistory(o.dispFrom,o.dispTo)
// //         o.checkNewData(o.dispFrom,o.dispTo)
//       }
    },

    onNewSensors:async(vals)=>{// if a sensor is added, need to get entire history
//       cl(`*********************************new sensors ${this.props.graph.divId}*************************************`)
//       cl(vals.dataMarkers)
//       cl(vals)
//       cl(this.props.graph.divId)
      // cl(`New Sensors: ${this.graphName}`)
//       cl(vals)
      if(vals.sensors){// if axis
        if(true || this.props.graph.divId!="guide-graph"){
//           cl("axis")
          let si=vals.sensors[vals.ind].options
          let so=this.sensors[vals.ind]
//           cl(si)
//           cl(so)
          so.mode=si.mode
          so.min=si.min 
          so.max=si.max
          if(!so.options){so.options={}}
          so.options.min=si.min 
          so.options.max=si.max
          so.options.showGrid=si.showGrid
          so.color=si.color
          so.unit=si.unit
//           cl(so.min)
          o.addGradients(so,vals.ind)
//           cl(so.min)
          if(this.props.graph.divId!="guide-graph"){
            o.addYAxes()
//             cl(so.min)
          }
          o.checkNewData(o.dispFrom,o.dispTo)
//           cl(so.min)
        }
        return
      }
      if(typeof(vals.dataMarkers)!="undefined"){
//         cl("doing")
        info.showDM=vals.dataMarkers
        o.checkNewData(o.dispFrom,o.dispTo)
        return
      }
      if(o.setNewSensors(vals)){
        await this.getHistory(o.dispFrom,o.dispTo)
      }
      o.checkNewData(o.dispFrom,o.dispTo)
//       let newSensors=[]
//       vals.forEach(v=>{ newSensors.push(`${v.s}-${v.z}-${v.c}-${v.i}`) })
//       let oldSensors=[]
//       this.sensors.forEach(v=>{ oldSensors.push(`${v.s}-${v.z}-${v.c}-${v.i}`) })
//       if(vals.length<this.sensors.length){
//         o.deleteSensors(oldSensors, newSensors)
//         o.checkNewData(o.dispFrom,o.dispTo)
//       }else{
//         o.addSensors(oldSensors, newSensors,vals)
//         await this.getHistory(o.dispFrom,o.dispTo)
//         o.checkNewData(o.dispFrom,o.dispTo)
//       }
      
//       cl(this.sensors)
//       cl(vals)
//       this.sensors=vals
//       let ad=o.allData
//       o.getRealData(ad.from,ad.to,vals)
//       cl(o.allData)
      
    },

    onNewEvents:async(vals)=>{// if an event is added/updated, need to get entire history
//       cl(`*********************************new sensors ${this.props.graph.divId}*************************************`)
//       cl(vals.dataMarkers)
//       cl(vals)
//       cl(this.props.graph.divId)
      // cl(`New Events: ${this.graphName}`)
      // cl(vals)
      // if(vals.length){// if events to show
        if(this.props.graph.divId!="main-graph"){
            cl("adjusting events")
//           cl("axis")
          // let si=vals.events[vals.ind].options
          // let so=this.events[vals.ind]
//           cl(si)
//           cl(so)
          // so.mode=si.mode
          // so.min=si.min 
          // so.max=si.max
          // if(!so.options){so.options={}}
          // so.options.min=si.min 
          // so.options.max=si.max
          // so.options.showGrid=si.showGrid
          // so.color=si.color
          // so.unit=si.unit
//           cl(so.min)
          // o.addGradients(so,vals.ind)
//           cl(so.min)
            if(o.setNewEvents(vals)){
                await this.getHistory(o.dispFrom,o.dispTo)
              }
//             cl(so.min)
            o.checkNewData(o.dispFrom,o.dispTo)
//           cl(so.min)
          return
        // }
          // if(typeof(vals.dataMarkers)!="undefined"){
          //   cl("undefined")
          //   info.showDM=vals.dataMarkers
          //   o.checkNewData(o.dispFrom,o.dispTo)
          //   return
          // }
      }
//       if(typeof(vals.dataMarkers)!="undefined"){
// //         cl("doing")
//         info.showDM=vals.dataMarkers
//         o.checkNewData(o.dispFrom,o.dispTo)
//         return
//       }
      // if(o.setNewEvents(vals)){
      //   await this.getHistory(o.dispFrom,o.dispTo)
      // }
      // o.checkNewData(o.dispFrom,o.dispTo)      
    },
    
    onSelectZoom:(vals)=>{// from, to
      // cl("on select zoom")
      // cl(this.props.graph.divId)
      if(this.props.graph.showSelect){// double check!
        info.sFrom=vals.fromVal
        info.sTo=vals.toVal
//         cl(info.sFrom,info.sTo)
        o.setBox()
      }
    },
    
    onSelectChange:(vals)=>{// from, to
      // cl("on select change")
      // cl(this.props.graph.divId)
      if(!this.props.graph.showSelect){// double check!
// this needs to calculate the transform values
        // cl(vals)
        let tr=o.calcTr(vals.fromVal, vals.toVal)
        o.doZoom2(tr)
//         cl(tr)
//         info.sFrom=vals.fromVal
//         info.sTo=vals.toVal
//         o.setBox()
      }
    },

    setBox:()=>{
//       cl("setbox")
      if(info.showSelect){
//         cl(info.sFrom,info.sTo)
//         cl(info.sFrom,info.sTo)
        o.left=o.x(1000*info.sFrom)
        o.right=o.x(1000*info.sTo)
//         cl(`setbox: ${o.left}`)
        o.moveBox()
      }
    },
    
    moveBox:()=>{
//       cl("movebox")
//       cl(o.left,o.right)

      o.box
        .attr('x',o.left+5)
        .attr("width", o.right-o.left-5)
      o.boxC
        .attr('x',o.left+5)
        .attr("width", o.right-o.left-5)
              
      o.boxR
        .attr('x',o.right-5)
      o.boxL
        .attr('x',o.left)
    },
    
    addBox:(svg)=>{
      let h=o.config.height
      let y=o.config.height-h
//       let w=o.config.width-100
      o.boxP=svg.append("g")
//       cl(o.left,o.right)
//       cl(o.left,y)
      o.box=o.boxP
        .append("rect")
        .attr('id','center')
        .attr('x',o.left+5)
        .attr("width", o.right-o.left-5)
        .attr('y',y)
        .attr("height", h)
        .attr("fill",(o.config.dark)?"white":"black")
        .attr("opacity",0.2)
        .attr("stroke","red")
        .attr("cursor","pointer")
        .call(o.dragHandler)
      o.boxC=o.boxP
        .append("clipPath")
        .attr("id","boxClip")
        .append("rect")
        .attr('x',o.left+5)
        .attr("width", o.right-o.left-5)
        .attr('y',y)
        .attr("height", h)
        .attr("fill","black")
        .attr("opacity",0.1)
//         cl(o.right,y)
      o.boxR=o.boxP
        .append("rect")
        .attr('id','right')
        .attr('x',o.right-5)
        .attr('y',y)
        .attr("width", 5)
        .attr("height", h)
        .attr("fill","url(#handleGradient)")
//         .attr("stroke-width", "1px")
//         .attr("stroke", "#DDDDDD")
        .attr("cursor","ew-resize")
        .call(o.dragHandler)
      o.boxL=o.boxP
        .append("rect")
        .attr('id','left')
        .attr('x',o.left)
        .attr('y',y)
        .attr("width", 5)
        .attr("height", h)
        .attr("fill","url(#handleGradient)")
//         .attr("fill","#EEEEEE")
//         .attr("stroke-width", "1px")
//         .attr("stroke", "#DDDDDD")
        .attr("cursor","ew-resize")
        .call(o.dragHandler)
      
    },
    
    addXGridlines:()=>{
      o.xAxisGrid=d3.axisBottom(o.x)
          .ticks(20)
          .tickSize(0-o.config.height)
          .tickFormat("")
      o.svgxg=o.svg2.append("g")
        .attr("class", "x grid")
        .attr("transform", "translate(0," + o.config.height + ")")
        .attr("opacity", 0.05)
        .call(o.xAxisGrid)
    },
    
    addXAxis:(svg,minTime,maxTime)=>{
      let tv=o.makeXTickVals(minTime,maxTime)
//       cl(tv)
      let range=(maxTime-minTime)/3600
      
      let ticks=o.makeXTickVals(minTime,maxTime)
//       let step=30
//       if(ticks>150){step=}
//       cl(range)
//       cl(o)
      // cl(o.x)
      if (this.props.graph.divId == "event-timeline") ticks = ticks.map((d)=>{return 0})
      if(range>24){
        o.xAxis=d3.axisBottom(o.x)
//             .ticks(5)
            .tickValues(ticks)
            .tickFormat(d3.timeFormat("%B:%e %H:%M"))
      }else{
        o.xAxis=d3.axisBottom(o.x)
            .tickValues(ticks)
            .tickFormat(d3.timeFormat("%H:%M"))
      }
      o.svgx=svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + o.config.height + ")")
        .attr("opacity",o.config.opacity)
        .call(o.xAxis)
      if(info.showGrid){o.addXGridlines()}
//       cl(info.showDM)
    },
    
    getFromTo:(tr)=>{// only called on main graph, zooming
//       cl(tr)
      if(!o.zr){
        let gr=this.props.graph
        let [fromVal,toVal]=o.calcFromTo(tr)// the limits of the new transform
        if(fromVal<gr.gFrom){fromVal=gr.gFrom}// compare with limits of data
        if(toVal>gr.gTo){toVal=gr.gTo}
        Object.assign(tr,o.calcTr(fromVal,toVal))
//         cl("select zoom and select change")
        o.onChange("selectZoom",{fromVal:fromVal,toVal:toVal})
        o.onChange("selectChange",{fromVal:fromVal,toVal:toVal})
      }
    },
    
    doZoom2:(tr)=>{
//         cl("doZoom2")
      if(!this.props.graph.showTimePeriod){
          o.pathsParent.attr("transform",`translate(${tr.x},0) scale(${tr.k},1) `)// adjust sensor paths
          o.shadeParent.attr("transform",`translate(${tr.x},0) scale(${tr.k},1) `)// adjust sensor paths
          o.dotsParent.attr("transform",`translate(${tr.x},0) scale(${tr.k},1) `)// adjust sensor paths
  //         this.sensors.forEach(s=>{
  //           s.path.attr("transform",`translate(${tr.x},0) scale(${tr.k},1) `)// adjust sensor paths
  //         })
          if(info.showSelect){o.boxP.attr("transform",`translate(${tr.x},0) scale(${tr.k},1) `)} // do select box
  //         o.setBox()// 
          o.throttleNewData(tr)// load new data
      }
    },
    
    doZoom:(e)=>{// this really shouldn't be called when a graph is being initialized
// showTimePeriod means that the dialog is showing, and *don't* zoom or anything
//       let maxZoom=3600
//       cl("doZoom")
      let tr=e.transform
//       cl(tr)
      if((tr.k>1)&&((o.dispTo-o.dispFrom<o.maxZoom)||(info.sTo-info.sFrom<o.maxZoom))&&
      (!this.props.graph.showSelect)
      ){
        o.resetZoom()
        return
      }
//       cl(this.props.graph.showTimePeriod)
      if(!this.props.graph.showTimePeriod){
//         cl(`do zoom ${this.props.graph.divId}`)
        let tr=e.transform
//         cl(tr)
        if(info.showSelect&&!this.updating){// guide graph
//           cl([o.dispFrom,o.dispTo])
          let [fromVal,toVal]=o.calcFromTo(tr)
          if((info.sFrom>=fromVal)&&(info.sTo<toVal)){
            o.dispFrom=fromVal
            o.dispTo=toVal
            info.from=fromVal
            info.to=toVal
            if(info.sFrom<info.from){info.sFrom=info.from}
            if(info.sTo>info.to){info.sTo=info.to}
  //           cl([info.sFrom,info.sTo])
            if((info.sTo-info.sFrom)<=o.maxZoom){
              if(info.sTo==info.to){info.sFrom=info.sTo-o.maxZoom}
              if(info.sFrom==info.from){info.sTo=info.sFrom+o.maxZoom}
            }
            o.onChange("guideZoom",{fromVal:fromVal,toVal:toVal})
          }
        }else{// main graph
          o.getFromTo(tr)
        }// calculate from and to, and send to select graph
        o.doZoom2(tr)
        if(o.svgx){o.svgx.call(o.xAxis.scale(tr.rescaleX(o.x)))}// rescale x axis
        if(o.svgxg){o.svgxg.call(o.xAxisGrid.scale(tr.rescaleX(o.x)))}// rescale x axis
      }
    },
    
    makeSvg2:(svgDiv)=>{
//       cl(o)
      var svg = d3.select(svgDiv).append("svg")
      .attr("viewBox",[0,0,
            o.config.width + o.config.margin.left + o.config.margin.right,
            o.config.height + o.config.margin.top + o.config.margin.bottom])
      o.defs=svg.append("defs")
      o.svg2=svg// main svg
        .append("g")
        .attr("transform", "translate(" + o.config.margin.left + "," + o.config.margin.top + ")")
//         cl(o.config.width,o.config.height)
//       cl(o.config.dark)
      o.svg2// graphing area
        .append("rect")
        .attr("width", o.config.width)
        .attr("height", o.config.height)
        .attr("fill",(o.config.dark)?"black":"white")
     if (this.props.graph.divId != "event-timeline") {
      o.cpRect=svg.append("clipPath")// clipping
        .attr("id","myclip")
      o.cpRect// rect to to clip to
        .append("rect")
        .attr("width",o.config.width)
        .attr("height",o.config.height)
        .attr("fill","white")
     }
//       cl("make svg2")
      o.zoom=d3.zoom().on('zoom', o.doZoom)// zoom function
      o.svg2.call(o.zoom)// assign behavior
      o.clipG= o.svg2.append("g")// clipping group
      if (this.props.graph.divId != "event-timeline") o.clipG.attr("clip-path","url(#myclip)")
      o.graphParent=o.clipG.append("g")
      o.shadeParent=o.graphParent.append("g")
      o.pathsParent=o.graphParent.append("g")
      o.dotsParent=o.graphParent.append("g")
      if(info.showSelect){
        o.shadeParent
          .attr("clip-path","url(#boxClip)")
        o.makeHandleGradient()
      }
        return svg
    },

    makeSvg:(svgDiv)=>{
//       cl(svgDiv)
      o.setConfig()
      o.svg=o.makeSvg2(svgDiv)
//       o.addXAxis(o.svg2)
//       o.addXGridlines()
//       o.addYAxes(o.svg2)
//       o.addPaths()
      this.props.graph.sensors.forEach((s,i)=>{o.addGradients(s,i)})
//       o.addShades()
      
      if(info.showSelect){o.addBox(o.svg2)}
//       cl(info)
      o.setBox()
//       o.dragHandler(o.svg2)
      
    },
  }
  
  o.makeSvg(info.svg)
  return o
  
}// the end of graph01


  render() {
//     cl(`Graph ${this.props.graph.divId}: ${this.props.graph.from} to ${this.props.graph.to}`)
//     cl(`Render ${this.props.graph.divId}`)
//     cl(this.props)
//     cl([this.props.graph.from,this.graph?.dispFrom])
    
    
//     let gr=this.graph
// //     cl(this.graph)
//     if(this.graph){
//       if(gr.dispFrom && gr.dispTo){
//         let pg=this.props.graph
// //         cl(pg.sensors)
// //         cl(Object.keys(gr.allData.sensors))
// //         cl(Object.keys(gr.allData.sensors).length,pg.sensors.length)
//         if((pg.from!=gr.dispFrom)||(pg.to!=gr.dispTo)/*||(Object.keys(gr.allData.sensors).length!=pg.sensors.length)*/){
//           cl("redraw")
//           this.graph.getRealData(pg.from,pg.to)
//         }
//       }
//     }
    
    
//     cl("render")
//     cl(this.props.graph.ref.current)
//  ref={this.svgDiv}
     //style={{width: 400, height: 400}}
//           style={{
//             
//             width:"100%",
//              height: 200,
//              marginTop:32,
//              marginBottom:1,
// //              backgroundColor:"#DDDDDD",
//           }}
//         style={{backgroundColor: "white"}}
    if(this.props.graph.divId=="guide-graph"){
      return(
        <div 
          id={this.props.graph.divId} 
          style={{height:100}}
          ref={this.svgDiv}
        />
      )
    }else if (this.props.graph.divId=="event-timeline"){
       return(
        <div 
          id={this.props.graph.divId} 
          style={{height:50}}
          ref={this.svgDiv}
        />
      ) 
    }
    else {
      return(
        <div id={this.props.graph.divId} ref={this.svgDiv}
          style={{height:400}}
        />
      )
    }
  }
}

export default Graph01;
