import React from 'react';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import C18MuiSelect from '../../usa/components/C18MuiSelect'

import {cl, globs, lerpColor} from '../../components/utils/utils';

class Select18 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     this.state={}
    // let getVal = (this.props.custom.type === "master")
    //   ? {type: "array", count: 3} : {type: "scalar"}
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
//     switch(props.custom.type){
//       case "setpoint":
//         this.state.setpoint=0
//         break
//       default:
//         break
//     }
  }

  updateFui=()=>{
    if(!this.changed){
    this.props.getValue(this.props.ind, {type: "scalar"})
    }
  }
  
  savePageEvent=()=>{
    this.changed=false
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  
  ecph = [
    {t: "None", v: 0},//0
    {t: "EC/pH Sensor Transmitter Board 1", v: 1},//1
    {t: "EC/pH Sensor Transmitter Board 2", v: 2},//2
    {t: "EC/pH Sensor Transmitter Board 3", v: 3},//3
    {t: "EC/pH Sensor Transmitter Board 4", v: 4},//4
    {t: "EC/pH Sensor Transmitter Board 5", v: 5},//5
    {t: "EC/pH Sensor Transmitter Board 6", v: 6},//6
    {t: "EC/pH Sensor Transmitter Board 7", v: 7},//7
    {t: "EC/pH Sensor Transmitter Board 8", v: 8},//8
    {t: "EC/pH Sensor Transmitter Board 9", v: 9},//9
    {t: "EC/pH Sensor Transmitter Board 10", v: 10},//10
    {t: "EC/pH Sensor Transmitter Board 11", v: 11},//11
    {t: "EC/pH Sensor Transmitter Board 12", v: 12},//12
    {t: "EC/pH Sensor Transmitter Board 13", v: 13},//13
    {t: "EC/pH Sensor Transmitter Board 14", v: 14},//14
    {t: "EC/pH Sensor Transmitter Board 15", v: 15},//15
    {t: "EC/pH Sensor Transmitter Board 16", v: 16},//16
    {t: "EC/pH Sensor Transmitter Board 17", v: 17},//17
    {t: "EC/pH Sensor Transmitter Board 18", v: 18},//18
    {t: "EC/pH Sensor Transmitter Board 19", v: 19},//19
    {t: "EC/pH Sensor Transmitter Board 20", v: 20},//20
    {t: "EC/pH Sensor Transmitter Board 21", v: 21},//21
    {t: "EC/pH Sensor Transmitter Board 22", v: 22},//22
    {t: "EC/pH Sensor Transmitter Board 23", v: 23},//23
    {t: "EC/pH Sensor Transmitter Board 24", v: 24},//24
    {t: "Analog Input 1", v: 27},//21
    {t: "Analog Input 2", v: 28},//22
    {t: "Analog Input 3", v: 29},//23
    {t: "Analog Input 4", v: 30},//24
    {t: "Analog Input 5", v: 31},//24
    {t: "Software", v: 46},//24
  ]
  mapping = [
    {t: "None", v: 0},//0
    {t: "Serial Sensor 1", v: 1},//1
    {t: "Serial Sensor 2", v: 2},//2
    {t: "Analog Input 1", v: 3},//3
    {t: "Analog Input 2", v: 4},//4
    {t: "Analog Input 3", v: 5},//5
    {t: "Analog Input 4", v: 6},//6
    {t: "Analog Input 5", v: 7},//7
    {t: "Analog Input 6", v: 8},//8
    {t: "Analog Input 7", v: 9},//9
    {t: "Analog Input 8", v: 10},//10
    {t: "Fast Digital Input 1", v: 11},//11
    {t: "Fast Digital Input 2", v: 12},//12
    {t: "Fast Digital Input 3", v: 13},//13
    {t: "Digital Input 1", v: 14},//14
    {t: "Digital Input 2", v: 15},//15
    {t: "Digital Input 3", v: 16},//16
    {t: "Digital Input 4", v: 17},//17
    {t: "Digital Input 5", v: 18},//18
    {t: "Digital Input 6", v: 19},//19
    {t: "Digital Input 7", v: 20},//20
    {t: "Digital Input 8", v: 21},//21
    {t: "Software", v: 22},//22
    {t: "Remote", v: 23},//23
    {t: "Expansion Analog Input 1", v: 24},//24
    {t: "Expansion Analog Input 2", v: 25},//25
    {t: "Sensor Module", v: 26},//26
    {t: "Sensor Module Backup", v: 27},//27
    {t: "Zone 1", v: 100},//28
    {t: "Zone 2", v: 101},//29
    {t: "Zone 3", v: 102},//30
    {t: "Zone 4", v: 103},//31
    {t: "Zone 5", v: 104},//32
    {t: "Zone 6", v: 105},//33
    {t: "Zone 7", v: 106},//34
    {t: "Zone 8", v: 107},//35
    {t: "Zone 9", v: 108},//36
    {t: "Zone 10", v: 109},//37
    {t: "Zone 11", v: 110},//38
    {t: "Zone 12", v: 111},//39
    {t: "Zone 13", v: 112},//40
    {t: "Zone 14", v: 113},//41
    {t: "Zone 15", v: 114},//42
    {t: "Zone 16", v: 115},//43
    {t: "Zone 17", v: 116},//44
    {t: "Zone 18", v: 117},//45
    {t: "Zone 19", v: 118},//46
    {t: "Zone 20", v: 119},//47
    {t: "Zone 21", v: 120},//48
    {t: "Zone 22", v: 121},//49
    {t: "Zone 23", v: 122},//50
    {t: "Zone 24", v: 123},//51
    {t: "Zone 25", v: 124},//52
    {t: "Zone 26", v: 125},//53
    {t: "Zone 27", v: 126},//54
    {t: "Zone 28", v: 127},//55
    {t: "Zone 29", v: 128},//56
    {t: "Zone 30", v: 129},//57
    {t: "Zone 31", v: 130},//58
    {t: "Zone 32", v: 131},//59
    {v2: 1, v: -1, t: "None"},// 60
    {v2: 1, v: 0, t: "Inside Temperature"},// 61
    {v2: 5, v: 1, t: "Outside Temperature"},// 62
    {v2: 2, v: 2, t: "Relative Humidity"},// 63
    {v2: 7, v: 3, t: "CO2"},// 64
    {v2: 8, v: 4, t: "Outside Light"},// 65
    {v2: 9, v: 5, t: "Wind Speed"},// 66
    {v2: 12, v: 6, t: "Analog Temperature 1"},// 67
    {v2: 13, v: 7, t: "Analog Temperature 2"},// 68
    {v2: 14, v: 8, t: "Analog Temperature 3"},// 69
    {v2: 15, v: 9, t: "Analog Temperature 4"},// 70
    {v2: 16, v: 10, t: "Analog Temperature 5"},// 71
    {v2: null, v: 11, t: "Fallback Temperature"},// 72
    {v2: 10, v: 12, t: "Wind Direction"},// 73
    {v2: 17, v: 13, t: "Soil Moisture 1"},// 74
    {v2: 18, v: 14, t: "Soil Moisture 2"},// 75
    {v2: 19, v: 15, t: "Soil Moisture 3"},// 76
    {v2: 20, v: 16, t: "Soil Moisture 4"},// 77
    {v2: 21, v: 17, t: "Soil Moisture 5"},// 78
    {v2: 11, v: 18, t: "Rain"},// 79
    {v2: null, v: 19, t: "Irrigation Trigger"},// 80
    {v2: 125, v: 20, t: "Differential Pressure"},// 81
    {v2: 124, v: 21, t: "Inside Light"},// 82
    {v2: 22, v: 32, t: "Vent Position Sensor 1"},// 83
    {v2: 23, v: 33, t: "Vent Position Sensor 2"},// 84
    {v2: 24, v: 34, t: "Vent Position Sensor 3"},// 85
    {v2: 25, v: 35, t: "Vent Position Sensor 4"},// 86
    {v2: 26, v: 36, t: "Vent Position Sensor 5"},// 87
    {v2: 27, v: 37, t: "EC/pH 1"},// 88
    {v2: 28, v: 38, t: "EC/pH 2"},// 89
    {v2: 29, v: 39, t: "EC/pH 3"},// 90
    {v2: 30, v: 40, t: "EC/pH 4"},// 91
    {v2: 31, v: 41, t: "EC/pH 5"},// 92
    {v2: 37, v: 42, t: "Generic Sensor 1"},// 93
    {v2: 38, v: 43, t: "Generic Sensor 2"},// 94
    {v2: 6, v: 44, t: "Outside Humidity"},// 95
    {v2: 3, v: 45, t: "Local Temperature"},// 96
    {v2: 4, v: 46, t: "Local Humidity"},// 97
    {v2: 126, v: 47, t: "Snow"},// 98
    {v2: 88, v: 48, t: "Black Plate Temp."},// 99
    {v2: 90, v: 49, t: "Barometric Pressure"},// 100
    {v2: null, v: 64, t: "None"},// 101
    {v2: null, v: 75, t: "None"},// 102
    {v2: 129, v: 75, t: "Accumulator 1"},// 103
    {v2: 131, v: 76, t: "Accumulator 2"},// 104
  ];

  stages = [
    {t: "Heat 6", v: 0},//0
    {t: "Heat 5", v: 1},//1
    {t: "Heat 4", v: 2},//2
    {t: "Heat 3", v: 3},//3
    {t: "Heat 2", v: 4},//4
    {t: "Heat 1", v: 5},//5
    {t: "Normal", v: 6},//6
    {t: "Cool 1", v: 7},//7
    {t: "Cool 2", v: 8},//8
    {t: "Cool 3", v: 9},//9
    {t: "Cool 4", v: 10},//10
    {t: "Cool 5", v: 11},//11
    {t: "Cool 6", v: 12},//12
  ];
  
  onChange=(e)=>{
    this.changed=true
    this.props.onChange(this.props.ind,
        {value: e.target.value})
  }
// astro, stages, tanks, trigger, inject, probe,
// ectype, concurrent, override, temp, windspd,
// light, volume, nutrient

  // getChannelName=(c)=>{
  //
  // }
  //
  // getMasterOptions=()=>{
  //   cl(this.props.current.masters)
  //
  //   return [];
  // }

  getStagesOptions=()=>{
    // cl(this.props)
    // return [];
//     cl(this.props)
//     cl(this.props.current)
    let min = 6 - this.props.current.heatStages*1;
    let max = 6 + this.props.current.coolStages*1;
    // cl(max)
    let ret = [];
    this.min = min
    for (let i = min ; i <= max ; i++){
      ret.push(this.stages[i])
      // cl(ret)
    }
//     cl(ret)
    return ret;
  }

  getECPHMappingOptions=()=>{
    let ecphMaps = {
      ecphSensor: [0, 1, 2, 3, 4, 5, 6, 7, 8,
        9, 10, 11, 12, 13, 14, 15, 16, 17,
        18, 19, 20, 21, 22, 23,24],
      tempSensor: [0, 1, 2, 3, 4, 5, 6, 7, 8,
        9, 10, 11, 12, 13, 14, 15, 16, 17,
        18, 19, 20, 21, 22, 23, 24,25,26,27,28,29,30],
    };
    let ret = [];
    try{
      ecphMaps[this.props.custom.mapType].map(ind=>{
        ret.push(this.ecph[ind])
      });
    }catch{}
    // cl(ret)
    return ret;
  };

  getMappingOptions=()=>{
// intemp, inhum, inlight, inco2, diffpress,
// outtemp, blackplate, outhum, outlight, windspd,
// winddir, rain, snow, barometer

    let mappingMaps = {
      intemp: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 26,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
        58, 59],
      inhum: [0, 1, 2, 22, 23, 26,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
        58, 59],
      inlight: [0, 3, 4, 5, 6, 7, 10, 22, 23, 26],
      inco2: [0, 8, 22, 23, 26],
      diffpress: [0, 22, 23, 26],
      outtemp: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23],
      blackplate: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23],
      outhum: [0, 1, 2, 22, 23],
      outlight: [0, 3, 4, 5, 6, 7, 10, 22, 23, 26],
      windspd: [0, 11, 12, 13, 22, 23],
      winddir: [0, 9, 22, 23],
      rain: [0, 11, 12, 13, 22, 23],
      snow: [0, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      barometer: [0, 3, 4, 5, 6, 7, 22, 23],
      baropressure: [0, 3, 4, 5, 6, 7, 22],
      irrigtrigger: [0, 14, 15, 16, 17, 18, 19, 20, 21],
      accum: [102, 61, 62, 99, 63, 95, 64, 65, 82, 66,
        67, 68, 69, 70, 71, 81, 93, 94],
      antemp: [0, 3, 4, 5, 6, 7, 22, 23],
      genmap: [0, 3, 4, 5, 6, 7, 22, 24, 25],
      irrmap: [0, 3, 4, 5, 6, 7, 22],
      genunits: [105, 106, 107, 108, 109, 110, 111, 112,
        113, 114, 115, 116, 117, 118, 119, 120],
      netmap: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 26],
      netmaphumid: [0, 1, 2, 22, 23, 26],
      ventmap: [0, 3, 4, 5, 6, 7, 22, 23],
      fallback: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23],
      influence: [63, 61],
      moisture: [13, 14, 15, 16, 17],
      light: [65, 82],
      altsensor: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23],
    }
    // cl(mappingMaps[this.props.custom.mapType])
    let ret = [];
    try{
      mappingMaps[this.props.custom.mapType].map(ind=>{
        ret.push(this.mapping[ind])
      });
    }catch{}
    // cl(ret)
    return ret;
  }

  getTanksOptions=()=>{
    // cl(this.props)
    let ret = [{v:255,t:"None"}];
    this.props.current.tankNames.forEach((n, i)=>{
      if (n === undefined){
        n = "Mixing Tank " + (i + 1);
      }
      ret.push(
          {t: n, v: i},//47
      );
    })
    return ret

  }

  makeSelect=()=>{
    let options = {
      astro: [
        {v: 0, t: "No Adjust"},
        {v: 1, t: "Sunrise"},
        {v: 2, t: "Sunset"},
      ],
      trigger: [
        {v: 0, t: "Trigger 1"},
        {v: 1, t: "Trigger 2"},
        {v: 2, t: "Trigger 3"},
        {v: 3, t: "All Triggers"},
      ],
      inject: [
        {v: 0, t: "Volume"},
        {v: 1, t: "Time"},
      ],
      probe: [
        {v: 0, t: "EC"},
        {v: 1, t: "pH"},
      ],
      ectype: [
        {v: 0, t: "Auto"},
        {v: 1, t: "5uS"},
        {v: 2, t: "50uS"},
        {v: 3, t: "500uS"},
        {v: 4, t: "5000uS"},
      ],
      concurrent: [
        {v: 0, t: "Sequential"},
        {v: 1, t: "Concurrent"},
      ],
      override: [
        {v: 0, t: "Daily"},
        {v: 1, t: "One Time"},
      ],
// temp, windspd, light, volume, nutrient,
      temp: [
        {v: 0, t: "Fahrenheit"},
        {v: 1, t: "Celsius"},
      ],
      windspd: [
        {v: 0, t: "Miles per Hour (mph)"},
        {v: 1, t: "Kilometer per Hour (kph)"},
      ],
      light: [
        {v: 0, t: "Watts per Square Meter (w/m2)"},
        {v: 1, t: "Kilolux (klux)"},
        {v: 2, t: "Micro Moles (uMol)"},
      ],
      volume: [
        {v: 0, t: "Liters and Milliliters (L/ml)"},
        {v: 1, t: "Gallons and Ounces (gal/oz)"},
        {v: 2, t: "Gallons and Milliliters (gal/ml)"},
      ],
      winddir: [
        {v: 0, t: "+0.0"},
        {v: 1, t: "+22.5"},
        {v: 2, t: "+45.0"},
        {v: 3, t: "+67.5"},
        {v: 4, t: "+90.0"},
        {v: 5, t: "+112.5"},
        {v: 6, t: "+135.5"},
        {v: 7, t: "+157.5"},
        {v: 8, t: "+180.0"},
        {v: 9, t: "-157.5"},
        {v: 10, t: "-135.0"},
        {v: 11, t: "-112.5"},
        {v: 12, t: "-90.0"},
        {v: 13, t: "-67.5"},
        {v: 14, t: "-45.0"},
        {v: 15, t: "-22.5"},
      ],
      nutrient: [
        {v: 0, t: "Electro-Conductivity (uS)"},
        {v: 1, t: "Conductivity Factor (CF)"},
        {v: 2, t: "Total Disolved Solid (PPM)"},
      ],
      tempcomp: [
        {v: 0, t: "Manual Compensation"},
        {v: 1, t: "Automatic with Sensor"},
      ],
      period: [
        {v: 0, t: "24 Hour"},
        {v: 1, t: "Setpoint Change"},
      ],
      genrange: [
        {v: 0, t:  "-99999 to 999999"},
        {v: 1, t:  "-999.9 to 9999.9"},
        {v: 2, t:  "-99.99 to 999.99"},
        {v: 3, t:  "-9.999 to 99.999"},
        {v: 4, t:  "-.9999 to 9.9999"},
      ],
      genunits: [
        {v: 0, t2: "unit", t: "Units"},// 105
        {v: 1, t2: "%", t: "Percent (%)"},// 106
        {v: 2, t2: "F", t: "Fahrenheit (F)"}, // 107
        {v: 3, t2: "C", t: "Celsius (C)"},// 108
        {v: 4, t2: "klux", t: "Kilolux (klux)"},// 109
        {v: 5, t2: "W/m", t: "Watts per meter squared (W/m)"},// 110
        {v: 6, t2: "km/h", t: "Kilometers per Hour (km/h)"},// 111
        {v: 7, t2: "mph", t: "Miles per Hour (mph)"},// 112
        {v: 8, t2: "PPM", t: "Parts per Million (PPM)"},// 113
        {v: 9, t2: "hPa", t: "Hectopascal (hPa)"},// 114
        {v: 10, t2: "kPa", t: "Kilopascal (kPa)"},// 115
        {v: 11, t2: "inHg", t: "Inches Mercury (inHg)"},// 116
        {v: 12, t2: "inH2O", t: "Inches H2O (inH2O)"},// 117
        {v: 13, t2: "V", t: "Volts (V)"},// 118
        {v: 14, t2: "mA", t: "Milliamperes (mA)"},// 119
        {v: 15, t2: "uMol", t: "Micro mol (uMol)"},// 120
      ],
      gensamples: [
        {v: 1, t: "1"},
        {v: 2, t: "2"},
        {v: 4, t: "4"},
        {v: 6, t: "6"},
        {v: 8, t: "8"},
        {v: 10, t: "10"},
        {v: 12, t: "12"},
        {v: 14, t: "14"},
        {v: 16, t: "16"},
        {v: 18, t: "18"},
        {v: 20, t: "20"},
        {v: 22, t: "22"},
        {v: 24, t: "24"},
        {v: 26, t: "26"},
        {v: 28, t: "28"},
        {v: 30, t: "30"},
        {v: 32, t: "32"},
        {v: 34, t: "34"},
        {v: 36, t: "36"},
        {v: 38, t: "38"},
        {v: 40, t: "40"},
        {v: 42, t: "42"},
        {v: 44, t: "44"},
        {v: 46, t: "46"},
        {v: 48, t: "48"},
        {v: 50, t: "50"},
        {v: 52, t: "52"},
        {v: 54, t: "54"},
        {v: 56, t: "56"},
        {v: 58, t: "58"},
        {v: 60, t: "60"},
        {v: 62, t: "62"},
        {v: 64, t: "64"},
      ],
      pidmults: [
        {v: 0, t:  "1000x"},
        {v: 1, t:  "100x"},
        {v: 2, t:  "10x"},
        {v: 3, t:  "1x"},
        {v: 4, t:  "0.1x"},
        {v: 5, t:  "0.01x"},
        {v: 6, t:  "0.001x"},
      ],
      ecphcalib: [
        {v: 0, t:  "No Calibration"},
        {v: 1, t:  "Single Point Calibration"},
        {v: 2, t:  "Double Point Calibration"},
      ],
      stagesCnt: [
            {v: 1, t: 1},
            {v: 2, t: 2},
            {v: 3, t: 3},
            {v: 4, t: 4},
            {v: 5, t: 5},
            {v: 6, t: 6},
      ],
      yesno: [
            {v: 0, t: "No"},
            {v: 1, t: "Yes"},
      ],
      ns: [
            {v: 0, t: "North"},
            {v: 1, t: "South"},
      ],
      ew: [
            {v: 0, t: "East"},
            {v: 1, t: "West"},
      ],
      canmap:[
        {v: 0, t:  "Temp 1"},
        {v: 1, t:  "Temp 2"},
        {v: 2, t:  "Temp 3"},
        {v: 3, t:  "Temp 4"},
        {v: 4, t:  "Temp 5"},
      ],
    setpoints:[
      {t: "Setpoint 1", v: 0},//0
      {t: "Setpoint 2", v: 1},//0
      {t: "Setpoint 3", v: 2},//0
      {t: "Setpoint 4", v: 3},//0
      {t: "Setpoint 5", v: 4},//0
      {t: "Setpoint 6", v: 5},//0
      {t: "Setpoint 7", v: 6},//0
      {t: "Setpoint 8", v: 7},//0
    ],
    inputs:[
      {"v":128,"t":"Inside Temperature"},
      {"v":129,"t":"Inside Temperature2"},
      {"v":130,"t":"Canopy Temperature"},
      {"v":131,"t":"Inside Humidity"},
      {"v":132,"t":"Inside Light"},
      {"v":133,"t":"Inside CO2"},
      {"v":134,"t":"Outside Temperature"},
      {"v":135,"t":"Outside Temperature2"},
      {"v":136,"t":"Black Plate Temperature"},
      {"v":137,"t":"Local Temperature"},
      {"v":138,"t":"Outside Humidity"},
      {"v":139,"t":"Local Humidity"},
      {"v":140,"t":"Outside Light"},
      {"v":141,"t":"Wind Speed"},
      {"v":142,"t":"Wind Direction"},
      {"v":143,"t":"Rain"},
      {"v":144,"t":"Snow"},
      {"v":145,"t":"Differential Pressure"},
      {"v":146,"t":"Barometric Pressure"},
      {"v":147,"t":"Analog Temperature 1"},
      {"v":148,"t":"Analog Temperature 2"},
      {"v":149,"t":"Analog Temperature 3"},
      {"v":150,"t":"Analog Temperature 4"},
      {"v":151,"t":"Analog Temperature 5"},
      {"v":152,"t":"Vent Position 1"},
      {"v":153,"t":"Vent Position 2"},
      {"v":154,"t":"Vent Position 3"},
      {"v":155,"t":"Vent Position 4"},
      {"v":156,"t":"Vent Position 5"},
      {"v":157,"t":"Soil Moisture 1"},
      {"v":158,"t":"Soil Moisture 2"},
      {"v":159,"t":"Soil Moisture 3"},
      {"v":160,"t":"Soil Moisture 4"},
      {"v":161,"t":"Soil Moisture 5"},
      {"v":162,"t":"Accumulated VPD"},
      {"v":163,"t":"Vapor Pressure Deficit"},
      {"v":164,"t":"EC Tank 1"},
      {"v":165,"t":"pH Tank 1"},
      {"v":166,"t":"Temperature Tank 1"},
      {"v":167,"t":"EC Tank 2"},
      {"v":168,"t":"pH Tank 2"},
      {"v":169,"t":"Temperature Tank 2"},
      {"v":170,"t":"EC Tank 3"},
      {"v":171,"t":"pH Tank 3"},
      {"v":172,"t":"Temperature Tank 3"},
      {"v":173,"t":"EC Tank 4"},
      {"v":174,"t":"pH Tank 4"},
      {"v":175,"t":"Temperature Tank 4"},
      {"v":176,"t":"EC Tank 5"},
      {"v":177,"t":"pH Tank 5"},
      {"v":178,"t":"Temperature Tank 5"},
      {"v":179,"t":"EC Tank 6"},
      {"v":180,"t":"pH Tank 6"},
      {"v":181,"t":"Temperature Tank 6"},
      {"v":182,"t":"EC Tank 7"},
      {"v":183,"t":"pH Tank 7"},
      {"v":184,"t":"Temperature Tank 7"},
      {"v":185,"t":"EC Tank 8"},
      {"v":186,"t":"pH Tank 8"},
      {"v":187,"t":"Temperature Tank 8"}],
  }
    let customs = {
      mapping: this.getMappingOptions,
      stages: this.getStagesOptions,
      tanks: this.getTanksOptions,
      ecph: this.getECPHMappingOptions,
      // master: this.getMasterOptions,
    }
    if (customs [this.props.custom.type] !== undefined){
      options [this.props.custom.type] =
          customs[this.props.custom.type]();
    }
//     cl(this.props.custom)
    // cl(options)
    this.max = options[this.props.custom.type]?.length - 1
    try{
      return (
        options[this.props.custom.type].map((c, i)=>{
          cl([c.v, this.props.value])
          if (c.v == this.props.value) this.curr = i
          return(
            <C18MuiMenuItem key={i} value={c.v}>{c.t}</C18MuiMenuItem>
          )
        })
      )
    }catch{
      cl("In Options, there is no type of " +
          this.props.custom.type)
    }
  }

  getColor=()=>{
    cl([this.props.value, this.max, this.curr])
      let startCol
      let endCol
      let colors
      switch (this.props.custom.type) {
          // blue to red
          case "stages":
            colors=["#eb5555","#db5555","#cb5555","#bb5555","#ab5555","#ab7855",
              "#ababab","#55ab55","#55ab78","#55ab9a","#559aab","#5588cb","#5570eb"]
            return colors[this.min + this.curr]
          // clear to orange
          case "astro":
            colors=["", "#f5c000", "#c082d1"]
            return colors[this.curr]
          default:
              startCol = [58, 130, 247, 0]
              endCol = [58, 130, 247, 1]
              break
      }
      return lerpColor(startCol, endCol, (this.curr / Math.abs(this.max)))
  }


  render(){
    // cl(this.props);
    let pr=this.props
    let select = this.makeSelect()
    let bgColor = this.getColor()
    cl(bgColor)
    return(
        <div id={pr?.custom?.infoId}>
          <C18MuiFormControl
              style={{minWidth: 150}}
          >
            <label>{pr.cont.title}</label>
            <C18MuiSelect
                id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
                value={pr.value}
                onChange={this.onChange}
                saveOK={pr.saveOK}
                unit={pr.custom.unit}
                bgColor={bgColor}
            >
              {select}
            </C18MuiSelect>
          </C18MuiFormControl>
        </div>
    );
  }
}

export default Select18 ;
