import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadAccountInfo,loadGatewaysInfo,getGatewayInfo,
  getGatewayIndex, addToAdminLog} from './C18utils'
import {cl, globs, getTime,getTimeI} from '../../components/utils/utils';
import history from "../../history"

const progressSize=300

class C18ManageGateways extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: false,
      addName:"",
      addId:"",
      gatewayIdInfo:"",
      downloadProgress:0,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGateways)
    this.subscribe_toClientEvent=globs.events.subscribe("toClient",this.toClient)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Gateways", url:`/usa/c18/admin/manageGateways2`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
    this.subscribe_toClientEvent.remove()
  }
  
  loadNewAddedSensorGateways=async()=>{
    let query={
        $or:[
          {siteId:""},
          {accountId: globs.userData.session.accountId},
        ],
        gatewayType:"addedSensor"
      }
//     cl(query)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "retrieve2", 
      sessionId: globs.userData.session.sessionId, body: query})
    let gws={}
    resp.data.forEach(re=>{
      gws[re.gatewayId]=re
    })
//     cl(gws)
    return gws
  }
  
  loadInfo=async()=>{
    await loadAccountInfo()
    await loadSitesInfo()
    await loadZonesInfo()
    await loadGatewaysInfo()
    setInterval(e=>{
      loadGatewaysInfo(true)
      this.setState({update:(this.state.update||0)+1})
    },20000)
    await this.loadFirmwares()
    let asGateways=await this.loadNewAddedSensorGateways()
//     cl(asGateways)
    this.saveGatewayNames(false)
//     cl(this.gatewayNames)
//     cl(globs.gatewaysInfo.info)
//     await loadZonesInfo()
//     this.saveZoneNames(false)// save the names to compare later
    let siteSel=(globs.sitesInfo.info[0]||{}).siteId
    let gwInfo=this.getGatewaySel(siteSel)
    var zoneSel
//     cl(gwInfo)
    if(gwInfo){zoneSel=this.getZoneSel(gwInfo.gatewaySel)}
    
//     cl(gatewaySel)
//     Object.assign(vals,{gatewaySel:this.getGatewaySel(vals.siteSel)})
//     let zoneSel=this.getZoneSel(siteSel)
    Object.assign(gwInfo||{},{loaded:true, siteSel:siteSel,zoneSel:zoneSel,
      asGateways:asGateways})
//     cl(gwInfo)
    this.setState({loaded:true, siteSel:siteSel,zoneSel:zoneSel,
      asGateways:asGateways})
//     cl(globs)
  }
  
  mySetState=(vals)=>{
    this.setState(vals)
  }
  
  getGatewaySel=(siteId)=>{
    let ggi=globs.gatewaysInfo.info
    for(let i=0;i<ggi.length;i++){
      if(ggi[i].siteId==siteId){
        let gw=ggi.filter(gw=>{return gw.gatewayId==ggi[i].gatewayId})[0]
//         cl(gw)
        return{gatewaySel:gw.gatewayId,isAddedSensor:gw.gatewayType=="addedSensor"}
//         cl(gw)
//         return ggi[i].gatewayId
      }
    }
  }
  
  getZoneSel=(gatewayId)=>{
//     cl(gatewayId)
    if(!gatewayId){return}
    let gw=(globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==gatewayId})[0])||{}
//     cl(gw)
    if(gw.gatewayType=="addedSensor"){
      return gw.zoneId
    }else{
//       cl(gatewayId)
//       cl(globs.zonesInfo.info)
      return globs.zonesInfo.info.filter(
        zi=>{
          return zi.gatewayId==(((gatewayId[0]||{}).zoneId)||0)
        })
    }
//       return globs.zonesInfo.info.filter(
//         zi=>{
//           return zi.gatewayId==(((gatewayId[0]||{}).zoneId)||0)
//         })    }
  }

  saveToAdminLog=(adds,o,n)=>{
//     cl(o)
//     cl(n)
    let addObj={
      userId:globs.userData.session.userId,
      siteId:o.siteId,
      gatewayId: o.gatewayId,
      time:Math.floor(getTime())
    }
    if (n.type == "addGateway" || n.type == "deleteGateway") {
      adds.push(
        Object.assign({},addObj,
        {
        action: n.type,
        oldVal: "",
        newVal: n.name,
      }))
      return
    }
    if(o.name!=n.name){
      adds.push(
        Object.assign({},addObj,
        {
        action:"gatewayName",
        oldVal:o.name,
        newVal:n.name,
      }))
    }
  }
  
  saveGateways=(cmd)=>{
    cl(cmd)
    if(cmd=="save"){
      this.saveGatewayNames(true)// save to db
    }
//     history.goBack()
  }
  
  saveGatewayNameToDb=async(upd)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", 
      sessionId: globs.userData.session.sessionId, body: upd
//       {
//         gatewayId: id,
//         name: name,
//       }
    })
    globs.events.publish("saveOK",true)
  }
  
  saveGatewayNames=(doSave)=>{// do Save, means save to db, not means copy current settings
    if(!doSave){this.gatewayNames={}}
    let ggi=globs.gatewaysInfo.info
    let adminAdds=[]
    let st=this.state
    for(let i=0;i<ggi.length;i++){
      if(doSave){
        let newZoneId=(ggi[i].gatewayId==st.gatewaySel)&&(ggi[i].zoneId!=st.zoneSel)
        if((this.gatewayNames[ggi[i].gatewayId]!=ggi[i].name)||(newZoneId)){
          let upd={gatewayId:ggi[i].gatewayId, name:this.gatewayNames[ggi[i].gatewayId]}
          if(newZoneId){upd.zoneId=st.zoneSel}
          cl(upd)
          this.saveGatewayNameToDb(upd)
          this.saveToAdminLog(adminAdds,ggi[i],{name: this.gatewayNames[ggi[i].gatewayId]})
          ggi[i].name=this.gatewayNames[ggi[i].gatewayId]
          if(newZoneId){ggi[i].zoneId=st.zoneSel}
        }
      }else{
        this.gatewayNames[ggi[i].gatewayId]=ggi[i].name
      }
    }
//     cl(this.gatewayNames)
//     cl(adminAdds)
    if(doSave){addToAdminLog(adminAdds)}
    globs.events.publish("saveOK",true)
  }
  
  addGateway=(id,name)=>{
    
  }
  
  deleteGateway=async(gw)=>{// this should remove the site and account info from the gateway
    cl(gw)
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this gateway?", buttons:["Cancel","Yes"]})
    
    if(res=="Yes"){
//       cl(this.state.gatewaySel)
//       cl(globs.gatewaysInfo.info)
//       cl(getGatewayIndex(this.state.gatewaySel))
//       cl(globs.gatewaysInfo.info[getGatewayIndex(this.state.gatewaySel)])
      let name = this.gatewayNames[this.state.gatewaySel]
      cl(globs.gatewaysInfo.info.length)
      globs.gatewaysInfo.info.splice(getGatewayIndex(this.state.gatewaySel),1)
//       delete globs.gatewaysInfo.info[getGatewayIndex(this.state.gatewaySel)]
      cl(globs.gatewaysInfo.info.length)
      delete this.gatewayNames[this.state.gatewaySel]
      let gwInfo=this.getGatewaySel(this.state.siteSel)
//       cl(gatewaySel)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", // remove the siteId and name from the gateway
        sessionId: globs.userData.session.sessionId, body: {gatewayId:this.state.gatewaySel, siteId:"", name:""}})
      await wsTrans("usa", {cmd: "cRest", uri: "/s/zones", method: "delete", // remove the siteId and name from the gateway
        sessionId: globs.userData.session.sessionId, body: {gatewayId:this.state.gatewaySel}})
      let adminAdds = []
      this.saveToAdminLog(adminAdds, {siteId: this.state.siteSel,gatewayId:this.state.gatewaySel}, {type: "deleteGateway", name: name})
      cl(adminAdds)
      addToAdminLog(adminAdds)
      this.mySetState(gwInfo)
    }
  }

  updateGateway=async(gw)=>{// this is just used to add a gateway
//     cl(gw)
//     cl(this.state)
//     cl(this.props)
//     cl(globs.gatewaysInfo)
    let gotGW=globs.gatewaysInfo.info.filter(gWay=>{return gWay.gatewayId==gw.gatewayId})[0]
    if(gotGW){return}
//     cl(gotGW)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/gateways", method: "update", 
      sessionId: globs.userData.session.sessionId, body: gw})
    cl(resp)
    if(resp.result=="duplicateKey"){
        let res=await this.props.parms.getPopup({
          text:"That Gateway ID is already in use, on a different account. It was not added.\
 Ask the owner of that account to delete it there, and then you can add it here.", 
          buttons:["OK"]})
        return false
    }
    return true
  }
  
//   checkAddedSensor=(gatewayId)=>{
//     cl(gatewayId)
//     if(this.state.asGateways[gatewayId]){}
//     
//   }

  validateGateway=(vals)=>{
//     if(c=="O"): c="0"
//     if(c=="Q"): c="0"
//     if(c=="I"): c="1"
//     if(c=="L"): c="1"
//     if(c=="S"): c="5"
//     if(c=="Z"): c="2"
//     if(c=="_"): c="A"
//     if(c=="-"): c="B"
    
    var doSimChars=(c)=>{
      let dups="0O0Q1I1L5S2Z"
      let pos=dups.indexOf(c)
      return(pos>=0)?dups[pos&0xFE]:c
    }
    let chars="0123456789ABCDEFGHJKMNPRTUVWXY"
    let gwId=vals.addId.toUpperCase()
    vals.gatewayIdInfo=""
    if(gwId.length&&(gwId.length!=16)){
      vals.gatewayIdInfo="A Gateway ID has 16 Characters"
    }else{
      let tot=0
      vals.gatewayIdInfo=""
      for(let i=0;i<gwId.length;i++){
        let c=doSimChars(gwId[i])
        let pos=chars.indexOf(c)
        if(pos<0){
          vals.gatewayIdInfo="A Gateway ID only has Numbers and Letters"
          return 
        }
        tot+=pos
      }
      if(tot%30){
        vals.gatewayIdInfo="Please Double Check: That's Not a Valid ID"
      }
//       cl(tot)
    }
//     cl(gwId)
    
  }
  
  updatePearlFirmware=(fName)=>{
    cl("update pearl")
    let st=this.state
    wsTrans("usa", {cmd: "cRest", uri: "/s/controller", method: "update", 
      sessionId: globs.userData.session.sessionId, body: 
      {gatewayId:st.gatewaySel,
        cmd:"downloadFirmware",
        fwSel:st.fwSel,
        }})
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    vals.gatewayIdInfo=""
    var gw
    switch(type){
//       case "siteSel":
//         cl(this.getGatewaySel(vals.siteSel))
//         Object.assign(vals,{gatewaySel:(this.getGatewaySel(vals.siteSel)||{}).gatewaySel})
//         Object.assign(vals,{zoneSel:this.getZoneSel(vals.gatewaySel),addName:"",addId:""})
// //         cl(vals)
//         this.mySetState(vals)
//         break
      case "siteSel":
//         cl(this.getGatewaySel(vals.siteSel))
        let gw0=this.getGatewaySel(vals.siteSel)||{}
        gw =globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==gw0.gatewaySel})[0]
//         cl(gw0)
//         let gwSel=gw.gatewaySel
//         cl(gwSel)
        Object.assign(vals,{gatewaySel:gw0.gatewaySel,gatewayType:gw?.gatewayType})
        Object.assign(vals,{zoneSel:this.getZoneSel(vals.gatewaySel),addName:"",addId:""})
        //         cl(vals)
        this.mySetState(vals)
        break
      case "gatewayName":
//         this.setGatewayName(this.state.gatewaySel,vals.gatewayName)
        globs.events.publish("savePageEnable",true)
        this.gatewayNames[this.state.gatewaySel]=vals.gatewayName
        this.mySetState(vals)
        break
      case "gatewayAdd":// handles the text box
//         cl(this.state.asGateways)
        this.validateGateway(vals)
        if(vals.addId){
          vals.isAddedSensor= this.state.asGateways[vals.addId]?true:false
        }
//         cl(vals)
        this.mySetState(vals)
        break
      case "gatewaySel":
        gw =globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==vals.gatewaySel})[0]
        cl(gw)
        Object.assign(vals,{zoneSel:this.getZoneSel(vals.gatewaySel),
          isAddedSensor:gw.gatewayType=="addedSensor",addName:"",addId:"",
          gatewayType:gw.gatewayType
        })
        this.mySetState(vals)
        break
      case "zoneSel":
        globs.events.publish("savePageEnable",true)
        this.mySetState(vals)
        break
      case "addGateway":
        let g={
          accountId: globs.userData.session.accountId,
          siteId: this.state.siteSel,
          gatewayId: this.state.addId,
//           name: this.state.addName,
          name: this.state.addName||"New Gateway",
        }
        if(this.state.isAddedSensor){
          g.zoneId=this.state.zoneSel
          g.gatewayType="addedSensor"
        }
//         cl(g)
//         cl(globs)
        let res=await this.updateGateway(g)
        if(res){
          globs.gatewaysInfo.info.push(g)
          this.gatewayNames[g.gatewayId]=g.name
          Object.assign(vals,{addName:"",addId:"",gatewaySel:this.state.addId})
          let adminAdds = []
          this.saveToAdminLog(adminAdds, g, {type: "addGateway", name: g.name})
          addToAdminLog(adminAdds)
          this.mySetState(vals)
        }
        break
      case "deleteGateway":
        this.deleteGateway(this.state.gatewaySel)
        break
      default:
        break
      case "fwSel":
        this.mySetState(vals)
        break
      case "fwUpdate":
//         cl(vals)
        this.updatePearlFirmware(vals.fwSel)
        break
    }
  }
  
  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }
  
  showZonesOpts=()=>{
    return globs.zonesInfo.info.filter(zi=>{return zi.siteId==this.state.siteSel}).map((z,i)=>{
      return(
        <option key={i} value={z.zoneId}>{z.zoneName}</option>
      )
    })
  }
  
  showGatewayOpts=()=>{
//     cl(globs)
//     cl(this.gatewayNames)
//     cl(globs.gatewaysInfo.info)
    return globs.gatewaysInfo.info.map((g,i)=>{
//       cl(g)
//       cl(this.gatewayNames[g.gatewayid])
      if(g.siteId==this.state.siteSel){
//         cl(g)
        return(
          <option key={i} value={g.gatewayId}>{this.gatewayNames[g.gatewayId]}</option>
        )
      }
    })
  }
  
  showSelectSite=()=>{
    return (
      <div className="custom-select">
        <label htmlFor="">Select Site</label>
        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showSelectZone=()=>{
    if(this.state.isAddedSensor){
      return (
        <>
          <div className="custom-select">
            <label htmlFor="">Select Zone</label>
            <C18Select00 id=""
              value={this.state.zoneSel}
              onChange={e=>this.onChange("zoneSel",{zoneSel: e.currentTarget.value})}
            >
              {this.showZonesOpts()}
            </C18Select00>
            {true&&
              <span className="material-icons down-arrow">
                keyboard_arrow_down
              </span>
            }
          </div> 
        </>
      )
    }
  }
  
  showSelectGateway=()=>{
    let isOwner=globs.userData.session.userId==globs.accountInfo.info.owner
    return (
      <div>
        <div className="custom-select">
          <label htmlFor="">Select Gateway</label>
          <C18Select00 id=""
            parms={{list:true}}
            value={this.state.gatewaySel}
            onChange={e=>this.onChange("gatewaySel",{gatewaySel: e.currentTarget.value})}
          >
            {this.showGatewayOpts()}
          </C18Select00>
          {false&&
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          }
        </div>
        {isOwner&&
          <div>
            <C18Button00 type="button" className="danger" onClick={e=>this.onChange("deleteGateway")}>Delete</C18Button00>
          </div>
        }
       </div>
    )
  }
  showGatewayIdInfo=()=>{
    let st=this.state
    return(
      <div>
      {st.gatewayIdInfo}
      </div>
    )
  }
  
  loadFirmwares=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/dirList", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body:{
        type:"fwImages",
      }
    })
//     cl(res)
    this.firmwares=res.data
  }
  
  showFirmwareOpts=()=>{
    return this.firmwares.map((im,i)=>{
      return(
        <option key={i} value={im}>{im}</option>
      )
    })
  }
  
  toClient=(obj)=>{
//     cl(obj)
    let st=this.state
    let da=obj.data
    var prog
    if(da.index>=0){
      prog=progressSize*(da.index+1)/da.cnt
    }else{
      prog=0-st.downloadProgress
    }
    this.setState({downloadProgress:prog})
  }
  
  showFwProgress=()=>{
    let st=this.state
    if(st.downloadProgress){
      if(st.downloadProgress==progressSize){
        return(
          <tr><td colSpan="2">
            <h3>Success!</h3>
          </td></tr>
        )
      }else{
        let result=(st.downloadProgress>=0)?"":"Failed"
        return(
          <tr><td colSpan="2">
          <br/>
            <label>Download Progress:</label>
            <div style={{width:progressSize,borderStyle:"solid",
              borderWidth:1,borderRadius:10
            }}>
              <div style={{width:Math.abs(st.downloadProgress),
               height:20,
               backgroundColor:(st.downloadProgress>=0)?"#88FF88":"#FF8888",
               borderRadius:10,textAlign:"center"}}>
               {result}
              </div>
            <div>
            </div>
            </div>
          </td></tr>
        )
      }
    }
  }
  
  render(){
//     cl(this.state.zoneSel)
    let st=this.state
//     cl(st)
    if(st.loaded){
//       let gatewayInfo=getGatewayInfo(this.state.gatewaySel)
      let name=this.gatewayNames[st.gatewaySel]
      let addDisable=(st.addId=="")// ||(st.gatewayIdInfo)
      let fwUpdateDisable=(!st.fwSel)
      let gw=(globs.gatewaysInfo.info.filter(gw=>{return gw.gatewayId==st.gatewaySel})[0])||{}
      var lastContact,lcTime
//       cl(gw)

      if(gw.updateTime){
        lcTime=getTimeI()-gw.updateTime
        lastContact=<div>{`Last Contact: ${lcTime} seconds ago`}</div>
      }
//       cl(gw)
      //       cl(name)
//       cl(gatewayInfo)
//       let zoneInfo=getZoneInfo(st.zoneSel)
    return(
      <div>
        {this.showSelectSite()}
        <p>Site ID: {st.siteSel}</p>
        {this.showSelectGateway()}
        <div>Gateway ID: {st.gatewaySel}</div>
        {lastContact}
        <div className="clearfloat"/><br/>

        <label htmlFor="gateway-name">Gateway Name</label>
        <C18Input00 type="text" id="gateway-name" 
          value={name||""}
          onChange={e=>this.onChange("gatewayName",{gatewayName:e.currentTarget.value})}
        />
        {((st.gatewayType==1900)&&(this.firmwares.length>0)&&(lcTime<200))&&
          <div>
            <p>Update Pearl Firmware</p>
            <table style={{width:"auto"}}><tbody>
            <tr><td>
              <label htmlFor="gateway-addid">Select Firmware</label>
            </td><td></td></tr>
            <tr>
            <td>
              <div className="custom-select" style={{marginBottom:0}}>
                <C18Select00 id=""
                  parms={{list:true}}
                  value={st.fwSel}
                  onChange={e=>this.onChange("fwSel",{fwSel: e.currentTarget.value})}
                >
                  {this.showFirmwareOpts()}
                </C18Select00>
              </div>
            </td>
            <td>
              <C18Button00 type="button" className="filled" disabled={fwUpdateDisable}
              onClick={()=>this.onChange("fwUpdate",{fwSel:st.fwSel})}>Update</C18Button00>
            </td>
            </tr>
            {this.showFwProgress()}
            </tbody></table>
            <div className="clearfloat"/><br/>        
          </div>
        }

        
        <p>Add Gateway</p>
        {false&&
          <>
            <label htmlFor="gateway-addname">Gateway Name</label>
            <C18Input00 type="text" id="gateway-addname" 
              value={this.state.addName}
              onChange={e=>this.onChange("gatewayAdd",{addName:e.currentTarget.value})}
            />
          </>
        }
        <label htmlFor="gateway-addid">Gateway ID</label>
        <C18Input00 type="text" id="gateway-addid" className="with-right-button"
          value={this.state.addId}
          onChange={e=>this.onChange("gatewayAdd",{addId:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled" disabled={addDisable}
        onClick={()=>this.onChange("addGateway",{gatewayName:this.state.addName})}>Add</C18Button00>
        <div className="clearfloat"/>
        {this.showGatewayIdInfo()}
        {this.showSelectZone()}
      </div>
    )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageGateways;
