import React from 'react';
import {Link} from 'react-router-dom'
// import C18Anchor00 from './C18Anchor00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'

// import ReactHtmlParser from 'react-html-parser'
// import C18SubMenuHeader00 from './C18SubMenuHeader00'
import {wsTrans,sensorIds,getUId,doGetPostBasic} from '../utils/utils'
import {cl,globs,constant,dateToDisplayDate,getPWString} from '../../components/utils/utils';

class C18TechPortal00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      accountSearch:"",
      userSearch:"",
      siteSearch:"",
      gatewaySearch:"",
      zoneSearch:"",
      searchType:"",
      oneSearch:"",
      searchRes:{},
      searchSel:-1,
      relUsers:[],
      relSites:[],
      relAccounts:[],
      relGateways:[],
      relZones:[],
      userSel:"",
      accountFeatures:{},
      resetPW:"",
      editUserAcctId:false,
      editGatewaySiteId:false,
      editType:"",
      editField:"",
      showSystemReport:false,
      uploadFwResult:"Upload Firmware Image",

//       pageType:"users",
// //       selUser:"",
//       editObj:{},
//       editId:"",
//       editKey:"",
    }
//     this.pageTitles={accounts:"Accounts",sites:"Sites",gateways:"Gateways",
//       zones:"Zones",users:"Users",}
//     this.pageParms={
//       users:["userSearch","users","name","email","userId","selUser"],
//       accounts:["accountSearch","accounts","name","adminEmail","accountId","selAccount"],
//       sites:["siteSearch","sites","name","","siteId","selSite"],
//       gateways:["gatewaySearch","gateways","name","","gatewayId","selGateway"],
//       zones:["zoneSearch","zones","zoneName","","zoneId","selZone"],
//     }
//     this.itemIds={accounts:"accountId",sites:"siteId",gateways:"gatewayId",zones:"zoneId",users:"userId"}
    this.loadInfo()
    this.setBreadCrumbs()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveData)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.subscribe_keyDownEvent=globs.events.subscribe("keyDown", 
      e=>this.onChange("searchKey",{key:e}))
    this.dark=((globs.device?.deviceTheme||"").toLowerCase().indexOf("dark")>=0)?1:0
    this.bgColor1=(this.dark)?"#202020":"FFFFFF"
    this.bgColor2=(this.dark)?"#203030":"FFFFFF"
    this.selColor1=(this.dark)?"#606060":"DDDDDD"
  }
  
  componentWillUnmount=()=>{
    this.subscribe_keyDownEvent.remove()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Tech Portal", url:`/usa/c18/admin/techPortal2`},
            ]},
        },
      )
    }
  }
  
  loadWsTrans=async(parms)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: parms.uri, 
      method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId, 
      body: parms.body})
    
  }
  
  makeItemLookups=(arr,idName)=>{
    let lu={}
    arr.forEach(ar=>{ lu[ar[idName]]=ar })
    return lu
  }
  
  loadInfo=async()=>{
// users, sites, accounts, zones, gateways,  
    this.types=["account","user","site","gateway","zone"]
    let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
//     cl(users)
    let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
    let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    let searchRes={}
    this.makeCustomSaveInfo()
    this.setState({loaded:true,
      users:users,
      accounts:accounts,
      sites:sites,
      gateways:gateways,
      zones:zones,
      searchRes:searchRes,
      lookUps:{
        userId:this.makeItemLookups(users,"userId"),
        accountId:this.makeItemLookups(accounts,"accountId"),
        siteId:this.makeItemLookups(sites,"siteId"),
        gatewayId:this.makeItemLookups(gateways,"gatewayId"),
        zoneId:this.makeItemLookups(zones,"zoneId"),
      }
    })
  }
  
  saveData=async(cmd)=>{
    let st=this.state
//     cl(st)
    if(cmd=="save"){
      if(st.editType){
        switch(st.editType){
          case "zone":
            let zone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
            cl(zone)
            let upd={
              zoneId:st.selectedId,
            }
            upd[st.editField]=zone[st.editField]
            cl(upd)
            cl(st.editField)
            cl(zone[st.editField])
            cl("done")
            globs.events.publish("saveOK",true)
            return await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones", 
              method: "update", sessionId: globs.userData.session.sessionId, 
              body: upd})
            break
        }
      }
//       if(st.editUserAcctId){
//         this.setState({editUserAcctId:false})
//         globs.events.publish("saveOK",true)
//         let selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]
//         cl(selUser)
//         let upd={userId:selUser.userId,accountId:st.userAccountId}
//         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers", 
//           method: "update", sessionId: globs.userData.session.sessionId, 
//           body: upd})
//       }
      this.checkSaveFields(this.saveFields[0])
      
//       if(st.editGatewaySiteId){
//         this.setState({editGatewaySiteId:false})
//         globs.events.publish("saveOK",true)
//         let selGateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
//         cl(selGateway)
//         let upd={gatewayId:selGateway.gatewayId,siteId:st.gatewaySiteId}
//         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways", 
//           method: "update", sessionId: globs.userData.session.sessionId, 
//           body: upd})
//       }
    }
  }
  
/******************** Routines to handle the custom field saves **********************************/

  unSaveFields=()=>{
    globs.events.publish("savePageEnable",false)
    let vals={editType:null,editField:null}// old style custom field
    this.saveFields.forEach(sfi=>{
      vals[sfi.showEditFlag]=false
    })
//     cl(vals)
    this.setState(vals)
    
  }
  
  makeCustomSaveInfo=()=>{
// table for individual fields that get saved:
    this.saveFields=[
    {showEditFlag: "editGatewaySiteId",table:"gateways",id:"gatewayId",
      field:"gatewaySiteId", uri:"/su/allGateways",
      updField:"siteId",fieldName:"Site ID:"},
    {showEditFlag: "editUserAccountId",table:"users",id:"userId",
      field:"userAccountId", uri:"/su/usaSUAllUsers",
      updField:"accountId",fieldName:"Acct ID:"}
    ]
  }
  
  checkSaveFields=async(sfi)=>{// sfi is saveFieldInfo
// this also needs to save to local storage!
    let st=this.state
    this.saveFields.forEach(async sfi=>{
      if(st[sfi.showEditFlag]/*.editGatewaySiteId*/){
//         cl("saving "+sfi.showEditFlag)
        globs.events.publish("saveOK",true)
        let vals={}
        vals[sfi.showEditFlag]=false// end edit mode
        this.setState(vals)//this.setState({editGatewaySiteId:false})
        let selVals=st[sfi.table].filter(v=>{return v[sfi.id]==st.selectedId})[0]
//         cl(selVals)// the item that's being saved'
        let upd={}
        upd[sfi.id]=selVals[sfi.id]
        upd[sfi.updField]=st[sfi.field]
        Object.assign(selVals,upd)
//         cl(upd)
//         cl(sfi.uri)
        return await wsTrans("usa", {cmd: "cRest", uri: sfi.uri, 
          method: "update", sessionId: globs.userData.session.sessionId, 
          body: upd})
  //         return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways", 
  //           method: "update", sessionId: globs.userData.session.sessionId, 
  //           body: upd})
      }
    })
  }
  
  hideShowCustomSaveField=(vals)=>{
/* this needs to set the editGatewaySiteId flag, *and*
save the current value to st.gatewaySiteId
when it enters edit mode
*/
//     cl("show custom")
//     cl(vals)
    this.setState(vals)
  }
  
  editCustomSaveField=(vals)=>{
//     cl("edit custom")
//     cl(vals)
    globs.events.publish("savePageEnable",true)
    this.setState(vals)
    
  }
  
  showCustomSaveField=(sfi)=>{
    let st=this.state
//     cl(st)
//     cl(sfi)
    let selItem=st[sfi.table].filter(v=>{return v[sfi.id]==st.selectedId})[0]||{}
    if(st[sfi.showEditFlag]/*.editGatewaySiteId*/){
//             <td
//               style={{cursor:"pointer"}}
//               onClick={e=>{
//                 let vals={type:sfi.field}
//                 vals[sfi.field]=e.currentTarget.value
//                 this.onChange("hideShowCustomSaveField",
//                 {gatewaySiteId:selItem[sfi.updField]})}
//               }
//             >
      return(
            <tr><td>{sfi.fieldName}</td>
            <td>
            <input type="text"
              style={{width:150,padding:0,borderRadius:0}}
              value={st[sfi.field]}
            
            onChange={
              e=>{
//                 let vals={type:sfi.field}
                let vals={}
                vals[sfi.field]=e.currentTarget.value
                this.onChange("editCustomSaveField",vals)
              }
            }
            ></input>
            </td></tr>
      )
    }else{
      return <tr><td>{sfi.fieldName}</td><td 
        style={{cursor:"pointer"}}
        onClick={e=>{
          let vals={}
          vals[sfi.showEditFlag]=true
          cl(st)
          cl(st[sfi.table])
          cl(sfi.id)
          cl(st.searchSel)
          let item=st[sfi.table].filter(it=>{return it[sfi.id]==st.selectedId})[0]// or selectedId
          cl(item)
          cl(sfi)
          vals[sfi.field]=item[sfi.updField]
          this.onChange("hideShowCustomSaveField",vals)}
        }
      >
        <span>{selItem[sfi.updField]||"No ID"}</span>
      </td>
      </tr>
    }
    
  }
  
/******************** End Routines to handle the custom field saves **********************************/
  
  searchType=(vals,type)=>{
//     cl(vals)
    let parms={
      account:{l:"accounts",n:"name",s:"accountSearch",i:"accountId"},
      user:{l:"users",n:"name",s:"userSearch",i:"userId"},
      site:{l:"sites",n:"name",s:"siteSearch",i:"siteId"},
      gateway:{l:"gateways",n:"name",s:"gatewaySearch",i:"gatewayId"},
      zone:{l:"zones",n:"zoneName",s:"zoneSearch",i:"zoneId"},
    }[type]
    let search=vals["oneSearch"].toLowerCase()
    let res=this.state[parms.l].filter(v=>{
      if(Array.isArray(v.zoneId)){v.zoneId=""}
      return ((v[parms.n]||"").toLowerCase().indexOf(search)>=0)||
      ((v.accountId||"").toLowerCase().indexOf(search)>=0)||
      ((v.userId||"").toLowerCase().indexOf(search)>=0)||
      ((v.siteId||"").toLowerCase().indexOf(search)>=0)||
      ((v.gatewayId||"").toLowerCase().indexOf(search)>=0)||
      ((v.clientId||"").toLowerCase().indexOf(search)>=0)||
      ((v.zoneId||"").toLowerCase().indexOf(search)>=0)||
      ((v.email||"").toLowerCase().indexOf(search)>=0)
    }).map(v=>{return {v:v[parms.i],t:v[parms.n],id:v["_id"]}})
    vals.searchRes[type]=res
  }
  
//   searchAccount=(vals)=>{this.searchType(vals,"account")}
//   searchUser=(vals)=>{this.searchType(vals,"user")}
//   searchSite=(vals)=>{this.searchType(vals,"site")}
//   searchGateway=(vals)=>{this.searchType(vals,"gateway")}
//   searchZone=(vals)=>{this.searchType(vals,"zone")}
  
  getSearchPos=()=>{
    let st=this.state
    if(st.searchSel==-1){return -1}
    for(let i=0;i<st.searchRes.length;i++){
      if(st.searchRes[i].v==st.searchSel){return i}
    }
    return -1
  }
  
  searchKey=(key)=>{
    let st=this.state
    let pos=this.getSearchPos()
    var searchSel=st.searchSel
    if(key=="ArrowDown"){
      if(pos<st.searchRes.length-1){
        searchSel=st.searchRes[pos+1].v
      }
    }else{
      if(pos>0){
        searchSel=st.searchRes[pos-1].v
      }
    }
    this.setState({searchSel:searchSel})
  }
  
  getFeatures=(accountId)=>{
    let st=this.state
    let selAccount=st.accounts.filter(a=>{return a.accountId==accountId})[0]
//     cl(selAccount)
    let features={};
    (selAccount.features||[]).forEach(f=>{
      features[f]=true
    })
    return features
  }
  
  ts2str=(ts)=>{
    return dateToDisplayDate(new Date(1000*ts),"mm/dd/yyyy h:mm:ss ap",420)
  }
  
  getZoneLastData=async(zoneId)=>{
    cl(zoneId)
    let zone=this.state.zones.filter(z=>{return z.zoneId==zoneId})[0]
//     cl(zone)
    let query={siteId:zone.siteId,zoneInd:+zone.siteZoneIndex}
    cl(query)
    
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/lastData", 
      method: "retrieve", sessionId: globs.userData.session.sessionId, 
      body: query})
    cl(res.data)
    let ts=(res.data[0]||{}).t||0
    let daStr=this.ts2str(ts)
//     let da=new Date(1000*ts)
//     let daStr=dateToDisplayDate(da,"mm/dd/yyyy h:mm:ss ap",420)
//     cl(res)
    return daStr
    
  }
  
  resetPassword=async()=>{
    let st=this.state
    let pw=getPWString(8)
    let userId=st.selectedId
    
    let upd={userId:userId,password:pw}
    cl(upd)
    cl(st)
    this.setState({resetPW:pw})

    return await wsTrans("usa", {cmd: "cRest", uri: "/s/users", 
      method: "update", sessionId: globs.userData.session.sessionId, 
      body: upd})
  }
  
  activateUser=async()=>{
// if a user has been invited to an account, then they already have the accountId filled in
// if a user has not been activated, then they have a "token" field
    let st=this.state
    let user=st.users.filter(u=>{return u.userId==st.selectedId})[0]
    cl(user)
    cl("activate")
    if(user.accountId){//user invited to an account
      let pw=getPWString(8)
      let body={userId:user.userId, name: user.name, password: pw, token: user.token};
      wsTrans("usa", {cmd: "cRest", uri: "/o/invites", method: "create", body: body})
      this.setState({resetPW:pw})
    }else{// new user and account
      let query={token:user.token}
      cl(query)
      return await wsTrans("usa", {cmd: "cRest", uri: "/o/users/activate", 
        method: "retrieve", sessionId: globs.userData.session.sessionId, 
        body: query})
    }
}
  
  deleteUser=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this User?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
//       let user=st.users.filter(u=>{return u.userId==st.selectedId})[0]
      let user=st.users.filter(u=>{return u._id==st.id})[0]
      cl(user)
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allUsers", 
        method: "delete", sessionId: globs.userData.session.sessionId, 
//         body: {userId:user.userId}})
        body: {_id:user._id}})
    }
  }
  
  deleteSite=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Site?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let site=st.sites.filter(s=>{return s._id==st.id})[0]// *if* selected from the site list
      if(!site){
        site=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
      }
      cl(site)
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allSites", 
        method: "delete", sessionId: globs.userData.session.sessionId, 
        body: {_id:site._id}})
    }
  }
  
  deleteGateway=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Gateway?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      let gateway=st.gateways.filter(g=>{return g._id==st.id})[0]// *if* selected from the site list
      if(!gateway){
        gateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
      }
      cl(gateway)
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allGateways", 
        method: "delete", sessionId: globs.userData.session.sessionId, 
        body: {_id:gateway._id}})
    }
  }
  
  deleteAccount  =async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Account?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      let account=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
      cl(account)
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/suAccounts", 
        method: "delete", sessionId: globs.userData.session.sessionId, 
        body: {accountId:account.accountId}})
    }
  }
  
  deleteZone=async()=>{
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Zone?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let st=this.state
      cl(st)
      let zone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
      cl(zone)
      return await wsTrans("usa", {cmd: "cRest", uri: "/su/allZones", 
        method: "delete", sessionId: globs.userData.session.sessionId, 
        body: {zoneId:zone.zoneId}})
    }
  }
  
  doRefresh=async(section)=>{
//     let users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
// //     cl(users)
//     let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
//     let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
//     let gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
//     let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    
    let vals={}
    let tp=(section=="techPortal")
    let st=this.state
    if((tp)||(section=="accounts")){
      vals.accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    }
    if((tp)||(section=="users")){
      vals.users=(await this.loadWsTrans({uri:"/su/allUsers"})).data
    }
    if((tp)||(section=="sites")){
      vals.sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    }
    if((tp)||(section=="gateways")){
      vals.gateways=(await this.loadWsTrans({uri:"/su/allGateways"})).data
    }
    if((tp)||(section=="zones")){
//       cl(this.state)
      vals.zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
      if(st.selectedType=="zone"){
        vals.zoneLastData=await this.getZoneLastData(st.selectedId)
      }
    }
    this.setState(vals)
//     cl(section)
  }

  addIGrowAcct=async(acct)=>{
    cl(acct)
    let st=this.state
    cl(st.iGrowAcct)
    cl(st)
    let res=await wsTrans("usa", {cmd: "cRest", uri: '/s/iGrow800Acct',
      method: 'retrieve', sessionId: globs.userData.session.sessionId,
      body: {igAccountId:st.iGrowAcct,siteId:st.selectedId,
        controllerType:1}})
    cl(res)
    let controllers=res.data
      .filter(c=>{return c.controllerType==1})
      .map(c=>{return c.serialNo})
    cl(controllers)
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    let searches={updAccount:this.searchAccount,updUser:this.searchUser,
      updSite:this.searchSite,updGateway:this.searchGateway,updZone:this.searchZone}
//     cl(type,vals)
    switch(type){
      case "updAccount":
      case "updUser":
      case "updSite":
      case "updGateway":
      case "updZone":
        vals.searchType=type
        searches[type](vals)
        vals.searchSel=-1
        this.setState(vals)
        break
      case "updSearch":
//         cl(st.searchRes)
        vals.searchRes={}
        this.types.forEach(k=>{
          this.searchType(vals,k)
        })
        Object.assign(vals,
          {relAccounts:[],relUsers:[],relSites:[],relGateways:[],relZones:[],selectedType:""}
        )
//         Object.keys(this.state.searchRes).forEach(k=>{
//           this.searchType(vals,k)
//         })
        this.setState(vals)
        break
      case "sel":
        this.unSaveFields()
        this.selectSearch(vals.type,vals.searchSel,vals.id)
        vals.selectedType=vals.type
//         cl(vals)
        if(vals.type=="account"){vals.accountFeatures=this.getFeatures(vals.searchSel)}
        if(vals.type=="zone"){vals.zoneLastData=await this.getZoneLastData(vals.searchSel)}
        vals.searchRes={}
//         cl(vals)
        this.setState(vals)
        break
      case "searchKey":
//         cl(vals)
//         cl(st.searchType)
        if(st.searchType!=""){
          if(["ArrowDown","ArrowUp"].includes(vals.key)){this.searchKey(vals.key)}
          if(vals.key=="Enter"){this.selectSearch(st.searchSel)}
        }
        break
      case "accountSel":// these are selections from the related list
        this.unSaveFields()
        this.getRelatedItems(vals.accountSel)
//         cl(vals)
        let accountFeatures=this.getFeatures(vals.accountSel)        
        this.setState({searchType:"",selectedType:"account",selectedId:vals.accountSel,
          accountSel:vals.accountSel,accountFeatures:accountFeatures,
        },
        )
        break
      case "userSel":
        this.unSaveFields()
        this.setState({searchType:"",selectedType:"user",selectedId:vals.userSel,
          userSel:vals.userSel,
        })
        break
      case "siteSel":
//         cl(vals)
//         cl(st)
        this.unSaveFields()
        let site=st.sites.filter(s=>{
          return s.siteId==vals.siteSel})[0]
        this.getRelatedItems(site.accountId,vals.siteSel,null,null,"site")
        this.setState({searchType:"",selectedType:"site",selectedId:vals.siteSel,
          siteSel:vals.siteSel,
        })
        break
      case "gatewaySel":
//         cl(vals)
        this.unSaveFields()
        let gateway=st.gateways.filter(g=>{
          return g.gatewayId==vals.gatewaySel})[0]
//         cl(gateway)
//         cl(st.gateways)
        this.getRelatedItems(gateway.accountId,gateway.siteId,vals.gatewaySel,null,"gateway")
        this.setState({searchType:"",selectedType:"gateway",selectedId:vals.gatewaySel,
          gatewaySel:vals.gatewaySel,
        })
        break
      case "zoneSel":
//         cl("zonesel")
//         cl(vals)
        this.unSaveFields()
        let zone=st.zones.filter(z=>{return z.zoneId==vals.zoneSel})[0]
//         cl(zone)
        let zoneLastData=await this.getZoneLastData(vals.zoneSel)
//         cl(zoneLastData)
        this.getRelatedItems(zone.accountId,zone.siteId,zone.gatewayId)
        this.setState({searchType:"",selectedType:"zone",selectedId:vals.zoneSel,
          gatewaySel:zone.gatewayId,zoneSel:vals.zoneSel,zoneLastData:zoneLastData,
        })
        break
      case "resetPassword":
        this.resetPassword()
        break
      case "activateUser":
        this.activateUser()
        break
      case "deleteUser":
        this.deleteUser()
        break
      case "hideShowCustomSaveField":
        this.hideShowCustomSaveField(vals)
        break
      case "editCustomSaveField":
        this.editCustomSaveField(vals)
        break
      case "showUserAccountId":
        vals.editUserAcctId=true
        this.setState(vals)
        break
//       case "showGatewaySiteId":
//         vals.editGatewaySiteId=true
//         this.setState(vals)
//         break
      case "userAccountId":
        globs.events.publish("savePageEnable",true)
        this.setState(vals)
        break
      case "deleteAccount":
        this.deleteAccount()
        break
      case "deleteZone":
        this.deleteZone()
        break
      case "deleteSite":
        this.deleteSite()
        break
      case "deleteGateway":
        this.deleteGateway()
        break
      case "feature":
        let features=Object.assign(st.accountFeatures)
        features[vals.feature]=vals.checked
        cl(vals)
        this.setState({accountFeatures:features})
        return
      case "saveFeatures":
//         cl(type)
        this.saveFeatures()
        break
      case "viewEdit":
        this.setState({editType:vals.type,editField:vals.field})
        cl(vals)
        break
      case "valEdit":
        globs.events.publish("savePageEnable",true)
        this.valEdit(vals)
        break
      case "link":
        vals.e.preventDefault()
        this.doLink(vals)
//         cl(vals)
        break
      case "upd":
        this.setState(vals)
        break
      case "refresh":
        this.doRefresh(vals.element)
//         cl(vals)
        break
      case "sendFwFile":
        cl(type)
        break
      case "igrow":
        this.addIGrowAcct(st.iGrowAcct)
        break
    }
  }
  
  valEdit=(vals)=>{
    let st=this.state
    switch(st.selectedType){
      case "zone":
        let zones=st.zones.slice(0)
        let zone=zones.filter(z=>{return z.zoneId==st.selectedId})[0]
        zone[st.editField]=vals.val
//         cl(zone)
        this.setState({zones:zones})
//         cl(zone)
//         let field
        break
    }
//     cl(st)
//     cl(vals)
  }
  
  saveFeatures=async()=>{
    let st=this.state
//     cl("save features")
    let features=[]
    Object.keys(st.accountFeatures).forEach(f=>{
//       cl(f)
      if(st.accountFeatures[f]){features.push(f)}
    })
    let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    selAccount.features=features
    cl(st)
    cl(features)
    
    return await wsTrans("usa", {cmd: "cRest", uri: '/su/suAccounts', 
      method: 'update', sessionId: globs.userData.session.sessionId, 
      body: {accountId:st.selectedId,features:features}})
  }
  
  showSearchBox=(type)=>{
    let st=this.state
//     cl(st.searchRes[type])
    return(
      <div style={{width:"100%",height:200,backgroundColor:this.bgColor2,marginRight:30,
        padding:10,overflowY:"auto",
      }}>
      <table><tbody>
      {st.searchRes[type].map((r,i)=>{
        let txt=(r.t)?r.t:"No Name"
//         cl(r)
        let bg=(r.v==st.searchSel)?this.selColor1:null
        return(
          <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}}
            onClick={e=>this.onChange("sel",{type:type,searchSel:r.v,id:r.id})}
          ><td>{txt}</td></tr>
        )})}
      </tbody></table>
      </div>
    )
  }
  
  getRelatedItems=(accountId,siteId,gatewayId,zoneId,selType)=>{
    
//     cl(accountId,siteId,gatewayId,zoneId,selType)
    var filterRes=(type,val)=>{
//       cl(type,val)
      if(siteId){
        if(gatewayId&&(type!="site")){
          if(zoneId){
            return (val.zoneId==zoneId)&&(val.siteId==siteId)
            &&(val.gatewayId==gatewayId)&&(val.accountId==accountId)
          }else{
            if(selType=="gateway"){
//               cl(val)
              return (val.gatewayId==gatewayId)&&(val.accountId==accountId)
            }else{
              return (val.siteId==siteId)&&(val.accountId==accountId)
                &&(val.gatewayId==gatewayId)
            }
          }
        }else{
          return (val.siteId==siteId)&&(val.accountId==accountId)
        }
      }else{
        return val.accountId==accountId
      }
    }
    
    let field={updAccount:"accountId"}
    let st=this.state
    let relAccounts=st.accounts.filter(a=>{return a.accountId==accountId}).map(a=>
      {return {v:a.accountId,t:a.name}})
    let relUsers=st.users.filter(u=>{return u.accountId==accountId}).map(u=>
      {return {v:u.userId,t:u.name}})
    
    let relSites=st.sites.filter(s=>{return filterRes("site",s)}).map(s=>
      {return {v:s.siteId,t:s.name}})
    
    let relGateways=st.gateways.filter(g=>{return filterRes("gateway",g)}).map(g=>
      {return {v:g.gatewayId,t:g.name}})
    
    let relZones=st.zones.filter(z=>{return filterRes("zone",z)}).map(z=>
      {return {v:z.zoneId,t:z.zoneName}})
//     cl(relUsers)
    this.setState({relAccounts:relAccounts,relUsers:relUsers,relSites:relSites,
      relGateways:relGateways,relZones:relZones})
  }
  
  selectSearch=(type,id,id2)=>{// id2 is the mongo _id
//     cl(type,id,id2)
    let st=this.state
//     cl(st.searchType)
    let parms={
      account:{v:"accounts",id:"accountId"},
      user:{v:"users",id:"userId"},
      site:{v:"sites",id:"siteId"},
      gateway:{v:"gateways",id:"gatewayId"},
      zone:{v:"zones",id:"zoneId"},
    }[type]
    var val
    if(id2){
      val=st[parms.v].filter(s=>{return s["_id"]==id2})[0]
    }else{
      val=st[parms.v].filter(s=>{return s[parms.id]==id})[0]
    }
//     cl(val)
//     let accountId=val.accountId
    
    
//     cl("select "+id)
//     let accountId=id
    
    this.getRelatedItems(val.accountId,val.siteId,val.gatewayId,null,type)//,val.zoneId
    this.setState({searchType:"",selectedId:id})
  }
  
  makeOpts=(vals)=>{
    return vals.map((v,i)=>{return(
      <option key={i} value={v.v}>{v.t}</option>
    )})
  }
  
//   showSelectUser=(relUsers)=>{
//     cl(relUsers)
//     return(
//       <div className="custom-select">
//         <div className="clearfloat"></div><br/>
//         <label htmlFor="">Related Users</label>
//         
//         <C18Select00 id=""
//           parms={{list:true,height:200}}
//           value={this.state.userSel}
//           onChange={e=>this.onChange("userSel",{userSel: e.currentTarget.value})}
//         >
//           {this.makeOpts(relUsers)}
//         </C18Select00>
//         {false&&
//           <span className="material-icons down-arrow">
//             keyboard_arrow_down
//           </span>
//         }
//       </div> 
//     )
//   }
  
  showSelectType=(relTypes,type)=>{
//     cl(type)
//     cl(relTypes)
    let parms={
      account:{t:"Accounts",v:"accountSel"},
      user:{t:"Users",v:"userSel"},
      site:{t:"Sites",v:"siteSel"},
      gateway:{t:"Gateways",v:"gatewaySel"},
      zone:{t:"Zones",v:"zoneSel"},
    }[type]
    return(
      <div className="custom-select">
        <div className="clearfloat"></div><br/>
        <label htmlFor="">Related {parms.t}</label>
        <C18Select00 id=""
          parms={{list:true,height:200}}
          value={this.state[parms.v]}
          onChange={e=>{
            let vals={}
            vals[parms.v]=e.currentTarget.value
            this.onChange(parms.v,vals)}
          }
        >
          {this.makeOpts(relTypes)}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div> 
    )
  }
  
  showFeatureFlags=(selAccount)=>{
    let features=[
    {v:"tasks",t:"Tasks"},
    {v:"messaging",t:"Messaging"},
    {v:"editFui",t:"Edit Fui"},
    {v:"cameras",t:"Cameras"},
    {v:"showGjId",t:"Show GJ ID"},
    {v:"techPortal",t:"Tech Portal"},
    {v:"salesPortal",t:"Sales Portal"},
    {v:"editInfo",t:"Edit Info Pages"},
    {v:"gjPing",t:"GJ Ping"},
    {v:"3rdParty",t:"Third Party"},
    {v:"fullSiteAccess",t:"Full Site Access"},
    {v:"showUserEmail",t:"Show Email"},
    {v:"zoneGroups",t:"Zone Groups"},
    {v:"saveDefaults",t:"Save Defaults"},
    {v:"equipmentImage",t:"Equipment Image"},
    {v:"configSave",t:"Save Config"},
//     {v:"videoTest",t:"Video Test"},
    {v:"testing",t:"Testing"},
    {v:"systemStatus",t:"System Status"},
    {v:"cropRecipes",t:"Crop Recipes"},
    {v:"svgEditor",t:"SVG Editor"},
    {v:"virtualZones",t:"Virtual Zones"},
    {v:"filterList",t:"Filter Lists"},
    {v:"watchParams",t:"Watch Parameters"},
    {v:"testAcct",t:"Test Account"},
    {v:"physView",t:"Physical View"},
    {v:"syncStatus",t:"Sync Status"},
    {v:"accesses",t:"View Page Accesses"},
    {v:"advancedDataViz",t:"Advanced Data Visualization"},    
//     {v:"fwUpload",t:"Firmware Upload"},
// tasks, messaging, editFui, cameras, showGjId, techPortal, salesPortal, gjPing, 3rdParty,fullSiteAccess,
// showUserEmail, zoneGroups, configSave,videoTest    
    ]
    let st=this.state
//     cl(st.accountFeatures)
    return(
      <div style={{height:100,backgroundColor:this.bgColor2, overflowY:"auto",padding:10,
        borderStyle:"solid",borderWidth:1,
        
      }}>
      <table><tbody>
      {features.map((f,i)=>{
        return(
        <tr key={i}><td>
        <input type="checkbox" style={{margin:5}}
          checked={st.accountFeatures[f.v]||false}
          onChange={e=>{this.onChange("feature",{feature:f.v,checked:e.currentTarget.checked})}}
        />
        </td>
        <td>{f.t}</td>
        </tr>
      )})}
      </tbody></table>
      </div>
    )
  }
  
  showUserAccountId=(user)=>{
//     cl(user)
    let st=this.state
    if(st.editUserAcctId){
      return(
        <div>
          <input type="text"
          style={{width:"100%",padding:0,borderRadius:0}}
          value={st.userAccountId}
          onChange={e=>this.onChange("userAccountId",
            {userAccountId:e.currentTarget.value})}
          ></input>
        </div>
      )
    }else{
      return <span>here{user.accountId}</span>
    }
  }
  
  showGatewaySiteId=(gw)=>{
    let st=this.state
    let selGateway=null
    if(st.editGatewaySiteId){
      return(
        <div>
            <tr><td>Site ID:</td><td
              style={{cursor:"pointer"}}
              onClick={e=>this.onChange("showGatewaySiteId",{gatewaySiteId:selGateway.siteId})}
            >
            {this.showGatewaySiteId(selGateway)}
            <input type="text"
            style={{width:"100%",padding:0,borderRadius:0}}
            value={st.gatewaySiteId}
            onChange={e=>this.onChange("gatewaySiteId",
              {gatewaySiteId:e.currentTarget.value})}
            ></input>
            </td></tr>
        </div>
      )
    }else{
      
      return <span>{gw.siteId||"No ID"}</span>
    }
  }

  showIGrow=()=>{
    let st=this.state
    let zones=st.zones.filter(z=>{
      return z.siteId==st.selectedId})
//     cl(zones[0]?.siteId,st.siteSel)
//     cl(zones)
    if(!zones.length){
      let sty={borderRadius:0,borderWidth:1,borderStyle:"solid",padding:0,width:60,
        display:"inline-block",marginLeft:5}
      let sty2=Object.assign({},sty,{width:40})
      return(
        <div>
          <h3>Add iGrow Account to Site</h3>
          <label style={{display:"inline-block"}}>AcctID:</label>
          <input type="number"
            value={st.iGrowAcct||0}
            onChange={e=>{this.onChange("upd",{iGrowAcct:e.target.value})}}
            style={sty}/>
          <button style={sty2}
          onClick={e=>{this.onChange("igrow")}}
          >Add</button>
        </div>
      )
    }
  }
  
  showSelectedInfo=(type)=>{
// this is for the one item currently selected
    let tiers={locked:"Locked",unlocked:"UnLocked"}
    let st=this.state
    var created
    switch(type){
      case "account":
        let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
        let owner=st.users.filter(u=>{return u.userId==selAccount.owner})[0]||{}
        created=(selAccount.created)?this.ts2str(selAccount.created):"(unknown)"
        return(
          <div>
            <h4>{selAccount.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selAccount.accountId||"No ID"}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Email:</td><td>{selAccount.adminEmail}</td></tr>
            <tr><td>Owner:</td><td>{owner.name}</td></tr>
            </tbody></table>
            <div style={{borderStyle:"solid",borderWidth:1,borderRadius:10,
              padding:10
            }}>
              <h4>Feature Flags</h4>
              {this.showFeatureFlags(selAccount)}
              <div className="clearfloat"></div><br/>
              <C18Button00 type="button" className="filled"
              onClick={e=>this.onChange("saveFeatures")}
              >
              Save</C18Button00>
            </div>
            <div className="clearfloat"/><br/>        
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteAccount")}}
            >Delete</C18Button00>
          </div>
        )
      case "user":
        let selUser=st.users.filter(u=>{return u._id==st.id})[0]
        if(!selUser){
          selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]// needed for selections from system check
        }
        let activated=(!selUser.token)
        let invited=!!selUser.accountId
        let pw=st.resetPW
        created=(selUser.created)?this.ts2str(selUser.created):"(unknown)"
        
//             <tr><td>Acct ID:</td><td
//               style={{cursor:"pointer"}}
//               onClick={e=>this.onChange("showUserAccountId",{userAccountId:selUser.accountId})}
//             >
//             {this.showUserAccountId(selUser)}
//             </td></tr>
        return(
          <div>
            <h4>{selUser.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selUser.userId}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            {this.showCustomSaveField(this.saveFields[1])}
            <tr><td>Email:</td><td>{selUser.email}</td></tr>
            <tr><td>Active:</td><td>{(selUser.active)?"Yes":"No"}</td></tr>
            <tr><td>Activated:</td><td>{(activated)?"Yes":"No"}</td></tr>
            {!activated&&
              <tr><td>Invited:</td><td>{(invited)?"Yes":"No"}</td></tr>
            }
            {(pw!="")&&
              <tr><td>Password:</td><td>{pw}</td></tr>
            }
            </tbody></table>
            {activated?
              <>
                <div className="clearfloat"/><br/>
                <C18Button00 type="button" className="filled"
                  onClick={e=>{this.onChange("resetPassword")}}
                >Reset Password</C18Button00>
              </>:
              <>
                <div className="clearfloat"/><br/>
                <C18Button00 type="button" className="filled"
                  onClick={e=>{this.onChange("activateUser")}}
                >Activate</C18Button00>
              </>
            }
            <div className="clearfloat"/><br/>        
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteUser")}}
            >Delete</C18Button00>
          </div>
        )
      case "site":
        let selSite=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
        created=(selSite.created)?this.ts2str(selSite.created):"(unknown)"
//         cl(selSite)
        return(
          <div>
            <h4>{selSite.name}</h4>
            <table><tbody>
            <tr><td>ID:</td><td>{selSite.siteId}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Account ID:</td><td>{selSite.accountId}</td></tr>
            </tbody></table>
            <div className="clearfloat"/><br/>        
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteSite")}}
            >Delete</C18Button00>
            {this.showIGrow()}
          </div>
        )
      case "gateway":
        let selGateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]||{}
        let zones=st.zones.filter(z=>{return z.gatewayId==st.selectedId})
        zones.sort((a,b)=>{
          if(a.gatewayZoneId>b.gatewayZoneId){return 1}
          if(a.gatewayZoneId<b.gatewayZoneId){return -1}
          return 0
        })
//         cl(zones)
//         cl(selGateway)
//             <tr><td>Site ID:</td><td>{selGateway.siteId}</td></tr>
//         cl(selGateway)
        created=(selGateway.created)?this.ts2str(selGateway.created):"(unknown)"
        return(
          <div>
            <h4>{selGateway.name}</h4>
            <table><tbody>
            <tr><td>GW ID:</td><td>{selGateway.gatewayId}</td></tr>
            <tr><td>Proc ID:</td><td
            style={{fontSize:11}}
            >{selGateway.clientId}</td></tr>
            <tr><td>Created:</td><td>{created}</td></tr>
            <tr><td>Last Comm:</td><td>{this.ts2str(selGateway.updateTime)}</td></tr>
            
            <tr><td>Account ID:</td><td>{selGateway.accountId||"No ID"}</td></tr>
            {this.showCustomSaveField(this.saveFields[0])}
            {zones.map((z,i)=>{
              return(
                <tr key={"z"+i}><td>{`Zone ${z.gatewayZoneIndex}:`}</td>
                <td>{`to Site: ${z.siteZoneIndex}`}</td></tr>
              )
            })}
            </tbody></table>
            <div className="clearfloat"/><br/>        
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteGateway")}}
            >Delete</C18Button00>
          </div>
        )
      case "zone":
        let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
//         cl(selZone)
//         let tier=selZone.zoneTier
//             <td>{selZone.siteZoneIndex}</td>
//             <td>{selZone.zoneId}</td>
        created=(selZone.created)?this.ts2str(selZone.created):"(unknown)"
        return(
          <div>
            <h4>{selZone.zoneName}</h4>
            <table><tbody>
            <tr><td>Name:</td>
            <td>{this.showViewEdit(selZone,type,"zoneName")}</td>
            </tr>
            
            <tr><td>ID:</td><td>{selZone.zoneId}</td></tr>
            <tr><td>Account ID:</td><td>{selZone.accountId}</td></tr>
            
            <tr><td>Site ID:</td>
              <td>{this.showViewEdit(selZone,type,"siteId")}</td>
            </tr>
            <tr><td>Created::</td><td>{created}</td></tr>
            <tr><td>GW Index:</td><td>{selZone.gatewayZoneIndex}</td></tr>
            
            <tr><td>Site Index:</td>
            <td>{this.showViewEdit(selZone,type,"siteZoneIndex")}</td>
            </tr>
            
            <tr><td>Gateway ID:</td><td>{selZone.gatewayId}</td></tr>
            <tr><td>Remote IP:</td><td>{selZone.remoteIp}</td></tr>
            <tr><td>Last Comm:</td><td>{st.zoneLastData}</td></tr>
            <tr><td>Connected:</td><td>{(selZone.connected)?"Yes":"No"}</td></tr>
            <tr><td>Sub Tier:</td><td>{tiers[selZone.zoneTier]||"None"}</td></tr>
            <tr><td>Virtual:</td><td>{(selZone.virtual)?"Yes":"No"}</td></tr>
            </tbody></table>
            <div className="clearfloat"/><br/>        
            <C18Button00 type="button" className="danger"
              onClick={e=>{this.onChange("deleteZone")}}
            >Delete</C18Button00>
          </div>
        )
    }
  }
  
  showViewEdit=(val,type,field)=>{
    let st=this.state
    if((st.editType==type)&&(st.editField==field)){
      let sty={borderRadius:0,padding:0,width:150}
      return(
        <input type="text" style={sty}
          value={val[field]||""}
          onChange={e=>this.onChange("valEdit",{val:e.currentTarget.value})}
        />
      )
    }else{
      let txt=(val[field]||(val[field]==0))?val[field]:"(none)"
//       let txt=val[field]||"(none)"
      return(
        <span onClick={e=>this.onChange("viewEdit",{val:val,type:type,field:field})}>
          {txt}
        </span>
      )
    }
//     
  }
  
  showAccounts=()=>{
    let st=this.state
//     cl(st)
//     let selAccount=st.accounts.filter(a=>{return a.accountId==st.selectedId})[0]
    let type="account"
    return(
      <div style={{width:300,height:500,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:20,
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"accounts"})}}
      >Accounts</h3>
      {(st.searchRes?.account?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relAccounts.length>0)&&
            this.showSelectType(st.relAccounts,type)
        )
      }
      </div>
    )
  }
  
//   showRelated=(rel)=>{
//     return(
//       <div style={{width:"100%",height:200,backgroundColor:"#CCCCFF",
//         overflowY:"auto"
//       }}>
//       <table><tbody>
//       {rel.map((r,i)=>{
//         return(
//           <tr key={i}><td>{r.t}</td></tr>
//         )
//       })}
//       </tbody></table>
//       </div>
//     )
//   }
  
  showUsers=()=>{
    let st=this.state
//     cl(st)
    let selUser=st.users.filter(u=>{return u.userId==st.selectedId})[0]
    let type="user"
//       {(st.searchRes?.user?.length)?
//         this.showSearchBox("user"):
//       (st.selectedType=="user")?
//         <>
//           <h4>{selUser.name}</h4>
//         </>:
//         (st.relUsers.length>0)&&
//           this.showSelectType(st.relUsers,"user")
//       }
    return(
      <div style={{width:300,height:500,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:20,
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"users"})}}
      >Users</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relUsers.length>0)&&
            this.showSelectType(st.relUsers,type)
        )
      }
      </div>
    )
  }
  
  showSites=()=>{
    let st=this.state
//     cl(st)
    let selSite=st.sites.filter(s=>{return s.siteId==st.selectedId})[0]
    let type="site"
    return(
      <div style={{width:300,height:500,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:20,
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"sites"})}}
      >Sites</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relSites.length>0)&&
            this.showSelectType(st.relSites,type)
        )
      }
      </div>
    )
  }
  
  showGateways=()=>{
    let st=this.state
    let selGateway=st.gateways.filter(g=>{return g.gatewayId==st.selectedId})[0]
    let type="gateway"
    return(
      <div style={{width:300,height:500,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:20,
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"gateways"})}}
      >Gateways</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relGateways.length>0)&&
            this.showSelectType(st.relGateways,type)
        )
      }
      </div>
    )
  }
  
  showZones=()=>{
    let st=this.state
//     cl(st)
    let selZone=st.zones.filter(z=>{return z.zoneId==st.selectedId})[0]
    let type="zone"
    return(
      <div style={{width:300,height:500,backgroundColor:this.bgColor1,borderStyle:"solid",
        borderWidth:1,borderRadius:10,boxShadow:"5px 10px 10px #888888",padding:20,
        margin:20,verticalAlign:"top",
        display:"inline-block"
      }}
        >
      <h3 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"zones"})}}
      >Zones</h3>
      {((st.searchRes||{})[type]?.length)?
        this.showSearchBox(type):
        ((st.selectedType==type)?
          this.showSelectedInfo(type):
          (st.relZones.length>0)&&
            this.showSelectType(st.relZones,type)
        )
      }
      </div>
    )
  }
  
  markImage=async(e)=>{
    
    cl(e.target.files)
    let files=e.target.files[0]
    let data = new FormData()
    data.append("type", "fwImage")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', files)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    cl("post image")
    cl(url,method,data,type)
//     var ret
    let ret=await doGetPostBasic(url, method, data, type)
    cl(ret)
    this.setState({uploadFwResult:"Upload OK!"})
    setTimeout(e=>this.setState({uploadFwResult:"Upload Firmware Image"}),5000)
//     let ret2 = await ret.json()
//     cl(ret2)
//     this.onChange("avatar",{avatar: ret2.avatar})
//     this.setState({avatar: ret2.avatar})
  }
    
  
  
  showFwUpload=()=>{
    let st=this.state
//     cl("show")
//       <C18Input00 type="file"  onChange={this.markImage} style={{
//         position:"absolute", 
//         width:103, 
//         height:103, 
//         marginTop:0, 
//         marginLeft:0,
//         zIndex:10,                   
//         opacity:0,
//         cursor: "pointer",
//       }}/>
//         <br/>
//         <button 
//           type="button"
//           onClick={e=>this.onChange("sendFwFile")}
//           style={{padding:"2px 5px",borderStyle:"solid", borderWidth:1, borderRadius:0,backgroundColor:"#EEEEEE",marginTop:5}}
//         >Send File</button>
    let color=(st.uploadFwResult=="Upload Firmware Image")?"#404040":"green"
    return(
      <div style={{height:100}}>
      <br/>
      <div>
        <form style={{float:"auto"}}
        className="file-upload-form">
        <label style={{color:color}}>{st.uploadFwResult}</label>
        <input style={{width:"auto",height:"auto",marginTop:0,zIndex:"auto",opacity:1,
          position:"static",cursor:"auto"}}
          type="file"
          onChange={this.markImage}
          />
          </form>
        </div>
      </div>
    )
  }
  
/****************************** Sanity Checks *****************************************/

  doLink=(vals)=>{
// vals sends the 'type' (gateway), and the gatewayId to select it
    cl(vals)
    switch(vals.type){
      case "account":
        this.onChange("accountSel",{accountSel:vals.accountId})
        break
      case "site":
        this.onChange("siteSel",{siteSel:vals.siteId})
        break
      case "gateway":
        this.onChange("gatewaySel",{gatewaySel:vals.gatewayId})
        break
      case "zone":
        this.onChange("zoneSel",{zoneSel:vals.zoneId})
        break
      case "user":
        this.onChange("userSel",{userSel:vals.userId})
        break
    }
  }
  
  checkUnique=(arr,idName,typeName,typeChange,lines)=>{
//     cl(arr)
    let ids={}
    arr.forEach(ac=>{
      let id=ac[idName]
      if(ids[id]){
        ids[id]+=1
      }else{
        ids[id]=1
      }
    })
    let dups=Object.keys(ids).filter(id=>{
      return (ids[id]>1)/* || (id=="JY8N0-CyDE4gUdhy")*/
    })
//     cl(dups)
    dups.forEach(du=>{
      let vals={type:typeChange}
      vals[idName]=(du=="undefined")?undefined:du
      let errLine=(
        <span>{typeName} <Link style={{color:"blue"}} to="" 
          onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
        >{du}</Link> has {ids[du]} entries</span>
      )
      lines.push(
        <div key={lines.length}>
          {errLine}<br/>
        </div>
      )
    })
  }

  checkAllUnique=(st,lines)=>{
    this.checkUnique(st.accounts,"accountId","Account","account",lines)
    this.checkUnique(st.sites,"siteId","Site","site",lines)
    this.checkUnique(st.gateways,"gatewayId","Gateway","gateway",lines)
    this.checkUnique(st.zones,"zoneId","Zone","zone",lines)
    this.checkUnique(st.users,"userId","User","user",lines)
  }
  
  checkUndefined=(arr,idName,fields,typeName,typeChange,lines)=>{
    arr.forEach(ar=>{
      let id=ar[idName]||"undefined"
      let ok=true
      fields.forEach(f=>{
        ok=ok&&(ar[f])
      })
      if(!ok){
        let vals={type:typeChange}
        vals[idName]=(id=="undefined")?undefined:id
        let errLine=(
          <span>{typeName} <Link style={{color:"blue"}} to="" 
            onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
          >{id}</Link> has undefined fields</span>
        )
        lines.push(
          <div key={lines.length}>
            {errLine}<br/>
          </div>
        )
        
      }
    })
    
  }
  
  checkAllUndefined=(st,lines)=>{
// checks the fields in the array to see if they're "undefined"
    this.checkUndefined(st.accounts,"accountId",["accountId","name"],"Account","account",lines)//,"adminEmail","owner"
    this.checkUndefined(st.sites,"siteId",["accountId","siteId","name"],"Site","site",lines)//,"adminEmail","owner"
    this.checkUndefined(st.gateways,"gatewayId",["gatewayId","siteId","accountId","name","uId"],"Gateway","gateway",lines)
    this.checkUndefined(st.zones,"zoneId",["zoneId","gatewayId","siteId","accountId","zoneName"],"Zone","zone",lines)
    this.checkUndefined(st.users,"userId",["accountId","email","name"],"User","user",lines)
  }
  
  checkReferences=(arr,idName,fields,typeName,typeChange,lines)=>{
// check the field in the array of fields to see if they're real sites, accounts, etc.'
/* for 'siteId, we're checking the sites table to see that all the sites
 refer to accountIds that actually exist,
 so, ar is an item from the sites table
 ar[f]is the accountId of that entry*/
//     cl(this.state)
    let lu=this.state.lookUps
//     cl(lu)
    arr.forEach(ar=>{
      fields.forEach(f=>{
        let id=ar[f]// the accountId of the entry in sites
        let tab=lu[f]// the accountId lookup table
        if(!tab[id]){// missing - make the entry to bring up this site
          let vals={type:typeChange}
          let id2=ar[idName]
//           cl(ar)
          vals[idName]=(id2=="undefined")?undefined:id2
          let errLine=(
            <span>{typeName} <Link style={{color:"blue"}} to="" 
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id2}</Link> has nonexistent {f}</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
//         cl(key)
//         cl(tab)
      })
    })
  }
  
  checkDuplicateZoneSiteIndexes=(st,lines)=>{
    let keys={}
    st.zones.forEach(z=>{
      let key=`${z.siteId}_${z.siteZoneIndex}`
      if(!keys[key])
        {keys[key]=[z]}else
        {keys[key].push(z)}// so keys is an array of the zones that have that siteZoneIndex
    })
    Object.keys(keys).forEach(k=>{
      if(keys[k].length>1){
        keys[k].forEach(z2=>{
          let vals={type:"zone"}
          let id=z2.zoneId
          vals["zoneId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Zone <Link style={{color:"blue"}} to="" 
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has duplicate SiteIndex {k}</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        })
        
      }
    })
  }
  
  checkZoneAccountReferences=(st,lines)=>{
    let gwLu=st.lookUps.gatewayId
    let siLu=st.lookUps.siteId
    st.zones.forEach(z=>{
      if(z.gatewayId && z.siteId){// has valid gateway and site IDs
        let gw=gwLu[z.gatewayId]
        let si=siLu[z.siteId]
//         cl(z)
//         cl(gw)
//         cl(si)
        if((gw&&si)&&((z.accountId!=gw.accountId)||(z.accountId!=si.accountId))){
          let vals={type:"zone"}
          let id=z.zoneId
          vals["zoneId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Zone <Link style={{color:"blue"}} to="" 
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has site or gw account error</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
      }
      
    })
  }
  
  checkGatewayAccountReferences=(st,lines)=>{
//     let gwLu=st.lookUps.gatewayId
    let siLu=st.lookUps.siteId
    st.gateways.forEach(gw=>{
      if(gw.siteId){// has valid gateway and site IDs
        let si=siLu[gw.siteId]
//         cl(gw)
//         cl(si)
        if((gw&&si)&&((gw.accountId!=si.accountId))){
          let vals={type:"gateway"}
          let id=gw.gatewayId
          vals["gatewayId"]=(id=="undefined")?undefined:id
          let errLine=(
            <span>Gateway <Link style={{color:"blue"}} to="" 
              onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
            >{id}</Link> has site account error</span>
          )
          lines.push(
            <div key={lines.length}>
              {errLine}<br/>
            </div>
          )
        }
      }
      
    })
  }
  
  checkZoneSiteIndexes=(st,lines)=>{
    st.zones.forEach(z=>{
      let sziOk=((z.siteZoneIndex==0)||(z.siteZoneIndex>0))&&(z.siteZoneIndex<100)
      if(!sziOk){
        let vals={type:"zone"}
        let id=z.zoneId
        vals["zoneId"]=(id=="undefined")?undefined:id
        let errLine=(
          <span>Zone <Link style={{color:"blue"}} to="" 
            onClick={e=>this.onChange("link",Object.assign(vals,{e:e}))}
          >{id}</Link> has siteZoneIndex error</span>
        )
        lines.push(
          <div key={lines.length}>
            {errLine}<br/>
          </div>
        )
      }
    })
  }
  
  checkAllReferencesExist=(st,lines)=>{
//     this.checkReferences(st.accounts,"accountId",["accountId","name"],"Account","account",lines)//,"adminEmail","owner"
    this.checkReferences(st.sites,"siteId",["accountId"],"Site","site",lines)
    this.checkReferences(st.gateways,"gatewayId",["accountId","siteId"],"Gateway","gateway",lines)
    this.checkReferences(st.zones,"zoneId",["accountId","siteId","gatewayId"],"Zone","zone",lines)
    this.checkReferences(st.users,"userId",["accountId"],"User","user",lines)
  }
  
  showSystemReport=()=>{
    let st=this.state
    let lines=[]// this is where the report will be put
    this.checkAllUnique(st,lines)
    this.checkAllUndefined(st,lines)
    this.checkAllReferencesExist(st,lines)
    this.checkDuplicateZoneSiteIndexes(st,lines)
    this.checkZoneAccountReferences(st,lines)
    this.checkGatewayAccountReferences(st,lines)
    this.checkZoneSiteIndexes(st,lines)
    return(
      <>
      <input type="checkbox"
        value={st.showSystemReport}
        onChange={e=>this.onChange("upd",{showSystemReport:e.target.checked})}
      />
      <h3 style={{marginLeft:10,display:"inline-block"}}>Show System Report</h3>
      {st.showSystemReport&&
        <div style={{width: 1000, borderStyle:"solid", borderWidth:1,borderRadius:10,padding:10}}>
        {lines.map(li=>{return li})}
        </div>
      }
      </>
    )
  }
  
/****************************** End Sanity Checks *****************************************/

  render(){
    let st=this.state
//     let [searchId,type,field1,field2,itemId,selId]=this.pageParms[st.pageType]
    if(st.loaded){
      return(
        <div
        >
        <h2 title="Click to Refresh" style={{cursor:"pointer"}}
        onClick={e=>{this.onChange("refresh",{element:"techPortal"})}}
        >Tech Portal</h2>
        <div className="clearfloat"></div><br/>
        {this.showSystemReport()}
        <br/>
        <label>Search</label>
        <input type="text"
          value={st.oneSearch}
          onChange={e=>this.onChange("updSearch",{oneSearch:e.currentTarget.value})}
        />
        {this.showAccounts()}
        {this.showUsers()}
        {this.showSites()}
        {this.showGateways()}
        {this.showZones()}
        {this.showFwUpload()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18TechPortal00;
